import React, { Component } from "react";
import { connect } from "react-redux";
import updateScreenSize from "../../../actions/updateScreenSize";
// import updateTopBar from "../../../actions/updateTopBar";
import { bindActionCreators } from "redux";
import { i18n } from "../../../translate/i18n";

class ScreenSize extends Component {
  // Altera a fonte do Texto Selecionado
  change(e) {
    this.props.updateScreenSize(JSON.parse(e.target.value), 0);
  }

  render(props) {
    const sizesFamily = [
      {
        sizeName: i18n.t("coupon.tools.screensizes.coupon"),
        sizeValue: { width: "540", height: "400" },
      },
      {
        sizeName: i18n.t("coupon.tools.screensizes.instasquare"),
        sizeValue: { width: "540", height: "540" },
      },
      {
        sizeName: i18n.t("coupon.tools.screensizes.instalandiscape"),
        sizeValue: { width: "675", height: "540" },
      },
      {
        sizeName: i18n.t("coupon.tools.screensizes.instaportrait"),
        sizeValue: { width: "540", height: "675" },
      },

    ];

    const sizeElements = sizesFamily.map((item, index) => {
      return (
        <option key={index} value={JSON.stringify(item.sizeValue)}>
          {item.sizeName}
        </option>
      );
    });

    return (
      <div id="1" style={{ marginLeft: "10px" }}>
        <select
          id="sizesFamily"
          className="form-control"
          style={{ fontSize: "11px", width: "100px", fontWeight: "400" }}
          onChange={(e) => this.change(e)
          }
        >
          {sizeElements}
        </select>
      </div>
    );
  }
}

function mapStateToProps(state) {
  // mapStateToProps returns an object, with:
  // property is the local prop name to this component
  // value will be the property in the root reducer... ie, piece of the store
  return {
    // couponNavBarData: state.couponNavBar,
    // canvas: state.canvas,
    // fontControl: state.fontControl,
    screenSize: state.screenSize
  };
}

// mapDispatchToProps is how we tie our component to the dispatch
// it take one args: dispatch
function mapDispatchToProps(dispatch) {
  // this function return bindActionCreators
  // and we hand bindActionCreators an object
  // each property will be a local prop
  // each value will be a function that is dispatch when run
  // second arg of bindActionCreators is the dispatch
  return bindActionCreators(
    {
      updateScreenSize: updateScreenSize,
      // updateTopBar: updateTopBar
    },
    dispatch
  );
}

// export default FontControl;
export default connect(mapStateToProps, mapDispatchToProps)(ScreenSize);
