const messages = {
  en: {
    translations: {
      menu: {
        leftmenu: {
          couponmodule: "Coupons & Vouchers",
          couponmoduleitemcreatecoupon: "Coupon Create",
          couponmoduleitemcoupons: "Coupons",
          communicationmoduleitemcontacts: "Contacts",
          communicationmoduleitemcampaign: "Campaigns",
          communicationmoduleitemcreatecampaign: "Create Campaign",
          communicationmoduleitemcreatehtml: "HTML Body",
          couponmoduleitemvalidate: "Validate Coupon",
        },
      },
      viewcoupons: {
        start: "No coupon here yet! Create your first:",
        click: "Click here",
        support: {
          maintitle: "Coupon Settings",
          title: "Title",
          titleplace: "Coupon Title",
          validtitleplace: "Coupon Title required.",
          adjust: "Adjust %",
          discount: "Discount of:",
          fixedcode: "Fixed Code",
          autocode: "Auto Generate Code",
          couponlimit: "Coupon Limit",
          couponlimitplace: "No Limit",
          voucher: "Voucher US$",
          coin: "US$",
          choosename: "Name",
          chooseemail: "Email",
          startdate: "Valid From:",
          enddata: "Valid until:",
          enddataplace: "Always Valid",
          starthour: "Start Time",
          endhour: "End Time",
          branchrule:
            "Define whether it can be validated at any establishment, or just determined.",
          brachruleplace: "Valid in all establishments",
          bhancruleplace2: "Anonymous Coupon Use Allowed",
          name: "Name",
          email: "Email",
          requreidfieldsdesc:
            "Set the required fields to view the coupon code or validate the coupon code.",
          description: "Description",
          terms: "Terms and conditions",
          btnsave: "Save Settings",
          btnedit: "Edit Design",
          swhitactivelabel: "Activate",
          swhitdeactivelabel: "Deactivate",
          messagesaving: "Saving Settings...",
          messageupdating: "Updated Successfully",
          modaldeletetitle: "Delete Coupon",
          modaldeleteh5: "Atention!",
          modaldeletecontent:
            "Are you sure you want to continue? Deleting the coupon cannot be undone, current campaigns will be canceled!",
          modaldeletecanceltext: "Cancel",
          modaldeleteoktext: "Delete Coupon",
          notifyconfigactivestatus: "Coupon activated successfully!",
          notifyconfigdeactivestatus:
            "Coupon Deactivated successfully! Generated code will no longer be validated!",
          notifyautoorfixedcode:
            "You need to set automatic code or set a fixed code!",
          notifycouponvoucher:
            "Coupon with 0% discount needs to offer Voucher value!",
          notifycoupontitle: "The coupon title is mandatory!",
          notifyhourinterval:
            "One period of time cannot be filled and the other doesnt, it needs to form an interval!",
          notifycodealready: "Fixed Coupon Code already in use!",
          notifyselectcoupon: "Select a coupon!",
        },
      },
      coupon: {
        topbar: {
          front: "All forwards",
          forward: "One forward",
          back: "Everything behind",
          backward: "One back",
          rectangle: "Rectangle",
          square: "Square",
          circle: "Circle",
          triangle: "Triangle",
          text: "To write",
          delete: "Delete",
        },
        canvas: {
          classification: "Classification AI: ",
          messagebackgroundremove: "Removing background from image ...",
          messagesavecoupon: "Saving Cupom!",
          messageupdatecoupon: "Coupon Updated Successfully!",
          messagesavecouponresult:
            'Coupon Saved Successfully! Attention: For this coupon to appear to your customer, you need to activate it by clicking on "Rules of Use"',
          errormessagesavecoupon: "Create your Coupon to be able to save!",
          messageaddingimage: "Adding Image.",
          textlabel: "Text",
          errormessageremovebackground:
            "Background image removal limit reached, make a credit purchase!",
          errormessageremovebackgroundselect:
            "Select an image to remove the background!",
          btnsavecoupontext: "Save Coupon",
          btncuponuserules: "Use Rules",
          btnnewcoupontext: "Create New",
        },
        supportmenu: {
          imageshowlabel1: "Gallery",
          imageshowlabel2: "Search",
          imageshowlabel3: "Pets",
          imageshowlabel4: "Fashion",
          imageshowlabel5: "Coffee",
          imageshowlabel6: "Business",
          imageshowlabel7: "Sales",
          imageshowlabel8: "Products",
          imageshowlabel9: "Health & Wellness",
          uploadimage: "Upload Image",
          icontextbackgroundremove: "Background Remove",
          icontexthighresolution: "High resolution",
          icontextlandscape: "Landscape/Portrait",
          modalbackgroundremovetitle: "Image Background Remove",
          modalbackgroundremoveh5: "Attention!",
          modalbackgroundremovecontent:
            "Removing the background from the image uses a credit: Are you sure you want to continue?",
          modalbackgroundremovebtncancel: "Cancel",
          modalbackgroundremovebtnok: "Image Background Remove",
          btnsearchtext: "Search",
          modalimagegallerytitle: "Delete Image",
          modalimagegalleryh5Content: "Attention!",
          modalimagegallerycontent:
            "Delete Image from Your Image Gallery: Are you sure you want to continue?",
          modalimagegallerycancelText: "Cancel",
          modalimagegalleryokText: "Delete Image",
        },
      },
      // Aqui
      contacts: {
        grid: {
          contactliststatuspaused: "Stopped",
          contactliststatusready: "Ready to use",
          addcontactslabel: "View/Add Contacts",
          columnlabelistname: "Contacts list name",
          columnlabelistdate: "Created at",
          columnlabeliststatus: "Status",
          columnlabelistactions: "Actions",
          columnlabelfirstname: "FirstName",
          columnlabellastname: "LastName",
          columnlabelmobil: "Mobil",
          columnlabelextra: "Extra1",
          columnlabelemail: "Email",
          columnlabelcreatedat: "Created at",
          columnlabelorigin: "Origin",
          buttonlabelcontacts: "View Contacts",
          searchplaceholder: "Search...",
          contactstatusactive: "Active",
          contactstatusinactive: "Inactive",
          contactstatusunsubscribe: "Unsubscribed",
          contactstatbounce: "Bounce",
          contactstatspamreport: "Spam Report",
          contactoriginlabel: "Add Manually",
          contactoriginlabelimported: "Imported List",
          contacthintlabel: "Import Contacts",
          columnrelationslabel: "Relate to column below:",
          dropdownignorelabel: "Ignore Column",
          dropdownnamelabel: "FirstName",
          dropdownnlastnamelabel: "LastName",
          dropdownnemaillabel: "Email",
          dropdownnmobillabel: "Mobil",
          delimiter: "delimiter",
          upload: "Upload",
          uploadfile: "Upload File",
          notifycontactdeleted: "Contact Successfully Deleted!",
          notifycontactupdated: "Contact updated successfully!",
          notifycontadded: "Contact Added Successfully!",
          notifyrequiredfield: "Email or Mobile are required!",
          messageimportingerror:
            "You are trying to import an Excel file. You need to save this file as a CSV first!",
          notifyimportresult1: "contacts imported successfully and",
          notifyimportresult2: " errors occurred,",
          notifyimportresult3:
            "                verify the import log for more details!",
          notifyimportresult4:
            "Import errors, verify the import log for more details!",
          notifyimportresult5: "Contacts imported successfully!",
        },
      },
      campaignview: {
        grid: {
          filterlabelemailmkt: "Email Marketing",
          filterlabelsms: "SMS",
          filterlabelsocialshare: "Social Share",
          filterlabelgeneric: "Generic Campaign",
          filterlabelactive: "Active",
          filterlabelinactive: "Inactive",
          customfieldemailmkt: "Email Marketing",
          customfieldsms: "SMS",
          customfieldsocialshare: "Social Share",
          customfieldgeneric: "Generic Campaign",
          eventlinklabel: "Events",
          detailslinklabel: "Campaign Details",
          columnlabelcampaignname: "Campaign Name",
          columnlabelmessagename: "Message",
          columnlabelcontactname: "Contact list",
          columnstatus: "Status",
          columnlabelsenttype: "Campaign Type",
          columnlabelsendfrom: "Sender",
          columnlabelcreatedat: "Created at",
          columnlabelsenddate: "Sent in",
          columnlabelcheckcampaign: "View Campaign",
          columnlabelactivity: "View Activity",
          searchlabel: "Search...",
        },
        activity: {
          filterlabelopen: "Opened Email",
          filterlabelviewpage: "Page Viewed",
          filterlabelgeneratecode: "",
          filterlabelclick: "Clicked Email Coupon",
          filterlabelusedcoupon: "Coupon Used",
          customfieldopen: "Abriu",
          customfieldviewpage: "Page Viewed",
          customfieldgeneratedcoupon: "Generated Code",
          customfieldclick: "Click",
          customfieldusedcoupon: "Coupon Used",
          modalcouponlabel: "View Coupon",
          usunsubscribe: "Unsubscribed",
          btncouponconfiglabel: "Configure Coupon",
          btnimageeditlabel: "Edit Image",
          searchlabel: "Search...",
          labelsource: "Shared Link",
          columnlabelsource: "Sent by",
          columnlabeldestination: "Sent to",
          columnnamesubject: "Subject",
          columnnameeventtype: "Activity",
          columnnamelink: "Clicked on",
          columnnameipaddress: "IP",
          columnlabelcoupon: "Coupon",
          columnlabeltimestamp: "Occurred on",
          columniptempflag: "Flag",
          columniptempmaintemp: "Temperature",
          columniptempmainhumidity: "Humidity",
          columninitialvalue: "Total",
          columndiscount: "Discount",
          columndiscountvalue: "Discount Value",
          columnvoucher: "Voucher",
          columnfinalvalue: "Final Value",
          columniptempzipcode: "ZipCode",
          columniptemploc: "Location",
          columniptempcity: "City",
          columniptempregion: "Region",
          columniptempcountryname: "Country",
          columniptempip: "IP",
          columniptempcurrencysymbol: "Currency",
          columniptemplanguages: "Language",
          columniptemplatitude: "Latitude",
          columniptemplongitude: "Longitude",
          columniptempprovidername: "Internet",
        },
        notify: {
          notifydeactivecampaign:
            "Attention: When deactivating a Shared Link campaign, the generate coupon page will be unavailable. Codes already generated will not be found.",
          notifyactivecampaign:
            "Shared Link Campaign Successfully Activated, the generate coupon page will be available. Codes already generated will be located.",
        },
        message: {
          couponimage: "No image to display.",
          imageediwarning:
            "If you change the image, the update will not be reflected in previously recorded events.",
        },
      },
      campaigncreate: {
        dropdowncoupon: "Select a Coupon",
        labelcontactlist: "Contact list",
        labelmessage: "Message",
        labelsharecoupon: "Choose Coupon To Share",
        labelsenderemail: "Sender",
        labelsocialnetworklink: "Link To Click on Social Media:",
        labelsocialnetworkclipboard: "Click to copy to ClipBoard",
        labelsocialnetworkdownload: "Click to download the coupon image:",
        labelsocialnetworkimagedownload: "Image Download",
        labelcampaignname: "Campaign Name",
        labelemailsubject: "Email Subject",
        labelsenddate: "Send in:",
        placeholderdate: "Set Date",
        labelcouponemailsubject: "Subject of Email Sent With Coupon Code",
        labelcardemailmkt: "Email Marketing",
        labelsocialshare: "Sharing",
        labelpushnotification: "Push Notification",
        labeldownloadcode: "Download Code",
        btnreset: "Reset",
        btnsave: "Save",
        message: {
          contactlist: "You need to create a list of contacts to send.",
          contactlistclick: "(Click here)",
          htmlmessage: "You need to create an HTML message to send.",
          htmlclick: "(Click here)",
          couponmessage: "You need to create a Coupon to share.",
          couponclick: "(Click here)",
          couponreleasemessage: "You need to release a Coupon to share.",
          couponreleaseclick: "(Click here)",
          senderemailmessage: "You need to register a Sender's E-mail.",
          senderclick: "(Click here)",
          senderemailmessagerelease:
            "You need to release the Registered Sender.",
        },
        notify: {
          contactlistalnotfound: "Non-existent contact list.",
          contactlistunavailable:
            "Contact list no longer available, it may have been deleted!",
          htmlmessagenotfound: "Non-existent HTML message.",
          copytoclipboard: "URL copied to the clipboard!",
          requiredfieldsender: "Set the Message Sender",
          requiredfielddate: "Set the sending date",
          requiredfieldhtml: "Set the HTML message",
          requiredfieldsendercode:
            "Set the Message Sender who will send the coupon code",
          requiredfieldcontactlist: "Set the Contacts list.",
          requiredfieldcupom: "Set the Coupon",

          campaigncreated: "The Campaign was successfully created!",
          pagereloading: "Reloading the page...",
          campaignsuccessfullycreated: "The Campaign was successfully created!",
          campaignaccountlimitreached:
            "Your account has reached the Campaign limit. Upgrade!",
          emailaccountlimitreached:
            "Your account has reached the limit for sending emails. Upgrade!",
          requiredfieldemailsubject: "Set the Email Subject.",
          requiredfieldcampaignname: "Set the Campaign Name.",
        },
      },
      couponcreatehtml: {
        btnreset: "Reset",
        btnsave: "Save",
        placeholdermessagename: "Message Name",

        notify: {
          htmlupdated: "Message successfully updated!",
          htmlcreated: "Message Created Successfully!",
          requiredfieldname: "Message name is required!",
          requiredhtmlmessage: "No HTML message to save!",
        },
      },
      validation: {
        labelname: "Name",
        placeholdername: "Name",
        labelemail: "Email",
        placeholderemail: "Email",
        labeltotalvalue: "Total purchase amount",
        labelfinalvalue: "Final purchase price: $",
        labeldiscount: "(-) Discount:",
        labelvoucher: "Voucher $",
        numberformat: "$ #,##0.##;($ #,##0.##)",
        numberformatvoucher: "$ #,##0.##",
        localvalue: "en-US",
        currencyvalue: "USD",
        btntext: "Validate",
        labelcouponrules: "Coupon Rules",
        labelcoupondiscount: "Discount",
        labelmanualdiscount: "Apply Manual Discount",
        labelcouponlimit: "Coupon Limit",
        placeholdercouponlimit: "Unlimited",
        labelvalidfrom: "Valid from:",
        placeholdervalid: "Always Valid",
        labelvaliduntil: "Valid until:",
        labelstarhour: "Start Time",
        labelendhour: "End Time",
        labeldescription: "Descriptive",
        labeltermsandconditions: "Terms and conditions",
        labelcouponcode: "Enter the coupon code:",
        labelfixedcode: "Fixed Code",
        labelactive: "Activate",
        labeldeactive: "Deactivate",
        labelautomatic: "Automatic",
        btnreset: "Reset",
        btncheck: "Check",

        notify: {
          couponalreadyused: "Coupon Already Used!",
          inactivecampaign: "Campaign Inactive",
          inactivecoupon: "Inactive Coupon",
          anonymousvalidationnotallowed: "Anonymous validation not allowed",
          requiredfieldemail: "Email required",
          requiredfieldname: "Name required.",
          couponnotfound: "No Coupons Found!",
          requiredfieldcouponcode: "Enter the coupon code!",
          couponsuccessfullyregistered: "Coupon registered successfully!",
          autherror: "Credentials error.",
          valueneedtobegreaterthanzero: "Final value cannot be negative.",
          requiredfieldnameandemail: "Name / Email are required.",
          rejectmessagenamerequired: "Name is required",
          rejectmessageemailrequired: "Email is required",
          rejectmessagecampaigndate: "Campaign period not yet started!",
          rejectmessagecampaignended: "Campaign period ended!",
          rejectmessageinvalidinterval: "Outside the valid time range for use!",
          rejectmessageinvalidvalue: "Total purchase amount cannot be $0",
        },
      },
      addbranch: {
        btnnew: "New",
        labelname: "Name",
        placeholdername: "Name",
        labelemail: "Email",
        placeholderemail: "Email",
        labeltype: "Type",
        labelperson: "Not Legal Entity",
        labelcorporate: "Legal Entity",
        placeholderidentifiertype: "Type",
        labelidentifiertype: "EIN/SSN",
        labelcompanyname: "Company Name",
        labelcompanynameas: "Trading Name",
        labelzipcode: "Zipcode",
        labelcityregion: "Region/City",
        labelregion: "Region",
        labelcity: "City",
        labeladdressline1: "AddressLine1",
        placeholderaddressline1: "AddressLine1",
        placeholdernumber: "Number",
        labeladdressline2: "AddressLine2",
        placeholderaddressline2: "AddressLine2",
        labelneighborhood: "Neighborhood",
        placeholderneighborhood: "Neighborhood",
        labelwebsite: "WebSite",
        placeholderwebsite: "WebSite",

        labelfacebook: "Facebook",
        placeholderfacebook: "Facebook",
        labelinstagram: "Instagram",
        placeholderinstagram: "Instagram",
        labelwhatsup: "Whatsup",
        labelphonenumber: "Phone",
        placeholderphonenumber: "Phone",
        labelmobil: "Mobil",
        placeholdermobil: "Mobil",
        labelmaps: "MAPS (Long/Lat)",
        placeholdermapslog: "Longitude",
        placeholdermapslat: "Latitude",
        labellogo: "Logo",
        bntreset: "Reset",
        btnsave: "Save",
        notify: {
          brachsuccessfullyupdated: "Updated Successfully!",
          branchsuccessfullycreated: "Saved Successfully!",
          verifyrequiredfields: "Verify the required fields!",
        },
        message: {
          requiredfieldname: "Name required",
          requiredfieldemail: "Email required",
          emailalreadyregistered: "Email already registered.",
          invalidemail: "Invalid email",
          identifieralreadyregistered: "EIN / SSN already registered.",
          requiredfieldcompanynameas: "Trading Name.",
        },
      },
      addoperators: {
        btnnew: "New",
        labelname: "Name",
        placeholdername: "Name",
        labelemail: "Email",
        placeholder: "Email",
        labelestablishment: "Establishment",
        labeldeactivate: "Deactivate",
        labelactivate: "Activate",
        labelupdate: "Update",
        labelregister: "Registered",
        labeloperatoraccess: "Operator Access",
        notify: {
          useractivatedsuccessfully: "User activated successfully!",
          userdeactivatedsuccessfully: "User deactivated successfully!",
          operatorupdatedsuccessfully: "Operator updated successfully!",
          operatorcreatedsuccessfully: "Operator successfully registered!",
          genericerror: "An error has occurred.",
          establishmentrequired: "Select an Establishment.",
          emailalreadyinuse: "Email already in use!",
          emailrequired: "Email required!",
          namerequired: "Name required.",
        },
        message: {
          namerequired: "Name required.",
          emailrequired: "Email required.",
          emailinvalid: "Invalid email.",
          emailalreadyregistered: "Email already registered.",
          firstgeneric:
            "The Operator will receive an email with a link to create their password.",
          secondgeneric:
            "Operator access is via an installable link both on the desktop and on the cell phone (PWA).",
        },
      },
      account: {
        profilepic: "Profile Photo",
        labelname: "Name",
        labelemail: "Email",
        labeltype: "Type",
        labelperson: "Not Legal Entity",
        labelcorporate: "Legal Entity",
        placeholderidentifiertype: "Type",
        labelidentifiertype: "EIN/SSN",
        labelcompanyname: "Company Name/Name",
        labelcompanynameas: "Trading Name",
        labelzipcode: "Zipcode",
        labelcityregion: "Region/City",
        labelregion: "Region",
        labelcity: "City",
        labeladdressline1: "AddressLine1",
        placeholderaddressline1: "AddressLine1",
        placeholdernumber: "Number",
        labeladdressline2: "AddressLine2",
        placeholderaddressline2: "AddressLine2",
        labelneighborhood: "Neighborhood",
        placeholderneighborhood: "Neighborhood",
        labelwebsite: "WebSite",
        labelcardnumber: "Credit Card",
        labelvalidate: "Valid",
        cvc: "CVC",
        placeholderwebsite: "WebSite",
        btnupdate: "Update Account",
        labelpaperpayment: "Boleto Bancário",
        labelcreatepaperpayment: "Gerar Boleto",
        labelplans: "Plans",
        labelmakepayment: "Pay",
        labelcreditcards: "Credit cards",
        labelcardname: "Full Name",
        labelcompanytype: "EIN/SSN",
        labelextracredit: "Extra Credit",
        allowrenew: "Allow Automatic Renewal",
        activate: "Activate",
        deactivate: "deactivate",
        monthlypayment: "Monthly payment: $",
        btnprofiledata: "Registration data",
        btnsign: "Payment",
        btnpayments: "Balance",
        btnconfigurations: "Settings",
        notify: {
          identifieralert:
            "EIN/SSN It is not mandatory, but if you inform yourself, you must indicate whether you are an individual or a legal entity!",
        },
        message: {
          generic:
            "Boleto bancário: liberação ocorre em até 15 minutos após envio do comprovante pela plataforma (horário comercial). Caso contrário, a liberação ocorrerá em até um dia útil.",
          identifieralreadyregistered: "EIN/SSN already registered.",
        },
        support: {
          labellimit: "Active Campaigns Limit: ",
          labelused: "Used",
          labelcontactslimit: "Limit of Registered Contacts:",
          labelemailsendlimit: "Email Send Limit",
          labelextraestablishments: "Extra establishments: ",
        },
      },
      toprightmenu: {
        videoclass: "Training video",
        labelwelcome: "Welcome",
        lblaccount: "My account",
        labelsenderemail: "Sender Email",
        labelconfigurations: "Settings",
        labellogout: "Logout",
      },
      logedout: {
        confirmemail: {
          buttonaction: "Resend Link",
          sending: "Sending ...",
          accountreadymessage: "Account already verified and ready to use!",
          accountreadysubmessage: "Click the link below to login.",
          activatelinkmessage: "Account Activation Link Sent Successfully",
          activatelinksubmessage:
            "Check the anti-spam box and click on the link to activate your new account.",
          accountsuccessfulyverified: "Account successfully verified.",
          accountsuccessfylyverifiedsubmessage:
            "Your account is ready to be used.",
          expiredtoken: "Expired token.",
          expiredtokenmessage:
            "Please click the button below again to send a new link to your email!",
          resendlinkmessage:
            "Account activation link successfully resubmitted.",
          resendlinksubmessage:
            "Check the anti-spam box and click on the link to activate your new account.",
          loginask: "Do you want to login?",
          loginlabel: "Login",
          salesmessage: "I love selling more!",
          salessubmessage: "It is too easy to use. I love it so much!",
          salesfooter: "- TooWeze Platform Client",
          notify: {
            usernotfound: "User not found.",
            needacceptterms: "Accept the terms",
            invalidtoken: "Invalid Token",
            invalidtokenmessage:
              "You cannot use this link / token to validate your account!",
          },
        },
      },
      createnewpassword: {
        labelnewpassword: "Create New Password",
        labeltypenewpassword: "Enter a new password.",
        labelpassword: "Password",
        btnnew: "Create",
        message: {
          passwordsuccess: "Password Created Successfully!",
          required: "Password required",
          passwordmessage:
            "Minimum of 8 digits, uppercase, lowercase and a number.",
          confirmpassword: "Confirm Password is required!",
          invalidpassword: "Different Passwords!",
          errormessage: "Erro!",
        },
        notify: {
          invalidtoken: "Invalid Token.",
          expiredtoken: "Expired token.",
          paswordmessage: "Password reset performed successfully!",
          invalidmessage: "Not valid.",
        },
        loginask: "Do you want to login?",
        loginlabel: "Login",
        salesmessage: "I love selling more!",
        salessubmessage: "It is too easy to use. I love it so much!",
        salesfooter: "- TooWeze Platform Client",
      },
      recoverypassword: {
        labelrecoverypassword: "Recover Password",
        messagerecoverypassword:
          "Enter your email, you will receive a link to reset your password.",
        labelemail: "Email",
        labelreset: " Reset Password ",
        successmessage: "Link Sent Successfully",
        successmessagepart2: "Check in the anti-spam box ",
        successmessagepart3: "and click on the link to create a new password.",
        message: {
          required: "Email required",
          invalid: "Invalid email",
        },
        notify: {
          emailnotfound: "Email not registered!",
        },
        loginask: "Do you want to login?",
        loginlabel: " Login",
        salesmessage: "I love selling more!",
        salessubmessage: "It is too easy to use. I love it so much!",
        salesfooter: "- TooWeze Platform Client",
      },
      registeraccount: {
        calltoactiontext1: "Sign up and get 7 days free",
        calltoactiontext2:
          "Do not have an account yet? Create now, it takes less than a minute!",
        calltoactiontext3: "No Credit Card required! Without commitment!",
        labelname: "Full name",
        labelemail: "Email",
        labelpassword: "Password",
        labeltermsaccept: "I accept ",
        labelterms: "Terms and conditions",

        message: {
          namerequired: "Name is required",
          emailrequired: "Email is required",
          emailinvalid: "Invalid email",
          passwordrequired: "Password is required",
          passwordinvalid:
            "Your password must be at least 8 digits, uppercase, lowercase and a number.",
        },
        notify: {
          alreadyregistered: "User already registered.",
          requiredlenght: "Name must be at least 5 characters long!",
          needacceptterms: "Accept the terms",
          requiredfields: "Check the fields!",
        },
        loginask2: "Already have an account? ",
        loginlabel: " Login",
        salesmessage: "I love selling more!",
        salessubmessage: "It is too easy to use. I love it so much!",
        salesfooter: "- TooWeze Platform Client",
      },
      login: {
        labelemailpassword: "Enter your email and password to get started.",
        labelemail: "Email",
        labelpassword: "Password",
        labelforgetpassword: "Forgot your password?",
        labelaccount: "Don't have an account yet? ",
        labelregister: "Register",
        labellogin: "LogIn",
        message: {
          emailrequired: "Email is required.",
          invalidemail: "Invalid email",
          passwordrequired: "Password is required",
        },
        notify: {
          userpassword: "Invalid username/password!",
          passwordlength: "Password must be at least 5 characters long!",
          confirmemail: "Confirm your email first!",
        },
        salesmessage: "I love selling more!",
        salessubmessage: "It is too easy to use. I love it so much!",
        salesfooter: "- TooWeze Platform Client",
      },
    },
  },
};

export { messages };
