import { toString } from "jimp";
import { urlAPI } from "./apiBase";
const axios = require("axios");
let token;
token = localStorage.getItem("tooweze_token");

// Cria um boleto PIX
export async function generatepaperpayment(allData) {
  const today = new Date();
  var data = {
    charge: {
      pixKey: "",
      pixIncludeImage: true,
      description: allData.planName,
      references: [allData.planName],

      amount: parseFloat(allData.planPrice).toFixed(2).toString(),
      dueDate: `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(
        -2
      )}-${("0" + (today.getDate() + 3)).slice(-2)}`,
      installments: 1,
      maxOverdueDays: 0,
      fine: 0,
      interest: "0.00",
      discountAmount: 1,
      discountDays: -1,
      paymentTypes: ["BOLETO_PIX"],
      paymentAdvance: false,
    },
    billing: {
      name: allData.userName,
      document: allData.companyIdentifierB.replace(/[^\w\s]/gi, ""),
      email: allData.email,
      address: {
        street: allData.addressLine1B,
        number: allData.numberB,
        complement: allData.addressLine2B,
        neighborhood: allData.cityRegionB,
        city: allData.cityB,
        state: allData.stateB,
        postCode: allData.zipCodeB.replace(/[^\w\s]/gi, ""),
      },
      notify: false,
    },
  };

  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: `${urlAPI.paymentgateway}/?destination=montly&planId=${allData.planId}`,
      headers: {
        "x-auth-token": token,
      },
      data: data,
    })
      .then((res) => {
        resolve(res.data._embedded.charges[0]);
      })
      .catch((err) => {
        console.log("Axios Err", err);
        reject(err);
      });
  });
}

export async function getBalance() {
  return new Promise((resolve, reject) => {
    axios
      .get(urlAPI.balance, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((balance) => {
        resolve(balance.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
