import React, { Component } from "react";
import { connect } from "react-redux";
import updateTopBar from "../../../actions/updateTopBar";
import { bindActionCreators } from "redux";
import "../../LayOut/TopBar.css";
import FontControl from "./FontControl";
import FontSizeComponent from "./FontSizeComponent";
import StrokeSizeComponent from "./StrokeSizeComponent";
import ColorBox from "devextreme-react/color-box";
import myElements from "../../data/topBarMenuCanvasData";

class CouponBuilderTopBar extends Component {
  // constructor() {
  //   super();
  // }
  handleColorChange = ({ value }) => {
    if (value) {
      this.addElement("changeColor", 0, value);
    }
  };

  // Aciona o Action que passa as informações para o component CanvasComponent
  addElement = (element, index, extra) => {
    if (element == "text") {
      this.props.updateTopBar(
        element,
        index,
        this.props.fontControl[0].fontFamily
      );
    } else {
      this.props.updateTopBar(element, index, extra);
    }
  };

  // Altera a fonte do Texto Selecionado
  // textChange(e) {
  //     this.setState({ fontfamily: e.target.value })
  //     this.addElement('changeText', 0, e.target.value);
  // }

  handleChangeStroke = () => { };

  render() {
    const navBarElements = myElements.map((item, index) => {
      return (
        <div key={index} style={{ marginLeft: "10px", width: "25px" }}>
          <a
            id={item.id}
            title={item.title}
            className="icons-list-demo"
            href="#"
            onClick={() => this.addElement(item.element, 0, item.extra)}
          >
            <svg
              id={item.id + 15}
              aria-hidden="true"
              focusable="false"
              width="22px"
              height="22px"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path d={item.svg} fill={item.fill} />
            </svg>
          </a>
        </div>
      );
    });

    return (
      <nav className="navbar navbar-default pl-0">
        <div className="container-fluid">
          <div className="navbar-nav navbar-right">
            <div className="btn-group navbar-btn">
              {navBarElements}
              <FontControl />
              <FontSizeComponent />

              <ColorBox
                height="31px"
                width="100px"
                style={{ marginTop: "-3px", marginLeft: "10px" }}
                defaultValue="#de1f1f"
                applyValueMode="instantly"
                onValueChanged={this.handleColorChange}
              />

              <StrokeSizeComponent />

              <div
                className="custom-control custom-switch"
                style={{ marginLeft: "10px" }}
              >
                <input
                  id="ckb_stroke"
                  type="checkbox"
                  className="custom-control-input"
                  onChange={(event) => {
                    this.addElement("stroke", 0, event.target.checked);
                  }}
                />
                <label
                  title="Contorno"
                  className="custom-control-label red"
                  style={{
                    paddingTop: "3px",
                    paddingRight: "10px",
                    fontSize: "12px",
                  }}
                  htmlFor="ckb_stroke"
                >
                  Contorno
                </label>
              </div>
              <div
                className="custom-control custom-switch"
                style={{ marginLeft: "3px" }}
              >
                <input
                  id="ckb_guideLines"
                  type="checkbox"
                  className="custom-control-input"
                  onChange={(event) => {
                    this.addElement("gridLines", 0, event.target.checked);
                  }}
                />
                <label
                  title="GuideLines"
                  className="custom-control-label red"
                  style={{
                    paddingRight: "25px",
                    fontSize: "14px",
                    // , color: '#755ce6'
                  }}
                  htmlFor="ckb_guideLines"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="ruler-combined"
                    style={{ marginTop: "5px" }}
                    className="svg-inline--fa fa-ruler-combined fa-w-16"
                    role="img"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M160 288h-56c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h56v-64h-56c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h56V96h-56c-4.42 0-8-3.58-8-8V72c0-4.42 3.58-8 8-8h56V32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v448c0 2.77.91 5.24 1.57 7.8L160 329.38V288zm320 64h-32v56c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-56h-64v56c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-56h-64v56c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-56h-41.37L24.2 510.43c2.56.66 5.04 1.57 7.8 1.57h448c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32z"
                    ></path>
                  </svg>
                </label>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

// mapStateToProps takes one args, "state" and that is the rootReducer/Store
function mapStateToProps(state) {
  // mapStateToProps returns an object, with:
  // property is the local prop name to this component
  // value will be the property in the root reducer... ie, piece of the store
  return {
    couponNavBarData: state.couponNavBar,
    canvas: state.canvas,
    fontControl: state.fontControl,
  };
}

// mapDispatchToProps is how we tie our component to the dispatch
// it take one args: dispatch
function mapDispatchToProps(dispatch) {
  // this function return bindActionCreators
  // and we hand bindActionCreators an object
  // each property will be a local prop
  // each value will be a function that is dispatch when run
  // second arg of bindActionCreators is the dispatch
  return bindActionCreators(
    {
      updateTopBar: updateTopBar,
    },
    dispatch
  );
}

// export default TopBar;
// connect take 2 args, the first one is a function that is going to map
// a piece of redux state to this components props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponBuilderTopBar);
