import React, { Component } from "react";
import './modallanding.css';

class ModalLanding extends Component {
  render() {
    return (
      <div
        id={this.props?.modalId}
        className="modallanding fade"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="danger-header-modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" style={{ width: '800px', left: -200 }}>
          <div className="modal-content" style={{ width: '800px' }}>
            {/* <div className="modal-header modal-colored-header">
              <h4 className="modal-title" id="danger-header-modalLabel">
                {this.props.title}
              </h4>
            </div> */}
            <div className="modal-body" style={{ width: '800px' }}>
              <h5 className="mt-0">{this.props.h5Content}</h5>
              <p>{this.props.content}</p>
            </div>
            {/* <div className="modal-footer">
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default ModalLanding;
