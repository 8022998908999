import React, { Component } from "react";
import "./Auth.css";
import logo from "../../images/complet_logo_2.png";

import { TextBox } from "devextreme-react";
import {
  Validator,
  RequiredRule,
  EmailRule,
  PatternRule
} from "devextreme-react/validator";
import { Button } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { Link } from "react-router-dom";
import { createUser } from "../../apis/toowezeApi";
import { i18n } from "../../translate/i18n";

export default class RegisterAccount extends Component {
  constructor(props) {
    super(props);

    this.websitePattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

    this.state = {
      loadPanelVisible: false,
      allPanelVisible: false,
      goValidateEmail: false,
      emailCheck: false,
      photo: "1",
      checked: false,
      nameIsValid: false,
      emailIsValid: false,
      passwordIsValid: false,
    };
  }

  componentWillMount() {
    document.body.style.backgroundColor = "white";
    this.setState({
      photo: Math.round(Math.random() * 8).toString(),
    });
  }

  componentDidMount() {
    document.body.style.backgroundColor = "white";

    // Limpa as informações de Login
    localStorage.removeItem("auth");
    localStorage.removeItem("tooweze_token");
    localStorage.removeItem("isConfigured");
    // Limpa as informações de Login
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  // Administra as mudanças de valores dos TextBox
  handleValueChange = (event, fieldId) => {
    if (fieldId) {
      if (fieldId === "checked") {
        this.setState({
          [fieldId]: event.target.checked ? event.target.checked : false,
        });
      } else {
        this.setState({
          [fieldId]: event.value ? event.value : null,
        });
      }
    }
  };

  // Cria a conta
  registerUser = () => {
    if (
      this.state.nameIsValid === true &&
      this.state.emailIsValid === true &&
      this.state.passwordIsValid === true
    ) {
      if (this.state.checked === true) {
        createUser(this.state.name, this.state.email, this.state.password, this.state.companyName, this.state.website)
          .then((res) => {
            if (res) {
              if (res.status == "200") {
                if (res.data == "User already registered.") {
                  this.showNotify(
                    i18n.t("registeraccount.notify.alreadyregistered"),
                    "error"
                  );
                } else if (res.data._id) {
                  // Enviar e-mail
                  window.location.replace(`confirmemail?id=${res.data._id}`);
                }
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              if (
                error.response.data ===
                '"name" length must be at least 5 characters long'
              ) {
                this.showNotify(
                  i18n.t("registeraccount.notify.requiredlenght"),
                  "error"
                );
              } else {
                this.showNotify(error.response.data, "error");
              }
            }
          });
      } else {
        // Aceite os termos
        this.showNotify(
          i18n.t("registeraccount.notify.needacceptterms"),
          "error"
        );
      }
    } else {
      this.showNotify(i18n.t("registeraccount.notify.requiredfields"), "error");
    }
  };

  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "left", offset: "290 -200" },
        closeOnClick: true,
      },
      type,
      3000
    );
  }

  checkIsValid = (event, type) => {
    if (type === "passwordIsValid") {
      let patt = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
      let res = patt.test(event.value);
      this.setState({
        [type]: event.isValid === true && res === true ? true : false,
      });
    } else {
      this.setState({
        [type]: event.isValid,
      });
    }
  };

  render() {
    return (
      <div className="row col-12 p-0 m-0">
        <div id="7" className="col-12 col-sm-4" style={{ paddingRight: "29px", paddingLeft: "0px" }}>
          <div className="logoFirst">
            <img className="logoExterno" src={logo} alt="Logo" />
          </div>

          <div
            className="auth-fluid-form-box"
          //   style={{ paddingTop: "8%" }}
          >
            <div className="align-items-center d-flex h-100">
              <div className="card-body" style={{ paddingRight: "4px" }}>
                <div className="auth-brand text-center text-lg-left"></div>

                <h4 className="mt-0">
                  {i18n.t("registeraccount.calltoactiontext1")}
                </h4>
                <p className="text-muted mb-4">
                  {i18n.t("registeraccount.calltoactiontext2")}
                </p>
                <p className="text-info mb-4" style={{ fontWeight: "bold" }}>
                  {i18n.t("registeraccount.calltoactiontext3")}
                </p>
                <div>
                  <form>
                    <div className="dx-field">
                      <div className="dx-label">
                        {i18n.t("registeraccount.companyname")}
                      </div>
                      <div className="dx-value">
                        <TextBox
                          showClearButton={true}
                          placeholder=""
                          value={this.state.companyName ? this.state.companyName : null}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "companyName")
                          }
                        >
                          <Validator
                            onValidated={(event) =>
                              this.checkIsValid(event, "companyname")
                            }
                          >
                            <RequiredRule
                              message={i18n.t(
                                "registeraccount.message.companynamerequired"
                              )}
                            />
                          </Validator>
                        </TextBox>
                      </div>
                      <div className="dx-label">
                        {i18n.t("registeraccount.website")}
                      </div>
                      <div className="dx-value">
                        <TextBox
                          showClearButton={true}
                          placeholder=""
                          value={this.state.website ? this.state.website : null}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "website")
                          }
                        >
                          <Validator
                            onValidated={(event) =>
                              this.checkIsValid(event, "companywebsite")
                            }
                          >
                            <RequiredRule
                              message={i18n.t(
                                "registeraccount.message.companywebsiterequired"
                              )}
                            />
                            <PatternRule message={i18n.t(
                              "registeraccount.message.companywebsiteinvalid"
                            )} pattern={this.websitePattern} />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>
                    <div className="dx-field">
                      <div className="dx-label">
                        {i18n.t("registeraccount.labelname")}
                      </div>
                      <div className="dx-value">
                        <TextBox
                          showClearButton={true}
                          placeholder=""
                          value={this.state.name ? this.state.name : null}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "name")
                          }
                        >
                          <Validator
                            onValidated={(event) =>
                              this.checkIsValid(event, "nameIsValid")
                            }
                          >
                            <RequiredRule
                              message={i18n.t(
                                "registeraccount.message.namerequired"
                              )}
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>
                    <div className="dx-field">
                      <div className="dx-label">
                        {i18n.t("registeraccount.labelemail")}
                      </div>
                      <div className="dx-value">
                        <TextBox
                          showClearButton={true}
                          placeholder=""
                          value={this.state.email}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "email")
                          }
                        >
                          <Validator
                            onValidated={(event) =>
                              this.checkIsValid(event, "emailIsValid")
                            }
                          >
                            <RequiredRule
                              message={i18n.t(
                                "registeraccount.message.emailrequired"
                              )}
                            />
                            <EmailRule
                              message={i18n.t(
                                "registeraccount.message.emailinvalid"
                              )}
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>
                    <div className="dx-field">
                      <div className="dx-label">
                        {i18n.t("registeraccount.labelpassword")}
                      </div>
                      <div className="dx-value">
                        <TextBox
                          showClearButton={true}
                          placeholder=""
                          mode="password"
                          value={this.state.password}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "password")
                          }
                          maxLength="128"
                        >
                          <Validator
                            onValidated={(event) =>
                              this.checkIsValid(event, "passwordIsValid")
                            }
                          >
                            <RequiredRule
                              message={i18n.t(
                                "registeraccount.message.passwordrequired"
                              )}
                            />
                          </Validator>
                        </TextBox>
                      </div>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "darkred",
                          display: this.state.passwordIsValid
                            ? "none"
                            : "block",
                        }}
                      >
                        {i18n.t("registeraccount.message.passwordinvalid")}
                      </span>
                    </div>

                    <div className="form-group">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="checkbox-signup"
                          value={this.state.checked}
                          onChange={(event) =>
                            this.handleValueChange(event, "checked")
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-signup"
                        >
                          {i18n.t("registeraccount.labeltermsaccept")}
                          <a
                            href="https://coupon-images-bash.s3.amazonaws.com/Termos+de+Uso.pdf"
                            className="text-muted"
                            target="_blank"
                          >
                            {i18n.t("registeraccount.labelterms")}
                          </a>
                        </label>
                      </div>
                    </div>
                    <div className="form-group mb-0 text-center">
                      <Button
                        text=" Criar Conta "
                        height={30}
                        width={"100%"}
                        type="success"
                        onClick={this.registerUser}
                      ></Button>
                    </div>

                    <div className="text-center mt-4">
                      <p id="output" className="text-muted font-16"></p>
                    </div>
                  </form>
                </div>

                <footer className="footer footer-alt">
                  <p className="text-muted">
                    {i18n.t("registeraccount.loginask2")}
                    <Link to="login">
                      <b>{i18n.t("registeraccount.loginlabel")}</b>
                    </Link>
                  </p>
                </footer>
              </div>
            </div>
          </div>
        </div>
        <div
          id="6"
          className="auth d-none d-sm-block col-sm-8"
          style={{
            top: "-17px",
            right: "-50px",
            background: `url('/images/capa_${this.state.photo}.jpg') 50% no-repeat`,
            backgroundSize: "cover",
          }}
        >
          {/* <!--Parte do meio--> */}
          {/* <MiddleBody module='Home'/> */}
          <div className="saleText text-center">
            <h2 className="mb-3">{i18n.t("registeraccount.salesmessage")}</h2>
            <p className="saleTextFirst">
              {i18n.t("registeraccount.salessubmessage")}
            </p>
            <p>{i18n.t("registeraccount.salesfooter")}</p>
          </div>
        </div>
      </div>
    );
  }
}
