import React, { Component } from "react";
import "./TopBar.css";
import CouponBuilderTopBar from "../CouponModule/BuildCanvas/CouponBuilderTopBar";
import { Button } from "devextreme-react";

class TopBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let moduleElement;
    if (this.props.module === "Home") {
      moduleElement = "";
    } else if (this.props.module === "Coupon") {
      moduleElement = <CouponBuilderTopBar />;
    } else if (this.props.module === "couponcampaignsview") {
      moduleElement = "";
    } else {
      moduleElement = "";
    }

    return (
      <>
        <div
          className="topbar shadow bg-white content"
          style={{ overflow: "scroll" }}
        >
          <div className="topbarContainer">
            <div style={{ display: "block" }}>
              <div className="col-md-12 col-xl-12 col-sm-12">
                <div className="row" style={{ paddingTop: "7px" }}>
                  <div className="col-1">
                    <Button
                      id="backButton"
                      icon="arrowleft"
                      onClick={this.props.history.goBack}
                    />

                  </div>
                  <div className="col-11 pl-0">
                    {moduleElement ? moduleElement : <label className="badge badge-danger" id="renewLabel"></label>}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TopBar;
