const messages = {
  es: {
    translations: {
      menu: {
        leftmenu: {
          couponmodule: "Cupones y vales",
          couponmoduleitemcreatecoupon: "Crear cupón",
          couponmoduleitemcoupons: "Sus cupones",
          communicationmoduleitemcontacts: "Contactos",
          communicationmoduleitemcampaign: "Ver campañas",
          communicationmoduleitemcreatecampaign: "Crear campaña",
          communicationmoduleitemcreatehtml: "Crear HTML",
          couponmoduleitemvalidate: "Registre el cupón",
        },
      },
      viewcoupons: {
        start: "¡Aún no hay cupón! Crea tu primero:",
        click: "Haga clic aquí",
        support: {
          maintitle: "Configuración de cupón",
          title: "Título",
          titleplace: "Título del cupón",
          validtitleplace: "Se requiere el título del cupón",
          adjust: "Ajustar %",
          discount: "Descuento:",
          fixedcode: "Código fijo",
          autocode: "Automático",
          couponlimit: "Límite de cupón",
          couponlimitplace: "Sin limite",
          voucher: "Vale €",
          coin: "€",
          choosename: "Nombre",
          chooseemail: "Correo electrónico",
          startdate: "Válida desde:",
          enddata: "Válido hasta:",
          enddataplace: "Siempre válido",
          starthour: "Hora de inicio",
          endhour: "Hora de finalización",
          branchrule:
            "Defina si se puede validar en cualquier establecimiento o simplemente determinarlo.",
          brachruleplace: "Válido en todos los establecimientos.",
          bhancruleplace2: "Se permite el uso anónimo del cupón.",
          name: "Nombre",
          email: "Correo electrónico",
          requreidfieldsdesc:
            "Configure los campos obligatorios para ver el código de cupón o validar el código de cupón.",
          description: "Descriptivo",
          terms: "Términos y Condiciones",
          btnsave: "Guardar ajustes",
          btnedit: "Editar diseño",
          swhitactivelabel: "Activar",
          swhitdeactivelabel: "Desactivar",
          messagesaving: "Guardar configuración",
          messageupdating: "Actualizado con éxito",
          modaldeletetitle: "Eliminar cupón",
          modaldeleteh5: "Aviso!",
          modaldeletecontent:
            "Estás seguro de que quieres continuar? La eliminación del cupón no se puede deshacer, las campañas actuales se cancelarán.",
          modaldeletecanceltext: "Cancelar",
          modaldeleteoktext: "Eliminar cupón",
          notifyconfigactivestatus: "Cupón activado con éxito!",
          notifyconfigdeactivestatus:
            "Cupón desactivado con éxito! El código generado ya no se validará!",
          notifyautoorfixedcode:
            "Necesita establecer un código automático o establecer un código fijo!",
          notifycouponvoucher:
            "El cupón con 0% de descuento debe ofrecer el valor del Vale!",
          notifycoupontitle: "¡El título del cupón es obligatorio!",
          notifyhourinterval:
            "Un período de tiempo no se puede llenar y el otro no, ¡necesita formar un intervalo!",
          notifycodealready: "¡Código de cupón fijo ya en uso!",
          notifyselectcoupon: "¡Seleccione un cupón!",
        },
      },
      coupon: {
        topbar: {
          front: "Todos los delanteros",
          forward: "Uno adelante",
          back: "Todo lo que hay detrás",
          backward: "Una espalda",
          rectangle: "Rectángulo",
          square: "Cuadrado",
          circle: "Circulo",
          triangle: "triángulo",
          text: "Escribir",
          delete: "Borrar",
        },
        canvas: {
          classification: "Clasificación IA: ",
          messagebackgroundremove: "Eliminando el fondo de la imagen ...",
          messagesavecoupon: "¡Grabando el Cupón!",
          messageupdatecoupon: "¡Cupón actualizado con éxito!",
          messagesavecouponresult:
            '¡Cupón registrado correctamente! Atención: para que este cupón le aparezca a su cliente, debe activarlo haciendo clic en "Reglas de uso"',
          errormessagesavecoupon: "¡Crea tu Cupón para poder registrarte!",
          messageaddingimage: "Añadiendo imagen.",
          textlabel: "Texto",
          errormessageremovebackground:
            "Se alcanzó el límite de eliminación de fondos, realice una compra a crédito",
          errormessageremovebackgroundselect:
            "¡Seleccione una imagen para eliminar el fondo!",
          btnsavecoupontext: "Guardar cupón",
          btncuponuserules: "Reglas de uso",
          btnnewcoupontext: "Crear nuevo cupón",
        },
        supportmenu: {
          imageshowlabel1: "Galería",
          imageshowlabel2: "Búsqueda",
          imageshowlabel3: "Mascotas",
          imageshowlabel4: "Ropa",
          imageshowlabel5: "Café",
          imageshowlabel6: "Negocio",
          imageshowlabel7: "Ventas",
          imageshowlabel8: "Productos",
          imageshowlabel9: "Bienestar", //Health & Wellness
          uploadimage: "Cargar imagen",
          icontextbackgroundremove: "Quitar fondo",
          icontexthighresolution: "Alta resolución",
          icontextlandscape: "Paisaje/Retrato",
          modalbackgroundremovetitle: "Eliminar fondo de la imagen",
          modalbackgroundremoveh5: "¡Aviso!",
          modalbackgroundremovecontent:
            "Quitar el fondo de la imagen utiliza un crédito: ¿Está seguro de que desea continuar?",
          modalbackgroundremovebtncancel: "Cancelar",
          modalbackgroundremovebtnok: "Eliminar fondo de la imagen",
          btnsearchtext: "Búsqueda",
          modalimagegallerytitle: "Eliminar Imagen",
          modalimagegalleryh5Content: "¡Aviso!",
          modalimagegallerycontent:
            "Eliminar imagen de su galería de imágenes: ¿Está seguro de que desea continuar?",
          modalimagegallerycancelText: "Cancelar",
          modalimagegalleryokText: "Eliminar Imagen",
        },
      },
      contacts: {
        grid: {
          contactliststatuspaused: "Pausado",
          contactliststatusready: "Liberado para su uso",
          addcontactslabel: "Ver/Agregar contactos",
          columnlabelistname: "Nombre de la lista de contactos",
          columnlabelistdate: "Creado en",
          columnlabeliststatus: "Estado",
          columnlabelistactions: "Comparte",
          columnlabelfirstname: "Nombre",
          columnlabellastname: "Apellido",
          columnlabelmobil: "Móbile",
          columnlabelextra: "Extra1",
          columnlabelemail: "Correo electrónico",
          columnlabelcreatedat: "Creado en",
          columnlabelorigin: "Origen",
          buttonlabelcontacts: "Ver contactos",
          searchplaceholder: "Búsqueda...",
          contactstatusactive: "Activo",
          contactstatusinactive: "Inactivo",
          contactstatusunsubscribe: "Darse de baja",
          contactstatbounce: "Bounce",
          contactstatspamreport: "Spam denunciado",
          contactoriginlabel: "Contacto agregado manualmente",
          contactoriginlabelimported: "Lista importada",
          contacthintlabel: "Importar contactos",
          columnrelationslabel: "Relacionarse con la columna de abajo:",
          dropdownignorelabel: "Ignorar columna",
          dropdownnamelabel: "Primer nombre",
          dropdownnlastnamelabel: "Apellido",
          dropdownnemaillabel: "Correo electrónico",
          dropdownnmobillabel: "Teléfono celular",
          delimiter: "Delimitador",
          upload: "Subir",
          uploadfile: "Subir archivo",
          notifycontactdeleted: "¡Contacto eliminado correctamente!",
          notifycontactupdated: "¡Contacto actualizado con éxito!",
          notifycontadded: "¡Contacto agregado exitosamente!",
          notifyrequiredfield: "¡Se requiere correo electrónico o móvil!",
          messageimportingerror:
            "Está intentando importar un archivo de Excel. ¡Primero debes convertir este archivo como CSV!",
          notifyimportresult1:
            "contactos importados correctamente y se produjeron",
          notifyimportresult2: " errores,",
          notifyimportresult3:
            "                ¡Consulte el registro de importación para obtener más detalles!",
          notifyimportresult4:
            "Importar errores, consulte el registro de importación para obtener más detalles.",
          notifyimportresult5: "¡Contactos importados correctamente!",
        },
      },
      campaignview: {
        grid: {
          filterlabelemailmkt: "Email Marketing",
          filterlabelsms: "SMS",
          filterlabelsocialshare: "Social Share",
          filterlabelgeneric: "Campaña genérica",
          filterlabelactive: "Ativas",
          filterlabelinactive: "Inativas",
          customfieldemailmkt: "Email Marketing",
          customfieldsms: "SMS",
          customfieldsocialshare: "Social Share",
          customfieldgeneric: "Campaña genérica",
          eventlinklabel: "Eventos",
          detailslinklabel: "Detalles de la campaña",
          columnlabelcampaignname: "Nombre de campaña",
          columnlabelmessagename: "Mensaje",
          columnlabelcontactname: "Lista de contactos",
          columnstatus: "Estado",
          columnlabelsenttype: "Tipo de campaña",
          columnlabelsendfrom: "Remitente",
          columnlabelcreatedat: "Creado en",
          columnlabelsenddate: "Enviado en",
          columnlabelcheckcampaign: "Ver campaña",
          columnlabelactivity: "Ver actividad",
          searchlabel: "Búsqueda...",
        },
        activity: {
          filterlabelopen: "Correo electrónico abierto",
          filterlabelviewpage: "Página vista",
          filterlabelgeneratecode: "Código generado",
          filterlabelclick: "Hizo clic en Cupón",
          filterlabelusedcoupon: "Cupón utilizado",
          customfieldopen: "Abrió",
          customfieldviewpage: "Página vista",
          customfieldgeneratedcoupon: "Código generado",
          customfieldclick: "Hizo clic",
          customfieldusedcoupon: "Cupón utilizado",
          modalcouponlabel: "Ver cupón",
          unsubscribe: "Darse de baja",
          btncouponconfiglabel: "Configurar cupón",
          btnimageeditlabel: "Editar imagen",
          searchlabel: "Búsqueda...",
          labelsource: "Enlace compartido",
          columnlabelsource: "Enviado por",
          columnlabeldestination: "Enviado a",
          columnnamesubject: "Tema en cuestion",
          columnnameeventtype: "Actividad",
          columnnamelink: "Hizo clic en",
          columnnameipaddress: "IP",
          columnlabelcoupon: "Cupón",
          columnlabeltimestamp: "Ocurrió el",
          columniptempflag: "Bandera",
          columniptempmaintemp: "Temperatura",
          columniptempmainhumidity: "Humedad",
          columninitialvalue: "Compra total",
          columndiscount: "Descuento",
          columndiscountvalue: "Precio de desc.",
          columnvoucher: "Value",
          columnfinalvalue: "Valor pago",
          columniptempzipcode: "Código postal",
          columniptemploc: "Local",
          columniptempcity: "Ciudad",
          columniptempregion: "Región",
          columniptempcountryname: "País",
          columniptempip: "IP",
          columniptempcurrencysymbol: "Moneda",
          columniptemplanguages: "Idioma",
          columniptemplatitude: "Latitud",
          columniptemplongitude: "Longitud",
          columniptempprovidername: "Internet",
        },
        notify: {
          notifydeactivecampaign:
            "Atención: al desactivar una campaña de enlace compartido, la página de generación de cupón no estará disponible. No se encontrarán los códigos ya generados.",
          notifyactivecampaign:
            "Campaña de enlace compartido activada con éxito, la página de generación de cupones estará disponible. Se encontrarán los códigos ya generados.",
        },
        message: {
          couponimage: "No hay imagen para mostrar.",
          imageediwarning:
            "Si cambia la imagen, la actualización no se reflejará en los eventos registrados anteriormente.",
        },
      },
      campaigncreate: {
        dropdowncoupon: "Seleccione un cupón",
        labelcontactlist: "Lista de contactos",
        labelmessage: "Mensaje",
        labelsharecoupon: "Elija un cupón para compartir",
        labelsenderemail: "Remitente",
        labelsocialnetworklink: "Enlace para hacer clic en las redes sociales:",
        labelsocialnetworkclipboard: "Haga clic en el ClipBoard",
        labelsocialnetworkdownload:
          "Haga clic para descargar la imagen del cupón:",
        labelsocialnetworkimagedownload: "Descarga de imagen",
        labelcampaignname: "Nombre de campaña",
        labelemailsubject: "Asunto del email",
        labelsenddate: "Envíe el:",
        placeholderdate: "Define la fecha",
        labelcouponemailsubject:
          "Asunto del correo electrónico enviado con el código de cupón",
        labelcardemailmkt: "Email Marketing",
        labelsocialshare: "Compartir",
        labelpushnotification: "Push Notification",
        labeldownloadcode: "Código de descarga",
        btnreset: "Reiniciar",
        btnsave: "Registrar",
        message: {
          contactlist: "Necesita crear una lista de contactos para enviar.",
          contactlistclick: "(Haga clic aquí)",
          htmlmessage: "Necesita crear un mensaje HTML para enviar.",
          htmlclick: "(Haga clic aquí)",
          couponmessage: "Necesitas crear un Cupón para compartir.",
          couponclick: "(Haga clic aquí)",
          couponreleasemessage: "Necesita liberar un cupón para compartir.",
          couponreleaseclick: "(Haga clic aquí)",
          senderemailmessage:
            "Necesita registrar un correo electrónico del remitente.",
          senderclick: "(Haga clic aquí)",
          senderemailmessagerelease: "Debe liberar al remitente registrado.",
        },
        notify: {
          contactlistalnotfound: "Lista de contactos inexistente.",
          contactlistunavailable:
            "La lista de contactos ya no está disponible, ¡puede que se haya eliminado!",
          htmlmessagenotfound: "Mensaje HTML inexistente.",
          copytoclipboard: "¡URL copiada al clipboard!",
          requiredfieldsender: "Configurar el remitente del mensaje",
          requiredfielddate: "Establecer la fecha de envío",
          requiredfieldhtml: "Definir el mensaje HTML",
          requiredfieldsendercode:
            "Defina el remitente del mensaje que enviará el código de cupón",
          requiredfieldcontactlist: "Defina la lista de contactos.",
          requiredfieldcupom: "Establecer el cupón",

          campaigncreated: "¡La campaña se creó con éxito!",
          pagereloading: "Releyendo la página ...",
          campaignsuccessfullycreated: "¡La campaña se creó con éxito!",
          campaignaccountlimitreached:
            "Su cuenta ha alcanzado el límite de campaña. ¡Suba de categoría!",
          emailaccountlimitreached:
            "Su cuenta ha alcanzado el límite de envío de correos electrónicos. ¡Suba de categoría!",
          requiredfieldemailsubject:
            "Establezca el Asunto del correo electrónico.",
          requiredfieldcampaignname: "Establezca el nombre de la campaña.",
        },
      },
      couponcreatehtml: {
        btnreset: "Reiniciar",
        btnsave: "Registrar",
        placeholdermessagename: "Nombre del mensaje",

        notify: {
          htmlupdated: "Mensaje actualizado con éxito!",
          htmlcreated: "¡Mensaje creado con éxito!",
          requiredfieldname: "¡El nombre del mensaje es obligatorio!",
          requiredhtmlmessage: "¡No hay mensaje HTML para registrar!",
        },
      },
      validation: {
        labelname: "Nombre",
        placeholdername: "Nombre",
        labelemail: "Correo electrónico",
        placeholderemail: "Correo electrónico",
        labeltotalvalue: "Importe total de la compra",
        labelfinalvalue: "Precio de compra final: $",
        labeldiscount: "(-) Descuento:",
        labelvoucher: "Vale de €",
        numberformat: "#.##0,## €;(#.##0,## €)",
        numberformatvoucher: "#.##0,## €",
        localvalue: "es",
        currencyvalue: "EUR",
        btntext: "Validar",
        labelcouponrules: "Reglas de cupones",
        labelcoupondiscount: "Descuento",
        labelmanualdiscount: "Aplicar descuento manual",
        labelcouponlimit: "Límite de cupón",
        placeholdercouponlimit: "Sin limite",
        labelvalidfrom: "Válida desde:",
        placeholdervalid: "Siempre válido",
        labelvaliduntil: "Válido hasta:",
        labelstarhour: "Hora de inicio",
        labelendhour: "Hora de finalización",
        labeldescription: "Descriptivo",
        labeltermsandconditions: "Términos y Condiciones",
        labelcouponcode: "Ingrese el código del cupón:",
        labelfixedcode: "Código fijo",
        labelactive: "Activar",
        labeldeactive: "Desactivar",
        labelautomatic: "Automático",
        btnreset: "Reiniciar",
        btncheck: "Verificar",
        notify: {
          couponalreadyused: "¡Cupón ya usado!",
          inactivecampaign: "Campaña inactiva",
          inactivecoupon: "Cupón inactivo",
          anonymousvalidationnotallowed: "No se permite la validación anónima.",
          requiredfieldemail: "Correo electronico requerido.",
          requiredfieldname: "Se requiere el nombre.",
          couponnotfound: "¡No se encontraron cupones!",
          requiredfieldcouponcode: "¡Complete el código del cupón!",
          couponsuccessfullyregistered: "Cupón registrado con éxito!",
          autherror: "Error de credenciales.",
          valueneedtobegreaterthanzero: "El valor final no puede ser negativo.",
          requiredfieldnameandemail:
            "Nombre / correo electrónico son obligatorios.",
          rejectmessagenamerequired: "Se requiere el nombre",
          rejectmessageemailrequired: "Correo electronico es requerido.",
          rejectmessagecampaigndate:
            "¡El período de la campaña aún no ha comenzado!",
          rejectmessagecampaignended: "¡El período de la campaña terminó!",
          rejectmessageinvalidinterval:
            "¡Fuera del rango de tiempo válido para su uso!",
          rejectmessageinvalidvalue:
            "El monto total de la compra no puede ser de $ 0",
        },
      },
      addbranch: {
        btnnew: "Nuevo",
        labelname: "Nombre",
        placeholdername: "Nombre",
        labelemail: "Correo electrónico",
        placeholderemail: "Correo electrónico",
        labeltype: "Tipo",
        labelperson: "Pesona Fisica",
        labelcorporate: "Persona jurídica",
        placeholderidentifiertype: "Tipo de registro",
        labelidentifiertype: "CIF/NIF/DNI",
        labelcompanyname: "Nombre de la empresa",
        labelcompanynameas: "Nombre Comercial",
        labelzipcode: "Código Postal",
        labelcityregion: "Región/Ciudad",
        labelregion: "Región",
        labelcity: "Ciudad",
        labeladdressline1: "Dirección",
        placeholderaddressline1: "Dirección",
        placeholdernumber: "Número",
        labeladdressline2: "Dirección 2",
        placeholderaddressline2: "Dirección 2",
        labelneighborhood: "Província/Estado",
        placeholderneighborhood: "Província/Estado",
        labelwebsite: "Sitio web",
        placeholderwebsite: "Sitio web",
        labelfacebook: "Facebook",
        placeholderfacebook: "Facebook",
        labelinstagram: "Instagram",
        placeholderinstagram: "Instagram",
        labelwhatsup: "Whatsup",
        labelphonenumber: "Teléfono",
        placeholderphonenumber: "Teléfono",
        labelmobil: "Mobile",
        placeholdermobil: "Mobile",
        labelmaps: "MAPS (Long/Lat)",
        placeholdermapslog: "Longitud",
        placeholdermapslat: "Latitud",
        labellogo: "Logo",
        bntreset: "Reiniciar",
        btnsave: "Registrar",
        notify: {
          brachsuccessfullyupdated: "¡Datos actualizados con éxito!",
          branchsuccessfullycreated: "¡Datos registrados con éxito!",
          verifyrequiredfields: "¡Consulta los campos obligatorios!",
        },
        message: {
          requiredfieldname: "Nombre requerido",
          requiredfieldemail: "Correo electronico requerido",
          emailalreadyregistered: "Correo electrónico ya registrado.",
          invalidemail: "Correo electrónico inválido",
          identifieralreadyregistered: "CIF/NIF/DNI ya registrado.",
          requiredfieldcompanynameas: "Se requiere Nombre Comercial",
        },
      },
      addoperators: {
        btnnew: "Nuevo",
        labelname: "Nombre",
        placeholdername: "Nombre",
        labelemail: "Correo electrónico",
        placeholder: "Correo electrónico",
        labelestablishment: "Establecimiento",
        labeldeactivate: "Desactivar",
        labelactivate: "Activar",
        labelupdate: "Actualizar",
        labelregister: "Registrar",
        labeloperatoraccess: "Acceso del operador",
        notify: {
          useractivatedsuccessfully: "¡Usuario activado con éxito!",
          userdeactivatedsuccessfully: "¡Usuario desactivado con éxito!",
          operatorupdatedsuccessfully: "¡Operador actualizado con éxito!",
          operatorcreatedsuccessfully: "¡Operador registrado exitosamente!",
          genericerror: "Se ha producido un error.",
          establishmentrequired: "Seleccione un establecimiento.",
          emailalreadyinuse: "¡El correo electrónico ya está en uso!",
          emailrequired: "¡Correo electronico requerido!",
          namerequired: "Nombre requerido",
        },
        message: {
          namerequired: "Nombre requerido",
          emailrequired: "Correo electronico requerido",
          emailinvalid: "Correo electronico inválido.",
          emailalreadyregistered: "El correo electrónico ya está en uso",
          firstgeneric:
            "El Operador recibirá un correo electrónico con un enlace para crear su contraseña.",
          secondgeneric:
            "El acceso del operador se realiza a través de un enlace instalable tanto en el desktop como en el móvil (PWA).",
        },
      },
      account: {
        profilepic: "Foto Perfil",
        labelname: "Nombre",
        labelemail: "Correo electrónico",
        labeltype: "Tipo",
        labelperson: "Pesona Fisica",
        labelcorporate: "Persona jurídica",
        placeholderidentifiertype: "Tipo de registro",
        labelidentifiertype: "CIF/NIF/DNI",
        labelcompanyname: "Nombre de la empresa",
        labelcompanynameas: "Nombre Comercial",
        labelzipcode: "Código Postal",
        labelcityregion: "Región/Ciudad",
        labelregion: "Región",
        labelcity: "Ciudad",
        labeladdressline1: "Dirección",
        placeholderaddressline1: "Dirección",
        placeholdernumber: "Número",
        labeladdressline2: "Dirección 2",
        placeholderaddressline2: "Dirección 2",
        labelneighborhood: "Província/Estado",
        placeholderneighborhood: "Província/Estado",
        labelwebsite: "Sitio web",
        labelcardnumber: "Numero de tarjeta",
        labelvalidate: "fecha de caducidad",
        cvc: "CVC",
        placeholderwebsite: "Sitio web",
        btnupdate: "Actualizar cuenta",
        labelpaperpayment: "Boleto Bancário",
        labelcreatepaperpayment: "Gerar Boleto",
        labelmakepayment: "Hacer el pago",
        labelcreditcards: "Tarjetas de crédito",
        labelcardname: "Nombre como en la tarjeta",
        labelcompanytype: "CIF/NIF/DNI",
        labelextracredit: "Crédito adicional",
        allowrenew: "Permitir renovación automática",
        activate: "Activar",
        deactivate: "Desactivar",
        monthlypayment: "Cuota mensual:",
        btnprofiledata: "Datos de registro",
        btnsign: "Mensualidad",
        btnpayments: "Pagos",
        btnconfigurations: "Ajustes",
        notify: {
          identifieralert:
            "CIF/NIF/DNI No es obligatorio, pero si informa, debe indicar si es persona fisica o jurídica!",
        },
        message: {
          generic:
            "Comprobante bancario: la liberación se produce dentro de los 15 minutos posteriores al envío del comprobante a través de la plataforma (horario comercial). De lo contrario, la liberación tendrá lugar dentro de un día hábil.",
          // requiredfieldname: "Nome requerido",
          // requiredfieldemail: "E-mail requerido",
          // emailalreadyregistered: "E-mail já registrado.",
          // invalidemail: "Email inválido",
          identifieralreadyregistered: "CIF/NIF/DNI ya registrado.",
          // requiredfieldcompanynameas: "Nome Fantasia requerido.",
        },
        support: {
          labellimit: "Límite de campañas activas: ",
          labelused: "Usado",
          labelcontactslimit: "Límite de contactos registrados:",
          labelemailsendlimit: "Límite de envío de correo electrónico",
          labelextraestablishments: "Establecimientos extra: ",
        },
      },
      toprightmenu: {
        videoclass: "Video aula",
        labelwelcome: "Bienvenido",
        lblaccount: "Mi cuenta",
        labelsenderemail: "Correo electrónico del remitente",
        labelconfigurations: "Ajustes",
        labellogout: "Logout",
      },

      logedout: {
        confirmemail: {
          buttonaction: "Reenviar enlace",
          sending: "Enviando...",
          accountreadymessage: "¡Cuenta ya verificada y lista para usar!",
          accountreadysubmessage:
            "Haga clic en el enlace de abajo para iniciar sesión.",
          activatelinkmessage:
            "Enlace de activación de cuenta enviado correctamente",
          activatelinksubmessage:
            "Cheque la casilla anti-spam y haga clic en el enlace para activar su nueva cuenta.",
          accountsuccessfulyverified: "Cuenta verificada correctamente.",
          accountsuccessfylyverifiedsubmessage:
            "Su cuenta está lista para ser utilizada.",
          expiredtoken: "Token caducado.",
          expiredtokenmessage:
            "¡Vuelva a hacer clic en el botón de abajo para enviar un nuevo enlace a su correo electrónico!",

          resendlinkmessage:
            "El enlace de activación de la cuenta se volvió a enviar correctamente.",
          resendlinksubmessage:
            "Cheque la casilla anti-spam y haga clic en el enlace para activar su nueva cuenta.",
          loginask: "¿Quieres iniciar sesión?",
          loginlabel: "Acceso",
          salesmessage: "¡Me encanta vender más!",
          salessubmessage: "Es muy fácil de usar. ¡Lo amo tanto!",
          salesfooter: "- Cliente de la plataforma TooWeze",
          notify: {
            usernotfound: "Usuario no encontrado.",
            needacceptterms: "Acepta los términos",
            invalidtoken: "Token Caducado.",
            invalidtokenmessage:
              "¡No es posible utilizar este enlace / token para validar su cuenta!",
          },
        },
      },
      createnewpassword: {
        labelnewpassword: "Crear nueva contraseña",
        labeltypenewpassword: "Introduzca una nueva contraseña.",
        labelpassword: "Contraseña",
        btnnew: "Crear",
        message: {
          passwordsuccess: "¡Contraseña creada con éxito!",
          required: "Se requiere contraseña",
          passwordmessage:
            "Mínimo de 8 dígitos, mayúsculas, minúsculas y un número.",
          confirmpassword: "¡Se requiere confirmación de contraseña!",
          invalidpassword: "¡Diferentes contraseñas!",
          errormessage: "Error!",
        },
        notify: {
          invalidtoken: "Token no valido.",
          expiredtoken: "Token caducado.",
          paswordmessage:
            "¡El restablecimiento de contraseña se realizó correctamente!",
          invalidmessage: "No es válido",
        },
        loginask: "¿Quieres iniciar sesión?",
        loginlabel: "Acceso",
        salesmessage: "¡Me encanta vender más!",
        salessubmessage: "Es muy fácil de usar. ¡Lo amo tanto!",
        salesfooter: "- Cliente de la plataforma TooWeze",
      },
      recoverypassword: {
        labelrecoverypassword: "Recuperar contraseña",
        messagerecoverypassword:
          "Ingrese su correo electrónico, recibirá un enlace para restablecer su contraseña.",
        labelemail: "Correo electrónico",
        labelreset: " Restablecer la contraseña ",
        successmessage: "Enlace enviado correctamente",
        successmessagepart2: "Cheque en la caja anti-spam ",
        successmessagepart3:
          "y haga clic en el enlace para crear una nueva contraseña.",
        message: {
          required: "Correo electronico requerido",
          invalid: "Correo electrónico inválido",
        },
        notify: {
          emailnotfound: "¡Correo electrónico no registrado!",
        },
        loginask: "¿Quieres iniciar sesión?",
        loginlabel: " Acceso",
        salesmessage: "¡Me encanta vender más!",
        salessubmessage: "Es muy fácil de usar. ¡Lo amo tanto!",
        salesfooter: "- Cliente de la plataforma TooWeze",
      },
      registeraccount: {
        calltoactiontext1: "Regístrate y obtén 7 días gratis",
        calltoactiontext2:
          "¿Aun no tiene una cuenta? ¡Crea ahora, toma menos de un minuto!",
        calltoactiontext3:
          "¡No se requiere tarjeta de crédito! ¡Sin compromiso!",
        labelname: "Nombre completo",
        labelemail: "Correo electrónico",
        labelpassword: "Contraseña",
        labeltermsaccept: "Acepto ",
        labelterms: "Términos y Condiciones",

        message: {
          namerequired: "Nombre requerido",
          emailrequired: "Correo electronico requerido",
          emailinvalid: "Correo electrónico inválido",
          passwordrequired: "Se requiere contraseña",
          passwordinvalid:
            "Su contraseña debe tener al menos 8 dígitos, mayúsculas, minúsculas y un número.",
        },
        notify: {
          alreadyregistered: "Usuario ya registrado.",
          requiredlenght: "¡El nombre debe tener al menos 5 caracteres!",
          needacceptterms: "Acepta los términos",
          requiredfields: "¡Revisa los campos!",
        },
        loginask2: "¿Ya tienes una cuenta? ",
        loginlabel: " Acceso",
        salesmessage: "¡Me encanta vender más!",
        salessubmessage: "Es muy fácil de usar. ¡Lo amo tanto!",
        salesfooter: "- Cliente de la plataforma TooWeze",
      },
      login: {
        labelemailpassword:
          "Ingrese su correo electrónico y contraseña para comenzar.",
        labelemail: "Correo electrónico",
        labelpassword: "Contraseña",
        labelforgetpassword: "Olvidaste tu contraseña?",
        labelaccount: "¿Aún no tienes una cuenta? ",
        labelregister: "Registrarse",
        labellogin: "Acceso",
        message: {
          emailrequired: "Correo electronico requerido.",
          invalidemail: "Correo electrónico inválido",
          passwordrequired: "Se requiere contraseña",
        },
        notify: {
          userpassword: "¡Usuario / contraseña invalida!",
          passwordlength: "¡La contraseña debe tener al menos 5 caracteres!",
          confirmemail: "¡Confirma tu correo electrónico primero!",
        },
        salesmessage: "¡Me encanta vender más!",
        salessubmessage: "Es muy fácil de usar. ¡Lo amo tanto!",
        salesfooter: "- Cliente de la plataforma TooWeze",
      },
    },
  },
};

export { messages };
