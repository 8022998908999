import axios from "axios";
import { urlAPI } from "./apiBase";

let token;
token = localStorage.getItem("tooweze_token");

let arrayBlock = [
  "vapricot",
  "ines3d",
  "evgeniasterg",
  "18thhorizon",
  "odd_fellow",
  "dikshakajaria",
  "pixelperfect",
  "claybanks",
];


export async function downloadUnsplashImage(url) {
  return new Promise((resolve, reject) => {
    axios.get(urlAPI.imagebank + "/download?url=" + url, {
      headers: {
        "x-auth-token": token,
      }
    }).then(url => {
      if (url) {
        resolve(url.data);
      } else {
        reject("Error!")
      }
    })
  })
}

export default function getImagesFromUnsplash(query, orientation, page) {
  if (!orientation) {
    orientation = "landscape";
  }

  return new Promise((resolve, reject) => {
    let AllImages = [];
    let translatefrom;

    if (query.search == "myGallery") {
      // Busca as imagens da Galeria

      let imageArray = [];

      imageArray.push({
        id: "myGallery",
        links: { html: "" },
        urls: { raw: "", thumb: "" },
        user: { first_name: "", last_name: "", links: { html: "" } },
      });

      imageArray.label = query.label;

      resolve(imageArray);

    } else {
      if (query.search == "search") {
        query.search = query.searchTerm;
        translatefrom = query.translatefrom;
      }

      if (query.search) {
        axios({
          method: "GET",
          url: urlAPI.imagebank + `?query=${query.search}&orientation=${orientation}&page=${page}&translatefrom=${translatefrom}`,
          headers: {
            "x-auth-token": token,
          },
        }).then(function (json) {
          let count2 = json.data.results.length;
          let found = false;
          if (count2 > 0) {
            json.data.results.forEach((element) => {
              for (var i = 0; i < arrayBlock.length; i++) {
                if (arrayBlock[i] == element.user.username) {
                  found = true;
                  break;
                }
              }

              if (found == false) {
                if (element.likes > 10) {
                  let url = element.urls.raw;
                  element.urls.raw = url + "&w=600&dpi=2";
                  element.urls.high = url + "&w=1200&dpi=2";
                  AllImages.push(element);
                }
              }

              count2 = count2 - 1;

              if (count2 == 0) {
                AllImages.label = query.label;
                resolve(AllImages);
              }

            });
          } else {
            resolve([]); // Com a busca retornando vazia, não pode rejeitar o Promisse.All pois ou tudo dá certo ou se dá um erro não funciona.
          }
        }).catch(function (err) {
          console.log("err", err.response);
          reject(err);
        });
      }
    }
  });
}
