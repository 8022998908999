// All reducers have 2 params :
// 1. Current State
// 2. Info that came from any action
const seedDate = 
[
    {
        fontFamily: ''
    }
    
];

export default (state = seedDate, action) => {
    
    if (action.type == 'updateFontControl') {
        
        const newState = [...state];
        newState[action.payload.index].oldfontFamily = newState[action.payload.index].fontFamily;
        newState[action.payload.index].fontFamily = action.payload.fontFamily;
        newState[action.payload.index].origin = true;
        return newState;    
    } else {

        const newState = [...state];
        newState[0].origin = false;
        return newState;

    }
}


