// All reducers have 2 params :
// 1. Current State
// 2. Info that came from any action

const seedDate =
    [
        {
            element: ''
        }

    ];

export default (state = seedDate, action) => {

    if (action.type == 'updateModal') {
        const newState = [...state];
        newState[action.payload.index].element = action.payload.element;
        newState[action.payload.index].extra = action.payload.extra;
        newState[action.payload.index].go = action.payload.go;
        return newState;

    } else {
        return state;
    }
}