import React, { Component } from "react";
import { Button } from "devextreme-react";
import { loadMessages, locale } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import "./CreateHTMLMiddle.css";
import "./reset.css"
// import HtmlEditor, {
//   Toolbar,
//   MediaResizing,
//   Item,
// } from "devextreme-react/html-editor";
import {
  HtmlEditor,
  Inject,
  Link as Link_,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  Image
} from "@syncfusion/ej2-react-richtexteditor";
import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-icons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";
import saveImagemGallery, {
  getAllHtmlBody, getHtmlBody, postHtmlBody, getCoupons
} from "../../../apis/toowezeApi";
import { actualURL } from "../../../apis/apiBase";
import { SelectBox } from "devextreme-react";
import { TextBox } from "devextreme-react/text-box";
import { i18n, lgMessages, languageCode } from "../../../translate/i18n";
import Spinner from "../../LayOut/Spinner";

export default class CouponCreateHtmlMiddle extends Component {
  constructor(props) {
    super(props);
    loadMessages(lgMessages);
    locale(languageCode);

    // Elementos do Editor HTML
    this.sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
    this.fontValues = [
      "Arial",
      "Courier New",
      "Georgia",
      "Impact",
      "Lucida Console",
      "Tahoma",
      "Times New Roman",
      "Verdana",
    ];
    this.headerValues = [false, 1, 2, 3, 4, 5];

    this.modelHTML = `<table style="width:100%">
    <tbody>
      <tr>
        <th rowspan="3" style="width:30%">Coluna Esquerda</th>
        <th style="width:40%">
        <p>Topo: Texto ou imagem</p>
  
        <p>&nbsp;</p>
        </th>
        <th rowspan="3" style="width:30%">Coluna Direita</th>
      </tr>
      <tr>
        <th style="width:40%">
        <p>Centro: Texto ou imagem</p>
  
        <p>&nbsp;</p>
        </th>
      </tr>
      <tr>
        <th style="width:40%">
        <p>Rodapé&nbsp;Texto ou imagem</p>
  
        <p>&nbsp;</p>
        </th>
      </tr>
    </tbody>
  </table>`

    this.state = {
      // messageBody: '',
      allCoupons: [],
      htmlBodyData: '',
      timestamp: Date.now(),
      imagemUrl: null,
      spinner: false
    };
  }

  componentDidMount() {
    // Lê todos os cupons
    this.readCouponsStartPage();

    // Lê todos os arquivos HTML
    getAllHtmlBody()
      .then((res) => {
        if (res) {
          this.setState({
            htmlBodyData: res,
          });
        }
      })
      .catch((error) => {
        console.log("Lê todos os arquivos HTML ERROR", error);
      });
  }

  // Função que busca todos os cupons
  readCouponsStartPage() {
    let coupons = [];
    let allCoupons = [];

    coupons.push(getCoupons());

    Promise.all(coupons)
      .then((data) => {
        if (data.length > 0) {
          data.forEach((coupon) => {
            if (coupon.length > 0) {
              // Evitando erro quando uma busca retorna vazia.
              allCoupons.push(coupon);
            }
          });
        }

        allCoupons.reverse();

        if (this.state.allCoupons.length == 0) {
          this.setState({
            allCoupons: allCoupons,
            spinner: false,
          });
        }
      })
      .catch((err) => console.log("erro **********", err));

    // Busca o cupom caso esteja vindo de outra página indicando o id na url
    //this.getCouponFromId()
  }

  changeHTML = (event) => {
    if (event) {
      this.setState({
        messageBody: event.value,
      });
    }
  };

  addElement = (coupon) => {
    this.setState({
      messageBody:
        this.state.messageBody +
        `<a id=${coupon._id} className="couponImage" href="${actualURL.url}viewcoupon.html?campaignId=campaignId_&couponId=${coupon._id}&rootId=${coupon.rootId}&source=1&contactId=contactSourceId" target="_blank">
            <img src="${coupon.image}" width="33%" height"auto"></a>`,
    });
  };

  // Administra a mudança de valor no dropdownlist relativo as mensagens HTML
  bodyHTMLSelected = (event) => {
    this.setState({ messageId: event.value });

    getHtmlBody(event.value).then((res) => {
      if (res) {
        this.setState({
          messageBody: res.messageBody,
          messageName: res.title,
          messageId: res._id,
        });
      }
    });
  };

  // Grava ou atualiza o HTML Body da Mensagem
  postHtmlBody_ = () => {

    this.setState({
      spinner: true
    });

    let messageBody_;

    if (
      this.state.messageBody != "" &&
      this.state.messageBody != undefined &&
      this.state.messageBody != null
    ) {
      if (
        this.state.messageName != "" &&
        this.state.messageName != undefined &&
        this.state.messageName != null
      ) {
        messageBody_ = {
          messageBody: this.state.messageBody,
          messageId: this.state.messageId,
          messageName: this.state.messageName,
        };

        this.extractImage(messageBody_.messageBody).then(newHTML => {

          console.log("newHTML ====>", newHTML);

          if (newHTML) {
            messageBody_.messageBody = newHTML;
            postHtmlBody(messageBody_)
              .then((res) => {
                if (res.status == "200") {
                  if (messageBody_.messageId) {
                    this.setState({
                      spinner: false
                    });
                    this.showNotify(
                      i18n.t("couponcreatehtml.notify.htmlupdated"),
                      "success"
                    );
                    messageBody_ = "";
                  } else {
                    this.setState({
                      spinner: false
                    });
                    this.showNotify(
                      i18n.t("couponcreatehtml.notify.htmlcreated"),
                      "success"
                    );
                    messageBody_ = "";
                  }

                  // Lê todos os arquivos HTML
                  getAllHtmlBody().then((res) => {
                    if (res) {
                      this.setState({
                        htmlBodyData: res,
                      });
                    }
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  spinner: false
                });
                messageBody_ = "";
                if (error.response) {
                  if (error.response.status == 401) {
                    this.showNotify("Erro de Login!", "error");
                  } else {
                    this.showNotify(error.response.data, "error");
                  }
                }
              });
          }
        }).catch(err => {
          this.setState({
            spinner: false
          });
          console.log(err)
        })
      } else {
        this.setState({
          spinner: false
        });
        this.showNotify(
          i18n.t("couponcreatehtml.notify.requiredfieldname"),
          "error"
        );
      }
    } else {
      this.setState({
        spinner: false
      });
      this.showNotify(
        i18n.t("couponcreatehtml.notify.requiredhtmlmessage"),
        "error"
      );
    }
  };

  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "top", offset: "0 200" },
        closeOnClick: true,
      },
      type,
      3000
    );
  }

  extractImage = (htmlBody) => {
    return new Promise(async (resolve, reject) => {
      let newHTML2 = "";
      newHTML2 = htmlBody.toString()
      const isStart = newHTML2.includes("data:image");

      if (isStart === true) {
        let m,
          str = htmlBody,
          rex = /src="(data:image\/[^;]+;base64[^"]+)"/g;
        while (m = rex.exec(str)) {
          const bodyBase64 = m?.[1];
          saveImagemGallery("image" + new Date().getTime().toString(), bodyBase64).then(result => {
            if (result && result?.urls?.raw) {
              newHTML2 = newHTML2.replace(bodyBase64, result.urls.raw);
              const isEnd = newHTML2.includes("data:image");
              if (isEnd === false) {
                resolve(newHTML2);
              }
            }
          });
        }
      } else {
        resolve(htmlBody)
      }
    });
  };

  reset = () => {
    this.setState({
      messageBody: "",
      messageName: "",
      messageId: null,
    });
  };

  // Função que administra toda mudança de valor nos componentes
  handleValueChange = (event, fieldId) => {
    if (fieldId) {
      this.setState({
        [fieldId]: event.value ? event.value.toString() : null,
      });
    }
  };


  imageUploadSuccess = (args) => {
    console.log("args", args);
    // if (args.e.currentTarget.getResponseHeader("name") != null) { 
    //    args.file.name = args.e.currentTarget.getResponseHeader("name"); 
    //    filename = document.querySelectorAll(".e-file-name")[0]; 
    //    filename.innerHTML = args.file.name.replace(document.querySelectorAll(".e-file-type")[0].innerHTML, ""); 
    //    filename.title = args.file.name; 
    // } 
  }

  imageUploading = (args) => {
    //   if (args.event.currentTarget.getResponseHeader('url') != null) { 
    //     // Access and use url as per your need in application end 
    //     var url = args.event.currentTarget.getResponseHeader('url'); 
    //     console.log('url', url)
    // } 
  }


  render() {
    console.log("Render ...")
    let allCoupons;

    allCoupons = this.state.allCoupons.map((element) => {
      return element.map((coupon) => {
        return (
          <div
            key={coupon._id}
            className="col-md-12 col-xl-6"
            style={{ float: "left", padding: "3px" }}
            onClick={() => {
              this.addElement(coupon);
            }}
          >
            <div
              id={coupon._id}
              className={
                "d-block shadow bg-white borderClass " + this.state.clickedClass
              }
            >
              <div className="">
                <div
                  className="input-group-append"
                  style={{ height: "28px", float: "right" }}
                ></div>
                <img
                  alt=""
                  src={
                    coupon.imageurlThumb +
                    "?" +
                    (coupon.updated ? coupon.updated : null)
                  }
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        );
      });
    });

    return (
      <div
        className="container col-12"
        style={{ marginLeft: "30px", paddingRight: "40px" }}
      >
        <div className="row">
          <div
            className="col-8"
            style={{ paddingLeft: "27px", paddingTop: "20px" }}
          >
            <div className="row">
              <TextBox
                value={this.state.messageName}
                onValueChanged={(event) =>
                  this.handleValueChange(event, "messageName")
                }
                height={30}
                width={204}
                style={{ margin: "3px" }}
                showClearButton={true}
                placeholder={i18n.t("couponcreatehtml.placeholdermessagename")}
                readOnly={this.messageId ? true : false}
              />

              <SelectBox
                dataSource={
                  this.state.htmlBodyData ? this.state.htmlBodyData : null
                }
                style={{ margin: "3px" }}
                displayExpr="title"
                valueExpr="_id"
                value={this.state.messageId ? this.state.messageId : null}
                width={250}
                onValueChanged={this.bodyHTMLSelected}
                readOnly={this.campaignId ? true : false}
              />

              <div className="">
                <Button
                  width={120}
                  text={i18n.t("couponcreatehtml.btnreset")}
                  type="normal"
                  height={30}
                  stylingMode="contained"
                  onClick={this.reset}
                  style={{ margin: "3px" }}
                />
                <Button
                  width={120}
                  text={i18n.t("couponcreatehtml.btnsave")}
                  type="success"
                  height={30}
                  stylingMode="contained"
                  style={{ margin: "3px" }}
                  onClick={this.postHtmlBody_}
                />
              </div>
            </div>
          </div>
          <div className="col-4"></div>
        </div>

        <div className="row" style={{ height: "600px" }}>
          <div className="col-8">
            <div className="widget-container">
              <div className="control-pane">
                <div className="control-section" id="rte">
                  <div className="rte-control-section reset-class">
                    {this.state.spinner === true ? <Spinner /> : <RichTextEditorComponent
                      id="defaultRTE"
                      value={this.state.messageBody}
                      autoSaveOnIdle={true}
                      enablePersistence={false}
                      height={'650px'}
                      insertImageSettings={{ saveFormat: 'Base64' }}
                      change={(e) => {
                        this.setState({ messageBody: e.value }, function () {
                          this.extractImage(this.state.messageBody)
                        })
                      }}
                    >
                      <Inject
                        services={[HtmlEditor, Toolbar, Link_, QuickToolbar, Image]}
                      />

                    </RichTextEditorComponent>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4" style={{ overflow: "auto", height: "650px" }}>
            <div className="card">
              <div className="col-md-12 col-xl-12 content">{allCoupons}</div>
            </div>
          </div>
        </div>
      </div>
    );



  }
}
