import React, { Component } from "react";
import "./PhotoControl.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import updatePhotoControl from "../../../actions/updatePhotoControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPortrait, faMagic } from "@fortawesome/free-solid-svg-icons";
import saveImagemGallery, {
  deleteImagemGallery, deleteStudio
} from "../../../apis/toowezeApi";
import Spinner from "../../LayOut/Spinner";
import PhotoCards from "./PhotoCards";
import Modal from "../../Modal";
import "./PhotoCards.css";
import './modal.css';
import notify from "devextreme/ui/notify";
import { i18n } from "../../../translate/i18n";

class PhotoControl extends Component {
  constructor() {
    super();
    this.state = {
      imagesShow: [],
      highResolution: false,
      spinner: false,
      count: 30,
      start: 0,
      activeTab: 5,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.photoControl !== nextProps.photoControl) {
      if (this.props.photoControl[0].element == "getImagemGallery") {
        return true;
      }
    }

    // Evita renderizar cada vez que o estado é modificado
    if (this.props.modalControl !== nextProps.modalControl) {
      if (this.props.modalControl[0].element === "deleteImageGallery" && this.props.modalControl[0].go === "go") {
        try {
          this.setState({ spinner: true });
          if (this.props.modalControl[0].extra) {
            deleteImagemGallery(this.props.modalControl[0].extra).then((res) => {
              this.setState({
                activeTab: 1,
                spinner: false,
              });
              this.props.updatePhotoControl("", 0, "landscape");
              this.setState({ spinner: false });
              return true;
            });
          }
        } catch (error) {
          this.setState({ spinner: false });
          console.log("Error uploading image...", error);
        }
      } else if (this.props.modalControl[0].element === "deleteArt" && this.props.modalControl[0].go === "go") {
        if (this.props.modalControl[0].extra) {
          try {
            this.setState({ spinner: true });
            deleteStudio(this.props.modalControl[0].extra).then((res) => {
              this.setState({
                activeTab: 0,
                spinner: false,
              });
              this.props.updatePhotoControl("", 0, "landscape");
              this.setState({ spinner: false });
              return true;
            });
          } catch (error) {
            this.setState({ spinner: false });
            console.log("Error uploading art...", error);
          }
        }
      }
    }
    return true;
  }

  // Elementos que irão compor o grupo de imagens que serão exibidas automáticamente.
  imagesShow = [
    // {
    //   search: "",
    //   label: "",
    // },
    {
      search: "art",
      label: i18n.t("coupon.supportmenu.imageshowlabel0"),
    },
    {
      search: "myGallery",
      label: i18n.t("coupon.supportmenu.imageshowlabel1"),
    },
    {
      search: "sticker",
      label: "Sticker", ///i18n.t("coupon.supportmenu.sticker")
    },
    { search: "search", label: i18n.t("coupon.supportmenu.imageshowlabel2") },
    { search: "pet", label: i18n.t("coupon.supportmenu.imageshowlabel3") },
    { search: "apparel", label: i18n.t("coupon.supportmenu.imageshowlabel4") },
    { search: "cup", label: i18n.t("coupon.supportmenu.imageshowlabel5") },
    {
      search: "business",
      label: i18n.t("coupon.supportmenu.imageshowlabel6"),
    },
    { search: "sales", label: i18n.t("coupon.supportmenu.imageshowlabel7") },
    { search: "products", label: i18n.t("coupon.supportmenu.imageshowlabel8") },
  ];

  // Nature
  // Wallpapers
  // Textures & Patterns
  // People
  // Business & Work
  // Travel
  // Technology
  // Animals
  // Interiors
  // Architecture
  // Food & Drink
  // Current Events
  // Athletics
  // Spirituality
  // Health & Wellness
  // Film
  // Fashion
  // Experimental
  // Arts & Culture
  // History

  // Administra a Busca
  handleSearch = () => {
    if (this.state.searchField.length > 2) {
      this.props.updatePhotoControl("", 0, "", this.state.searchField)
      this.setState({
        activeTab: 3, // A busca precisa ser sempre o segundo elemento nas tabs.
        searchField: "",
      });
    }
  };

  // Botão que muda o state da tab, para quando realizar a busca depois de ter clicado uma tab.
  tabClick = (index) => {
    if (index) {
      this.setState({
        activeTab: index, // A busca precisa ser sempre o segundo elemento nas tabs.
      });
    }
  };

  // Botão que muda as fotos de retrato para paisagem.
  handleChange = (event) => {
    if (event.target.checked == true) {
      // this.readAllImages('','portrait');
      this.props.updatePhotoControl("", 0, "portrait");
    } else {
      // this.readAllImages('','landscape');
      this.props.updatePhotoControl("", 0, "landscape");
    }
  };

  handleHighresolution = (event) => {
    if (event.target.checked) {
      this.props.updatePhotoControl("highResolution", 0, "");
    } else {
      this.props.updatePhotoControl("lowResolution", 0, "");
    }
  };

  handleUploadChange = (event) => {
    this.setState({
      spinner: true,
    });

    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        saveImagemGallery("imagegallery", reader.result)
          .then((result) => {
            this.setState({
              activeTab: 1,
              spinner: false,
            });
            this.props.updatePhotoControl("", 0, "landscape");
          })
          .catch((error) => {
            this.setState({
              spinner: false,
            });
            this.showNotify(error.response.data, "error");
          });
      };
    }
  };

  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "top", offset: "0 200" },
        closeOnClick: true,
      },
      type,
      3000
    );
  }

  render() {
    let allPhotoCards;
    let allPhotoCardsTabs;
    let className = "tab-pane";

    if (this.state.spinner == true) {
      return <Spinner />;
    } else {
      allPhotoCardsTabs = this.imagesShow.map((imageArray, index) => {
        return (
          <li key={index} className="nav-item">
            <a
              id={index}
              href={"#" + imageArray.label.toString()}
              data-toggle="tab"
              onClick={() => {
                this.tabClick(index);
              }}
              aria-expanded={index == this.state.activeTab ? true : false}
              className={
                index == this.state.activeTab ? "nav-link active" : "nav-link"
              }
            >
              <span className="d-none d-lg-block">{imageArray.label}</span>
            </a>
          </li>
        );
      });

      allPhotoCards = this.imagesShow.map((label, index) => {
        return (
          <div
            key={index}
            className={
              className + (index == this.state.activeTab ? " show active" : "")
            }
            id={label.label.toString()}
          >
            <div
              className="form-elegant scrollbar-light-blue"
            // style={{minHeight:'330px'}}
            >
              <div>
                <PhotoCards
                  key={index}
                  label={label.label}
                  search={label.search}
                  linkId={label.label.toString()}
                  isActive={index == this.state.activeTab ? true : false}
                />
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div>
        <div className="row container">
          <div className="custom-control custom-switch">
            <input
              id="ckb_portrait"
              type="checkbox"
              className="custom-control-input"
              onChange={this.handleChange}
            />
            <label
              title={i18n.t("coupon.supportmenu.icontextlandscape")}
              className="custom-control-label red"
              style={{
                paddingRight: "25px",
                fontSize: "14px",
                color: "green",
                paddingTop: "2px",
              }}
              htmlFor="ckb_portrait"
            >
              <FontAwesomeIcon icon={faPortrait} color="green" />
            </label>
          </div>
          <div className="custom-control custom-switch">
            <input
              id="ckb_highresolution"
              type="checkbox"
              className="custom-control-input"
              onChange={this.handleHighresolution}
            />
            <label
              title={i18n.t("coupon.supportmenu.icontexthighresolution")}
              className="custom-control-label red"
              style={{ paddingRight: "35px", fontSize: "24px", color: "green" }}
              htmlFor="ckb_highresolution"
            >
              <span
                className="mdi mdi-quality-high"
                style={{
                  lineHeight: "0",
                  position: "absolute",
                  paddingTop: "1px",
                }}
              >
                <svg id="SvgjsSvg1072" width="22" height="22" version="1.1">
                  <defs id="SvgjsDefs1073"></defs>
                  <g id="SvgjsG1074">
                    <svg
                      width="22"
                      height="22"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#008000"
                        d="M14.5 13.5h2v-3h-2M18 14a1 1 0 0 1-1 1h-.75v1.5h-1.5V15H14a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1m-7 5H9.5v-2h-2v2H6V9h1.5v2.5h2V9H11m8-5H5c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"
                        className="color626262 svgShape"
                      ></path>
                    </svg>
                  </g>
                </svg>
              </span>
            </label>
            <a
              title={i18n.t("coupon.supportmenu.icontextbackgroundremove")}
              id="btn_removebg_"
              style={{ padding: "3px", cursor: "pointer" }}
              data-toggle="modal"
              data-target="#remove-header-modal"
            >
              <FontAwesomeIcon icon={faMagic} color="green" />
            </a>

            <Modal
              modalId="remove-header-modal"
              title={i18n.t("coupon.supportmenu.modalbackgroundremovetitle")}
              h5Content={i18n.t("coupon.supportmenu.modalbackgroundremoveh5")}
              content=""
              cancelText={i18n.t(
                "coupon.supportmenu.modalbackgroundremovebtncancel"
              )}
              okText={i18n.t("coupon.supportmenu.modalbackgroundremovebtnok")}
              actionType="removeBackGround"
              credit="block"
            />
            <Modal
              modalId="remove-header-modal2"
              title={i18n.t("coupon.supportmenu.modalimagegallerytitle")}
              h5Content={i18n.t(
                "coupon.supportmenu.modalimagegalleryh5Content"
              )}
              content={i18n.t("coupon.supportmenu.modalimagegallerycontent")}
              cancelText={i18n.t(
                "coupon.supportmenu.modalimagegallerycancelText"
              )}
              okText={i18n.t("coupon.supportmenu.modalimagegalleryokText")}
              actionType="go"
            />
            <Modal
              modalId="remove-header-modal3"
              title={i18n.t("coupon.supportmenu.modalartllerytitle")}
              h5Content={i18n.t(
                "coupon.supportmenu.modalartlleryh5Content"
              )}
              content={i18n.t("coupon.supportmenu.modalartllerycontent")}
              cancelText={i18n.t(
                "coupon.supportmenu.modalartllerycancelText"
              )}
              okText={i18n.t("coupon.supportmenu.modalartlleryokText")}
              actionType="go"
            />
          </div>
        </div>
        <div className="row container" style={{ paddingTop: "24px" }}>
          <div className="input-group mb-2">
            <input
              type="text"
              className="form-control"
              placeholder=""
              aria-label=""
              aria-describedby="basic-addon2"
              value={this.state.searchField}
              onChange={(event) => {
                this.setState({ searchField: event.target.value });
              }}
            />

            <div className="input-group-append" style={{ height: "38px" }}>
              <button
                className="btn btn-info"
                type="button"
                style={{ padding: "6px 6px" }}
                onClick={this.handleSearch}
              >
                {i18n.t("coupon.supportmenu.btnsearchtext")}
              </button>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-6">
            <a style={{ fontSize: "x-small", fontWeight: "bolder" }} href="https://unsplash.com/?utm_source=tooweze&utm_medium=referral" target="_blank">Photos by Unsplash</a>
          </div>
          <div className="col-6">
            <a style={{ fontSize: "x-small", fontWeight: "bolder" }} href="https://stipop.io/landing?utm_source=tooweze&utm_medium=referral" target="_blank">Stickers by stipop.io</a>
          </div>
        </div>
        <div
          className="row"
          style={{
            paddingLeft: "15px",
            paddingTop: "5px",
            paddingBottom: "0px",
            paddingRight: "7px",
          }}
        >
          <ul
            id="tabStore"
            className="tabbable nav nav-tabs mb-3 content"
            style={{ width: "97%" }}
          >
            {allPhotoCardsTabs}
          </ul>

          <div id="scrollableDiv" className="tab-content container">
            {allPhotoCards}
          </div>
        </div>

        <div
          className="input-group"
          style={{
            marginTop: "3px",
            position: "absolute",
            bottom: "5px",
            width: "88%",
            border: "10px solid rgba(0, 0, 0, 0.3)",
          }}
        >
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="inputGroupFile04"
              onChange={this.handleUploadChange}
            />
            <label className="custom-file-label" htmlFor="inputGroupFile04">
              {i18n.t("coupon.supportmenu.uploadimage")}
            </label>
          </div>
        </div>
      </div>
    );
  }
}

// mapStateToProps takes one args, "state" and that is the rootReducer/Store
function mapStateToProps(state) {
  // mapStateToProps returns an object, with:
  // property is the local prop name to this component
  // value will be the property in the root reducer... ie, piece of the store
  return {
    couponNavBarData: state.couponNavBar,
    modalControl: state.modalControl,
    photoControl: state.photoControl,
  };
}

// mapDispatchToProps is how we tie our component to the dispatch
// it take one args: dispatch
function mapDispatchToProps(dispatch) {
  // this function return bindActionCreators
  // and we hand bindActionCreators an object
  // each property will be a local prop
  // each value will be a function that is dispatch when run
  // second arg of bindActionCreators is the dispatch
  return bindActionCreators(
    {
      updatePhotoControl: updatePhotoControl,
    },
    dispatch
  );
}

// export default TopBar;
// connect take 2 args, the first one is a function that is going to map
// a piece of redux state to this components props
export default connect(mapStateToProps, mapDispatchToProps)(PhotoControl);
