import React, { Component } from "react";
import { Auth, registerAccessPage } from "../../apis/toowezeApi";
import { Link } from "react-router-dom";
import { i18n } from "../../translate/i18n";
import "./TopRightMenu.css";

class TopRightMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      accountId: "",
      contactsLimit: 0,
      createdAt: 0,
      email: "",
      isActive: false,
      isAdmin: false,
      isConfigured: false,
      isSuper: false,
      isVerify: false,
      name: "",
      planId: "",
      rootId: "",
      validTo: "",
      profilePic: "../images/avatar-1_.jpg",
    };
  }

  componentWillMount() {
    Auth(localStorage.getItem("tooweze_token"))
      .then((auth) => {
        this.setState({
          userName: auth.name,
          accountId: auth.accountId,
          contactsLimit: auth.contactsLimit,
          createdAt: auth.createdAt,
          email: auth.email,
          isActive: auth.isActive,
          isAdmin: auth.isAdmin,
          isConfigured: auth.isConfigured,
          isSuper: auth.isSuper,
          isVerify: auth.isVerify,
          name: auth.name,
          planId: auth.planId,
          rootId: auth.rootId,
          validTo: auth.validTo,
          profilePic: auth.profilePic
            ? auth.profilePic
            : "../images/avatar-1_.jpg",
        });
      })
      .catch((error) => {
        console.log("error", error)
        this.props.history.push("auth/login");
      });

    registerAccessPage(window.location.pathname).then(result => {
    }).catch(error => {
      console.log("Erro pegando a página", error);
    })

  }

  logOut = () => {
    this.props.history.push("auth/login");
  };

  render() {
    return (
      <div className="main">
        <div className="topbar shadow bg-white">
          <div className="topbarContainer alternative">
            <div className="col-md-12 col-xl-12 col-sm-12">
              <div className="row" style={{ marginLeft: "0px" }}>
                <div
                  className="col-4"
                  style={{ paddingTop: "13px", paddingLeft: "0px" }}
                >
                  <a
                    href="https://youtube.com.br"
                    target="_blank"
                    title={i18n.t("toprightmenu.videoclass")}
                    rel="noopener noreferrer"
                  >
                    <i>
                      <span className="badge badge-success alter">
                        <svg
                          className="svgStyle"
                          height="24px"
                          viewBox="-47 0 479 480"
                          width="24px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            className="pathStyle"
                            d="m24.507812 472h336c8.835938 0 16-7.164062 16-16v-432c0-8.835938-7.164062-16-16-16h-248v80c0 8.835938-7.164062 16-16 16h-88v352c0 8.835938 7.164063 16 16 16zm120-322.511719 138.511719 90.511719-138.511719 90.511719zm0 0"
                            fill="#9bc9ff"
                          />
                          <g fill="#1e81ce">
                            <path d="m360.507812 0h-248c-.175781 0-.335937.0898438-.511718.0976562-.625.0429688-1.242188.1562498-1.839844.3437498-.273438.085938-.535156.144532-.800781.253906-.835938.339844-1.613281.824219-2.289063 1.425782l-104 96c-.707031.6875-1.285156 1.5-1.695312 2.398437-.117188.261719-.21875.527344-.304688.800781-.261718.71875-.417968 1.46875-.460937 2.230469 0 .167969-.105469.320313-.105469.488281v351.960938c0 13.253906 10.746094 24 24 24h336.007812c13.253907 0 24-10.746094 24-24v-432c0-13.253906-10.746093-24-24-24zm-256 26.273438v61.726562c0 4.417969-3.582031 8-8 8h-67.535156zm264 429.726562c0 4.417969-3.582031 8-8 8h-336c-4.417968 0-8-3.582031-8-8v-344h80c13.253907 0 24-10.746094 24-24v-72h240c4.417969 0 8 3.582031 8 8zm0 0" />
                            <path d="m287.386719 233.304688-138.503907-90.503907c-2.457031-1.605469-5.597656-1.738281-8.183593-.339843-2.582031 1.398437-4.191407 4.097656-4.191407 7.035156v181.007812c0 2.9375 1.609376 5.636719 4.191407 7.035156 2.585937 1.398438 5.726562 1.265626 8.183593-.339843l138.503907-90.503907c2.261719-1.476562 3.625-3.996093 3.625-6.695312s-1.363281-5.21875-3.625-6.695312zm-134.878907 82.414062v-151.4375l115.878907 75.71875zm0 0" />
                          </g>
                        </svg>
                        {i18n.t("toprightmenu.videoclass")}
                      </span>
                    </i>
                  </a>
                </div>
                <div className="col-8">
                  <div className="row">
                    {/* <div className="col-6" style={{ paddingLeft: "30px" }}>
                      <div className="circular--portrait">
                        <img src={this.state.profilePic} alt="" />
                      </div>
                    </div> */}
                    <div className="col-12">
                      <ul className="list-unstyled topbar-right-menu float-right mb-0">
                        <li className="dropdown notification-list">
                          <a
                            className="nav-link dropdown-toggle nav-user arrow-none mr-0"
                            data-toggle="dropdown"
                            href=""
                            role="button"
                            aria-haspopup="false"
                            aria-expanded="false"
                          >
                            <span>
                              <span className="account-user-name">
                                {this.state.userName}
                              </span>
                              <br />
                              <span
                                className="account-position"
                                style={{ fontSize: "12px" }}
                              >
                                Administrador
                              </span>
                            </span>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                            <div className=" dropdown-header noti-title">
                              <h6 className="text-overflow m-0">
                                {i18n.t("toprightmenu.labelwelcome")}
                              </h6>
                            </div>
                            <Link to="account">
                              <span className="dropdown-item notify-item">
                                <i className="mdi mdi-account-circle mr-1"></i>
                                <span>{i18n.t("toprightmenu.lblaccount")}</span>
                              </span>
                            </Link>

                            <Link to="account?card=configurations">
                              <span className="dropdown-item notify-item">
                                <i className="mdi mdi-account-edit mr-1"></i>
                                <span>{i18n.t("toprightmenu.labelsenderemail")}</span>
                              </span>
                            </Link>
                            <Link to="account?card=configurations">
                              <span className="dropdown-item notify-item">
                                <i className="mdi mdi-lifebuoy mr-1"></i>
                                <span>
                                  {i18n.t("toprightmenu.labelconfigurations")}
                                </span>
                              </span>
                            </Link>
                            <a
                              id="logout"
                              className="dropdown-item notify-item"
                              onClick={this.logOut}
                            >
                              <i className="mdi mdi-logout mr-1"></i>
                              <span>{i18n.t("toprightmenu.labellogout")}</span>
                            </a>
                            <a
                              className=" dropdown-header noti-title nav-link dropdown-toggle"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Languages
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <a
                                className="dropdown-item dropdown-header noti-title"
                                onClick={() => {
                                  localStorage.setItem("i18nextLng", "en-US");
                                  window.location.reload();
                                }}
                              >
                                English - US
                              </a>
                              <a
                                className="dropdown-item dropdown-header noti-title"
                                onClick={() => {
                                  localStorage.setItem("i18nextLng", "pt-BR");
                                  window.location.reload();
                                }}
                              >
                                Portuguese Brazil
                              </a>
                              <a
                                className="dropdown-item dropdown-header noti-title"
                                onClick={() => {
                                  localStorage.setItem("i18nextLng", "es");
                                  window.location.reload();
                                }}
                              >
                                Spanish
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopRightMenu;
