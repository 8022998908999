// All reducers have 2 params :
// 1. Current State
// 2. Info that came from any action
const seedDate =
    [
        {
            selectedPhoto: ''
        }

    ];

export default (state = seedDate, action) => {

    if (action.type == 'updatePhotoCard') {
        const newState = [...state];
        newState[action.payload.index].selectedPhoto = action.payload.selectedPhoto;
        newState[action.payload.index].extra = action.payload.extra;
        return newState;
    } else {

        return state;

    }
}


