import axios from "axios";

export default async function getCities(state) {
  return new Promise((result, reject) => {
    /// Just a comment
    if (state) {
      return axios({
        method: "GET",
        url: `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`,
      })
        .then((res) => {
          result(res);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      // Erro Estado Obrigatório
      reject("Informar Estado é Obrigatório!");
    }
  });
}
