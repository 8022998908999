// this is the root reducer! it is the store manager for all the reducers 
// to make a rootReducer 
// 1. Get a method form redux, called combineReducers
import { combineReducers } from 'redux';

// 2 . get each individual reducers
import couponNavBarReducer from './couponNavBarReducer';
import canvasReducer from './canvasReducer';
import fontControlReducer from './fontControlReducer';
import updateScreenSizeReducer from './updateScreenSizeReducer';
import photoCardReducer from './photoCardReducer'
import photoControlReducer from './photoControlReducer';
import modalReducer from './modalReducer';
import viewCouponSupportReducer from './viewcouponSupportMenuReducer';
import viewLandingPageMenuReducer from './viewLandingPageMenuReducer'
import fontSizeReducer from './fontSizeReducer';
import couponCampaignsViewReducer from './couponCampaignsViewReducer';

// 3. call combinereducers and hand it an object
// each key in combineReducer will be a piece of state in the redux store
// each value, will be the value of that piece of state in the redux store

const rootreducer = combineReducers({
    couponNavBar: couponNavBarReducer,
    canvas: canvasReducer,
    fontControl: fontControlReducer,
    screenSize: updateScreenSizeReducer,
    selectedPhoto: photoCardReducer,
    photoControl: photoControlReducer,
    modalControl: modalReducer,
    viewCouponSupportReducer: viewCouponSupportReducer,
    viewLandingPageMenuReducer: viewLandingPageMenuReducer,
    fontSize: fontSizeReducer,
    couponCampaignsView: couponCampaignsViewReducer
});

export default rootreducer;