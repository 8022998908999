import React, { Component } from "react";
import { connect } from "react-redux";
import updateTopBar from "../../../actions/updateTopBar";
import { bindActionCreators } from "redux";

class StrokeSizeComponent extends Component {
  // Altera a fonte do Texto Selecionado
  strokeSizeChange(e) {
    this.props.updateTopBar("strokeSize", 0, e.target.value);
  }

  render() {
    const strokeSize = [
      {
        fontSize: "1",
      },
      {
        fontSize: "2",
      },
      {
        fontSize: "3",
      },
      {
        fontSize: "4",
      },
      {
        fontSize: "5",
      },
      {
        fontSize: "6",
      },
      {
        fontSize: "7",
      },
      {
        fontSize: "8",
      },
      {
        fontSize: "9",
      },
      {
        fontSize: "10",
      },
      {
        fontSize: "11",
      },
    ];

    const strokSizeElements = strokeSize.map((item, index) => {
      return (
        <option key={index} value={item.fontSize}>
          {item.fontSize}
        </option>
      );
    });

    return (
      <div id="1" style={{ marginLeft: "10px", marginTop: "-3px" }}>
        <select
          id="strokeSize"
          className="form-control"
          style={{ fontSize: "11px", width: "65px", fontWeight: "bold" }}
          onClick={(e) => this.strokeSizeChange(e)}
          onChange={(e) => this.strokeSizeChange(e)}
        >
          {strokSizeElements}
        </select>
      </div>
    );
  }
}

function mapStateToProps(state) {
  // mapStateToProps returns an object, with:
  // property is the local prop name to this component
  // value will be the property in the root reducer... ie, piece of the store
  return {
    couponNavBarData: state.couponNavBar,
    canvas: state.canvas,
    fontControl: state.fontControl,
  };
}

// mapDispatchToProps is how we tie our component to the dispatch
// it take one args: dispatch
function mapDispatchToProps(dispatch) {
  // this function return bindActionCreators
  // and we hand bindActionCreators an object
  // each property will be a local prop
  // each value will be a function that is dispatch when run
  // second arg of bindActionCreators is the dispatch
  return bindActionCreators(
    {
      updateTopBar: updateTopBar,
    },
    dispatch
  );
}

// export default FontControl;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StrokeSizeComponent);
