import React, { Component } from "react";
import { connect } from "react-redux";
import updateFontControl from "../../../actions/updateFontControl";
import { bindActionCreators } from "redux";

class FontControl extends Component {
  // Altera a fonte do Texto Selecionado
  textChange(e) {
    this.props.updateFontControl(e.target.value, 0);
  }

  render() {
    const fontFamily = [
      {
        fontName: "Arial Black",
        fontValue: "Arial Black",
      },

      {
        fontName: "Georgia",
        fontValue: "Georgia",
      },
      {
        fontName: "Palatino",
        fontValue: "Palatino",
      },
      {
        fontName: "Book Antiqua",
        fontValue: "Book Antiqua",
      },
      {
        fontName: "Times New Roman",
        fontValue: "Times New Roman",
      },
      {
        fontName: "Arial",
        fontValue: "Arial",
      },
      {
        fontName: "Helvetica",
        fontValue: "Helvetica",
      },
      {
        fontName: "Gadget",
        fontValue: "Gadget",
      },
      {
        fontName: "Comic Sans MS",
        fontValue: "Comic Sans MS",
      },
      {
        fontName: "cursive",
        fontValue: "cursive",
      },
      {
        fontName: "Impact",
        fontValue: "Impact",
      },
      {
        fontName: "Charcoal",
        fontValue: "Charcoal",
      },
      {
        fontName: "Lucida Sans Unicode",
        fontValue: "Lucida Sans Unicode",
      },
      {
        fontName: "Lucida Grande",
        fontValue: "Lucida Grande",
      },
      {
        fontName: "Tahoma",
        fontValue: "Tahoma",
      },
      {
        fontName: "Geneva",
        fontValue: "Geneva",
      },
      {
        fontName: "Trebuchet MS",
        fontValue: "Trebuchet MS",
      },
      {
        fontName: "Helvetica",
        fontValue: "Helvetica",
      },
      {
        fontName: "Verdana",
        fontValue: "Verdana",
      },
      {
        fontName: "Geneva",
        fontValue: "Geneva",
      },
      {
        fontName: "Monospace Fonts",
        fontValue: "Monospace Fonts",
      },
      {
        fontName: "Courier New",
        fontValue: "Courier New",
      },
      {
        fontName: "Courier",
        fontValue: "Courier",
      },
      {
        fontName: "Lucida Console",
        fontValue: "Lucida Console",
      },
      {
        fontName: "Monaco",
        fontValue: "Monaco",
      },
    ];

    const fontElements = fontFamily.map((item, index) => {
      return (
        <option key={index} value={item.fontValue}>
          {item.fontName}
        </option>
      );
    });

    return (
      <div id="1" style={{ marginLeft: "10px", marginTop: "-3px" }}>
        <select
          id="fontsfamily"
          className="form-control"
          style={{ fontSize: "11px", width: "100px", fontWeight: "400" }}
          onChange={(e) => this.textChange(e)}
        >
          {fontElements}
        </select>
      </div>
    );
  }
}

function mapStateToProps(state) {
  // mapStateToProps returns an object, with:
  // property is the local prop name to this component
  // value will be the property in the root reducer... ie, piece of the store
  return {
    couponNavBarData: state.couponNavBar,
    canvas: state.canvas,
    fontControl: state.fontControl,
  };
}

// mapDispatchToProps is how we tie our component to the dispatch
// it take one args: dispatch
function mapDispatchToProps(dispatch) {
  // this function return bindActionCreators
  // and we hand bindActionCreators an object
  // each property will be a local prop
  // each value will be a function that is dispatch when run
  // second arg of bindActionCreators is the dispatch
  return bindActionCreators(
    {
      updateFontControl: updateFontControl,
    },
    dispatch
  );
}

// export default FontControl;
export default connect(mapStateToProps, mapDispatchToProps)(FontControl);
