import React, { Component } from "react";
import { fabric } from "fabric";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import { urlAPI } from "../../../apis/apiBase";
import saveImagemGallery, {
  proceedSave,
  getCoupons,
  removebg,
  proceedSaveStudio,
  getStudio,
} from "../../../apis/toowezeApi";
import updatePhotoControl from "../../../actions/updatePhotoControl";
import { Button, TextBox } from "devextreme-react";
import jimp from "jimp";
import ScreenSize from "./ScreenSizes"
import "./CanvasComponent.css";
import queryString from "query-string";
import { Redirect } from "react-router";
import notify from "devextreme/ui/notify";
import { i18n } from "../../../translate/i18n";
import { translateOneWordToYourLanguage } from "../../../apis/awsSupport";
let mouseStatus;
let activeObject;
let objectType;
let canvasGlobal;
let Spinner;
let hLine; // Linha da grade horizontal
let vLine; // Linha da grade vertical
let tags = [
  <label key="label" className="showTags badge badge-info mt-0 mb-0 mr-1">
    {i18n.t("coupon.canvas.classification")}
  </label>,
];
let showTags;
let messageText;
let sizeRenderControl;
let teste;
let canvasWidth;

fabric.Object.prototype.noScaleCache = false;

class CanvasComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canvasWidth: "540", // 1080 / 2 => 540 (Medidas do Instagram)
      canvasHeight: "400", // 1350 / 2 => 675
      messageClass: "fade",
      messageText: "",
      studioId: "",
      guidelines: false,
      stroke: false,
      strokeSize: 1,
      isEmpty: true,
      labels: [],
      alterStatus: "",
      isCoupon: true
    };
  }

  // Ajuda a evitar o Render da página toda vez que há alguma mudança no State
  shouldComponentUpdate(nextProps, nextState) {

    // if (this.state !== nextState) {
    //   console.log("1");
    //   return true;
    // }

    // Evita renderizar cada vez que o estado é modificado
    if (this.props.couponNavBarData !== nextProps.couponNavBarData) {
      this.insertItemInCanvas();
      return true;
    }

    if (this.props.selectedPhoto !== nextProps.selectedPhoto) {
      this.addImage();
      this.handleWidth({ value: this.state.canvasWidth });
      this.handleHeight({ value: this.state.canvasHeight });
      return true;
    }

    if (this.props.modalControl !== nextProps.modalControl) {
      if (this.props.modalControl[0].go === "removeBackGround") {
        this.SpinnerOn(true, i18n.t("coupon.canvas.messagebackgroundremove"));
        this.removeBackGround()
          .then((res) => {
            if (res) {
              this.props.updatePhotoControl("getImagemGallery", 0, "");
              setTimeout(() => {
                this.SpinnerOn(false);
              }, 2000);
            }
          })
          .catch((error) => {
            this.showNotify(error, "error");
            this.SpinnerOn(false);
          });
        return true;
      }
    }

    if (this.props.screenSize !== nextProps.screenSize) {

      if (this.props.screenSize[0].screenSize.width && this.props.screenSize[0].screenSize.height) {
        if (this.props.screenSize[0].screenSize.width === "540" && this.props.screenSize[0].screenSize.height === "400") {
          this.setState({
            isCoupon: true
          });
        } else {
          this.setState({
            isCoupon: false
          });
        }
      }

      if (this.props.screenSize[0].screenSize.width) {
        this.handleWidth({ value: this.props.screenSize[0].screenSize.width });
      }
      if (this.props.screenSize[0].screenSize.height) {
        this.handleHeight({ value: this.props.screenSize[0].screenSize.height });
      }

      return true;

    }

    if (this.props.fontControl !== nextProps.fontControl) {
      this.changeText(this.props.fontControl[0].fontFamily);
      return true;
    }
    return true;
  }

  componentDidMount() {
    hLine = document.getElementById("hLine"); // Linha da grade horizontal
    vLine = document.getElementById("vLine"); // Linha da grade vertical
    // Spinner = document.getElementById("loading"); // Spinner
    showTags = document.getElementsByClassName("showTags");
    this.canvas = new fabric.Canvas("canvas", {});

    if (this.canvas) {
      // Não funcionou ainda como desejado
      // Busca o formato do Canvas no State
      // this.canvas.setDimensions({width:this.state.canvasWidth, height:this.state.canvasHeight});

      let mycanvas = this.canvas;
      canvasGlobal = mycanvas;

      this.canvas.on("mouse:down", function (options) {
        if (options.target) {
          activeObject = mycanvas.getActiveObject(); /// Atualizar esta propriedade permite saber qual objeto está ativo, para troca de fonte, cor ou deleção
          objectType = options.target.type;
        } else {
          activeObject = null;
        }
      });

      // Faz que quando clicar fora do Canvas, deselecione os elementos
      this.canvas.on("mouse:out", (e) => {
        mouseStatus = "OUT";
      });

      this.canvas.on("mouse:over", (e) => {
        mouseStatus = "OVER";
      });

      /// TECLADO
      document.addEventListener("keydown", function (zEvent) {
        if (
          (zEvent.ctrlKey && zEvent.key === "d") ||
          (zEvent.ctrlKey && zEvent.key === "D")
        ) {
          // Duplicar o elemento

          if (mycanvas.getActiveObject()) {
            var _clipboard;
            mycanvas.getActiveObject().clone(function (cloned) {
              _clipboard = cloned;
              if (_clipboard) {
                _clipboard.set("top", _clipboard.top + 25);
                _clipboard.set("left", _clipboard.left + 25);
                mycanvas.add(_clipboard);
                mycanvas.setActiveObject(_clipboard);
              }
            });
          }
        } else if (zEvent.key == "ArrowUp") {
          // Mover o objeto para cima

          if (mycanvas.getActiveObject()) {
            var _clipboard;
            _clipboard = mycanvas.getActiveObject();
            mycanvas.getActiveObject().set("top", _clipboard.top - 1);
            mycanvas.renderAll();
          }
        } else if (zEvent.key == "ArrowDown") {
          // Mover o objeto para baixo

          if (mycanvas.getActiveObject()) {
            var _clipboard;
            _clipboard = mycanvas.getActiveObject();
            mycanvas.getActiveObject().set("top", _clipboard.top + 1);
            mycanvas.renderAll();
          }
        } else if (zEvent.key == "ArrowLeft") {
          // Mover o objeto para a esquerda

          if (mycanvas.getActiveObject()) {
            var _clipboard;
            _clipboard = mycanvas.getActiveObject();
            mycanvas.getActiveObject().set("left", _clipboard.left - 1);
            mycanvas.renderAll();
          }
        } else if (zEvent.key == "ArrowRight") {
          // Mover o objeto para a direita

          if (mycanvas.getActiveObject()) {
            var _clipboard;
            _clipboard = mycanvas.getActiveObject();
            mycanvas.getActiveObject().set("left", _clipboard.left + 1);
            mycanvas.renderAll();
          }
        } else if (zEvent.key == "Delete") {
          // Deletar o objeto
          if (mycanvas.getActiveObjects()) {
            let activeObjects = mycanvas.getActiveObjects();
            if (activeObjects.length) {
              activeObjects.forEach(function (object) {
                if (object) {
                  mycanvas.remove(object);
                }
              });
            }
          }
        }
      });

      document
        .getElementById("canvasArea")
        .addEventListener("click", function (event) {
          if (mouseStatus != "OVER") {
            activeObject = null;
            mycanvas.discardActiveObject();
            mycanvas.renderAll();
          }
        });

      // Com isso habilitado, sempre que sai da página, pergunta se desejar salvar antes
      // window.onbeforeunload = function (e) {
      //     // Cancel the event
      //     e.preventDefault();
      //     // Chrome requires returnValue to be set
      //     e.returnValue = '';
      // };

      //Prevent Ctrl+S (and Ctrl+W for old browsers and Edge)
      document.onkeydown = function (e) {
        e = e || window.event; //Get event

        if (!e.ctrlKey) return;

        var code = e.which || e.keyCode; //Get key code

        switch (code) {
          case 83: //Block Ctrl+S
          case 87: //Block Ctrl+W -- Not work in Chrome and new Firefox
          case 68:
            e.preventDefault();
            e.stopPropagation();
            break;
        }
      };
      /// TECLADO
    }

    // Alterar o tamanho do canvas dinâmicamente.
    // https://stackoverflow.com/questions/42990609/objects-cannot-be-selected-after-canvas-is-resized
    // https://github.com/fabricjs/fabric.js/wiki/When-to-call-setCoords
    // MOVER GRID HORIZONTAL
    const limit = canvasGlobal._offset.top;
    hLine.onmousedown = function (event) {
      let shiftY = event.clientY - hLine.getBoundingClientRect().top;

      hLine.style.left = canvasGlobal._offset.left + "px";

      hLine.style.zIndex = 1000;
      document.body.append(hLine);

      moveAtY(event.pageX, event.pageY); // moves the hLine at (pageX, pageY) coordinates
      // taking initial shifts into account

      function moveAtY(pageX, pageY) {
        hLine.style.top = pageY - shiftY + "px";

        // Evita sair da borda
        if (pageY - shiftY < limit + 1) {
          hLine.style.top = `'${limit}px'`;
          document.removeEventListener("mousemove", onMouseMoveY);
          hLine.onmouseup = null;
        }
      }

      function onMouseMoveY(event) {
        moveAtY(event.pageX, event.pageY);
        //}
      } // move the hLine on mousemove

      document.addEventListener("mousemove", onMouseMoveY); // drop the hLine, remove unneeded handlers

      hLine.onmouseup = function () {
        document.removeEventListener("mousemove", onMouseMoveY);
        hLine.onmouseup = null;
      };
    };

    hLine.ondragstart = function () {
      return false;
    };

    // MOVER GRID HORIZONTAL

    // MOVER GRID VERTICAL
    const limitVline = canvasGlobal._offset.left;
    vLine.onmousedown = function (event) {
      let shiftX = event.clientX - vLine.getBoundingClientRect().left;
      vLine.style.top = canvasGlobal._offset.top + "px";

      vLine.style.zIndex = 1000;
      document.body.append(vLine);
      moveAt(event.pageX, event.pageY); // moves the vLine at (pageX, pageY) coordinates
      // taking initial shifts into account

      function moveAt(pageX, pageY) {
        vLine.style.left = pageX - shiftX + "px";
        // vLine.style.top = pageY - shiftY + 'px';

        // Evita sair da borda
        if (pageX - shiftX < limitVline + 1) {
          vLine.style.left = `'${limitVline}px'`;
          document.removeEventListener("mousemove", onMouseMove);
          vLine.onmouseup = null;
        }
      }

      function onMouseMove(event) {
        moveAt(event.pageX, event.pageY);
      } // move the vLine on mousemove

      document.addEventListener("mousemove", onMouseMove); // drop the vLine, remove unneeded handlers

      vLine.onmouseup = function () {
        document.removeEventListener("mousemove", onMouseMove);
        vLine.onmouseup = null;
      };
    };

    vLine.ondragstart = function () {
      return false;
    };

    // MOVER GRID VERTICAL

    if (this.state.guidelines == true) {
      hLine.style.display = "block";
      vLine.style.display = "block";
    } else {
      hLine.style.display = "none";
      vLine.style.display = "none";
    }

    // Checa se há um id na URL para editar o canvas do cupom
    this.checkIfIsEdition();
  }

  // Desmontando o DOM a página, eliminar os grids.
  componentWillUnmount() {
    let hLine = document.getElementById("hLine"); // Linha da grade horizontal
    let vLine = document.getElementById("vLine"); // Linha da grade vertical

    if (hLine) {
      hLine.style.display = "none";
    }

    if (vLine) {
      vLine.style.display = "none";
    }
  }

  // Com o id da URL busca o Cupom para editar
  checkIfIsEdition() {
    let url = window.location.search;
    let params = queryString.parse(url);
    if (params.id) {
      getCoupons(params.id, true).then((res) => {
        this.canvas.clear();
        let isEmpty = true;
        if (res.canvas && res.canvas != "<empty>") {
          this.canvas.loadFromJSON(res.canvas);
          isEmpty = false;
        }
        this.setState({
          studioId: params.id,
          isEmpty: isEmpty,
        }); // Passa o cupom para o Canvas e sinaliza de que o mesmo não está vazio, para não impedir de gravar.
        if (res?.labels.length > 0) {
          this.createTags(res.labels).then(tagsresult => {
            this.setState({
              tags: tagsresult
            })
          }).catch(err => console.log("tags Error", err))
        }
      });
    }
  }

  reDrawCanvas(screenSize, type) {
    // let newCanva = document.getElementById("canvas");
    // newCanva.style = "width: 900px";
    // this.setState({ canvasWidth: "900px" });
    // Instragram Size 1.200 px por 566 px

    // console.log("screenSize", screenSize)


    // this.setState({
    //   alterStatus: "Alterado"
    // })

    this.canvas.discardActiveObject();

    if (type === "canvasWidth") {
      this.canvas.setDimensions({ width: screenSize.width });
    } else if (type === "canvasHeight") {
      this.canvas.setDimensions({ height: screenSize.height });
    }

    this.canvas.calcOffset();
    this.canvas.renderAll();

    // if (screenSize.width == "540" && screenSize.height == "400") {
    //   console.log("Sim cupom")
    //   this.setState({
    //     isCoupon: true,
    //   });
    // } else {
    //   console.log("Não cupom")
    //   this.setState({
    //     isCoupon: false,
    //   });
    // }

  }

  // Criar o link e faz o download da imagem
  imageProcessDowload = () => {
    if (!this.state.isCoupon) {
      let image = this.canvas.toDataURL({
        format: 'jpeg',
        quality: 1
      });
      if (image) {
        var link = document.createElement("a");
        var fileName = "toowezeStudio";
        fileName = fileName.replace(/ /g, "_");
        link.href = image;
        link.download = "toowezeStudio" + new Date().getMilliseconds() + ".jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  // Converte uma imagem em URL para Blob para poder fazer o download
  readImage = (image) => {
    return new Promise((resolve, reject) => {
      if (image) {
        jimp.read(image + "?auto=compress&cs=tinysrgb&h=350").then((image) => {
          const result = image.getBase64Async(jimp.MIME_JPEG, (err, res) => { });
          result.then((blogSrc) => { // Resultado da imagem em Blob
            resolve(blogSrc);
          });
        }).catch((error) => {
          reject(error);
        });
      }
    });
  };

  reDrawCanvas2(screenSize) {
    let newWidth = screenSize.width;
    let canvas = this.canvas = new fabric.Canvas("canvas", {});
    if (canvas.width != newWidth) {
      var scaleMultiplier = newWidth / canvas.width;
      var objects = canvas.getObjects();
      for (var i in objects) {
        objects[i].scaleX = objects[i].scaleX * scaleMultiplier;
        objects[i].scaleY = objects[i].scaleY * scaleMultiplier;
        objects[i].left = objects[i].left * scaleMultiplier;
        objects[i].top = objects[i].top * scaleMultiplier;
        objects[i].setCoords();
      }
      var obj = canvas.backgroundImage;
      if (obj) {
        obj.scaleX = obj.scaleX * scaleMultiplier;
        obj.scaleY = obj.scaleY * scaleMultiplier;
      }

      canvas.discardActiveObject();
      this.canvas.setDimensions({ width: canvas.getWidth() * scaleMultiplier, height: canvas.getWidth() * scaleMultiplier });
      // canvas.setWidth(canvas.getWidth() * scaleMultiplier);
      // canvas.setHeight(canvas.getWidth() * scaleMultiplier);
      canvas.renderAll();
      canvas.calcOffset();
    }
  }


  // Funciona em partes, mas não conseguir fazer funcionar ainda
  // Atualiza o tamanho do Canvas
  // reDrawCanvas() {

  //   console.log("canvasWidth");

  //   let newCanvasDim = {
  //     width: this.state.canvasWidth,
  //     height: this.state.canvasHeight,
  //   };

  //   canvasGlobal.getObjects().map(function (o) {
  //     // calculate factors
  //     var factorW = newCanvasDim.width / o.canvas.width;
  //     var factorH = newCanvasDim.height / o.canvas.height;

  //     // calculate new position
  //     var left = o.left * factorW;
  //     var top = o.top * factorH;
  //     o.set("left", left);
  //     // o.setCoords();
  //     o.set("top", top);
  //     // o.setCoords();
  //     // o.setLeft(left);
  //     // o.setTop(top);

  //     // calculate new size
  //     var width = o.width * factorW;
  //     var height = o.height * factorH;
  //     o.set("width", width);
  //     // o.setCoords();
  //     o.set("height", height);
  //     // o.setCoords();
  //     // o.setWidth(width);
  //     // o.setHeight(height);

  //     canvasGlobal.renderAll();
  //     // canvasGlobal.setCoords();
  //     // canvasGlobal.requestRenderAll();
  //   });

  //   canvasGlobal = canvasGlobal.setDimensions({
  //     width: this.state.canvasWidth,
  //     height: this.state.canvasHeight,
  //   });
  //   // canvasGlobal.renderAll();

  //   this.canvas.renderAll.bind(canvasGlobal);
  //   // canvas.setWidth( <desired width> );
  //   // canvas.setHeight( <desired height> );
  //   canvasGlobal.calcOffset();
  // }

  // Não consegui ainda fazer funcionar
  // Modifica de Landscape para Portrait e vice-versa.
  // handleChangePageOrientation(button) {
  //   if (button.target.checked) {
  //     this.setState({
  //       canvasWidth: "800", // 1080
  //       canvasHeight: "650", // 1350
  //     });
  //   } else {
  //     // this.canvas.setDimensions({width:'800', height:'650'});
  //     this.setState({
  //       canvasWidth: "540", // 1080
  //       canvasHeight: "675", // 1350
  //     });
  //   }
  // }

  // Componentes do TopBar
  // Tras este elemento um passo para a frente
  bringForward() {
    if (this.canvas) {
      if (activeObject) {
        this.canvas.bringForward(activeObject);
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
      }
    }
  }

  // Tras este elemento todo para frente
  bringToFront() {
    if (this.canvas) {
      if (activeObject) {
        this.canvas.bringToFront(activeObject);
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
      }
    }
  }

  // Envia para trás de tudo
  sendBackwards() {
    if (this.canvas) {
      if (activeObject) {
        this.canvas.sendBackwards(activeObject);
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
      }
    }
  }

  // Envia um para trás
  sendToBack() {
    if (this.canvas) {
      if (activeObject) {
        this.canvas.sendToBack(activeObject);
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
      }
    }
  }

  SpinnerOn(on, message) {
    if (on == true) {
      // Spinner.style.display = "block";
      // Spinner.innerHtml = "block";

      Array.from(showTags).forEach((el) => {
        el.style.display = "none";
      });

      if (message) {
        this.setState({
          messageClass: "show",
          messageText: message,
        });
      }
    } else {
      // Spinner.style.display = "none";
    }

    setTimeout(() => {
      // Spinner.style.display = "none";
      this.setState({
        messageClass: "fade",
        messageText: "",
      });
      Array.from(showTags).forEach((el) => {
        el.style.display = "inline";
      });
    }, 3000);
  }

  //Cria um Retângulo
  createRect() {
    if (this.canvas) {
      let rect = new fabric.Rect({
        width: 200,
        height: 100,
        strokeWidth: this.state.strokeSize,
        fill: null,
        stroke: "red",
        noScaleCache: true,
        strokeUniform: true,
      });
      this.canvas.add(rect);
      this.canvas.setActiveObject(rect);
      this.customizeHandler(this.canvas);
    }
  }

  //Cria um quadrado
  createSquare() {
    if (this.canvas) {
      let rect = new fabric.Rect({
        width: 200,
        height: 200,
        fill: null,
        strokeWidth: this.state.strokeSize,
        stroke: "black",
      });
      this.canvas.setActiveObject(rect);
      this.canvas.add(rect);
      this.customizeHandler(this.canvas);
      objectType = this.canvas.getActiveObject().get("type");
    }
  }

  // Cria um círculo
  createCircle() {
    var circle = new fabric.Circle({
      radius: 100,
      fill: null,
      strokeWidth: this.state.strokeSize,
      stroke: "black",
      left: 100,
      top: 100,
    });
    this.canvas.setActiveObject(circle);
    this.canvas.add(circle);
    this.customizeHandler(this.canvas);
    objectType = this.canvas.getActiveObject().get("type");
  }

  // Cria um Triângulo
  createTriangle() {
    if (this.canvas) {
      var triangle = new fabric.Triangle({
        width: 200,
        height: 300,
        strokeWidth: this.state.strokeSize,
        fill: null,
        stroke: "black",
        left: 50,
        top: 50,
      });
      this.canvas.setActiveObject(triangle);
      this.canvas.add(triangle);
      this.customizeHandler(this.canvas);
      objectType = this.canvas.getActiveObject().get("type");
    }
  }

  //text
  insertText(fontFamily) {
    if (this.canvas) {
      var text = new fabric.IText(i18n.t("coupon.canvas.textlabel"), {
        fontFamily: fontFamily ? fontFamily : "Arial Black",
        left: 100,
        top: 100,
        fontSize:
          this.props.fontSize[0].fontSize != ""
            ? this.props.fontSize[0].fontSize
            : 72,
      });
      this.canvas.setActiveObject(text);
      this.canvas.add(text);
      this.customizeHandler(this.canvas);
      objectType = this.canvas.getActiveObject().get("type");
    }
  }

  // Muda a fonte do texto selecionado
  changeText(fontFamily) {
    if (this.canvas) {
      if (objectType == "i-text") {
        if (activeObject) {
          activeObject.set({ fontFamily: fontFamily });
          this.canvas.renderAll();
        }
      }
    }
  }

  // Altera o tamanho da fonte
  updateFontSize(fontSize) {
    if (this.canvas) {
      if (objectType == "i-text") {
        if (activeObject) {
          activeObject.set({ fontSize: fontSize });
          this.canvas.renderAll();
        }
      }
    }
  }

  // Altera o tamanho da borda.
  updateStrokeSize() {
    if (this.canvas) {
      if (activeObject) {
        activeObject.set({
          strokeWidth: this.state.strokeSize,
          strokeUniform: true,
        });
        this.canvas.renderAll();
        // this.canvas.requestRenderAll();
      }
    }
  }

  createTags = async (labels) => {
    return new Promise((resolve, reject) => {
      if (labels.length > 0) {
        let count = labels.length;
        tags = [];
        labels.forEach((item, index) => {
          if (item) {
            translateOneWordToYourLanguage(item).then((result) => {
              tags.push(
                <span
                  key={index}
                  className="showTags badge badge-primary mt-0 mb-0 mr-1"
                  style={{
                    display:
                      this.state.messageClass === "show" ? "none" : "inline",
                  }}
                >
                  {result}
                </span>
              );

              count = count - 1;
              if (count === 0) {
                resolve(tags)
              }
            });
          }
        });
      }
    })
  }

  // Gravar ou atualiza o Cupom
  saveCoupon = () => {
    if (this.state.isEmpty == false) {
      this.SpinnerOn(true, i18n.t("coupon.canvas.messagesavecoupon"));
      if (this.canvas) {
        // Cria o objeto cupom para gravar.
        let coupon = {
          title: "title not set",
          description: "description not set",
          originalPrice: "0",
          discountPrice: "0",
          discount: "0",
          minimumPurchase: "0",
          initDate: "0",
          endDate: "0",
          email: "0",
          condiction: "condiction not set",
          status: 1,
          image: this.canvas.toDataURL(),
          canvas: JSON.stringify(this.canvas),
        };

        if (this.state.studioId != "" && this.state.studioId.length > 0) {
          // Atualiza o Cupom Atual
          proceedSave(coupon, this.state.studioId)
            .then((res) => {
              this.SpinnerOn(false);

              if (res) {
                // Atualiza o id no estado, para validar o botão de configurar o cupom.
                this.setState({
                  studioId: res.data._id,
                });

                const _id = res.data._id;

                setTimeout(() => {
                  getCoupons(_id, true).then((res) => {
                    //Tags
                    if (res?.labels.length > 0) {
                      this.createTags(res.labels).then(tagsresult => {
                        this.setState({
                          tags: tagsresult
                        })
                      }).catch(err => console.log("tags Error", err))
                    }
                  });
                }, 3000);

                this.SpinnerOn(
                  true,
                  i18n.t("coupon.canvas.messageupdatecoupon")
                );
                setTimeout(() => {
                  this.SpinnerOn(false);
                }, 500);
              }
            })
            .catch((error) => {
              this.SpinnerOn(false, error.response.data);
            });
        } else {
          // Grava um novo Cupom
          proceedSave(coupon)
            .then((res) => {
              // Atualiza o id no estado, para validar o botão de configurar o cupom.
              if (res) {
                this.setState({
                  studioId: res.data._id,
                });

                const _id = res.data._id;

                //Tags
                setTimeout(() => {
                  getCoupons(_id, true).then((res) => {
                    //Tags
                    if (res?.labels.length > 0) {
                      this.createTags(res.labels).then(tagsresult => {
                        this.setState({
                          tags: tagsresult
                        })
                      }).catch(err => console.log("tags Error", err))
                    }
                  });
                }, 2000);

                this.SpinnerOn(
                  true,
                  i18n.t("coupon.canvas.messagesavecouponresult")
                );
                setTimeout(() => {
                  this.SpinnerOn(false);
                }, 5000);
              }
            })
            .catch((error) => {
              this.SpinnerOn(false, error.response.data);
            });
        }
      }
    } else {
      this.SpinnerOn(true, i18n.t("coupon.canvas.errormessagesavecoupon"));
    }
  };

  // Gravar ou atualiza Arte
  saveArt = () => {
    this.SpinnerOn(true, i18n.t("coupon.canvas.messagesaveart"));

    if (this.canvas) {
      // Cria o objeto cupom para gravar.

      let studio = {
        image: this.canvas.toDataURL(),
        canvas: JSON.stringify(this.canvas),
        width: this.state.canvasWidth,
        height: this.state.canvasHeight
      };

      let checkCanvas = JSON.parse(studio.canvas);

      if (checkCanvas.objects.length > 0) {
        if (this.state.studioId !== "" && this.state.studioId.length > 0) {
          // Atualiza
          proceedSaveStudio(studio, this.state.studioId)
            .then((res) => {
              this.SpinnerOn(false);

              if (res) {
                // Atualiza o id no estado
                this.setState({
                  studioId: res.data._id,
                });

                const _id = res.data._id;

                setTimeout(() => {
                  getStudio(_id, true).then((res) => {
                    //Tags
                    if (res?.labels.length > 0) {
                      this.createTags(res.labels).then(tagsresult => {
                        this.setState({
                          tags: tagsresult
                        })
                      }).catch(err => console.log("tags Error", err))
                    }
                    this.props.updatePhotoControl("art", 0, "landscape");
                  });
                }, 1000);

                this.SpinnerOn(
                  true,
                  i18n.t("coupon.canvas.messageupdateart")
                );
                setTimeout(() => {
                  this.SpinnerOn(false);
                }, 500);
              }
            })
            .catch((error) => {
              console.log("canvas error", error);
              this.SpinnerOn(false, error?.response?.data);
            });
        } else {
          // Grava uma arte.
          proceedSaveStudio(studio)
            .then((res) => {
              // Atualiza o id no estado, para validar o botão de configurar o cupom.
              if (res) {
                this.setState({
                  studioId: res.data._id,
                });

                const _id = res.data._id;

                //Tags
                setTimeout(() => {
                  getStudio(_id, true).then((res) => {
                    //Tags
                    if (res?.labels.length > 0) {
                      this.createTags(res.labels).then(tagsresult => {
                        this.setState({
                          tags: tagsresult
                        })
                      }).catch(err => console.log("tags Error", err))
                    }

                    this.props.updatePhotoControl("art", 0, "landscape");
                  });
                }, 1000);

                this.SpinnerOn(
                  true,
                  i18n.t("coupon.canvas.messagesaveartresult")
                );
                setTimeout(() => {
                  this.SpinnerOn(false);
                }, 5000);
              }
            })
            .catch((error) => {
              console.log("canvas error", error.message);
              this.SpinnerOn(false, error?.response?.data);
            });
        }
      } else {
        // Vazio
        this.SpinnerOn(true, i18n.t("coupon.canvas.errormessagesaveart"));
      }
    } else {
      this.SpinnerOn(true, i18n.t("coupon.canvas.errormessagesaveart"));
    }
  };

  //Deleta o item selecionado
  delete() {
    if (this.canvas) {
      let myCanvas2 = this.canvas;
      let activeObjects = myCanvas2.getActiveObjects();
      if (activeObjects.length) {
        activeObjects.forEach(function (object) {
          if (object) {
            myCanvas2.remove(object);
          }
        });
      } else {
        console.log("Please select the drawing to delete");
      }
    }
  }

  // Altera a cor, borda ou cor de fundo
  changeColor(newColor) {
    if (this.canvas) {
      if (activeObject) {
        if (this.state.stroke == false) {
          // Cor do objeto
          activeObject.set({ fill: newColor });
        } else if (this.state.stroke == true) {
          // Cor do contorno do objeto
          activeObject.set({ stroke: newColor });
        }
      } else {
        this.canvas.backgroundColor = newColor;
      }

      this.canvas.renderAll();
    }
  }

  // Duplica um objeto
  duplicate() {
    var object = fabric.util.object.clone(this.canvas.getActiveObject());
    object.set("top", object.top + 5);
    object.set("left", object.left + 5);
    this.canvas.add(object);
  }

  // Altera os Handles para algo mais customizado.
  customizeHandler(mycanvas) {
    mycanvas.getActiveObject().set({
      borderColor: "red",
      cornerColor: "#755ce6",
      cornerSize: 8,
      transparentCorners: false,
      // selectable: true
    });

    activeObject = mycanvas.getActiveObject();

    mycanvas.renderAll();
  }

  // Deseleciona os elementos quando clicar fora da área do Canvas.
  // readArea(e) {
  //     if (mouseStatus != 'OVER') {
  //         this.canvas.discardActiveObject()
  //     }
  // }

  // Adiciona uma imagem na galeria.
  addImage() {

    if (this.props?.selectedPhoto[0]) {
      if (this.props?.selectedPhoto[0]?.extra) {
        this.SpinnerOn(true, i18n.t("coupon.canvas.messageaddingart"));
        /// Adding Canva
        getStudio(this.props.selectedPhoto[0].extra, true).then(res => {

          this.canvas.clear();
          if (res.canvas && res.canvas !== "<empty>") {
            this.canvas.loadFromJSON(res.canvas);
          }
          this.setState({
            studioId: res._id,
            isCoupon: false,
            canvasWidth: res.width,
            canvasHeight: res.height,
          });

          if (res?.labels.length > 0) {
            this.createTags(res.labels).then(tagsresult => {
              this.setState({
                tags: tagsresult
              })
            }).catch(err => console.log("tags Error", err))
          }

          this.handleWidth({ value: res.width });
          this.handleHeight({ value: res.height });

          this.SpinnerOn(false);
        }).catch(err => {
          this.SpinnerOn(false);
        });

      } else {
        /// Adding image

        this.SpinnerOn(true, i18n.t("coupon.canvas.messageaddingimage"));

        try {
          if (this.canvas) {
            let photoUrl = this.props.selectedPhoto[0].selectedPhoto;

            if (photoUrl?.includes("?")) {
              photoUrl = photoUrl + "&" + new Date().getTime(); // Resolve o problema de cache do chrome
            } else {
              photoUrl = photoUrl + "?" + new Date().getTime(); // Resolve o problema de cache do chrome
            }

            let e = photoUrl;
            var img = new Image();
            let imageString = e;

            if (
              this.props.selectedPhoto[0].selectedPhoto
                .toString()
                .includes("stipop")
            ) {
              axios({
                method: "GET",
                url: `${urlAPI.stickerprovider}/${encodeURIComponent(
                  this.props.selectedPhoto[0].selectedPhoto.toString()
                )}`,
                headers: {
                  "x-auth-token": localStorage.getItem("tooweze_token"),
                },
              })
                .then((res) => {
                  img.src = "data:image/jpeg;base64," + res.data;
                })
                .catch((error) => {
                  console.log("error", error);
                });
            } else {

              var request = new XMLHttpRequest();
              request.open("GET", imageString, true);
              request.responseType = "blob";
              request.onload = function () {
                var reader = new FileReader();
                reader.readAsDataURL(request.response);
                reader.onload = function (e) {
                  imageString = e.target.result;
                  img.src = imageString;
                };
              };

              request.send();
            }

            let mycanvas = this.canvas;

            img.onload = () => {
              var imgElement = img;

              var imgInstance = new fabric.Image(imgElement, {
                left: 2,
                top: 2,
                right: 2,
                bottom: 2,
              });

              mycanvas.setActiveObject(imgInstance.scale(0.8)); // Scale reduz um pouco o tamanho da imagem para caber totalmente dentro do Canvas.
              mycanvas.add(imgInstance);

              mycanvas.getActiveObject().set({
                borderColor: "red",
                cornerColor: "#755ce6",
                cornerSize: 8,
                transparentCorners: false,
              });

              objectType = "image";

              mycanvas.renderAll();

            };
            this.SpinnerOn(false, "");
          }
        } catch (error) {
          this.SpinnerOn(false);
          console.log("error", error);
        }
      }
    } {
      // Erro
      this.SpinnerOn(false);
    }

  }


  // Faz aparecer uma mensagem
  showNotify = (message, type) => {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "top", offset: "0 200" },
        closeOnClick: true,
      },
      type,
      3000
    );
  };

  // Remove o fundo da imagem.
  removeBackGround() {
    /// Checar aqui se tem crédito:

    if (this.canvas) {
      let mycanvas = this.canvas;
      activeObject = mycanvas.getActiveObject();

      return new Promise(function (resolve, reject) {
        if (objectType == "image" && activeObject) {
          let imageURL = activeObject._element.src;
          let type;

          if (imageURL.includes("http")) {
            type = "url";
          } else {
            type = "blob";
          }

          // Função de Remover o fundo da imagem.
          removebg(imageURL, type)
            .then((res) => {
              /// REMOVER 1 CRÉDITO AQUI

              let imageedited = res?.data?.result_b64;

              if (imageedited?.includes('{"data":{"result_b64":"')) {
                imageedited = imageedited?.replace(
                  '{"data":{"result_b64":"',
                  ""
                );
              }

              imageedited = imageedited.replace(/\"/g, "");
              imageedited = imageedited.replace(/\}/g, "");
              imageedited = imageedited.replace(/\{/g, "");
              imageedited = imageedited.replace(/\=/g, "");

              var img = new Image();
              img.src = "data:image/png;base64," + imageedited;
              let imagemSource = img.src;

              img.onload = function () {
                var imgElement = img;
                var imgInstance = new fabric.Image(imgElement, {});

                mycanvas.add(imgInstance);
                mycanvas.remove(activeObject);

                // Grava no banco de dados a imagem com fundo removido.
                saveImagemGallery("imagegallery", imagemSource).then(
                  (result) => {

                    //document.getElementById("loading").style.display = "none";
                    resolve(result);
                  }
                );
              };
            })
            .catch((error) => {
              console.log("Imagem Error", error)
              if (

                error.response.status === 404 &&
                error.response.data === "Reach the limit"
              ) {
                reject(
                  i18n.t("coupon.canvas.errormessageremovebackground"),
                  "error"
                );
              }
            });
        } else {
          reject(
            i18n.t("coupon.canvas.errormessageremovebackgroundselect"),
            "error"
          );
        }
      });
    }
  }

  // Insere diversos itens no Cavas, com comando vindo da TopBar Canvas
  insertItemInCanvas() {
    this.props.couponNavBarData.map((item) => {
      this.setState({ isEmpty: false }); // Diz que já foi inserido algo no Canvas para permitir salvar.

      if (item.element == "square") {
        this.createSquare();
      } else if (item.element == "circle") {
        this.createCircle();
      } else if (item.element == "rectangle") {
        this.createRect();
      } else if (item.element == "triangle") {
        this.createTriangle();
      } else if (item.element == "text") {
        this.insertText(item.extra);
      } else if (item.element == "changeText") {
        this.changeText(item.extra);
      } else if (item.element == "textSize") {
        this.updateFontSize(item.extra);
      } else if (item.element == "front") {
        this.bringToFront();
      } else if (item.element == "forward") {
        this.bringForward();
      } else if (item.element == "backward") {
        this.sendBackwards();
      } else if (item.element == "back") {
        this.sendToBack();
      } else if (item.element == "delete") {
        this.delete();
      } else if (item.element == "changeColor") {
        this.changeColor(item.extra);
      } else if (item.element == "gridLines") {
        this.handleChangeGuidelines(item.extra);
      } else if (item.element == "stroke") {
        this.handleChangeStroke(item.extra);
      } else if (item.element == "strokeSize") {
        this.handleChangeStrokeSize(item.extra);
      }
    });
  }

  // Direciona para a página de configurar as regras do cupom com a id na url
  handleRedirectOnClick = () => {
    this.setState({ redirect: true });
  };

  // Limpa todos as informações para iniciar um cupom do zero.
  handleResetCoupon = () => {
    this.canvas.clear();

    this.setState({
      canvasWidth: "540", // 1080 / 2 => 540 (Medidas do Instagram)
      canvasHeight: "400", // 1350 / 2 => 675
      messageClass: "fade",
      messageText: "",
      studioId: "",
      guidelines: false,
      stroke: false,
      strokeSize: 1,
      isEmpty: true,
      tags: 0,
    });

    this.props.history.push("/studio");
  };

  // Liga ou desliga as linhas
  handleChangeGuidelines = (checked) => {
    this.setState({ guidelines: checked });
  };

  // Define se vai colorir o objeto ou o contorno
  handleChangeStroke = (checked) => {
    this.setState({ stroke: checked });
  };

  // Define o tamanho do Stoke
  handleChangeStrokeSize = (strokeSize) => {
    this.setState({ strokeSize: parseInt(strokeSize) }, function () {
      this.updateStrokeSize(); // Altera o tamano da borda.
    });
  };

  handleWidth = (event) => {
    this.setState({
      canvasWidth: event.value ? event.value.toString() : null
    }, function resize() {
      this.reDrawCanvas({ width: this.state.canvasWidth }, "canvasWidth");
      canvasWidth = event.value;
      if (this.state.canvasWidth === "540" && this.state.canvasHeight === "400") {
        this.setState({
          isCoupon: true
        });
      } else {
        this.setState({
          isCoupon: false
        });
      }
    });
  }

  handleHeight = (event) => {
    this.setState({
      canvasHeight: event.value ? event.value.toString() : null
    }, function resize() {
      this.reDrawCanvas({ height: this.state.canvasHeight }, "canvasHeight");
      if (this.state.canvasWidth === "540" && this.state.canvasHeight === "400") {
        this.setState({
          isCoupon: true
        });
      } else {
        this.setState({
          isCoupon: false
        });
      }
    });
  }

  // Função que administra toda mudança de valor nos componentes
  handleValueChange = (event, fieldId) => {

    if (fieldId === "canvasWidth") {
      this.setState({
        canvasWidth: event.value ? event.value.toString() : null
      }, function resize() {
        this.reDrawCanvas({ width: this.state.canvasWidth }, "canvasWidth");
        canvasWidth = event.value;
      });
    } else if (fieldId === "canvasHeight") {
      this.setState({
        canvasHeight: event.value ? event.value.toString() : null
      }, function resize() {
        this.reDrawCanvas({ height: this.state.canvasHeight }, "canvasHeight");
      });
    }

    // if (fieldId) {
    //   this.setState({
    //     [fieldId]: event.value ? event.value.toString() : null
    //   }, function newCanvas() {
    // if (fieldId === "canvasWidth") {
    //   this.reDrawCanvas({ width: this.state.canvasWidth }, "canvasWidth");
    // } else if (fieldId === "canvasHeight") {
    //   this.reDrawCanvas({ height: this.state.canvasHeight }, "canvasHeight");
    // }
    //   }
    //   );
    // }
  };

  render() {

    if (this.state.redirect == true) {
      if (this.state.studioId) {
        return <Redirect push to={`/viewcoupons?id=${this.state.studioId}`} />;
      }
    }

    if (this.state.guidelines == true) {
      if (hLine && vLine) {
        hLine.style.display = "block";
        vLine.style.display = "block";
      }
    } else {
      if (hLine && vLine) {
        hLine.style.display = "none";
        vLine.style.display = "none";
      }
    }

    if (this.state.messageText) {
      messageText = <div style={{
        display: "flex", justifyContent: "center",
      }}>
        <div className="row">
          <div
            style={{
              width: `${this.state.canvasWidth ? this.state.canvasWidth : "540"}px`,
              textAlign: "center",
              margin: "0 auto",
              marginTop: "1px",
            }}
          >
            {this.state.tags !== 0 ? this.state.tags : ""}
          </div>
          <div
            className={`alert alert-primary ${this.state.messageClass}`}
            role="alert"
            style={{
              width: `${this.state.canvasWidth ? this.state.canvasWidth : "540"}px`,
              textAlign: "center",
              margin: "0 auto",
              marginTop: "1px",
            }}
          >
            {this.state.messageText}
          </div>
        </div></div>;
    } else {
      messageText = "";
    }



    if (!this.state.messageText) {
      sizeRenderControl = <>
        <div className="row" style={{ paddingTop: "8px" }}>
          <label style={{ marginLeft: "20px", marginTop: "3px" }}>Tamanho:</label>
          <ScreenSize />
          <div className="dx-field">
            <div className="dx-field-value">
              <TextBox
                // placeholder="Width"
                onValueChanged={this.handleWidth}
                // onValueChanged={
                //   (event) => this.handleValueChange(event, "canvasWidth")
                // }
                value={
                  this.state.canvasWidth
                }
                width={"80px"}
                style={{ float: "left" }}
              />
            </div>
          </div>
          <div className="dx-field">
            <div className="dx-field-value">
              <TextBox
                placeholder="Height"
                onValueChanged={this.handleHeight}
                // onValueChanged={
                //   (event) => this.handleValueChange(event, "canvasHeight")
                // }
                value={
                  this.state.canvasHeight
                }
                width={"80px"}
                style={{ float: "left", marginRight: "10px" }}
              />
            </div>
          </div>

          <div className="col">
            <div
              className="row"
            >
              <div
                style={{
                  display: "block",
                  height: "25px",
                  float: "left",
                  margin: "0px 3px",
                }}
              >
                <Button
                  width={120}
                  text={i18n.t("coupon.canvas.btnimagedonwload")}
                  type="success"
                  stylingMode="outlined"
                  onClick={this.imageProcessDowload}
                  visible={!this.state.isCoupon}
                  style={{ marginLeft: "50px" }}
                />
              </div>
            </div>
            <div
              className="row"
            >
              <div
                style={{
                  display: "block",
                  height: "25px",
                  float: "right",
                  margin: "0px 3px",
                }}
              >
                <Button
                  width={120}
                  text={i18n.t("coupon.canvas.btnsaveart")}
                  type="success"
                  stylingMode="outlined"
                  onClick={this.saveArt}
                  visible={!this.state.isCoupon}
                  style={{ marginLeft: "50px" }}
                />
              </div>
            </div>
          </div>


        </div>

      </>;
    } else {
      sizeRenderControl = "";
    }


    return (
      <>
        <div className="row">
          <div
            id="canvasArea"
            className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xl-13" style={{ padding: 0 }}
          >
            <div className="row">
              <div className="col"
              // style={{ paddingTop: "30px" }}
              >
                <div
                  style={{
                    display: "block",
                    height: "60px",
                    width: `${this.state.canvasWidth ? this.state.canvasWidth : "540"}px`,
                    margin: "0 auto",
                  }}
                >
                  <div className="">
                    <div className="col-9 p-0">
                      <div className="" style={{ width: `${this.state.canvasWidth ? this.state.canvasWidth : "540"}px`, minWidth: "530px", margin: "0 auto" }}>
                        {messageText}
                        {sizeRenderControl}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className=""
                  style={{
                    width: `${this.state.canvasWidth ? this.state.canvasWidth : "540"}px`,
                    height: "400px",
                    margin: "0 auto",
                  }}
                >
                  <div
                    id="vLine"
                    className="guide v"
                    style={{ height: `${this.state.canvasHeight ? this.state.canvasHeight : "400"}px` }}
                  ></div>
                  <div
                    id="hLine"
                    className="guide h"
                    style={{ width: `${this.state.canvasWidth ? this.state.canvasWidth : "540"}px` }}
                  ></div>

                  <canvas
                    id="canvas"
                    width={`${this.state.canvasWidth ? this.state.canvasWidth : "540"}px`}
                    height={`${this.state.canvasHeight ? this.state.canvasHeight : "400"}px`}
                  ></canvas>
                  <div>
                    <div
                      style={{
                        width: `${this.state.canvasWidth ? this.state.canvasWidth : "540"}px`,
                        textAlign: "center",
                        margin: "0 auto",
                        marginTop: "1px",
                      }}
                    >
                      {this.state.tags !== 0 ? this.state.tags : ""}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xl-13">
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div
                          className=""
                          style={{ float: "left", marginRight: "0px" }}
                        >
                          <div
                            style={{
                              display: "block",
                              height: "25px",
                              float: "left",
                              margin: "0px 3px",
                            }}
                          >
                            <Button
                              width={120}
                              text={i18n.t("coupon.canvas.btnsavecoupontext")}
                              type="success"
                              stylingMode="outlined"
                              onClick={this.saveCoupon}
                              visible={this.state.isCoupon ? this.state.isCoupon : false}
                            />
                          </div>
                        </div>
                        <div
                          className=""
                          style={{ float: "left", marginRight: "0px" }}
                        >
                          <div
                            style={{
                              display: "block",
                              height: "25px",
                              float: "left",
                              margin: "0px 3px",
                            }}
                          >
                            <Button
                              className="download"
                              width={120}
                              text={i18n.t("coupon.canvas.btncuponuserules")}
                              type="normal"
                              stylingMode="outlined"
                              visible={this.state.isCoupon ? true : false}
                              onClick={() =>
                                this.handleRedirectOnClick(this.state.studioId)
                              }
                              disabled={this.state.studioId.length > 0 ? false : true}
                            />
                          </div>
                        </div>
                        <div
                          className=""
                          style={{ float: "left", marginRight: "0px" }}
                        >
                          <div
                            style={{
                              display: "block",
                              height: "25px",
                              float: "left",
                              margin: "0px 3px",
                            }}
                          >
                            <Button
                              className=""
                              width={120}
                              text={i18n.t("coupon.canvas.btnnewcoupontext")}
                              type="normal"
                              stylingMode="outlined"
                              onClick={this.handleResetCoupon}
                              visible={this.state.isCoupon ? this.state.isCoupon : false}
                            />
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>



            {/* <div className="row">
          <div
            style={{
              width: `${this.state.canvasWidth}px`,
              textAlign: "center",
              margin: "0 auto",
              marginTop: "1px",
            }}
          >
            {this.state.tags !== 0 ? this.state.tags : ""}
          </div>
          <div
            className={`alert alert-primary ${this.state.messageClass}`}
            role="alert"
            style={{
              width: `${this.state.canvasWidth}px`,
              textAlign: "center",
              margin: "0 auto",
              marginTop: "1px",
            }}
          >
            {this.state.messageText}
          </div>
        </div> */}
          </div>
        </div>



      </>
    );
  }
}

function mapStateToProps(state) {
  // mapStateToProps returns an object, with:
  // property is the local prop name to this component
  // value will be the property in the root reducer... ie, piece of the store
  return {
    couponNavBarData: state.couponNavBar,
    fontControl: state.fontControl,
    screenSize: state.screenSize,
    selectedPhoto: state.selectedPhoto,
    photoControl: state.photoControl,
    modalControl: state.modalControl,
    fontSize: state.fontSize,
  };
}

function mapDispatchToProps(dispatch) {
  // this function return bindActionCreators
  // and we hand bindActionCreators an object
  // each property will be a local prop
  // each value will be a function that is dispatch when run
  // second arg of bindActionCreators is the dispatch
  return bindActionCreators(
    {
      updatePhotoControl: updatePhotoControl,
    },
    dispatch
  );
}
// export default CanvasComponent;
export default connect(mapStateToProps, mapDispatchToProps)(CanvasComponent);
