import React, { Component } from "react";
// import creditCard from "../../images/creditcard.gif";
import logo_pix from "../../../images/logo_pix.png";
import { Button } from "devextreme-react";
import { locale, loadMessages } from "devextreme/localization";
import notify from "devextreme/ui/notify";
//Base
import { LoadPanel } from "devextreme-react/load-panel";
import { getCustomer, updateCustomer, getCustomerBalance, getCustomerTotalBalance, deleteCustomer, insertSenderEmail, getCustomerActivity } from "../../../apis/toowezeApi";
import { TextBox } from "devextreme-react";

import { SelectBox } from "devextreme-react";
import {
  companyIdentifierValidation,
  buscaCEP,
  companyRootIDIdentifierValidation,
} from "../../../apis/toowezeApi";
import getCities from "../../../apis/citiesApi";
import states from "../../data/states";
import {
  Validator,
  RequiredRule,
  CompareRule,
  EmailRule,
  PatternRule,
  StringLengthRule,
  RangeRule,
  AsyncRule,
} from "devextreme-react/validator";
import DataGrid, {
  Column,
  Pager,
  Paging,
  HeaderFilter,
  SearchPanel,
  Lookup,
  Editing,
} from "devextreme-react/data-grid";
import { i18n, lgMessages, languageCode } from "../../../translate/i18n";
import { generatepaperpayment, getBalance } from "../../../apis/payment";
import data from '../../data/data';


import Spinner from "../../LayOut/Spinner";
const moment = require("moment-timezone");

const position = { of: "#addressCard" };
const positionAll = { of: "#branchCard" };

export default class AddEditCustomersMiddle extends Component {
  constructor(props) {
    super(props);
    // Configura o idioma
    loadMessages(lgMessages);
    locale(languageCode);

    this.state = {
      identifierType: 1,
      subGroupId: null,
      branchs: "",
      loadPanelVisible: false,
      allPanelVisible: false,
      cities: [],
      companyIdentifierCheck: true,
      companyIdentifier: "",
      goValidateIdentifier: false,
      goValidateEmail: false,
      emailCheck: false,
      cardType: "profile",
      barCode: "active_",
      card: "",
      renew: true,
      companyIdentifierReadOnly: false,
      planPrice: 0,
      disbaleButton: false,
      selectedPlan: [],
      senderEmails: [],
      customer: {},
      // readSenderEmails: []
    };

  }

  componentDidMount() {

    let urlParams = new URLSearchParams(window.location.search);
    let setCard = urlParams.get("card");

    if (setCard) {
      this.setState({
        cardType: setCard
      })
    }

    if (setCard === "loyalty") {
      this.getCustomerBalance()
    }



    const customerId = urlParams.get("id");

    if (customerId) {
      getCustomer(customerId)
        .then((res) => {
          console.log("res", res)
          this.setState({
            id: res?._id,
            email: res?.email,
            isEmailValidated: res?.isEmailValidated,
            isVerify: res?.isVerify,
            externalID: res?.externalID,
            gender: res?.gender,
            mobil: res?.mobil,
            name: res?.name,
            origin: res?.origin,
            personalId: res?.personalId,
            personalsecondId: res?.personalsecondId,
            identifierType: res?.identifierType ? res?.identifierType : 1,
            profilePic: res?.profilePic && res?.profilePic !== "" ? res?.profilePic : undefined,
            rootId: res?.rootId,
            status: res?.status,
            createdAt: res?.createdAt,
            birthDay: res?.birthDay ? this.converterDataISOParaBrasileira(res?.birthDay) : "",
            tags: res?.tags,
            addressLine1: res?.address?.addressLine1,
            addressLine2: res?.address?.addressLine2,
            cityId: res?.address?.cityId,
            cityName: res?.address?.cityName,
            countryId: res?.address?.countryId,
            neighborhood: res?.address?.neighborhood,
            regionId: res?.address?.regionId,
            regionName: res?.address?.regionName,
            zipCode: res?.address?.zipcode,
          });

          this.getCustomerTotalBalance("loyalty")
          this.getCustomerTotalBalance("cashback")

          if (this.state.regionName) {
            getCities(this.state.regionName)
              .then((res) => {
                this.setState({
                  cities: res.data,
                });
              })
              .catch((error) => {
                console.log("Cities Error", error);
              });
          }




        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  onKeyUp(event, fieldId) {
    event.event.preventDefault();

    let finalValue = event.event.currentTarget.value;

    if (fieldId === "zipCode" || fieldId === "zipCodeB") {
      this.setState({ goValidateEmail: false });
      this.setState({ goValidateIdentifier: false });

      /// CEP
      var v = finalValue.replace(/^([\d]{5})\.*([\d]{3})/, "$1-$2");
      finalValue = v;

      this.setState({
        [fieldId]: finalValue,
      });

      if (finalValue.length === 9) {
        this.setState({
          loadPanelVisible: true,
        });

        console.log("finalValue ===========>", finalValue)

        buscaCEP(finalValue)
          .then((cep) => {
            if (cep.data.uf) {
              console.log("cep.data.uf ===>", cep.data.uf)
              getCities(cep.data.uf)
                .then((res) => {
                  if (res.data) {
                    // if (fieldId === "zipCode") {
                    this.setState({
                      cities: res.data,
                      loadPanelVisible: false,
                      addressLine1: cep.data.logradouro,
                      // addressLine2: cep.data.complemento,
                      regionName: cep.data.uf,
                      neighborhood: cep.data.bairro,
                      // state: cep.data.uf,
                      cityName: cep.data.localidade,
                      backgroundColor: "#ffdede",
                    });



                    // } else if (fieldId === "zipCodeB") {
                    //   this.setState({
                    //     citiesB: res.data,
                    //     loadPanelVisible: false,
                    //     addressLine1B: cep.data.logradouro,
                    //     // addressLine2B: cep.data.complemento,
                    //     cityRegionB: cep.data.bairro,
                    //     stateB: cep.data.uf,
                    //     cityB: cep.data.localidade,
                    //     backgroundColorB: "#ffdede",
                    //   });
                    // }
                  }
                })
                .catch((error) => {
                  console.log("Cities Error", error);
                });
            } else {
              this.setState({
                loadPanelVisible: false,
              });
            }

          })
          .catch((error) => {
            this.setState({
              loadPanelVisible: false,
            });
          });
      }
    } else if (fieldId === "personalId") {
      if (finalValue) {
        if (finalValue.length === 14) {
          /// Pessoa Jurídica
          let v1 = finalValue.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          );
          finalValue = v1;
        } else if (finalValue.length === 11) {
          /// Pessoa Física
          let v2 = finalValue.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/g,
            "$1.$2.$3-$4"
          );
          finalValue = v2;
        }

        this.setState({
          personalId: finalValue,
        });

        if (this.state.personalId) {
          if (
            this.state.personalId.length === 13 ||
            this.state.personalId.length === 17
          ) {
            this.setState({ goValidateIdentifier: true });
          } else {
            this.setState({ goValidateIdentifier: false });
          }
        }
      }
    } else if (fieldId === "birthDay") {
      this.setState({
        birthDay: finalValue
          .replace(/\D/g, "")
          .replace(/(\d{2})(\d)/, "$1/$2")
          .replace(/(\d{2})(\d)/, "$1/$2")
          .replace(/(\d{4})(\d)/, "$1")
      });
    } else if (
      fieldId === "phone" ||
      fieldId === "whatsup" ||
      fieldId === "mobil"
    ) {
      // Telefone
      let v1 = finalValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v1 = v1.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
      v1 = v1.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
      finalValue = v1;
      this.setState({
        [fieldId]: finalValue ? finalValue : null,
      });
    } else if (fieldId === "email") {
      let email = event.event.currentTarget.value;

      if (email) {
        if (email.includes("@")) {
          this.setState({
            email: email,
            goValidateEmail: true,
          });
        }
      }
    } else if (fieldId === "validate") {
      let v2 = finalValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v2 = v2.replace(/(\d{2})(\d{2})/g, "$1/$2");
      finalValue = v2;
      this.setState({
        [fieldId]: finalValue ? finalValue : null,
      });
    }
  }

  // Função para converter de "11/04/1976" para "1976-04-11"
  converterDataBrasileiraParaISO(dataBrasileira) {
    const dia = dataBrasileira.substring(0, 2);
    const mes = dataBrasileira.substring(3, 5);
    const ano = dataBrasileira.substring(6, 10);
    return `${ano}-${mes}-${dia}`;
  }

  // Função para converter de "1976-04-11" para "11/04/1976"
  converterDataISOParaBrasileira(dataISO) {
    const ano = dataISO.substring(0, 4);
    const mes = dataISO.substring(5, 7);
    const dia = dataISO.substring(8, 10);
    return `${dia}/${mes}/${ano}`;
  }

  // Verifica se o email já está cadastrado
  asyncCompanyIdentifierEmailValidation = (params) => {
    if (this.state.goValidateEmail === true) {
      this.setState({ goValidateEmail: false });
      return companyIdentifierValidation(
        "email",
        params.value,
        "",
        this.state.branchId
      ).then((res) => {
        this.setState({ emailCheck: !res });
        return !res; // Não tem repetido = true, é valido? !res
      });
    } else {
      return new Promise((resolve, reject) => resolve(this.state.emailCheck));
    }
  };

  // Insere a máscara quando clica no textbox
  insertMask(textBoxValue, fieldId) {
    if (textBoxValue) {
      textBoxValue = textBoxValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
      if (fieldId === "zipCode") {
        /// CEP
        return textBoxValue.replace(/^([\d]{5})\.*([\d]{3})/, "$1-$2");
      } else if (
        fieldId === "personalId"
      ) {
        if (textBoxValue.length === 14) {
          /// Pessoa Jurídica
          return textBoxValue.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          );
        } else if (textBoxValue.length === 11) {
          /// Pessoa Física
          return textBoxValue.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/g,
            "$1.$2.$3-$4"
          );
        }

      } else if (fieldId === "birthDay") {
        return textBoxValue
          .replace(/\D/g, "")
          .replace(/(\d{2})(\d)/, "$1/$2")
          .replace(/(\d{2})(\d)/, "$1/$2")
          .replace(/(\d{4})(\d)/, "$1")
      } else if (
        fieldId === "phone" ||
        fieldId === "whatsup" ||
        fieldId === "mobil"
      ) {
        // Telefone
        textBoxValue = textBoxValue.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        return textBoxValue.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
      }
    }
  }

  // Remove a máscara
  removeMask(textBoxValue) {
    if (textBoxValue) {
      return textBoxValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
    }

  }

  // Administra as mudanças de valores dos TextBox
  handleValueChange = (event, fieldId) => {

    if (fieldId) {
      console.log("fieldId", fieldId)
      console.log("event.value", event.value)
      this.setState({
        [fieldId]: event.value ? event.value : null,
      });
    }

    if (fieldId === "regionName") {
      console.log("event.value", event.value)
      getCities(event.value)
        .then((res) => {
          console.log("cidades", res)
          this.setState({
            cities: res.data,
          });
        })
        .catch((error) => {
          console.log("Cities Error", error);
        });
    } else if (fieldId === "email") {
      this.setState({ goValidateEmail: true });
    } else if (fieldId === "identifierType") {
      let companyIdentifier = this.state.companyIdentifier;
      this.setState({
        companyIdentifier: 0,
        goValidateIdentifier: false,
      });

      this.setState({
        companyIdentifier: companyIdentifier,
        goValidateIdentifier: true,
      });
    } else if (fieldId === "renew") {
      this.setState({
        [fieldId]: event.target.checked,
      });
    }
  };

  // Função de quando clica os cards relativos a escolha do tipo de campanha.
  // changePaymentType = (event) => {
  //   if (event.currentTarget.id === "card") {
  //     this.setState({
  //       card: "active_",
  //       barCode: "",
  //     });
  //   } else if (event.currentTarget.id === "barCode") {
  //     this.setState({
  //       card: "",
  //       barCode: "active_",
  //     });
  //   }
  // };

  getCustomerBalance = (type) => {
    getCustomerBalance(this.state.id, type).then(res => {
      console.log("getCustomerBalance", res)
      this.setState({
        datagrid: res,
        cardType: type,
      });
    }).catch(error => {
      console.log("error", error)

    })
  }

  getCustomerTotalBalance = (type) => {
    getCustomerTotalBalance(this.state.id, type).then(res => {
      this.setState({
        [type]: res[0]?.amount
      });
      console.log(
        "res[0]?.amount", res[0]?.amount
      )
    }).catch(error => {
      console.log("error", error)

    })
  }

  getCustomerActivity = (type) => {
    getCustomerActivity(this.state.id).then(res => {
      console.log("getCustomerBalance", res)
      this.setState({
        datagrid: res,
        cardType: type,
      });
    }).catch(error => {
      console.log("error", error)

    })
  }


  // getBalance = () => {
  //   getBalance()
  //     .then((res) => {
  //       this.setState({
  //         datagrid: res,
  //         cardType: "payments",
  //       });
  //     })
  //     .catch((err) => {
  //       this.setState({
  //         cardType: "payments",
  //       });
  //       console.log("err balance", err);
  //     });
  // };

  // Criar o boleto PIX
  // createPaperPayment = (event) => {
  //   this.setState({
  //     disbaleButton: true,
  //   });

  //   generatepaperpayment(this.state)
  //     .then((res) => {
  //       this.setState({
  //         cardType: "pix",
  //         reference: res.reference,
  //         dueDate: res.dueDate,
  //         pixAmount: res.amount,
  //         pixStatus: res.status,
  //         id: res.code,
  //         payloadInBase64: Buffer.from(
  //           res.pix.payloadInBase64,
  //           "base64"
  //         ).toString("utf-8"),
  //         imageInBase64: res.pix.imageInBase64,
  //       });

  //       setTimeout(() => {
  //         document.getElementsByClassName("middleBody")[0].scrollTop = 0;
  //         this.setState({
  //           pixReady: true,
  //           disbaleButton: false,
  //         });
  //       }, 1000);
  //     })
  //     .catch((error) => {

  //       // if (error.response) {
  //       //   // Request made and server responded
  //       //   console.log(error.response.data[0].message);
  //       //   console.log(error.response.status);
  //       //   console.log(error.response.headers);
  //       // } else if (error.request) {
  //       //   // The request was made but no response was received
  //       //   console.log(error.request);
  //       // } else {
  //       //   // Something happened in setting up the request that triggered an Error
  //       //   console.log('Error', error.message);
  //       // };

  //       let message = error?.response?.data[0]?.message;

  //       // if (message && message.includes("billing.document")) {
  //       //   message = message.replace("billing.document", i18n.t("customerAddEdit.labelcompanytype"))
  //       // }

  //       this.showNotify(message ? message : error, "error");
  //       this.setState({
  //         pixReady: false,
  //         disbaleButton: false,
  //       });
  //     });
  // };

  // resendEmail = (event) => {
  //   insertSenderEmail(event.data.email).then(res => {
  //     // mensagem
  //     if (res) {
  //       this.showNotify(i18n.t("customerAddEdit.message.resendemail"), "success");
  //     }
  //   }).catch(error => {
  //     // mensagem
  //     this.showNotify(error, "error");
  //   });
  // }


  // Atualiza os dados da conta
  updateCustomer = () => {
    if (
      this.state.companyIdentifier !== "" &&
      this.state.companyIdentifier !== undefined &&
      this.state.identifierType !== 1 &&
      this.state.identifierType !== 2
    ) {
      this.showNotify(i18n.t("customerAddEdit.message.identifieralert"), "error");
    } else {
      this.showNotify("OK", "success");

      if (
        (this.state.companyIdentifier === "" ||
          this.state.companyIdentifier === undefined) &&
        (this.state.identifierType === 1 || this.state.identifierType === 2)
      ) {
        this.setState({
          identifierType: undefined,
        });
      }

      const customer = {
        id_: this.state?.id,
        email: this.state?.email,
        isEmailValidated: this.state?.isEmailValidated,
        isVerify: this.state?.isVerify,
        externalID: this.state?.externalID,
        gender: this.state?.gender,
        mobil: this.state?.mobil,
        name: this.state?.name,
        origin: this.state?.origin,
        personalId: this.state?.personalId,
        personalsecondId: this.state?.personalsecondId,
        identifierType: this.state?.identifierType ? this.state?.identifierType : 1,
        profilePic: this.state?.profilePic && this.state?.profilePic !== "" ? this.state?.profilePic : undefined,
        rootId: this.state?.rootId,
        status: this.state?.status,
        createdAt: this.state?.createdAt,
        birthDay: this.converterDataBrasileiraParaISO(this.state?.birthDay),
        // tags: this.state?.tags,
        address: {
          addressLine1: this.state?.addressLine1,
          addressLine2: this.state?.addressLine2,
          cityId: this.state?.cityId,
          cityName: this.state?.cityName,
          countryId: this.state?.countryId,
          neighborhood: this.state?.neighborhood,
          regionId: this.state?.regionId,
          regionName: this.state?.regionName,
          zipcode: this.state?.zipCode,
        }
      }

      console.log("customer indo gravar", customer)

      updateCustomer(customer)
        .then((res) => {
          console.log("Customer gravado", res)
        })
        .catch((error) => {
          this.showNotify(error, "error");
        });
    }
  };

  deleteCustomer = () => {
    deleteCustomer(this.state._id).then(() => {
      this.showNotify(i18n.t("customerAddEdit.notifycustomersdeleted"), "success");
      this.readCustomersGrid();
    }).catch((err) => {
      this.showNotify(err, "error");
    });
  }

  // Transforma a imagem em Blob e grava no State
  // handleUploadChange = (event) => {
  //   let reader = new FileReader();
  //   if (event.target.files && event.target.files.length) {
  //     const [file] = event.target.files;
  //     reader.readAsDataURL(file);

  //     reader.onload = () => {
  //       this.setState({
  //         image: reader.result,
  //       });
  //     };
  //   }
  // };

  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "top", offset: "0 200" },
        closeOnClick: true,
      },
      type,
      3000
    );
  }

  calculateDate(data) {
    return data.createAt ? new Date(data.createAt * 1000) : "";
  }

  secondCalculateDate(data) {
    return data.createdAt ? new Date(data.createdAt * 1000) : "";
  }

  calculateActivity(data) {
    if (data?.activityDetails) {
      return processObjectEntries(data?.activityDetails);
    } else {
      console.log("data", data);
      return "";
    }
  }

  // calculateActivity2() {
  //   return data.activityDetails.join(" ");
  // }

  // statusEditorRender(cell) {
  //   let onValueChanged = this.onValueChanged.bind(this, cell);
  //   return (
  //     <SelectBox
  //       defaultValue={cell.value}
  //       {...cell.column.lookup}
  //       onValueChanged={onValueChanged}
  //       itemRender={this.itemRender}
  //     />
  //   );
  // }


  // Verifica se existe CPF ou CNPJ já Cadastrados
  companyRootIDIdentifierValidation = (params) => {
    console.log("params", params)
    if (this.state.goValidateIdentifier === true) {
      this.setState({ goValidateIdentifier: false });
      if (params.value.length > 10) {
        return companyRootIDIdentifierValidation(
          "companyIdentifier",
          params.value,
          this.state.identifierType,
          this.state.id
        ).then((res) => {
          this.setState({ companyIdentifierCheck: !res });
          return !res; // Não tem repetido = true, é valido? !res
        });
      } else {
        return new Promise((resolve, reject) =>
          resolve(this.state.companyIdentifierCheck)
        );
      }
    } else {
      return new Promise((resolve, reject) =>
        resolve(this.state.companyIdentifierCheck)
      );
    }
  };

  setCurrecy = (data) => {
    return data.data.amount ? (this.state.cardType === "cashback" ? i18n.t("customerAddEdit.grid.currency") + " " + parseFloat(data.data.amount).toFixed(2) : parseFloat(data.data.amount).toFixed(2)) : "";
  }

  toDate = (data) => {
    console.log('data ========>', data)
    try {
      return new Date(data?.data?.log_date_timeZone).toLocaleString();
    } catch (error) {
      console.log('error =======>', error)
      return null
    }
  }

  toDate_acitity = (data) => {
    console.log('data ========>', data)
    try {
      return new Date(data?.data?.activityDetails?.log_date_timeZone).toLocaleString();
    } catch (error) {
      console.log('error =======>', error)
      return null
    }
  }

  render() {
    let cardType = "";

    if (this.state.cardType === "profile") {
      cardType = (
        <>
          <div className="card">
            <div className="card-body" style={{ paddingBottom: "5px" }}>
              <div className="col-12">
                <div className="row" style={{ height: "35px" }}>
                  <div className="col-8">
                    <div>
                      <label className="badge badge-warning" style={{ float: "right" }}>
                        {i18n.t("customerAddEdit.labeltotalbalancepoints")}
                        {" " + this.state?.loyalty > 0 ? this.state?.loyalty : 0} </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div >
                      <label className="badge badge-warning" style={{ float: "right" }}>
                        {i18n.t("customerAddEdit.labeltotalbalancecashback")}
                        {" " + i18n.t("customerAddEdit.grid.currency")}
                        {this.state?.cashback > 0 ? " " + this.state?.cashback : " " + 0}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("customerAddEdit.labelname")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    value={this.state.name}
                    readOnly={false}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "name")
                    }
                  ></TextBox>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("customerAddEdit.labelemail")}
                </div>
                <div className="dx-field-value">
                  <TextBox value={this.state.email} readOnly={true} />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("customerAddEdit.labeltype")}
                </div>
                <div className="dx-field-value">
                  <SelectBox
                    readOnly={this.state.companyIdentifierReadOnly}
                    items={[
                      {
                        identifierType: 1,
                        identifierName: i18n.t("customerAddEdit.labelperson"),
                      },
                      {
                        identifierType: 2,
                        identifierName: i18n.t("customerAddEdit.labelcorporate"),
                      },
                    ]}
                    stylingMode="filled"
                    displayExpr="identifierName"
                    valueExpr="identifierType"
                    defaultValue={this.state.identifierType}
                    value={this.state.identifierType}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "identifierType")
                    }
                    width="100%"
                    placeholder={i18n.t("customerAddEdit.placeholderidentifiertype")}
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("customerAddEdit.labelidentifiertype")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    placeholder=""
                    value={this.state.personalId}
                    readOnly={this.state.companyIdentifierReadOnly}
                    onKeyUp={(event) =>
                      this.onKeyUp(event, "personalId")
                    }
                    onFocusOut={() =>
                      this.setState({
                        personalId: this.insertMask(
                          this.state.personalId,
                          "personalId"
                        ),
                      })
                    }
                  >
                    <Validator>
                      <AsyncRule
                        message={i18n.t(
                          "customerAddEdit.message.identifieralreadyregistered"
                        )}
                        ignoreEmptyValue={true}
                        validationCallback={(event) =>
                          this.companyRootIDIdentifierValidation(event)
                        }
                      />
                    </Validator>
                  </TextBox>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("customerAddEdit.labelmobil")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    showClearButton={true}
                    placeholder=""
                    value={this.state.mobil}
                    onKeyUp={(event) =>
                      this.onKeyUp(event, "mobil")
                    }
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("customerAddEdit.labelbirthdate")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    showClearButton={true}
                    placeholder=""
                    value={this.state.birthDay}
                    onKeyUp={(event) =>
                      this.onKeyUp(event, "birthDay")
                    }
                  ></TextBox>
                </div>
              </div>
            </div>
          </div>

          <hr style={{ margin: "8px" }} />

          <div id="addressCard" className="card">
            <div
              className="card-body second-group"
              style={{ paddingBottom: "5px" }}
            >
              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("customerAddEdit.labelzipcode")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    placeholder=""
                    maxLength={9}
                    value={this.state.zipCode}
                    onKeyUp={(event) => this.onKeyUp(event, "zipCode")}
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("customerAddEdit.labelcityregion")}
                </div>
                <div className="dx-field-value">
                  <div className="row">
                    <div className="col-4">
                      <SelectBox
                        dataSource={states}
                        displayExpr="sigla"
                        valueExpr="sigla"
                        stylingMode="filled"
                        defaultValue=""
                        width="100%"
                        placeholder={i18n.t("customerAddEdit.labelregion")}
                        value={this.state.regionName}
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "regionName")
                        }
                      />
                    </div>
                    <div className="col-8">
                      <SelectBox
                        dataSource={this.state.cities}
                        displayExpr="cityName"
                        valueExpr="nome"
                        stylingMode="filled"
                        defaultValue=""
                        width="100%"
                        placeholder={i18n.t("customerAddEdit.labelcity")}
                        value={this.state.cityName}
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "cityName")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("customerAddEdit.labeladdressline1")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    showClearButton={true}
                    placeholder={i18n.t("customerAddEdit.placeholderaddressline1")}
                    value={this.state.addressLine1}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "addressLine1")
                    }
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("customerAddEdit.labeladdressline2")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    showClearButton={true}
                    placeholder={i18n.t("customerAddEdit.placeholderaddressline2")}
                    value={this.state.addressLine2}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "addressLine2")
                    }
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("customerAddEdit.labelneighborhood")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    showClearButton={true}
                    placeholder={i18n.t("customerAddEdit.placeholderneighborhood")}
                    value={this.state.neighborhood}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "neighborhood")
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-12"
            style={{ paddingTop: "4px", paddingRight: "0px" }}
          >
            <div className="row">
              <div className="col-6">
                <Button
                  text={i18n.t("customerAddEdit.btndelete")}
                  width={150}
                  height={30}
                  type="danger"
                  style={{ float: "right" }}
                  onClick={this.deleteCustomer}
                />
              </div>
              <div className="col-6">
                <Button
                  text={i18n.t("customerAddEdit.btnupdate")}
                  width={150}
                  height={30}
                  type="success"
                  style={{ float: "right" }}
                  onClick={this.updateCustomer}
                />
              </div>
            </div>
          </div>
        </>

      );
    } else if (this.state.cardType === "loyalty" || this.state.cardType === "cashback") {
      cardType = (
        <div style={{ marginTop: "-10px" }}>
          <br />
          <div className="card" style={{ minHeight: "450px" }}>
            <div className="card-body" style={{ padding: "0px" }}>
              <div
                className="col-12"
              // style={{ marginLeft: "30px", marginTop: "30px" }}
              >
                <DataGrid
                  dataSource={this.state.datagrid}
                  showBorders={true}
                  columnAutoWidth={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  style={{ fontSize: "smaller", width: "100%" }}
                >
                  <Paging defaultPageSize={10} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[5, 10, 15]}
                    showInfo={true}
                  />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    className="custom"
                    width={240}
                    placeholder={i18n.t("customerAddEdit.grid.searchplaceholder")}
                  />

                  <Column
                    dataField="log_date_timeZone"
                    caption={i18n.t("transactions.activity.log_date_timeZone")}
                    cellRender={this.toDate}
                  ></Column>

                  {/* <Column
                    dataField="createAt"
                    width={80}
                    caption={i18n.t("customerAddEdit.grid.columnlabelcreatedAt")}
                    dataType="date"
                    calculateCellValue={this.calculateDate}
                  ></Column> */}
                  <Column
                    dataField="amount"
                    width={70}
                    caption={i18n.t("customerAddEdit.grid.columnamount")}
                    cellRender={this.setCurrecy}
                  />
                  <Column
                    dataField="ruleName"
                    width={140}
                    caption={i18n.t("customerAddEdit.grid.columnlabeldescription")}
                  />
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.cardType === "activity") {
      cardType = (
        <div style={{ marginTop: "-10px" }}>
          <br />
          <div className="card" style={{ minHeight: "450px" }}>
            <div className="card-body" style={{ padding: "0px" }}>
              <div
                className="col-12"
              >
                <DataGrid
                  dataSource={this.state.datagrid}
                  showBorders={true}
                  columnAutoWidth={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  style={{ fontSize: "smaller", width: "100%" }}
                >
                  <Paging defaultPageSize={10} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[5, 10, 15]}
                    showInfo={true}
                  />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    className="custom"
                    width={240}
                    placeholder={i18n.t("customerAddEdit.grid.searchplaceholder")}
                  />
                  {/* <Column
                    dataField="createdAt"
                    width={80}
                    caption={i18n.t("customerAddEdit.grid.columnlabelcreatedAt")}
                    dataType="date"
                    calculateCellValue={this.secondCalculateDate}
                  ></Column> */}
                  <Column
                    dataField="log_date_timeZone"
                    caption={i18n.t("transactions.activity.log_date_timeZone")}
                    cellRender={this.toDate_acitity}
                  ></Column>
                  <Column
                    dataField="activity"
                    width={300}
                    caption={i18n.t("customerAddEdit.grid.columnactivity")}
                  />
                  <Column
                    dataField="activityDetails"
                    width={300}
                    caption={i18n.t("customerAddEdit.grid.columnactivity")}
                    calculateCellValue={this.calculateActivity}
                  />
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container col-11" style={{ marginLeft: "30px" }}>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={true}
        />

        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={positionAll}
          visible={this.state.allPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={true}
        />

        <div id="branchCard" className="row mt-3 branch">
          <div className="col-3 pr-0">
            <div className="card" style={{ height: "600px", overflow: "auto" }}>
              <div className="card-body p-1">
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div style={{ height: "90px" }}></div>
                    </div>

                    <div className="col-6">
                      <div className="d-flex flex-row-reverse"></div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row mb-1">
                    <Button
                      id="backButton"
                      text={i18n.t("account.btnprofiledata")}
                      width="100%"
                      height="30px"
                      onClick={() => this.setState({ cardType: "profile" })}
                      type="default"
                      stylingMode={
                        this.state.cardType === "profile"
                          ? "contained"
                          : "outlined"
                      }
                    />
                  </div>
                  <div className="row mb-1">
                    <Button
                      id="backButton"
                      text={i18n.t("customerAddEdit.labelbalancepoints")}
                      width="100%"
                      height="30px"
                      onClick={() => {
                        this.setState({ cardType: "loyalty" }, () => {
                          this.getCustomerBalance(this.state.cardType);
                        });
                      }}
                      type="default"
                      stylingMode={
                        this.state.cardType === "loyalty"
                          ? "contained"
                          : "outlined"
                      }
                    />
                  </div>

                  {/* <div className="row mb-1">
                    <Button
                      id="backButton"
                      text={i18n.t("account.labelextracredit")}
                      width="100%"
                      height="30px"
                      onClick={() => {
                        this.setState({ cardType: "extracredit" });
                        this.getPlans();
                      }}
                      type="default"
                      stylingMode={
                        this.state.cardType === "extracredit"
                          ? "contained"
                          : "outlined"
                      }
                    />
                  </div> */}

                  <div className="row mb-1">
                    <Button
                      id="backButton"
                      text={i18n.t("customerAddEdit.labelbalancecashback")}
                      width="100%"
                      height="30px"
                      onClick={() => {
                        this.setState({ cardType: "cashback" }, () => {
                          this.getCustomerBalance(this.state.cardType);
                        });
                      }}
                      type="default"
                      stylingMode={
                        this.state.cardType === "cashback"
                          ? "contained"
                          : "outlined"
                      }
                    />
                  </div>

                  <div className="row mb-1">
                    <Button
                      id="backButton"
                      text={i18n.t("customerAddEdit.labelactivity")}
                      width="100%"
                      height="30px"
                      onClick={() => {
                        this.setState({ cardType: "activity" }, () => {
                          this.getCustomerActivity(this.state.cardType);
                        });
                      }}
                      type="default"
                      stylingMode={
                        this.state.cardType === "activity"
                          ? "contained"
                          : "outlined"
                      }
                    />
                  </div>

                  {/* <div className="row mb-1">
                    <Button
                      id="backButton"
                      text={i18n.t("account.btnconfigurations")}
                      width="100%"
                      height="30px"
                      onClick={() =>
                        this.setState({ cardType: "configurations" })
                      }
                      type="default"
                      stylingMode={
                        this.state.cardType === "configurations"
                          ? "contained"
                          : "outlined"
                      }
                    />
                  </div> */}
                </div>

                <hr />
              </div>
            </div>
          </div>

          <div className="col-9 pr-0">
            <div className="card" style={{ height: "600px", overflow: "auto" }}>
              {this.state.pixReady === false ? (
                <>
                  <Spinner />
                </>
              ) : (
                <div className="card-body">{cardType}</div>
              )}
            </div>
          </div>

          {/* DIREITA */}
        </div>
      </div>
    );
  }

}

function processObjectEntries(data) {
  console.log("log", data)
  return Object.entries(data).map(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        return `${key}: [${value.map(item => processObjectEntries(item)).join(', ')}]`;
      } else {
        return `${key}: { ${processObjectEntries(value)} }`;
      }
    } else {
      return `${key}: ${value}`;
    }
  }).join(', ');
}
