import React, { useState } from "react";
import TopBar from "../../LayOut/TopBar";
import MiddleBody from "../../LayOut/MiddleBody";
import LeftMenu from "../../LayOut/LeftMenu";
import TopRightMenu from "../../LayOut/TopRightMenu";

const CreatePage = (props) => {
  return (
    <>
      <div
        id="5"
        className="col-1"
        style={{
          paddingLeft: "0 !important",
          margin: "0 auto",
          paddingTop: "8px",
        }}
      >
        {/* <!-- Left Menu --> */}
        <LeftMenu />
      </div>

      <div id="6" className="col-8 supMiddle" style={{ zIndex: "1000" }}>
        {/* <!--Menu Superior--> */}
        <TopBar module="createpage" history={props.history} />
        {/* <!--Parte do meio--> */}
        <MiddleBody
          module="createpage"
          middleLarge={true}
          history={props.history}
        />
      </div>
      <div id="7" className="col-3">
        {/* <!--Menu de Apoio--> */}
        <TopRightMenu history={props.history} />
      </div>
    </>
  );
};

export default CreatePage;
