import React, { Component } from "react";
import './modal.css';

class ModalPreview extends Component {

    render() {
        return (
            <div
                id={this.props?.modalId}
                className="modal fade"
                tabIndex="-1"

                role="dialog"
                aria-labelledby="danger-header-modalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl" style={{ marginLeft: 50 }}>
                    <div className="modal-content" style={{ width: 1350 }}>
                        <div className="modal-header modal-colored-header">
                            <button
                                id="exclusiveButton"
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                            >
                                ×
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5 className="mt-0">{this.props.h5Content}</h5>
                            <iframe width={"100%"} height={500}
                                src={this.props.content}
                                title="Template">
                            </iframe>
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-info"
                                data-dismiss="modal"
                                onClick={this.actionType}
                                style={{ padding: "6px 12px 6px 12px" }}
                            >
                                {this.props.okText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default ModalPreview;