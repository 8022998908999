import React, { Component } from "react";
import "./ViewCouponSupportMenu.css";
import { connect } from "react-redux";
import { getCoupons } from "../../../apis/toowezeApi";
import { TextBox, TextArea } from "devextreme-react";
import { Slider } from "devextreme-react/slider";
import { loadMessages, locale } from "devextreme/localization";
import DateBox from "devextreme-react/date-box";
import { Button } from "devextreme-react";
import { Redirect } from "react-router";
import Modal from "../../Modal";
import { deleteCoupons } from "../../../apis/toowezeApi";
import updateViewCouponSupportMenu from "../../../actions/updateViewCouponSupportMenu";
import { bindActionCreators } from "redux";
import {
  proceedSaveConfig,
  getAllBranchs,
  proceedSaveConfigStatus,
} from "../../../apis/toowezeApi";
import {
  NumberBox,
  Button as NumberBoxButton,
} from "devextreme-react/number-box";
import TagBox from "devextreme-react/tag-box";
import ArrayStore from "devextreme/data/array_store";
import config from "devextreme/core/config";
import notify from "devextreme/ui/notify";
import { LoadPanel } from "devextreme-react/load-panel";
import { Validator, RequiredRule } from "devextreme-react/validator";
import { i18n, lgMessages, languageCode } from "../../../translate/i18n";

let Spinner;

class ViewCouponSupportMenu extends Component {
  constructor(props) {
    super(props);

    loadMessages(lgMessages);
    locale(languageCode);
    config({ defaultCurrency: "BRL" });

    this.state = {
      coupon: "",
      sliderValue: 1,
      couponStatus: 0,
      currencyValue: 0,
      random: true,
      limit: 0,
      loadPanelVisible: false,
      branchs: [],
      subGroupId: [],
      dataFields: [
        {
          _id: "1",
          fieldName: i18n.t("viewcoupons.support.choosename"),
          required: false,
        },
        {
          _id: "2",
          fieldName: i18n.t("viewcoupons.support.chooseemail"),
          required: false,
        },
      ],
      initDate: "0",
      endDate: "0",
      initHour: "0",
      endHour: "0",
      fields: ["1", "2"],
      timestamp: Date.now(),
    };

    this.setSliderValue = this.setSliderValue.bind(this);

    this.date = new Date();
    let allBranchs = [];

    getAllBranchs()
      .then((res) => {
        res.data.forEach((element) => {
          allBranchs.push(element);
        });
      })
      .catch((error) => {
        console.log("Estabelecimentos Error", error);
      });

    this.dataSource = new ArrayStore({
      data: allBranchs,
      key: "_id",
    });
  }

  // Campo de Dinheiro
  changeCurrency = (data) => {
    this.setState({
      currencyValue: data.value,
    });
  };
  // Campo de Dinheiro

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    // // Evita renderizar cada vez que o estado é modificado
    if (
      this.props.viewCouponSupportReducer !== nextProps.viewCouponSupportReducer
    ) {
      if (this.props.viewCouponSupportReducer[0].element != "0") {
        this.getCoupon(this.props.viewCouponSupportReducer[0].element);
        return true;
      } else {
        // Limpar todos os campos e imagem do supporte menu.
        this.setState({
          coupon: "",
        });
      }
    }

    // // Evita renderizar cada vez que o estado é modificado
    if (this.props.modalControl !== nextProps.modalControl) {
      if (this.props.modalControl[0].element == "deleteCoupon") {
        deleteCoupons(this.props.modalControl[0].extra).then((res) => {
          this.props.updateViewCouponSupportMenu(
            "0",
            0,
            "updateViewCouponMiddle"
          );
        });
      }
      return true;
    }

    return true;
  }

  componentDidMount() {
    Spinner = document.getElementById("loading"); // Spinner
  }

  // Obtem um cupom utilizando o id
  getCoupon(id) {
    if (id) {
      this.setState({ loadPanelVisible: true });

      getCoupons(id, false).then((res) => {
        // Preparando os valores para serem inseridos nos controles de Data e Hora do Devexpress
        let initDate_ = res.initDate
          ? res.initDate != "0"
            ? new Date(res.initDate + "T00:00:00")
            : "0"
          : "0";
        let endDate_ = res.endDate
          ? res.endDate != "0"
            ? new Date(res.endDate + "T23:59:59")
            : "0"
          : "0";
        let initHour_ = res.initHour
          ? res.initHour
            ? res.initHour.toString().includes(":")
              ? new Date().setHours(
                res.initHour.split(":")[0],
                res.initHour.split(":")[1]
              )
              : "0"
            : "0"
          : "0"; // O formato esperado é: 10:30
        let endHour_ = res.endHour
          ? res.endHour
            ? res.endHour.toString().includes(":")
              ? new Date().setHours(
                res.endHour.split(":")[0],
                res.endHour.split(":")[1]
              )
              : "0"
            : "0"
          : "0"; // O formato esperado é: 10:30

        console.log("endHour_ 176", endHour_)

        // Checa se é tipo data, pq o controle do devexpress dá erro qdo entra um valor inválido
        if (!(initDate_ instanceof Date)) {
          initDate_ = "0";
        }
        if (!(endDate_ instanceof Date)) {
          endDate_ = "0";
        }

        this.setState({
          coupon: res,
          title: res.title,
          description: res.description ? res.description : "",
          originalPrice: res.originalPrice,
          discountPrice: res.discountPrice,
          sliderValue: res.discount ? res.discount : 0,
          minimumPurchase: res.minimumPurchase,
          currencyValue: res.voucher,
          limit: res.limit ? res.limit : 0,
          random: res.random == null ? false : res.random,
          fixedCode: res.fixedCode == null ? "" : res.fixedCode,
          initDate: initDate_,
          endDate: endDate_,
          initHour: initHour_, // O formato esperado é: 10:30
          endHour: endHour_, // O formato esperado é: 10:30
          email: res.email,
          terms: res.condiction ? res.condiction : "",
          couponStatus: res.status ? res.status : 0,
          subGroupId: res.subGroupId ? res.subGroupId : [],
          fields: res.fields,
        });

        let allImages = document.querySelectorAll("img");
        let totalImages = allImages.length;

        allImages.forEach((item) => {
          totalImages = totalImages - 1;
          item.onload = () => { };
        });

        if (totalImages === 0) {
          this.setState({ loadPanelVisible: false });
        }
      });
    }
  }

  // Direciona para a página de editar o cupom com a id na url
  handleRedirectOnClick = (id) => {
    this.setState({ redirect: true, id: id });
  };

  // Reload a página após deletar um cupom
  handleReloadOnClick = () => {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("id");
    window.location.reload(false);
  };

  handleValueChange = (event, fieldId) => {

    console.log("Event ===>", event);
    console.log("FieldId ===>", fieldId);

    if (fieldId == "random") {
      // console.log("event ***************", event.target.checked); // Wed Dec 31 1969 04:00:00 GMT-0300 (Horário Padrão de Brasília)
    }

    if (fieldId == "fixedCode") {
      // console.log("Check FixedCode");
    }

    // console.log("fieldId ***************", fieldId); // Wed Dec 31 1969 04:00:00 GMT-0300 (Horário Padrão de Brasília)
    // console.log("event ***************", event.value); // Wed Dec 31 1969 04:00:00 GMT-0300 (Horário Padrão de Brasília)

    // console.log("Hour UTC", new Date(event.value).getUTCHours()); // Não posso usar UTC, pq consiera que sendo 4, estará somando +3 resultado em hora 7. Ver como isso deve ser feito para uma padrão internacional,
    // Continuar usando local time ou mudar para UTC mas padronizar a informação dentro do servidor.

    // console.log(
    //   "Hour Local Time",
    //   ("0" + new Date(event.value).getHours()).toString().slice(-2)
    // );
    // '0' + validTo_.getDate()).slice(-2)

    if (fieldId) {
      if (fieldId == "random") {
        // random não é devextreme
        this.setState({
          [fieldId]: event.target.checked,
        });
      } else if (fieldId == "subGroupId" || fieldId == "fields") {
        this.setState({
          [fieldId]: event.value ? event.value : [],
        });
        // } else if (fieldId == "initHour" || fieldId == "endHour") {
        //   this.setState({
        //     [fieldId]: event.value ? event.value.toString() : "0",
        //   });
      } else {
        this.setState({
          [fieldId]: event.value ? event.value.toString() : null,
        }, function () {
          console.log("initHour ***", this.state?.initHour)
          console.log("endHour ***", this.state?.endHour)
        });
      }
    }
  };

  handlecouponStatus(event) {
    // Atualizar Status Direto no Banco de Dados
    if (this.state.coupon._id) {
      if (
        this.state.random != true &&
        (this.state.fixedCode == "" || this.state.fixedCode == null)
      ) {
        // Não posso salvar, você precisa definir código automático ou definir um código fixo.
        this.showNotify(
          i18n.t("viewcoupons.support.notifyautoorfixedcode"),
          "error"
        );
        this.SpinnerOn(false);
        this.setState({ loadPanelVisible: false });
      } else if (
        parseFloat(this.state.sliderValue) < 1 &&
        (this.state.currencyValue === 0 ||
          this.state.currencyValue === undefined ||
          this.state.currencyValue === null)
      ) {
        this.showNotify(
          i18n.t("viewcoupons.support.notifycouponvoucher"),
          "error"
        );
        this.SpinnerOn(false);
        this.setState({ loadPanelVisible: false });
      } else if (
        this.state.title === "" ||
        this.state.title === undefined ||
        this.state.title === null
      ) {
        this.showNotify(
          i18n.t("viewcoupons.support.notifycoupontitle"),
          "error"
        );
        this.SpinnerOn(false);
        this.setState({ loadPanelVisible: false });

        // Se um dos intervalos for igual a Null e o outro não, faz com que o outro tb esteja Null, pois não é possível formar assim um intervalo de tempo.
      } else if (
        (this.state?.initHour === null ||
          this.state?.initHour === "0" ||
          this.state?.initHour === undefined) &&
        this.state?.endHour !== null &&
        this.state?.endHour !== "0" &&
        this.state?.endHour !== undefined
      ) {
        console.log("this.state.initHour 330", this.state?.initHour);
        console.log("this.state.endHour 330", this.state?.endHour);
        this.showNotify(
          i18n.t("viewcoupons.support.notifyhourinterval"),
          "error"
        );
        this.SpinnerOn(false);
        this.setState({ loadPanelVisible: false });
      } else if (
        (this.state?.endHour === null ||
          this.state?.endHour === "0" ||
          this.state?.endHour === undefined) &&
        this.state?.initHour !== null &&
        this.state?.initHour !== "0" &&
        this.state?.initHour !== undefined
      ) {
        console.log("this.state.initHour 347", this.state?.initHour);
        console.log("this.state.endHour 347", this.state?.endHour);
        this.showNotify(
          i18n.t("viewcoupons.support.notifyhourinterval"),
          "error"
        );
        this.SpinnerOn(false);
        this.setState({ loadPanelVisible: false });
      } else {
        this.setState(
          {
            couponStatus: event.target.checked ? 1 : 0,
          },
          () => this.proceedSaveConfigStatus()
        );
      }
    }
  }

  proceedSaveConfigStatus() {
    if (this.state.couponStatus === 0) {
      // Mantenho assim, pois se setar o status para zero, não vai fazer todo o processo abaixo de gravar tudo.

      proceedSaveConfigStatus(this.state.couponStatus, this.state.coupon._id)
        .then((res) => {

          if (res.status === 200) {
            if (res.data.status === 1) {
              this.showNotify(
                i18n.t("viewcoupons.support.notifyconfigactivestatus"),
                "success"
              );
            } else {
              this.showNotify(
                i18n.t("viewcoupons.support.notifyconfigdeactivestatus"),
                "success"
              );
            }
          }
        })
        .catch((error) => {
          this.showNotify(error, "error");
        });
    } else if (this.state.couponStatus === 1) {
      // Isso garante que ao atualizar o Status, não grave com Desconto 0%
      this.saveCouponConfigurations();
    }
  }

  // Gravar ou atualiza o Cupom
  saveCouponConfigurations = () => {
    if (this.state.coupon._id) {
      this.SpinnerOn(true, i18n.t("viewcoupons.support.messagesaving"));
      this.setState({ loadPanelVisible: true });

      if (
        this.state.random != true &&
        (this.state.fixedCode == "" || this.state.fixedCode == null)
      ) {
        // Não posso salvar, você precisa definir código automático ou definir um código fixo.
        this.showNotify(
          i18n.t("viewcoupons.support.notifyautoorfixedcode"),
          "error"
        );
        this.SpinnerOn(false);
        this.setState({ loadPanelVisible: false });
      } else if (
        parseFloat(this.state.sliderValue) < 1 &&
        (this.state.currencyValue === 0 ||
          this.state.currencyValue === undefined ||
          this.state.currencyValue === null)
      ) {
        this.showNotify(
          i18n.t("viewcoupons.support.notifycouponvoucher"),
          "error"
        );
        this.SpinnerOn(false);
        this.setState({ loadPanelVisible: false });
      } else if (
        this.state.title === "" ||
        this.state.title === undefined ||
        this.state.title === null
      ) {
        this.showNotify(
          i18n.t("viewcoupons.support.notifycoupontitle"),
          "error"
        );
        this.SpinnerOn(false);
        this.setState({ loadPanelVisible: false });

        // Se um dos intervalos for igual a Null e o outro não, faz com que o outro tb esteja Null, pois não é possível formar assim um intervalo de tempo.
      } else if (
        (this.state?.initHour === null ||
          this.state?.initHour === "0" ||
          this.state?.initHour === undefined || this.state?.initHour === "") &&
        this.state?.endHour !== null &&
        this.state?.endHour !== "0" &&
        this.state?.endHour !== undefined && this.state?.endHour !== ""
      ) {
        console.log("this.state.initHour 445", this.state?.initHour);
        console.log("this.state.endHour 445", this.state?.endHour);
        this.showNotify(
          i18n.t("viewcoupons.support.notifyhourinterval"),
          "error"
        );
        this.SpinnerOn(false);
        this.setState({ loadPanelVisible: false });
      } else if (
        (this.state?.endHour === null ||
          this.state?.endHour === "0" ||
          this.state?.endHour === undefined || this.state?.endHour === "") &&
        this.state?.initHour !== null &&
        this.state?.initHour !== "0" &&
        this.state?.initHour !== undefined && this.state?.initHour !== ""
      ) {
        console.log("this.state.initHour 461", this.state?.initHour);
        console.log("this.state.endHour 461", this.state?.endHour);
        this.showNotify(
          i18n.t("viewcoupons.support.notifyhourinterval"),
          "error"
        );
        this.SpinnerOn(false);
        this.setState({ loadPanelVisible: false });
      } else {
        /// Construir o Cupom
        let validFrom;
        let validTo;

        if (this.state.initDate === null) {
          this.setState({
            initDate: "0",
          });
        }
        if (this.state.endDate === null) {
          this.setState({
            endDate: "0",
          });
        }

        // Se um dos intervalos for igual a Null e o outro não, faz com que o outro tb esteja Null, pois não é possível formar assim um intervalo de tempo.
        if (this.state?.initHour === null && this.state?.endHour !== null) {
          this.setState({
            initHour: "0",
            endHour: "0",
          });
        }
        if (this.state?.initHour !== null && this.state?.endHour === null) {
          this.setState({
            initHour: "0",
            endHour: "0",
          });
        }

        if (this.state?.initDate && this.state?.initDate !== "0") {
          validFrom = new Date(this.state.initDate);
        }

        if (this.state?.endDate && this.state?.endDate !== "0") {
          validTo = new Date(this.state.endDate);
        }

        // Preparando os valores para serem inseridos nos controles de Data e Hora do Devexpress
        let initDate_ =
          this.state.initDate != "0"
            ? validFrom.getFullYear() +
            "-" +
            ("0" + (validFrom.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + validFrom.getDate()).slice(-2)
            : "0"; // this.state.initDate,
        let endDate_ =
          this.state.endDate != "0"
            ? validTo.getFullYear() +
            "-" +
            ("0" + (validTo.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + validTo.getDate()).slice(-2)
            : "0";

        let initHour_;
        console.log("this.state?.initHour =====>", this.state?.initHour)
        console.log("this.state?.initHour new Date( =====>", new Date(this.state?.initHour))
        if (this.state?.initHour && this.state?.initHour !== "0") {
          initHour_ =
            this.state?.initHour != "0"
              ? ("0" + new Date(this.state?.initHour).getHours())
                .toString()
                .slice(-2) +
              ":" +
              ("0" + new Date(this.state?.initHour).getMinutes())
                .toString()
                .slice(-2)
              : "0";
        } else {
          initHour_ = "0"
        }

        console.log("this.state.endHour 524", this.state?.endHour)
        let endHour_;

        if (this.state?.endHour && this.state?.endHour !== "0") {
          endHour_ = ("0" + new Date(this.state?.endHour).getHours())
            .toString()
            .slice(-2) +
            ":" +
            ("0" + new Date(this.state?.endHour).getMinutes())
              .toString()
              .slice(-2);
        } else {
          endHour_ = "0"
        }

        let coupon = {
          title: this.state.title,
          description: this.state.description,
          originalPrice: "0",
          discountPrice: "0",
          discount: String(this.state.sliderValue),
          minimumPurchase: "0",
          voucher: this.state.currencyValue ? this.state.currencyValue : 0,
          limit: this.state.limit ? this.state.limit : 0,
          random: this.state.random,
          fixedCode: this.state.fixedCode,
          initDate: initDate_,
          endDate: endDate_,
          initHour: initHour_,
          endHour: endHour_,
          email: "0",
          condiction: this.state.terms,
          subGroupId: this.state.subGroupId,
          fields: this.state.fields,
          status: this.state.couponStatus ? this.state.couponStatus : 0,
        };

        if (this.state.coupon._id != "" && this.state.coupon._id.length > 0) {
          // Atualiza o Cupom Atual
          proceedSaveConfig(coupon, this.state.coupon._id)
            .then((res) => {
              if (res) {
                setTimeout(() => {
                  this.SpinnerOn(false);
                  this.setState({ loadPanelVisible: false });
                  this.SpinnerOn(
                    true,
                    i18n.t("viewcoupons.support.messageupdating")
                  );
                  setTimeout(() => {
                    this.SpinnerOn(false);
                  }, 1000);
                }, 1000);
              } else {
                this.SpinnerOn(false);
                this.showNotify("ERRO!", "error");
              }
            })
            .catch((err) => {

              if (err.response.data === "Cupom Code Already Exists!") {
                this.showNotify(
                  i18n.t("viewcoupons.support.notifycodealready"),
                  "error"
                );
              } else {
                this.showNotify(err.response.data, "error");
              }

              this.setState({ loadPanelVisible: false });
              this.SpinnerOn(false);
            });
        } else {
          /// Erro
        }
      }
    } else {
      // Selecione um cupom
      this.showNotify(
        i18n.t("viewcoupons.support.notifyselectcoupon"),
        "error"
      );
      this.SpinnerOn(false);
      this.setState({ loadPanelVisible: false });
    }
  };

  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "top", offset: "0 200" },
        closeOnClick: true,
      },
      type,
      3000
    );
  }

  SpinnerOn(on, message) {
    if (on == true) {
      Spinner.style.display = "block";
      Spinner.innerHtml = "block";

      if (message) {
        this.setState({
          messageClass: "show",
          messageText: message,
        });
      }
    } else {
      Spinner.style.display = "none";
      setTimeout(() => {
        this.setState({
          messageClass: "fade",
          messageText: "",
        });
      }, 4000);
    }
  }

  // log(locale) {
  //     console.log(`${new Intl.DateTimeFormat(locale).format(date)} ${new Intl.NumberFormat(locale).format(count)}`);
  // }

  Item(data) {
    return (
      <div className="coupon custom-item" style={{ height: "40px" }}>
        <div className="row" style={{ height: "40px" }}>
          <div className="col-4" style={{ height: "40px" }}>
            {/* <img src={data.logoURL} /> */}
            <div
              className="coupon form-avatar"
              style={{ backgroundImage: `url(${data.logoURL})` }}
            ></div>
          </div>
          <div className="col-8" style={{ height: "40px" }}>
            <div className="" style={{ paddingTop: "11px" }}>
              {data.companyName2}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    // this.getCoupon(this.props.viewCouponSupportReducer[0].element)

    //this.log("en-US");
    // expected output: 5/24/2012 26,254.39

    //this.log("pt-BR");
    // expected output: 24.5.2012 26.254,39

    if (this.state.redirect == true) {
      if (this.state.id != "") {
        return <Redirect push to={`/studio?id=${this.state.id}`} />;
      } else {
        // Reload depois de deletar o cupom.
        return <Redirect push to="/viewcoupons" />;
      }
    }

    // canvas: "{"version":"3.6.3","objects":[{"type":"image","ver"
    // condiction: "createCoupon.condiction.value"
    // createdAt: 1588204934
    // createdBy: "5e682c6bdf4ddb4ff4cc8a3a"
    // description: "createCoupon.description.value"
    // discount: "createCoupon.discount.value"
    // discountPrice: "0"
    // endDate: "0"
    // image: "https://coupon-images-bash.s3.amazonaws.com/6f83532c-2d7d-482f-96dc-dcc222b92b93.jpg"
    // initDate: "0"
    // minimumPurchase: "0"
    // originalPrice: "0"
    // rootId: "9ba30363-2a6b-5137-bf7b-6d131c3f2917"
    // status: 1
    // title: "createCoupon.title.value"
    // __v: 0
    // _id: "5eb087b5d61c3020243351c0"

    return (
      <div style={{ height: "700px" }}>
        <div
          id="supportMenu"
          className="col-12"
          style={{
            textAlign: "center",
            paddingRight: "0px",
            paddingLeft: "0px",
          }}
        >
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            // position={position}
            // onHiding={this.hideLoadPanel}
            visible={this.state.loadPanelVisible}
            showIndicator={true}
            shading={true}
            showPane={true}
            closeOnOutsideClick={true}
            height={250}
          />
          <Modal
            modalId="delete-header-modal"
            title={i18n.t("viewcoupons.support.modaldeletetitle")}
            h5Content={i18n.t("viewcoupons.support.modaldeleteh5")}
            content={i18n.t("viewcoupons.support.modaldeletecontent")}
            cancelText={i18n.t("viewcoupons.support.modaldeletecanceltext")}
            okText={i18n.t("viewcoupons.support.modaldeleteoktext")}
            actionType="deleteCoupon"
            extra={this.state.coupon._id}
          />

          <div
            className=""
            style={{
              width: "260px",
              height: "194px",
              margin: "0 auto",
              background: "whitesmoke",
            }}
          >
            <img
              src={
                this.state.coupon.imageurlThumb +
                "?" +
                (this.state.coupon.updated ? this.state.coupon.updated : null)
              }
              alt=""
              style={{ maxWidth: "100%", minWidth: "80%", height: "auto" }}
            />
          </div>

          <div
            style={{
              display: "block",
              height: "25px",
              margin: "0px auto",
              width: "260px",
            }}
          >
            <div
              className="custom-control custom-switch"
              style={{ float: "left" }}
            >
              <input
                id="ckb_couponStatus"
                type="checkbox"
                className="custom-control-input active"
                checked={this.state.couponStatus == 1 ? true : false}
                onChange={(event) => {
                  this.handlecouponStatus(event);
                }}
                readOnly={this.state.coupon._id ? false : true}
              />
              <label
                title={
                  this.state.couponStatus == 1
                    ? i18n.t("viewcoupons.support.swhitdeactivelabel")
                    : i18n.t("viewcoupons.support.swhitactivelabel")
                }
                className="custom-control-label active"
                style={{ fontSize: "14px", color: "green" }}
                htmlFor="ckb_couponStatus"
              ></label>
            </div>
            <div id="loading" style={{ display: "none", textAlign: "right" }}>
              <span
                className={`badge badge-info ${this.state.messageClass}`}
                style={{ marginRight: "6%" }}
              >
                {this.state.messageText}
              </span>
              <div
                className="spinner-grow spinner-grow-sm"
                style={{ color: "#d4453a" }}
                role="status"
              >
                <span className="sr-only"></span>
              </div>
              <div
                className="spinner-grow spinner-grow-sm"
                style={{ color: "#d4453a" }}
                role="status"
              >
                <span className="sr-only"></span>
              </div>
              <div
                className="spinner-grow spinner-grow-sm"
                style={{ color: "#d4453a" }}
                role="status"
              >
                <span className="sr-only"></span>
              </div>
            </div>
          </div>

          {/* <div> */}
          <div className="form">
            <div className="dx-fieldset" style={{ marginTop: "10px" }}>
              <div
              // style={{marginBottom: '0.5rem'}}
              >
                <strong>{i18n.t("viewcoupons.support.maintitle")}</strong>
              </div>
            </div>

            <div
              className=""
              style={{
                marginBottom: "0px",
                minHeight: "30px",
                zIndex: "999",
                float: "right",
                paddingRight: "0px",
              }}
            >
              <Button
                disabled={this.state.coupon._id ? false : true}
                width={100}
                text={i18n.t("viewcoupons.support.btnsave")}
                type="default"
                stylingMode="contained"
                style={{ marginLeft: "3px", fontSize: "10px" }}
                onClick={() => this.saveCouponConfigurations()}
              />
              <Button
                disabled={this.state.coupon._id ? false : true}
                width={100}
                text={i18n.t("viewcoupons.support.btnedit")}
                type="default"
                stylingMode="outlined"
                style={{ marginLeft: "3px", fontSize: "10px" }}
                onClick={() =>
                  this.handleRedirectOnClick(this.state.coupon._id)
                }
              />
              <a
                id={2}
                title={"item.title"}
                className="icons-list-demo"
                style={{ marginLeft: "4px", cursor: "pointer", zIndex: "999" }}
                data-toggle="modal"
                data-target="#delete-header-modal"
              >
                <svg
                  id={2 + 15}
                  aria-hidden="true"
                  focusable="false"
                  width="22px"
                  height="22px"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    d={
                      "M15 16h4v2h-4v-2m0-8h7v2h-7V8m0 4h6v2h-6v-2m-4-2v8H5v-8h6m2-2H3v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V8m1-3h-3l-1-1H6L5 5H2v2h12V5z"
                    }
                    fill={"#ff0000"}
                  />
                </svg>
              </a>
            </div>

            <br />

            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("viewcoupons.support.title")}
              </div>
              <div className="dx-field-value">
                <TextBox
                  defaultValue=""
                  readOnly={this.state.coupon._id ? false : true}
                  showClearButton={true}
                  placeholder={i18n.t("viewcoupons.support.titleplace")}
                  value={this.state.title}
                  onValueChanged={(event) =>
                    this.handleValueChange(event, "title")
                  }
                >
                  <Validator>
                    <RequiredRule
                      message={i18n.t("viewcoupons.support.validtitleplace")}
                    />
                  </Validator>
                </TextBox>
              </div>
            </div>

            <div className="dx-field">
              <div className="dx-field custom-height-slider">
                <div className="dx-field-label">
                  {i18n.t("viewcoupons.support.adjust")}
                </div>
                <div className="dx-field-value">
                  <Slider
                    min={0}
                    max={100}
                    value={
                      this.state.sliderValue
                        ? parseFloat(this.state.sliderValue)
                        : 0
                    }
                    onValueChanged={this.setSliderValue}
                    readOnly={this.state.coupon._id ? false : true}
                    tooltip={{
                      enabled: false,
                      showMode: "onHover",
                      position: "bottom",
                      format,
                    }}
                  />
                </div>
              </div>
            </div>
            <p></p>
            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("viewcoupons.support.discount")}
              </div>
              <div className="dx-field-value">
                <div
                  className=""
                  style={{ height: "35px", paddingLeft: "11%" }}
                >
                  {
                    <div className="discount" style={{ marginTop: "0px" }}>
                      -{this.state.sliderValue ? this.state.sliderValue : 0}%
                    </div>
                  }
                </div>
              </div>
            </div>

            {/* Randômico */}

            <p></p>
            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("viewcoupons.support.fixedcode")}
              </div>
              <div className="dx-field-value">
                <div
                  style={{
                    display: "block",
                    height: "25px",
                    margin: "0px auto",
                  }}
                >
                  <div
                    className="custom-control custom-switch"
                    style={{ float: "left", paddingTop: "3px" }}
                  >
                    <input
                      id="ckb_random"
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.random ? this.state.random : false} //{this.state.random ? (this.state.random == true ? true : false) : true}
                      onChange={(event) => {
                        this.handleValueChange(event, "random");
                      }}
                    />
                    <label
                      title={
                        this.state.random == false
                          ? i18n.t("viewcoupons.support.swhitdeactivelabel")
                          : i18n.t("viewcoupons.support.swhitactivelabel")
                      }
                      className="custom-control-label red"
                      style={{ fontSize: "12px", paddingTop: "5px" }}
                      htmlFor="ckb_random"
                      readOnly={this.state.coupon._id ? false : true}
                    >
                      {i18n.t("viewcoupons.support.autocode")}
                    </label>
                  </div>
                </div>
                {/* <TextBox defaultValue="" showClearButton={true} placeholder="Ex: PROMO20" value={this.state.fixedCode} onValueChanged={(event) => this.handleValueChange(event, 'fixedCode')} /> */}
              </div>
            </div>

            {/* Codigo Fixo */}
            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("viewcoupons.support.fixedcode")}
              </div>
              <div
                className="dx-field-value code"
                style={{ fontFamily: "Impact" }}
              >
                <TextBox
                  className=""
                  defaultValue=""
                  isValid={
                    this.state.random != true
                      ? this.state.fixedCode
                        ? true
                        : false
                      : true
                  }
                  showClearButton={true}
                  readOnly={this.state.coupon._id ? false : true}
                  placeholder="Ex: PROMO20"
                  style={{ fontFamily: "Impact", background: "yellow" }}
                  disabled={this.state.random} //{this.state.random ? (this.state.random == true ? true : false) : true}
                  // valueChangeEvent="keyup"
                  value={
                    this.state.random != true
                      ? this.state.fixedCode
                        ? this.state.fixedCode.toString().toUpperCase()
                        : ""
                      : ""
                  }
                  onValueChanged={(event) =>
                    this.handleValueChange(event, "fixedCode")
                  }
                />
              </div>
            </div>

            {/* <div style={{display: 'block', height: '25px', margin: '0px auto', width: '260px'}}>
            <div className="custom-control custom-switch" style={{float: 'left'}}>
            <input id="ckb_couponStatus" type="checkbox" className="custom-control-input" checked={this.state.couponStatus == 1 ? true : false} onChange={(event) => {this.handlecouponStatus(event)}} />
            <label title={this.state.couponStatus == 1 ? 'Desativar' : 'Ativar'} className="custom-control-label red" style={{fontSize: '14px', color: 'green'}} htmlFor="ckb_couponStatus">
            </label>
            </div>
        </div> */}

            {/* Limite de Cupons */}

            <div className="dx-field">
              <div className="dx-field-label" style={{ paddingRight: "0px" }}>
                {i18n.t("viewcoupons.support.couponlimit")}
              </div>
              <div className="dx-field-value">
                <NumberBox
                  showClearButton={true}
                  showSpinButtons={true}
                  placeholder={i18n.t("viewcoupons.support.couponlimitplace")}
                  onValueChanged={(event) =>
                    this.handleValueChange(event, "limit")
                  }
                  value={this.state.limit == 0 ? null : this.state.limit}
                  readOnly={this.state.coupon._id ? false : true}
                  min={1}
                >
                  {/* <NumberBoxButton name="clear" /> */}
                  <NumberBoxButton name="spins" />
                </NumberBox>
              </div>
            </div>

            {/* https://js.devexpress.com/Demos/WidgetsGallery/Demo/Common/CustomTextEditorButtons/React/Light/ */}
            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("viewcoupons.support.voucher")}
              </div>
              <div className="dx-field-value">
                <NumberBox
                  showClearButton={true}
                  // showSpinButtons={true}
                  // format={this.state.currencyFormat}
                  format={
                    localStorage.getItem("i18nextLng") == "pt-BR"
                      ? "R$ #.##0,##"
                      : ""
                  }
                  // precision="2"
                  // format={ style: "currency", currency: "EUR", useGrouping: true }
                  // format="currency"
                  minimumSignificantDigits="2"
                  currency={localStorage.getItem("i18nextLng")}
                  //value={new Intl.NumberFormat('locale').format((this.state.currencyValue ? this.state.currencyValue : 0).toFixed(2))}
                  // value={(this.state.currencyValue ? this.state.currencyValue : 0).toFixed(2)}
                  value={
                    this.state.currencyValue ? this.state.currencyValue : 0
                  }
                  onValueChanged={this.changeCurrency}
                  onFocusIn={() => this.setState({ currencyValue: 0 })}
                  readOnly={this.state.coupon._id ? false : true}
                >
                  {/* <NumberBoxButton
            name="currency"
            location="after"
            options={this.currencyButton}
        /> */}
                  <NumberBoxButton name="clear" />
                  {/* <NumberBoxButton name="spins" /> */}
                </NumberBox>
              </div>
            </div>

            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("viewcoupons.support.startdate")}
              </div>
              <DateBox
                readOnly={this.state.coupon._id ? false : true}
                className="dx-field-value"
                max={this.state.endDate ? this.state.endDate == "0"
                  ? null
                  : new Date(this.state.endDate)
                  : null
                }
                type="date"
                value={
                  this.state.initDate
                    ? this.state.initDate == "0"
                      ? null
                      : new Date(this.state.initDate)
                    : null
                }
                showClearButton={true}
                placeholder={i18n.t("viewcoupons.support.enddataplace")}
                onValueChanged={(event) =>
                  this.handleValueChange(event, "initDate")
                }
              />
            </div>

            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("viewcoupons.support.enddata")}
              </div>
              <DateBox
                readOnly={this.state.coupon._id ? false : true}
                className="dx-field-value"
                min={this.state.initDate}
                type="date"
                value={
                  this.state.endDate
                    ? this.state.endDate == "0"
                      ? null
                      : new Date(this.state.endDate)
                    : null
                }
                showClearButton={true}
                placeholder={i18n.t("viewcoupons.support.enddataplace")}
                onValueChanged={(event) =>
                  this.handleValueChange(event, "endDate")
                }
              />
            </div>

            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("viewcoupons.support.starthour")}
              </div>
              <div className="dx-field-value">
                {console.log("this.state.endHour 1201", this.state.endHour)}
                {console.log("ternary 1202", this.state.endHour != "0" ? this.state.endHour : "0")}
                <DateBox
                  readOnly={this.state.coupon._id ? false : true}
                  type="time"
                  showClearButton={true}
                  // max={parseInt(this.state?.endHour) > 0 ? this.state?.endHour : "0"}
                  // max={this.state.initHour}
                  max={this.state?.endHour != "0" ? this.state?.endHour : ""}
                  value={
                    this.state?.initHour
                      ? this.state?.initHour == "0"
                        ? null
                        : new Date(this.state?.initHour)
                      : null
                  }
                  placeholder={i18n.t("viewcoupons.support.enddataplace")}
                  // defaultValue={null}
                  onValueChanged={(event) =>
                    this.handleValueChange(event, "initHour")
                  }
                />
              </div>
            </div>

            <div className="dx-field">
              <div className="dx-field-label">

                {i18n.t("viewcoupons.support.endhour")}
              </div>
              <div className="dx-field-value">
                <DateBox
                  readOnly={this.state.coupon._id ? false : true}
                  type="time"
                  min={this.state?.initHour ? this.state?.initHour : "0"}
                  showClearButton={true}
                  value={
                    this.state?.endHour
                      ? this.state?.endHour == "0"
                        ? null
                        : new Date(this.state?.endHour)
                      : null
                  }
                  placeholder={i18n.t("viewcoupons.support.enddataplace")}
                  onValueChanged={(event) =>
                    this.handleValueChange(event, "endHour")
                  }
                />
              </div>
            </div>

            {/* Estabelecimentos Válidos */}
            <TagBox
              dataSource={this.dataSource}
              readOnly={this.state.coupon._id ? false : true}
              className="redHeight"
              displayExpr="companyName2"
              valueExpr="_id"
              itemRender={this.Item}
              placeholder={i18n.t("viewcoupons.support.brachruleplace")}
              onValueChanged={(event) =>
                this.handleValueChange(event, "subGroupId")
              }
              value={this.state.subGroupId}
            />
            <span className="text-muted" style={{ fontSize: "11px" }}>
              {i18n.t("viewcoupons.support.branchrule")}
            </span>
            {/* Estabelecimentos Válidos */}

            {/* Campos de Contato */}
            <TagBox
              dataSource={this.state.dataFields}
              readOnly={this.state.coupon._id ? false : true}
              className="redHeight"
              displayExpr="fieldName"
              valueExpr="_id"
              // itemRender={this.Item}
              placeholder={i18n.t("viewcoupons.support.bhancruleplace2")}
              onValueChanged={(event) =>
                this.handleValueChange(event, "fields")
              }
              value={this.state.fields}
            />
            <span className="text-muted" style={{ fontSize: "11px" }}>
              {i18n.t("viewcoupons.support.requreidfieldsdesc")}
            </span>
            {/* Campos de Contato */}

            <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
              <strong>{i18n.t("viewcoupons.support.description")}</strong>
            </div>
            <div className="dx-fieldset" id="notes-container">
              <div className="dx-field">
                <TextArea
                  className="redHeight2 dx-field-value"
                  height={300}
                  width={"100%"}
                  onValueChanged={(event) =>
                    this.handleValueChange(event, "description")
                  }
                  value={this.state.description}
                  readOnly={this.state.coupon._id ? false : true}
                />
              </div>
            </div>
            <div style={{ marginTop: "4rem", marginBottom: "1rem" }}>
              <strong>{i18n.t("viewcoupons.support.terms")}</strong>
            </div>
            <div className="dx-fieldset" id="notes-container">
              <div className="dx-field">
                <TextArea
                  className="redHeight2 dx-field-value"
                  height={300}
                  width={"100%"}
                  onValueChanged={(event) =>
                    this.handleValueChange(event, "terms")
                  }
                  value={this.state.terms}
                  readOnly={this.state.coupon._id ? false : true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  setSliderValue({ value }) {
    this.setState({ sliderValue: value });
  }

  // valueChanged(e) {
  //     console.log('e.value', e.value);
  //     this.setState({
  //         couponStatus: e.value
  //     });
  // }
}

function format(value) {
  return `${value}%`;
}

function mapStateToProps(state) {
  // mapStateToProps returns an object, with:
  // property is the local prop name to this component
  // value will be the property in the root reducer... ie, piece of the store
  return {
    viewCouponSupportReducer: state.viewCouponSupportReducer,
    modalControl: state.modalControl,
  };
}

function mapDispatchToProps(dispatch) {
  // this function return bindActionCreators
  // and we hand bindActionCreators an object
  // each property will be a local prop
  // each value will be a function that is dispatch when run
  // second arg of bindActionCreators is the dispatch
  return bindActionCreators(
    {
      updateViewCouponSupportMenu: updateViewCouponSupportMenu,
    },
    dispatch
  );
}

// export default CanvasComponent;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewCouponSupportMenu);
