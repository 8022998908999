export const temperaturesData = [
  {
    day: "1",
    value: 57,
  },
  {
    day: "2",
    value: 58,
  },
  {
    day: "3",
    value: 57,
  },
  {
    day: "4",
    value: 59,
  },
  {
    day: "5",
    value: 63,
  },
  {
    day: "6",
    value: 63,
  },
  {
    day: "7",
    value: 63,
  },
  {
    day: "8",
    value: 64,
  },
  {
    day: "9",
    value: 64,
  },
  {
    day: "10",
    value: 64,
  },
  {
    day: "11",
    value: 69,
  },
  {
    day: "12",
    value: 60,
  },
  {
    day: "13",
    value: 75,
  },
  {
    day: "14",
    value: 78,
  },
  {
    day: "15",
    value: 76,
  },
  {
    day: "16",
    value: 70,
  },
  {
    day: "17",
    value: 65,
  },
  {
    day: "18",
    value: 65,
  },
  {
    day: "19",
    value: 68,
  },
  {
    day: "20",
    value: 70,
  },
  {
    day: "21",
    value: 73,
  },
  {
    day: "22",
    value: 73,
  },
  {
    day: "23",
    value: 75,
  },
  {
    day: "24",
    value: 78,
  },
  {
    day: "25",
    value: 76,
  },
  {
    day: "26",
    value: 76,
  },
  {
    day: "27",
    value: 80,
  },
  {
    day: "28",
    value: 76,
  },
  {
    day: "29",
    value: 75,
  },
  {
    day: "30",
    value: 75,
  },
  {
    day: "31",
    value: 74,
  },
];
