import React, { Component } from "react";
import PhotoControl from "../CouponModule/BuildCanvas/PhotoControl";
import ViewCouponSupportMenu from "../CouponModule/ViewCoupon/ViewCouponSupportMenu";
import AccountSupportMenu from "../Account/AccountSupportMenu";
import LandingPageMenu from '../LandingPageModule/ViewPages/ViewLandingPagesMenu'
// import Mobil from "./MobilComponent";

import "./SupportMenu.css";

class SupportMenu extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let moduleElement;
    if (this.props.module == "Home") {
      moduleElement = "<p>Home</p>";
    } else if (this.props.module == "Coupon") {
      moduleElement = <PhotoControl />;
    } else if (this.props.module == "viewcoupons") {
      moduleElement = <ViewCouponSupportMenu />;
    } else if (this.props.module == "account") {
      moduleElement = <AccountSupportMenu />;
    } else if (this.props.module == "addoperators") {
      // moduleElement = <Mobil />
      moduleElement = "";
    } else if (this.props.module == "mylandingpages") {
      moduleElement = <LandingPageMenu />;
    } else {
      moduleElement = "";
    }

    return (
      <div className="supportMenu shadow bg-white">
        <div className="">
          <div
            className="card"
            style={{
              // height: '718px',
              borderRadius: "unser",
              border: "unset",
            }}
          >
            <div
              className="card-body container content"
              style={{
                // padding: '0.5rem',
                overflow: "auto",
                height: "85vh",
                position: "relative",
              }}
            >
              {moduleElement}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SupportMenu;
