import React, { Component } from "react";
import { Button } from "devextreme-react";
import { loadMessages, locale } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import {
  getCouponCode,
  getCoupons,
  registerCouponCode,
} from "../../../apis/toowezeApi";
import { TextBox, TextArea } from "devextreme-react";
import {
  NumberBox,
  Button as NumberBoxButton,
} from "devextreme-react/number-box";
import DateBox from "devextreme-react/date-box";
import { i18n, lgMessages, languageCode } from "../../../translate/i18n";
import updateEvent from "../../../apis/registerEventIPTem";

const moment = require("moment-timezone");

let showCoupon;

export default class ValidationMiddle extends Component {
  constructor() {
    super();
    // Configura o idioma
    loadMessages(lgMessages);
    locale(languageCode);

    this.state = {
      code: "",
      fixedCode1: "",
      fixedCode2: "",
      fixedCode3: "",
      sliderValue: 0,
      finalValue: 0,
      random: true,
      emailrequired: false,
    };
  }

  componentDidMount() {
    /// **** PEDE PERMISSÃO A PRIMEIRA VEZ, MAS FUNCIONA
    // navigator.clipboard.readText()
    // .then(text => {
    //     console.log('Pasted content: ', text);
    //     if (text) {
    //         this.setState({
    //             //JVPQ-BDVF-654T
    //             fixedCode1: text.split('-')[0],
    //             fixedCode2: text.split('-')[1],
    //             fixedCode3: text.split('-')[2]
    //         })
    //     }
    // })
    // .catch(err => {
    //     console.error('Failed to read clipboard contents: ', err);
    // });
    /// **** PEDE PERMISSÃO A PRIMEIRA VEZ, MAS FUNCIONA
  }

  // Valida o código do cupom
  getCouponCode = () => {
    if (
      (this.state.random === true &&
        this.state.fixedCode1 &&
        this.state.fixedCode2 &&
        this.state.fixedCode3) ||
      (this.state.random === false && this.state.fixedCode1)
    ) {
      let checkCode;
      if (this.state.random === true) {
        checkCode =
          this.state.fixedCode1 +
          "-" +
          this.state.fixedCode2 +
          "-" +
          this.state.fixedCode3;
      } else {
        checkCode = this.state.fixedCode1;
      }

      getCouponCode(checkCode)
        .then((res) => {
          // campaignId: "5ee391b5fed3ed44e8e6ffee"
          // code: "JVPQ-BDVF-654T"
          // contactList: "aa9f5b46-dcae-5365-8ff7-46d606c6564b"
          // couponId: "5eb084b0d61c3020243351bf"
          // createAt: 1591972009
          // email: "contatoteste@gnxp.com.br"
          // firstName: "Alan"
          // generateFor: "5"
          // lastName: "Silva"
          // operator: "5e682c6bdf4ddb4ff4cc8a3a"
          // redeemBy: "5"
          // rootId: "9ba30363-2a6b-5137-bf7b-6d131c3f2917"
          // used: true

          this.setState({
            email: res.data.email ? res.data.email : "",
            name:
              (res.data.firstName ? res.data.firstName : "") +
              " " +
              (res.data.lastName ? res.data.lastName : ""),
          });

          if (res.status == "200") {
            if (res.data.used !== true) {
              // couponCode = id;

              // getCoupons(res.data.couponId).then(res => {
              getCoupons(res.data.couponId, false)
                .then((res) => {
                  // Preparando os valores para serem inseridos nos controles de Data e Hora do Devexpress
                  // let initDate_ = res.initDate != '0' ? (new Date(res.initDate + ' 00:00')) : '0';
                  // let endDate_ = res.endDate != '0' ? (new Date(res.endDate + ' 00:00')) : '0';
                  // let initHour_ = res.initHour ?  (res.initHour.toString().includes(':') ? new Date().setHours(res.initHour.split(':')[0], res.initHour.split(':')[1]) : '0') : '0'; // O formato esperado é: 10:30
                  // let endHour_ = res.endHour ? (res.endHour.toString().includes(':') ? new Date().setHours(res.endHour.split(':')[0], res.endHour.split(':')[1]) : '0') : '0'; // O formato esperado é: 10:30

                  // Preparando os valores para serem inseridos nos controles de Data e Hora do Devexpress

                  console.log("resinitDate 122", res.initDate);
                  console.log("endDate 122", res.endDate);

                  console.log("new Date(res.initDate", new Date(res.initDate + "T00:00:01"))
                  console.log("new Date(res.endDate", new Date(res.endDate + "T23:59:59"))

                  let initDate_ = res.initDate
                    ? res.initDate != "0"
                      ? new Date(res.initDate + "T00:00:01")
                      : "0"
                    : "0";
                  let endDate_ = res.endDate
                    ? res.endDate != "0"
                      ? new Date(res.endDate + "T23:59:59")
                      : "0"
                    : "0";
                  let initHour_ = res.initHour
                    ? res.initHour
                      ? res.initHour.toString().includes(":")
                        ? new Date().setHours(
                          res.initHour.split(":")[0],
                          res.initHour.split(":")[1]
                        )
                        : "0"
                      : "0"
                    : "0"; // O formato esperado é: 10:30
                  let endHour_ = res.endHour
                    ? res.endHour
                      ? res.endHour.toString().includes(":")
                        ? new Date().setHours(
                          res.endHour.split(":")[0],
                          res.endHour.split(":")[1]
                        )
                        : "0"
                      : "0"
                    : "0"; // O formato esperado é: 10:30

                  // Checa se é tipo data, pq o controle do devexpress dá erro qdo entra um valor inválido
                  if (!(initDate_ instanceof Date)) {
                    initDate_ = "0";
                  }
                  if (!(endDate_ instanceof Date)) {
                    endDate_ = "0";
                  }

                  // if (!(initHour_ instanceof Date)) {
                  //     initHour_ = '0';
                  // }
                  // if (!(endHour_ instanceof Date)) {
                  //     endHour_ = '0';
                  // }

                  this.setState({
                    coupon: res,
                    title: res.title,
                    description: res.description,
                    originalPrice: res.originalPrice,
                    discountPrice: res.discountPrice,
                    sliderValue: res.discount,
                    minimumPurchase: res.minimumPurchase,
                    currencyValue: res.voucher,
                    limit: res.limit,
                    //random: (res.random == null ? false : res.random),
                    fixedCode: res.fixedCode == null ? "" : res.fixedCode,
                    initDate: initDate_,
                    endDate: endDate_,
                    initHour: initHour_, // O formato esperado é: 10:30
                    endHour: endHour_, // O formato esperado é: 10:30
                    terms: res.condiction,
                    couponStatus: res.status,
                  });

                  // this.setState({
                  //     showCoupon: res
                  // })

                  if (res.fields.length > 0) {
                    //resolve('OK')
                    res.fields.forEach((item) => {
                      if (item === "1") {
                        ///reject('Campo Nome obrigatório!')
                      } else if (item === "2") {
                        ///reject('Campo Email obrigatório!')
                        this.setState({
                          emailrequired: true,
                        });
                      }
                    });
                  }

                  if (res.status == "200") {
                    // let groupDiv = document.getElementById('myCoupons')
                    // groupDiv.innerHTML = '';
                    // let completeDiv = '';
                    // let coupon = res.data;
                    //if (coupon.image) {
                    // <div class="card-body" style="border: 2px; border-style: solid;margin-bottom: 1px;border-color: whitesmoke;">
                    //     <div class="dropdown card-widgets">
                    //     </div>
                    //     <img id="img_" data-id="${coupon._id}" data-rootid="${coupon.rootId}" class="couponImage" src="${coupon.image}" style="width: 80%; height: auto;">
                    //     <ul class="list-group list-group-flush">
                    //     </div>
                    //      if (coupon.status) {
                    // showCoupon = <div className="col-md-8 col-xl-8" style={{float: 'left',padding: '3px',margin: '0 auto',paddingTop: '21px'}}>
                    // <div id={this.state.showCoupon._id} className="d-block shadow bg-white borderClass ">
                    // <div className="">
                    // <div className="input-group-append" style={{height: '28px', float: 'right'}}>
                    // </div>
                    // <img id="img_" src={this.state.showCoupon.image} style={{width: '100%', height: 'auto'}} />
                    // </div>
                    // </div>
                    // </div>
                    // completeDiv = `
                    // <img id="img_" data-id="${coupon._id}" data-rootid="${coupon.rootId}" class="couponImage" src="${coupon.image}" style="max-width: 400px; height: auto;">
                    // `;
                    // condiction: "undefined"
                    // createdAt: 1562760206
                    // createdBy: "5c8a5ea5c9ddf9096c89b8dd"
                    // description: "Código Exclusivo Via Banner Facebook"
                    // discount: "22,00"
                    // discountPrice: "21,00"
                    // endDate: " 07/12/2019"
                    // groupId: null
                    // image: "https://coupon-images-bash.s3.amazonaws.com/c3e4af4f-3359-404f-b561-bf890dcfa3cb.jpg"
                    // initDate: "07/12/2019 "
                    // minimumPurchase: "23,00"
                    // originalPrice: "20,00"
                    // rootId: "5525"
                    // status: 1
                    // subGroupId: null
                    // title: "Nova Coleção"
                    // __v: 0
                    // _id: "5d266427b297db365024b709"
                    //          if (coupon.minimumPurchase > 0) {
                    // tem um valor mínimo.
                    //        }
                    //        if (coupon.discount) {
                    // informa o desconto em percentual.
                    //  validate.lbl_discount.innerText = 'Desconto de: ' + coupon.discount + ' %';
                    //       }
                    //    }
                    //   }
                    //couponImage = coupon.image;
                    //  groupDiv.insertAdjacentHTML('afterbegin', completeDiv);
                    //imageDowload(coupon.image);
                    //  readImage(coupon.image);
                  }
                })
                .catch((error) => {
                  this.showNotify(error.message, "error");
                });
              // validate.lbl_name.innerText = 'Cupom gerado para: ' + res.data.firstName + ' ' + (res.data.lastName ? res.data.lastName: '');
              // validate.lbl_email.innerText = 'Email: ' + res.data.email;
              // validate.lbl_date.innerText = 'Gerado em: ' + convertDate(res.data.createAt, 'timestamp');
              // validate.btn_registerCode.style.display = 'block';
              // validate.btn_clear2.style.display = 'block';
              // validate.btn_checkCode.style.display = 'none';
              // validate.btn_clear.style.display = 'none';
              // validate.seq_1.disabled = true;
              // validate.seq_2.disabled = true;
              // validate.seq_3.disabled = true;
            } else {
              this.showNotify(
                i18n.t("validation.notify.couponalreadyused"),
                "error"
              );
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 401) {
              this.showNotify(i18n.t("validation.notify.autherror"), "error");
            } else if (error.response.status == 404) {
              if (error.response.data == "Inactive Campaign") {
                this.showNotify(
                  i18n.t("validation.notify.inactivecampaign"),
                  "error"
                );
              } else if (error.response.data == "Coupon already used!") {
                this.showNotify(
                  i18n.t("validation.notify.couponalreadyused"),
                  "error"
                );
              } else if (error.response.data == "Inactive Coupon") {
                this.showNotify(
                  i18n.t("validation.notify.inactivecoupon"),
                  "error"
                );
              } else if (
                error.response.data == "Anonymous validation not allowed"
              ) {
                this.showNotify(
                  i18n.t("validation.notify.requiredfieldemail"),
                  "error"
                );
              } else {
                this.showNotify(
                  i18n.t("validation.notify.couponnotfound"),
                  "error"
                );
              }
            } else {
              this.showNotify(error.response.data, "error");
            }
          }
        });
    } else {
      this.showNotify(
        i18n.t("validation.notify.requiredfieldcouponcode"),
        "error"
      );
    }

    // if (this.state.code && this.state.code != '') {
    //     getCouponCode(this.state.code).then(res => {
    //         console.log('getCouponCode', res);
    //     }).catch(error => {
    //         console.log('getCouponCode error', error);
    //     })
    // } else {
    //     this.showNotify('Preencha o código do cupom!', 'error')
    // }
  };

  // Reseta todos os campos
  reset = () => {
    this.setState({
      coupon: "",
      fixedCode1: "",
      fixedCode2: "",
      fixedCode3: "",
      title: "",
      description: "",
      originalPrice: "",
      discountPrice: "",
      sliderValue: 0,
      minimumPurchase: 0,
      currencyValue: 0,
      limit: 0,
      //random: null,
      fixedCode: null,
      initDate: 0,
      endDate: 0,
      initHour: 0,
      endHour: 0,
      email: "",
      name: "",
      terms: "",
      couponStatus: 0,
      purchase: 0,
      finalValue: 0,
    });

    // let directUrl = window.location.href;
    // if (directUrl.indexOf("?") > 0) {
    //     directUrl = directUrl.substring(0, directUrl.indexOf("?"));
    //     window.history.replaceState({}, document.title, directUrl);
    //     //window.location.href = directUrl;
    // }
  };

  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "top", offset: "0 200" },
        closeOnClick: true,
      },
      type,
      3000
    );
  }

  // Administra as mudanças de valores dos TextBox
  handleValueChange = (event, fieldId) => {
    if (fieldId) {
      if (
        fieldId === "fixedCode1" ||
        fieldId === "fixedCode2" ||
        fieldId === "fixedCode3"
      ) {
        this.setState({
          [fieldId]: event.value ? event.value.toString().toUpperCase() : null,
        });
      } else if (fieldId !== "purchase2" && fieldId !== "random") {
        this.setState({
          [fieldId]: event.value ? event.value : null,
        });
      } else if (fieldId === "random") {
        // random não é devextreme
        this.reset();
        this.setState({
          [fieldId]: event.target.checked,
        });
      }

      // Se alterar o código dos campoas, deleta o cupom que já foi apresentado.
      if (
        fieldId === "fixedCode1" ||
        fieldId === "fixedCode2" ||
        fieldId === "fixedCode3"
      ) {
        // Se estiver exibindo o cupom já, então reseta tudo
        if (this.state.coupon) {
          this.reset();
        }
      }
    }
  };

  ///http://hackerzone.com.br/blog/trabalhar-com-valor-monetario-em-javascript/

  onKeyPress(event, fieldId) {
    let finalValue = event.event.currentTarget.value;

    if (event.event.key === "Backspace") {
      finalValue = finalValue.substring(0, finalValue.length - 1);
    } else {
      //finalValue = event.event.currentTarget.value + event.event.key;
    }

    // TESTE REGEX 2
    var valor = finalValue;
    //+ event.event.key;
    var v = valor.replace(/\D/g, "");
    v = (v / 100).toFixed(2) + "";
    v = v.replace(".", ",");
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    valor = v;

    // TESTE REGEX 2

    this.setState({
      purchase: valor,
    });

    finalValue = valor;

    var n = finalValue.indexOf(",");

    // Não tem Ponto
    if (finalValue.indexOf(".") === -1) {
      // E tem vírgula
      if (finalValue.indexOf(",") > -1) {
        //1,25 //10,25 //100,25 //1000,25
        //finalValue = finalValue.replace(",",".");
        if (parseInt(finalValue.length) - parseInt(n) === 3) {
          finalValue = finalValue.replace(",", ".");
        }
      }
      // Tem Ponto
    } else if (finalValue.indexOf(".") !== -1) {
      // NÃO Tem Vírgula
      if (finalValue.indexOf(",") === -1) {
      } else {
        // TEM vírgula
        // 1.000,00 || 1,000.00  1.500,00
        finalValue = finalValue.replace(".", "");
        finalValue = finalValue.replace(",", ".");
      }
    }

    if (parseFloat(finalValue) > 0) {
      this.setState({
        finalValue: finalValue
          ? (
            parseFloat(finalValue) -
            parseFloat(finalValue) *
            (parseFloat(this.state.sliderValue) / 100)
          ).toFixed(2) - this.state.currencyValue
          : null,
      });
    } else {
      this.setState({
        finalValue: 0,
      });
    }
  }

  /// Valida o cupom
  validate = () => {
    this.proceedValidate()
      .then((res) => {
        if (res.data.Authorized === true) {
          this.showNotify(
            i18n.t("validation.notify.couponsuccessfullyregistered"),
            "success"
          );
          /// EventId
          const eventId = res.data.coupon.eventId;
          updateEvent(eventId);
          this.reset();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            this.showNotify(i18n.t("validation.notify.autherror"), "error");
          } else if (error.response.status == 404) {
            if (error.response.data == "Inactive Campaign") {
              this.showNotify(
                i18n.t("validation.notify.inactivecampaign"),
                "error"
              );
            } else if (error.response.data == "Coupon already used!") {
              this.showNotify(
                i18n.t("validation.notify.couponalreadyused"),
                "error"
              );
            } else if (
              error.response.data == "Value can not be less than zero!"
            ) {
              this.showNotify(
                i18n.t("validation.notify.valueneedtobegreaterthanzero"),
                "error"
              );
            } else if (error.response.data == "email is required!") {
              this.showNotify(
                i18n.t("validation.notify.requiredfieldnameandemail"),
                "error"
              );
            } else if (error.response.data == "Inactive Coupon") {
              this.showNotify(
                i18n.t("validation.notify.inactivecoupon"),
                "error"
              );
            } else {
              this.showNotify(error.response.data, "error");
            }
          } else if (error.response.status) {
            this.showNotify(error.response.data, "error");
          }
        } else {
          this.showNotify(error, "error");
        }
      });
  };

  proceedValidate = () => {
    return new Promise((resolve, reject) => {
      if (
        this.state.purchase != undefined &&
        this.state.purchase != "" &&
        this.state.purchase !== null
      ) {
        // Verifica se pode validar com os dados do usuário anônimo.
        if (this.state.coupon.fields.length === 0) {
          //resolve('OK')
        } else {
          this.state.coupon.fields.forEach((item) => {
            if (item === "1") {
              if (
                this.state.name === undefined ||
                this.state.name === "" ||
                this.state.name === null
              ) {
                reject(i18n.t("validation.notify.rejectmessagenamerequired"));
              }
            } else if (item === "2") {
              if (
                this.state.email === undefined ||
                this.state.email === "" ||
                this.state.email === null
              ) {
                reject(
                  reject(i18n.t("validation.notify.rejectmessageemailrequired"))
                );
              }
            }
          });
        }

        // Primeiro checar se a data inicial é relevante
        if (
          this.state.coupon.initDate != undefined &&
          this.state.coupon.initDate != "" &&
          this.state.coupon.initDate != "0" &&
          this.state.coupon.initDate !== null
        ) {
          // Checar data inicial
          // initDate: "2020-07-07"
          // let initDate_ = res.initDate ? (res.initDate != '0' ? (new Date(res.initDate + ' 00:00')) : '0') : '0';
          // let today = new Date()
          //https://www.npmjs.com/package/moment-timezone
          let todayDate = moment().tz("America/Sao_Paulo").format();
          // let initDate = moment(this.state.coupon.initDate).tz("America/Sao_Paulo").format();

          // `${this.state.initDate} 00:00:00`

          console.log("this.state.coupon.initDate 608", this.state.coupon.initDate)
          console.log("this.state.coupon.endDate 608", this.state.coupon.endDate)

          let initDate = moment(`${this.state.coupon.initDate} 00:00:00`)
            .tz("America/Sao_Paulo")
            .format();

          console.log("todayDate 622", todayDate);
          console.log("let initDate 623", initDate);

          console.log("Periodo ok 625", new Date(initDate) > new Date(todayDate));

          if (new Date(initDate) > new Date(todayDate)) {
            reject(i18n.t("validation.notify.rejectmessagecampaigndate"));
          }
        }

        if (
          this.state.coupon.endDate != undefined &&
          this.state.coupon.endDate != "" &&
          this.state.coupon.endDate != "0" &&
          this.state.coupon.endDate !== null
        ) {
          // Checar data Final
          // endDate: "2020-07-07"
          // let endDate_ = res.endDate ? (res.endDate != '0' ? (new Date(res.endDate + ' 00:00')) : '0') : '0';
          // let today = new Date()
          //https://www.npmjs.com/package/moment-timezone

          let todayDate = moment().tz("America/Sao_Paulo").format();
          let endDate = moment(`${this.state.coupon.endDate} 23:59:59`)
            .tz("America/Sao_Paulo")
            .format();

          // console.log('today', today);
          // console.log('Hoje ---->', todayDate);

          // console.log('Hoje Convertido ---->', new Date(todayDate));
          // console.log('Data Final Convertido ---->', new Date(endDate));

          // console.log('Data final maior que hoje? Pode validar? Direto', endDate > todayDate) // Data final maior que data atual? Se TRUE, pode validar, se False não pode validar.
          // console.log('Data final maior que hoje? Pode validar? Convertido', new Date(endDate) > new Date(todayDate)) // Data final maior que data atual? Se TRUE, pode validar, se False não pode validar.

          // console.log('Hora Inicial ---->', new Date(todayDate).getHours());
          // console.log('Hora Final ---->', new Date(todayDate).getMinutes());

          if (new Date(endDate) < new Date(todayDate)) {
            reject(i18n.t("validation.notify.rejectmessagecampaignended"));
          }
        }

        // Para saber se o horário da validação está dentro do range permitido.
        // Agora está MAIOR que (Hoje + horário incial) && MENOR que (Hoje + Horário Final)
        // https://github.com/moment/moment/issues/1199 (Interessante sobre a compraçãro de datas e horários)
        if (
          this.state.coupon.initHour != undefined &&
          this.state.coupon.initHour != "" &&
          this.state.coupon.initHour != "0" &&
          this.state.coupon.initHour !== null
        ) {
          if (
            this.state.coupon.endHour != undefined &&
            this.state.coupon.endHour != "" &&
            this.state.coupon.endHour != "0" &&
            this.state.coupon.endHour !== null
          ) {
            // Define o horário agora
            let todayNow = moment().tz("America/Sao_Paulo").format();

            // Define o horário inicial
            let todayAnd_initHour = new Date(
              moment().tz("America/Sao_Paulo").format()
            );
            todayAnd_initHour = moment(
              todayAnd_initHour.setHours(
                this.state.coupon.initHour.split(":")[0],
                this.state.coupon.initHour.split(":")[1],
                0
              )
            )
              .tz("America/Sao_Paulo")
              .format();

            // Define o horário final
            let todayAnd_endHour = new Date(
              moment().tz("America/Sao_Paulo").format()
            );
            todayAnd_endHour = moment(
              todayAnd_endHour.setHours(
                this.state.coupon.endHour.split(":")[0],
                this.state.coupon.endHour.split(":")[1],
                59
              )
            )
              .tz("America/Sao_Paulo")
              .format();

            // Pergunta que precisa responder
            // console.log(
            //   "Agora é maior que hora inicial?",
            //   todayNow > todayAnd_initHour
            // );
            // console.log(
            //   "Agora é menor que hora final?",
            //   todayNow < todayAnd_endHour
            // );

            if (
              todayNow > todayAnd_initHour !== true ||
              todayNow < todayAnd_endHour !== true
            ) {
              reject(i18n.t("validation.notify.rejectmessagecampaignended"));
            }
          }
        }

        // Checar quantidade de cupons (Isso não seria somente na geração do número?)
        let checkCode;
        if (this.state.random === true) {
          checkCode =
            this.state.fixedCode1 +
            "-" +
            this.state.fixedCode2 +
            "-" +
            this.state.fixedCode3;
        } else {
          checkCode = this.state.fixedCode1;
        }

        if (checkCode) {
          // id, name, email, initialValue, discount, voucher, finalValue

          registerCouponCode(
            checkCode,
            this.state.name,
            this.state.email,
            this.state.purchase,
            this.state.coupon.discount,
            this.state.coupon.voucher,
            this.state.finalValue
          )
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject("Code Error!");
        }
      } else {
        reject(i18n.t("validation.notify.rejectmessageinvalidvalue"));
      }
    });
  };

  createDateNow = () => {
    var today = new Date();
    var date =
      today.getDate() +
      "/" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "/" +
      today.getFullYear();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;
    return dateTime;
    // this.setState({
    //     rigthNow: dateTime
    // })
  };

  render() {
    let validationBox = "Aqui";

    validationBox = (
      <div>
        <div className="form">
          <div className="card">
            <div className="card-body">
              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("validation.labelname")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    showClearButton={true}
                    placeholder={i18n.t("validation.placeholdername")}
                    value={this.state.name}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "name")
                    }
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("validation.labelemail")}{" "}
                  {this.state.emailrequired === true ? "*" : ""}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    showClearButton={true}
                    placeholder={i18n.t("validation.placeholderemail")}
                    value={this.state.email}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "email")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <hr style={{ marginTop: "6px", marginBottom: "6px" }} />

          <div className="card">
            <div className="card-body" style={{ paddingBottom: "5px" }}>
              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("validation.labeltotalvalue")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    defaultValue=""
                    showClearButton={true}
                    value={
                      this.state.purchase
                        ? this.state.purchase.toString()
                        : null
                    }
                    //onValueChanged={(event) => this.handleValueChange(event, 'purchase')}
                    onKeyUp={(event) => this.onKeyPress(event, "purchase")}
                  // format="R$ #.##0,##;"
                  // useMaskBehavior={true}
                  />

                  {/* <NumberBox format="R$ #.##0,##" value={(this.state.currencyValue ? ((this.state.currencyValue)*-1) : 0)}  /> */}
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("validation.labeldiscount")}
                </div>
                <div className="dx-field-value">
                  {/* <TextBox readOnly={this.state.sliderValue == 0 ? false : true} defaultValue="" showClearButton={true} value={this.state.sliderValue == 0 ? '' : this.state.sliderValue } onValueChanged={(event) => this.handleValueChange(event, 'title')} /> */}

                  <NumberBox
                    format="#0%"
                    value={parseInt(this.state.sliderValue) / 100}
                    step={0.01}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("validation.labelvoucher")}
                </div>
                <div className="dx-field-value">
                  {/* <TextBox readOnly={true} defaultValue="" value={(this.state.currencyValue ? (this.state.currencyValue) : 0).toFixed(2)} /> */}

                  <NumberBox
                    format={i18n.t("validation.numberformat")} ///{"R$ #.##0,##;(R$ #.##0,##)"}
                    value={
                      this.state.currencyValue
                        ? this.state.currencyValue * -1
                        : 0
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("validation.labelfinalvalue")}
                </div>
                <div className="dx-field-value" style={{ color: "red" }}>
                  <TextBox
                    readOnly={true}
                    defaultValue=""
                    showClearButton={true}
                    value={this.state.finalValue.toLocaleString(
                      i18n.t("validation.localvalue"), ///"pt-BR"
                      {
                        style: "currency",
                        currency: i18n.t("validation.currencyvalue"), ///"BRL",
                      }
                    )}
                  //onValueChanged={(event) => this.handleValueChange(event, 'finalValue')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 pr-0">
            <div
              className="form-group mt-1"
              style={{ width: "110px", float: "right", height: "37px" }}
            >
              <Button
                id="backButton"
                icon="tags"
                text={this.state.coupon ? "Validar" : ""}
                width="100%"
                height="37px"
                type={this.state.coupon ? "danger" : "default"}
                visible={this.state.coupon ? true : false}
                onClick={this.validate}
                style={{ borderWidth: "0px", borderStyle: "unset" }}
              />
            </div>
          </div>
        </div>
      </div>
    );

    if (this.state.coupon) {
      showCoupon = (
        <div
          className="col-md-8 col-xl-8"
          style={{
            float: "left",
            padding: "3px",
            margin: "0 auto",
            paddingTop: "21px",
          }}
        >
          <div
            id={this.state.coupon._id}
            className="d-block shadow bg-white borderClass "
          >
            <div className="">
              <div
                className="input-group-append"
                style={{ height: "28px", float: "right" }}
              ></div>
              <img
                id="img_"
                src={this.state.coupon.image}
                style={{ width: "100%", height: "auto" }}
                alt=""
              />
            </div>
          </div>
          <div className="form">
            <div
              className="dx-fieldset"
              style={{ marginTop: "10px", textAlign: "center" }}
            >
              <div>
                <strong>
                  {i18n.t("validation.labelcouponrules")}
                  {this.state.title}
                </strong>
              </div>
            </div>
            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("validation.labelcoupondiscount")}
              </div>
              <div className="dx-field-value">
                <div
                  className=""
                  style={{ height: "35px", paddingLeft: "11%" }}
                >
                  {this.state.sliderValue == 0 ? (
                    <div>
                      <span
                        className="badge badge-info"
                        style={{ padding: "10px", height: "30px" }}
                      >
                        {i18n.t("validation.labelmanualdiscount")}
                      </span>
                    </div>
                  ) : (
                    <div className="discount" style={{ marginTop: "0px" }}>
                      -{this.state.sliderValue}%
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("validation.labelvoucher")}
              </div>
              <div className="dx-field-value">
                <NumberBox
                  showClearButton={true}
                  format={i18n.t("validation.numberformatvoucher")}
                  minimumSignificantDigits="2"
                  currency={i18n.t("validation.currencyvalue")}
                  readOnly={true}
                  value={
                    this.state.currencyValue ? this.state.currencyValue : 0
                  }
                  style={{ backgroundColor: "yellow" }}
                >
                  <NumberBoxButton name="clear" />
                </NumberBox>
              </div>
            </div>

            {/* Limite de Cupons */}
            <div className="dx-field">
              <div
                className="dx-field-label"
                title={i18n.t("validation.labelcouponlimit")}
                style={{ paddingRight: "0px" }}
              >
                Limite de Cupons
              </div>
              <div className="dx-field-value">
                <NumberBox
                  readOnly={true}
                  showClearButton={true}
                  showSpinButtons={true}
                  placeholder={i18n.t("validation.placeholdercouponlimit")}
                  value={this.state.limit == 0 ? null : this.state.limit}
                >
                  <NumberBoxButton name="spins" />
                </NumberBox>
              </div>
            </div>
            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("validation.labelvalidfrom")}
              </div>
              <DateBox
                className="dx-field-value"
                readOnly={true}
                type="date"
                value={
                  this.state.initDate
                    ? this.state.initDate == "0"
                      ? null
                      : new Date(this.state.initDate)
                    : null
                }
                placeholder={i18n.t("validation.placeholdervalid")}
              />
            </div>
            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("validation.labelvaliduntil")}
              </div>
              <DateBox
                className="dx-field-value"
                readOnly={true}
                type="date"
                value={
                  this.state.endDate
                    ? this.state.endDate == "0"
                      ? null
                      : new Date(this.state.endDate)
                    : null
                }
                placeholder={i18n.t("validation.placeholdervalid")}
              />
            </div>
            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("validation.labelstarhour")}
              </div>
              <div className="dx-field-value">
                <DateBox
                  type="time"
                  readOnly={true}
                  value={
                    this.state.initHour
                      ? this.state.initHour == "0"
                        ? null
                        : new Date(this.state?.initHour)
                      : null
                  }
                  placeholder={i18n.t("validation.placeholdervalid")}
                  defaultValue={null}
                />
              </div>
            </div>

            <div className="dx-field">
              <div className="dx-field-label">
                {i18n.t("validation.labelendhour")}
              </div>
              <div className="dx-field-value">
                <DateBox
                  type="time"
                  readOnly={true}
                  value={
                    this.state.endHour
                      ? this.state.endHour == "0"
                        ? null
                        : new Date(this.state?.endHour)
                      : null
                  }
                  placeholder={i18n.t("validation.placeholdervalid")}
                  onValueChanged={(event) =>
                    this.handleValueChange(event, "endHour")
                  }
                />
              </div>
            </div>

            <div style={{ marginTop: "4rem", marginBottom: "1rem" }}>
              <strong>{i18n.t("validation.labeldescription")}</strong>
            </div>
            <div className="dx-fieldset" id="notes-container">
              <div className="dx-field">
                <TextArea
                  className="redHeight2 dx-field-value"
                  height={300}
                  width={"100%"}
                  readOnly={true}
                  value={this.state?.description}
                />
              </div>
            </div>
            <div style={{ marginTop: "4rem", marginBottom: "1rem" }}>
              <strong>{i18n.t("validation.labeltermsandconditions")}</strong>
            </div>
            <div className="dx-fieldset" id="notes-container">
              <div className="dx-field">
                <TextArea
                  className="redHeight2 dx-field-value"
                  height={300}
                  width={"100%"}
                  readOnly={true}
                  value={this.state?.terms}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      showCoupon = "";
    }

    return (
      <div className="col-11" style={{ paddingRight: "0px" }}>
        {/* <Button id="backButton" icon="arrowleft" onClick={this.props.history.goBack} /> */}
        {/* <span style={{marginLeft: '20px'}}>{this.createDateNow()}</span> */}
        <div className="row mt-3">
          <div className="col-6">
            <div id="validation" className="card" style={{ height: "600px" }}>
              <div
                className="card-body"
                style={{ overflow: "auto", paddingTop: "0px" }}
              >
                <div style={{ height: "8px" }}></div>
                <div className="myCSS col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="row">
                    <div className="col-6">
                      <h6 style={{ paddingTop: "3px", marginBottom: "5px" }}>
                        <label>{i18n.t("validation.labelcouponcode")}</label>
                      </h6>
                    </div>
                    <div className="col-6">
                      <div className="row" style={{ height: "31px" }}>
                        <div className="col pr-0">
                          <label
                            style={{
                              fontSize: "12px",
                              paddingTop: "6px",
                              float: "right",
                            }}
                            htmlFor=""
                          >
                            Código Fixo
                          </label>
                        </div>
                        <div className="col">
                          <div
                            style={{
                              display: "block",
                              height: "25px",
                              margin: "0px auto",
                            }}
                          >
                            <div
                              className="custom-control custom-switch"
                              style={{ float: "left", paddingTop: "3px" }}
                            >
                              <input
                                id="ckb_random"
                                type="checkbox"
                                className="custom-control-input"
                                checked={
                                  this.state.random ? this.state.random : false
                                } //{this.state.random ? (this.state.random == true ? true : false) : true}
                                onChange={(event) => {
                                  this.handleValueChange(event, "random");
                                }}
                              />
                              <label
                                title={
                                  this.state.random == false
                                    ? i18n.t("validation.labeldeactive")
                                    : i18n.t("validation.labelactive")
                                }
                                className="custom-control-label red"
                                style={{ fontSize: "12px", paddingTop: "3px" }}
                                htmlFor="ckb_random"
                              >
                                {i18n.t("validation.labelautomatic")}
                              </label>
                            </div>
                          </div>
                          {/* <TextBox defaultValue="" showClearButton={true} placeholder="Ex: PROMO20" value={this.state.fixedCode} onValueChanged={(event) => this.handleValueChange(event, 'fixedCode')} /> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ height: "51px" }}>
                    <div
                      className={
                        this.state.random
                          ? "col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                          : "col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9"
                      }
                    >
                      <div
                        className="form-group mb-3"
                        style={{ width: this.state.random ? "110px" : "355px" }}
                      >
                        <TextBox
                          className="form-control"
                          showClearButton={true}
                          maxLength={this.state.random ? "4" : "150"}
                          style={{
                            fontFamily: "Impact",
                            fontSize: "x-large",
                            fontWeight: "bolder",
                          }}
                          value={
                            this.state.fixedCode1
                              ? this.state.fixedCode1.toString().toUpperCase()
                              : ""
                          }
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "fixedCode1")
                          }
                        />
                      </div>
                    </div>

                    <div
                      className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                      style={{ display: this.state.random ? "block" : "none" }}
                    >
                      <div
                        className="form-group mb-3"
                        style={{ width: "110px" }}
                      >
                        <TextBox
                          className="form-control"
                          visible={this.state.random}
                          showClearButton={true}
                          maxLength="4"
                          style={{
                            fontFamily: "Impact",
                            fontSize: "x-large",
                            fontWeight: "bolder",
                          }}
                          value={
                            this.state.fixedCode2
                              ? this.state.fixedCode2.toString().toUpperCase()
                              : ""
                          }
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "fixedCode2")
                          }
                        />
                      </div>
                    </div>

                    <div
                      className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                      style={{ display: this.state.random ? "block" : "none" }}
                    >
                      <div
                        className="form-group mb-3"
                        style={{ width: "110px" }}
                      >
                        <TextBox
                          className="form-control"
                          visible={this.state.random}
                          showClearButton={true}
                          maxLength="4"
                          style={{
                            fontFamily: "Impact",
                            fontSize: "x-large",
                            fontWeight: "bolder",
                          }}
                          value={
                            this.state.fixedCode3
                              ? this.state.fixedCode3.toString().toUpperCase()
                              : ""
                          }
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "fixedCode3")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                      <div
                        className="form-group mb-3"
                        style={{ width: "110px" }}
                      >
                        <Button
                          id="backButton"
                          icon="tags"
                          text={
                            this.state.coupon
                              ? i18n.t("validation.btnreset")
                              : i18n.t("validation.btncheck")
                          }
                          width="100%"
                          height="50px"
                          type={this.state.coupon ? "default" : "success"}
                          disabled={this.campaignId ? true : false}
                          onClick={
                            this.state.coupon ? this.reset : this.getCouponCode
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-12"
                  style={{
                    paddingRight: "0px",
                    paddingTop: "10px",
                    height: "350px",
                  }}
                >
                  {validationBox}
                </div>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div
              className="myCSS2 card"
              style={{ height: "600px", overflow: "auto" }}
            >
              {showCoupon}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
