import React, { Component } from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  HeaderFilter,
  SearchPanel,
  Export,
  Scrolling
} from "devextreme-react/data-grid";
// import Spinner from "../../LayOut/Spinner";
import { Popup } from "devextreme-react/popup";
import { FilterRow } from "devextreme-react/data-grid";

import { reports, getLogById } from "../../../apis/toowezeApi";
import { Button } from "devextreme-react";
import DateBox from "devextreme-react/date-box";
import { loadMessages, locale } from "devextreme/localization";
import { i18n, lgMessages, languageCode } from "../../../translate/i18n";
// import Switch from "devextreme-react/switch";
import './switch.css';
// import RadioGroup from 'devextreme-react/radio-group';
import imgCelsius from "../../../images/thermometer.png";

// const priorities = [
//   i18n.t("transactions.activity.sales"),
//   i18n.t("transactions.activity.cashback"),
//   i18n.t("transactions.activity.points")];

const jsonData = {
  "_id": "66dafd18f2748d510cf3cf2c",
  "log_date_timeZone": "2024-09-06T10:01:12-03:00",
  "method": "POST",
  "url": "/api/transactions",
  "headers": {
    "content-type": "application/json",
    "x-auth-token": "****",
    "user-agent": "PostmanRuntime/7.41.2",
    "accept": "*/*",
    "cache-control": "no-cache",
    "postman-token": "228a7667-ebc0-448c-8e9c-8b2d9631d85a",
    "host": "localhost:3000",
    "accept-encoding": "gzip, deflate, br",
    "connection": "keep-alive",
    "content-length": "430"
  },
  "ip": "::1",
  "body": {
    "customerId": "667c326241e88a34a4d4e590",
    "ruleId": "6679dfbdbdd6b75c291c0a1f",
    "products": [
      {
        "productId": "1",
        "productName": "Compra Geral Teste",
        "description": "",
        "value": 11.99,
        "quantity": 1
      }
    ],
    "payments": [
      {
        "amount": 11.99,
        "currency": "Money",
        "type": "Debit"
      }
    ],
    "ip": "187.183.44.135",
    "observation": "Compra Comum TESTE TIME 7"
  },
  "rawHeaders": [
    "Content-Type",
    "application/json",
    "x-auth-token",
    "****",
    "User-Agent",
    "PostmanRuntime/7.41.2",
    "Accept",
    "*/*",
    "Cache-Control",
    "no-cache",
    "Postman-Token",
    "228a7667-ebc0-448c-8e9c-8b2d9631d85a",
    "Host",
    "localhost:3000",
    "Accept-Encoding",
    "gzip, deflate, br",
    "Connection",
    "keep-alive",
    "Content-Length",
    "430"
  ],
  "createdAt": 1725627645.835,
  "log_date": "2024-09-06T13:01:12.538+0000",
  "__v": 0
};


export default class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphStats: 0,
      spinner: true,
      customStartDate: 0,
      customEndDate: 0,
      exibitionType: 'transactions',
      exibitionPeriod: 'byDay'
    };
    loadMessages(lgMessages);
    locale(languageCode);
  }

  componentDidMount() {
    this.getReport()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.exibitionType !== this.state.exibitionType) {
      this.setState({
        graphStats: null
      })
    }
  }


  toDate = (data) => {
    try {
      return new Date(data?.data?.log_date_timeZone).toLocaleString();
    } catch (error) {
      console.log('error =======>', error)
      return null
    }
  }

  logUrl = (data) => {
    try {
      return data?.data?.logId;
    } catch (error) {
      console.log('error =======>', error)
      return null
    }
  }


  handleNPS = (data) => {
    const npsRate = data.data.nps;
    if (npsRate > 0 && npsRate < 7) {
      return <div>
        <label className="badge badge-danger" style={{ fontSize: 12 }}>{npsRate}</label>
      </div>
    } else if (npsRate > 6 && npsRate < 9) {
      return <div>
        <label className="badge badge-warning" style={{ fontSize: 12 }}>{npsRate}</label>
      </div>
    } else if (npsRate > 8) {
      return <div>
        <label className="badge badge-success" style={{ fontSize: 12 }}>{npsRate}</label>
      </div>
    }
  }

  calcFinalValue = (data) => {
    if (data) {
      let finalResult;
      let result;
      if (data?.data?.cashbackused > 0) {
        finalResult = "R$ " + (data?.data?.finalValue - data?.data?.cashbackused).toFixed(2);
      } else {
        finalResult = "R$ " + data?.data?.finalValue.toFixed(2)
      }

      if (finalResult) {
        result = (
          <div>
            <label className="badge badge-success" style={{ fontSize: 12 }}>{finalResult}</label>
          </div>
        );
      } else {
        result = (
          <div>
            <label className="badge">n/a</label>
          </div>
        );
      }
      return result;
    }
  }


  addCurrency = (data) => {
    let result = <label></label>;

    if (data) {
      const resultData = data?.data[data.column.dataField].toFixed(2);
      const symbol = "R$ "
      let finalResult = 0;
      if (data.data[data.column.dataField]) {
        if (data.column.dataField !== "discountPercent" && data.column.dataField !== "points_recieved") {
          finalResult = symbol + " " + resultData;
        } else if (data.column.dataField === "discountPercent") {
          finalResult = resultData + " %";
        } else if (data.column.dataField === "points_recieved") {
          finalResult = " " + resultData
        }
      }

      if (finalResult) {

        if (data.column.dataField === "discountValue" || data.column.dataField === "cashbackused") {
          result = (
            <div>
              <label className="badge badge-danger" style={{ fontSize: 12 }}>{finalResult}</label>
            </div>
          );
        } else {
          result = (
            <div>
              <label className="badge badge-info" style={{ fontSize: 12 }}>{finalResult}</label>
            </div>
          );
        }

      } else {
        result = (
          <div>
            <label className="badge">n/a</label>
          </div>
        );
      }
    }
    return result;
  };


  wheater = (data) => {
    let result = <label></label>;
    if (data.value) {
      result = (
        <div>
          <label className="badge badge-warning">
            {data.data?.feels_like}
            <img alt=""
              width="12"
              height="12"
              src={imgCelsius} />
          </label>
          <img
            alt=""
            src={
              "http://openweathermap.org/img/w/" +
              data.value +
              ".png"
            }
            style={{ marginBottom: "5px" }}
            width="22"
            height="22"
          />
        </div>
      );
    }
    return result;
  };

  getReport = (initDate, endDate, peridType) => {

    if (!initDate || !endDate) {
      this.setState({
        customStartDate: 0,
        customEndDate: 0
      })

      const monthDates = this.getMonthDates(peridType);
      initDate = monthDates.startOfMonth;
      endDate = monthDates.endOfMonth;
    }

    if (this.state.exibitionType === 'transactions') {
      reports(this.state.exibitionType, initDate, endDate).then(result => {

        if (result) {
          this.setState({
            datagrid: result
          })
        }

      }).catch(err => {
        console.log('err', err)
      })
    }
  }

  handleValueChange(event, type) {
    if (type === "customStartDate") {
      this.setState({ customStartDate: this.returnDateValue(new Date(event.value.toString())) + 'T00:00:00-03:00' })
    } else if (type === "customEndDate") {
      this.setState({ customEndDate: this.returnDateValue(new Date(event.value.toString())) + 'T23:59:59-03:00' })
    }
    if (this.state.customStartDate !== 0 && this.state.customEndDate !== 0) {
      this.getReport(this.state.customStartDate, this.state.customEndDate)
    }
  }

  returnDateValue(newDate) {
    return newDate.getFullYear() + "-" + ("0" + (newDate.getMonth() + 1)).slice(-2) + "-" + ("0" + newDate.getDate()).slice(-2);
  }

  getMonthDates(periodType) {

    const date = new Date();
    let initDate;
    let endDate;
    let year;
    let month;
    let day;

    if (periodType === 'fullMonth' || !periodType) {
      initDate = new Date(date.getFullYear(), date.getMonth(), 1);
      endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else if (periodType === 'last30days') {
      const startDate = new Date();
      startDate.setDate(date.getDate() - 30);
      initDate = startDate;
      endDate = date
    } else if (periodType === 'last90days') {
      const startDate = new Date();
      startDate.setDate(date.getDate() - 90);
      initDate = startDate;
      endDate = date
    } else if (periodType === 'lastmonth') {
      const startDate = new Date();
      startDate.setDate(date.getDate() - 30);
      initDate = startDate;
      endDate = date
    }

    const formatDate = (date, type) => {

      year = date.getFullYear();
      month = (date.getMonth() + 1).toString().padStart(2, '0');
      day = date.getDate().toString().padStart(2, '0');

      let hours = '00';
      let minutes = '00';
      let seconds = '00';

      if (type === 'start') {
        hours = '00';
        minutes = '00';
        seconds = '00';
      }

      if (type === 'end') {
        hours = '23';
        minutes = '59';
        seconds = '59';
      }

      const finalOffSet = "-03:00";

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${finalOffSet}`;
    };

    const formattedDates = {
      startOfMonth: formatDate(initDate, 'start'),
      endOfMonth: formatDate(endDate, 'end'),
    };

    return formattedDates;
  }


  showModal = (data) => {
    getLogById(data?.logId).then(result => {
      console.log('result', result)
      this.setState({
        popupVisible: true,
        logData: result
      });
    })
  };

  showLogId = (data) => {
    console.log('data', data)
    if (data?.data?.logId) {
      return (
        <div onClick={() => this.showModal(data.data)}>
          <span className="likeLink">
            {/* {i18n.t("campaignview.activity.modalcouponlabel")} */}
            Requisição
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  hideInfo = () => {
    this.setState({
      popupVisible: false,
    });
  };



  renderLog(jsonData) {

    if (jsonData && Array.isArray(jsonData["rawHeaders"])) {
      const tokenIndex = jsonData["rawHeaders"].indexOf("x-auth-token");

      if (tokenIndex !== -1 && tokenIndex + 1 < jsonData["rawHeaders"].length) {
        // Substitui o valor do token pelo valor mascarado
        jsonData["rawHeaders"][tokenIndex + 1] = "*****";
      }
    } else {
      console.warn("rawHeaders não está presente no jsonData ou não é um array.");
    }


    const maskToken = (jsonStr) => {
      return jsonStr
        ?.replace(/"x-auth-token":\s?"([^"]+)"/g, '"x-auth-token": "****"')  // Para objetos
        ?.replace(/"x-auth-token",\s?"([^"]+)"/g, '"x-auth-token", "****"'); // Para arrays
    };

    const renderJson = (data, indent = 0) => {
      if (typeof data === "object" && !Array.isArray(data)) {
        return Object.keys(data).map((key) => {
          const value = data[key];
          const indentStyle = { paddingLeft: `${indent * 20}px` };

          // Renderização do campo "body" com destaque especial
          if (key === "body") {
            return (
              <div key={key} style={indentStyle}>
                <span style={{ color: "#00d1ff", fontWeight: "bold" }}>"{key}": </span>
                <div style={{ backgroundColor: "#252526", padding: "10px", borderRadius: "4px", marginTop: "5px" }}>
                  <pre style={{ color: "#9cdcfe", fontFamily: 'Helvetica', fontSize: '14px', whiteSpace: 'pre-wrap' }}>
                    {JSON.stringify(value, null, 2)}
                  </pre>
                </div>
              </div>
            );
          }

          // Renderização de arrays
          if (Array.isArray(value)) {
            return (
              <div key={key} style={indentStyle}>
                <span style={{ color: "#00d1ff", fontWeight: "bold" }}>"{key}": </span>
                <pre style={{ color: "#d4d4d4", paddingLeft: `${(indent + 1) * 20}px`, fontFamily: 'Helvetica', fontSize: '14px', whiteSpace: 'pre-wrap' }}>
                  {JSON.stringify(value, null, 2)}
                </pre>
              </div>
            );
          }

          // Renderização de outros campos
          return (
            <div key={key} style={indentStyle}>
              <span style={{ color: "#00d1ff", fontWeight: "bold" }}>"{key}": </span>
              {typeof value === "object" ? (
                <div>{renderJson(value, indent + 1)}</div>
              ) : (
                <span style={{ color: "#d4d4d4", fontFamily: 'Helvetica', fontSize: '14px' }}>{JSON.stringify(value)}</span>
              )}
            </div>
          );
        });
      }

      // Renderização de valores simples
      return <span style={{ color: "#d4d4d4", fontFamily: 'Helvetica', fontSize: '14px' }}>{JSON.stringify(data)}</span>;
    };

    // Converter o JSON para string, aplicar a máscara e depois renderizar
    let jsonString = JSON.stringify(jsonData, null, 2);
    jsonString = maskToken(jsonString); // Aplicar a máscara ao x-auth-token

    return (
      <div style={{ padding: "10px", backgroundColor: "#1e1e1e", color: "#d4d4d4", fontFamily: "Helvetica", maxHeight: "300px", overflowY: "auto", borderRadius: "4px", whiteSpace: "pre-wrap" }}>
        <pre style={{ backgroundColor: "#1e1e1e", color: "#d4d4d4" }}>{jsonString}</pre>
      </div>
    );
  }





  render() {
    return (
      <div
        className="col-12"
      >
        <div className="row m-0">
          <div className="col-12 container">
            <div className="row pb-3">
              <div className="col-6 p-3" style={{ backgroundColor: "whitesmoke" }}>
                <div className="row">
                  <div className="col-6">
                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("transactions.activity.startDate")}
                      </div>
                      <DateBox
                        className="dx-field-value"
                        type="date"
                        value={
                          this.state.customStartDate
                            ? this.state.customStartDate === "0"
                              ? null
                              : this.state.customStartDate
                            : null
                        }
                        placeholder="Período Inicial"
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "customStartDate")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("transactions.activity.endDate")}
                      </div>
                      <DateBox
                        className="dx-field-value"
                        type="date"
                        placeholder="Período Final"
                        value={
                          this.state.customEndDate
                            ? this.state.customEndDate === "0"
                              ? null
                              : this.state.customEndDate
                            : null
                        }
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "customEndDate")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 p-3" style={{ backgroundColor: "whitesmoke" }}>
                <div className="row">
                  <Popup
                    visible={this.state.popupVisible}
                    onHiding={this.hideInfo}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title={'Transação Gerada Baseada Nestas Informações'}
                    width={600}
                    height={400}
                  >
                    {/* Uso de dangerouslySetInnerHTML para renderizar o HTML gerado pela função */}
                    {/* <div dangerouslySetInnerHTML={{ __html: this.renderLog(this.state.logData) }} /> */}
                    {this.renderLog(this.state.logData)}
                  </Popup>
                  <div className="col-3"><Button
                    id="backButton"
                    text={i18n.t("transactions.activity.last30days")}
                    width="100%"
                    height="30px"
                    onClick={() => this.getReport("", "", "last30days")}
                    type="default"
                    stylingMode="outlined" /></div>
                  <div className="col-3"><Button
                    id="backButton"
                    text={i18n.t("transactions.activity.last90days")}
                    width="100%"
                    height="30px"
                    onClick={() => this.getReport("", "", "last90days")}
                    type="default"
                    stylingMode="outlined" /></div>
                  <div className="col-3">
                    <Button
                      id="backButton"
                      text={i18n.t("transactions.activity.thismonth")}
                      width="100%"
                      height="30px"
                      onClick={() => this.getReport("", "", "")}
                      type="default"
                      stylingMode="outlined" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DataGrid
            dataSource={this.state.datagrid}
            showBorders={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            style={{ fontSize: 12 }}
          >
            <Scrolling mode="standard" />
            <HeaderFilter visible={true} />
            <SearchPanel
              visible={true}
              className="custom"
              width={240}
              placeholder={i18n.t("campaignview.activity.searchlabel")}
            />
            <FilterRow visible={false} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={20} defaultPageIndex={0} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 30]}
              showInfo={true}
            />
            <Column
              dataField="customerId"
              // caption={i18n.t("transactions.activity.log_request")}
              // cellRender={this.showLogId}
              visible={false}
              allowExporting={true}
            ></Column>
            <Column
              dataField="logId"
              caption={i18n.t("transactions.activity.log_request")}
              cellRender={this.showLogId}
            ></Column>
            <Column
              dataField="log_date_timeZone"
              caption={i18n.t("transactions.activity.log_date_timeZone")}
              cellRender={this.toDate}
            ></Column>
            <Column
              dataField="name"
              caption={i18n.t("transactions.activity.name")}
            ></Column>
            <Column
              dataField="email"
              caption={i18n.t("transactions.activity.email")}
              width={200}
            ></Column>
            <Column
              dataField="observation"
              caption={i18n.t("transactions.activity.observation")}
            ></Column>
            <Column
              dataField="initialValue"
              caption={i18n.t("transactions.activity.initialValue")}
              cellRender={this.addCurrency}
            ></Column>
            <Column
              dataField="cashbackused"
              caption={i18n.t("transactions.activity.cashbackused")}
              cellRender={this.addCurrency}
            ></Column>
            <Column
              dataField="discountValue"
              caption={i18n.t("transactions.activity.discountValue")}
              cellRender={this.addCurrency}
            ></Column>
            <Column
              dataField="finalValue"
              caption={i18n.t("transactions.activity.finalValue")}
              cellRender={this.calcFinalValue}
            ></Column>
            <Column
              dataField="cashback_recieved"
              caption={i18n.t("transactions.activity.cashback_recieved")}
              cellRender={this.addCurrency}
            ></Column>
            <Column
              dataField="points_recieved"
              caption={i18n.t("transactions.activity.points_recieved")}
              cellRender={this.addCurrency}
            ></Column>
            <Column
              dataField="voucher"
              caption={i18n.t("transactions.activity.voucher")}
            ></Column>
            <Column
              dataField="couponCodeNumber"
              caption={i18n.t("transactions.activity.couponCodeNumber")}
            ></Column>
            <Column
              dataField="mobil"
              caption={i18n.t("transactions.activity.mobil")}
            ></Column>
            <Column
              dataField="personalId"
              caption={i18n.t("transactions.activity.personalId")}
            ></Column>
            <Column
              dataField="externalId"
              caption={i18n.t("transactions.activity.externalId")}
            ></Column>
            <Column
              dataField="weatherIcon"
              caption={i18n.t("transactions.activity.weatherIcon")}
              cellRender={this.wheater}
            ></Column>
            <Column
              dataField="nps"
              caption={i18n.t("transactions.activity.nps")}
              cellRender={this.handleNPS}
            ></Column>
            <Column
              dataField="comments"
              caption={i18n.t("transactions.activity.comments")}
            ></Column>
            <Export
              enabled={true}
              allowExportSelectedData={false}
              fileName="transactions"
            />
          </DataGrid>
        </div>

      </div>
    );
  }

}
