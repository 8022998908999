import React, { Component } from "react";
import { Button } from "devextreme-react";
import { loadMessages, locale } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import "./AddBranchMiddle.css";
import { LoadPanel } from "devextreme-react/load-panel";
import { TextBox } from "devextreme-react";
import { SelectBox } from "devextreme-react";
import {
  getAllBranchs,
  proceedSaveBranch,
  companyIdentifierValidation,
  buscaCEP,
} from "../../apis/toowezeApi";
import getCities from "../../apis/citiesApi";
import states from "../data/states";
import {
  Validator,
  RequiredRule,
  EmailRule,
  AsyncRule,
} from "devextreme-react/validator";
import { i18n, lgMessages, languageCode } from "../../translate/i18n";

const position = { of: "#addressCard" };
const positionAll = { of: "#branchCard" };

export default class AddBranchMiddle extends Component {
  constructor(props) {
    super(props);
    // Configura o idioma
    loadMessages(lgMessages);
    locale(languageCode);

    this.state = {
      branchType: 2,
      subGroupId: null,
      branchs: "",
      loadPanelVisible: false,
      allPanelVisible: false,
      cities: [],
      companyIdentifierCheck: true,
      goValidateIdentifier: false,
      goValidateEmail: false,
      emailCheck: false,
    };
  }

  componentDidMount() {
    getAllBranchs()
      .then((res) => {
        this.setState({
          branchs: res.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  // Reseta todos os campos
  reset = () => {
    this.setState({
      branchId: "",
      name: "",
      email: "",
      companyName: "",
      companyName2: "",
      addressLine1: "",
      addressLine2: "",
      cityRegion: "",
      state: "",
      city: "",
      companyIdentifier: "",
      facebookPage: "",
      instagramPage: "",
      latitude: "",
      longitude: "",
      mobil: "",
      phone: "",
      webSite: "",
      whatsup: "",
      zipCode: "",
      groupId: "",
      status: "",
      subGroupId: null,
      branchType: 2,
      logoURL: "",
      image: "",
      cities: [],
    });
  };

  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "top", offset: "0 200" },
        closeOnClick: true,
      },
      type,
      3000
    );
  }

  // Administra as mudanças de valores dos TextBox
  handleValueChange = (event, fieldId) => {
    if (fieldId) {
      this.setState({
        [fieldId]: event.value ? event.value : null,
      });
    }

    if (fieldId === "state") {
      getCities(event.value)
        .then((res) => {
          this.setState({
            cities: res.data,
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else if (fieldId == "email") {
      this.setState({ goValidateEmail: true });
    } else if (fieldId == "branchType") {
      let companyIdentifier = this.state.companyIdentifier;
      this.setState({
        companyIdentifier: 0,
        goValidateIdentifier: false,
      });

      this.setState({
        companyIdentifier: companyIdentifier,
        goValidateIdentifier: true,
      });
    }
  };

  // Gravar Estabelecimento
  saveBranch = () => {
    if (
      this.state.email &&
      this.state.emailCheck === true &&
      this.state.name &&
      this.state.companyName2
    ) {
      this.setState({ allPanelVisible: true });
      proceedSaveBranch(this.state, this.state.subGroupId)
        .then((res) => {
          if (res.status === 200) {
            if (this.state.subGroupId) {
              this.showNotify(
                i18n.t("addbranch.notify.brachsuccessfullyupdated"),
                "success"
              );
            } else {
              this.showNotify(
                i18n.t("addbranch.notify.branchsuccessfullycreated"),
                "success"
              );
            }
            setTimeout(() => {
              getAllBranchs()
                .then((res) => {
                  this.setState({
                    branchs: res.data,
                    allPanelVisible: false,
                  });
                })
                .catch((error) => {
                  this.setState({ allPanelVisible: false });
                });
            }, 500);
          }
        })
        .catch((error) => {
          this.showNotify(error.response.data, "error");
          this.setState({ allPanelVisible: false });
        });
    } else {
      this.showNotify(i18n.t("addbranch.notify.verifyrequiredfields"), "error");
    }
  };

  // Administra a mudança de valor no dropdownlist relativo aos Estabelecimentos
  branchSelected = (event) => {
    this.setState({ subGroupId: event.value, allPanelVisible: true });

    if (this.state.subGroupId) {
      getAllBranchs(this.state.subGroupId)
        .then((res) => {
          let selectedBranch;
          selectedBranch = res.data;

          this.setState({
            branchId: selectedBranch._id,
            addressLine1: selectedBranch.addressLine1,
            addressLine2: selectedBranch.addressLine2,
            cityRegion: selectedBranch.cityRegion,
            companyIdentifier: selectedBranch.companyIdentifier,
            companyName: selectedBranch.companyName
              ? selectedBranch.companyName
              : "",
            companyName2: selectedBranch.companyName2
              ? selectedBranch.companyName2
              : "",
            email: selectedBranch.email,
            state: selectedBranch.state,
            city: selectedBranch.city,
            facebookPage: selectedBranch.facebookPage,
            instagramPage: selectedBranch.instagramPage,
            latitude: selectedBranch.latitude,
            longitude: selectedBranch.longitude,
            mobil: selectedBranch.mobil,
            name: selectedBranch.name,
            phone: selectedBranch.phone,
            webSite: selectedBranch.webSite,
            whatsup: selectedBranch.whatsup,
            zipCode: selectedBranch.zipCode,
            groupId: selectedBranch.groupId,
            subGroupId: selectedBranch._id,
            logoURL: selectedBranch.logoURL ? selectedBranch.logoURL : "",
            image: "",
            goValidateEmail: true,
            goValidateIdentifier: selectedBranch.companyIdentifier
              ? true
              : false,
          });

          setTimeout(() => {
            this.setState({ allPanelVisible: false });
          }, 500);
        })
        .catch((error) => {
          this.setState({ allPanelVisible: false });
        });
    }
  };

  onKeyUp(event, fieldId) {
    event.event.preventDefault();

    let finalValue = event.event.currentTarget.value;

    if (fieldId === "zipCode") {
      this.setState({ goValidateEmail: false });
      this.setState({ goValidateIdentifier: false });

      /// CEP
      var v = finalValue.replace(/^([\d]{5})\.*([\d]{3})/, "$1-$2");
      finalValue = v;

      this.setState({
        zipCode: finalValue,
      });

      if (finalValue.length === 9) {
        this.setState({
          loadPanelVisible: true,
        });

        buscaCEP(this.state.zipCode)
          .then((cep) => {
            if (cep.data.uf) {
              getCities(cep.data.uf)
                .then((res) => {
                  if (res.data) {
                    this.setState({
                      cities: res.data,
                      loadPanelVisible: false,
                      addressLine1: cep.data.logradouro,
                      addressLine2: cep.data.complemento,
                      cityRegion: cep.data.bairro,
                      state: cep.data.uf,
                      city: cep.data.localidade,
                      backgroundColor: "#ffdede",
                    });
                  }
                })
                .catch((error) => {
                  console.log("Error", error);
                });
            }
          })
          .catch((error) => {
            this.setState({
              loadPanelVisible: false,
            });
          });
      }
    } else if (fieldId === "companyIdentifier") {
      if (this.state.branchType === 2) {
        /// Pessoa Jurídica
        var v = finalValue.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
          "$1.$2.$3/$4-$5"
        );
      } else if (this.state.branchType === 1) {
        /// Pessoa Física
        var v = finalValue.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/g,
          "$1.$2.$3-$4"
        );
      }

      finalValue = v;

      this.setState({
        companyIdentifier: finalValue,
      });

      if (
        this.state.companyIdentifier.length == 13 ||
        this.state.companyIdentifier.length == 17
      ) {
        this.setState({ goValidateIdentifier: true });
      } else {
        this.setState({ goValidateIdentifier: false });
      }
    } else if (
      fieldId === "phone" ||
      fieldId === "whatsup" ||
      fieldId === "mobil"
    ) {
      // Telefone
      var v = finalValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
      v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
      finalValue = v;
      this.setState({
        [fieldId]: finalValue ? finalValue : null,
      });
    } else if (fieldId == "email") {
      let email = event.event.currentTarget.value;

      if (email) {
        if (email.includes("@")) {
          this.setState({
            email: email,
            goValidateEmail: true,
          });
        }
      }
    }
  }

  // Insere a máscara quando clica no textbox
  insertMask(textBoxValue, fieldId) {
    if (textBoxValue) {
      textBoxValue = textBoxValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
      if (fieldId === "zipCode") {
        /// CEP
        return textBoxValue.replace(/^([\d]{5})\.*([\d]{3})/, "$1-$2");
      } else if (fieldId === "companyIdentifier") {
        if (this.state.branchType === 2) {
          /// Pessoa Jurídica
          return textBoxValue.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          );
        } else if (this.state.branchType === 1) {
          /// Pessoa Física
          return textBoxValue.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/g,
            "$1.$2.$3-$4"
          );
        }
      } else if (
        fieldId === "phone" ||
        fieldId === "whatsup" ||
        fieldId === "mobil"
      ) {
        // Telefone
        textBoxValue = textBoxValue.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        return textBoxValue.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
      }
    }
  }

  // Remove a máscara
  removeMask(textBoxValue) {
    if (textBoxValue) {
      return textBoxValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
    }
  }

  handleUploadChange = (event) => {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.setState({
          image: reader.result,
        });
      };
    }
  };

  // Verifica se existe CPF ou CNPJ já Cadastrados
  asyncCompanyIdentifierValidation = (params) => {
    if (this.state.goValidateIdentifier == true) {
      this.setState({ goValidateIdentifier: false });
      if (params.value.length > 13) {
        return companyIdentifierValidation(
          "companyIdentifier",
          params.value,
          this.state.branchType,
          this.state.branchId
        ).then((res) => {
          this.setState({ companyIdentifierCheck: !res });
          return !res; // Não tem repetido = true, é valido? !res
        });
      } else {
        return new Promise((resolve, reject) =>
          resolve(this.state.companyIdentifierCheck)
        );
      }
    } else {
      return new Promise((resolve, reject) =>
        resolve(this.state.companyIdentifierCheck)
      );
    }
  };

  // Verifica se o email já está cadastrado
  asyncCompanyIdentifierEmailValidation = (params) => {
    if (this.state.goValidateEmail == true) {
      this.setState({ goValidateEmail: false });
      return companyIdentifierValidation(
        "email",
        params.value,
        "",
        this.state.branchId
      ).then((res) => {
        this.setState({ emailCheck: !res });
        return !res; // Não tem repetido = true, é valido? !res
      });
    } else {
      return new Promise((resolve, reject) => resolve(this.state.emailCheck));
    }
  };

  render() {
    return (
      //branchs

      <div className="container col-11" style={{ marginLeft: "30px" }}>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          // onHiding={this.hideLoadPanel}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={true}
        />

        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={positionAll}
          visible={this.state.allPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={true}
        />

        <div id="branchCard" className="row mt-3 branch">
          <div className="col-6">
            <div className="card" style={{ height: "600px", overflow: "auto" }}>
              <div className="card-body">
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div
                        className="form-avatar"
                        style={{
                          backgroundImage: `url(${
                            this.state.image && this.state.image != ""
                              ? this.state.image
                              : this.state.logoURL
                          })`,
                        }}
                      ></div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-row-reverse">
                        <SelectBox
                          dataSource={
                            this.state.branchs ? this.state.branchs : null
                          }
                          style={{ margin: "3px" }}
                          displayExpr="companyName2"
                          valueExpr="_id"
                          value={
                            this.state.subGroupId ? this.state.subGroupId : null
                          }
                          width={150}
                          onValueChanged={(event) => this.branchSelected(event)}
                        />
                        <Button
                          id="backButton"
                          icon="plus"
                          onClick={this.reset}
                          text={i18n.t("addbranch.btnnew")}
                          height={30}
                          width={100}
                          style={{ margin: "3px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body" style={{ paddingBottom: "5px" }}>
                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelname")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          showClearButton={true}
                          placeholder={i18n.t("addbranch.labelname")}
                          value={this.state.name}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "name")
                          }
                        >
                          <Validator>
                            <RequiredRule
                              message={i18n.t(
                                "addbranch.message.requiredfieldname"
                              )}
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelemail")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          showClearButton={true}
                          placeholder={i18n.t("addbranch.placeholderemail")}
                          value={this.state.email}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "email")
                          }
                          onKeyUp={(event) => this.onKeyUp(event, "email")}
                        >
                          <Validator>
                            <RequiredRule
                              message={i18n.t(
                                "addbranch.message.requiredfieldemail"
                              )}
                            />
                            <EmailRule
                              message={i18n.t("addbranch.message.invalidemail")}
                            />
                            <AsyncRule
                              message={i18n.t(
                                "addbranch.message.emailalreadyregistered"
                              )}
                              validationCallback={(event) =>
                                this.asyncCompanyIdentifierEmailValidation(
                                  event
                                )
                              }
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labeltype")}
                      </div>
                      <div className="dx-field-value">
                        <SelectBox
                          items={[
                            {
                              branchType: 1,
                              branchName: i18n.t("addbranch.labelperson"),
                            },
                            {
                              branchType: 2,
                              branchName: i18n.t("addbranch.labelcorporate"),
                            },
                          ]}
                          stylingMode="filled"
                          displayExpr="branchName"
                          valueExpr="branchType"
                          defaultValue={this.state.branchType}
                          value={this.state.branchType}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "branchType")
                          }
                          width="100%"
                          placeholder={i18n.t(
                            "addbranch.placeholderidentifiertype"
                          )}
                        />
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelidentifiertype")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          placeholder=""
                          value={this.state.companyIdentifier}
                          onKeyUp={(event) =>
                            this.onKeyUp(event, "companyIdentifier")
                          }
                          onFocusIn={() =>
                            this.setState({
                              companyIdentifier: this.insertMask(
                                this.state.companyIdentifier,
                                "companyIdentifier"
                              ),
                            })
                          }
                          onFocusOut={() =>
                            this.setState({
                              companyIdentifier: this.removeMask(
                                this.state.companyIdentifier
                              ),
                            })
                          }
                        >
                          <Validator>
                            <AsyncRule
                              message={i18n.t(
                                "addbranch.message.identifieralreadyregistered"
                              )}
                              ignoreEmptyValue={true}
                              validationCallback={(event) =>
                                this.asyncCompanyIdentifierValidation(event)
                              }
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelcompanyname")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          showClearButton={true}
                          placeholder=""
                          value={this.state.companyName}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "companyName")
                          }
                        />
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelcompanynameas")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          showClearButton={true}
                          placeholder=""
                          value={this.state.companyName2}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "companyName2")
                          }
                        >
                          <Validator>
                            <RequiredRule
                              message={i18n.t(
                                "addbranch.message.requiredfieldcompanynameas"
                              )}
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div id="addressCard" className="card">
                  <div
                    className="card-body second-group"
                    style={{ paddingBottom: "5px" }}
                  >
                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelzipcode")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          placeholder=""
                          maxLength={9}
                          value={this.state.zipCode}
                          onKeyUp={(event) => this.onKeyUp(event, "zipCode")}
                        />
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelcityregion")}
                      </div>
                      <div className="dx-field-value">
                        <div className="row">
                          <div className="col-4">
                            <SelectBox
                              dataSource={states}
                              displayExpr="sigla"
                              valueExpr="sigla"
                              stylingMode="filled"
                              defaultValue=""
                              width="100%"
                              placeholder={i18n.t("addbranch.labelregion")}
                              value={this.state.state}
                              onValueChanged={(event) =>
                                this.handleValueChange(event, "state")
                              }
                            />
                          </div>
                          <div className="col-8">
                            <SelectBox
                              dataSource={this.state.cities}
                              displayExpr="nome"
                              valueExpr="nome"
                              stylingMode="filled"
                              defaultValue=""
                              width="100%"
                              placeholder={i18n.t("addbranch.labelcity")}
                              value={this.state.city}
                              onValueChanged={(event) =>
                                this.handleValueChange(event, "city")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labeladdressline1")}
                      </div>
                      <div className="dx-field-value">
                        <div className="row">
                          <div
                            className="col-8"
                            style={{ paddingRight: "3px" }}
                          >
                            <TextBox
                              showClearButton={true}
                              placeholder={i18n.t(
                                "addbranch.placeholderaddressline1"
                              )}
                              value={this.state.addressLine1}
                              onValueChanged={(event) =>
                                this.handleValueChange(event, "addressLine1")
                              }
                            />
                          </div>
                          <div className="col-4" style={{ paddingLeft: "4px" }}>
                            <TextBox
                              showClearButton={true}
                              placeholder={i18n.t(
                                "addbranch.placeholdernumber"
                              )}
                              value={this.state.number}
                              onValueChanged={(event) =>
                                this.handleValueChange(event, "+addressLine1")
                              }
                              style={{
                                backgroundColor: this.state.backgroundColor
                                  ? this.state.backgroundColor
                                  : "",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labeladdressline2")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          showClearButton={true}
                          placeholder={i18n.t(
                            "addbranch.placeholderaddressline2"
                          )}
                          value={this.state.addressLine2}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "addressLine2")
                          }
                        />
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelneighborhood")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          showClearButton={true}
                          placeholder={i18n.t(
                            "addbranch.placeholderneighborhood"
                          )}
                          value={this.state.cityRegion}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "cityRegion")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card" style={{ height: "600px", overflow: "auto" }}>
              <div className="card-body">
                <div style={{ height: "74px" }}></div>
                <div
                  className="card"
                  style={{ height: "484px", overflow: "auto" }}
                >
                  <div className="card-body" style={{ paddingBottom: "5px" }}>
                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelwebsite")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          showClearButton={true}
                          placeholder={i18n.t("addbranch.placeholderwebsite")}
                          value={this.state.webSite}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "webSite")
                          }
                        />
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelfacebook")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          showClearButton={true}
                          placeholder={i18n.t("addbranch.placeholderfacebook")}
                          value={this.state.facebookPage}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "facebookPage")
                          }
                        />
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelinstagram")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          showClearButton={true}
                          placeholder={i18n.t("addbranch.placeholderinstagram")}
                          value={this.state.instagramPage}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "instagramPage")
                          }
                        />
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelwhatsup")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          placeholder={i18n.t("addbranch.labelwhatsup")}
                          value={this.state.whatsup}
                          onKeyUp={(event) => this.onKeyUp(event, "whatsup")}
                          onFocusIn={() =>
                            this.setState({
                              whatsup: this.insertMask(
                                this.state.whatsup,
                                "whatsup"
                              ),
                            })
                          }
                          onFocusOut={() =>
                            this.setState({
                              whatsup: this.removeMask(this.state.whatsup),
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelphonenumber")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          placeholder={i18n.t(
                            "addbranch.placeholderphonenumber"
                          )}
                          value={this.state.phone}
                          onKeyUp={(event) => this.onKeyUp(event, "phone")}
                          onFocusIn={() =>
                            this.setState({
                              phone: this.insertMask(this.state.phone, "phone"),
                            })
                          }
                          onFocusOut={() =>
                            this.setState({
                              phone: this.removeMask(this.state.phone),
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelmobil")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          placeholder={i18n.t("addbranch.placeholdermobil")}
                          value={this.state.mobil}
                          onKeyUp={(event) => this.onKeyUp(event, "mobil")}
                          onFocusIn={() =>
                            this.setState({
                              mobil: this.insertMask(this.state.mobil, "mobil"),
                            })
                          }
                          onFocusOut={() =>
                            this.setState({
                              mobil: this.removeMask(this.state.mobil),
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addbranch.labelmaps")}
                      </div>
                      <div className="dx-field-value">
                        <div className="row">
                          <div className="col-6">
                            <TextBox
                              showClearButton={true}
                              placeholder={i18n.t(
                                "addbranch.placeholdermapslog"
                              )}
                              value={this.state.longitude}
                              onValueChanged={(event) =>
                                this.handleValueChange(event, "longitude")
                              }
                            />
                          </div>
                          <div className="col-6">
                            <TextBox
                              showClearButton={true}
                              placeholder={i18n.t(
                                "addbranch.placeholdermapslat"
                              )}
                              value={this.state.latitude}
                              onValueChanged={(event) =>
                                this.handleValueChange(event, "latitude")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="input-group"
                      style={{ marginTop: "3px", width: "60%", float: "right" }}
                    >
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="inputGroupFile04"
                          onChange={this.handleUploadChange}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="inputGroupFile04"
                        >
                          {i18n.t("addbranch.labellogo")}
                        </label>
                      </div>
                    </div>

                    <div
                      className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      style={{ marginTop: "150px", padding: "0px" }}
                    >
                      <div
                        className="row"
                        style={{ float: "right", marginRight: "0px" }}
                      >
                        <div className="form-group" style={{ width: "110px" }}>
                          <Button
                            id="backButton1"
                            icon="arrowleft"
                            text={i18n.t("addbranch.bntreset")}
                            width="100%"
                            height="50px"
                            type="outline"
                            onClick={this.reset}
                          />
                        </div>

                        <div
                          className="form-group ml-1"
                          style={{ width: "110px" }}
                        >
                          <Button
                            id="backButton2"
                            icon="tags"
                            text={i18n.t("addbranch.btnsave")}
                            width="100%"
                            height="50px"
                            type="success"
                            onClick={this.saveBranch}
                            useSubmitBehavior={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
