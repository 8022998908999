import {
  cmdImport,
  cmdDeviceDesktop,
  cmdDeviceTablet,
  cmdDeviceMobile,
  cmdClear,
} from "./../consts";

import "./panels.css";
import * as bundleJS from "../../../data/dataJS.txt";
import { saveLandinPage } from '../../../../apis/toowezeApi';
const AWS = require("aws-sdk");
// const uuid = require("uuid/v4");

// const auth = JSON.parse(localStorage.getItem('auth'));

// console.log("auth ===>", auth);

// let bucketName = auth.name.split(' ')

// console.log("bucketName", bucketName[0])

// if (bucketName.length < 5) {
//   if (bucketName.length > 0) {
//     bucketName = bucketName[0] + bucketName[1]
//   }
// }

// console.log('bucketName', bucketName.toLowerCase())

// const BUCKET = 'twz-' + bucketName.toLowerCase();
const BUCKET = 'twz-qyontecnologia7';
const BUCKET_THUMB = "coupon-images-tooweze-thumb";

export default (editor, config) => {
  const pn = editor.Panels;
  const eConfig = editor.getConfig();
  const crc = "create-comp";
  const mvc = "move-comp";
  const swv = "sw-visibility";
  const expt = "export-template";
  const osm = "open-sm";
  const otm = "open-tm";
  const ola = "open-layers";
  const obl = "open-blocks";
  const ful = "fullscreen";
  const prv = "preview";

  eConfig.showDevices = 0;


  pn.getPanels().reset([
    {
      id: "commands",
      buttons: [{}],
    },
    {
      id: "options",
      buttons: [
        // {
        // id: "mvc",
        // command: (e) => {
        //   // console.log("HTML ===>", e.getHtml());
        //   // console.log("CSS ====>", e.getCss());
        //   console.log("JS ====>", e.getJs());
        //   // <link href="css/styles.css" rel="stylesheet"/><!-- AQUI CSS -->
        //   // let bodyHTML = e.getHtml();
        //   // bodyHTML = bodyHTML.replace(
        //   //   "<!-- AQUI CSS -->",
        //   //   '<link href="styles.css" rel="stylesheet"/><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">'
        //   // );



        //   // let getHead = bodyHTML.slice(bodyHTML.indexOf("<head>"), bodyHTML.indexOf("</head>") + 7)

        //   // let newHead = `<head><meta charset="utf-8">
        //   //         <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
        //   //         <link rel="stylesheet" href="styles.css"></link>
        //   //         <script src="//code-eu1.jivosite.com/widget/0uaFENBhDk" async></script>
        //   //         </head>`

        //   // bodyHTML = bodyHTML.replace(getHead, newHead)

        //   // console.log("bodyHTML", bodyHTML)

        //   let bodyHTML = `<!doctype html>
        //   <html lang="en">
        //     <head>
        //       <meta charset="utf-8">
        //       <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
        //       <link rel="stylesheet" href="styles.css">
        //       <script src="//code-eu1.jivosite.com/widget/0uaFENBhDk" async></script>
        //       </head>
        //     <body>${e.getHtml()}
        //     <button onclick="myFunction()">Try it</button>
        //     </body>
        //     <script src="javascript.js"></script>

        //   <html>`;

        //   upload(bodyHTML)
        //     .then((res) => console.log("res HTML ====>"))
        //     .catch((err) => console.log("err HTML ====>", err));
        //   //scripts.js
        //   uploadJS(e.getJs())
        //     .then((res) => {
        //       console.log("res JS ====>", res);
        //     })
        //     .catch((err) => console.log("err JS ====>", err));
        //   uploadCSS(e.getCss())
        //     .then((res) => {
        //       console.log("res CSS ====>");
        //       window.open(
        //         `https://${BUCKET}.s3.amazonaws.com/index.html`
        //       );
        //     })
        //     .catch((err) => console.log("err CSS ====>", err));
        // },
        // context: "mvc",
        // className: "smallbtn btn btn-primary",
        // label: "Publicar",
        // },
        {
          id: "mvc",
          command: 'mvc',
          // command: (e) => {
          //   // console.log("HTML ===>", e.getHtml());
          //   // console.log("CSS ====>", e.getCss());
          //   console.log("JS ====>", e.getJs());
          //   // <link href="css/styles.css" rel="stylesheet"/><!-- AQUI CSS -->
          //   // let bodyHTML = e.getHtml();
          //   // bodyHTML = bodyHTML.replace(
          //   //   "<!-- AQUI CSS -->",
          //   //   '<link href="styles.css" rel="stylesheet"/><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">'
          //   // );

          //   let bodyHTML = `<!doctype html>
          //   <html lang="en">
          //     <head>
          //       <meta charset="utf-8">
          //       <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
          //       <link rel="stylesheet" href="styles.css">
          //     </head>
          //     <body>${e.getHtml()}
          //     <button onclick="myFunction()">Try it</button>
          //     </body>
          //     <script src="javascript.js"></script>
          //   <html>`;

          //   // upload(bodyHTML)
          //   //   .then((res) => console.log("res HTML ====>"))
          //   //   .catch((err) => console.log("err HTML ====>", err));
          //   // //scripts.js
          //   // uploadJS(e.getJs())
          //   //   .then((res) => {
          //   //     console.log("res JS ====>", res);
          //   //   })
          //   //   .catch((err) => console.log("err JS ====>", err));
          //   // uploadCSS(e.getCss())
          //   //   .then((res) => {
          //   //     console.log("res CSS ====>");
          //   //     window.open(
          //   //       `https://${BUCKET}.s3.amazonaws.com/index.html`
          //   //     );
          //   //   })
          //   //   .catch((err) => console.log("err CSS ====>", err));

          //   console.log("e.getJs() ====>", e.getJs());

          //   saveLandinPage(undefined, bodyHTML, e.getCss()).then(res => {
          //     console.log("Gravando Res", res)
          //   }).catch(err => console.log("Erro gravando", err))



          // },
          context: "mvc",
          className: "smallbtn btn btn-primary",
          label: "Publicar",
        },
        {
          id: "mvc_",
          command: 'mvc_',
          // command: (e) => {
          //   // console.log("HTML ===>", e.getHtml());
          //   // console.log("CSS ====>", e.getCss());
          //   console.log("JS ====>", e.getJs());
          //   // <link href="css/styles.css" rel="stylesheet"/><!-- AQUI CSS -->
          //   // let bodyHTML = e.getHtml();
          //   // bodyHTML = bodyHTML.replace(
          //   //   "<!-- AQUI CSS -->",
          //   //   '<link href="styles.css" rel="stylesheet"/><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">'
          //   // );

          //   let bodyHTML = `<!doctype html>
          //   <html lang="en">
          //     <head>
          //       <meta charset="utf-8">
          //       <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
          //       <link rel="stylesheet" href="styles.css">
          //     </head>
          //     <body>${e.getHtml()}
          //     <button onclick="myFunction()">Try it</button>
          //     </body>
          //     <script src="javascript.js"></script>
          //   <html>`;

          //   // upload(bodyHTML)
          //   //   .then((res) => console.log("res HTML ====>"))
          //   //   .catch((err) => console.log("err HTML ====>", err));
          //   // //scripts.js
          //   // uploadJS(e.getJs())
          //   //   .then((res) => {
          //   //     console.log("res JS ====>", res);
          //   //   })
          //   //   .catch((err) => console.log("err JS ====>", err));
          //   // uploadCSS(e.getCss())
          //   //   .then((res) => {
          //   //     console.log("res CSS ====>");
          //   //     window.open(
          //   //       `https://${BUCKET}.s3.amazonaws.com/index.html`
          //   //     );
          //   //   })
          //   //   .catch((err) => console.log("err CSS ====>", err));

          //   console.log("e.getJs() ====>", e.getJs());

          //   saveLandinPage(undefined, bodyHTML, e.getCss()).then(res => {
          //     console.log("Gravando Res", res)
          //   }).catch(err => console.log("Erro gravando", err))



          // },
          context: "mvc_",
          className: "smallbtn btn btn-primary",
          label: "Gravar",
        },
        {
          id: swv,
          command: swv,
          context: swv,
          className: "fa fa-square-o",
          active: true,
        },
        {
          id: prv,
          context: prv,
          command: (e) => e.runCommand(prv),
          className: "fa fa-eye",
        },
        {
          id: ful,
          command: ful,
          context: ful,
          className: "fa fa-arrows-alt",
        },
        // {
        //   id: expt,
        //   className: "fa fa-code",
        //   command: (e) => e.runCommand(expt),
        // },
        {
          id: "undo",
          className: "fa fa-undo",
          command: (e) => e.runCommand("core:undo"),
        },
        {
          id: "redo",
          className: "fa fa-repeat",
          command: (e) => e.runCommand("core:redo"),
        },
        {
          id: cmdImport,
          className: "fa fa-download",
          command: (e) => e.runCommand(cmdImport),
        },
        {
          id: cmdClear,
          className: "fa fa-trash",
          command: (e) => e.runCommand(cmdClear),
        },
        {
          id: 'open-code',
          // className: 'fa fa-code',
          className: 'fa fa-toggle-off',
          command: 'open-code',
          attributes: { title: 'Open HTML/CSS Code' },
        }
      ],
    },
    {
      id: "views",
      buttons: [
        {
          id: osm,
          command: osm,
          active: true,
          className: "fa fa-paint-brush",
        },
        {
          id: otm,
          command: otm,
          className: "fa fa-cog",
        },
        {
          id: ola,
          command: ola,
          className: "fa fa-bars",
        },
        {
          id: obl,
          command: obl,
          className: "fa fa-th-large",
        },
      ],
    },
  ]);

  // Add devices buttons
  const panelDevices = pn.addPanel({ id: "devices-c" });
  panelDevices.get("buttons").add([
    {
      id: cmdDeviceDesktop,
      command: cmdDeviceDesktop,
      className: "fa fa-desktop",
      active: 1,
    },
    {
      id: cmdDeviceTablet,
      command: cmdDeviceTablet,
      className: "fa fa-tablet",
    },
    {
      id: cmdDeviceMobile,
      command: cmdDeviceMobile,
      className: "fa fa-mobile",
    },
  ]);

  const openBl = pn.getButton("views", obl);
  editor.on("load", () => openBl && openBl.set("active", 1));

  // On component change show the Style Manager
  config.showStylesOnChange &&
    editor.on("component:selected", () => {
      const openSmBtn = pn.getButton("views", osm);
      const openLayersBtn = pn.getButton("views", ola);

      // Don't switch when the Layer Manager is on or
      // there is no selected component
      if (
        (!openLayersBtn || !openLayersBtn.get("active")) &&
        editor.getSelected()
      ) {
        openSmBtn && openSmBtn.set("active", 1);
      }
    });
};

AWS.config = {
  accessKeyId: "AKIA5UH2UFRKK56K6FJP",
  secretAccessKey: "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",
  region: "us-east-1",
};


const S3 = new AWS.S3();

// const getObject = (key) => {
//   return new Promise((res, rej) => {
//     S3.getObject(
//       {
//         Bucket: BUCKET,
//         Key: key,
//       },
//       (err, data) => {
//         if (err) {
//           return rej(err);
//         }
//         return res(data.Body);
//       }
//     );
//   });
// };




// https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/LinkFormat.html
// https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/ServingCompressedFiles.html
// https://docs.aws.amazon.com/AmazonS3/latest/API/API_PutObject.html
// https://niiknow.github.io/grapesjs-components-farmer/?css=materialize
// https://stackoverflow.com/questions/62146891/how-to-add-a-custom-button-to-the-grapesjs-toolbar

const upload = (body) => {
  // const id = uuid();
  return new Promise((res, rej) => {

    S3.putObject(
      {
        Bucket: BUCKET,
        Key: "land1/index.html",
        Body: body,
        // Body: new Buffer.from(
        //   body.replace(/^data:image\/\w+;base64,/, ""),
        //   "base64"
        // ),
        // ContentEncoding: "base64",
        ContentType: "text/html",
      },
      (err) => {
        if (err) {
          return rej(err);
        }
        return res({
          bucket: BUCKET,
          key: "land1/index.html",
        });
      }
    );


  });
};

const uploadCSS = (body) => {
  // const id = uuid();
  return new Promise((res, rej) => {
    S3.putObject(
      {
        Bucket: BUCKET,
        Key: "land1/styles.css",
        Body: body,
        // Body: new Buffer.from(
        //   body.replace(/^data:image\/\w+;base64,/, ""),
        //   "base64"
        // ),
        // ContentEncoding: "base64",
        ContentType: "text/css",
      },
      (err) => {
        if (err) {
          return rej(err);
        }
        return res({
          bucket: BUCKET,
          key: "land1/styles.css",
        });
      }
    );
  });
};

// https://stackoverflow.com/questions/19459893/how-to-create-folder-or-key-on-s3-using-aws-sdk-for-node-js/31728298
const uploadJS = (body) => {
  // const id = uuid();
  body += `
  function myFunction() {
    alert("Hello! I am an alert box!");
  }
  `;
  return new Promise((res, rej) => {
    S3.putObject(
      {
        Bucket: BUCKET,
        Key: "land1/javascript.js",
        Body: body,
        ContentType: "text/js",
      },
      (err) => {
        if (err) {
          return rej(err);
        }
        return res({
          bucket: BUCKET,
          key: "land1/javascript.js",
        });
      }
    );
  });
};

// const update = (id, body, thumb, couponId) => {
//   return new Promise((res, rej) => {
//     S3.putObject(
//       {
//         Bucket: thumb && thumb === true ? BUCKET_THUMB : BUCKET,
//         Key: id + ".jpg",
//         Body: new Buffer.from(
//           body.replace(/^data:image\/\w+;base64,/, ""),
//           "base64"
//         ),
//         ContentEncoding: "base64",
//         ContentType: "image/jpeg",
//       },
//       (err) => {
//         if (err) {
//           return rej(err);
//         }
//         return res({
//           bucket: thumb && thumb === true ? BUCKET_THUMB : BUCKET,
//           key: id + ".jpg",
//         });
//       }
//     );
//   });
// };
