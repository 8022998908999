import React, { Component } from "react";
import DataGrid, {

    Column,
    Pager,
    Paging,
    HeaderFilter,
    SearchPanel,
    Editing,
    Lookup,
    RequiredRule,
    Export,

} from "devextreme-react/data-grid";
import Spinner from "../../LayOut/Spinner";
import { getContactList, EditContactList, createNewContactList, deleteContactList, } from "../../../apis/toowezeApi";
import { Link } from "react-router-dom";
import SelectBox from "devextreme-react/select-box";
import "./ContactsMiddle.css";
import { i18n } from "../../../translate/i18n";

export default class ContactsMiddle extends Component {

    constructor(props) {

        super(props);
        this.state = {

            datagrid: [],
            spinner: true,
            events: [],

        };

        this.logEvent = this.logEvent.bind(this);
        this.onEditingStart = this.logEvent.bind(this, "EditingStart");
        this.onInitNewRow = this.logEvent.bind(this, "InitNewRow");
        this.onRowInserting = this.logEvent.bind(this, "RowInserting");
        this.onRowUpdating = this.logEvent.bind(this, "RowUpdating");
        this.onRowRemoving = this.logEvent.bind(this, "RowRemoving");

        this.clearEvents = this.clearEvents.bind(this);

        // Insere opções na coluna de Status
        this.statusEditorRender = this.statusEditorRender.bind(this);

    }

    // Evento que ocorre quando uma linha é adicionada na tabela.
    onRowInserted = (event) => {

        createNewContactList(event.data.listName, event.data.status).then((res) => {

            this.getContactList(); // Relê a lista após gravar uma nova lista.

        });

    };

    // Evento que ocorre quando uma linha é editada na tabela.
    onRowUpdated = (event) => {

        try {

            EditContactList(event.data._id, event.data.listName, event.data.status).then((res) => {
                this.getContactList(); // Relê a lista após gravar uma nova lista.
            });

        } catch (error) {
            console.log("onRowUpdated Error", error);
        }

    };

    // Evento que ocorre quando uma linha é deletada da tabela.
    onRowRemoved = (event) => {

        if (event.data._id) {

            deleteContactList(event.data._id).then((res) => { }).catch((err) => {

                console.log("err", err);

            });

        }

    };

    logEvent(eventName) {

        this.setState((state) => {

            return {

                events: [eventName].concat(state.events)

            };

        });

    }

    clearEvents() {

        this.setState({ events: [] });

    }

    componentDidMount() { // Chama a função que lê os dados e lança no grid.
        this.getContactList();

    }

    // Função que lê carrega o grid
    getContactList() {

        getContactList().then((res) => {

            this.setState({ datagrid: res, spinner: false, });

        }).catch((err) => {

            this.setState({ datagrid: [], spinner: false, });

        });

    }

    // Cria os botões
    contactsActions(data) {

        return (
            data.data?._id ?
                <Link

                    to={
                        `contactsview?id=${data.data?._id
                        }`
                    }
                    className="buttonClass badge badge-success"
                    style={
                        { color: "#fff" }
                    }>
                    {
                        i18n.t("contacts.grid.addcontactslabel")
                    } </Link> : ""
        );

    }

    // Insere opções na coluna de Status
    onValueChanged(cell, e) {
        cell.setValue(e.value);
    }

    statusEditorRender(cell) {

        let onValueChanged = this.onValueChanged.bind(this, cell);
        return (
            <SelectBox defaultValue={
                cell.value
            }
                {...cell.column.lookup}
                onValueChanged={onValueChanged}
                itemRender={
                    this.itemRender
                } />
        );

    }

    itemRender(data) {

        let imageSource = `../images/status-${data._id
            }.png`;
        if (data != null) {

            return (
                <div>
                    <img src={imageSource}
                        className="status-icon middle_"
                        alt=""></img>
                    <span className="middle_">
                        {
                            data.name
                        }</span>
                </div>
            );

        } else {

            return <span>(All)</span>;

        }

    }

    calculateCellValue2(data) {

        return data.createdAt ? new Date(data.createdAt * 1000) : "";

    }

    statuses = [
        {

            id: 0,
            name: i18n.t("contacts.grid.contactliststatuspaused"),

        }, {

            id: 1,
            name: i18n.t("contacts.grid.contactliststatusready"),

        },
    ];
    // Insere opções na coluna de Status

    render() {

        if (this.state.spinner == true) {

            return <Spinner />;

        } else { // Nome da Lista de Contatos	Criado em	Atualizada em	Status	Editar/Deletar

            return (
                <div className="container col-11"
                    style={
                        {

                            marginLeft: "30px",
                            marginTop: "30px",

                        }
                    }>
                    <DataGrid dataSource={
                        this.state.datagrid
                    }
                        showBorders={true}
                        allowColumnReordering={true}
                        onEditingStart={
                            this.onEditingStart
                        }
                        onInitNewRow={
                            this.onInitNewRow
                        }
                        onRowInserting={
                            this.onRowInserting
                        }
                        onRowInserted={
                            this.onRowInserted
                        }
                        onRowUpdating={
                            this.onRowUpdating
                        }
                        onRowUpdated={
                            this.onRowUpdated
                        }
                        onRowRemoving={
                            this.onRowRemoving
                        }
                        onRowRemoved={
                            this.onRowRemoved
                        }>
                        <Editing mode="row"
                            allowUpdating={true}
                            allowAdding={true}
                            allowDeleting={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={
                                [5, 10, 15,]
                            }
                            showInfo={true} />

                        <HeaderFilter visible={true} />
                        <SearchPanel visible={true}
                            className="custom"
                            width={240}
                            placeholder={
                                i18n.t("contacts.grid.searchplaceholder")
                            } />
                        <Column dataField="listName"
                            caption={
                                i18n.t("contacts.grid.columnlabelistname")
                            }>
                            <RequiredRule />
                        </Column>

                        <Column dataField="createdAt"
                            caption={
                                i18n.t("contacts.grid.columnlabelistdate")
                            }
                            dataType="date"
                            calculateCellValue={
                                this.calculateCellValue2
                            }
                            allowEditing={false} />

                        <Column dataField="status"
                            caption={
                                i18n.t("contacts.grid.columnlabeliststatus")
                            }
                            width={200}
                            allowEditing={true}
                            editCellRender={
                                this.statusEditorRender
                            }>
                            <Lookup dataSource={
                                this.statuses
                            }
                                displayExpr="name"
                                valueExpr="id" />
                            <RequiredRule />
                        </Column>
                        <Column dataField="sendDate_"
                            caption={
                                i18n.t("contacts.grid.columnlabelistactions")
                            }
                            cellRender={
                                this.contactsActions
                            }
                            allowEditing={false} />
                        <Export enabled={true}
                            allowExportSelectedData={false}
                            fileName="TooWeze_ContactsList" />
                    </DataGrid>
                </div>
            );

        }

    }

}
