import { i18n } from "../../translate/i18n";

export default [
  {
    element: "front",
    title: i18n.t("coupon.topbar.front"),
    extra: "",
    id: "front",
    svg: "M2 2h9v4H9V4H4v5h2v2H2V2m20 11v9h-9v-4h2v2h5v-5h-2v-2h4M8 8h8v8H8V8z",
    fill: "#1c7cec",
  },
  {
    element: "forward",
    title: i18n.t("coupon.topbar.forward"),
    extra: "",
    id: "forward",
    svg: "M2 2h14v14H2V2m20 6v14H8v-4h2v2h10V10h-2V8h4z",
    fill: "#1c7cec",
  },
  {
    element: "back",
    title: i18n.t("coupon.topbar.back"),
    extra: "",
    id: "back",
    svg:
      "M2 2h9v9H2V2m7 2H4v5h5V4m13 9v9h-9v-9h9m-7 7h5v-5h-5v5m1-12v3h-3V8h3m-5 8H8v-3h3v3z",
    fill: "#1c7cec",
  },
  {
    element: "backward",
    title: i18n.t("coupon.topbar.backward"),
    extra: "",
    id: "backward",
    svg: "M2 2h14v14H2V2m20 6v14H8v-4h10V8h4M4 4v10h10V4H4z",
    fill: "#1c7cec",
  },
  {
    element: "rectangle",
    title: i18n.t("coupon.topbar.rectangle"),
    extra: "",
    id: "rectangle",
    svg: "M19 6h3v2h-3v3h-2V8h-3V6h3V3h2v3m-2 11v-3h2v5H3V6h8v2H5v9h12z",
    fill: "#1c7cec",
  },
  {
    element: "square",
    title: i18n.t("coupon.topbar.square"),
    extra: "",
    id: "square",
    svg: "M19 5h3v2h-3v3h-2V7h-3V5h3V2h2v3m-2 14v-6h2v8H3V5h8v2H5v12h12z",
    fill: "#1c7cec",
  },
  {
    element: "circle",
    title: i18n.t("coupon.topbar.circle"),
    extra: "",
    id: "circle",
    svg:
      "M12 20a8 8 0 0 1-8-8a8 8 0 0 1 8-8a8 8 0 0 1 8 8a8 8 0 0 1-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z",
    fill: "#1c7cec",
  },
  {
    element: "triangle",
    title: i18n.t("coupon.topbar.triangle"),
    extra: "",
    id: "triangle",
    svg: "M12 2L1 21h22M12 6l7.53 13H4.47",
    fill: "#1c7cec",
  },
  {
    element: "text",
    title: i18n.t("coupon.topbar.text"),
    extra: "Georgia",
    id: "text",
    svg:
      "M18.5 4l1.16 4.35l-.96.26c-.45-.87-.91-1.74-1.44-2.18C16.73 6 16.11 6 15.5 6H13v10.5c0 .5 0 1 .33 1.25c.34.25 1 .25 1.67.25v1H9v-1c.67 0 1.33 0 1.67-.25c.33-.25.33-.75.33-1.25V6H8.5c-.61 0-1.23 0-1.76.43c-.53.44-.99 1.31-1.44 2.18l-.96-.26L5.5 4h13z",
    fill: "#1c7cec",
  },
  {
    element: "delete",
    title: i18n.t("coupon.topbar.delete"),
    extra: "",
    id: "delete",
    svg:
      "M15 16h4v2h-4v-2m0-8h7v2h-7V8m0 4h6v2h-6v-2m-4-2v8H5v-8h6m2-2H3v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V8m1-3h-3l-1-1H6L5 5H2v2h12V5z",
    fill: "#ff0000",
  },
];
