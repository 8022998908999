import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enMessages from "devextreme/localization/messages/en.json";
import esMessages from "devextreme/localization/messages/es.json";
import ptMessages from "devextreme/localization/messages/pt.json";

import { messages } from "./languages";
let messageContent;
let langCode;

i18n.use(LanguageDetector).init({
  debug: false,
  defaultNS: ["translations"],
  fallbackLng: "en",
  ns: ["translations"],
  resources: messages,
});

switch (localStorage.getItem("i18nextLng")) {
  case "pt-BR":
    messageContent = ptMessages;
    langCode = "pt";
    break;
  case "en-US":
    messageContent = enMessages;
    langCode = "en";
    break;
  case "es":
    messageContent = esMessages;
    langCode = "es";
    break;
  default:
}

export const lgMessages = messageContent;
export const languageCode = langCode;

export { i18n };
