import React, { Component } from "react";
export default class LogOut extends Component {
  componentWillMount() {
    localStorage.removeItem("auth");
    localStorage.removeItem("tooweze_token");
    localStorage.removeItem("isConfigured");
  }

  render() {
    return (
      <div>
        <h1>LogOut</h1>
      </div>
    );
  }
}
