import React from "react";
import ReactDOM from "react-dom";
import "./custom.scss";
import "./index.css";
import App from "./App";
// import { ClerkProvider } from '@clerk/clerk-react'
import 'dotenv/config'

// import * as serviceWorker from './serviceWorker';


// 1. In order to wire up a redux/react app, we newwd react-redux
// we need Provider ReactComponent, to be around everything!
import { Provider } from "react-redux";

// 2. We need to create redux store, so that redux exists, and the provider has a store!
import { createStore } from "redux";

// 3. reducers to populate the store
// 3a We always start with a rootReducer
// 4. make individual reducers to hand to the rootreducer (3)
import rootReducer from "./reducers/rootReducer";
// 5. create the store (2) by passing it the root rootReducer, wich is made up of the reducers

// const theStore = createStore(rootReducer);

console.log('process', process.env)

// Import your publishable key
const PUBLISHABLE_KEY = 'pk_test_Y29uY3JldGUtd2VldmlsLTQ0LmNsZXJrLmFjY291bnRzLmRldiQ'

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

const theStore = createStore(
  rootReducer,
  /* preloadedState, */ window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__()
);

// Provider is the glue between react and redux. Give it the store!

ReactDOM.render(
  // <React.StrictMode>

  <Provider store={theStore}>
    {/* <ClerkProvider publishableKey={PUBLISHABLE_KEY}> */}
    <App />
    {/* </ClerkProvider> */}
  </Provider>,

  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// git add ..
// git commit -m ""
// git push
