import React, { Component } from "react";
import { actualURL } from "../../../apis/apiBase";
import { LoadPanel } from "devextreme-react/load-panel";
import Spinner from "../../LayOut/Spinner";
import { Button } from "devextreme-react";
import { loadMessages, locale } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import {
  searchCampaign,
  getContactListUsers,
  getHtmlBody,
  getAllHtmlBody,
  getContactList,
  readSenderEmails,
  getCoupons,
  proceedSaveCampaign,
  sendEmailTest,
  reports
} from "../../../apis/toowezeApi";
import { SelectBox } from "devextreme-react";
import { TextBox } from "devextreme-react/text-box";
import DateBox from "devextreme-react/date-box";
import "./CampaignCreateMiddle.css";
import jimp from "jimp";

import { i18n, lgMessages, languageCode } from "../../../translate/i18n";

let emailArray = [];
const position = {
  of: "#allCard",
};

// Ler informações de relatórios

let reportList = []

export default class CampaignCreateMiddle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardEmail: "active_",
      cardShare: "",
      cardPushNotification: "",
      messageId: "",
      subject: "",
      campaignName: "",
      contactListId: "",
      spinner: true,
      contactList: [],
      htmlBodyData: 0,
      readSenderEmails: 0,
      cuponList: 0,
      isMultiline: true, // / HTML Editor
      sendDate: "0",
      messageBody: "",
      shareComplete: false,
      notification: {
        title: "",
        content: "",
        dateToSend: null
      }
      // showCoupon: null,
    };

    this.sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
    this.fontValues = [
      "Arial",
      "Courier New",
      "Georgia",
      "Impact",
      "Lucida Console",
      "Tahoma",
      "Times New Roman",
      "Verdana",
    ];
    this.headerValues = [false, 1, 2, 3, 4, 5];
    this.enabled = {
      enabled: true,
    };

    this.campaignId = null;


    // Configura o idioma
    loadMessages(lgMessages);
    locale(languageCode);
  }

  componentDidMount() {
    // Verifica se está exibindo uma campanha

    this.setState({ spinner: false, loadPanelVisible: false });

    // Busca os emails de envio
    readSenderEmails()
      .then((res) => {
        if (res.length == 0) {
          // Nenhum item na tabela
          this.setState({ readSenderEmails: 0 });
        } else {
          // Se tiver um que está ativo verifica
          let active = false;
          let i;
          emailArray = [];

          for (i = 0; i < res.length; i++) {
            if (res[i].status == "Active") {
              active = true;
              emailArray.push({ _id: res[i]._id, email: res[i].email });
              // break;
            }
          }

          if (active == true) {
            this.setState({
              readSenderEmails: emailArray,
              loadPanelVisible: false,
              spinner: false,
            });
          } else {
            this.setState({
              readSenderEmails: -1,
              loadPanelVisible: false,
              spinner: false,
            });
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ loadPanelVisible: false, spinner: false });
      });

    let urlParams = new URLSearchParams(
      window.location.search.replace("amp;", "")
    );
    this.campaignId = urlParams.get("campaignId");
    if (this.campaignId) {
      this.searchCampaign(this.campaignId);
    } else {
      this.emailMarketingCampaign();
    }
  }

  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: {
          at: "top",
          offset: "0 200",
        },
        closeOnClick: true,
      },
      type,
      3000
    );

    this.setState({ spinner: false });
  }

  // Busca a campanha para mostrar
  searchCampaign(campaignId) {
    // Ativar loading
    this.setState({ spinner: true });
    searchCampaign(campaignId)
      .then((res) => {
        this.proceedPublishShare(res);
      })
      .catch((err) => {
        this.showNotify(err, "error");
      });
  }

  proceedPublishShare(campaign) {
    // Exibe as informações para compartilhamento em rede social

    if (campaign.sentType === 1) {
      // Relacionando o email de envio, com o id de envio
      for (var i = 0; i < emailArray.length; i++) {
        if (emailArray[i].email == campaign.sendFrom) {
          this.setState({ readSenderEmailsDefaultValue: emailArray[i]._id });
        }
      }

      this.setState({
        messageId: campaign.messageId,
        subject: campaign.subject,
        campaignName: campaign.name,
        contactListId: campaign.contactListId,
        sendDate: campaign.sendDate, // + " 00:00:00",
        spinner: false,
        loadPanelVisible: false,
      });

      this.emailMarketingCampaign().then((res) => {
        // / ContactList Selecionado no Dropdown
        getContactListUsers(this.state.contactListId)
          .then((res) => {
            // Está buscando todos os contatos só pra informar o número de contatos, deveria ser refatorado isso.
            this.setState({
              contactListQTD: res.length,
              spinner: false,
              loadPanelVisible: false,
            });
          })
          .catch((error) => {
            if (error.response) {
              this.setState({
                contactListQTD: res.length,
                spinner: false,
                loadPanelVisible: false,
              });

              if (
                error.response.status === "404" &&
                error.response.data === "Not found"
              ) {
                this.showNotify(
                  i18n.t("campaigncreate.notify.contactlistalnotfound"),
                  "error"
                );
              } else {
                this.showNotify(
                  i18n.t("campaigncreate.notify.contactlistunavailable"),
                  "error"
                );
              }
            }
          });

        // Busca a Mensagem HTML
        if (this.state.messageId) {
          getHtmlBody(this.state.messageId)
            .then((res) => {
              this.setState({
                spinner: false,
                messageBody: res.messageBody,
                loadPanelVisible: false,
              });
            })
            .catch((error) => {
              this.setState({ spinner: false, loadPanelVisible: false });
              if (error.response) {
                if (
                  error.response.status == "404" &&
                  error.response.data == "Not found"
                ) {
                  this.showNotify(
                    i18n.t("campaigncreate.notify.htmlmessagenotfound"),
                    "error"
                  );
                } else {
                  this.showNotify(error.response.data, "error");
                }
              }
            });
        }

        this.setState({ spinner: false, loadPanelVisible: false });
      });
    } else if (campaign.sentType === 3) {
      this.shareCampaign();

      for (var i = 0; i < emailArray.length; i++) {
        if (emailArray[i].email == campaign.sendFrom) {
          this.setState({ readSenderEmailsDefaultValue: emailArray[i]._id });
        }
      }

      this.setState({
        messageId: campaign.messageId,
        subject: campaign.subject,
        campaignName: campaign.name,
        contactListId: campaign.contactListId,
        cardEmail: "",
        cardShare: "active_",
        downloadCode: "",
        shareComplete: true,
        urlShare:
          actualURL.url +
          `viewcoupon.html?campaignId=${campaign._id}&couponId=${campaign.messageId}&rootId=${campaign.rootId}&source=2`,
      });

      getCoupons(campaign.messageId, false).then((res) => {
        this.setState({
          spinner: false,
          loadPanelVisible: false,
          showCoupon: res,
        });
      });
    } else if (campaign.sentType == 4) {
      // Campanha de download de código
    }
  }

  // Modificando tudo para Campanha de Email Marketing
  emailMarketingCampaign() {
    return new Promise(async (resolve, reject) => {

      reportList = await reports();
      reportList = reportList.map((report) => {
        report.isCustomer = true;
        return report;
      });

      reports().then((res) => {
        console.log("Reports", res)
      });

      getContactList().then((res) => {
        this.setState({
          contactList: [...res.filter((list) => list.status === 1), ...reportList],
          loadPanelVisible: false,
          spinner: false,
        });
      });

      console.log("State", this.state);

      getAllHtmlBody().then((res) => {
        if (res) {
          this.setState({
            htmlBodyData: res, // [] Usei para testar mensagem
            loadPanelVisible: false,
            spinner: false,
          });
        }

        resolve("Resolvido");
      });
    });
  }

  // Modificando tudo para Campanha de Compartilhamento
  shareCampaign() {
    // Buscando a lista de contatos

    // Obtendo a lista de cupons
    getCoupons()
      .then((res) => {
        if (res.length == 0) {
          // Nenhum item na tabela
          this.setState({ cuponList: 0, loadPanelVisible: false });
        } else {
          // Se tiver um que está ativo verifica
          let active = false;
          let i;
          let cuponsArray = [];

          for (i = 0; i < res.length; i++) {
            if (res[i].status == "1") {
              active = true;
              cuponsArray.push({
                _id: res[i]._id,
                title: res[i].title,
                image: res[i].image,
                imageurlThumb: res[i].imageurlThumb,
              });
            }
          }

          if (active === true) {
            this.setState({ cuponList: cuponsArray });
          } else {
            this.setState({ cuponList: -1 });
          }
        }
      })
      .then(async () => {

        reportList = await reports();
        reportList = reportList.map((report) => {
          report.isCustomer = true;
          return report;
        });


        getContactList().then((res) => {

          this.setState({
            contactList: [...res.filter((list) => list.status === 1), ...reportList], // [] Usei para testar mensagem
            loadPanelVisible: false,
          });
        });
      });
  }

  // Função que administra toda mudança de valor nos componentes
  handleValueChange = (event, fieldId) => {
    if (fieldId) {
      this.setState({
        [fieldId]: event.value ? event.value.toString() : null,
      });
    }
  };

  // Função de quando clica os cards relativos a escolha do tipo de campanha.
  changeCampaignType = (event) => {
    this.reset();

    if (event.currentTarget.id === "cardEmail") {
      this.setState({
        cardEmail: "active_",
        cardShare: "",
        downloadCode: "",
        cardPushNotification: "",
        loadPanelVisible: true,
        spinner: false,
      });

      this.emailMarketingCampaign();
    } else if (event.currentTarget.id === "cardShare") {
      this.setState({
        cardEmail: "",
        cardShare: "active_",
        downloadCode: "",
        cardPushNotification: "",
        loadPanelVisible: true,
        spinner: false,
      });

      this.shareCampaign();
      // downloadCode
    } else if (event.currentTarget.id === "cardPushNotification") {
      this.setState({
        cardEmail: "",
        cardShare: "",
        downloadCode: "",
        cardPushNotification: "active_",
        loadPanelVisible: true,
        spinner: false,
      });

      this.shareCampaign();
      // downloadCode
    } else if (event.currentTarget.id === "downloadCode") {
      // Desabilitado
      // console.log('Aqui 586')
      // this.setState({
      //     cardEmail: '',
      //     cardShare: '',
      //     downloadCode: 'active_',
      //     loadPanelVisible: true
      // })

      // this.shareCampaign();
      this.reset();
    }
  };

  Field = (data) => {
    if (data) {
      return (
        <div className="custom-item">
          <img
            src={data && data.imageurlThumb}
            width="50px"
            height="auto"
            alt=""
          />
          <TextBox
            className="product-name"
            defaultValue={data && data.title}
            readOnly={true}
          />
        </div>
      );
    } else {
      return (
        <div className="custom-item">
          <TextBox
            className="product-name"
            defaultValue={i18n.t("campaigncreate.dropdowncoupon")}
            readOnly={true}
          />
        </div>
      );
    }
  };

  Item = (data) => {
    if (data) {
      return (
        <div className="custom-item">
          <img
            src={
              data.imageurlThumb + "?" + (data.updated ? data.updated : null)
            }
            width="40px"
            height="auto"
            alt=""
          />
          <div className="product-name">{data.title}</div>
        </div>
      );
    } else {
      return (
        <div className="custom-item">
          <img src="" width="40px" height="auto" alt="" />
          <div className="product-name"></div>
        </div>
      );
    }
  };

  // Administra a mudança de valor no dropdownlist dos remetentes de email
  contactsSelected = (event) => {
    event.event.preventDefault();
    if (event.event !== undefined) {
      this.setState({
        contactListId: event.value,
        contactName: event.event.target.innerHTML,
      }, () => {
        if (event.value) {
          const findContactList = this.state.contactList.filter((objeto) => {
            return objeto._id.toString() === this.state.contactListId.toString();
          });

          if (findContactList.length > 0) {
            console.log('Objeto encontrado:', findContactList);
            console.log("findContactList.isCustomer", findContactList[0].isCustomer)
            this.setState({
              //   contactListId: findContactList._id,
              //   contactName: findContactList.listName,
              isCustomer: findContactList[0].isCustomer ?? false
            }, () => {
              console.log('this.state', this.state)
            });

          } else {
            console.log('Nenhum objeto encontrado com o ID');
          }
        }
      });
    }

    // event.event.preventDefault();

    // if (event.value) {
    //   const _id = event.value;
    //   console.log("_id", _id)
    //   const findContactList = this.state.contactList.filter((objeto) => {
    //     return objeto._id.toString() === _id.toString();
    //   });

    //   if (findContactList.length > 0) {
    //     console.log('Objeto encontrado:', findContactList);
    //     // this.setState({
    //     //   contactListId: findContactList._id,
    //     //   contactName: findContactList.listName,
    //     //   isCustomer: findContactList.isCustomer ?? false
    //     // });
    //   } else {
    //     console.log('Nenhum objeto encontrado com o ID');
    //   }
    // }

  };

  // / Dropdown quando selecionado um Cupom
  couponSelected = (event) => {
    if (event) {
      // Inserindo o Cupom Selecionado para Exibir
      let id = event.value;
      if (id) {
        getCoupons(id, false).then((res) => {
          console.log("Cupom", res);
          this.setState({
            showCoupon: res,
            messageId: id,
            messageName: res.title ? res.title : "Not Set",
          });
        });
      }
    }
  };

  // Administra a mudança de valor no dropdownlist relativo as mensagens HTML
  bodyHTMLSelected = (event) => {
    this.setState({ messageId: event.value });

    getHtmlBody(event.value).then((res) => {
      if (res) {
        this.setState({ messageBody: res.messageBody, messageName: res.title });
      }
    });
  };

  // Administra as mudanças de remetente de email
  readSenderEmailsSelected = (event) => {
    this.setState({
      readSenderEmailsDefaultValue: event.value,
      sendFrom: event.event.target.innerHTML,
    });
  };

  // Converte uma imagem em URL para Blog para poder fazer o download
  readImage = (image) => {
    return new Promise((resolve, reject) => {
      if (image) {
        jimp
          .read(image + "?auto=compress&cs=tinysrgb&h=350")
          .then((image) => {
            const result = image.getBase64Async(
              jimp.MIME_JPEG,
              (err, res) => { }
            );
            result.then((blogSrc) => {
              // Resultado da imagem em Blob
              resolve(blogSrc);
            });
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };

  // Copia o texto da URL de compartilhamento para o ClipBoard
  toCliBboard = () => {
    var copyTextarea = document.getElementById("urlText");
    if (copyTextarea) {
      copyTextarea.select(); // select the text area
      document.execCommand("copy"); // copy to clipboard
      this.showNotify(
        i18n.t("campaigncreate.notify.copytoclipboard"),
        "success"
      );
    }
  };

  // Criar o link e faz o download da imagem
  imageProcessDowload = () => {
    if (this.state.showCoupon) {
      this.readImage(this.state.showCoupon.image).then((imageBlog) => {
        if (imageBlog) {
          var link = document.createElement("a");
          var fileName = this.state.subject;
          fileName = fileName.replace(/ /g, "_");
          link.href = imageBlog;
          link.download = "Social_Share_Image_" + fileName + ".jpg";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    }
  };

  // Envio de Teste
  sendTest = () => {
    this.setState({ spinner: true });

    // Função para salvar campanha.
    let campaign;
    // Verificar Campos
    if (
      this.state.campaignName != "" &&
      this.state.campaignName != "0" &&
      this.state.campaignName !== undefined
    ) {
      if (
        this.state.subject != "" &&
        this.state.subject != "0" &&
        this.state.subject != "0" &&
        this.state.subject !== undefined
      ) {
        if (this.state.cardEmail === "active_") {
          // Campanha de E-mail Marketing
          if (
            this.state.messageId != "" &&
            this.state.messageId != "0" &&
            this.state.messageId !== undefined
          ) {
            if (
              this.state.sendFrom != "" &&
              this.state.sendFrom != "0" &&
              this.state.sendFrom !== undefined
            ) {
              if (
                this.state.emailTest != "" &&
                this.state.emailTest != "0" &&
                this.state.emailTest !== undefined
              ) {
                campaign = {
                  name: this.state.campaignName, // Nome da Campanha
                  messageId: this.state.messageId, // Id da Mensagem que está sendo salva no mysql, Body, na tabela MAIL_TEMPLATE
                  sendFrom: this.state.sendFrom, // --> No caso do e-mail marketing, é o email de remetente
                  replyTo: this.state.sendFrom, // --> No caso do e-mail marketing, é o e-mail para respostas
                  subject: this.state.subject, // --> No caso do e-mail marketing é o assunto do e-mail, gravado junto do Body HTML
                  sendTo: this.state.emailTest,
                };

                this.setState({ spinner: false });

                sendEmailTest(campaign)
                  .then((sendTestEmail) => {
                    this.showNotify(
                      i18n.t("campaigncreate.notify.campaigntestsented"),
                      "success"
                    );
                  })
                  .catch((error) => {
                    console.log("Error sending email teste", error);
                  });
              } else {
                this.showNotify(
                  i18n.t("campaigncreate.notify.requiredfieldemailtest"),
                  "error"
                );
              }
            } else {
              this.showNotify(
                i18n.t("campaigncreate.notify.requiredfieldsendertest"),
                "error"
              );
            }
          } else {
            this.showNotify(
              i18n.t("campaigncreate.notify.requiredfieldhtml"),
              "error"
            );
          }
        }
      } else {
        this.showNotify(
          i18n.t("campaigncreate.notify.requiredfieldemailsubject"),
          "error"
        );
      }
    } else {
      this.showNotify(
        i18n.t("campaigncreate.notify.requiredfieldcampaignname"),
        "error"
      );
    }
  };
  // Reseta todos os campos
  reset = () => {
    // Limpa a informação do Id da campanha que está sendo usado para exibir campanhas já criadas.
    this.campaignId = null;

    this.setState({
      messageId: "",
      subject: "",
      campaignName: "",
      contactListId: "",
      emailTest: "",
      readSenderEmailsDefaultValue: "",
      sendDate: "",
      messageBody: "",
      shareComplete: false,
      showCoupon: "",
    });

    // Limpa a informação do Id da campanha da URL e dá reload na página.
    let directUrl = window.location.href;
    if (directUrl.indexOf("?") > 0) {
      directUrl = directUrl.substring(0, directUrl.indexOf("?"));
      window.history.replaceState({}, document.title, directUrl);
    }
  };

  // Grava uma nova campanha
  Save = () => {
    // Ativar o Sppiner
    this.setState({ spinner: true });

    // Função para salvar campanha.
    let campaign;
    // Verificar Campos
    if (
      this.state.campaignName != "" &&
      this.state.campaignName != "0" &&
      this.state.campaignName !== undefined
    ) {
      if (
        this.state.subject != "" &&
        this.state.subject != "0" &&
        this.state.subject != "0" &&
        this.state.subject !== undefined
      ) {
        if (this.state.cardEmail === "active_") {
          // Campanha de E-mail Marketing
          if (
            this.state.messageId != "" &&
            this.state.messageId != "0" &&
            this.state.messageId !== undefined
          ) {
            if (
              this.state.contactListId != "" &&
              this.state.contactListId != "0" &&
              this.state.contactListId !== undefined
            ) {
              if (
                this.state.sendDate != "" &&
                this.state.sendDate != "0" &&
                this.state.sendDate !== undefined
              ) {
                if (
                  this.state.sendFrom != "" &&
                  this.state.sendFrom != "0" &&
                  this.state.sendFrom !== undefined
                ) {
                  let sendDate_ = new Date(this.state.sendDate);
                  sendDate_ =
                    sendDate_.getFullYear() +
                    "-" +
                    ("0" + (sendDate_.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + sendDate_.getDate()).slice(-2);

                  campaign = {
                    name: this.state.campaignName, // Nome da Campanha
                    isPublished: false,
                    sentType: 1, // 1 => E-mail
                    messageId: this.state.messageId, // Id da Mensagem que está sendo salva no mysql, Body, na tabela MAIL_TEMPLATE
                    messageName: this.state.messageName, // Nome da Mensagem que está sendo salva no mysql, Body, na tabela MAIL_TEMPLATE
                    contactName: this.state.contactName, // Nome da Lista de Contato, obtida em GET ContactList
                    contactListId: this.state.contactListId, // Id da Lista de Contato, obtida em GET ContactList
                    sendFrom: this.state.sendFrom, // --> No caso do e-mail marketing, é o email de remetente
                    replyTo: this.state.sendFrom, // --> No caso do e-mail marketing, é o e-mail para respostas
                    subject: this.state.subject, // --> No caso do e-mail marketing é o assunto do e-mail, gravado junto do Body HTML
                    status: 1, // this.state.ckbox_active.checked ? '1' : '0',
                    sendDate: sendDate_,
                  };
                } else {
                  this.showNotify(
                    i18n.t("campaigncreate.notify.requiredfieldsender"),
                    "error"
                  );
                }
              } else {
                this.showNotify(
                  i18n.t("campaigncreate.notify.requiredfielddate"),
                  "error"
                );
              }
            } else {
              this.showNotify(
                i18n.t("campaigncreate.notify.requiredfieldcontactlist"),
                "error"
              );
            }
          } else {
            this.showNotify(
              i18n.t("campaigncreate.notify.requiredfieldhtml"),
              "error"
            );
          }
        } else if (this.state.cardShare === "active_") {
          // Camapanha de Link Compartilhado
          if (
            this.state.messageId !== "" &&
            this.state.messageId !== "0" &&
            this.state.messageId !== undefined
          ) {
            if (
              this.state.contactListId !== "" &&
              this.state.contactListId !== "0" &&
              this.state.contactListId !== undefined
            ) {
              if (
                this.state.sendFrom !== "" &&
                this.state.sendFrom !== "0" &&
                this.state.sendFrom !== undefined
              ) {
                let sendDate_ = new Date();
                campaign = {
                  name: this.state.campaignName, // Nome da Campanha
                  isPublished: true,
                  sentType: 3, // 1 => E-mail 3 => Share
                  messageId: this.state.messageId, // Id da Mensagem que está sendo salva no mysql, Body, na tabela MAIL_TEMPLATE //=> Ou Id do Cupom Quando Share
                  messageName: this.state.messageName, // Nome da Mensagem que está sendo salva no mysql, Body, na tabela MAIL_TEMPLATE //=> ou Title do Cupom Quando Share
                  contactName: this.state.contactName, // Nome da Lista de Contato, obtida em GET ContactList
                  contactListId: this.state.contactListId, // Id da Lista de Contato, obtida em GET ContactList // Share
                  sendFrom: this.state.sendFrom, // this.state.senderId, // --> No caso do e-mail marketing, é o email de remetente // Share
                  subject: this.state.subject,
                  replyTo: this.state.sendFrom, // --> No caso do e-mail marketing, é o e-mail para respostas ||
                  status: 1, // this.state.ckbox_active.checked ? '1' : '0',
                  sendDate:
                    sendDate_.getFullYear() +
                    "-" +
                    ("0" + (sendDate_.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + sendDate_.getDate()).slice(-2),
                };
              } else {
                this.showNotify(
                  i18n.t("campaigncreate.notify.requiredfieldsendercode"),
                  "error"
                );
              }
            } else {
              this.showNotify(
                i18n.t("campaigncreate.notify.requiredfieldcontactlist"),
                "error"
              );
            }
          } else {
            this.showNotify(
              i18n.t("campaigncreate.notify.requiredfieldcupom"),
              "error"
            );
          }
        } else if (this.state.downloadCode === "active_") {
          // console.log("downloadCode");
        }

        proceedSaveCampaign(campaign)
          .then((res) => {
            let campaignIdIdKeep = res._id;
            // Ativa o envio imediato de Campanha de E-mail Marketing
            if (this.state.cardEmail === "active_") {
              // Publica o envio da Campanha de Email

              if (res) {
                this.setState({ spinner: false });
                this.showNotify(
                  i18n.t("campaigncreate.notify.campaigncreated"),
                  "success"
                );
                setTimeout(() => {
                  this.reset();
                  this.showNotify(
                    i18n.t("campaigncreate.notify.pagereloading"),
                    "success"
                  );
                  // Lê a campanha de Cupom assim que foi criada.
                  let newPage = `campaigncreate?campaignId=${campaignIdIdKeep}`;
                  window.location.replace(newPage);
                }, 2000);
              }
            } else if (this.state.cardShare === "active_") {
              this.setState({ spinner: false });
              this.showNotify(
                i18n.t("campaigncreate.notify.campaignsuccessfullycreated"),
                "success"
              );
              setTimeout(() => {
                this.reset();
                this.showNotify(
                  i18n.t("campaigncreate.notify.pagereloading"),
                  "success"
                );
                // Lê a campanha de Cupom assim que foi criada.
                let newPage = `campaigncreate?campaignId=${campaignIdIdKeep}`;
                window.location.replace(newPage);
              }, 2000);
            }
          })
          .catch((error) => {
            this.setState({ spinner: false });
            if (error.response) {
              let message = "";
              message = error.response.data;
              if (message === "You did reach the account limit") {
                message = i18n.t(
                  "campaigncreate.notify.campaignaccountlimitreached"
                );
                this.showNotify(message, "error");
              } else if (
                message === "You did reach the account limit to send emails"
              ) {
                message = i18n.t(
                  "campaigncreate.notify.emailaccountlimitreached"
                );
                this.showNotify(message, "error");
              } else if (message === "Account not approval to send emails.") {
                // Account not approval to send emails.
                message = i18n.t(
                  "campaigncreate.notify.emailaccountnotapproval"
                );
                this.showNotify(message, "error");
              } else if (
                message === '"name" length must be at least 5 characters long'
              ) {
                message = i18n.t("campaigncreate.notify.namelength");
                this.showNotify(message, "error");
              } else if (message === "Too many complaint not allowed.") {
                message = i18n.t("campaigncreate.notify.toomanycomplaint");
                this.showNotify(message, "error");
              } else {
                this.showNotify(message, "error");
              }
            }
          });
      } else {
        this.showNotify(
          i18n.t("campaigncreate.notify.requiredfieldemailsubject"),
          "error"
        );
      }
    } else {
      this.showNotify(
        i18n.t("campaigncreate.notify.requiredfieldcampaignname"),
        "error"
      );
    }
  };

  render() {
    let card;
    let contactData;
    let bodyHTMLData;
    let cuponsData;
    let emailSenderData;
    let htmlBodyEditor; // Form
    let showCoupon;
    let shareComplete;
    // Quando estiver mostrando uma campanha salva de compartilhamento
    //

    // Detecta se tem uma lista de contatos criada e caso não apresenta a mensagem para criar uma.
    if (this.state.contactList === 0 || this.state.contactList === null) {
      contactData = (
        <div className="dx-field">
          <div className="dx-field-label">
            {i18n.t("campaigncreate.labelcontactlist")}{" "}
          </div>
          <div className="dx-field-value">
            <span
              className="badge badge-danger"
              style={{
                paddingTop: "8px",
                fontWeight: "400",
                height: "25px",
                width: "100%",
              }}
            >
              {i18n.t("campaigncreate.message.contactlist")}
              <a
                href="\contacts"
                style={{
                  color: "#f9f8f8",
                  fontWeight: "bold",
                }}
              >
                {i18n.t("campaigncreate.message.contactlistclick")}{" "}
              </a>
            </span>
          </div>
        </div>
      );
    } else {
      contactData = (
        <div className="dx-field">
          <div className="dx-field-label">
            {i18n.t("campaigncreate.labelcontactlist")}{" "}
          </div>
          <div className="dx-field-value">
            <SelectBox
              dataSource={this.state.contactList ? this.state.contactList : ""}
              displayExpr="listName"
              valueExpr="_id"
              onValueChanged={this.contactsSelected}
              value={this.state.contactListId ? this.state.contactListId : ""}
              //contactListId
              readOnly={this.campaignId ? true : false}
            />
          </div>
        </div>
      );
    }

    // Detecta se tem uma lista de contatos criada e caso não apresenta a mensagem para criar uma.
    if (this.state.htmlBodyData === 0) {
      bodyHTMLData = (
        <div className="dx-field">
          <div className="dx-field-label">
            {i18n.t("campaigncreate.labelmessage")}{" "}
          </div>
          <div className="dx-field-value">
            <span
              className="badge badge-danger"
              style={{
                paddingTop: "8px",
                fontWeight: "400",
                height: "25px",
                width: "100%",
              }}
            >
              {i18n.t("campaigncreate.message.htmlmessage")}
              <a
                href="\createhtml"
                style={{
                  color: "#f9f8f8",
                  fontWeight: "bold",
                }}
              >
                {i18n.t("campaigncreate.message.htmlclick")}{" "}
              </a>
            </span>
          </div>
        </div>
      );
    } else {
      bodyHTMLData = (
        <div className="dx-field">
          <div className="dx-field-label">
            {i18n.t("campaigncreate.labelmessage")}{" "}
          </div>
          <div className="dx-field-value">
            <SelectBox
              dataSource={
                this.state.htmlBodyData ? this.state.htmlBodyData : null
              }
              displayExpr="title"
              valueExpr="_id"
              value={this.state.messageId ? this.state.messageId : null}
              onValueChanged={this.bodyHTMLSelected}
              readOnly={this.campaignId ? true : false}
            />
          </div>
        </div>
      );
    }

    if (this.state.cuponList === 0) {
      cuponsData = (
        <div className="dx-field">
          <div
            className="dx-field-label"
            title={i18n.t("campaigncreate.labelsharecoupon")}
          >
            {i18n.t("campaigncreate.labelsharecoupon")}{" "}
          </div>
          <div className="dx-field-value">
            <span
              className="badge badge-danger"
              style={{
                paddingTop: "8px",
                fontWeight: "400",
                height: "25px",
                width: "100%",
              }}
            >
              {i18n.t("campaigncreate.message.couponmessage")}
              <a
                href="\createhtml"
                style={{
                  color: "#f9f8f8",
                  fontWeight: "bold",
                }}
              >
                {i18n.t("campaigncreate.message.couponclick")}{" "}
              </a>
            </span>
          </div>
        </div>
      );
    } else if (this.state.cuponList === -1) {
      cuponsData = (
        <div className="dx-field">
          <div
            className="dx-field-label"
            title="Escolha o Cupom Para Compartilhar"
          >
            {i18n.t("campaigncreate.labelsharecoupon")}{" "}
          </div>
          <div className="dx-field-value">
            <span
              className="badge badge-danger"
              style={{
                paddingTop: "8px",
                fontWeight: "400",
                height: "25px",
                width: "100%",
              }}
            >
              {i18n.t("campaigncreate.message.couponreleasemessage")}
              <a
                href="\createhtml"
                style={{
                  color: "#f9f8f8",
                  fontWeight: "bold",
                }}
              >
                {i18n.t("campaigncreate.message.couponreleaseclick")}{" "}
              </a>
            </span>
          </div>
        </div>
      );
    } else if (this.state.cuponList) {
      cuponsData = (
        <div className="dx-field">
          <div className="dx-field-label">
            {i18n.t("campaigncreate.labelsharecoupon")}{" "}
          </div>
          <div className="dx-field-value">
            <SelectBox
              id="custom-templates"
              dataSource={this.state.cuponList ? this.state.cuponList : null}
              displayExpr="title"
              valueExpr="_id"
              fieldRender={this.state.cuponList ? this.Field : ""}
              itemRender={this.state.cuponList ? this.Item : ""}
              value={this.state.messageId ? this.state.messageId : null}
              onValueChanged={this.couponSelected}
              readOnly={this.campaignId ? true : false}
            />
          </div>
        </div>
      );
    }

    // Dropdown para mostrar os emails de remetente de mensagens
    if (this.state.readSenderEmails === 0) {
      emailSenderData = (
        <div className="dx-field">
          <div className="dx-field-label">
            {i18n.t("campaigncreate.labelsenderemail")}{" "}
          </div>
          <div className="dx-field-value">
            <span
              className="badge badge-danger"
              style={{
                paddingTop: "8px",
                fontWeight: "400",
                height: "25px",
                width: "100%",
              }}
            >
              {i18n.t("campaigncreate.message.senderemailmessage")}
              {/* https://app.tooweze.com/account?card=configurations */}
              <a
                href="\account?card=configurations"
                style={{
                  color: "#f9f8f8",
                  fontWeight: "bold",
                }}
              >
                {i18n.t("campaigncreate.message.senderclick")}{" "}
              </a>
            </span>
          </div>
        </div>
      );
    } else if (this.state.readSenderEmails === -1) {
      emailSenderData = (
        <div className="dx-field">
          <div className="dx-field-label">
            {i18n.t("campaigncreate.labelsenderemail")}{" "}
          </div>
          <div className="dx-field-value">
            <span
              className="badge badge-danger"
              style={{
                paddingTop: "8px",
                fontWeight: "400",
                height: "25px",
                width: "100%",
              }}
            >
              {i18n.t("campaigncreate.message.senderemailmessage")}
            </span>
          </div>
        </div>
      );
    } else {
      emailSenderData = (
        <>
          <div className="dx-field">
            <div className="dx-field-label">
              {i18n.t("campaigncreate.labelsenderemail")}{" "}
            </div>
            <div className="dx-field-value">
              <SelectBox
                dataSource={
                  this.state.readSenderEmails
                    ? this.state.readSenderEmails
                    : null
                }
                displayExpr="email"
                valueExpr="_id"
                onValueChanged={this.readSenderEmailsSelected}
                value={
                  this.state.readSenderEmailsDefaultValue
                    ? this.state.readSenderEmailsDefaultValue
                    : null
                }
                readOnly={this.campaignId ? true : false}
              />
            </div>
          </div>
        </>
      );
    }

    // Apresenta o Editor de HTML para mostrar a mensagem HTML escolhida
    if (this.state.cardEmail === "active_") {
      htmlBodyEditor = (
        <div id="viewerhtml" style={{ overflowY: "scroll" }}>
          <iframe
            id="iFrameMD"
            title="IFrame"
            src="data:text/html;charset=utf-8,"
            style={{ width: "100%", height: "100vh", border: "0px" }}
            srcDoc={"<html>" + this.state.messageBody + "</html>"}
          ></iframe>
        </div>
      );
    }
    // FORM PARA CAMPANHA DE EMAIL MARKETING

    // Apresenta o Cupom Selecionado no lado direito da tela ao invés do Editor de HTML
    if (this.state.showCoupon && this.state.cardShare === "active_") {
      showCoupon = (
        <div
          className="col-md-8 col-xl-8"
          style={{
            float: "left",
            padding: "3px",
            margin: "0 auto",
            paddingTop: "21px",
          }}
        >
          <div
            id={this.state.showCoupon._id}
            className="d-block shadow bg-white borderClass "
          >
            <div className="">
              <div
                className="input-group-append"
                style={{
                  height: "28px",
                  float: "right",
                }}
              ></div>
              <img
                id="img_"
                src={this.state.showCoupon.image}
                style={{
                  width: "100%",
                  height: "auto",
                }}
                alt=""
              />
            </div>
          </div>
        </div>
      );
    } else {
      showCoupon = "";
      shareComplete = "";
    }

    // Informações Extras quando está exibindo uma campanha de cupom.
    if (
      this.state.shareComplete === true &&
      this.state.cardShare === "active_"
    ) {
      shareComplete = (
        <div className="container">
          <div className="dropdown card-widgets"></div>
          <div className="form-group">
            <label
              htmlFor="urlText"
              stytle={{ fontWeight: "bolder" }}
              className="bg-primary text-white"
            >
              <strong style={{ padding: "5px" }}>
                {i18n.t("campaigncreate.labelsocialnetworklink")}{" "}
              </strong>
            </label>
            <div style={{ textAlign: "right" }}>
              <span
                id="warning"
                className="badge badge-primary"
                onClick={this.toCliBboard}
              >
                {i18n.t("campaigncreate.labelsocialnetworkclipboard")}{" "}
              </span>
            </div>
            <input
              id="urlText"
              type="text"
              className="form-control"
              readOnly={true}
              value={this.state.urlShare}
              onClick={this.toCliBboard}
            />
          </div>
          <div className="form-group mb-0">
            <label>
              <strong>
                {" "}
                {i18n.t("campaigncreate.labelsocialnetworkdownload")}{" "}
              </strong>
            </label>
          </div>
          <div
            className="form-group mb-0"
            onClick={this.imageProcessDowload}
            style={{ height: "50px" }}
          >
            <span id="btn_download" className="btn btn-danger btn-sm">
              {i18n.t("campaigncreate.labelsocialnetworkimagedownload")}{" "}
            </span>
          </div>
          <ul className="list-group list-group-flush" />
        </div>
      );
    } else {
      shareComplete = "";
    }

    // Define se mostra o form para Campanhas de Email Marketing ou de Link de Compartilhamento
    if (this.state.cardEmail === "active_") {
      // FORM PARA CAMPANHA DE EMAIL MARKETING

      showCoupon = "";
      shareComplete = "";

      card = (
        <div style={{ padding: "25px 3px 0px 3px" }}>
          <div className="dx-field">
            <div className="dx-field-label">
              {i18n.t("campaigncreate.labelcampaignname")}{" "}
            </div>
            <div className="dx-field-value">
              <TextBox
                value={this.state.campaignName}
                onValueChanged={(event) =>
                  this.handleValueChange(event, "campaignName")
                }
                showClearButton={true}
                readOnly={this.campaignId ? true : false}
              />
            </div>
          </div>

          <div className="dx-field">
            <div className="dx-field-label">
              {i18n.t("campaigncreate.labelemailsubject")}{" "}
            </div>
            <div className="dx-field-value">
              <TextBox
                value={this.state.subject}
                onValueChanged={(event) =>
                  this.handleValueChange(event, "subject")
                }
                showClearButton={true}
                readOnly={this.campaignId ? true : false}
              />
            </div>
          </div>

          {/* listBox com as mensagens HTML */}
          {bodyHTMLData}

          {/* listBox com as lista de envios */}
          {contactData}

          <div className="dx-field">
            <div className="dx-field-label">
              {i18n.t("campaigncreate.labelsenddate")}{" "}
            </div>
            <DateBox
              className="dx-field-value"
              type="date"
              readOnly={this.campaignId ? true : false}
              min={new Date()}
              value={
                this.state.sendDate
                  ? this.state.sendDate == "0"
                    ? null
                    : this.state.sendDate
                  : null
              }
              showClearButton={true}
              placeholder="Definir Data"
              onValueChanged={(event) =>
                this.handleValueChange(event, "sendDate")
              }
            />
          </div>

          {/* listBox com os emails remetentes de mensagens */}
          {emailSenderData}

          <div className="dx-field">
            <div className="dx-field-label">
              {i18n.t("campaigncreate.labelemailsendtest")}{" "}
            </div>
            <div className="dx-field-value">
              <TextBox
                value={this.state.emailTest}
                placeholder={i18n.t(
                  "campaigncreate.labelemailsendtestplaceholder"
                )}
                onValueChanged={(event) =>
                  this.handleValueChange(event, "emailTest")
                }
                showClearButton={true}
                readOnly={this.campaignId ? true : false}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.cardShare === "active_") {
      // FORM PARA LINK DE COMPARTILHAMENTO

      htmlBodyEditor = "";

      card = (
        <div style={{ padding: "25px 3px 0px 3px" }}>
          <div className="dx-field">
            <div className="dx-field-label">
              {i18n.t("campaigncreate.labelcampaignname")}{" "}
            </div>
            <div className="dx-field-value">
              <TextBox
                value={this.state.campaignName}
                onValueChanged={(event) =>
                  this.handleValueChange(event, "campaignName")
                }
                showClearButton={true}
                readOnly={this.campaignId ? true : false}
              />
            </div>
          </div>
          <div className="dx-field">
            <div
              className="dx-field-label"
              title={i18n.t("campaigncreate.labelcouponemailsubject")}
            >
              {i18n.t("campaigncreate.labelcouponemailsubject")}{" "}
            </div>
            <div className="dx-field-value">
              <TextBox
                value={this.state.subject}
                onValueChanged={(event) =>
                  this.handleValueChange(event, "subject")
                }
                showClearButton={true}
                readOnly={this.campaignId ? true : false}
              />
            </div>
          </div>
          {/* listBox com os cupons */}
          {cuponsData}
          {/* listBox com as lista de envios */}
          {contactData}
          {/* listBox com os emails remetentes de mensagens */}
          {emailSenderData}{" "}
        </div>
      );
    } else if (this.state.downloadCode === "active_") {
      // FORM PARA DOWNLOAD DE CÓDIGO DE CUPOM

      htmlBodyEditor = "";
      shareComplete = "";
      showCoupon = "";

      card = (
        <div style={{ padding: "25px 3px 0px 3px" }}>
          <div className="dx-field">
            <div className="dx-field-label">
              {i18n.t("campaigncreate.labelcampaignname")}{" "}
            </div>
            <div className="dx-field-value">
              <TextBox
                value={this.state.campaignName}
                onValueChanged={(event) =>
                  this.handleValueChange(event, "campaignName")
                }
                showClearButton={true}
                readOnly={this.campaignId ? true : false}
              />
            </div>
          </div>
          {/* listBox com os cupons */}
          {cuponsData}{" "}
        </div>
      );
    } else if (this.state.cardPushNotification === "active_") {
      card = (
        <div style={{ padding: "25px 3px 0px 3px" }}>
          <div className="dx-field">
            <div className="dx-field-label">
              Título da Notificação
            </div>
            <div className="dx-field-value">
              <TextBox
                value={this.state.notification.title}
                onValueChanged={(event) =>
                  this.setState({
                    notification: {
                      title: event.value
                    }
                  })
                }
                showClearButton={true}
              />
            </div>
          </div>

          <div className="dx-field">
            <div className="dx-field-label">
              Conteúdo da Notificação
            </div>
            <div className="dx-field-value">
              <TextBox
                value={this.state.notification.content}
                onValueChanged={(event) =>
                  this.setState({
                    notification: {
                      content: event.value
                    }
                  })
                }
                showClearButton={true}
              />
            </div>
          </div>
          <div className="dx-field">
            <div className="dx-field-label">
              Enviar em:
            </div>
            <DateBox
              className="dx-field-value"
              type="datetime"
              min={new Date()}
              value={
                this.state.notification.dateToSend
              }
              showClearButton={true}
              placeholder="Definir Data"
              onValueChanged={(event) =>
                this.setState({
                  notification: { dateToSend: event.value }
                })
              }
            />
          </div>
        </div>
      );
    }

    if (this.state.spinner == true) {
      return <Spinner />;
    } else {
      return (
        <div
          className="container col-12"
        // style={
        //     { marginLeft: "30px" }
        // }
        >
          <div className="row mt-3">
            <div className="col-6">
              <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={position}
                visible={this.state.loadPanelVisible}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={true}
              />

              <div id="allCard" className="card" style={{ height: "80vh" }}>
                <div className="card-body" style={{ overflow: "auto" }}>
                  <div
                    className="container row"
                    style={{
                      flexDirection: "row",
                      padding: "0px",
                      margin: "0 auto",
                    }}
                  >
                    <div
                      className="col-4"
                      style={{
                        paddingLeft: "1px",
                        paddingRight: "1px",
                      }}
                    >
                      <div
                        id="cardEmail"
                        className={"card " + this.state.cardEmail}
                        style={{ textAlign: "center" }}
                        onClick={(event) => this.changeCampaignType(event)}
                      >
                        <div className="card-body">
                          {/* <!-- SVG ICON EMAIL MARKETING --> */}
                          <svg
                            id="Capa_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                            style={{
                              enableBackground: "new 0 0 512 512",
                              width: "100%",
                            }}
                            width="100"
                            height="100"
                          >
                            <g>
                              <path
                                style={{ fill: "#50536E" }}
                                d="M465.5,285.36v206.036c0,6.542-3.045,12.372-7.797,16.143c-3.507,2.792-7.951,4.461-12.775,4.461   H54.071c-4.824,0-9.267-1.669-12.775-4.461c-4.752-3.77-7.797-9.601-7.797-16.143V285.36c0-6.542,3.045-12.372,7.797-16.143   c3.507-2.792,7.951-4.461,12.775-4.461h390.857c4.824,0,9.267,1.669,12.775,4.461C462.455,272.988,465.5,278.818,465.5,285.36z"
                              />
                              <path
                                style={{ fill: "#D8D5ED" }}
                                d="M407.754,455.34H91.246c-2.209,0-4-1.791-4-4V44.818c0-2.209,1.791-4,4-4h316.507   c2.209,0,4,1.791,4,4V451.34C411.754,453.549,409.963,455.34,407.754,455.34z"
                              />
                              <path
                                style={{ fill: "#BEBADE" }}
                                d="M380.848,40.818V455.34h26.905c2.209,0,4-1.791,4-4V44.818c0-2.209-1.791-4-4-4H380.848z"
                              />
                              <path
                                style={{ fill: "#FFBD54" }}
                                d="M465.5,285.36v206.036c0,6.542-3.045,12.372-7.797,16.143c-3.507,2.792-7.951,4.461-12.775,4.461   H54.071c-4.824,0-9.267-1.669-12.775-4.461L249.5,388.378l208.203-119.161C462.455,272.988,465.5,278.818,465.5,285.36z"
                              />
                              <path
                                style={{ fill: "#FFD659" }}
                                d="M457.703,507.539c-3.507,2.792-7.951,4.461-12.775,4.461H54.071c-4.824,0-9.267-1.669-12.775-4.461   c-4.752-3.77-7.797-9.601-7.797-16.143V285.36c0-6.542,3.045-12.372,7.797-16.143L249.5,388.378L457.703,507.539z"
                              />
                              <g>
                                <path
                                  style={{ fill: "#50536E" }}
                                  d="M144.421,133.128h222.52c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5h-222.52    c-4.142,0-7.5,3.357-7.5,7.5S140.279,133.128,144.421,133.128z"
                                />
                                <path
                                  style={{ fill: "#50536E" }}
                                  d="M366.941,155.214h-222.52c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h222.52    c4.143,0,7.5-3.357,7.5-7.5S371.083,155.214,366.941,155.214z"
                                />
                                <path
                                  style={{ fill: "#50536E" }}
                                  d="M269.76,192.301H144.421c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5H269.76    c4.143,0,7.5-3.357,7.5-7.5S273.903,192.301,269.76,192.301z"
                                />
                              </g>
                              <g>
                                <path
                                  style={{ fill: "#FFD659" }}
                                  d="M365.957,212.673l-114.754,58.734l-56.002-56.002l58.734-114.754c0.711-1.39,2.57-1.683,3.674-0.58    l108.927,108.927C367.64,210.103,367.347,211.962,365.957,212.673z"
                                />
                                <path
                                  style={{ fill: "#FF2C5C" }}
                                  d="M206.813,304.96l-2.914,2.914c-12.472,12.472-32.692,12.472-45.164,0l0,0    c-12.472-12.472-12.472-32.692,0-45.164l2.914-2.914L206.813,304.96z"
                                />
                                <path
                                  style={{ fill: "#50536E" }}
                                  d="M278.312,336.901L278.312,336.901c-6.035,6.035-15.819,6.035-21.853,0l-63.375-63.375    l21.853-21.853l63.375,63.375C284.346,321.082,284.346,330.866,278.312,336.901z"
                                />
                                <path
                                  style={{ fill: "#FFBD54" }}
                                  d="M253.936,100.652l-13.357,26.096l99.282,99.282l26.096-13.357c1.39-0.711,1.683-2.57,0.58-3.674    L257.61,100.072C256.507,98.969,254.647,99.262,253.936,100.652z"
                                />
                                <path
                                  style={{ fill: "#4FC6F5" }}
                                  d="M253.416,274.383l-37.622,37.622c-1.68,1.68-4.405,1.68-6.085,0L154.604,256.9    c-1.68-1.68-1.68-4.405,0-6.085l37.622-37.622c1.68-1.68,4.405-1.68,6.085,0l55.104,55.104    C255.096,269.978,255.096,272.703,253.416,274.383z"
                                />
                                <path
                                  style={{ fill: "#3BB3E3" }}
                                  d="M192.226,213.193l-21.853,21.853c1.68-1.68,4.405-1.68,6.085,0l55.104,55.104    c1.68,1.68,1.68,4.405,0,6.085l21.853-21.853c1.68-1.68,1.68-4.405,0-6.085l-55.104-55.104    C196.631,211.513,193.906,211.513,192.226,213.193z"
                                />
                              </g>
                              <path
                                style={{ fill: "#FF2C5C" }}
                                d="M478.5,72c0,6.188-6.476,11.443-8.001,17.151c-1.578,5.906,1.356,13.686-1.636,18.856   c-3.035,5.245-11.268,6.575-15.524,10.832c-4.257,4.257-5.587,12.49-10.832,15.524c-5.17,2.991-12.95,0.057-18.856,1.636   C417.943,137.524,412.689,144,406.5,144c-6.188,0-11.443-6.476-17.151-8.001c-5.906-1.578-13.686,1.356-18.856-1.636   c-5.245-3.035-6.575-11.268-10.832-15.524c-4.257-4.257-12.49-5.587-15.524-10.832c-2.991-5.17-0.057-12.95-1.636-18.856   C340.976,83.443,334.5,78.189,334.5,72c0-6.188,6.476-11.443,8.001-17.151c1.578-5.906-1.356-13.686,1.636-18.856   c3.035-5.245,11.268-6.575,15.524-10.832c4.257-4.257,5.587-12.49,10.832-15.524c5.17-2.991,12.95-0.057,18.856-1.636   C395.057,6.476,400.311,0,406.5,0c6.188,0,11.443,6.476,17.151,8.001c5.906,1.578,13.686-1.356,18.856,1.636   c5.245,3.035,6.575,11.268,10.832,15.524c4.257,4.257,12.49,5.587,15.524,10.832c2.991,5.17,0.057,12.95,1.636,18.856   C472.024,60.557,478.5,65.811,478.5,72z"
                              />
                              <g>
                                <circle
                                  style={{ fill: "#FFD659" }}
                                  cx="383.462"
                                  cy="49.075"
                                  r="7.726"
                                />
                                <circle
                                  style={{ fill: "#FFD659" }}
                                  cx="429.537"
                                  cy="95.15"
                                  r="7.726"
                                />
                                <path
                                  style={{ fill: "#FFD659" }}
                                  d="M435.2,43.412c-2.93-2.928-7.678-2.928-10.607,0L377.8,90.206c-2.929,2.93-2.929,7.678,0,10.607    c1.465,1.464,3.385,2.196,5.304,2.196s3.839-0.732,5.304-2.196L435.2,54.02C438.129,51.09,438.129,46.342,435.2,43.412z"
                                />
                              </g>
                            </g>
                          </svg>
                          {/* <!-- SVG ICON EMAIL MARKETING --> */}{" "}
                        </div>
                        <label>
                          {" "}
                          {i18n.t("campaigncreate.labelcardemailmkt")}{" "}
                        </label>
                      </div>
                    </div>

                    <div
                      className="col-4"
                      style={{
                        paddingLeft: "1px",
                        paddingRight: "1px",
                      }}
                    >
                      <div
                        id="cardShare"
                        className={"card " + this.state.cardShare}
                        style={{
                          marginLeft: "0px",
                          textAlign: "center",
                        }}
                        onClick={(event) => this.changeCampaignType(event)}
                      >
                        <div className="card-body">
                          {/* <!-- SVG ICON COMPARTILHAMENTO --> */}
                          <svg
                            height="74pt"
                            viewBox="0 0 513.08509 513"
                            width="80pt"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              enableBackground: "new 0 0 512 512",
                              width: "100%",
                            }}
                          >
                            <path
                              d="m136.542969 8.042969h240c8.835937 0 16 7.164062 16 16v464c0 8.835937-7.164063 16-16 16h-240c-8.835938 0-16-7.164063-16-16v-464c0-8.835938 7.164062-16 16-16zm0 0"
                              fill="#eee"
                            />
                            <path
                              d="m120.542969 72.042969h272v336h-272zm0 0"
                              fill="#dce775"
                            />
                            <path
                              d="m272.542969 456.042969c0 8.835937-7.164063 16-16 16-8.835938 0-16-7.164063-16-16 0-8.835938 7.164062-16 16-16 8.835937 0 16 7.164062 16 16zm0 0"
                              fill="#fff"
                            />
                            <path
                              d="m504.542969 200.042969h-87.992188l-48.007812 32v-32h-24v-96h160zm0 0"
                              fill="#ff8a65"
                            />
                            <path
                              d="m504.542969 400.042969c0 48.601562-39.398438 88-88 88-48.601563 0-88-39.398438-88-88 0-48.601563 39.398437-88 88-88 48.601562 0 88 39.398437 88 88zm0 0"
                              fill="#ff8a65"
                            />
                            <path
                              d="m292.664062 219.921875-128 42.671875 53.328126 32 32 53.328125zm0 0"
                              fill="#ff8a65"
                            />
                            <path
                              d="m184.542969 112.042969c0 48.601562-39.398438 88-88 88-48.601563 0-88-39.398438-88-88 0-48.601563 39.398437-88 88-88 48.601562 0 88 39.398437 88 88zm0 0"
                              fill="#ff8a65"
                            />
                            <g fill="#fff">
                              <path d="m384.542969 128.042969h80v16h-80zm0 0" />
                              <path d="m384.542969 160.042969h48v16h-48zm0 0" />
                              <path d="m448.542969 464.042969h-32c-17.132813-.019531-33.542969-6.894531-45.566407-19.097657-12.027343-12.199218-18.664062-28.710937-18.433593-45.839843.734375-34.511719 28.527343-62.316407 63.039062-63.0625h.960938c35.332031.035156 63.964843 28.667969 64 64v8c0 13.253906-10.746094 24-24 24-13.253907 0-24-10.746094-24-24v-8h16v8c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-8c.027343-12.859375-5.140625-25.183594-14.335938-34.175781-9.082031-9.132813-21.515625-14.132813-34.390625-13.824219-25.753906.859375-46.429687 21.542969-47.273437 47.296875-.171875 12.84375 4.808593 25.226562 13.828125 34.375 9.015625 9.152344 21.324218 14.3125 34.171875 14.328125h32zm0 0" />
                              <path d="m416.542969 432.042969c-17.671875 0-32-14.328125-32-32s14.328125-32 32-32 32 14.328125 32 32-14.328125 32-32 32zm0-48c-8.835938 0-16 7.164062-16 16 0 8.835937 7.164062 16 16 16 8.835937 0 16-7.164063 16-16 0-8.835938-7.164063-16-16-16zm0 0" />
                              <path d="m50.882812 146.386719 80-80 11.3125 11.316406-79.996093 79.996094zm0 0" />
                              <path d="m64.542969 80.042969h16v16h-16zm0 0" />
                              <path d="m112.542969 128.042969h16v16h-16zm0 0" />
                              <path d="m152.945312 348.328125 33.941407-33.941406 11.3125 11.3125-33.941407 33.941406zm0 0" />
                              <path d="m151.574219 301.695312 11.3125-11.3125 11.316406 11.3125-11.316406 11.3125zm0 0" />
                              <path d="m199.570312 349.695312 11.3125-11.3125 11.3125 11.3125-11.3125 11.3125zm0 0" />
                            </g>
                            <path d="m304.542969 32.042969h16v16h-16zm0 0" />
                            <path d="m192.542969 32.042969h16v16h-16zm0 0" />
                            <path d="m224.542969 32.042969h64v16h-64zm0 0" />
                            <path d="m256.542969 432.042969c-13.253907 0-24 10.746093-24 24 0 13.253906 10.746093 24 24 24 13.253906 0 24-10.746094 24-24 0-13.253907-10.746094-24-24-24zm0 32c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8 8 3.582031 8 8-3.582031 8-8 8zm0 0" />
                            <path d="m418.941406 208.042969h93.601563v-112h-112v-72c0-13.253907-10.746094-24.0000002-24-24.0000002h-240c-10.75.046875-20.15625 7.2382812-23.023438 17.5976562-37.765625-7.007813-76.074219 9.167969-97.386719 41.121094s-21.527343 73.535156-.542968 105.710937c20.980468 32.171875 59.117187 48.742188 96.953125 42.121094v281.449219c0 13.253906 10.746093 24 24 24h240c10.75-.046875 20.15625-7.238281 23.023437-17.601563 37.765625 7.011719 76.070313-9.164062 97.382813-41.117187 21.3125-31.957031 21.527343-73.539063.546875-105.710938-20.980469-32.171875-59.117188-48.742187-96.953125-42.121093v-85.160157zm-282.398437-192h240c4.417969 0 8 3.582031 8 8v40h-204.992188c-11.363281-19.582031-29.261719-34.53125-50.550781-42.234375.945312-3.386719 4.023438-5.738282 7.542969-5.765625zm224 192v38.945312l24-16v78.652344c-38.304688 13.570313-63.929688 49.765625-64 90.402344h-192v-197.601563c24.035156-8.402344 43.722656-26.054687 54.6875-49.039062 10.964843-22.980469 12.300781-49.390625 3.710937-73.359375h197.601563v16h-48v112zm-264-16c-44.183594 0-80-35.816407-80-80 0-44.183594 35.816406-80 80-80 44.183593 0 80 35.816406 80 80-.050781 44.164062-35.835938 79.949219-80 80zm280 304h-240c-4.417969 0-8-3.582031-8-8v-72h193.449219c5.828124 34.117187 29.542968 62.46875 62.09375 74.230469-.949219 3.390624-4.023438 5.742187-7.542969 5.769531zm40-176c44.183593 0 80 35.816406 80 80 0 44.183593-35.816407 80-80 80-44.183594 0-80-35.816407-80-80 .046875-44.164063 35.835937-79.953125 80-80zm-40-102.945313v-25.054687h-24v-80h144v80h-82.402344zm0 0" />
                            <path d="m145.628906 260.507812 66.539063 39.917969 39.910156 66.527344 53.265625-159.710937zm38.066406 4.175782 79.351563-26.457032-46.289063 46.296876zm64.214844 64.207031-19.839844-33.054687 46.289063-46.289063zm0 0" />
                            <path d="m290.890625 317.703125 11.3125-11.3125 24.003906 24-11.3125 11.3125zm0 0" />
                            <path d="m170.890625 197.703125 11.3125-11.3125 24.003906 24-11.316406 11.3125zm0 0" />
                            <path d="m.542969 336.042969h16v176h-16zm0 0" />
                            <path d="m64.542969 400.042969h16v112h-16zm0 0" />
                            <path d="m32.542969 368.042969h16v144h-16zm0 0" />
                          </svg>
                          {/* <!-- SVG ICON COMPARTILHAMENTO --> */}{" "}
                        </div>
                        <label>
                          {" "}
                          {i18n.t("campaigncreate.labelsocialshare")}{" "}
                        </label>
                      </div>
                    </div>

                    <div
                      className="col-4"
                      style={{
                        paddingLeft: "1px",
                        paddingRight: "1px",
                      }}
                    >
                      <div
                        id="cardPushNotification"
                        className={"card " + this.state.cardPushNotification}
                        style={{
                          marginLeft: "0px",
                          textAlign: "center",
                        }}
                        onClick={(event) => this.changeCampaignType(event)}
                      >
                        <div className="card-body">
                          <svg
                            height="74pt"
                            viewBox="0 0 513.08509 513"
                            width="80pt"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              enableBackground: "new 0 0 512 512",
                              width: "100%",
                            }}
                          >
                            <path
                              d="m136.542969 8.042969h240c8.835937 0 16 7.164062 16 16v464c0 8.835937-7.164063 16-16 16h-240c-8.835938 0-16-7.164063-16-16v-464c0-8.835938 7.164062-16 16-16zm0 0"
                              fill="#eee"
                            />
                            <path
                              d="m120.542969 72.042969h272v336h-272zm0 0"
                              fill="#dce775"
                            />
                            <path
                              d="m272.542969 456.042969c0 8.835937-7.164063 16-16 16-8.835938 0-16-7.164063-16-16 0-8.835938 7.164062-16 16-16 8.835937 0 16 7.164062 16 16zm0 0"
                              fill="#fff"
                            />
                            <path
                              d="m504.542969 200.042969h-87.992188l-48.007812 32v-32h-24v-96h160zm0 0"
                              fill="#ff8a65"
                            />
                            <path
                              d="m504.542969 400.042969c0 48.601562-39.398438 88-88 88-48.601563 0-88-39.398438-88-88 0-48.601563 39.398437-88 88-88 48.601562 0 88 39.398437 88 88zm0 0"
                              fill="#ff8a65"
                            />
                            <path
                              d="m292.664062 219.921875-128 42.671875 53.328126 32 32 53.328125zm0 0"
                              fill="#ff8a65"
                            />
                            <path
                              d="m184.542969 112.042969c0 48.601562-39.398438 88-88 88-48.601563 0-88-39.398438-88-88 0-48.601563 39.398437-88 88-88 48.601562 0 88 39.398437 88 88zm0 0"
                              fill="#ff8a65"
                            />
                            <g fill="#fff">
                              <path d="m384.542969 128.042969h80v16h-80zm0 0" />
                              <path d="m384.542969 160.042969h48v16h-48zm0 0" />
                              <path d="m448.542969 464.042969h-32c-17.132813-.019531-33.542969-6.894531-45.566407-19.097657-12.027343-12.199218-18.664062-28.710937-18.433593-45.839843.734375-34.511719 28.527343-62.316407 63.039062-63.0625h.960938c35.332031.035156 63.964843 28.667969 64 64v8c0 13.253906-10.746094 24-24 24-13.253907 0-24-10.746094-24-24v-8h16v8c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-8c.027343-12.859375-5.140625-25.183594-14.335938-34.175781-9.082031-9.132813-21.515625-14.132813-34.390625-13.824219-25.753906.859375-46.429687 21.542969-47.273437 47.296875-.171875 12.84375 4.808593 25.226562 13.828125 34.375 9.015625 9.152344 21.324218 14.3125 34.171875 14.328125h32zm0 0" />
                              <path d="m416.542969 432.042969c-17.671875 0-32-14.328125-32-32s14.328125-32 32-32 32 14.328125 32 32-14.328125 32-32 32zm0-48c-8.835938 0-16 7.164062-16 16 0 8.835937 7.164062 16 16 16 8.835937 0 16-7.164063 16-16 0-8.835938-7.164063-16-16-16zm0 0" />
                              <path d="m50.882812 146.386719 80-80 11.3125 11.316406-79.996093 79.996094zm0 0" />
                              <path d="m64.542969 80.042969h16v16h-16zm0 0" />
                              <path d="m112.542969 128.042969h16v16h-16zm0 0" />
                              <path d="m152.945312 348.328125 33.941407-33.941406 11.3125 11.3125-33.941407 33.941406zm0 0" />
                              <path d="m151.574219 301.695312 11.3125-11.3125 11.316406 11.3125-11.316406 11.3125zm0 0" />
                              <path d="m199.570312 349.695312 11.3125-11.3125 11.3125 11.3125-11.3125 11.3125zm0 0" />
                            </g>
                            <path d="m304.542969 32.042969h16v16h-16zm0 0" />
                            <path d="m192.542969 32.042969h16v16h-16zm0 0" />
                            <path d="m224.542969 32.042969h64v16h-64zm0 0" />
                            <path d="m256.542969 432.042969c-13.253907 0-24 10.746093-24 24 0 13.253906 10.746093 24 24 24 13.253906 0 24-10.746094 24-24 0-13.253907-10.746094-24-24-24zm0 32c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8 8 3.582031 8 8-3.582031 8-8 8zm0 0" />
                            <path d="m418.941406 208.042969h93.601563v-112h-112v-72c0-13.253907-10.746094-24.0000002-24-24.0000002h-240c-10.75.046875-20.15625 7.2382812-23.023438 17.5976562-37.765625-7.007813-76.074219 9.167969-97.386719 41.121094s-21.527343 73.535156-.542968 105.710937c20.980468 32.171875 59.117187 48.742188 96.953125 42.121094v281.449219c0 13.253906 10.746093 24 24 24h240c10.75-.046875 20.15625-7.238281 23.023437-17.601563 37.765625 7.011719 76.070313-9.164062 97.382813-41.117187 21.3125-31.957031 21.527343-73.539063.546875-105.710938-20.980469-32.171875-59.117188-48.742187-96.953125-42.121093v-85.160157zm-282.398437-192h240c4.417969 0 8 3.582031 8 8v40h-204.992188c-11.363281-19.582031-29.261719-34.53125-50.550781-42.234375.945312-3.386719 4.023438-5.738282 7.542969-5.765625zm224 192v38.945312l24-16v78.652344c-38.304688 13.570313-63.929688 49.765625-64 90.402344h-192v-197.601563c24.035156-8.402344 43.722656-26.054687 54.6875-49.039062 10.964843-22.980469 12.300781-49.390625 3.710937-73.359375h197.601563v16h-48v112zm-264-16c-44.183594 0-80-35.816407-80-80 0-44.183594 35.816406-80 80-80 44.183593 0 80 35.816406 80 80-.050781 44.164062-35.835938 79.949219-80 80zm280 304h-240c-4.417969 0-8-3.582031-8-8v-72h193.449219c5.828124 34.117187 29.542968 62.46875 62.09375 74.230469-.949219 3.390624-4.023438 5.742187-7.542969 5.769531zm40-176c44.183593 0 80 35.816406 80 80 0 44.183593-35.816407 80-80 80-44.183594 0-80-35.816407-80-80 .046875-44.164063 35.835937-79.953125 80-80zm-40-102.945313v-25.054687h-24v-80h144v80h-82.402344zm0 0" />
                            <path d="m145.628906 260.507812 66.539063 39.917969 39.910156 66.527344 53.265625-159.710937zm38.066406 4.175782 79.351563-26.457032-46.289063 46.296876zm64.214844 64.207031-19.839844-33.054687 46.289063-46.289063zm0 0" />
                            <path d="m290.890625 317.703125 11.3125-11.3125 24.003906 24-11.3125 11.3125zm0 0" />
                            <path d="m170.890625 197.703125 11.3125-11.3125 24.003906 24-11.316406 11.3125zm0 0" />
                            <path d="m.542969 336.042969h16v176h-16zm0 0" />
                            <path d="m64.542969 400.042969h16v112h-16zm0 0" />
                            <path d="m32.542969 368.042969h16v144h-16zm0 0" />
                          </svg>
                        </div>
                        <label>
                          {" "}
                          {i18n.t("campaigncreate.labelpushnotification")}{" "}
                        </label>
                      </div>
                    </div>

                    {/* <div
                      className="col-4"
                      style={{
                        paddingLeft: "1px",
                        paddingRight: "1px",
                      }}
                    >
                      <div
                        id="downloadCode"
                        className={"card " + this.state.downloadCode}
                        style={{
                          marginLeft: "0px",
                          textAlign: "center",
                          background: "#dedede",
                          opacity: ".5",
                          height: "100%",
                        }}
                      >
                        <div className="card-body"> */}
                    {/* <!-- SVG ICON DOWNLOAD DE CÓDIGO --> */}
                    {/* <svg height="74pt" viewBox="-30 0 640 640" width="80pt" xmlns="http://www.w3.org/2000/svg"
                                                        style={
                                                            {
                                                                enableBackground: "new 0 0 512 512",
                                                                width: "100%"
                                                            }
                                                        }>
                                                        <path d="m300.585938 101.449219-15.308594-101.449219h-224.601563c-33.820312 0-60.675781 27.554688-60.675781 61.371094v424.71875c0 33.816406 26.855469 60.8125 60.675781 60.8125h213.054688l153.492187-232.402344v-165.734375zm0 0" fill="#00acea" />
                                                        <path d="m285.277344.140625 141.527344 148.625h-94.210938c-26.164062 0-47.316406-21.152344-47.316406-47.316406zm0 0" fill="#00efd1" />
                                                        <g fill="#083863">
                                                            <path d="m279.992188 318.675781h-181.464844c-7.652344 0-13.917969 6.265625-13.917969 13.917969 0 7.65625 6.265625 13.914062 13.917969 13.914062h181.324218c7.652344 0 13.917969-6.257812 13.917969-13.914062 0-7.652344-6.121093-13.917969-13.777343-13.917969zm0 0" />
                                                            <path d="m98.527344 276.929688h140.96875c7.652344 0 13.917968-6.261719 13.917968-13.917969 0-7.652344-6.265624-13.914063-13.917968-13.914063h-140.96875c-7.652344 0-13.917969 6.261719-13.917969 13.914063 0 7.65625 6.265625 13.917969 13.917969 13.917969zm0 0" />
                                                            <path d="m234.066406 389.648438h-135.539062c-7.652344 0-13.917969 6.265624-13.917969 13.914062 0 7.65625 6.265625 13.917969 13.917969 13.917969h135.539062c7.65625 0 13.917969-6.261719 13.917969-13.917969 0-7.648438-6.261719-13.914062-13.917969-13.914062zm0 0" />
                                                        </g>
                                                        <path d="m426.804688 286.8125c86.28125 12.101562 152.65625 86.136719 152.65625 175.753906 0 97.96875-79.457032 177.433594-177.425782 177.433594-67.351562 0-126.078125-37.574219-156-92.960938-13.636718-25.1875-21.429687-53.992187-21.429687-84.609374 0-97.96875 79.460937-177.429688 177.429687-177.429688 8.351563.140625 16.558594.699219 24.769532 1.8125zm0 0" fill="#fedb41" />
                                                        <path d="m473.285156 459.648438-57.195312 61.644531v-151.960938c0-7.652343-6.261719-13.917969-13.917969-13.917969-7.652344 0-13.914063 6.265626-13.914063 13.917969v151.960938l-57.613281-61.644531c-5.289062-5.566407-14.195312-5.988282-19.761719-.699219-5.566406 5.289062-5.980468 14.058593-.699218 19.625l81.414062 87.527343c2.640625 2.785157 6.261719 4.453126 10.15625 4.453126 3.894532 0 7.511719-1.667969 10.160156-4.453126l81.40625-87.527343c5.289063-5.566407 5.007813-14.472657-.558593-19.625-5.558594-5.289063-14.1875-4.867188-19.476563.699219zm0 0" fill="#083863" />
                                                    </svg> */}
                    {/* <!-- SVG ICON DOWNLOAD DE CÓDIGO --> */}
                    {/* </div> */}
                    {/* <label> {
                                                    i18n.t("campaigncreate.labeldownloadcode")
                                                } </label> */}
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                  <div style={{ height: "20px" }}></div>

                  {card}

                  <div
                    style={{
                      float: "right",
                      paddingTop: "10px",
                    }}
                  >
                    <Button
                      id="backButton"
                      icon="arrowleft"
                      text={i18n.t("campaigncreate.btnreset")}
                      width="120px"
                      style={{ marginRight: "5px" }}
                      onClick={this.reset}
                    />
                    <Button
                      id="testButton"
                      icon="arrowleft"
                      text={i18n.t("campaigncreate.btntest")}
                      visible={this.state.cardEmail === "active_"}
                      width="120px"
                      style={{ marginRight: "5px" }}
                      onClick={this.sendTest}
                    />
                    <Button
                      id="backButton"
                      icon="save"
                      text={i18n.t("campaigncreate.btnsave")}
                      width="120px"
                      type="success"
                      disabled={this.campaignId ? true : false}
                      onClick={this.Save}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 p-0">
              <div className="card" style={{ height: "80vh" }}>
                {htmlBodyEditor}
                {showCoupon}
                {shareComplete}{" "}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
