import React, { Component } from "react";
import Spinner from "../../LayOut/Spinner";
import { Button } from "devextreme-react";
// import usMessages from "devextreme/localization/messages/en.json";
import notify from "devextreme/ui/notify";

import DataGrid, {
    Column,
    Pager,
    Paging,
    HeaderFilter,
    SearchPanel,
    Editing,
    Popup,
    Form,
    EmailRule,
    Export,

} from "devextreme-react/data-grid";
import { loadMessages, locale, } from "devextreme/localization";
import { getContactList, createContact, updateContact, deleteContact, } from "../../../apis/toowezeApi";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { Item } from "devextreme-react/form";
import { Template } from "devextreme-react/core/template";
import { Popup as PopUp1 } from "devextreme-react/popup";
import { ScrollView } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";
import SwitchComponent from "../../CouponModule/BuildCanvas/SwitchComponent";
import Switch from "devextreme-react/switch";
import { i18n, lgMessages, languageCode, } from "../../../translate/i18n";
import "./ContactsViewMiddle.css";

let columns_;
let columns_Line;
let lines;
let myDrops;
// let delimiter = ";";
let columnList = [];
let message = "";
let isOK = false;
const position = {
    of: "#importing"

};

export default class ContactsMiddle extends Component {

    constructor(props) {

        super(props);

        this.state = {

            datagrid: [],
            spinner: true,
            events: [],
            popupVisible: false,
            delimiter: ";",
            text: "",
            button: false,
            backgroundcolor: "white",
            loadPanelVisible: false,
            statusDisabled: false,
            showDelimiter: "",
            myDrops: []

        };

        // Configura o idioma
        loadMessages(lgMessages);
        locale(languageCode);
    }

    componentDidMount() {
        this.readContactGrid();
    }

    // Lê todos os contados da lista e insere no grid.
    readContactGrid() {

        let url = window.location.search;
        let params = queryString.parse(url);
        if (params.id) {

            getContactList(params.id).then((res) => {
                this.setState({ datagrid: res, spinner: false, });
            }).catch((err) => {
                this.setState({ datagrid: [], spinner: false, });
            });
        }
    }

    // Faz aparecer uma mensagem
    showNotify(message, type) {

        notify({
            message: message,
            width: 300,
            position: {
                at: "top",
                offset: "0 200",

            },
            closeOnClick: true,

        }, type, 3000);
    }

    // Cria os botões
    contactsActions(data) {
        return (
            <Link to={
                `campaignsactivity?id=${data.data._id
                }`
            }
                className="buttonClass badge badge-success"
                style={
                    { color: "#fff" }
                }>
                {
                    i18n.t("contacts.grid.buttonlabelcontacts")
                } </Link>
        );
    }

    // Altera a visualização no grid da informação de status de 1 para Ativo
    sentStatus(data) {

        if (data.data.status == "1") {
            return (
                <span className="badge badge-success">
                    {
                        i18n.t("contacts.grid.contactstatusactive")
                    } </span>
            );
        } else if (data.data.status == "0") {
            return (
                <span className="badge badge-danger">
                    {
                        i18n.t("contacts.grid.contactstatusinactive")
                    } </span>
            );
        } else if (data.data.status == "2") {
            return (
                <span className="badge badge-danger">
                    {
                        i18n.t("contacts.grid.contactstatusunsubscribe")
                    } </span>
            );
        } else if (data.data.status == "3") { // Bounce
            return (
                <span className="badge badge-danger">
                    {
                        i18n.t("contacts.grid.contactstatbounce")
                    } </span>
            );
        } else if (data.data.status == "4") { // Complaint
            return (
                <span className="badge badge-danger">
                    {
                        i18n.t("contacts.grid.contactstatspamreport")
                    } </span>
            );
        }
    }

    // Deleta um Contato da Lista
    onRowRemoving = (e) => {

        deleteContact(e.data._id).then((res) => {
            this.showNotify(i18n.t("contacts.grid.notifycontactdeleted"), "success");
            this.readContactGrid();
        }).catch((err) => {

            this.showNotify(err, "error");
        });
    };

    // Atualiza um contato
    onRowUpdated = (e) => {

        updateContact({
            id: e.data._id,
            status: e.data.status,
            email: e.data.email,
            firstName: e.data.firstName,
            lastName: e.data.lastName,
            mobil: e.data.mobil,
            extraField1: e.data.extraField1,
            extraField2: e.data.extraField2,

        }).then((res) => {

            this.showNotify(i18n.t("contacts.grid.notifycontactupdated"), "success");
            this.readContactGrid();

        }).catch((err) => {
            this.showNotify(err, "error");
        });
    };

    onRowInserting = (e) => {

        let url = window.location.search;

        let params = queryString.parse(url);

        if (params.id) {
            e.cancel = new Promise((resolve, reject) => {
                if (e.data.mobil !== undefined || e.data.email !== undefined) {

                    createContact({

                        listId: params.id,
                        list: [
                            {
                                firstName: e.data.firstName,
                                lastName: e.data.lastName,
                                mobil: e.data.mobil,
                                email: e.data.email,
                                status: e.data.status,
                                extraField1: e.data.extraField1,
                                extraField2: e.data.extraField2,
                                origin: i18n.t("contacts.grid.contactoriginlabel"),

                            },
                        ],

                    }).then((res) => {

                        if (res) {
                            if (res.data.validation) {
                                if (res.data.validation.errors > 0) {
                                    reject("Erro! " + res.data.validation.errorList[0]);
                                } else if (res.data.validation.success > 0) {
                                    this.showNotify(i18n.t("contacts.grid.notifycontadded"), "success");
                                    this.readContactGrid();
                                    resolve();
                                }

                            } else {
                                reject("Error!");
                            }

                        } else {
                            reject("Error!");
                        }

                    }).catch((err) => {
                        reject(err);
                    });
                } else {
                    reject(i18n.t("contacts.grid.notifyrequiredfield"));
                }
            });
        }
    };

    // Incluindo um botão no toolbar do Grid
    toolbarItemRender = () => {
        return (
            <Button id="import" icon="export"
                onClick={
                    this.importContacts
                }
                hint={
                    i18n.t("contacts.grid.contacthintlabel")
                }
                style={
                    { background: "#f9b831" }
                } />
        );
    };

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({ location: "after", template: "totalGroupCount", });
    };
    // Incluindo um botão no toolbar do Grid

    // Função de Abrir modal de importar Contatos
    importContacts = () => {
        this.setState({ popupVisible: true });
    };

    // Pop Up tipo modal para importar contatos
    // Desmiss o PopUp
    hideInfo = () => {
        this.setState({ popupVisible: false, text: "", loadPanelVisible: false, });
    };

    delimiterValue = (event) => {
        this.setState({ delimiter: event.target.value });
    };


    readUpload = (event) => {

        if (event && event.target.files[0]) {
            let myFile = event.target.files[0];
            event.target.value = "";

            var reader = new FileReader();

            reader.readAsDataURL(myFile);

            if (myFile) {

                reader.onload = (e) => {
                    this.setState({ text: e.target.result, button: true, }, function () {
                        this.createDrop()
                    });

                };
            }
        }
    };

    // Evento do OnChange do FileUpload, lê as colunas e cria os dropdowns para o relacionamento das colunas antes da importação.
    buildForm = (text) => {

        if (text) { // var text = this.props.text;

            try {
                let indexOfBase = text.indexOf('base64,');
                let textToRemove = text.slice(0, indexOfBase + 7);

                if (text.includes(textToRemove)) {
                    text = text.replace(textToRemove, "");
                    isOK = true;
                } else {
                    isOK = false;
                    message = i18n.t("contacts.grid.messageimportingerror");
                }

            } catch (error) {
                isOK = false;
                message = i18n.t("contacts.grid.messageimportingerror");
            }

            if (isOK === true) {
                text = atob(text);

                // Remove aspas do texto
                let test = text;
                let patt = new RegExp(/['"]+/g);
                let res = patt.test(test);

                if (res === true) {
                    text = text.replace(/['"]+/g, '')
                }

                lines = text.split("\n");

                // Delimiter automatic
                let temp = lines[0];
                let count1 = (temp.match(/;/g) || []).length;
                let count2 = (temp.match(/,/g) || []).length;
                let check_delimiter;

                if (count1 > count2) {
                    this.setState({ delimiter: ";" })
                    check_delimiter = ";";
                } else if (count1 < count2) {
                    this.setState({ delimiter: "," })
                    check_delimiter = ",";
                }

                if (lines.length > 1) { // Alterei pois antes estava percorrendo todo arquivo apenas para identifiar o nome das colunas, que estão na primeira linha.

                    columns_ = lines[0].split(check_delimiter);
                    columnList = [];
                    columns_Line = columns_;

                    for (var j = 0, len2 = columns_Line.length; j < len2; j++) {
                        if (columns_Line[j].trim() !== "") {
                            columnList.push({ id: j, value: columns_Line[j], });
                        } else {
                            columns_Line.splice(j);
                        }
                    }
                }

                if (this.state.button === false) {
                    this.setState({ button: true });
                }

                return columnList;

            } else { // Mensagem
                this.showNotify(message);
                return [];
            }
        }
    };

    // Função que realiza a importação dos contatos:
    proceedImportContacts = () => {

        if (isOK === true) {

            this.setState({ loadPanelVisible: true });

            let emailCol;
            let firstNameCol;
            let lastNameCol;
            let mobilCol;
            let extraField1Col;
            let extraField2Col;

            // Encontra as colunas relacionadas
            var colNumbers = new Promise(function (resolve, reject) {

                for (var k = 0, len3 = columns_Line.length; k < len3; k++) {

                    let selectOption_ = document.getElementById(`columnRef${k}`).value;

                    // Posiona os números das colunas e e atrela as variáveis
                    if (selectOption_ !== "0") {

                        if (selectOption_ === "email") {
                            emailCol = k;
                        } else if (selectOption_ === "firstName") {
                            firstNameCol = k;
                        } else if (selectOption_ === "lastName") {
                            lastNameCol = k;
                        } else if (selectOption_ === "mobil") {
                            mobilCol = k;
                        } else if (selectOption_ === "extraField1") {
                            extraField1Col = k
                        } else if (selectOption_ === "extraField2") {
                            extraField2Col = k
                        }
                    }
                }

                resolve({
                    emailCol,
                    firstNameCol,
                    lastNameCol,
                    mobilCol,
                    extraField1Col,
                    extraField2Col,
                });

            });

            colNumbers.then((colNumbers_) => {
                if (colNumbers_.emailCol !== undefined || colNumbers_.mobilCol !== undefined) {
                    return new Promise((resolve, reject) => {

                        let url = window.location.search;
                        let params = queryString.parse(url);

                        let data_ = {
                            listId: params.id,
                            list: [],
                        };

                        // Sabendo qual o relacionamento das colunas faz a importação
                        for (var i = 0, len = lines.length; i < len; i++) {

                            let columns_;
                            columns_ = lines[i].split(this.state.delimiter);

                            let email_ = "";
                            let firstName_ = "";
                            let lastName_ = "";
                            let mobil_ = "";
                            let extraField1_ = "";
                            let extraField2_ = "";

                            email_ = columns_[colNumbers_.emailCol];
                            firstName_ = columns_[colNumbers_.firstNameCol];
                            lastName_ = columns_[colNumbers_.lastNameCol];
                            mobil_ = columns_[colNumbers_.mobilCol];
                            extraField1_ = columns_[colNumbers_.extraField1Col];
                            extraField2_ = columns_[colNumbers_.extraField2Col];

                            if (email_) {
                                email_ = email_.trim().toLowerCase();
                            }

                            if (firstName_) {
                                firstName_ = firstName_.trim();
                            }

                            if (lastName_) {
                                lastName_ = lastName_.trim();
                            }

                            if (mobil_) {
                                mobil_ = mobil_.trim();
                            }

                            if (mobil_) {
                                mobil_ = mobil_.match(/\d+/g);
                            }

                            if (mobil_) {

                                if (mobil_.length > 0) {
                                    mobil_ = mobil_.join("");
                                }

                            }

                            if (email_ !== undefined) {
                                if (!email_.includes("@")) {
                                    email_ = undefined;
                                }

                            }

                            if (extraField1_) {
                                extraField1_ = extraField1_.trim();
                            }

                            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                            if (!re.test(String(email_).trim().toLowerCase())) {
                                email_ = undefined;
                            }

                            if ((email_ !== "" && email_ !== undefined && email_.includes("@")) || (mobil_ !== "" && mobil_ !== undefined)) {

                                if (firstName_) {

                                    let firstNameArray = firstName_.split(" ");

                                    if (firstNameArray.length > 1 && (lastName_ === undefined || lastNameCol === "0")) {

                                        firstName_ = firstNameArray.shift();
                                        lastName_ = firstNameArray.join(" ");
                                    }

                                }

                                let add = {

                                    firstName: firstName_,
                                    lastName: lastName_,
                                    mobil: mobil_,
                                    email: email_,
                                    extraField1: extraField1_,
                                    extraField2: extraField2_,
                                    status: 1,
                                    origin: i18n.t("contacts.grid.contactoriginlabelimported"),

                                };

                                data_.list.push(add);
                            } else {
                                continue;
                            }

                        }

                        resolve(data_);

                    }).then((mydata) => { // Enviando tudo para importação.
                        createContact(mydata).then((res) => { // Resultado da importação
                            if (res) {
                                if (res.status === 200) {
                                    if (res.data.validation) {
                                        if (res.data.validation.success > 0 && res.data.validation.errors > 0) { // Houveram sucessos e erros
                                            this.showNotify(`${res.data.validation.success
                                                }, ${i18n.t("contacts.grid.notifyimportresult1")
                                                } ${res.data.validation.errors
                                                } ${i18n.t("contacts.grid.notifyimportresult2")
                                                }
                                                 ${i18n.t("contacts.grid.notifyimportresult3")
                                                }`);
                                            this.readContactGrid(); // / Relê os dados no grid.

                                        } else if (res.data.validation.errors > 0 && res.data.validation.success == 0) { // Todos com erro
                                            this.showNotify(i18n.t("contacts.grid.notifyimportresult4"), "error");
                                            this.readContactGrid(); // / Relê os dados no grid.

                                        } else if (res.data.validation.success > 0 && res.data.validation.errors == 0) { // Todos com Sucesso
                                            this.showNotify(i18n.t("contacts.grid.notifyimportresult5"), "success");
                                            this.readContactGrid(); // / Relê os dados no grid.
                                        }

                                        setTimeout(() => {
                                            this.hideInfo();
                                        }, 3000);
                                    }

                                } else if (res.status === 402) {
                                    this.showNotify(i18n.t("contacts.grid.notifyimportresult6"), "error");
                                    this.hideInfo();
                                }

                                else {
                                    this.showNotify("Error!", "error");
                                    this.hideInfo();
                                }

                            } else {
                                this.showNotify("Error!", "error");
                                this.hideInfo();
                            }

                        }).catch((error) => { // Erro da Importação

                            this.setState({ loadPanelVisible: false });

                            let message = "";
                            let message_ = "";

                            console.log("error", error);

                            if (error) {

                                if (error?.reponse) {
                                    console.log("error.reponse", error.reponse);
                                    if (error?.response?.data) {
                                        console.log("error.response.data", error.response.data)
                                        message = error.response.data;
                                        if (message === "You did reach the account limit") { // Close the model
                                            message_ = "Sua conta atingiu o limite de contatos. Faça um Upgrade!";
                                            this.showNotify("Erro Na Importação: " + message_, "error");
                                        } else if (message === "No Contacts to import!") {
                                            message_ = "Não há nenhum contato nesta lista para importar!";
                                            this.showNotify("Erro Na Importação: " + message_, "error");
                                        } else {
                                            message_ = message;
                                            this.showNotify("Erro Na Importação: " + message_, "error");
                                        }
                                    }

                                } else {
                                    if (error?.message === "Request failed with status code 402") {
                                        message_ = "Sua conta atingiu o limite de contatos. Faça um Upgrade!";
                                        this.showNotify("Erro Na Importação: " + message_, "error");
                                    } else {
                                        console.log("error status", error.status)
                                        this.showNotify("Erro Na Importação: " + error.reponse, "error");
                                    }
                                }
                            } else {
                                this.showNotify("Erro Na Importação: " + error, "error");
                            }
                        });
                    }).catch((err) => {

                        console.log("err", err);
                        this.showNotify("Erro Na Importação: " + err, "error");

                    });

                } else {

                    this.setState({ loadPanelVisible: false });

                    if (colNumbers_.emailCol == undefined && colNumbers_.mobilCol == undefined && colNumbers_.firstNameCol == undefined && colNumbers_.lastNameCol == undefined) {

                        this.showNotify("Você não relacionou nenhuma coluna para importação.", "error");
                    } else {
                        this.showNotify("Você precisa selecionar ao menos uma coluna com dados de contato como: email ou celular.", "error");
                    }
                }
            });
        }

    };

    changedValue = (event, id) => {
        this.setState({

            backgroundcolor: {
                id: id,
                color: "yellow",
            }
        });
    };

    // Status
    editStatus = (data) => { // Tras o component de Switch
        return (
            <div>
                <SwitchComponent id={
                    data.data._id
                }
                    event={"contactStatus"}
                    value={
                        data.data.status == 1 ? true : false
                    } />
            </div>
        );
    };

    renderSwitch = (cellInfo) => {

        const setEditedValue = (valueChangedEventArg) => {
            cellInfo.setValue(valueChangedEventArg.value);
        };
        return (
            <div className=""
                style={
                    { paddingLeft: "6px" }
                }>
                <Switch width={50}
                    switchedOnText="Ativo"
                    switchedOffText="Inativo"
                    defaultValue={
                        cellInfo.value
                    }
                    disabled={
                        cellInfo.row.values[3] === 0 || cellInfo.row.values[3] === 1 ? false : true
                    }
                    onValueChanged={setEditedValue} />
            </div>
        );
    };

    calculateCellValue2(data) {

        if (data) {
            if (data.createdAt) {
                return new Date(data.createdAt * 1000);
            }
        }
    }

    createDrop() {

        // setTimeout(() => {

        if (this.state.text) {

            myDrops = this.buildForm(this.state.text).map((result) => {

                return (
                    <div key={
                        result.id
                    }
                        className="form-group">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6">
                                    <label htmlFor={
                                        "Column_" + result.id
                                    }>
                                        Coluna do seu arquivo
                                    </label>
                                    <input className="form-control" type="text"
                                        id={
                                            "Column_" + result.id
                                        }
                                        defaultValue={
                                            result.value
                                        } />
                                </div>
                                <div className="col-6">
                                    <label htmlFor={
                                        "columnRef" + result.id
                                    }>
                                        {
                                            i18n.t("contacts.grid.columnrelationslabel")
                                        } </label>
                                    <select id={
                                        "columnRef" + result.id
                                    }
                                        className="browser-default custom-select"
                                        defaultValue="0">
                                        <option value="0">
                                            {
                                                i18n.t("contacts.grid.dropdownignorelabel")
                                            } </option>
                                        <option value="firstName">
                                            {
                                                i18n.t("contacts.grid.dropdownnamelabel")
                                            } </option>
                                        <option value="lastName">
                                            {
                                                i18n.t("contacts.grid.dropdownnlastnamelabel")
                                            } </option>
                                        <option value="email">
                                            {
                                                i18n.t("contacts.grid.dropdownnemaillabel")
                                            } </option>
                                        <option value="mobil">
                                            {
                                                i18n.t("contacts.grid.dropdownnmobillabel")
                                            } </option>
                                        <option value="extraField1">
                                            Extra {/* {i18n.t("contacts.grid.dropdownnextralabel")} */} </option>
                                        <option value="extraField2">
                                            Extra2 {/* {i18n.t("contacts.grid.dropdownnextralabel")} */} </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            });
            this.setState({ myDrops: myDrops })

        }
        // }, 500);

    }

    render() {

        if (this.state.spinner === true) {
            return <Spinner />;
        } else {


            // Nome da Lista de Contatos	Criado em	Atualizada em	Status	Editar/Deletar
            return (
                <div className="container col-11"
                    style={
                        {
                            marginLeft: "30px",
                            marginTop: "30px",
                        }
                    }>
                    {/* PopUp de Importação de Contatos */}
                    <PopUp1 visible={
                        this.state.popupVisible
                    }
                        onHiding={
                            this.hideInfo
                        }
                        dragEnabled={false}
                        closeOnOutsideClick={true}
                        showTitle={true}
                        title="Importar Lista de Cotatos"
                        width={600}
                        height={450}
                        style={
                            { overflow: "scroll" }
                        }>
                        <ScrollView width="100%" height="100%">
                            <div id="importing" className="col-12"
                                style={
                                    { marginLeft: "3px" }
                                }>
                                <div className="row">
                                    <div className="col p-0">
                                        <div className="pl-3 pr-3">
                                            {/* <div className="container form-group mb-3">
                                                <label htmlFor="delimiter">
                                                    {
                                                        i18n.t("contacts.grid.delimiter")
                                                    } </label>
                                                <input className="form-control" type="text" id="delimiter"
                                                    style={
                                                        {
                                                            width: "72px",
                                                            margin: "0px 0px 16px 0px",
                                                            padding: "6px",

                                                        }

                                                    }
                                                    maxLength="1"
                                                    value={
                                                        this.state.delimiter
                                                    }
                                                    onChange={
                                                        (event) => this.setState({ delimiter: event.target.value })
                                                    } />
                                                <label style={
                                                    {
                                                        fontWeight: "bolder",
                                                        color: 'red',
                                                    }

                                                }>
                                                    {
                                                        this.state.showDelimiter
                                                    }</label>
                                            </div> */}

                                            <div className="container input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"
                                                        style={
                                                            { height: "35px" }
                                                        }>
                                                        {
                                                            i18n.t("contacts.grid.upload")
                                                        } </span>
                                                </div>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="fileUpload_"
                                                        onChange={
                                                            (event) => this.readUpload(event)
                                                        } />
                                                    <label className="custom-file-label" htmlFor="fileUpload_">
                                                        {
                                                            i18n.t("contacts.grid.uploadfile")
                                                        } </label>
                                                </div>
                                            </div>

                                            <div id="allColumns">
                                                {this.state.myDrops}

                                                <div className="container row p-0"
                                                    style={
                                                        { textAlign: "right" }
                                                    }>
                                                    <div className="col p-0">
                                                        <Button className="m-1"
                                                            visible={
                                                                this.state.button
                                                            }
                                                            width={120}
                                                            height={25}
                                                            text="Cancelar"
                                                            type="success"
                                                            stylingMode="outlined"
                                                            onClick={
                                                                this.hideInfo
                                                            } />
                                                        <Button visible={
                                                            this.state.button
                                                        }
                                                            width={120}
                                                            height={25}
                                                            text="Importar"
                                                            type="success"
                                                            stylingMode="contained"
                                                            onClick={
                                                                this.proceedImportContacts
                                                            } />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <LoadPanel shadingColor="rgba(0,0,0,0.4)"
                                position={position}
                                visible={
                                    this.state.loadPanelVisible
                                }
                                showIndicator={true}
                                shading={true}
                                showPane={true}
                                closeOnOutsideClick={true} />
                        </ScrollView>
                    </PopUp1>
                    {/* PopUp de Importação de Contatos */}

                    <DataGrid dataSource={
                        this.state.datagrid
                    }
                        showBorders={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        className="custom dx-cell-focus-disabled"
                        onRowInserting={
                            this.onRowInserting
                        }
                        onRowUpdated={
                            this.onRowUpdated
                        }
                        onRowRemoving={
                            this.onRowRemoving
                        }
                        onToolbarPreparing={
                            this.onToolbarPreparing
                        }
                    /// Adicionando um botão no toolbar do grid
                    >
                        <Paging defaultPageSize={12} />
                        <Pager showInfo={true} />

                        <Editing mode="popup"
                            allowUpdating={true}
                            allowDeleting={true}
                            allowAdding={true}>
                            <Popup title="Contato"
                                showTitle={true}
                                width={700}
                                height={400}
                                shading={true}
                                fullScreen={false}></Popup>
                            <Form>
                                <Item itemType="group"
                                    colCount={2}
                                    colSpan={2}>
                                    <Item dataField="firstName" />
                                    <Item dataField="lastName" />
                                    <Item dataField="email">
                                        <EmailRule />
                                    </Item>
                                    <Item dataField="mobil" />
                                    <Item dataField="extraField1" />
                                    <Item dataField="extraField2" />
                                </Item>
                                <Item dataField="status" />
                                <Item dataField="createdAt"
                                    visible={false} />
                            </Form>
                        </Editing>

                        <HeaderFilter visible={true} />
                        <SearchPanel visible={true}
                            className="custom"
                            width={240}
                            placeholder={
                                i18n.t("contacts.grid.searchplaceholder")
                            } />
                        <Column dataField="firstName"
                            caption={
                                i18n.t("contacts.grid.columnlabelfirstname")
                            } />
                        <Column dataField="lastName"
                            caption={
                                i18n.t("contacts.grid.columnlabellastname")
                            } />
                        <Column dataField="email"
                            caption={
                                i18n.t("contacts.grid.columnlabelemail")
                            } />
                        <Column dataField="mobil"
                            caption={
                                i18n.t("contacts.grid.columnlabelmobil")
                            } />
                        <Column dataField="extraField1"
                            caption={
                                i18n.t("contacts.grid.columnlabelextra")
                            } />
                        <Column dataField="extraField2"
                            caption={
                                "Extra2"
                                // i18n.t("contacts.grid.columnlabelextra")
                            } />
                        <Column dataField="msg"
                            caption={
                                "Message"
                                // i18n.t("contacts.grid.columnlabelextra")
                            } />

                        <Column dataField="status"
                            caption={
                                i18n.t("contacts.grid.columnlabeliststatus")
                            }
                            cellRender={
                                this.sentStatus
                            }
                            editCellRender={
                                this.renderSwitch
                            } />

                        <Column dataField="createdAt"
                            caption={
                                i18n.t("contacts.grid.columnlabelcreatedat")
                            }
                            dataType="date"
                            calculateCellValue={
                                this.calculateCellValue2
                            } />
                        <Column dataField="origin"
                            caption={
                                i18n.t("contacts.grid.columnlabelorigin")
                            }></Column>
                        <Template name="totalGroupCount"
                            render={
                                this.toolbarItemRender
                            } />
                        <Export enabled={true}
                            allowExportSelectedData={false}
                            fileName="TooWeze_Contatos" />
                    </DataGrid>
                </div>
            );
        }
    }
}