import React, { Component } from "react";
import updateCouponCampaignsView from "../../../actions/updateCouponCampaignsView";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class SwitchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Evita renderizar cada vez que o estado é modificado
    if (this.props.value !== nextProps.value) {
      return true;
    }

    return true;
  }

  // Altera o valor do Switch e informa o Store na Action
  valueChanged = (id, event) => {
    this.setState({
      value: !this.state.value,
    });

    if (event == "campaignStatus") {
      this.props.updateCouponCampaignsView(id, event, 0, !this.state.value);
    }
  };

  // Recebe o evento e o id, conforme o evento aciona uma action diferente.
  render() {
    return (
      <div className="custom-control custom-switch" style={{ float: "left" }}>
        <input
          id={this.props.id}
          type="checkbox"
          className="custom-control-input"
          checked={this.state.value}
          onChange={() => {
            this.valueChanged(this.props.id, this.props.event);
          }}
        />
        <label
          className="custom-control-label red"
          style={{ fontSize: "14px", color: "green" }}
          htmlFor={this.props.id}
        ></label>
      </div>
    );
  }
}

// mapStateToProps takes one args, "state" and that is the rootReducer/Store
function mapStateToProps(state) {
  // mapStateToProps returns an object, with:
  // property is the local prop name to this component
  // value will be the property in the root reducer... ie, piece of the store
  return {};
}

// mapDispatchToProps is how we tie our component to the dispatch
// it take one args: dispatch
function mapDispatchToProps(dispatch) {
  // this function return bindActionCreators
  // and we hand bindActionCreators an object
  // each property will be a local prop
  // each value will be a function that is dispatch when run
  // second arg of bindActionCreators is the dispatch
  return bindActionCreators(
    {
      updateCouponCampaignsView: updateCouponCampaignsView,
    },
    dispatch
  );
}

// export default TopBar;
// connect take 2 args, the first one is a function that is going to map
// a piece of redux state to this components props
export default connect(mapStateToProps, mapDispatchToProps)(SwitchComponent);
