import axios from "axios";
import { urlAPI } from "./apiBase";

const token = localStorage.getItem("tooweze_token");

export default async function getSticker(userId, page) {
  return new Promise((result, reject) => {
    if (userId) {
      return axios({
        method: "GET",
        url: urlAPI.stickerprovider + `/pages/?page=${page}`,
        headers: {
          "x-auth-token": token,
        },
      }).then((res) => {
        result(res.data);
      }).catch((error) => {
        console.log("Sticker Error", error);
        reject(error);
      });
    } else {
      // Erro Estado Obrigatório
      reject("Sticker Error!");
    }
  });
}
