import React, { Component } from "react";
import Spinner from "../../LayOut/Spinner";
import { Button } from "devextreme-react";

import DataGrid, {
    Column,
    Pager,
    Paging,
    HeaderFilter,
    SearchPanel,
    Export,
    Lookup,
    Scrolling

} from "devextreme-react/data-grid";
import { loadMessages, locale, } from "devextreme/localization";
import { getReport } from "../../../apis/toowezeApi";
import states from "../../data/states";
import countries from "../../data/countries";
import { Link } from "react-router-dom";
import { Template } from "devextreme-react/core/template";
import Switch from "devextreme-react/switch";
import { i18n, lgMessages, languageCode, } from "../../../translate/i18n";
import "./ReportsViewMiddle.css";
let setReport

export default class ReportsViewMiddle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datagrid: [],
            spinner: true,
            events: [],
            popupVisible: false,
            delimiter: ";",
            text: "",
            button: false,
            backgroundcolor: "white",
            loadPanelVisible: false,
            statusDisabled: false,
            showDelimiter: "",
            myDrops: []
        };
        loadMessages(lgMessages);
        locale(languageCode);
    }


    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        this.loadData();
    }

    loadData() {
        let urlParams = new URLSearchParams(window.location.search);
        if (setReport !== urlParams.get("type")) {
            setReport = urlParams.get("type");
            getReport(setReport)
                .then((res) => {
                    this.updateState(res);
                })
                .catch((err) => {
                    this.updateState([]);
                });
        }
    }

    updateState(data) {
        this.setState({ datagrid: data, spinner: false });
    }

    // componentDidMount() {
    //     this.getCustomersReport();
    // }

    // componentDidUpdate() {
    //     let urlParams = new URLSearchParams(window.location.search);
    //     if (setReport !== urlParams.get("type")) {
    //         this.setState({ spinner: true });
    //         setReport = urlParams.get("type");
    //         getReport(setReport).then((res) => {
    //             this.setState({ datagrid: res, spinner: false, });
    //         }).catch((err) => {
    //             this.setState({ datagrid: [], spinner: false, });
    //         });
    //     }
    // }

    // getCustomersReport() {
    //     let urlParams = new URLSearchParams(window.location.search);
    //     if (setReport !== urlParams.get("type")) {
    //         setReport = urlParams.get("type");
    //         getReport(setReport).then((res) => {
    //             this.setState({ datagrid: res, spinner: false, });
    //         }).catch((err) => {
    //             this.setState({ datagrid: [], spinner: false, });
    //         });
    //     }
    // }

    setStatus(data) {
        if (data.data.status === "Active") {
            return (
                <span className="badge badge-success">
                    {
                        i18n.t("customers.grid.customerstatusactive")
                    } </span>
            );
        } else if (data.data.status === "Inactive") {
            return (
                <span className="badge badge-danger">
                    {
                        i18n.t("customers.grid.customerstatusinactive")
                    } </span>
            );
        }
    }

    statusHeaderFilter = [
        {
            text: i18n.t("customers.grid.customerstatusactive"),
            value: ["status", "=", "Active"],
        },
        {
            text: i18n.t("customers.grid.customerstatusinactive"),
            value: ["status", "=", "Inactive"],
        },
    ];

    setGender(data) {
        if (data.data.gender === "1") {
            return (
                <span className="badge badge-primary" width="30px">
                    {
                        i18n.t("customers.grid.customergendermale")
                    } </span>
            );
        } else if (data.data.gender === "2") {
            return (
                <span className="badge badge-danger" width={3}>
                    {
                        i18n.t("customers.grid.customergenderfemale")
                    } </span>
            );
        } else {
            return (
                <span className="badge badge-info" width={3}>
                    {
                        i18n.t("customers.grid.customergendernot")
                    } </span>
            );
        }
    }

    genderHeaderFilter = [
        {
            text: i18n.t("customers.grid.customergendermaleheader"),
            value: ["gender", "=", "1"],
        },
        {
            text: i18n.t("customers.grid.customergenderfemalehearder"),
            value: ["gender", "=", "2"],
        },
    ];

    toolbarItemRender = () => {
        return (
            <Button id="import" icon="export"
                onClick={
                    this.importCustomers
                }
                hint={
                    i18n.t("customers.grid.customershintlabel")
                }
                style={
                    { background: "#f9b831" }
                } />
        );
    };

    renderSwitch = (cellInfo) => {
        const setEditedValue = (valueChangedEventArg) => {
            cellInfo.setValue(valueChangedEventArg.value);
        };
        return (
            <div className=""
                style={
                    { paddingLeft: "6px" }
                }>
                <Switch width={50}
                    switchedOnText={i18n.t("customers.grid.customerstatusactive")}
                    switchedOffText={i18n.t("customers.grid.customerstatusinactive")}
                    defaultValue={
                        cellInfo.value
                    }
                    disabled={
                        cellInfo.row.values[3] === 0 || cellInfo.row.values[3] === 1 ? false : true
                    }
                    onValueChanged={setEditedValue} />
            </div>
        );
    };

    calculateCellValue2(data) {
        if (data) {
            if (data.createdAt) {
                return new Date(data.createdAt * 1000);
            }
        }
    }

    addCurrency = (data) => {
        if (data.value) {
            const value = data.value;
            const formattedValue = Number(value).toFixed(2);
            const withZero = formattedValue.includes('.') ? formattedValue : formattedValue + '0';
            return (
                <span style={{ fontWeight: 'bold' }}>{withZero}</span>

            );
        } else {
            return (<div>
                <label className="badge">n/a</label>
            </div>
            );
        }
    };


    activity(data) {
        return (
            <Link to={`addeditcustomers?id=${data.data._id}`}>
                {i18n.t("customers.grid.customerlabeldetails")}
            </Link>
        );
    }

    toDate = (data) => {
        try {
            return new Date(data?.data?.log_date_timeZone).toLocaleString();
        } catch (error) {
            console.log('error =======>', error)
            return null
        }
    }

    render() {

        if (this.state.spinner === true) {
            return <Spinner />;
        } else {
            return (
                <div className="container col-11"
                    style={
                        {
                            marginLeft: "30px",
                            marginTop: "30px",
                        }
                    }>
                    <h1>{setReport === "allPoints" ? i18n.t("customers.grid.columnlabeltotalPoints") : i18n.t("customers.grid.columnlabeltotalCashBack")}</h1>

                    <DataGrid dataSource={
                        this.state.datagrid
                    }
                        columnWidth={100}
                        showBorders={true}

                        allowColumnReordering={true}
                        allowColumnResizing={true}

                    >
                        <Scrolling mode="standard" />
                        <Paging defaultPageSize={12} />
                        <Pager showInfo={true} />
                        <HeaderFilter visible={true} />
                        <SearchPanel visible={true}
                            className="custom"
                            width={240}
                            placeholder={
                                i18n.t("customers.grid.searchplaceholder")
                            } />

                        <Column
                            dataField="seeActivity"
                            caption={i18n.t("customers.grid.customerlabeldetails")}
                            cellRender={this.activity}
                            allowFiltering={false}
                        />

                        <Column dataField="name" width={200} caption={i18n.t("customers.grid.columnlabelname")} />
                        <Column dataField="email" width={200} caption={i18n.t("customers.grid.columnlabelemail")} />

                        <Column dataField="mobil" caption={i18n.t("customers.grid.columnlabelmobil")} />
                        <Column dataField="total" caption={setReport === "allPoints" ? i18n.t("customers.grid.columnlabeltotalPoints") : i18n.t("customers.grid.columnlabeltotalCashBack")} cellRender={this.addCurrency} />

                        <Column dataField="gender"
                            caption={
                                i18n.t("customers.grid.columnlabelgender")
                            }
                            cellRender={
                                this.setGender
                            }
                            editCellRender={
                                this.renderSwitch
                            }

                        ><HeaderFilter dataSource={this.genderHeaderFilter} /></Column>

                        <Column dataField="externalID" caption={i18n.t("customers.grid.columnlabelexternalID")} />
                        <Column dataField="birthDay" dataType="date" caption={i18n.t("customers.grid.columnlabelbirthDay")} />
                        <Column dataField="address.cityName" caption={i18n.t("customers.grid.columnlabelcityName")} />
                        <Column dataField="regionId" caption={i18n.t("customers.grid.columnlabelregionName")}>
                            <Lookup dataSource={states} valueExpr="id" displayExpr="nome" />
                        </Column>

                        <Column dataField="countryId" caption={i18n.t("customers.grid.columnlabelcountryName")}>
                            <Lookup dataSource={countries} valueExpr="id" displayExpr="name" />
                        </Column>
                        <Column dataField="tags" type={"Array"}
                            caption={i18n.t("customers.grid.columnlabeltags")}>
                        </Column>

                        <Column dataField="status"
                            caption={
                                i18n.t("customers.grid.columnlabelstatus")
                            }
                            cellRender={
                                this.setStatus
                            }
                            editCellRender={
                                this.renderSwitch
                            } >
                            <HeaderFilter dataSource={this.statusHeaderFilter} />
                        </Column>

                        {/* <Column dataField="createdAt"
                            caption={
                                i18n.t("customers.grid.columnlabelcreatedat")
                            }
                            dataType="date"
                            calculateCellValue={
                                this.calculateCellValue2
                            } /> */}

                        <Column
                            dataField="log_date_timeZone"
                            caption={i18n.t("transactions.activity.log_date_timeZone")}
                            cellRender={this.toDate}
                        ></Column>


                        <Column dataField="_id" width={100} />
                        <Template name="totalGroupCount"
                            render={
                                this.toolbarItemRender
                            } />
                        <Export enabled={true}
                            allowExportSelectedData={false}
                            fileName="TooWeze_Customers" />
                    </DataGrid>

                </div>
            );
        }
    }
}