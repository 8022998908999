import React, { useEffect, useState } from "react";
import Spinner from "../../LayOut/Spinner";
// import './css/bootstrap.css'
import "grapesjs/dist/css/grapes.min.css";
// import "grapesjs/dist/grapes.min.js";
// import "grapesjs-plugin-ckeditor/dist/grapesjs-plugin-ckeditor.min.js";
import grapesjs from "grapesjs";
import pluginBlocks from "grapesjs-blocks-basic";
import pluginNavbar from "grapesjs-navbar";
import pluginCountdown from "grapesjs-component-countdown";
import pluginForms from "grapesjs-plugin-forms";
import pluginExport from "grapesjs-plugin-export";
// import pluginAviary from "grapesjs-aviary";
import pluginFilestack from "grapesjs-plugin-filestack";
// import grapesjsPluginCkeditor from "grapesjs-plugin-ckeditor";
// import checkEditor from "./CheckEditor";

import tUIImageEditor from "grapesjs-tui-image-editor";
import { uploadImage } from "../../../apis/awsSupport";
// import "./GrapeStyle.scss";
// import "./CreateMiddle.scss";
import "./CreateMiddle.css";

// If you need plugins, put them below the main grapesjs script
// import 'grapesjs-some-plugin';
import pt from "grapesjs/src/i18n/locale/pt";


import blocks from "./blocks";
import commands from "./commands";
import components from "./components";
import panels from "./panels";
import styles from "./styles";
import ModalLanding from "../../ModalLanding";
import { i18n } from "../../../translate/i18n";
import iconD from "tui-image-editor/dist/svg/icon-d.svg";
import iconB from "tui-image-editor/dist/svg/icon-b.svg";
import iconA from "tui-image-editor/dist/svg/icon-a.svg";
import iconC from "tui-image-editor/dist/svg/icon-c.svg";
// import './modal.css';
import './mystyles.css';
import { getLandingPage, getLandingPages, saveLandinPage, updateLandinPage } from '../../../apis/toowezeApi';
// var he = require('he');
import he from 'he';
// import { imageRemoving } from "@syncfusion/ej2-richtexteditor";
import { useSSR } from "react-i18next";

let editor;
var html;
var htmlWithCss;
const AWS = require("aws-sdk");
const BUCKET = 'twz-tooweze-brazil';
const BUCKET_THUMB = "coupon-images-tooweze-thumb";

let landingPageId;
const userAccount = JSON.parse(localStorage.getItem("auth"));
const S3 = new AWS.S3();

AWS.config = {
  accessKeyId: "AKIA5UH2UFRKK56K6FJP",
  secretAccessKey: "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",
  region: "us-east-1",
};

const CreatePageMiddle = (props) => {
  const [htmlBody, setHtmlBody] = useState(null);
  const [bodyCSS, setBodyCSS] = useState(null);
  const [readyToGo, setReadyToGo] = useState(false);
  const [bucket, setBucket] = useState("");

  let urlParams = new URLSearchParams(
    window.location.search.replace("amp;", "")
  );
  landingPageId = urlParams.get("id");

  if (landingPageId.includes("version")) {
    landingPageId = landingPageId.split("?")[0];
  }

  useEffect(() => {

    if (landingPageId) {
      getLandingPage(landingPageId).then(result => {
        setHtmlBody(he.decode(result.html));
        setBodyCSS(he.decode(result.css));
        setBucket(result.bucket);
        setReadyToGo(true);
      }).catch(err => console.log('landingpage Error', err));
    } else {
      setReadyToGo(true);
    }
  }, [htmlBody, landingPageId]);


  // const upload = (body) => {
  //   // const id = uuid();
  //   return new Promise((res, rej) => {
  //     S3.putObject(
  //       {
  //         Bucket: bucket,
  //         Key: "index.html",
  //         Body: body,
  //         ContentType: "text/html",
  //       },
  //       (err) => {
  //         if (err) {
  //           return rej(err);
  //         }
  //         return res({
  //           bucket: bucket,
  //           key: "index.html",
  //         });
  //       }
  //     );


  //   });
  // };

  // const uploadCSS = (body) => {
  //   // const id = uuid();
  //   return new Promise((res, rej) => {
  //     S3.putObject(
  //       {
  //         Bucket: bucket,
  //         Key: "styles.css",
  //         Body: body,
  //         // Body: new Buffer.from(
  //         //   body.replace(/^data:image\/\w+;base64,/, ""),
  //         //   "base64"
  //         // ),
  //         // ContentEncoding: "base64",
  //         ContentType: "text/css",
  //       },
  //       (err) => {
  //         if (err) {
  //           return rej(err);
  //         }
  //         return res({
  //           bucket: bucket,
  //           key: "styles.css",
  //         });
  //       }
  //     );
  //   });
  // };

  // https://stackoverflow.com/questions/19459893/how-to-create-folder-or-key-on-s3-using-aws-sdk-for-node-js/31728298
  // const uploadJS = (body) => {
  //   // const id = uuid();
  //   return new Promise((res, rej) => {
  //     S3.putObject(
  //       {
  //         Bucket: bucket,
  //         Key: "javascript.js",
  //         Body: body,
  //         ContentType: "text/js",
  //       },
  //       (err) => {
  //         if (err) {
  //           return rej(err);
  //         }
  //         return res({
  //           bucket: bucket,
  //           key: "javascript.js",
  //         });
  //       }
  //     );
  //   });
  // };


  useEffect(() => {

    //var closeButton = document.getElementById('exclusiveButton')
    // var closeButton2 = document.getElementById('closebutton2')
    //var myModal_ = document.getElementById('codeHTMLCSSModal')


    // closeButton2.addEventListener('click', function () {
    //   myModal.classList.toggle('show')
    // });


    // closeButton.addEventListener('click', function () {
    //   myModal_.style = "display: none;"
    //   myModal_.classList.toggle('show')
    // });

    function buildCodeEditor(type) {
      var codeEditor = editor.CodeManager.getViewer('CodeMirror').clone();
      codeEditor.set({
        codeName: type === 'html' ? 'htmlmixed' : 'css',
        readOnly: false,
        theme: 'hopscotch',
        autoBeautify: true,
        autoCloseTags: true,
        autoCloseBrackets: true,
        styleActiveLine: true,
        smartIndent: true,
      });
      return codeEditor;
    }

    function setupHtmlAutoUpdates(htmlCodeEditor) {
      function update() {
        const htmlCode = htmlCodeEditor.editor.getValue();
        if (!htmlCode) return;
        editor.setComponents(htmlCode);
      }
      var delay;
      htmlCodeEditor.editor.on('change', function () {
        clearTimeout(delay);
        delay = setTimeout(update, 300);
      });
      // htmlCodeEditor.editor.refresh();
    }

    function setupCssAutoUpdates(cssCodeEditor) {
      function update() {
        const cssCode = cssCodeEditor.editor.getValue();
        if (!cssCode) return;
        editor.setStyle(cssCode);
      }
      var delay;
      cssCodeEditor.editor.on('change', function () {
        clearTimeout(delay);
        delay = setTimeout(update, 300);
      });
    }

    const extractImage = (htmlBody) => {
      return new Promise(async (resolve, reject) => {
        var m,
          urls = [],
          str = htmlBody, // Aqui tem que definir que a tag é realmente de imagem
          rex = /src="(data:image\/[^;]+;base64[^"]+)"/g;

        while (m = rex.exec(str)) {
          let imagemComplement = new Date().getTime().toString();
          const image = await uploadImage(userAccount._id + imagemComplement, m[1], true);
          htmlBody = htmlBody.replace(m[1], 'https://' + image.bucket + '.s3.amazonaws.com/' + image.key + '?size=' + m[1].length);
        }
        resolve(htmlBody);
      });
    };

    const extractUrls = (htmlBody) => {
      return new Promise(async (resolve, reject) => {
        var m,
          urls = [],
          str = htmlBody,
          rex = /<img.*?src="([^">]*\/([^">]*?))".*?>/g;

        while (m = rex.exec(str)) {
          if (m[1].includes('tooweze') || m[1].includes('bash')) {
            urls.push({ url: m[1], size: m[1].split('size=')[1] ? parseInt(m[1].split('size=')[1]) : 1 });
          }
        }

        resolve(urls);

      });
    };

    // function getMeta(url) {
    //   var img = new Image();
    //   img.onload = function () {
    //     console.log('width: ', this.width);
    //     console.log('height: ', this.height);
    //     return this.height;
    //   };
    //   img.src = url;
    // }

    function buildCodePanel(panel) {
      const codePanel = document.createElement('div');

      codePanel.classList.add('code-panel');

      const htmlSection = document.createElement('section');
      const cssSection = document.createElement('section');
      htmlSection.innerHTML = '<div>HTML</div>';
      cssSection.innerHTML = '<div>CSS</div>';

      const htmlCodeEditor = buildCodeEditor('html');
      const cssCodeEditor = buildCodeEditor('css');
      const htmlTextArea = document.createElement('textarea');
      const cssTextArea = document.createElement('textarea');
      htmlSection.appendChild(htmlTextArea);
      cssSection.appendChild(cssTextArea);

      codePanel.appendChild(htmlSection);
      codePanel.appendChild(cssSection);

      // document.getElementById('htmlTextModal').appendChild(htmlSection)

      // panel.set('appendContent', codePanel).trigger('change:appendContent');

      document.getElementById('htmlTextModal').appendChild(codePanel);

      htmlCodeEditor.init(htmlTextArea);
      cssCodeEditor.init(cssTextArea);
      // htmlCodeEditor.setContent(document.getElementById('myModal'));
      // cssCodeEditor.setContent(document.getElementById('myModal'));

      htmlCodeEditor.setContent(editor.getHtml());
      cssCodeEditor.setContent(editor.getCss({ avoidProtected: true }));

      htmlSection.style = {
        direction: 'vertical',
        sizes: [50, 50],
        minSize: 100,
        gutterSize: 2,
      };

      cssSection.style = {
        direction: 'vertical',
        sizes: [50, 50],
        minSize: 100,
        gutterSize: 2,
      };

      htmlSection.addEventListener('onDragEnd', () => {
        htmlCodeEditor.editor.refresh();
        cssCodeEditor.editor.refresh();
      }, false);

      cssSection.addEventListener('onDragEnd', () => {
        htmlCodeEditor.editor.refresh();
        cssCodeEditor.editor.refresh();
      }, false);


      setupHtmlAutoUpdates(htmlCodeEditor);
      setupCssAutoUpdates(cssCodeEditor);

      // make sure editor is aware of width change after the 300ms effect ends
      setTimeout(() => {
        htmlCodeEditor.editor.refresh();
        cssCodeEditor.editor.refresh();
      }, 320);

      return codePanel;
    }


    grapesjs.plugins.add("gjs-preset-webpage", (editor, opts = {}) => {
      let config = opts;

      let defaults = {
        // Which blocks to add
        blocks: ["link-block", "quote", "text-basic"],

        // Modal import title
        modalImportTitle: "Import",

        // Modal import button text
        modalImportButton: "Import",

        // Import description inside import modal
        modalImportLabel: "",

        // Default content to setup on import model open.
        // Could also be a function with a dynamic content return (must be a string)
        // eg. modalImportContent: editor => editor.getHtml(),
        modalImportContent: "",

        // Code viewer (eg. CodeMirror) options
        importViewerOptions: {},

        // Confirm text before cleaning the canvas
        textCleanCanvas: "Are you sure to clean the canvas?",

        // Show the Style Manager on component change
        showStylesOnChange: 1,

        // Text for General sector in Style Manager
        textGeneral: "General",

        // Text for Layout sector in Style Manager
        textLayout: "Layout",

        // Text for Typography sector in Style Manager
        textTypography: "Typography",

        // Text for Decorations sector in Style Manager
        textDecorations: "Decorations",

        // Text for Extra sector in Style Manager
        textExtra: "Extra",

        // Use custom set of sectors for the Style Manager
        customStyleManager: [],

        // `grapesjs-blocks-basic` plugin options
        // By setting this option to `false` will avoid loading the plugin
        blocksBasicOpts: {},

        // `grapesjs-navbar` plugin options
        // By setting this option to `false` will avoid loading the plugin
        navbarOpts: {},

        // `grapesjs-component-countdown` plugin options
        // By setting this option to `false` will avoid loading the plugin
        countdownOpts: {},

        // `grapesjs-plugin-forms` plugin options
        // By setting this option to `false` will avoid loading the plugin
        formsOpts: {},

        // `grapesjs-plugin-export` plugin options
        // By setting this option to `false` will avoid loading the plugin
        exportOpts: {
          filenamePfx: "tooweze",
        },

        // `grapesjs-aviary` plugin options, disabled by default
        // Aviary library should be included manually
        // By setting this option to `false` will avoid loading the plugin
        aviaryOpts: 0,

        // `grapesjs-plugin-filestack` plugin options, disabled by default
        // Filestack library should be included manually
        // By setting this option to `false` will avoid loading the plugin
        filestackOpts: 0,
      };

      // Load defaults
      for (let name in defaults) {
        if (!(name in config)) config[name] = defaults[name];
      }

      const {
        blocksBasicOpts,
        navbarOpts,
        countdownOpts,
        formsOpts,
        exportOpts,
        aviaryOpts,
        filestackOpts,
      } = config;

      // Load plugins
      blocksBasicOpts && pluginBlocks(editor, blocksBasicOpts);
      navbarOpts && pluginNavbar(editor, navbarOpts);
      countdownOpts && pluginCountdown(editor, countdownOpts);
      formsOpts && pluginForms(editor, formsOpts);
      exportOpts && pluginExport(editor, exportOpts);
      // aviaryOpts && pluginAviary(editor, aviaryOpts);
      filestackOpts && pluginFilestack(editor, filestackOpts);

      // Load components
      components(editor, config);

      // Load blocks
      blocks(editor, config);

      // Load commands
      commands(editor, config);

      // Load panels
      panels(editor, config);

      // Load styles
      styles(editor, config);
    });

    editor = grapesjs.init({
      height: "100vh",
      showOffsets: 1,
      noticeOnUnload: 0,
      storageManager: {
        id: "gjs-", // Prefix identifier that will be used inside storing and loading
        type: "local", // Type of the storage
        autosave: true, // Store data automatically
        autoload: true, // Autoload stored data on init
        stepsBeforeSave: 1, // If autosave enabled, indicates how many changes are necessary before store method is triggered
        storeComponents: true, // Enable/Disable storing of components in JSON format
        storeStyles: true, // Enable/Disable storing of rules in JSON format
        storeHtml: true, // Enable/Disable storing of components as HTML string
        storeCss: true, // Enable/Disable storing of rules as CSS string
      },
      container: "#gjs",
      fromElement: true,

      plugins: [
        "gjs-preset-webpage",
        "gjs-blocks-basic",
        tUIImageEditor,
        // "gjs-plugin-ckeditor",
      ],
      pluginsOpts: {
        "gjs-preset-webpage": {},
        // "gjs-plugin-ckeditor": {
        //   /* ...options */
        // },
        [tUIImageEditor]: {
          includeUI: {
            initMenu: "filter",
          },
          icons: {
            "menu.normalIcon.path": iconD,
            "menu.activeIcon.path": iconB,
            "menu.disabledIcon.path": iconA,
            "menu.hoverIcon.path": iconC,
            "submenu.normalIcon.path": iconD,
            "submenu.activeIcon.path": iconC,
          },

          // Scripts to load dynamically in case no TOAST UI editor instance was found
          script: [
            // tUIImageEditor,
            // "https://cdnjs.cloudflare.com/ajax/libs/fabric.js/4.2.0/fabric.min.js",
            "https://uicdn.toast.com/tui.code-snippet/v1.5.0/tui-code-snippet.min.js",
            "https://uicdn.toast.com/tui-color-picker/v2.2.0/tui-color-picker.min.js",
            "https://cdnjs.cloudflare.com/ajax/libs/tui-image-editor/3.15.0/tui-image-editor.min.js",
          ],

          // In case the script is loaded this style will be loaded too
          style: [
            "https://uicdn.toast.com/tui-color-picker/v2.2.0/tui-color-picker.min.css",
            "https://cdnjs.cloudflare.com/ajax/libs/tui-image-editor/3.15.0/tui-image-editor.css",
          ],
        },
      },
      commands: {
        defaults: [
          {
            id: 'mvc_',
            run: function (e, senderBtn) {

              setReadyToGo(false);

              let bodyHTML = e.getHtml();

              try {
                extractImage(bodyHTML).then(htmlBody => {
                  extractUrls(htmlBody).then(urls => {
                    updateLandinPage(landingPageId, bodyHTML, e.getCss(), urls, false).then(res => {
                      if (res) {
                        setHtmlBody(null);
                        setBodyCSS(null);
                        window.location.reload();
                      }
                    }).catch(err => console.log("Erro gravando", err));
                  });

                }).catch(err => console.log('err ===>', err));
              } catch (error) {
                console.log('error', error);
              }

            },
            stop: function (editor, senderBtn) {
              setReadyToGo(true);
            }
          },
          {
            id: "mvc",
            run: function (e, senderBtn) {

              // Publish
              try {
                setReadyToGo(false);
                extractImage(e.getHtml()).then(htmlBody => {
                  extractUrls(htmlBody).then(urls => {
                    updateLandinPage(landingPageId, e.getHtml(), e.getCss(), urls, true).then(res => {
                      setReadyToGo(true);
                      if (res) {
                        setHtmlBody(null);
                        setBodyCSS(null);
                        window.open(
                          `https://${res.bucket}.s3.amazonaws.com/index.html?` + new Date().getMilliseconds()
                        );
                      }
                      window.location.reload();
                    }).catch(err => {
                      setReadyToGo(true);
                      console.log("Erro gravando", err)
                    });
                  });

                }).catch(err => {
                  setReadyToGo(true);
                  console.log('err ===>', err)
                });
              } catch (error) {
                setReadyToGo(true);
                console.log('error', error);
              }
              // Publish

              // getLandingPages(landingPageId).then(result => {

              //   let scritps;

              //   if (result.scripts.length > 0) {
              //     scritps += result.scripts.forEach(script => script);
              //   }

              //   let bodyHTML = `<!doctype html>
              //       <html lang="en">
              //         <head>
              //           <meta charset="utf-8">
              //           <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
              //           <link rel="stylesheet" href="styles.css">
              //           ${result.scripts ? he.decode(result.scripts.join('\n')) : ""}
              //           </head>
              //         <body>${he.decode(result.html)}
              //         </body>
              //       <html>`;

              //   upload(bodyHTML)
              //     .then((res) => console.log("res HTML ====>"))
              //     .catch((err) => console.log("err HTML ====>", err));
              //   //scripts.js
              //   // uploadJS(e.getJs())
              //   //   .then((res) => {
              //   //     console.log("res JS ====>", res);
              //   //   })
              //   //   .catch((err) => console.log("err JS ====>", err));
              //   uploadCSS(result.css)
              //     .then((res) => {
              //       console.log("res CSS ====>");
              //       window.open(
              //         `https://${bucket}.s3.amazonaws.com/index.html?` + new Date().getMilliseconds()
              //       );
              //     })
              //     .catch((err) => console.log("err CSS ====>", err));

              // }).catch(err => {
              //   console.log('getLandingpage err', err);
              // });

            },
            context: "mvc",
            className: "smallbtn btn btn-primary",
            label: "Publicar",

            // stop: function (editor, senderBtn) {
            //   setReadyToGo(true);
            // }
          },
          {
            id: 'open-code',
            run: function (editor, senderBtn) {
              const pn = editor.Panels;
              // const id = 'views-container';
              const id = 'codeHTMLCSSModal';
              const panel = pn.getPanel(id) || pn.addPanel({ id });
              if (!this.codePanel) this.codePanel = buildCodePanel(panel);
              this.codePanel.style.display = 'block';
              // editor.$('.gjs-pn-views-container').get(0).style.width = '35%';
              // editor.$('.gjs-cv-canvas').get(0).style.width = '65%';
              var myModal = document.getElementById('codeHTMLCSSModal');
              myModal.style = "display: block";
              myModal.classList.add('show');
            },
            stop: function (editor, senderBtn) {
              if (this.codePanel) this.codePanel.style.display = 'none';

              // // editor.$('.gjs-pn-views-container').get(0).style.width = '15%';
              // // editor.$('.gjs-cv-canvas').get(0).style.width = '85%';
              var myModal = document.getElementById('codeHTMLCSSModal');
              myModal.style = "display: none";
              myModal.classList.remove('show');

              // const pn = editor.Panels;
              // // const id = 'views-container';
              // const id = 'codeHTMLCSSModal';
              // const panel = pn.getPanel(id) || pn.addPanel({ id });
              // if (!this.codePanel) this.codePanel = buildCodePanel(panel)
              // this.codePanel.style.display = 'none';
              // // editor.$('.gjs-pn-views-container').get(0).style.width = '35%';
              // // editor.$('.gjs-cv-canvas').get(0).style.width = '65%';
              // var myModal = document.getElementById('codeHTMLCSSModal')
              // myModal.style = "display: none"
              // myModal.classList.toggle('show')

            },
          }
        ]
      },
      i18n: {
        locale: "pt", // default locale
        detectLocale: true, // by default, the editor will detect the language
        localeFallback: "pt", // default fallback
        messages: { pt },
      },
    });


    // closeButton.addEventListener('click', function () {
    //   //   // myModal.classList.toggle('show')
    //   document.getElementById('codeHTMLCSSModal').style = "display: none"
    //   editor.refresh();
    // });

    var blockManager = editor.BlockManager;
    blockManager.get("image").set({
      content: `<div data-custom-attr="1">...</div>`,
    });


    // This is our custom script (avoid using arrow functions)
    // const script = function () {
    //   // alert("Hi");
    //   // `this` is bound to the component element
    //   this.innerHTML = `
    //   <!-- Begin page -->
    //   <div class="">
    //     <div class="content">
    //       <!-- Start Content-->
    //       <div class="" sytle="padding: 15px;">
    //         <div class="row" style="margin: 0px 5px 0px 5px">
    //           <div class="col-md-4 col-lg-4"></div>
    //           <div id="danger" class="alert alert-danger" role="alert" style="display: none;">
    //             <strong></strong>
    //           </div>
    //           <div id="success" class="alert alert-success" role="alert" style="display: none;">
    //             <strong></strong>
    //           </div>
    //           <div id="mycard" class="col-md-4 col-xl-4" style="padding: 15px;">
    //           <!-- Simple card -->
    //           <div class="card d-block" style="padding: 20px;">
    //           <img class="card-img-top" src="https://coupon-images-bash.s3.amazonaws.com/60a812b5e734730015034ae0.jpg" alt="Coupon" style="border-radius: 10px;">
    //           <div class="card-body" style="padding: 10px">
    //           <div class="form-group mb-3"><label id="lbl_name" for="txt_name">Nome<span style="color: red">*</span>:</label><input type="text" id="txt_name" class="form-control"></div><div class="form-group mb-3"><label id="lbl_email" for="txt_email">E-mail<span style="color: red">*</span>:</label><input type="text" id="txt_email" class="form-control"></div>
    //           <label id="atention"><strong>Atenção:</strong> 
    //           O Código do Cupom será enviado para seu e-mail! 
    //           O remetente é: <strong>todos@gnxp.com.br</strong> - Verifique na caixa anti-spam
    //           e coloque este como rementente confiável.</label>
    //           <p></p>
    //           <a id="btn_createCode" onclick="myFunction()" data-id="60a812b5e734730015034ae0" data-rootid="7f9e693c-6e7b-5d58-b3aa-fbec91078013" data-campaignid="60a9655568901500155d892a" href="" class="btn btn-primary">Gerar Código Desconto</a>
    //           <p></p>
    //           <p style="color: red; font-weight: bolder; text-align: center;" id="p_text"></p>
    //           <h5 id="title" class="card-title">Not Set</h5>
    //           <p id="description" class="card-text"></p>
    //           <p id="condition" class="card-text"></p>
    //           </div>
    //           </div></div>
    //           <div class="col-md-4 col-lg-4"></div>
    //         </div>
    //       </div>
    //     </div>
    //     <!-- content -->
    //   </div>`;
    //   // console.log("the element", this);
    // };

    // // Define a new custom component
    // editor.Components.addType("comp-with-js", {
    //   model: {
    //     defaults: {
    //       script,
    //       // Add some style, just to make the component visible
    //       // style: {
    //       //   width: "450px",
    //       //   height: "350px",

    //       //   // background: "red",
    //       // },
    //     },
    //   },
    // });

    // Create a block for the component, so we can drop it easily
    editor.Blocks.add("leadForm-block", {

      label: i18n.t("landingpagemodule.createpage.includeform"),
      attributes: { class: "fa fa-text", style: "background-color: rgb(255, 182, 0)" },
      content: `
      <div class="container p-3" style="width: 50%;">
      <div class="form-group">
        <label for="inputName">Nome: <span style="color: red">*</span></label>
        <input type="text" class="form-control" id="inputName" aria-describedby="emailHelp" placeholder="Nome">
      </div>
      <div class="form-group">
        <label for="inputEmail">Email: <span style="color: red">*</span></label>
        <input type="text" class="form-control" id="inputEmail" aria-describedby="emailHelp" placeholder="Email">
        <span id="validationEmail" style="display: none;">E-mail Inválido!</span>
      </div>
      <div class="form-group">
        <label for="inputMobil">Celular/WhatsUp: <span style="color: red">*</span></label>
        <input type="text" class="form-control" id="inputMobil" aria-describedby="emailHelp" placeholder="Celular">
      </div>
      <div class="form-group">
        <label for="inputMessage">Mensagem</label>
        <input type="text" class="form-control" id="inputMessage" aria-describedby="emailHelp" placeholder="Mensagem">
      </div>
      <button onclick="leadRegister('${landingPageId}')">Enviar</button>
      <span id="warning" style="color: red; display: none">Preencha corretamente todos os campos.</span>
    </div>
      `,
    });

  }, [editor, htmlBody]);

  useEffect(() => {
    if (editor) {
      editor.setComponents(htmlBody);
      editor.setStyle(bodyCSS);
      editor.refresh();
      // html = editor.getHtml();
    }
  }, [editor, htmlBody, landingPageId]);


  if (readyToGo === false) {
    return <Spinner />;
  } else {
    return (
      // <div style={{ width: "120%" }}>
      //   <div className="panel__top">
      //     <div className="panel__basic-actions"></div>
      //     <div className="panel__devices"></div>
      //     <div className="panel__switcher"></div>
      //   </div>
      //   <div className="editor-row">
      //     <div className="editor-canvas container">
      <>

        <div id="gjs" style={{ width: "150%", paddingBottom: "20px" }}></div>

        {/* https://jsfiddle.net/szLp8h4n/189/ */}


        {/* <div className="modal" id="codeHTMLCSSModal" tabindex="-1" aria-labelledby="codeHTMLCSSModalTitle" aria-hidden="true" draggable="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="codeHTMLCSSModalTitle">HTML / CSS</h5>
                <button id="closebutton2" type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ height: '650px' }}>
                <div id="htmlTextModal" style={{ height: '600px' }}></div>
              </div>
              <div className="modal-footer">
                <button id="closebutton" type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div> */}

        <ModalLanding
          modalId="codeHTMLCSSModal"
          title=""
          h5Content=""
          content={<div id="htmlTextModal" style={{ height: '600px', width: '100%' }}></div>}
        />

      </>



      //     </div>
      //     <div className="panel__right">
      //       <div className="layers-container"></div>
      //       <div className="styles-container"></div>
      //       <div className="traits-container"></div>
      //     </div>
      //   </div>
      //   <div id="blocks"></div>
      // </div>
    );
  }


};

export default CreatePageMiddle;
