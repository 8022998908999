import React, { Component } from "react";
import { connect } from "react-redux";
import updateFontSize from "../../../actions/updateFontSize";
import updateTopBar from "../../../actions/updateTopBar";
import { bindActionCreators } from "redux";

class FontSizeComponent extends Component {
  // Altera a fonte do Texto Selecionado
  textFontSizeChange(e) {
    this.props.updateFontSize(e.target.value, 0);
    this.props.updateTopBar("textSize", 0, e.target.value);
  }

  render() {
    const fontFamily = [
      {
        fontSize: "14",
      },
      {
        fontSize: "16",
      },
      {
        fontSize: "18",
      },
      {
        fontSize: "20",
      },
      {
        fontSize: "22",
      },
      {
        fontSize: "24",
      },
      {
        fontSize: "26",
      },
      {
        fontSize: "28",
      },
      {
        fontSize: "36",
      },
      {
        fontSize: "48",
      },
      {
        fontSize: "72",
      },
    ];

    const fontSizeElements = fontFamily.map((item, index) => {
      return (
        <option key={index} value={item.fontSize}>
          {item.fontSize}
        </option>
      );
    });

    return (
      <div id="1" style={{ marginLeft: "10px", marginTop: "-3px" }}>
        <select
          id="fontSize"
          className="form-control"
          style={{ fontSize: "11px", width: "65px", fontWeight: "bold" }}
          onChange={(e) => this.textFontSizeChange(e)}
        >
          {fontSizeElements}
        </select>
      </div>
    );
  }
}

function mapStateToProps(state) {
  // mapStateToProps returns an object, with:
  // property is the local prop name to this component
  // value will be the property in the root reducer... ie, piece of the store
  return {
    couponNavBarData: state.couponNavBar,
    canvas: state.canvas,
    fontControl: state.fontControl,
  };
}

// mapDispatchToProps is how we tie our component to the dispatch
// it take one args: dispatch
function mapDispatchToProps(dispatch) {
  // this function return bindActionCreators
  // and we hand bindActionCreators an object
  // each property will be a local prop
  // each value will be a function that is dispatch when run
  // second arg of bindActionCreators is the dispatch
  return bindActionCreators(
    {
      updateFontSize: updateFontSize,
      updateTopBar: updateTopBar,
    },
    dispatch
  );
}

// export default FontControl;
export default connect(mapStateToProps, mapDispatchToProps)(FontSizeComponent);
