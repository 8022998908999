const AWS = require("aws-sdk");

AWS.config = {
  accessKeyId: "AKIA5UH2UFRKK56K6FJP",
  secretAccessKey: "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",
  region: "us-east-1",
};

const BUCKET = 'twz-qyontecnologia7';
const BUCKET_THUMB = "coupon-images-tooweze-thumb";
const translate = new AWS.Translate();
const S3 = new AWS.S3();

const translateWords = (word, sourceLang, targetLang) => {
  return new Promise((resolve, reject) => {
    var params = {
      SourceLanguageCode: sourceLang /* required */,
      TargetLanguageCode: targetLang /* required */,
      Text: word /* required */,
    };

    translate.translateText(params, function (err, data) {
      if (err) reject(err);
      // an error occurred
      else resolve(data.TranslatedText); // successful response
    });
  });
};

/// Da linguagem do navegador para inglês, usando na busca.
const translateOneWord = (word) => {
  return new Promise((result, reject) => {
    let sourceLang;
    switch (localStorage.getItem("i18nextLng")) {
      case "pt-BR":
        sourceLang = "pt";
        break;
      case "en-US":
        sourceLang = "en";
        break;
      case "es":
        sourceLang = "es";
        break;
      default:
        sourceLang = "pt";
    }
    try {
      translateWords(word, sourceLang, "en").then((res) => {
        result(res);
      });
    } catch (error) {
      reject(error);
    }
  });
};

/// Traduz tudo do inglês para a linguagem do navegado.
const translateOneWordToYourLanguage = (word) => {
  return new Promise((result, reject) => {
    let toLang;
    switch (localStorage.getItem("i18nextLng")) {
      case "pt-BR":
        toLang = "pt";
        break;
      case "en-US":
        toLang = "en";
        break;
      case "es":
        toLang = "es";
        break;
      default:
        toLang = "pt";
    }
    try {
      translateWords(word, "en", toLang).then((res) => {
        result(res);
      });
    } catch (error) {
      reject(error);
    }
  });
};

const systemTranslate = (words) => {
  return new Promise((result, reject) => {
    let targetLang;
    switch (localStorage.getItem("i18nextLng")) {
      case "pt-BR":
        targetLang = "pt";
        break;
      case "en-US":
        targetLang = "en";
        break;
      case "es":
        targetLang = "es";
        break;
      default:
        targetLang = "pt";
    }

    try {
      let count = words?.length;
      const labelsArray = [];
      words.forEach((item) => {
        translateWords(item, "en", targetLang).then((res) => {
          labelsArray.push(res);
        });
        count = count - 1;
        if (count === 0) {
          result(labelsArray);
        }
      });
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

const uploadImage = (id, body, thumb) => {
  return new Promise((res, rej) => {
    S3.putObject(
      {
        Bucket: thumb && thumb === true ? BUCKET_THUMB : BUCKET,
        Key: id + ".jpg",
        Body: new Buffer.from(
          body.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        ),
        ContentEncoding: "base64",
        ContentType: "image/jpeg",
      },
      (err) => {
        if (err) {
          return rej(err);
        }
        return res({
          bucket: thumb && thumb === true ? BUCKET_THUMB : BUCKET,
          key: id + ".jpg",
        });
      }
    );
  });
};

module.exports = {
  systemTranslate: systemTranslate,
  translateOneWord: translateOneWord,
  translateOneWordToYourLanguage: translateOneWordToYourLanguage,
  uploadImage: uploadImage
};
