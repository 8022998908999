import axios from "axios";
import { urlAPI } from "./apiBase";
let ip = {};
let temp = {};
const apiKey = "09ae85bf177a8c7b4b2753f575f1445184545206e24e9474f17714ee";
const temperatureKey = "7d0a385cadf9bd21765f8b716f545d48";

export default async function updateEvent(eventId) {
  ip = await getIP();
  temp = await getTemp(ip.latitude, ip.longitude);
  return new Promise((resolve, reject) => {
    if (eventId) {
      return axios({
        method: "PUT",
        url: `${urlAPI.events}/operator/${eventId}`,
        headers: {
          Accept: "application/json",
          "x-auth-token": localStorage.getItem("tooweze_token"),
        },
        data: {
          ip: ip,
          temperature: temp,
        },
      }).then((res) => {
        resolve(res);
      });
    }
  });
}

const getIP = () => {
  return new Promise((resolve, reject) => {
    return axios({
      method: "GET",
      url: `https://api.ipdata.co/?api-key=${apiKey}`,
      headers: {
        Accept: "application/json",
      },
    }).then((res) => {
      resolve(res.data);
    });
  });
};
const getTemp = (latitude, longitude) => {
  return new Promise((resolve, reject) => {
    return axios({
      method: "GET",
      url: `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${temperatureKey}`,
      headers: {
        Accept: "application/json",
      },
    }).then((res) => {
      resolve(res.data);
    });
  });
};
