import React, { Component } from "react";
import { Redirect } from "react-router";
import "./ViewLandingPagesMiddle.css";
import { getLandingPages, getLandingPagesTemplates } from "../../../apis/toowezeApi";
import { connect } from "react-redux";
import updateViewLandingPageMenu from "../../../actions/updateViewLandingPageMenu";
import { bindActionCreators } from "redux";
// import queryString from "query-string";
import Spinner from "../../LayOut/Spinner";
import ModalPreview from "../../ModalPreview";
import HtmlEditor, { MediaResizing } from "devextreme-react/html-editor";
// import letsGoGif from "../../../images/letsGo.gif";
import { i18n } from "../../../translate/i18n";
import he from 'he';
// import { getLandingPage, getLandingPages, saveLandinPage, updateLandinPage } from '../../../apis/toowezeApi';

class ViewLandingPagesMiddle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLands: [],
            allYourLands: [],
            allLandsBody: null,
            allYourLandsBody: null,
            showImage: "https://coupon-images-tooweze-thumb.s3.amazonaws.com/blank_image_landingpage.png",
            clickedClass: "",
            spinner: true,
            timestamp: Date.now(),
            changeModal: ""
        };
    }


    // Aciona o Action que passa as informações para o component CouponBuilderSupportMenu
    // Neste caso ao clicar no cupom, ele irá ser detalhado no Support Menu
    addElement = (element, type) => {
        // return <Redirect push to={`/createpage?id=${element}`} />;
        this.props.updateViewLandingPageMenu(element, 0, type);
    };

    modalPreview = (event) => {
        // Buscar o html usando o id
        this.setState({
            changeModal: ""
        });
        getLandingPagesTemplates(event).then(landingPage => {
            this.setState({
                changeModal: landingPage.url
            });
        }).catch(err => console.log("Erro lendon landingPage", err))
    }


    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true;
        }

        // Atualiza os cupons depois de ter sido deletado no component ViewCouponSupportMenu
        // updateViewCouponMiddle
        if (this.props.viewLandingPageMenuReducer !== nextProps.viewLandingPageMenuReducer) { // / Lê todas Landings Pages
            if (this.props.viewLandingPageMenuReducer[0].extra === "updateViewLandingPageMenu") {
                this.setState({ allLands: [], allLandsBody: null, allYourLandsBody: null, allYourLands: [] });
                this.readLandingPagesStartPage();
                this.readAllYourLandsBodyStartPage()
                return true;
            }
        }

        return true;
    }

    componentDidMount() { // / Lê todas Landings Pages
        this.readLandingPagesStartPage();
        this.readAllYourLandsBodyStartPage()
    }

    // / Lê todas Landings Pages Templastes
    readLandingPagesStartPage() {
        let lands = [];
        let allLands = [];

        lands.push(getLandingPagesTemplates());

        Promise.all(lands).then((data) => {
            if (data.length > 0) {
                data.forEach((land) => {
                    if (land.length > 0) { // Evitando erro quando uma busca retorna vazia.
                        allLands.push(land);

                    }
                });
            }

            allLands.push([{
                "defaultImage": [{ url: "https://coupon-images-tooweze-thumb.s3.amazonaws.com/blank_image_landingpage.png", size: 1 }],
                "status": 0,
                "createdAt": 1635544021,
                "_id": -1,
                "title": "Página em Branco",
                "pageTitle": "Title",
                "bucket": "",
                "defaultUrl": "defaultUrl",
                "domain": "domain",
            }]);

            allLands.reverse();

            if (this.state.allLands.length == 0) {
                this.setState({ allLands: allLands });
            }

        }).catch((err) => console.log("erro **********", err));

        // Busca o cupom caso esteja vindo de outra página indicando o id na url
        // this.getLandingFromId();
    }


    readAllYourLandsBodyStartPage() {
        let lands = [];
        let allYourLands = [];


        lands.push(getLandingPages());

        Promise.all(lands).then((data) => {
            if (data.length > 0) {
                data.forEach((land) => {
                    if (land.length > 0) { // Evitando erro quando uma busca retorna vazia.
                        allYourLands.push(land);
                    }
                });
            }

            allYourLands.reverse();

            if (this.state.allYourLands.length == 0) {
                this.setState({ allYourLands: allYourLands });
            }
        }).catch((err) => console.log("erro **********", err));

        // Busca o cupom caso esteja vindo de outra página indicando o id na url
        // this.getLandingFromId();
    }

    render() {


        // Bloco template
        if (this.state.allLands.length > 0) {
            this.state.allLandsBody = this.state.allLands.map((element) => {
                return element.map((land) => {

                    {
                        if (land?.defaultImage) {
                            if (land.defaultImage.length > 1) {
                                const maxSize = land.defaultImage.reduce((acc, shot) => acc = acc > shot?.size ? acc : shot?.size, 0);

                                for (let i = 0; i < land.defaultImage.length; i++) {
                                    if (land.defaultImage[i]?.size === maxSize)
                                        land.showImage = land.defaultImage[i]?.url
                                }
                            } else {
                                if (land.defaultImage[0]?.url) {
                                    land.showImage = land.defaultImage[0]?.url
                                }
                            }
                        }
                    }

                    return (


                        <div key={
                            land._id
                        }
                            className="col-md-6 col-xl-6"
                            style={
                                {
                                    float: "left",
                                    padding: "3px"
                                }
                            }

                        >
                            <div id={
                                land._id
                            }
                                className={
                                    "d-block shadow bg-white borderClass " + this.state.clickedClass
                                }
                                onClick={
                                    (event) => {
                                        this.addElement(land._id, 'template');
                                    }
                                }
                                style={{
                                    minHeight: '250px', zIndex: 50,
                                    backgroundImage: `url(${land?.showImage ? land?.showImage : "https://coupon-images-tooweze-thumb.s3.amazonaws.com/blank_image_landingpage.png"
                                        })`, backgroundSize: 'cover'
                                }}>
                                <div className="">
                                    <div className="input-group-append" style={{ height: "28px", float: "right" }}>
                                        <div className="widget-container" style={{ backgroundColor: 'white', borderRadius: '6px', padding: '3px' }}>
                                            {land.title}</div></div>
                                </div>
                                <div className="" style={{ height: "28px", float: "left", display: "flex" }}>
                                    <div
                                        data-toggle="modal"
                                        data-target="#preview-header-modal"
                                        data-id={land._id ? land._id : null}
                                        onClick={
                                            (event) => {
                                                this.modalPreview(land._id)
                                            }
                                        } className="" style={{ backgroundColor: 'white', borderRadius: '6px', padding: '3px', position: "absolute", bottom: "10px", zIndex: 100 }}>
                                        <span>{i18n.t("mylandingpages.view.preview")}</span></div></div>
                            </div>
                        </div>
                    );
                });
            });
        } else {
            // Apresenta mensagem
            if (this.state.spinner === true) {
                this.setState({ spinner: false });
            }
        }

        if (this.state.allLandsBody) {
            if (this.state.allLandsBody.length > 0 && this.state.spinner === true) {
                this.setState({ spinner: false });
            }
        }

        // Bloco Landing Pages Proprias
        if (this.state.allYourLands.length > 0) {
            this.state.allYourLandsBody = this.state.allYourLands.map((element) => {
                return element.map((land) => {
                    {
                        if (land?.defaultImage) {
                            if (land.defaultImage.length > 1) {
                                const maxSize = land.defaultImage.reduce((acc, shot) => acc = acc > shot?.size ? acc : shot?.size, 0);

                                for (let i = 0; i < land.defaultImage.length; i++) {
                                    if (land.defaultImage[i]?.size === maxSize)
                                        land.showImage = land.defaultImage[i]?.url
                                }
                            } else {
                                if (land.defaultImage[0]?.url) {
                                    land.showImage = land.defaultImage[0]?.url
                                }
                            }
                        }
                    }

                    return (

                        <div key={
                            land._id
                        }
                            className="col-md-6 col-xl-6"
                            style={
                                {
                                    float: "left",
                                    padding: "3px"
                                }
                            }
                            onClick={
                                (event) => {
                                    this.addElement(land._id, 'file');
                                }
                            }>
                            <div id={
                                land._id
                            }
                                className={
                                    "d-block shadow bg-white borderClass " + this.state.clickedClass
                                }
                                style={{
                                    minHeight: '250px',
                                    backgroundImage: `url(${land?.showImage ? land?.showImage : "https://coupon-images-tooweze-thumb.s3.amazonaws.com/blank_image_landingpage.png"
                                        })`, backgroundSize: 'cover'
                                }}>
                                <div className="" style={{ height: "28px", float: "right", display: "contents" }}>
                                    <div className="" style={{ backgroundColor: 'white', borderRadius: '6px', padding: '3px' }}>
                                        {land.title}</div></div>
                                <div className="" style={{ height: "28px", float: "left", display: "flex" }}>
                                    <div
                                        data-toggle="modal"
                                        data-target="#preview-header-modal"
                                        data-id={land._id ? land._id : null}
                                        onClick={
                                            (event) => {
                                                this.setState({
                                                    changeModal: "https://" + land.defaultUrl + "/index.html"
                                                })
                                            }
                                        } className="" style={{ backgroundColor: 'white', borderRadius: '6px', padding: '3px', position: "absolute", bottom: "10px", zIndex: 100 }}>
                                        <span>{i18n.t("mylandingpages.view.preview")}</span></div></div>
                            </div>
                        </div>
                    );
                });
            });
        } else {
            // Apresenta mensagem
            if (this.state.spinner === true) {
                this.setState({ spinner: false });
            }
        }

        if (this.state.allYourLandsBody) {
            if (this.state.allYourLandsBody.length > 0 && this.state.spinner === true) {
                this.setState({ spinner: false });
            }
        }

        if (this.state.spinner === true) {
            return <Spinner />;
        }

        return (
            <>
                <ModalPreview
                    modalId="preview-header-modal"
                    title={i18n.t("mylandingpages.view.title")}
                    h5Content=""
                    content={this.state.changeModal}
                    cancelText={i18n.t("mylandingpages.view.cancel")}
                    okText={i18n.t("mylandingpages.view.modalok")}
                    actionType="openpreview"
                    extra="preview"
                />
                <div className="row"
                    style={
                        { overflowX: 'hidden' }
                    }>
                    <div className="col-md-6 col-xl-6 content"
                        style={
                            {
                                overflow: 'scroll',
                                height: '100vh'
                            }
                        }>
                        <div style={
                            { display: 'block' }
                        }>
                            <h3>{i18n.t("mylandingpages.view.template")}</h3>
                        </div>

                        {
                            this.state.allLandsBody
                        } </div>
                    <div className="col-md-6 col-xl-6 content"
                        style={
                            {
                                overflow: 'scroll',
                                height: '100vh'
                            }
                        }>
                        <div style={
                            { display: 'block' }
                        }>
                            <h3>{i18n.t("mylandingpages.view.yourpages")}</h3>
                        </div>

                        {

                            this.state.allYourLandsBody
                        }
                    </div>

                </div>

            </>
        );
    }
}

// mapStateToProps takes one args, "state" and that is the rootReducer/Store
function mapStateToProps(state) {
    // mapStateToProps returns an object, with:
    // property is the local prop name to this component
    // value will be the property in the root reducer... ie, piece of the store
    return { // modalControl: state.modalControl,
        viewLandingPageMenuReducer: state.viewLandingPageMenuReducer
    };
}

// mapDispatchToProps is how we tie our component to the dispatch
// it take one args: dispatch
function mapDispatchToProps(dispatch) {
    // this function return bindActionCreators
    // and we hand bindActionCreators an object
    // each property will be a local prop
    // each value will be a function that is dispatch when run
    // second arg of bindActionCreators is the dispatch
    return bindActionCreators({
        updateViewLandingPageMenu: updateViewLandingPageMenu
    }, dispatch);
}

// // export default TopBar;
// // connect take 2 args, the first one is a function that is going to map
// // a piece of redux state to this components props
export default connect(mapStateToProps, mapDispatchToProps)(ViewLandingPagesMiddle);
