import React, { Component } from "react";
import "./Auth.css";
import logo from "../../images/complet_logo_2.png";
import notify from "devextreme/ui/notify";
import { validateAllSenders } from "../../apis/toowezeApi";
import { Link } from "react-router-dom";
import { i18n } from "../../translate/i18n";

export default class ConfirmAllEmails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photo: "1",
    };
  }

  componentWillMount() {
    document.body.style.backgroundColor = "white";
    // Limpa as informações de Login
    localStorage.removeItem("auth");
    localStorage.removeItem("tooweze_token");
    localStorage.removeItem("isConfigured");
    // Limpa as informações de Login

    this.setState({
      photo: Math.round(Math.random() * 8).toString(),
    });

  }

  componentDidMount() {
    /// Verifica se já foi validada a conta.
    validateAllSenders()
      .then((res) => {
      })
      .catch((error) => {
        console.log("Error", error)
        if (error.response) {
          this.showNotify(error, "error");
        }
      });
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }


  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "left", offset: "290 -200" },
        closeOnClick: true,
      },
      type,
      3000
    );
  }

  render() {
    let resultPanel;

    resultPanel = (
      <>
        <h4 className="mt-0" style={{ textAlign: "center" }}>
          {this.state.message}
        </h4>
        <p className="text-muted mb-4" style={{ textAlign: "center" }}>
          {this.state.subMessage}
        </p>
        <div></div>
        <div style={{ textAlign: "center" }}>
          <svg
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style={{ enableBackground: "new 0 0 512 512", width: "75px" }}
          >
            <path
              style={{ fill: "#FFA000" }}
              d="M495.75,351.054c-10.463,10.44-24.643,16.273-39.402,16.25H55.653
            c-14.759,0.022-28.939-5.81-39.402-16.25L197.9,169.406l30.72,22.261c16.273,12.021,38.489,12.021,54.762,0l30.72-22.261
            L495.75,351.054z"
            />
            <path
              style={{ fill: "#FFD54F" }}
              d="M501.315,34.06L314.101,169.406l-30.72,22.261c-16.273,12.021-38.489,12.021-54.762,0l-30.72-22.261
            L10.463,34.282c10.418-14.581,27.27-23.218,45.19-23.151h400.696C474.135,11.153,490.853,19.679,501.315,34.06z"
            />
            <g>
              <path
                style={{ fill: "#FFC107" }}
                d="M10.463,34.282L197.9,169.406L16.251,351.054C5.81,340.592-0.022,326.434,0,311.653V66.783
            C-0.044,55.118,3.607,43.721,10.463,34.282z"
              />
              <path
                style={{ fill: "#FFC107" }}
                d="M512,66.783v244.87c0.022,14.759-5.81,28.939-16.25,39.402L314.101,169.406L501.315,34.06
            C508.261,43.565,512,55.007,512,66.783z"
              />
            </g>
            <circle
              style={{ fill: "#2196F3" }}
              cx="256"
              cy="378.435"
              r="122.435"
            />
            <path
              style={{ fill: "#FAFAFA" }}
              d="M233.74,434.087c-2.961,0-5.788-1.158-7.88-3.25l-44.522-44.522
            c-4.274-4.43-4.141-11.464,0.267-15.738c4.319-4.163,11.153-4.163,15.471,0l36.664,36.641l70.033-70.033
            c4.43-4.274,11.464-4.141,15.738,0.267c4.163,4.319,4.163,11.153,0,15.471l-77.913,77.913
            C239.505,432.908,236.678,434.087,233.74,434.087z"
            />
            <path
              d="M256,500.87c-67.517,0-122.435-54.94-122.435-122.435S188.483,256,256,256c67.495,0,122.435,54.94,122.435,122.435
            S323.495,500.87,256,500.87z M256,278.261c-55.229,0-100.174,44.945-100.174,100.174S200.771,478.609,256,478.609
            s100.174-44.945,100.174-100.174S311.23,278.261,256,278.261z"
            />
            <path
              d="M456.348,367.305h-44.522c-6.144,0-11.13-4.986-11.13-11.13c0-6.144,4.986-11.13,11.13-11.13h44.522
            c18.41,0,33.391-14.982,33.391-33.391V66.783c0-18.41-14.982-33.391-33.391-33.391H55.653c-18.41,0-33.391,14.982-33.391,33.391
            v244.87c0,18.41,14.982,33.391,33.391,33.391h44.522c6.144,0,11.13,4.986,11.13,11.13c0,6.144-4.986,11.13-11.13,11.13H55.653
            C24.977,367.305,0,342.35,0,311.653V66.783c0-30.675,24.977-55.652,55.652-55.652h400.696c30.698,0,55.652,24.977,55.652,55.652
            v244.87C512,342.35,487.046,367.305,456.348,367.305z"
            />
            <path
              d="M256.023,211.813c-11.91,0-23.819-3.718-33.992-11.153L49.152,75.799c-5.009-3.584-6.122-10.552-2.515-15.516
            c3.584-5.009,10.529-6.122,15.538-2.515l172.967,124.906c12.533,9.149,29.251,9.149,41.695,0.045l172.989-124.95
            c4.986-3.629,11.932-2.515,15.538,2.493c3.606,4.986,2.471,11.954-2.493,15.538L289.904,200.704
            C279.797,208.117,267.91,211.813,256.023,211.813z"
            />
            <path
              d="M55.653,322.783c-2.849,0-5.699-1.091-7.88-3.25c-4.341-4.341-4.341-11.398,0-15.738L159.077,192.49
            c4.341-4.341,11.398-4.341,15.738,0c4.341,4.341,4.341,11.398,0,15.738L63.511,319.533
            C61.351,321.692,58.502,322.783,55.653,322.783z"
            />
            <path
              d="M456.348,322.783c-2.849,0-5.699-1.091-7.88-3.25L337.164,208.229c-4.341-4.341-4.341-11.398,0-15.738
            c4.341-4.341,11.398-4.341,15.738,0l111.304,111.304c4.341,4.341,4.341,11.398,0,15.738
            C462.047,321.692,459.198,322.783,456.348,322.783z"
            />
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        </div>
      </>
    );


    return (
      <div className="row col-12 p-0 m-0">
        <div id="7" className="col-12 col-sm-4" style={{ paddingRight: "29px", paddingLeft: "0px" }}>
          <div className="logoFirst">
            <img className="logoExterno" src={logo} alt="Logo" />
          </div>

          <div className="auth-fluid-form-box" style={{ paddingTop: "8%" }}>
            <div className="align-items-center d-flex h-100">
              <div className="card-body" style={{ paddingRight: "4px" }}>
                <div className="auth-brand text-center text-lg-left"></div>

                {resultPanel}

                <footer>
                  <p className="text-muted" style={{ textAlign: "center" }}>
                    {i18n.t("logedout.confirmemail.loginask")}
                    <Link to="auth/login">
                      <b> {i18n.t("logedout.confirmemail.loginlabel")}</b>
                    </Link>
                  </p>
                </footer>
              </div>
            </div>
          </div>
        </div>
        <div
          id="6"
          className="auth d-none d-sm-block col-sm-8"
          style={{
            top: "-17px",
            right: "-50px",
            background: `url('/images/capa_${this.state.photo}.jpg') 50% no-repeat`,
            backgroundSize: "cover",
          }}
        >
          {/* <!--Parte do meio--> */}
          <div className="saleText text-center">
            <h2 className="mb-3">
              {i18n.t("logedout.confirmemail.salesmessage")}
            </h2>
            <p className="saleTextFirst">
              {i18n.t("logedout.confirmemail.salessubmessage")}
            </p>
            <p>{i18n.t("logedout.confirmemail.salesfooter")}</p>
          </div>
        </div>
      </div>
    );
  }
}
