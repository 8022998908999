import React, { Component } from "react";
import "./MiddleBody.css";
import DashBoard from "../DashBoard/DashBoard";
import Account from "../Account/AccountMiddle";
import CanvasComponent from "../CouponModule/BuildCanvas/CanvasComponent";
import ViewCouponsMiddle from "../CouponModule/ViewCoupon/ViewCouponsMiddle";

import CampaignsViewMiddle from "../ComunicationModule/CampaignsView/CampaignsViewMiddle";
import CampaignsActivityMiddle from "../ComunicationModule/CampaignsActivity/CampaignsActivityMiddle";
import ContactsMiddle from "../ComunicationModule/Contacts/ContactsMiddle";
import CustomersMiddle from "../Customers/CustomersView/CustomersViewMiddle";
import AddEditCustomersMiddle from "../Customers/AddEditCustomers/AddEditCustomersMiddle";

import ReportsMiddle from "../Customers/Reports/ReportsViewMiddle";


import ContactsViewMiddle from "../ComunicationModule/ContactsView/ContactsViewMiddle";
import CampaignCreateMiddle from "../ComunicationModule/CampaignCreate/CampaignCreateMiddle";
import CreateHtmlMiddle from "../ComunicationModule/CreateHTML/CreateHtmlMiddle";

import ValidationMiddle from "../CouponModule/Validation/ValidationMiddle";
import AddBranchMiddle from "../AddBranch/AddBranchMiddle";
import AddOperatorsMiddle from "../AddOperators/AddOperatorsMiddle";

/// Módulo Landing Page
import CreatePageMiddle from "../LandingPageModule/CreatePage/CreatePageMiddle";
import ViewLandingPagesMiddle from '../LandingPageModule/ViewPages/ViewLandingPagesMiddle';
import SettingsMiddle from "../Settings/SettingsMiddle";
import EmailMKTStats from "../DashBoard/EmailMKTStats/EmailMKTStatsMiddle";
import SalesStats from "../DashBoard/SalesStats/SalesStatsMiddle";
import Transactions from "../DashBoard/Transactions/TransactionsMiddle";
// import Mobil from "./MobilComponent";

class MiddleBody extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let className;
    if (this.props.middleLarge === true) {
      className = "middleBody shadow bg-white middleLarge content"; // Altera a classe para expandir a parte do meio da tela em uma única parte
    } else {
      className = "middleBody shadow bg-white content";
    }

    let moduleElement;
    if (this.props.module === "Home") {
      moduleElement = <DashBoard />;
    } else if (this.props.module === "Coupon") {
      // className += " removescroll";
      moduleElement = <CanvasComponent history={this.props.history} />;
    } else if (this.props.module === "viewcoupons") {
      moduleElement = <ViewCouponsMiddle history={this.props.history} />;
    } else if (this.props.module === "campaignsview") {
      moduleElement = (
        <CampaignsViewMiddle history={this.props.history} />
      );
    } else if (this.props.module === "campaignsactivity") {
      moduleElement = (
        <CampaignsActivityMiddle history={this.props.history} />
      );
    } else if (this.props.module === "contacts") {
      moduleElement = <ContactsMiddle history={this.props.history} />;
    } else if (this.props.module === "customers") {
      moduleElement = <CustomersMiddle history={this.props.history} />;
    } else if (this.props.module === "addeditcustomers") {
      moduleElement = <AddEditCustomersMiddle history={this.props.history} />;
    } else if (this.props.module === "reports") {
      moduleElement = <ReportsMiddle history={this.props.history} />;
    } else if (this.props.module === "contactsview") {
      moduleElement = <ContactsViewMiddle history={this.props.history} />;
    } else if (this.props.module === "campaigncreate") {
      moduleElement = (
        <CampaignCreateMiddle history={this.props.history} />
      );
    } else if (this.props.module === "createhtml") {
      moduleElement = <CreateHtmlMiddle history={this.props.history} />;
    } else if (this.props.module === "validation") {
      moduleElement = <ValidationMiddle history={this.props.history} />;
    } else if (this.props.module === "addbranch") {
      moduleElement = <AddBranchMiddle history={this.props.history} />;
    } else if (this.props.module === "account") {
      moduleElement = <Account history={this.props.history} />;
    } else if (this.props.module === "addoperators") {
      moduleElement = <AddOperatorsMiddle history={this.props.history} />;
    } else if (this.props.module === "createpage") {
      moduleElement = <CreatePageMiddle history={this.props.history} />;
    } else if (this.props.module === "mylandingpages") {
      moduleElement = <ViewLandingPagesMiddle history={this.props.history} />;
    } else if (this.props.module === "settings") {
      moduleElement = <SettingsMiddle history={this.props.history} tab={this.props.tab} />
    } else if (this.props.module === "emailmktstats") {
      moduleElement = <EmailMKTStats history={this.props.history} />
    } else if (this.props.module === "salesstats") {
      moduleElement = <SalesStats history={this.props.history} />
    } else if (this.props.module === "transactions") {
      moduleElement = <Transactions history={this.props.history} />
    }

    else {
      moduleElement = "";
    }

    return <div className={className}>{moduleElement}</div>;
  }
}

export default MiddleBody;
