import React, { Component } from "react";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Label,
  Format,
  Legend,
  Export,
  Connector,
} from "devextreme-react/chart";
import { SelectBox } from "devextreme-react";
import { getCampaigns, getEmailStats } from "../../../apis/toowezeApi";
import { i18n } from "../../../translate/i18n";
import Spinner from "../../LayOut/Spinner";

const customPalette = ["#f9c14e"];

export default class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highAverage: 77,
      lowAverage: 58,
      campaignId: 0,
      campaignName: "",
      emailStats: 0,
      paineFinal: 0,
      graphStats: 0,
      spinner: true
    };
    this.customizeLabel = this.customizeLabel.bind(this);
    this.customizePoint = this.customizePoint.bind(this);
  }


  componentDidMount() {

    getEmailStats().then(result => {

      let result_;

      if (result) {
        result_ = result.totalResult.sort(function (a, b) {
          if (a.count > b.count) { return -1; }
          if (a.count < b.count) { return 1; }
          return 0;
        });
      }

      if (result) {
        this.setState({
          emailStats: result_
        })
      }

      this.painelFinal()

    }).catch(err => {
      console.log('err', err)
    })

    getCampaigns()
      .then((res) => {
        res.sort(function (a, b) {
          if (a._id < b._id) { return -1; }
          if (a._id > b._id) { return 1; }
          return 0;
        });
        res = res.reverse()
        this.setState({
          datagrid: res,
        });
        if (res.length > 0) {

          this.setState({
            campaignId: res[0]._id,
            campaignName: res[0].name,
            spinner: false
          }, this.campaignSelected({ value: res[0]._id }))
        }

      })
      .catch((err) => {
        this.setState({
          datagrid: [],
          spinner: false,
        });
      });
  }

  campaignSelected = (event) => {
    this.setState({
      campaignId: event.value,
      campaignName: event.event?.target.innerText ? event.event?.target.innerText : "",
      spinner: false
    })
    getEmailStats(this.state.campaignId).then(res => {

      let result = res.resultFinal.sort(function (a, b) {
        if (a.count > b.count) { return -1; }
        if (a.count < b.count) { return 1; }
        return 0;
      });


      this.setState({
        graphStats: result
      })
    })
  }

  painelFinal = () => {
    let paineFinal;
    paineFinal = this.state.emailStats.map((item, index) => {
      return (
        <div className="col m-1" style={{ maxWidth: "18rem" }}>
          <div className="card mb-3" style={{ maxWidth: '18rem' }}>
            <div id={index} className="card-header">{item._id.eventType}</div>
            <div className="card-body">
              <h5 className="card-title">{i18n.t("dashboard.emailstats.totalmonth")}{item.count}</h5>
              <p className="card-text">{i18n.t("dashboard.emailstats.percentual")}{item.percentage.toFixed(2)}%</p>
            </div>
          </div>
        </div>
      );
    });
    this.setState({
      paineFinal
    })
  }

  render() {

    if (this.state.spinner === true) {
      return <Spinner />;
    } else if (this.state.campaignName !== undefined) {
      return (
        <div
          className="col-12"
        >
          <div className="row m-0" style={{ textAlign: 'center' }}>
            <div className="col-md-12 col-xl-12 content">
              <div className="row" style={{ justifyContent: 'space-around' }}>

                {this.state.paineFinal}

              </div>
            </div>
          </div>

          <div className="row m-0">
            <div className="col-12 container">
              <div className="row pb-3">
                <div className="col-6">
                  <div className="d-flex" style={{ marginLeft: '21px' }}>
                    <SelectBox
                      dataSource={
                        this.state.datagrid ? this.state.datagrid : null
                      }
                      style={{ margin: "3px" }}
                      displayExpr="name"
                      valueExpr="_id"
                      value={
                        this.state.campaignId ? this.state.campaignId : null
                      }
                      width={350}
                      onValueChanged={(event) => this.campaignSelected(event)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 container">
            <div className="col-12">
              <div className="row">
                <Chart
                  id="chart"
                  style={{ height: "400px", width: "100%" }}
                  palette={customPalette}
                  title={this.state.campaignName ? this.state.campaignName : ''}
                  dataSource={this.state.graphStats}
                  onPointClick={this.onPointClick}
                >
                  <CommonSeriesSettings
                    argumentField="campaign"
                    type="bar"
                    hoverMode="allArgumentPoints"
                    selectionMode="allArgumentPoints"
                  >
                    <Label visible={true}>
                      <Format type="fixedPoint" precision={0} />
                    </Label>
                  </CommonSeriesSettings>
                  <Series
                    argumentField="eventType"
                    valueField="count"
                    ValueAxis="percentage"
                  >
                    <Label
                      visible={true}
                      position="columns"
                      customizeText={this.customizeLabel}
                    >
                      {/* <Font size={16} /> */}
                      <Connector visible={true} width={1.5} />
                    </Label>

                  </Series>

                  <Legend
                    visible={false}
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                    customizeItems={this.customizeLabel2}
                    customizeText={this.customizeLabel3}
                  >
                  </Legend>

                  <Export enabled={true} />
                </Chart>
              </div>
              <div className="row">
              </div>
            </div>
          </div>
        </div>
      );
    }

  }

  customizeLabel(stats) {
    if (stats) {
      return `<span style="color: #000000;">${stats?.point?.data?.count ? stats?.point?.data?.count : 0} - <span><label style="font-size: x-small; color: #000000;">(${stats?.point?.data?.percentage ? stats?.point?.data?.percentage < 1 ? stats?.point?.data?.percentage?.toFixed(3) : stats?.point?.data?.percentage?.toFixed(2) : 0}%)</label>`;
    }
  }

  customizeLabel2(stats) {
    stats.map(items => {
    })
    if (stats.argumentText === "Click") {
      stats.point._dataItem.data.eventType = "Clique";
      stats.argument = "Clique";
    }
    return "stats";
  }

  customizeLabel3(stats) {
    stats.map(items => {
      items.map(item2 => {
      })
    })
    return "Teste";
  }

  customizePoint(arg) {
    if (arg.value > this.state.highAverage) {
      return { color: "#ff7c7c", hoverStyle: { color: "#ff7c7c" } };
    } else if (arg.value < this.state.lowAverage) {
      return { color: "#8c8cff", hoverStyle: { color: "#8c8cff" } };
    }
  }

  // customizeLabel(arg) {
  //   if (arg.value > this.state.highAverage) {
  //     return {
  //       visible: true,
  //       backgroundColor: "#ff7c7c",
  //       customizeText: function (e) {
  //         return `${e.valueText}&#176F`;
  //       },
  //     };
  //   }
  // }

  // customizeText(arg) {
  //   return `${arg.valueText}&#176F`;
  // }

  onPointClick(e) {
    e.target.select();
  }
}
