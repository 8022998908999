const messages = {
  pt: {
    translations: {
      menu: {
        leftmenu: {
          dashboard: "DashBoard",
          studiomodule: "Studio",
          couponmodule: "Cupons",
          configModule: "Config",
          landingpagemodule: "LandingPage",
          branchmodule: "Suas Filiais",
          branchmoduleitembranchs: "Estabelecimentos",
          branchmoduleitemoperators: "Operadores",
          landingmoduleitempagbuilder: "LandingPage",
          couponmoduleitemcreatecoupon: "Criar Cupom",
          couponmoduleitemcoupons: "Seus Cupons",
          couponmoduleitemvalidate: "Validar Cupom",
          communicationmoduleitemcontacts: "Contatos",
          communicationmoduleitemcustomers: "Clientes",
          communicationmoduleitemcampaign: "Campanhas",
          communicationmoduleitemcreatecampaign: "Criar Campanha",
          communicationmoduleitemcreatehtml: "Mensagem HTML",
          reports: "Relatórios",
          cashbackreports: "CashBack Válidos",
          pointsreport: "Pontos Válidos"
        },
      },
      dashboard: {
        emailstats: {
          totalmonth: "Total Mês: ",
          percentual: "Percentual: "
        }
      },
      configurations: {
        cashbackrules: "Regras de CashBack",
        pointsrules: "Regras de Pontuação",
        portalandapp: "Configurar Web App",
        configcomm: "Configurar Comunicação",
        store: "Loja de Prêmios",
        configpointrules: {
          newbtn: "Novo",
          title: "Criar/Configurar Regras de Pontos",
          rulename: "Nome da Regra",
          whenspent: "Quando gastar R$: ",
          whenparticipate: "Quando participar deste evento:",
          willrecieve: "Receberá: ",
          recievetype: "Pontos",
          expirationtitle: "Esses pontos expiram em: ",
          expirationtypeone: "Não expiram",
          expirationtypetwo: "Expiram em: ",
          expirationtypefixed: "Data fixa: ",
          apllitopartial: "Aplicar Regra ao pagar com Pontos?",
          apllitopartialyes: "Sim",
          apllitopartialno: "Não",
          rulestatus: "Status",
          rulestatusactive: "Ativa",
          rulestatusinactive: "Inativa",
          ruletype: "Tipo de Regra",
          needtokentousepoints: "Precisa token para usar Pontos?",
          flatpoints: "Evento",
          purchase: "Consumo",
          communicationconfigtitle: "Comunicação dos Pontos",
          communicationconfigsubtitle: "Sempre ao receber pontos, enviar:",
          communicationtypeemail: "Email",
          communicationtypesms: "SMS",
          communicationtypepush: "Push Notification",
          communicationconfigtitleCB: "Comunicação de CashBack",
          communicationconfigsubtitleCB: "Sempre ao receber cashback, enviar:",
        },
        configcashbackrules: {
          newbtn: "Novo",
          title: "Criar/Configurar Regras de CashBack",
          rulename: "Nome da Regra",
          whenspent: "Quando gastar R$: ",
          whenparticipate: "Quando participar deste evento:",
          willrecieve: "Receberá: ",
          recievetype: "em CashBack",
          expirationtitle: "Esse CashBack expiram em: ",
          expirationtypeone: "Não expiram",
          expirationtypetwo: "Expiram em: ",
          expirationtypefixed: "Data fixa: ",
          apllitopartial: "Aplicar Regra ao usar CashBack?",
          apllitopartialyes: "Sim",
          apllitopartialno: "Não",
          needtokentouse: "Precisa token para usar CashBack?",
          rulestatus: "Status",
          rulestatusactive: "Ativa",
          rulestatusinactive: "Inativa",
          ruletype: "Tipo de Regra",
          flatpoints: "Evento",
          purchase: "Consumo"
        }

      },
      transactions: {
        activity: {
          name: "Nome",
          email: "email",
          mobil: "Celular",
          personalId: "CPF",
          observation: "Observação",
          externalId: "CódExterno",
          couponCodeNumber: "Cupom",
          initialValue: "Valor Inicial",
          discountPercent: "%Desc",
          discountValue: "Desc.Valor",
          voucher: "Voucher",
          cashbackused: "Usou CashBack",
          finalValue: "Valor Final",
          feels_like: "Temp",
          weatherDescription: "Tempo",
          weatherIcon: "Tempo",
          weatherMain: "Tempo",
          nps: "NPS",
          comments: "Comentários",
          log_date_timeZone: "Data",
          log_request: "Logs",
          last30days: "30 dias",
          last90days: "90 dias",
          thismonth: "Este Mês",
          startDate: "Data Inicial",
          endDate: "Data Final",
          sales: "Vendas",
          points: "Pontos Distribuídos",
          cashback: "CashBack Distribuídos",
          points_recieved: "Pontos Ganhos",
          cashback_recieved: "CashBack Ganho"
        }
      },
      viewcoupons: {
        start: "Nenhum cupom ainda! Crie o seu primeiro:",
        click: "Clique Aqui",
        support: {
          maintitle: "Configurações do Cupom",
          title: "Título",
          titleplace: "Título do Cupom",
          validtitleplace: "Título do Cupom requerido",
          adjust: "Ajustar %",
          discount: "Desconto de:",
          fixedcode: "Código Fixo",
          autocode: "Automático",
          couponlimit: "Limite de Cupons",
          couponlimitplace: "Sem Limite",
          voucher: "Voucher R$",
          coin: "R$",
          choosename: "Nome",
          chooseemail: "Email",
          startdate: "Valido De:",
          enddata: "Valido Até:",
          enddataplace: "Sempre Válido",
          starthour: "Horário Inicial",
          endhour: "Horário Final",
          branchrule:
            "Defina se poderá ser validado em qualquer estabelecimento, ou apenas determinados.",
          brachruleplace: "Válido em todos Estabelecimentos",
          bhancruleplace2: "Uso Anônimo do Cupom Permitido",
          name: "Nome",
          email: "Email",
          requreidfieldsdesc:
            "Defina os campos obrigatórios para visualizar o código do cupom ou validar o código do cupom.",
          description: "Descritivo",
          terms: "Termos e Condições",
          btnsave: "Gravar Configurações",
          btnedit: "Editar Design",
          swhitactivelabel: "Ativar",
          swhitdeactivelabel: "Desativar",
          messagesaving: "Gravando Configurações",
          messageupdating: "Atualizado Com Sucesso",
          modaldeletetitle: "Deletar Cupom",
          modaldeleteh5: "Atenção!",
          modaldeletecontent:
            "Tem certeza de que deseja continuar? Deletar o cupom não poderá ser desfeito, campanhas atuais serão canceladas!",
          modaldeletecanceltext: "Cancelar",
          modaldeleteoktext: "Deletar Cupom",
          notifyconfigactivestatus: "Cupom ativado com sucesso!",
          notifyconfigdeactivestatus:
            "Cupom Desativado com sucesso! Código Gerados Não Serão Mais Validados!",
          notifyautoorfixedcode:
            "Você precisa definir código automático ou definir um código fixo!",
          notifycouponvoucher:
            "Cupom com 0% de desconto precisa oferecer valor de Voucher!",
          notifycoupontitle: "O Título do Cupom é obrigatório!",
          notifyhourinterval:
            "Um período de horário não pode estar preenchido e o outro não, precisa formar um intervalo!",
          notifycodealready: "Código Fixo do Cupom já em uso!",
          notifyselectcoupon: "Selecione um cupom!",
        },
      },
      mylandingpages: {
        view: {
          template: "Template",
          yourpages: "Suas Páginas",
          preview: "Visualizar",
          title: "Visualizar Template",
          modalok: "OK",
          cancel: "Cancelar"
        },
        support: {
          maintitle: "Configurações da LadingPage",
          title: "Nome Arquivo",
          titleplace: "Nome do Arquivo",
          validtitleplace: "Nome Arquivo Requerido",

          pagetitle: "Título Web Page",
          pagetitleplace: "Título da Página WEB",
          validpagetitle: "",
          bucket: "url da LandingPage",
          bucketplace: "url única.",
          validbucketplace: "A url é obrigatória.",
          script: "Scripts",
          scriptPlace: "Coloque aqui scripts como Google Analytics, Jivo Chat etc.",
          terms: "Termos e Condições",
          btnsave: "Gravar Configurações",
          btnedit: "Editar Design",
          swhitactivelabel: "Ativar",
          swhitdeactivelabel: "Desativar",
          messagesaving: "Gravando Configurações",
          messageupdating: "Atualizado Com Sucesso",
          modaldeletetitle: "Deletar Landing Page",
          modaldeleteh5: "Atenção!",
          modaldeletecontent:
            "Tem certeza de que deseja continuar? Deletar a LandingPage não poderá ser desfeito.",
          modaldeletecanceltext: "Cancelar",
          modaldeleteoktext: "Deletar Landing Page",
          notifyconfigactivestatus: "Landing Page ativada com sucesso!",
          notifyconfigdeactivestatus:
            "Landing Page Desativada com sucesso! Esta página não poderá mais ser visualizada!",
          notifypagetitle: "O Título do Arquivo é obrigatório!",
          notifypagebucket: "A URL é obrigatória!",
          notifyselectpage: "Selecione uma Landing Page!",
          notifysaveconfigpage: "Grave as configurações primeiro!"
        },
      },
      coupon: {
        topbar: {
          front: "Tudo para frente",
          forward: "Um para frente",
          back: "Tudo para trás",
          backward: "Um para trás",
          rectangle: "Retângulo",
          square: "Quadrado",
          circle: "Círculo",
          triangle: "Triângulo",
          text: "Escrever",
          delete: "Deletar",
        },
        tools: {
          screensizes: {
            coupon: "Cupom",
            instasquare: "Instagram - Foto Quadrada",
            instalandiscape: "Instagram - Foto Paisagem",
            instaportrait: "Instagram - Foto Retrato"
          }
        },
        canvas: {
          classification: "Classificação IA: ",
          messagebackgroundremove: "Removendo fundo da imagem...",
          messagesavecoupon: "Gravando Cupom!",
          messageupdatecoupon: "Cupom Atualizado Com Sucesso!",
          messagesavecouponresult:
            'Cupom Gravado Com Sucesso! Atenção: Para este cupom aparecer para seu cliente, você precisa ativá-lo clicando em "Regras de Uso"',
          errormessagesavecoupon: "Crie seu Cupom para poder gravar!",
          messagesaveart: "Gravando Arte!",
          messageupdateart: "Arte Atualizada Com Sucesso!",
          messagesaveartresult:
            'Arte Gravada Com Sucesso!"',
          errormessagesaveart: "Crie uma Arte para poder gravar!",
          messageaddingimage: "Adicionando Imagem.",
          messageaddingart: "Adicionando Arte.",
          textlabel: "Texto",
          errormessageremovebackground:
            "Limite de remoção de fundo atingido, faça uma aquisição de créditos!",
          errormessageremovebackgroundselect:
            "Selecione uma imagem para remover o fundo!",
          btnsavecoupontext: "Gravar Cupom",
          btnimagedonwload: "Download Imagem",
          btnsaveart: "Gravar",
          btncuponuserules: "Regras de Uso",
          btnnewcoupontext: "Reset",
        },
        supportmenu: {
          imageshowlabel0: "Arte",
          imageshowlabel1: "Galeria",
          imageshowlabel2: "Busca",
          imageshowlabel3: "Pets",
          imageshowlabel4: "Vestuário",
          imageshowlabel5: "Café",
          imageshowlabel6: "Negócios",
          imageshowlabel7: "Vendas",
          imageshowlabel8: "Produtos",
          imageshowlabel9: "Bem Estar", //Health & Wellness
          uploadimage: "Subir Imagem",
          icontextbackgroundremove: "Remover Fundo",
          icontexthighresolution: "Alta Resolução",
          icontextlandscape: "Paisagem/Retrato",
          modalbackgroundremovetitle: "Remover Fundo da Imagem",
          modalbackgroundremoveh5: "Atenção!",
          modalbackgroundremovecontent:
            "Remover o fundo da imagem utiliza um crédito: Tem certeza de que deseja continuar?",
          modalbackgroundremovebtncancel: "Cancelar",
          modalbackgroundremovebtnok: "Remover Fundo da Imagem",
          btnsearchtext: "Buscar",

          modalimagegallerytitle: "Deletar Imagem",
          modalimagegalleryh5Content: "Atenção!",
          modalimagegallerycontent:
            "Deletar Imagem da Sua Galeria de Imagens: Tem certeza de que deseja continuar?",
          modalimagegallerycancelText: "Cancelar",
          modalimagegalleryokText: "Deletar Imagem",

          modalartllerytitle: "Deletar Arte",
          modalartlleryh5Content: "Atenção!",
          modalartllerycontent:
            "Deletar Arte da Sua Galeria de Arte: Tem certeza de que deseja continuar?",
          modalartllerycancelText: "Cancelar",
          modalartlleryokText: "Deletar Arte",
        },
      },
      contacts: {
        grid: {
          contactliststatuspaused: "Pausada",
          contactliststatusready: "Liberada para Uso",
          addcontactslabel: "Ver/Adicionar Contatos",
          columnlabelistname: "Nome da lista de contatos",
          columnlabelistdate: "Criada em",
          columnlabeliststatus: "Status",
          columnlabelistactions: "Ações",
          columnlabelfirstname: "Nome",
          columnlabellastname: "Sobrenome",
          columnlabelmobil: "Celular",
          columnlabelextra: "Extra1",
          columnlabelemail: "Email",
          columnlabelcreatedat: "Criada em",
          columnlabelorigin: "Origem",
          buttonlabelcontacts: "Ver Contatos",
          searchplaceholder: "Busca...",
          contactstatusactive: "Ativo",
          contactstatusinactive: "Inativo",
          contactstatusunsubscribe: "Descadastrou",
          contactstatbounce: "Bounce",
          contactstatspamreport: "Reportou Spam",
          contactoriginlabel: "Contato Adicionado Manualmente",
          contactoriginlabelimported: "Lista Importada",
          contacthintlabel: "Importar Contatos",
          columnrelationslabel: "Relacionar com coluna abaixo:",
          dropdownignorelabel: "Ignorar Coluna",
          dropdownnamelabel: "Primeiro Nome",
          dropdownnlastnamelabel: "Sobrenome",
          dropdownnemaillabel: "Email",
          dropdownnmobillabel: "Celular",
          delimiter: "delimitador",
          upload: "Upload",
          uploadfile: "Subir Arquivo",
          notifycontactdeleted: "Contato Deletado Com Sucesso!",
          notifycontactupdated: "Contato atualizado com sucesso!",
          notifycontadded: "Contato Adicionado com Sucesso!",
          notifyrequiredfield: "Email ou Celular são obrigatórios!",
          messageimportingerror:
            "Você está tentando importar um arquivo Excel. Precisa salvar este arquivo como CSV antes!",
          notifyimportresult1: "contatos importados com sucesso e ",
          notifyimportresult2: " erros ocorridos,",
          notifyimportresult3:
            "                verifique o log de imporação para mais detalhes!",
          notifyimportresult4:
            "Erros na importação, verifique o log de imporação para mais detalhes!",
          notifyimportresult5: "Contatos importados com sucesso!",
          notifyimportresult6: "Você atingiu o limite de contatos em sua assinatura!"
        },
      },
      customers: {
        grid: {
          customersliststatuspaused: "Pausada",
          customersliststatusready: "Liberada para Uso",
          addcustomerslabel: "Ver/Adicionar Clientes",
          columnlabelistname: "Nome da lista de clientes",
          columnlabelistdate: "Criada em",
          columnlabeliststatus: "Status",
          columnlabelistactions: "Ações",

          columnlabelid: "Id",
          columnlabelname: "Nome",
          columnlabelemail: "Email",
          columnlabelmobil: "Celular",
          columnlabelgender: "Sexo",
          columnlabelexternalID: "ExternalID",
          columnlabelbirthDay: "Aniversário",
          columnlabelpersonalId: "CPF",
          columnlabelpersonalsecondId: "RG",

          columnlabelcolumnlabeline1: "Endereço",
          columnlabelcolumnlabeline2: "Complemento",
          columnlabelneighborhood: "Bairro",
          columnlabelcityId: "Id Cidade",
          columnlabelcityName: "Cidade",


          columnlabelzipcode: "CEP",
          columnlabelregionId: "Id Estado",
          columnlabelregionName: "Estado",
          columnlabelcountryId: "Id País",
          columnlabelcountryName: "País",
          columnlabeltags: "Tags",
          columnlabelisEmailValidate: "E-mail Validado",


          columnlabelstatus: "Status",
          columnlabelisVerify: "Verificado",
          columnlabelprofilePic: "Foto",
          columnlabeltotalPoints: "Total Pontos Disponíveis",
          columnlabeltotalCashBack: "Total CashBack Disponível",
          columnlabelextra: "Extra1",

          columnlabelcreatedat: "Criada em",
          columnlabelorigin: "Dashboard",
          buttonlabelcustomers: "Ver Clientes",
          searchplaceholder: "Busca...",
          customerstatusactive: "Ativo",
          customerstatusinactive: "Inativo",
          customergendermale: "M",
          customergenderfemale: "F",
          customergendernot: "N/I",
          customergendermaleheader: "M",
          customergenderfemalehearder: "F",
          columncustomerlabeldetails: "Detalhes",
          customerlabeldetails: "Editar/Detalhes",

          customerstatusunsubscribe: "Descadastrou",
          customerstatbounce: "Bounce",
          customerstatspamreport: "Reportou Spam",
          customersoriginlabel: "Cliente Adicionado Manualmente",
          customersoriginlabelimported: "Lista Importada",
          customershintlabel: "Importar Clientes",
          columnrelationslabel: "Relacionar com coluna abaixo:",
          dropdownignorelabel: "Ignorar Coluna",
          dropdownnamelabel: "Primeiro Nome",
          dropdownnlastnamelabel: "Sobrenome",
          dropdownnemaillabel: "Email",
          dropdownnmobillabel: "Celular",
          delimiter: "delimitador",
          upload: "Upload",
          uploadfile: "Subir Arquivo",

          notifycustomersupdated: "Cliente atualizado com sucesso!",
          notifycustomerdded: "Cliente Adicionado com Sucesso!",
          notifyrequiredfield: "Email ou Celular são obrigatórios!",
          messageimportingerror:
            "Você está tentando importar um arquivo Excel. Precisa salvar este arquivo como CSV antes!",
          notifyimportresult1: "clientes importados com sucesso e ",
          notifyimportresult2: " erros ocorridos,",
          notifyimportresult3:
            "                verifique o log de importação para mais detalhes!",
          notifyimportresult4:
            "Erros na importação, verifique o log de imporação para mais detalhes!",
          notifyimportresult5: "Clientes importados com sucesso!",
          notifyimportresult6: "Você atingiu o limite de clientes em sua assinatura!"
        },
      },
      customerAddEdit: {
        profilepic: "Foto Perfil",
        labelname: "Nome:",
        labelemail: "E-mail:",
        labeltype: "Tipo:",
        labelperson: "Pessoa Física:",
        labelcorporate: "Pessoa Jurídica:",
        placeholderidentifiertype: "Tipo de Cadastro",
        labelidentifiertype: "CNPJ/CPF:",
        labelmobil: "Celular/Telefone:",
        labelbirthdate: "Nascimento:",
        labelzipcode: "CEP:",
        labelcityregion: "Estado/Cidade:",
        labelregion: "Estado:",
        labelcity: "Cidade:",
        labeladdressline1: "Rua:",
        placeholderaddressline1: "Rua",
        placeholdernumber: "Número",
        labeladdressline2: "Complemento:",
        placeholderaddressline2: "Complemento",
        labelneighborhood: "Bairro:",
        placeholderneighborhood: "Bairro",

        btnupdate: "Atualizar Cliente",
        btnadd: "Adicionar Cliente",
        btndelete: "Deletar Cliente",
        notifycustomersdeleted: "Cliente Deletado Com Sucesso!",
        labelpaperpayment: "Boleto PIX",
        pixCopy: "PIX Copia e Cola",
        labelcreatepaperpayment: "Gerar Boleto",
        labelmakepayment: "Efetuar Pagamento",
        labelcreditcards: "Cartões de Crédito",
        labelcardname: "Nome como no cartão:",
        labelcompanytype: "CNPJ/CPF Assinatura:",
        labelextracredit: "Crédito Extra:",
        labelEmail: "Email Remetente:",
        allowrenew: "Permitir Renovação Automática",
        activate: "Ativar",
        deactivate: "Desativar",
        monthlypayment: "Plano Assinatura: R$",
        btnprofiledata: "Dados Cadastrais",
        labeltotalbalancepoints: "Total de Pontos: ",
        labeltotalbalancecashback: "Total de CashBack: ",

        labelbalancepoints: "Extrato de Pontos",
        labelbalancecashback: "Extrato de CashBack",
        labelactivity: "Atividades",

        btnconfigurations: "Configurações",
        grid: {
          searchplaceholder: "Buscar",
          columnlabelcreatedAt: "Data",
          columnlabeldescription: "Descritivo",
          columnlabelsourceType: "Origem",
          columnamount: "Valor",
          columnactivity: "Atividade",
          columnstatus: "Status",
          paid: "Pago",
          credit: "Crédito",
          debit: "Débito",
          currency: "R$",
          columnlabelemail: "Email Remetente",
          columnlabelresend: "Reenviar",
          activeStatus: "Disponível",
          inactiveStatus: "Pendente"
        },

        notify: {
          identifieralert:
            "CPF/CNPJ Não é obrigatório, mas se informar precisa indicar se é pessoa física ou jurídica!",
        },
        message: {
          generic: "Boleto PIX: Liberação imediata.",
          // requiredfieldname: "Nome requerido",
          // requiredfieldemail: "E-mail requerido",
          // emailalreadyregistered: "E-mail já registrado.",
          // invalidemail: "Email inválido",
          identifieralreadyregistered: "CNPJ/CPF já cadastrados.",
          senderemailalreadyregistered: "Email já registrado em outra conta!",
          resendemail: "Email reenviado com sucesso!",
          cantdeleteemail: "Não posso deletar, precisa haver ao menos um e-mail rementente!",
          identifieralert: "Defina o tipo de Pessoa!"
          // requiredfieldcompanynameas: "Nome Fantasia requerido.",
        },
        support: {
          labellimit: "Limite de Campanhas Ativas: ",
          labelused: "Utilizado ",
          labelcontactslimit: "Limite de Contatos Cadastrados: ",
          labelemailsendlimit: "Limite de Envio de E-mails: ",
          labelextraestablishments: "Estabelecimentos Extras: ",
        },
      },
      campaignview: {
        grid: {
          filterlabelemailmkt: "Email Marketing",
          filterlabelsms: "SMS",
          filterlabelsocialshare: "Social Share",
          filterlabelgeneric: "Campanha Genérica",
          filterlabelactive: "Ativas",
          filterlabelinactive: "Inativas",
          customfieldemailmkt: "Email Marketing",
          customfieldsms: "SMS",
          customfieldsocialshare: "Social Share",
          customfieldgeneric: "Campanha Genérica",
          eventlinklabel: "Eventos",
          detailslinklabel: "Detalhes da Campanha",
          columnlabelcampaignname: "Nome da Campanha",
          columnlabelmessagename: "Mensagem",
          columnlabelcontactname: "Lista de Contatos",
          columnstatus: "Status",
          columnlabelsenttype: "Tipo de Campanha",
          columnlabelsendfrom: "Remetente",
          columnlabelcreatedat: "Criada em",
          columnlabelsenddate: "Enviada em",
          columnlabelcheckcampaign: "Ver Campanha",
          columnlabelactivity: "Ver Atividade",
          searchlabel: "Busca...",
        },
        activity: {
          filterlabelopen: "Abriu Email",
          filterlabelviewpage: "Página Visualizada",
          filterlabelgeneratecode: "Código Gerado",
          filterlabelclick: "Clicou Cupom do Email",
          filterlabelusedcoupon: "Cupom Utilizado",
          filterlandingpageform: "Cadastro LandingPage",
          customfieldopen: "Abriu",
          customfieldviewpage: "Página Visualizada",
          customfieldgeneratedcoupon: "Código Gerado",
          customfieldclick: "Clique",
          customfieldusedcoupon: "Cupom Utilizado",
          customfieldusedlandingpageform: "Cadastro Realizado",
          modalcouponlabel: "Ver Cupom",
          unsubscribe: "Descadastrou",
          btncouponconfiglabel: "Configurar Cupom",
          btnimageeditlabel: "Editar Imagem",
          searchlabel: "Busca...",
          labelsource: "Link Compartilhado",
          labelsourcelandingpage: "LandingPage",
          columnlabelsource: "Enviado por",
          columnlabeldestination: "Enviado para",
          columnnamesubject: "Assunto",
          columnnameeventtype: "Atividade",
          columnnamelink: "Clicou em",
          columnnameipaddress: "IP",
          columnlabelcoupon: "Cupom",
          columnlabeltimestamp: "",
          columniptempflag: "Bandeira",
          columniptempmaintemp: "Temperatura",
          columniptempmainhumidity: "Umidade",
          columninitialvalue: "Total Compra",
          columndiscount: "Desconto",
          columndiscountvalue: "Valor Desc.",
          columnvoucher: "Voucher",
          columnfinalvalue: "Valor Pago",
          columniptempzipcode: "CEP",
          columniptemploc: "Local",
          columniptempcity: "Cidade",
          columniptempregion: "Estado",
          columniptempcountryname: "País",
          columniptempip: "IP",
          columniptempcurrencysymbol: "Moeda",
          columniptemplanguages: "Idioma",
          columniptemplatitude: "Latitude",
          columniptemplongitude: "Longitude",
          columniptempprovidername: "Internet",
          // Aqui
        },
        notify: {
          notifydeactivecampaign:
            "Atenção: Ao desativar uma campanha de Link Compartilhado a página de gerar cupom ficará indisponível. Códigos já gerados não serão localizados.",
          notifyactivecampaign:
            "Campanha de Link Compartilhado Ativada com Sucesso, a página de gerar cupom ficará disponível. Códigos já gerados serão localizados.",
        },
        message: {
          couponimage: "Nenhuma imagem para exibir.",
          imageediwarning:
            "Caso você altere a imagem, a atualização não reflitirá em eventos registrados anteriormente.",
        },
      },
      campaigncreate: {


        report_all: "Todos Clientes",
        report_all_points: "Todos Com Pontos",
        report_biggests_points: "Maiores Pontuadores",
        report_lowests_points: "Menores Pontuadores",
        report_all_without_points: "Todos Sem Pontos",



        dropdowncoupon: "Selecione um Cupom",
        labelcontactlist: "Lista de Contatos",
        labelmessage: "Mensagem",
        labelsharecoupon: "Escolha o Cupom Para Compartilhar",
        labelsenderemail: "Remetente",
        labelsocialnetworklink: "Link Para Click nas Redes Sociais:",
        labelsocialnetworkclipboard: "Click to ClipBoard",
        labelsocialnetworkdownload:
          "Clique para fazer download da imagem do cupom:",
        labelsocialnetworkimagedownload: "Image Download",
        labelcampaignname: "Nome da Campanha",
        labelemailsubject: "Assunto do Email",
        labelemailsendtest: "Enviar teste para os e-mails:",
        labelemailsendtestplaceholder: "Digite um ou mais e-mails separados por ;",
        labelsenddate: "Enviar em:",
        placeholderdate: "Definir Data",
        labelcouponemailsubject: "Assunto do Email Enviado Com Código do Cupom",
        labelcardemailmkt: "Email Marketing",
        labelsocialshare: "Compartilhamento",
        labelpushnotification: "Push Notification",
        labeldownloadcode: "Download Código",
        btnreset: "Resetar",
        btntest: "Envio Teste",
        btnsave: "Gravar",
        message: {
          contactlist: "Você precisa criar uma lista de contatos para enviar.",
          contactlistclick: "(Clique Aqui)",
          htmlmessage: "Você precisa criar uma mensagem HTML para enviar.",
          htmlclick: "(Clique Aqui)",
          couponmessage: "Você precisa criar um Cupom para compartilhar.",
          couponclick: "(Clique Aqui)",
          couponreleasemessage:
            "Você precisa liberar um Cupom para compartilhar.",
          couponreleaseclick: "(Clique Aqui)",
          senderemailmessage: "Você precisa cadastrar um E-mail de Remetente.",
          senderclick: "(Clique Aqui)",
          senderemailmessagerelease:
            "Você precisa liberar o Remetente Cadastrado.",
        },
        notify: {
          contactlistalnotfound: "Lista de Contato Inexistente.",
          contactlistunavailable:
            "Lista de Contatos não mais disponível, pode ter sido excluída!",
          htmlmessagenotfound: "Mensagem HTML Inexistente.",
          copytoclipboard: "URL copiada para o clipboard!",
          requiredfieldsender: "Defina o Remetente da Mensagem",
          requiredfieldsendertest: "Defina o Remetente da Mensagem, o e-mail de teste será enviado para ele.",
          requiredfielddate: "Defina a data de envio",
          requiredfieldhtml: "Defina a mensagem HTML",
          requiredfieldsendercode:
            "Defina o Remetente da Mensagem que enviará o código do cupom",
          requiredfieldcontactlist: "Defina a lista de Contatos.",
          requiredfieldemailtest: "E-mail para enviar o teste é obrigatório!",
          requiredfieldcupom: "Defina o Cupom",

          campaigncreated: "A Campanha foi Criada com Sucesso!",
          campaigntestsented: "Email de teste enviado com Sucesso!",
          pagereloading: "Relendo a página...",
          campaignsuccessfullycreated: "A Campanha foi Criada com Sucesso!",
          campaignaccountlimitreached:
            "Sua conta atingiu o limite de Campanhas. Faça um Upgrade!",
          emailaccountlimitreached:
            "Sua conta atingiu o limite de envio de e-mails. Faça um Upgrade!",
          emailaccountnotapproval: "Conta não aprovada para enviar e-mail.",
          namelength: "Nome da campanha precisa ao menos 5 caracteres.",
          requiredfieldemailsubject: "Defina o Assunto do Email.",
          requiredfieldcampaignname: "Defina o Nome da Campanha.",
          toomanycomplaint: "Conta com excesso de reclamação de SPAM!"
        },
      },
      couponcreatehtml: {
        btnreset: "Resetar",
        btnsave: "Gravar",
        placeholdermessagename: "Nome da Mensagem",

        notify: {
          htmlupdated: "Mensagem Atualizada Com Sucesso!",
          htmlcreated: "Mensagem Criada Com Sucesso!",
          requiredfieldname: "Nome da mensagem é obrigatório!",
          requiredhtmlmessage: "Nenhuma mensagem HTML para gravar!",
        },
      },
      validation: {
        labelname: "Nome:",
        placeholdername: "Nome",
        labelemail: "Email:",
        placeholderemail: "Email",
        labeltotalvalue: "Valor total da compra:",
        labelfinalvalue: "Valor final da compra: R$",
        labeldiscount: "(-) Desconto de:",
        labelvoucher: "Voucher de R$:",
        numberformat: "R$ #.##0,##;(R$ #.##0,##)",
        numberformatvoucher: "R$ #.##0,##",
        localvalue: "pt-BR",
        currencyvalue: "BRL",
        btntext: "Validar",
        labelcouponrules: "Regras do Cupom:",
        labelcoupondiscount: "Desconto de:",
        labelmanualdiscount: "Aplicar Desconto Manual:",
        labelcouponlimit: "Limite de Cupons:",
        placeholdercouponlimit: "Sem Limite",
        labelvalidfrom: "Válido de:",
        placeholdervalid: "Sempre Válido",
        labelvaliduntil: "Válido Até:",
        labelstarhour: "Horário Inicial:",
        labelendhour: "Horário Final:",
        labeldescription: "Descritivo:",
        labeltermsandconditions: "Termos e Condições:",
        labelcouponcode: "Digite o código do cupom:",
        labelfixedcode: "Código Fixo:",
        labelactive: "Ativar:",
        labeldeactive: "Desativar:",
        labelautomatic: "Automático:",
        btnreset: "Resetar",
        btncheck: "Checar",
        notify: {
          couponalreadyused: "Cupom Já Utilizado!",
          inactivecampaign: "Campanha Inativa",
          inactivecoupon: "Cupom Inativo",
          anonymousvalidationnotallowed: "Validação anônima não permitida",
          requiredfieldemail: "E-mail obrigatório",
          requiredfieldname: "Nome é obrigatório.",
          couponnotfound: "Nenhum Cupom Encontrado!",
          requiredfieldcouponcode: "Preencher o código do cupom!",
          couponsuccessfullyregistered: "Cupom registrado com sucesso!",
          autherror: "Erro de Credenciais.",
          valueneedtobegreaterthanzero: "Valor final não pode ser negativo.",
          requiredfieldnameandemail: "Nome/Email são obrigatórios.",
          rejectmessagenamerequired: "Nome é obrigatório",
          rejectmessageemailrequired: "E-mail é obrigatório",
          rejectmessagecampaigndate: "Período da campanha ainda não iniciado!",
          rejectmessagecampaignended: "Período da campanha finalizado!",
          rejectmessageinvalidinterval:
            "Fora do intervalo válido de horário para uso!",
          rejectmessageinvalidvalue: "Valor total da compra não pode ser R$ 0",
        },
      },
      landingpagemodule: {
        createpage: {
          includeform: "Adicionar Formulário"
        }
      },
      addbranch: {
        btnnew: "Novo",
        labelname: "Nome",
        placeholdername: "Nome",
        labelemail: "E-mail",
        placeholderemail: "Email",
        labeltype: "Tipo:",
        labelperson: "Pessoa Física:",
        labelcorporate: "Pessoa Jurídica:",
        placeholderidentifiertype: "Tipo de Cadastro",
        labelidentifiertype: "CNPJ/CPF:",
        labelcompanyname: "Razão Social:",
        labelcompanynameas: "Nome Fantasia:",
        labelzipcode: "CEP:",
        labelcityregion: "Estado/Cidade:",
        labelregion: "Estado:",
        labelcity: "Cidade:",
        labeladdressline1: "Rua:",
        placeholderaddressline1: "Rua",
        placeholdernumber: "Número",
        labeladdressline2: "Complemento:",
        placeholderaddressline2: "Complemento",
        labelneighborhood: "Bairro:",
        placeholderneighborhood: "Bairro",
        labelwebsite: "WebSite:",
        placeholderwebsite: "WebSite",
        labelfacebook: "Facebook:",
        placeholderfacebook: "Facebook",
        labelinstagram: "Instagram:",
        placeholderinstagram: "Instagram",
        labelwhatsup: "Whatsup:",
        labelphonenumber: "Telefone:",
        placeholderphonenumber: "Telefone",
        labelmobil: "Celular:",
        placeholdermobil: "Celular",
        labelmaps: "MAPS (Long/Lat):",
        placeholdermapslog: "Longitude",
        placeholdermapslat: "Latitude",
        labellogo: "Logomarca:",
        bntreset: "Resetar",
        btnsave: "Gravar",
        notify: {
          brachsuccessfullyupdated: "Dados Atualizados Com Sucesso!",
          branchsuccessfullycreated: "Dados Gravados Com Sucesso!",
          verifyrequiredfields: "Verifique os campos obrigatórios!",
        },
        message: {
          requiredfieldname: "Nome requerido",
          requiredfieldemail: "E-mail requerido",
          emailalreadyregistered: "E-mail já registrado.",
          invalidemail: "Email inválido",
          identifieralreadyregistered: "CNPJ/CPF já cadastrados.",
          requiredfieldcompanynameas: "Nome Fantasia requerido.",
        },
      },
      addoperators: {
        btnnew: "Novo",
        labelname: "Nome:",
        placeholdername: "Nome:",
        labelemail: "Email:",
        placeholder: "Email",
        labelestablishment: "Estabelecimento:",
        labeldeactivate: "Desativar:",
        labelactivate: "Ativar:",
        labelupdate: "Atualizar:",
        labelregister: "Cadastrar:",
        labeloperatoraccess: "Acesso Operador:",
        notify: {
          useractivatedsuccessfully: "Usuário ativado com sucesso!",
          userdeactivatedsuccessfully: "Usuário desativado com sucesso!",
          operatorupdatedsuccessfully: "Operador atualizado com sucesso!",
          operatorcreatedsuccessfully: "Operador cadastrado com sucesso!",
          genericerror: "Algum erro ocorreu.",
          establishmentrequired: "Selecione um Estabelecimento.",
          emailalreadyinuse: "Email já em uso!",
          emailrequired: "Email obrigatório!",
          namerequired: "Nome obrigatório",
        },
        message: {
          namerequired: "Nome requerido.",
          emailrequired: "Email requerido.",
          emailinvalid: "Email inválido.",
          emailalreadyregistered: "Email já regsitrado.",
          firstgeneric:
            "O Operador receberá um e-mail com um link para criar sua senha.",
          secondgeneric:
            "O Acesso do operador é feito via link instalável tanto no desktop quanto no celular (PWA).",
        },
      },
      account: {
        profilepic: "Foto Perfil",
        labelname: "Nome:",
        labelemail: "E-mail:",
        labeltype: "Tipo:",
        labelperson: "Pessoa Física:",
        labelcorporate: "Pessoa Jurídica:",
        placeholderidentifiertype: "Tipo de Cadastro",
        labelidentifiertype: "CNPJ/CPF:",
        labelcompanyname: "Razão Social/Nome:",
        labelcompanynameas: "Nome Fantasia:",
        labelzipcode: "CEP:",
        labelcityregion: "Estado/Cidade:",
        labelregion: "Estado:",
        labelcity: "Cidade:",
        labeladdressline1: "Rua:",
        placeholderaddressline1: "Rua",
        placeholdernumber: "Número",
        labeladdressline2: "Complemento:",
        placeholderaddressline2: "Complemento",
        labelneighborhood: "Bairro:",
        placeholderneighborhood: "Bairro",
        labelwebsite: "WebSite:",
        labelcardnumber: "Número do Cartão:",
        labelvalidate: "Validade:",
        cvc: "CVC",
        placeholderwebsite: "WebSite",
        btnupdate: "Atualizar Conta",
        labelpaperpayment: "Boleto PIX",
        pixCopy: "PIX Copia e Cola",
        labelcreatepaperpayment: "Gerar Boleto",
        labelmakepayment: "Efetuar Pagamento",
        labelcreditcards: "Cartões de Crédito",
        labelcardname: "Nome como no cartão:",
        labelcompanytype: "CNPJ/CPF Assinatura:",
        labelextracredit: "Crédito Extra:",
        labelEmail: "Email Remetente:",
        allowrenew: "Permitir Renovação Automática",
        activate: "Ativar",
        deactivate: "Desativar",
        monthlypayment: "Plano Assinatura: R$",
        btnprofiledata: "Dados Cadastrais",
        btnsign: "Assinatura",
        btnpayments: "Pagamentos",
        btnconfigurations: "Configurações",
        grid: {
          searchplaceholder: "Buscar",
          columnlabelcreatedAt: "Data",
          columnlabeldescription: "Descritivo",
          columnlabelsourceType: "Origem",
          columnamount: "Valor",
          columnstatus: "Status",
          paid: "Pago",
          credit: "Crédito",
          debit: "Débito",
          currency: "R$",
          columnlabelemail: "Email Remetente",
          columnlabelresend: "Reenviar",
          activeStatus: "Disponível",
          inactiveStatus: "Pendente"
        },

        notify: {
          identifieralert:
            "CPF/CNPJ Não é obrigatório, mas se informar precisa indicar se é pessoa física ou jurídica!",
        },
        message: {
          generic: "Boleto PIX: Liberação imediata.",
          // requiredfieldname: "Nome requerido",
          // requiredfieldemail: "E-mail requerido",
          // emailalreadyregistered: "E-mail já registrado.",
          // invalidemail: "Email inválido",
          identifieralreadyregistered: "CNPJ/CPF já cadastrados.",
          senderemailalreadyregistered: "Email já registrado em outra conta!",
          resendemail: "Email reenviado com sucesso!",
          cantdeleteemail: "Não posso deletar, precisa haver ao menos um e-mail rementente!",
          identifieralert: "Defina o tipo de Pessoa!"
          // requiredfieldcompanynameas: "Nome Fantasia requerido.",
        },
        support: {
          labellimit: "Limite de Campanhas Ativas: ",
          labelused: "Utilizado ",
          labelcontactslimit: "Limite de Contatos Cadastrados: ",
          labelemailsendlimit: "Limite de Envio de E-mails: ",
          labelextraestablishments: "Estabelecimentos Extras: ",
        },
      },
      toprightmenu: {
        videoclass: "Vídeo Aula",
        labelwelcome: "Bem Vindo(a)",
        lblaccount: "Minha Conta",
        labelsenderemail: "Email Remetente",
        labelconfigurations: "Configurações",
        labellogout: "Logout",
      },

      logedout: {
        confirmemail: {
          buttonaction: "Reenviar Link",
          sending: "Enviando...",
          accountreadymessage: "Conta já verificada e pronta para uso!",
          accountreadysubmessage: "Clique no link abaixo para logar.",
          activatelinkmessage: "Link de Ativação da Conta Enviado Com Sucesso",
          activatelinksubmessage:
            "Verifique na caixa anti-spam e clique no link para ativar sua nova conta.",
          accountsuccessfulyverified: "Conta verificada com sucesso.",
          accountsuccessfylyverifiedsubmessage:
            "Sua conta está pronta para ser usada.",
          expiredtoken: "Token expirado.",
          expiredtokenmessage:
            "Por favor clique novamente no botão abaixo para enviar um novo link para seu e-mail!",

          resendlinkmessage:
            "Link de ativação da conta re-enviado com sucesso.",
          resendlinksubmessage:
            "Verifique na caixa anti-spam e clique no link para ativar sua nova conta.",
          loginask: "Deseja fazer login?",
          loginlabel: "Login:",
          salesmessage: "Adoro Vender Mais!",
          salessubmessage: "É muito fácil de usar. Adoro muito isso!",
          salesfooter: "- Cliente Plataforma TooWeze",
          notify: {
            usernotfound: "Usuário não encontrado.",
            needacceptterms: "Aceite os termos",
            invalidtoken: "Token Inválido",
            invalidtokenmessage:
              "Não é possível utilizar este link/token para validar sua conta!",
          },
        },
      },
      createnewpassword: {
        labelnewpassword: "Criar Nova Senha:",
        labeltypenewpassword: "Digite uma nova senha:",
        labelpassword: "Senha:",
        btnnew: "Criar",
        message: {
          passwordsuccess: "Senha Criada Com Sucesso!",
          required: "Senha Requerida.",
          passwordmessage:
            "Mínimo de 8 dígitos, letra maíscula, mínuscula e um número.",
          confirmpassword: "Confirmar a Senha é obrigatório!",
          invalidpassword: "Senhas Diferentes!",
          errormessage: "Erro!",
        },
        notify: {
          invalidtoken: "Token Inválido.",
          expiredtoken: "Token expirado.",
          paswordmessage: "Reset da senha realizado com sucesso!",
          invalidmessage: "Não válido.",
        },
        loginask: "Deseja fazer login?",
        loginlabel: "Login",
        salesmessage: "Adoro Vender Mais!",
        salessubmessage: "É muito fácil de usar. É Tooweze!",
        salesfooter: "- Cliente Plataforma TooWeze",
      },
      recoverypassword: {
        labelrecoverypassword: "Recuperar Senha",
        messagerecoverypassword:
          "Digite seu e-mail, você receberá um link para resetar sua senha.",
        labelemail: "Email",
        labelreset: " Resetar Senha ",
        successmessage: "Link Enviado Com Sucesso",
        successmessagepart2: "Verifique na caixa anti-spam ",
        successmessagepart3: "e clique no link para criar uma nova senha.",
        message: {
          required: "Email requerido",
          invalid: "Email inválido",
        },
        notify: {
          emailnotfound: "Email não cadastrado!",
        },
        loginask: "Deseja fazer login?",
        loginlabel: " Login",
        salesmessage: "Adoro Vender Mais!",
        salessubmessage: "É muito fácil de usar. Adoro muito isso!",
        salesfooter: "- Cliente Plataforma TooWeze",
      },
      registeraccount: {
        calltoactiontext1: "Cadastre-se e ganhe 7 dias Grátis",
        calltoactiontext2:
          "Não tem uma conta ainda? Cria agora, leva menos de um minuto!",
        calltoactiontext3: "Não precisa Cartão de Crédito! Sem Compromisso!",
        companyname: "Empresa",
        website: "WebSite",
        labelname: "Nome Completo",
        labelemail: "Email",
        labelpassword: "Senha",
        labeltermsaccept: "Eu aceito ",
        labelterms: "Termos e Condições",

        message: {
          companynamerequired: "Nome da empresa obrigatório.",
          companywebsiterequired: "WebSite obrigatório.",
          companywebsiteinvalid: "URL inválida.",
          namerequired: "Nome requerido",
          emailrequired: "Email requirido",
          emailinvalid: "Email inválido",
          passwordrequired: "Senha Requerida",
          passwordinvalid:
            "Sua senha precisa ter no mínimo 8 dígitos, letra maíscula, mínuscula e um número.",
        },
        notify: {
          alreadyregistered: "Usuário já cadastrado.",
          requiredlenght: "Nome precisa ter ao menos 5 caracteres!",
          needacceptterms: "Aceite os termos",
          requiredfields: "Verifique os campos!",
        },
        loginask2: "Já possui uma conta? ",
        loginlabel: " Login",
        salesmessage: "Adoro Vender Mais!",
        salessubmessage: "É muito fácil de usar. Adoro muito isso!",
        salesfooter: "- Cliente Plataforma TooWeze",
      },
      login: {
        labelemailpassword: "Digite seu e-mail e senha para começar.",
        labelemail: "Email",
        labelpassword: "Senha",
        labelforgetpassword: "Esqueceu sua senha?",
        labelaccount: "Ainda não possui uma conta? ",
        labelregister: "Cadastre-se",
        labellogin: "LogIn",
        message: {
          emailrequired: "Email requerido.",
          invalidemail: "Email inválido",
          passwordrequired: "Senha Requerida",
        },
        notify: {
          userpassword: "Usuário/Senha inválidos!",
          passwordlength: "Senha precisa ter ao menos 5 caracteres!",
          confirmemail: "Confirme seu email primeiro!",
        },
        salesmessage: "Adoro Vender Mais!",
        salessubmessage: "É muito fácil de usar. Adoro muito isso!",
        salesfooter: "- Cliente Plataforma TooWeze",
      },
    },
  },
};

export { messages };
