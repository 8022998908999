import React, { Component } from "react";
import "./Auth.css";
import logo from "../../images/complet_logo_2.png";

import { LoadPanel } from "devextreme-react/load-panel";
import { TextBox, TextArea } from "devextreme-react";
import { SelectBox } from "devextreme-react";
import {
  Validator,
  RequiredRule,
  CompareRule,
  EmailRule,
  PatternRule,
  StringLengthRule,
  RangeRule,
  AsyncRule,
} from "devextreme-react/validator";
import { Button } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { Link } from "react-router-dom";
import { recoverPassword } from "../../apis/toowezeApi";
import { i18n } from "../../translate/i18n";
export default class CreateNewPassWord extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadPanelVisible: false,
      allPanelVisible: false,
      goValidateEmail: false,
      emailCheck: false,
      photo: "9",
      checked: false,
      passWordIsValid: false,
      created: false,
      urlToken: null,
    };
  }

  componentWillMount() {
    document.body.style.backgroundColor = "white";
    this.setState({
      photo: Math.round(Math.random() * 8).toString(),
    });
  }

  componentDidMount() {
    document.body.style.backgroundColor = "white";

    // Limpa as informações de Login
    localStorage.removeItem("auth");
    localStorage.removeItem("tooweze_token");
    localStorage.removeItem("isConfigured");
    // Limpa as informações de Login

    var urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("hash")) {
      this.setState({
        urlToken: urlParams.get("hash"),
      });
    }
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  // Administra as mudanças de valores dos TextBox
  handleValueChange = (event, fieldId) => {
    if (fieldId) {
      if (fieldId === "checked") {
        this.setState({
          [fieldId]: event.target.checked ? event.target.checked : false,
        });
      } else {
        this.setState({
          [fieldId]: event.value ? event.value : null,
        });
      }
    }
  };

  // Faz login na conta
  changeThePassWord = (event) => {
    if (
      this.state.passWordIsValid === true &&
      this.state.password != "" &&
      this.state.password != null
    ) {
      recoverPassword(this.state.urlToken, this.state.password)
        .then((res) => {
          if (res.data.msg === "Invalid Token!") {
            this.showNotify(
              i18n.t("createnewpassword.notify.invalidtoken"),
              "error"
            );
          } else if (res.data.msg === "Token expired!") {
            this.showNotify(
              i18n.t("createnewpassword.notify.expiredtoken"),
              "error"
            );
          } else if (res.data.msg === "Password reset with success!") {
            this.setState({ created: true });
          }
        })
        .catch((error) => {
          if (error.response) {
            this.showNotify(error.response.data, "error");
          }
        });
    } else {
      this.showNotify(
        i18n.t("createnewpassword.notify.invalidmessage"),
        "error"
      );
    }
  };

  checkIsValid = (event, type) => {
    if (type === "passWordIsValid") {
      let patt = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
      let res = patt.test(event.value);
      this.setState({
        [type]: event.isValid === true && res === true ? true : false,
      });
    } else {
      this.setState({
        [type]: event.isValid,
      });
    }
  };

  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "middle", offset: "-450 -250" },
        closeOnClick: true,
      },
      type,
      3000
    );
  }

  passwordComparison = () => {
    return this.state.password;
  };

  render() {
    let resultHTML;

    if (this.state.created === true) {
      resultHTML = (
        <>
          <h4 className="mt-0" style={{ textAlign: "center" }}>
            {i18n.t("createnewpassword.message.passwordsuccess")}
          </h4>
          <div style={{ textAlign: "center" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="100px"
              viewBox="0 0 510 510"
            >
              <linearGradient
                id="SVGID_1_"
                x1="140.994"
                x2="369.185"
                y1="239.641"
                y2="467.831"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#d4453a"></stop>
                <stop offset="1" stopColor="#f9b831"></stop>
              </linearGradient>
              <linearGradient
                id="SVGID_2_"
                x1="157.718"
                x2="352.436"
                y1="256.364"
                y2="451.082"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#edf5ff"></stop>
                <stop offset="1" stopColor="#d5e8fe"></stop>
              </linearGradient>
              <linearGradient id="lg1">
                <stop offset="0" stopColor="#b5dbff" stopOpacity="0"></stop>
                <stop
                  offset="0.173"
                  stopColor="#8fc5e9"
                  stopOpacity="0.173"
                ></stop>
                <stop
                  offset="0.454"
                  stopColor="#56a4c8"
                  stopOpacity="0.454"
                ></stop>
                <stop
                  offset="0.696"
                  stopColor="#2d8db1"
                  stopOpacity="0.696"
                ></stop>
                <stop
                  offset="0.885"
                  stopColor="#147ea2"
                  stopOpacity="0.885"
                ></stop>
                <stop offset="1" stopColor="#0b799d"></stop>
              </linearGradient>
              <linearGradient
                id="SVGID_3_"
                x1="355.864"
                x2="258.531"
                y1="396.353"
                y2="191.686"
                gradientUnits="userSpaceOnUse"
                xlinkHref="#lg1"
              ></linearGradient>
              <linearGradient
                id="SVGID_4_"
                x1="141.955"
                x2="-114.644"
                y1="394.529"
                y2="234.313"
                gradientUnits="userSpaceOnUse"
                xlinkHref="#lg1"
              ></linearGradient>
              <linearGradient
                id="SVGID_5_"
                x1="244.542"
                x2="-12.057"
                y1="390.282"
                y2="230.066"
                gradientUnits="userSpaceOnUse"
                xlinkHref="#lg1"
              ></linearGradient>
              <linearGradient
                id="SVGID_6_"
                x1="357.841"
                x2="101.245"
                y1="392.722"
                y2="232.508"
                gradientUnits="userSpaceOnUse"
                xlinkHref="#lg1"
              ></linearGradient>
              <linearGradient
                id="SVGID_7_"
                x1="441.836"
                x2="278.207"
                y1="376.868"
                y2="274.701"
                gradientUnits="userSpaceOnUse"
                xlinkHref="#lg1"
              ></linearGradient>
              <linearGradient id="lg2">
                <stop offset="0" stopColor="#b9dd39"></stop>
                <stop offset="1" stopColor="#0b799d"></stop>
              </linearGradient>
              <linearGradient
                id="SVGID_8_"
                x1="90.915"
                x2="90.915"
                y1="321.333"
                y2="422.667"
                gradientUnits="userSpaceOnUse"
                xlinkHref="#lg2"
              ></linearGradient>
              <linearGradient
                id="SVGID_9_"
                x1="200.305"
                x2="200.305"
                y1="321.333"
                y2="422.667"
                gradientUnits="userSpaceOnUse"
                xlinkHref="#lg2"
              ></linearGradient>
              <linearGradient
                id="SVGID_10_"
                x1="309.695"
                x2="309.695"
                y1="321.333"
                y2="422.667"
                gradientUnits="userSpaceOnUse"
                xlinkHref="#lg2"
              ></linearGradient>
              <linearGradient
                id="SVGID_11_"
                x1="419.085"
                x2="419.085"
                y1="321.333"
                y2="422.667"
                gradientUnits="userSpaceOnUse"
                xlinkHref="#lg2"
              ></linearGradient>
              <linearGradient
                id="SVGID_12_"
                x1="201.215"
                x2="283.522"
                y1="72.426"
                y2="154.734"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#edf5ff"></stop>
                <stop offset="1" stopColor="#b5dbff"></stop>
              </linearGradient>
              <linearGradient
                id="SVGID_13_"
                x1="251.688"
                x2="251.688"
                y1="131.627"
                y2="-65.726"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#b5dbff" stopOpacity="0"></stop>
                <stop
                  offset="0.243"
                  stopColor="#93cef6"
                  stopOpacity="0.243"
                ></stop>
                <stop
                  offset="0.576"
                  stopColor="#6abfec"
                  stopOpacity="0.576"
                ></stop>
                <stop
                  offset="0.84"
                  stopColor="#51b5e5"
                  stopOpacity="0.84"
                ></stop>
                <stop offset="1" stopColor="#48b2e3"></stop>
              </linearGradient>
              <linearGradient id="lg3">
                <stop offset="0" stopColor="#ffda2d"></stop>
                <stop offset="1" stopColor="#fdbf00"></stop>
              </linearGradient>
              <linearGradient
                id="SVGID_14_"
                x1="200.312"
                x2="292"
                y1="170.187"
                y2="261.875"
                gradientUnits="userSpaceOnUse"
                xlinkHref="#lg3"
              ></linearGradient>
              <linearGradient
                id="SVGID_15_"
                x1="295.081"
                x2="203.622"
                y1="260.426"
                y2="168.967"
                gradientUnits="userSpaceOnUse"
                xlinkHref="#lg3"
              ></linearGradient>
              <linearGradient
                id="SVGID_16_"
                x1="286.411"
                x2="214.735"
                y1="251.763"
                y2="180.087"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#0b799d"></stop>
                <stop offset="1" stopColor="#07485e"></stop>
              </linearGradient>
              <linearGradient
                id="SVGID_17_"
                x1="255"
                x2="255"
                y1="282.671"
                y2="310.286"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#ff9100" stopOpacity="0"></stop>
                <stop offset="1" stopColor="#ff9100"></stop>
              </linearGradient>
              <linearGradient
                id="SVGID_18_"
                x1="255"
                x2="255"
                y1="426.151"
                y2="469.441"
                gradientUnits="userSpaceOnUse"
                xlinkHref="#lg1"
              >
                <stop offset="0" stopColor="#ff9100" stopOpacity="0"></stop>
                <stop offset="1" stopColor="#ff9100"></stop>
              </linearGradient>
              <path
                fill="url(#SVGID_1_)"
                d="M487.362 463.63H22.638C10.135 463.63 0 453.495 0 440.992V266.3c0-12.503 10.135-22.638 22.638-22.638h464.724c12.503 0 22.638 10.135 22.638 22.638v174.692c0 12.503-10.135 22.638-22.638 22.638z"
              ></path>
              <path
                fill="url(#SVGID_2_)"
                d="M464.723 436.358H45.277c-10.259 0-18.575-8.316-18.575-18.575V289.509c0-10.259 8.316-18.575 18.575-18.575h419.445c10.259 0 18.575 8.316 18.575 18.575v128.273c.001 10.26-8.315 18.576-18.574 18.576z"
              ></path>
              <path
                fill="url(#SVGID_3_)"
                d="M160.499 304.81l158.82 158.82h168.044c12.503 0 22.638-10.135 22.638-22.638v-45.771L358.442 243.662H151.558v43.277c0 7.309 3.513 13.798 8.941 17.871z"
              ></path>
              <path
                fill="url(#SVGID_4_)"
                d="M242.505 436.36H105.64l-44.671-44.67c-3.151-1.933-5.681-4.953-5.681-8.879 0-2.84 1.086-5.239 2.614-6.994l7.077-10.073-17.102-17.579c-3.855-1.635-5.705-5.609-5.705-10.598 0-5.681 5.025-12.03 12.018-12.03.656 0 1.754 0 3.282.656l22.95 7.435-1.098-22.294c.227-7.22 5.693-10.49 11.803-10.49 4.595 0 8.7 1.838 10.55 5.812l21.076 20.312 2.268-.776c1.086-.43 2.184-.656 3.27-.656 3.938 0 7.137 2.447 9.118 5.729l35.935 35.935z"
              ></path>
              <path
                fill="url(#SVGID_5_)"
                d="M351.896 436.36H215.031l-44.671-44.671c-3.151-1.933-5.681-4.953-5.681-8.879 0-2.84 1.086-5.239 2.614-6.994l6.051-8.617 1.026-1.456-17.102-17.579c-3.855-1.635-5.705-5.609-5.705-10.598 0-5.681 5.024-12.03 12.018-12.03.656 0 1.754 0 3.282.656l22.95 7.435-1.098-22.294c.227-7.22 5.693-10.49 11.803-10.49 4.595 0 8.688 1.838 10.55 5.812l21.076 20.312 2.256-.776c1.098-.43 2.196-.656 3.282-.656 3.938 0 7.137 2.447 9.118 5.729z"
              ></path>
              <path
                fill="url(#SVGID_6_)"
                d="M461.276 436.36H324.423l-44.671-44.671c-3.151-1.933-5.693-4.953-5.693-8.879 0-2.84 1.098-5.239 2.626-6.994l6.051-8.617 1.026-1.456-17.114-17.579c-3.843-1.635-5.705-5.609-5.705-10.598 0-5.681 5.036-12.03 12.03-12.03.656 0 1.742 0 3.282.656l22.95 7.435-1.098-22.294c.227-7.22 5.681-10.49 11.803-10.49 4.595 0 8.688 1.838 10.55 5.812l21.076 20.312 2.255-.776c1.098-.43 2.184-.656 3.282-.656 3.938 0 7.125 2.447 9.118 5.729l35.935 35.935z"
              ></path>
              <path
                fill="url(#SVGID_7_)"
                d="M483.295 348.976v68.802c0 10.264-8.318 18.582-18.57 18.582h-30.922l-44.671-44.671c-3.139-1.933-5.681-4.953-5.681-8.879 0-2.84 1.098-5.239 2.626-6.994l6.051-8.617 1.027-1.456-17.114-17.579c-3.843-1.635-5.704-5.609-5.704-10.598 0-5.681 5.036-12.03 12.03-12.03.656 0 1.742 0 3.27.656l22.962 7.435-1.098-22.294c.215-7.22 5.681-10.49 11.803-10.49 4.595 0 8.688 1.838 10.55 5.812l21.064 20.312 2.268-.776c1.098-.43 2.184-.656 3.282-.656 3.938 0 7.125 2.447 9.118 5.729z"
              ></path>
              <path
                fill="url(#SVGID_8_)"
                d="M125.235 376.037c1.093 1.311 1.748 3.935 1.748 6.339 0 6.995-6.995 11.804-13.334 11.804-2.404 0-5.901-.437-8.306-4.153l-14.209-21.86-14.427 22.078c-1.967 3.279-4.59 3.935-7.651 3.935-5.247 0-13.771-4.153-13.771-11.367 0-2.841 1.093-5.246 2.623-6.995l14.427-20.548-22.733-6.558c-5.028-1.093-7.433-5.465-7.433-11.148s5.028-12.023 12.023-12.023c.656 0 2.598.073 4.127.729l22.104 7.359-1.094-22.296c.219-7.213 5.684-10.492 11.804-10.492 6.121 0 11.367 3.279 11.586 10.492l-.875 22.734 23.171-7.87c1.093-.437 2.186-.656 3.279-.656 6.777 0 11.367 7.214 11.367 13.553 0 4.372-2.186 8.525-7.432 9.618l-22.297 5.246z"
              ></path>
              <path
                fill="url(#SVGID_9_)"
                d="M234.625 376.037c1.093 1.311 1.748 3.935 1.748 6.339 0 6.995-6.995 11.804-13.334 11.804-2.404 0-5.901-.437-8.306-4.153l-14.209-21.86-14.427 22.078c-1.967 3.279-4.59 3.935-7.651 3.935-5.247 0-13.771-4.153-13.771-11.367 0-2.841 1.093-5.246 2.623-6.995l14.427-20.548-22.733-6.558c-5.028-1.093-7.433-5.465-7.433-11.148s5.028-12.023 12.023-12.023c.656 0 1.749 0 3.279.656l22.953 7.432-1.094-22.296c.219-7.213 5.684-10.492 11.804-10.492 6.121 0 11.367 3.279 11.586 10.492l-.875 22.734 23.171-7.87c1.093-.437 2.186-.656 3.279-.656 6.777 0 11.367 7.214 11.367 13.553 0 4.372-2.186 8.525-7.432 9.618l-22.297 5.246z"
              ></path>
              <path
                fill="url(#SVGID_10_)"
                d="M344.015 376.037c1.093 1.311 1.748 3.935 1.748 6.339 0 6.995-6.995 11.804-13.334 11.804-2.404 0-5.901-.437-8.306-4.153l-14.209-21.86-14.427 22.078c-1.967 3.279-4.59 3.935-7.651 3.935-5.247 0-13.771-4.153-13.771-11.367 0-2.841 1.093-5.246 2.623-6.995l14.427-20.548-22.733-6.558c-5.028-1.093-7.433-5.465-7.433-11.148s5.028-12.023 12.023-12.023c.656 0 1.749 0 3.279.656l22.953 7.432-1.094-22.296c.219-7.213 5.684-10.492 11.804-10.492 6.121 0 11.367 3.279 11.586 10.492l-.875 22.734 23.171-7.87c1.093-.437 2.186-.656 3.279-.656 6.777 0 11.367 7.214 11.367 13.553 0 4.372-2.186 8.525-7.432 9.618l-22.297 5.246z"
              ></path>
              <path
                fill="url(#SVGID_11_)"
                d="M453.405 376.037c1.093 1.311 1.748 3.935 1.748 6.339 0 6.995-6.995 11.804-13.334 11.804-2.404 0-5.901-.437-8.306-4.153l-14.209-21.86-14.427 22.078c-1.967 3.279-4.59 3.935-7.651 3.935-5.247 0-13.771-4.153-13.771-11.367 0-2.841 1.093-5.246 2.623-6.995l14.427-20.548-22.733-6.558c-5.028-1.093-7.433-5.465-7.433-11.148s5.028-12.023 12.023-12.023c.656 0 1.749 0 3.279.656l22.953 7.432-1.094-22.296c.219-7.213 5.684-10.492 11.804-10.492 6.121 0 11.367 3.279 11.586 10.492l-.875 22.734 23.171-7.87c1.093-.437 2.186-.656 3.279-.656 6.777 0 11.367 7.214 11.367 13.553 0 4.372-2.186 8.525-7.432 9.618l-22.297 5.246z"
              ></path>
              <g>
                <path
                  fill="url(#SVGID_12_)"
                  d="M339.654 147.841h-31.945v-13.506c0-30.89-25.131-56.021-56.021-56.021s-56.021 25.131-56.021 56.021v13.506h-31.945v-13.506c0-48.504 39.461-87.966 87.966-87.966 48.504 0 87.966 39.461 87.966 87.966z"
                ></path>
                <path
                  fill="url(#SVGID_13_)"
                  d="M251.688 63.666c-40.237 0-72.972 32.735-72.972 72.972v11.203h16.951v-13.506c0-30.89 25.131-56.021 56.021-56.021s56.021 25.131 56.021 56.021v13.506h16.951v-11.203c0-40.237-32.736-72.972-72.972-72.972z"
                ></path>
                <g>
                  <path
                    fill="url(#SVGID_14_)"
                    d="M336.111 309.27H173.889c-12.333 0-22.33-9.998-22.33-22.33V162.81c0-12.333 9.998-22.33 22.33-22.33h162.223c12.333 0 22.33 9.998 22.33 22.33v124.13c0 12.332-9.998 22.33-22.331 22.33z"
                  ></path>
                  <path
                    fill="url(#SVGID_15_)"
                    d="M294.656 208.965c0-21.901-17.755-39.656-39.656-39.656s-39.656 17.755-39.656 39.656c0 15.505 8.904 28.922 21.872 35.443v18.249c0 9.822 7.962 17.784 17.784 17.784s17.784-7.962 17.784-17.784v-18.249c12.969-6.521 21.872-19.938 21.872-35.443z"
                  ></path>
                  <path
                    fill="url(#SVGID_16_)"
                    d="M255 270.131c-4.121 0-7.475-3.353-7.475-7.475v-24.605l-5.678-2.855c-9.988-5.022-16.193-15.073-16.193-26.232 0-16.182 13.165-29.346 29.346-29.346s29.346 13.165 29.346 29.346c0 11.158-6.205 21.21-16.193 26.232l-5.678 2.855v24.605c0 4.122-3.354 7.475-7.475 7.475z"
                  ></path>
                  <path
                    fill="url(#SVGID_17_)"
                    d="M151.558 253.143v33.796c0 12.333 9.998 22.33 22.33 22.33h162.223c12.333 0 22.33-9.998 22.33-22.33v-33.796z"
                  ></path>
                </g>
              </g>
              <path
                fill="url(#SVGID_18_)"
                d="M0 394.18v46.812c0 12.503 10.135 22.638 22.638 22.638h464.724c12.503 0 22.638-10.135 22.638-22.638V394.18z"
              ></path>
            </svg>
          </div>
        </>
      );
    } else if (this.state.urlToken != null) {
      resultHTML = (
        <>
          <h4 className="mt-0">
            {i18n.t("createnewpassword.labelnewpassword")}
          </h4>
          <p className="text-muted mb-4">
            {i18n.t("createnewpassword.labeltypenewpassword")}
          </p>

          <div>
            <form>
              <div className="dx-field">
                <div className="dx-label">
                  {i18n.t("createnewpassword.labelpassword")}
                </div>
                <div className="dx-value">
                  <TextBox
                    showClearButton={true}
                    mode="password"
                    value={this.state.password}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "password")
                    }
                  >
                    <Validator>
                      <RequiredRule
                        message={i18n.t("createnewpassword.message.required")}
                      />
                    </Validator>
                  </TextBox>
                </div>
                <span
                  style={{
                    fontSize: "12px",
                    color: "darkred",
                    display: this.state.passwordIsValid ? "none" : "block",
                  }}
                >
                  {i18n.t("createnewpassword.message.passwordmessage")}
                </span>
              </div>

              <div className="dx-field">
                <div className="dx-label">
                  {i18n.t("createnewpassword.labelpassword")}
                </div>
                <div className="dx-value">
                  <TextBox mode="password">
                    <Validator
                      onValidated={(event) =>
                        this.checkIsValid(event, "passWordIsValid")
                      }
                    >
                      <RequiredRule
                        message={i18n.t(
                          "createnewpassword.message.confirmpassword"
                        )}
                      />
                      <CompareRule
                        message={i18n.t(
                          "createnewpassword.message.invalidpassword"
                        )}
                        comparisonTarget={this.passwordComparison}
                      />
                    </Validator>
                  </TextBox>
                </div>
              </div>

              <div className="form-group mb-0 text-center">
                <Button
                  text={i18n.t("createnewpassword.btnnew")}
                  height={30}
                  width={"100%"}
                  type="success"
                  onClick={this.changeThePassWord}
                ></Button>
              </div>

              <div className="text-center mt-4">
                <p id="output" className="text-muted font-16"></p>
              </div>
            </form>
          </div>
        </>
      );
    } else {
      resultHTML = (
        <div style={{ textAlign: "center", color: "red" }}>
          {i18n.t("createnewpassword.message.errormessage")}
        </div>
      );
    }

    return (
      <div className="row col-12 p-0 m-0">
        <div id="7" className="col-12 col-sm-4" style={{ paddingRight: "29px", paddingLeft: "0px" }}>
          <div className="logoFirst">
            <img className="logoExterno" src={logo} alt="Logo" />
          </div>

          <div className="auth-fluid-form-box" style={{ paddingTop: "8%" }}>
            <div className="align-items-center d-flex h-100">
              <div className="card-body" style={{ paddingRight: "4px" }}>
                <div className="auth-brand text-center text-lg-left"></div>

                {resultHTML}

                <footer>
                  <p className="text-muted" style={{ textAlign: "center" }}>
                    {i18n.t("createnewpassword.loginask")}
                    <Link to="login">
                      <b> {i18n.t("createnewpassword.loginlabel")}</b>
                    </Link>
                  </p>
                </footer>
              </div>
            </div>
          </div>
        </div>
        <div
          id="6"
          className="auth d-none d-sm-block col-sm-8"
          style={{
            top: "-17px",
            right: "-50px",
            background: `url('/images/capa_${this.state.photo}.jpg') 50% no-repeat`,
            backgroundSize: "cover",
          }}
        >
          {/* <!--Parte do meio--> */}
          <div className="saleText text-center">
            <h2 className="mb-3">{i18n.t("createnewpassword.salesmessage")}</h2>
            <p className="saleTextFirst">
              {i18n.t("createnewpassword.salessubmessage")}
            </p>
            <p>{i18n.t("createnewpassword.salesfooter")}</p>
          </div>
        </div>
      </div>
    );
  }
}
