import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
// import {
//   SignedIn,
//   SignedOut,
//   SignInButton,
//   UserButton,
//   useUser
// } from "@clerk/clerk-react";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./components/CouponModule/Validation/ValidationMiddle.css";
import HomeComponent from "./components/HomeComponent";

import CupomBuilder from "./components/CouponModule/BuildCanvas/CupomBuilder";
import ViewCoupons from "./components/CouponModule/ViewCoupon/ViewCoupons";
import AddBranch from "./components/AddBranch/AddBranch";
import Login from "./components/auth/Login";
import LogOut from "./components/auth/LogOut";
import ConfirmEmail from "./components/auth/ConfirmEmail";
import ConfirmAllEmails from "./components/auth/ConfirmAllEmails";
import RecoveryPassWord from "./components/auth/RecoveryPassWord";
import Register from "./components/auth/RegisterAccount";
import CreateNewPassWord from "./components/auth/CreateNewPassWord";
import Account from "./components/Account/Account";
import AddOperators from "./components/AddOperators/AddOperators";
import EmailMKTStats from "./components/DashBoard/EmailMKTStats/EmailMKTStats";
import SalesStats from "./components/DashBoard/SalesStats/SalesStats";
import Transactions from "./components/DashBoard/Transactions/Transactions";

// Módulo Comunicação
import Contacts from "./components/ComunicationModule/Contacts/Contacts";
import ContactsView from "./components/ComunicationModule/ContactsView/ContactsView";
import CustomersView from "./components/Customers/CustomersView/CustomersView";
import AddEditCustomers from "./components/Customers/AddEditCustomers/AddEditCustomers";
import ReportsView from "./components/Customers/Reports/ReportsView";
import CampaignView from "./components/ComunicationModule/CampaignsView/CampaignView";
import CampaignsActivity from "./components/ComunicationModule/CampaignsActivity/CampaignsActivity";
import CreateHTML from "./components/ComunicationModule/CreateHTML/CreateHTML";
import CampaignCreate from "./components/ComunicationModule/CampaignCreate/CampaignCreate";

// Módulo LandingPage
import CreatePage from "./components/LandingPageModule/CreatePage/CreatePage";
import MyLandingPages from './components/LandingPageModule/ViewPages/ViewLandingPages';

import Validation from "./components/CouponModule/Validation/Validation";
import "./App.css";
import SettingsPage from "./components/Settings/SettingsPage";

// Verificar autenticação usando token local ou Clerk
const isAuthenticated = () => {
  const token = localStorage.getItem("tooweze_token");
  return token !== null;
};

// Componente de Rota Protegida
const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/auth/login" />
      )
    }
  />
);

class App extends Component {
  render() {
    return (
      <Router>
        <header>
          {/* <SignedOut>
            <SignInButton />
          </SignedOut>
          <SignedIn>
            <UserButton />
          </SignedIn> */}
        </header>
        <div id="1" className="container-fluid" style={{ margin: "2px 10px" }}>
          <div
            id="2"
            className="card shadow bg-white cardAdd"
            style={{
              paddingLeft: "8px !important",
              margin: "0 auto",
              minHeight: "98vh",
              backgroundColor: "#f5f5f5 !important",
            }}
          >
            <div id="3" className="col" style={{ maxHeight: "100vh" }}>
              <div id="4" className="row">
                <Switch>
                  <Route exact path="/" component={HomeComponent} />
                  <ProtectedRoute exact path="/dashboard" component={HomeComponent} />
                  <ProtectedRoute exact path="/emailmktstats" component={EmailMKTStats} />
                  <ProtectedRoute exact path="/salesstats" component={SalesStats} />
                  <ProtectedRoute exact path="/transactions" component={Transactions} />
                  <ProtectedRoute exact path="/settings" component={SettingsPage} />

                  {/* Módulo Conta */}
                  <ProtectedRoute path="/account" component={Account} />

                  {/* Módulo Cupons */}
                  <Route path="/studio" component={CupomBuilder} />
                  <ProtectedRoute path="/viewcoupons" component={ViewCoupons} />

                  {/* Módulo Criar Campanhas */}
                  <Route path="/campaigncreate" component={CampaignCreate} />
                  <Route path="/campaignsview" component={CampaignView} />
                  <Route path="/campaignsactivity" component={CampaignsActivity} />

                  {/* Módulo Criar HMTL */}
                  <Route path="/createhtml" component={CreateHTML} />

                  {/* Modulo Contatos */}
                  <Route path="/contacts" component={Contacts} />
                  <Route path="/contactsview" component={ContactsView} />

                  {/* Modulo Customers */}
                  <Route path="/customers" component={CustomersView} />
                  <Route path="/addeditcustomers" component={AddEditCustomers} />
                  <Route path="/reports" component={ReportsView} />

                  {/* Módulo Validar Cupom */}
                  <Route path="/validation" component={Validation} />

                  {/* Módulo Adicionar Estabelecimentos */}
                  <Route path="/addbranch" component={AddBranch} />
                  <Route path="/addoperators" component={AddOperators} />

                  {/* Módulo Criar Landing Page */}
                  <Route path="/createpage" component={CreatePage} />
                  <Route path="/mylandingpages" component={MyLandingPages} />

                  {/* Módulo de Login/Autênticação */}
                  <Route path="/auth/login" component={Login} />
                  <Route path="/auth/logout" component={LogOut} />
                  <Route path="/auth/confirmemail" component={ConfirmEmail} />
                  <Route path="/verifysuccess" component={ConfirmAllEmails} />
                  <Route path="/verifyfailure" component={ConfirmAllEmails} />
                  <Route path="/auth/recoverypassword" component={RecoveryPassWord} />
                  <Route path="/auth/register" component={Register} />
                  <Route path="/auth/createnewpassword" component={CreateNewPassWord} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
