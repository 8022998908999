// This file is an action creator
// action creators return actions
// actions is an object that has at least a property of type
// this action creator is going to be handed to the dispatch
export default (fontSize, index) => {
    return {
        type: 'updateFontSize',
        payload: {
            fontSize,
            index
        }
    }
}
