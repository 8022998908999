import React from "react";

import LeftMenu from "../LayOut/LeftMenu"
import TopBar from "../LayOut/TopBar"
import MiddleBody from "../LayOut/MiddleBody";
import TopRightMenu from "../LayOut/TopRightMenu";

export default function SettingsPage(props) {
  const tab = props.location.search?.replace("?tab=", "")

  return (
    <>
      <div
        className="col-1"
        style={{
          paddingLeft: "0 !important",
          margin: "0 auto",
          paddingTop: "8px",
        }}
      >
        {/* <!-- Left Menu --> */}
        <LeftMenu />
      </div>

      <div id="6" className="col-8 supMiddle" style={{ zIndex: "1000" }}>
        {/* <!--Menu Superior--> */}
        <TopBar module="viewcoupons" history={props.history} />
        {/* <!--Parte do meio--> */}
        <MiddleBody module="settings" history={props.history} middleLarge tab={tab} />
      </div>
      <div id="7" className="col-3">
        {/* <!--Menu de Apoio--> */}
        <TopRightMenu history={props.history} />
        {/* <SupportMenu module="viewcoupons" /> */}
      </div>
    </>
  );
}