import { urlAPI, adminToken } from "./apiBase";
import axios from "axios";

let token;
token = localStorage.getItem("tooweze_token");

// Grava a imagem de upload na galeria de imagens
export default function saveImagemGallery(title, image) {
    return new Promise(function (resolve, reject) {
        axios({
            method: "POST",
            url: urlAPI.imagegallery,

            headers: {
                "x-auth-token": token
            },

            data: {
                title: title,
                image: image,
                firstName: "",
                lastName: ""
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}


// Remove o fundo da imagem
export function removebg(url, type) {
    return new Promise(function (resolve, reject) {
        axios({
            method: "POST",
            url: urlAPI.imagegallery + "/removebackground",

            headers: {
                "x-auth-token": token
            },

            data: {
                url: url,
                type: type
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

// Obtem as imagens gravadas na galeria do usuário.
export async function getImagemGallery() {
    let imageArray = [];

    return new Promise(function (resolve, reject) {
        axios({
            method: "GET",
            url: urlAPI.imagegallery,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status == "200") {

                json.data.map((image) => {
                    if (image._id) {
                        imageArray.push({
                            id: image._id,
                            links: {
                                html: image.links.html
                            },
                            urls: {
                                raw: image.urls.raw,
                                thumb: image.urls.thumb,
                                high: image.urls.raw
                            },
                            user: {
                                first_name: image.user.firstName,
                                last_name: image.user.lastName,
                                links: {
                                    html: image.user.links.html
                                }
                            },
                            type: "gallery"
                        });
                    }
                });

                // console.log("imageArray", imageArray);
                // imageArray.label = "Gallery";
                // console.log("json.data", json.data)
                // resolve(json.data);
                imageArray.label = "gallery";
                resolve(imageArray);
            }
        }).catch(function (error) {
            console.log("error", error);
        });
    });
}

export function deleteImagemGallery(id) {
    return new Promise(function (resolve, reject) {
        axios({
            method: "DELETE",
            url: urlAPI.imagegallery + "/" + id,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve("imageArray");
            }
        }).catch(function (error) {
            console.log("error", error);
        });
    });
}
// / PHOTOCARD

// / COUPONBUILDER
export function proceedSave(coupon, id) {
    let newUrl;

    let meth;
    if (id) {
        meth = "PUT";
        newUrl = urlAPI.coupon + "/" + id;
    } else {
        meth = "POST";
        newUrl = urlAPI.coupon;
    }

    return new Promise((resolve, reject) => {
        return axios({
            method: meth,
            url: newUrl,

            headers: {
                "x-auth-token": token
            },

            data: {
                image: coupon.image,
                canvas: coupon.canvas
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json);
            } else {
                reject("Error");
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

// Esta função atualiza apenas as informações de configurações do Cupom
export function proceedSaveConfig(coupon, id) {
    return new Promise((resolve, reject) => {
        return axios({
            method: "PUT",
            url: urlAPI.coupon + "/config/" + id,

            headers: {
                "x-auth-token": token
            },

            data: {
                title: coupon.title,
                description: coupon.description,
                discount: coupon.discount,
                originalPrice: coupon.originalPrice,
                discountPrice: coupon.discountPrice,
                minimumPurchase: coupon.minimumPurchase,
                voucher: coupon.voucher,
                limit: coupon.limit,
                random: coupon.random,
                fixedCode: coupon.fixedCode ? coupon.fixedCode : "",
                initDate: coupon.initDate,
                endDate: coupon.endDate,
                initHour: coupon.initHour,
                endHour: coupon.endHour,
                groupId: coupon.groupId,
                subGroupId: coupon.subGroupId,
                fields: coupon.fields,
                condiction: coupon.condiction,
                status: coupon.status
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

// Esta função atualiza apenas o Status do Cupom
export function proceedSaveConfigStatus(status, id) {
    return new Promise((resolve, reject) => {
        return axios({
            method: "PUT",
            url: urlAPI.coupon + "/status/" + id,

            headers: {
                "x-auth-token": token
            },

            data: {
                status: status
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / COUPONBUILDER

/// STUDIO
export function proceedSaveStudio(studio, id) {
    let newUrl;

    let meth;
    if (id) {
        meth = "PUT";
        newUrl = urlAPI.studio + "/" + id;
    } else {
        meth = "POST";
        newUrl = urlAPI.studio;
    }

    return new Promise((resolve, reject) => {
        return axios({
            method: meth,
            url: newUrl,

            headers: {
                "x-auth-token": token
            },

            data: {
                image: studio.image,
                canvas: studio.canvas,
                width: studio.width,
                height: studio.height
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json);
            } else {
                reject("Error");
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

// Esta função atualiza apenas as informações de configurações do Cupom
export function proceedSaveStudioConfig(coupon, id) {
    return new Promise((resolve, reject) => {
        return axios({
            method: "PUT",
            url: urlAPI.coupon + "/config/" + id,

            headers: {
                "x-auth-token": token
            },

            data: {
                title: coupon.title,
                description: coupon.description,
                discount: coupon.discount,
                originalPrice: coupon.originalPrice,
                discountPrice: coupon.discountPrice,
                minimumPurchase: coupon.minimumPurchase,
                voucher: coupon.voucher,
                limit: coupon.limit,
                random: coupon.random,
                fixedCode: coupon.fixedCode ? coupon.fixedCode : "",
                initDate: coupon.initDate,
                endDate: coupon.endDate,
                initHour: coupon.initHour,
                endHour: coupon.endHour,
                groupId: coupon.groupId,
                subGroupId: coupon.subGroupId,
                fields: coupon.fields,
                condiction: coupon.condiction,
                status: coupon.status
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

// Esta função atualiza apenas o Status do Cupom
export function proceedSaveStudioConfigStatus(status, id) {
    return new Promise((resolve, reject) => {
        return axios({
            method: "PUT",
            url: urlAPI.coupon + "/status/" + id,

            headers: {
                "x-auth-token": token
            },

            data: {
                status: status
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

export async function getStudio(id, canvas) {
    token = localStorage.getItem("tooweze_token");

    return new Promise(function (resolve, reject) {
        let newUrl;
        if (id) {
            if (canvas === true) {
                newUrl = urlAPI.studio + "/" + id + "?canvas=true";
            } else {
                newUrl = urlAPI.studio + "/" + id;
            }
        } else {
            newUrl = urlAPI.studio;
        }

        axios({
            method: "GET",
            url: newUrl,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status == "200") {
                const imageArray = [];

                if (Array.isArray(json.data)) {
                    json.data.map((studio) => {
                        if (studio._id) {
                            imageArray.push({
                                id: studio?._id,
                                links: {
                                    html: ""
                                },
                                urls: {
                                    raw: studio.image,
                                    thumb: studio.imageurlThumb + "?time=" + new Date().getMilliseconds(),
                                    high: studio.image,
                                    type: "canva",
                                    extra: ""
                                },
                                user: {
                                    first_name: "",
                                    last_name: "",
                                    links: {
                                        html: ""
                                    }
                                },
                                type: "art"
                            });
                        }
                    });

                    imageArray.label = "Art";
                    // [
                    //     {
                    //         "title": "Not Set",
                    //         "status": 1,
                    //         "labels": [],
                    //         "_id": "620818538409af3504a0e4f0",
                    //         "image": "https://coupon-images-bash.s3.amazonaws.com/620818538409af3504a0e4f0.jpg",
                    //         "rootId": "a4343b1a-c208-5110-b115-f519c0a5eb2e",
                    //         "__v": 0,
                    //         "imageurlThumb": "https://coupon-images-tooweze-thumb.s3.amazonaws.com/620818538409af3504a0e4f0.jpg"
                    //     }
                    // ]
                    resolve(imageArray);
                } else {
                    resolve(json.data)
                }


            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

export function deleteStudio(id) {
    return new Promise(function (resolve, reject) {
        axios({
            method: "DELETE",
            url: urlAPI.studio + "/" + id,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve("imageArray");
            }
        }).catch(function (error) {
            console.log("error", error);
        });
    });
}

export const reports = (id, initDate, endDate) => {
    return new Promise((resolve, reject) => {
        token = localStorage.getItem("tooweze_token");
        axios({
            method: "GET",
            url: id ? `${urlAPI.reports}/${id}?initDate=${initDate}&endDate=${endDate}` : urlAPI.reports,
            headers: {
                "x-auth-token": token
            }
        }).then((json) => {
            if (json.status === 200) {
                resolve(json.data);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

/// STUDIO



// / GET CUPONS (COUPONBUILDER VIEWCOUPON)
export async function getCoupons(id, canvas) {
    token = localStorage.getItem("tooweze_token");

    return new Promise(function (resolve, reject) {
        let newUrl;
        if (id) {
            if (canvas === true) {
                newUrl = urlAPI.coupon + "/" + id + "?canvas=true";
            } else {
                newUrl = urlAPI.coupon + "/" + id;
            }
        } else {
            newUrl = urlAPI.coupon;
        }

        axios({
            method: "GET",
            url: newUrl,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / GET CUPONS (COUPONBUILDER VIEWCOUPON)

// / GET Campaigns Obtem as campanhas de Cupons
export async function getCampaigns(id) {
    return new Promise(function (resolve, reject) {
        let newUrl;
        if (id) {
            newUrl = urlAPI.campaigns + "/" + id;
        } else {
            newUrl = urlAPI.campaigns;
        }

        axios({
            method: "GET",
            url: newUrl,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / GET Campaigns
// / GET Campaigns Obtem as campanhas de Cupons
export async function getEmailStats(id) {
    return new Promise(function (resolve, reject) {
        let newUrl;
        if (id) {
            newUrl = urlAPI.emailstats + "/" + id;
        } else {
            newUrl = urlAPI.emailstats;
        }

        axios({
            method: "GET",
            url: newUrl,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / GET Campaigns


// export async function getCampaignsActivityPagination(id, limit, skip) {
// if (id) {
//     return new Promise(function (resolve, reject) {
//       getCampaignsActivity(id, limit, skip).then(result => {
//         if (result.length > 3000) {


//           let newSip = skip + result.length;
//           let totalPage = 3000;
//           let totalCalls = newSkip / totalPage;

//           for (i = totalCalls; i < totalCalls; i++) {
//             getCampaignsActivity(id, limit, skip).then(result => {})
//           }
//         } else {

//         }

//       }).catch(err => reject(err))
//     });
// }
// }
// / GET CAMPAIGN ACTIVITY
export async function getCampaignsActivity(id, limit, skip) {
    if (id) {
        return new Promise(function (resolve, reject) {
            axios.get(urlAPI.events + "/" + id, {
                headers: {
                    "x-auth-token": token
                },
                params: {
                    limit: limit,
                    skip: skip
                }
            }).then(function (json) {
                resolve(json.data);
            }).catch(function (error) {
                reject(error);
            });
        });
    }
}
// / GET CAMPAIGN ACTIVITY

// / Ativar/Desativar Campanha
export async function activeCampaign(id) {
    if (id) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "PUT",
                url: urlAPI.publish + "/" + id,
                headers: {
                    "x-auth-token": token
                }
            }).then(function (json) {
                resolve(json.data);
            }).catch(function (error) {
                reject(error);
            });
        });
    }
}

export async function getLogById(id) {
    if (id) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: urlAPI.logs + "/" + id,
                headers: {
                    "x-auth-token": token
                }
            }).then(function (json) {
                resolve(json.data);
            }).catch(function (error) {
                reject(error);
            });
        });
    }
}

// / Search Campaign
export async function searchCampaign(campaignId) {
    if (campaignId) {
        return new Promise((result, reject) => {
            axios.get(urlAPI.campaigns + "/" + campaignId, {
                headers: {
                    "x-auth-token": token
                }
            }).then(function (json) {
                if (json.status == 200) {
                    result(json.data);
                }
            }).catch(function (error) {
                reject(error);
            });
        });
    }
}

export async function getAllHtmlBody() { // Get the HTML Body of the message to use with e-mail marketing.

    return new Promise((resolve, reject) => {
        return axios({
            method: "GET",
            url: urlAPI.message,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// Verifica se uma determinada lista de contatos, possui contatos.
export async function getContactListUsers(contactListId) {
    return new Promise((resolve, reject) => {
        axios.get(urlAPI.contactList + "/" + contactListId, {
            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

export async function getHtmlBody(id) { // Get an specific HTMLBody

    return new Promise((resolve, reject) => {
        return axios({
            method: "GET",
            url: urlAPI.message + "/" + id,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            resolve(json.data);
        }).catch(function (error) {
            reject(error);
        });
    });
}

// LANDING PAGE
export async function getLandingPage(id) { // Get an specific HTMLBody

    return new Promise((resolve, reject) => {
        return axios({
            method: "GET",
            url: urlAPI.landingpage + "/" + id,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            resolve(json.data);
        }).catch(function (error) {
            reject(error);
        });
    });
}

// GET ALL LandingPages
export async function getLandingPages(id) {

    return new Promise(function (resolve, reject) {
        let newUrl;
        if (id) {
            newUrl = urlAPI.landingpage + "/" + id;
        } else {
            newUrl = urlAPI.landingpage;
        }

        axios({
            method: "GET",
            url: newUrl,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

// GET ALL LandingPages
export async function getLandingPagesTemplates(id) {

    return new Promise(function (resolve, reject) {

        let newUrl;

        if (id) {
            newUrl = urlAPI.landingpage + "/template/" + id;
        } else {
            newUrl = urlAPI.landingpage + "/template";
        }

        axios({
            method: "GET",
            url: newUrl,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

// Atualiza a Landing Page
export async function updateLandinPage(id, html, css, image, publish) {
    return new Promise(function (resolve, reject) {
        let method;
        let url;
        if (id) {
            method = 'PUT';
            url = urlAPI.landingpage + '/' + id;
        } else {
            method = 'POST';
            url = urlAPI.landingpage;
        }
        axios({
            method: method,
            url: url,
            headers: {
                "x-auth-token": token
            },
            data: {
                html: html,
                css: css,
                defaultImage: image,
                publish: publish
            }
        }).then(function (json) {
            if (json.status == "201") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
};

// Atualiza apenas as configurações da Landing Page
export async function updateConfigLandinPage(id, landingpage) {
    return new Promise(function (resolve, reject) {
        let method;
        let url;
        if (id) {
            method = 'PUT';
            url = urlAPI.landingpage + '/config/' + id;
            axios({
                method: method,
                url: url,
                headers: {
                    "x-auth-token": token
                },
                data: {
                    bucket: landingpage.bucket,
                    defaultUrl: landingpage.defaultUrl,
                    domain: landingpage.domain,
                    pageTitle: landingpage.pageTitle,
                    scripts: landingpage.scripts,
                    status: landingpage.status,
                    title: landingpage.title
                }
            }).then(function (json) {
                resolve(json.data);
            }).catch(function (error) {
                reject(error);
            });
        }
    });
};

// Cria a LandingPage pela primeira vez
export async function CreateLandinPage(land) {
    return new Promise(function (resolve, reject) {
        let method;
        let url;
        method = 'POST';
        url = urlAPI.landingpage;
        axios({
            method: method,
            url: url,
            headers: {
                "x-auth-token": token
            },
            data: {
                title: land.title,
                html: land.html,
                css: land.css,
                pageTitle: land.pageTitle,
                bucket: land.bucket,
                defaultUrl: land.defaultUrl,
                defaultImage: land.defaultImage,
                domain: land.domain,
                scripts: land.scripts,
                status: land.status
            }
        }).then(function (json) {
            resolve(json.data);
        }).catch(function (error) {
            reject(error);
        });
    });
}

// Grava a Landing Page
export async function saveLandinPage(id, html, css) {
    return new Promise(function (resolve, reject) {
        let method;
        let url;
        if (id) {
            method = 'PATCH';
            url = urlAPI.landingpage + '/' + id;
        } else {
            method = 'POST';
            url = urlAPI.landingpage;
        }
        axios({
            method: method,
            url: url,
            headers: {
                "x-auth-token": token
            },
            data: {
                title: 'Título',
                html: html,
                css: css,
                pageTitle: 'pageTitle',
                bucket: 'bucket',
                defaultUrl: 'defaultUrl',
                domain: 'domain',
                scripts: ['scripts'],
                status: 1
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

// Atualiza apenas o Status da Landing Page
// Esta função atualiza apenas o Status do Cupom
export function proceedLandingConfigStatus(status, id) {
    return new Promise((resolve, reject) => {
        return axios({
            method: "PUT",
            url: urlAPI.landingpage + "/status/" + id,

            headers: {
                "x-auth-token": token
            },

            data: {
                status: status
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

// / DELETAR Landing Page
export async function deleteLandingPage(id) {
    if (id) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "DELETE",
                url: urlAPI.landingpage + "/" + id,
                headers: {
                    "x-auth-token": token
                }
            }).then(function (json) {
                if (json.status == "200") {
                    resolve(json.data);
                }
            }).catch(function (error) {
                console.log("error", error);
            });
        });
    }
}

// LANDING PAGE


// ===================
// EMAIL REMETENTE AWS
// ===================
// Busca os emails validos como remetente
// validateallsenders
export async function validateAllSenders() {
    return new Promise((resolve, reject) => {
        axios.get(urlAPI.validateallsenders, {
            headers: {
                "x-auth-token": adminToken.token
            }
        }).then(function (json) {
            resolve(json.data);
        }).catch(function (error) {
            reject(error);
        });
    });
}
export async function readSenderEmails() {
    return new Promise((resolve, reject) => {
        axios.get(urlAPI.sendervalidation, {
            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            resolve(json.data);
        }).catch(function (error) {
            reject(error);
        });
    });
}

export async function insertSenderEmail(email) {
    return new Promise((resolve, reject) => {
        axios.post(urlAPI.sendervalidation,
            {
                email: email
            }
            , {
                headers: {
                    "x-auth-token": token
                }
            }).then(function (json) {
                resolve(json.data);
            }).catch(function (error) {
                reject(error);
            })
    })
}

export async function deleteSenderEmails(id) {
    return new Promise((resolve, reject) => {
        axios.delete(urlAPI.sendervalidation + "/" + id, {
            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            resolve(json.data)
        }).catch(function (error) {
            reject(error)
        });
    })
}

// ===================
// EMAIL REMETENTE AWS
// ===================

// Envia email de Teste
export async function sendEmailTest(email) {
    return new Promise((resolve, reject) => {
        axios.post(urlAPI.emailtest,
            {
                name: email.name,
                messageId: email.messageId,
                sendFrom: email.sendFrom,
                replyTo: email.replyTo,
                subject: email.subject,
                sendTo: email.sendTo
            }
            , {
                headers: {
                    "x-auth-token": token
                }
            }).then(function (json) {
                resolve(json.data);
            }).catch(function (error) {
                reject(error);
            })
    })
}

// / GET CONTACTS LIST
export async function getContactList(id) {
    let urlApi = urlAPI.contactList;

    if (id) {
        urlApi += "/" + id;
    }

    return new Promise(function (resolve, reject) {
        axios.get(urlApi, {
            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            resolve(json.data);
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / GET CONTACTS LIST

// / EDIT CONTACT LIST (Altera o nome e Status de uma lista de contatos)
export async function EditContactList(id, listName, status) {
    return new Promise(function (resolve, reject) {
        axios({
            method: "PUT",
            url: urlAPI.contactList + "/" + id,

            headers: {
                "x-auth-token": token
            },
            data: {
                listName: listName,
                status: status
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / EDIT CONTACT LIST

// / CRIA UMA NOVA LISTA DE CONTATOS
export async function createNewContactList(listName, status) {
    return new Promise(function (resolve, reject) {
        axios({
            method: "POST",
            url: urlAPI.contactList,
            headers: {
                "x-auth-token": token
            },
            data: {
                listName: listName,
                status: status
            }
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / CRIA UMA NOVA LISTA DE CONTATOS

// / Delete ContactList
export async function deleteContactList(id) {
    if (id) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "DELETE",
                url: urlAPI.contactList + "/" + id,
                headers: {
                    "x-auth-token": token
                }
            }).then(function (json) {
                if (json.status == "200") {
                    resolve(json.data);
                }
            }).catch(function (err) {
                reject(err);
            });
        });
    }
}
// / Delete ContactList


export function getCustomersList() { return }
export function editCustomersList() { return }
export function createNewCustomersList() { return }
export function deleteCustomersList() { return }

// export function getCustomers() { return }

export function getCustomerActivity(id) {
    return new Promise((resolve, reject) => {
        axios.get(urlAPI.customerActivity + `/${id}/`, {
            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status === 200) {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

export function getCustomerBalance(id, type) {
    return new Promise((resolve, reject) => {
        axios.get(urlAPI.customerbalance + `/operator/${type}/` + id, {
            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status === 200) {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

export function getCustomerTotalBalance(id, type) {
    return new Promise((resolve, reject) => {
        axios.get(urlAPI.customertotalbalance + `/operator/${type}/` + id, {
            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status === 200) {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

export async function createCustomer(customer) {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: urlAPI.customers,

            headers: {
                "x-auth-token": token
            },
            data: customer
        }).then((res) => {
            if (res.status === 200) {
                resolve(res);
            }
        }).catch((err) => {
            reject(err.toJSON());
        });
    });
}

export async function updateCustomer(customer) {
    return new Promise((resolve, reject) => {
        console.log("Stringfy", JSON.stringify(customer))
        axios({
            method: "PATCH",
            url: urlAPI.customers + "/" + customer.id_,
            headers: {
                "x-auth-token": token
            },
            data: customer
        }).then((res) => {
            console.log("res", res)
            if (res.status === 200) {
                resolve(res.data);
            } else {
                reject(res.data);
            }
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function deleteCustomer(id) {
    return new Promise((resolve, reject) => {
        axios({
            method: "DELETE",
            url: urlAPI.customers + "/" + id,
            headers: {
                "x-auth-token": token
            }
        }).then((res) => {
            if (res.status === 200) {
                resolve("Deleted");
            }
        }).catch((err) => {
            reject(err);
        });
    });
}


export function getCustomers() {
    return new Promise((resolve, reject) => {
        axios.get(urlAPI.customers, {
            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status === 200) {

                const sortedData = json.data.sort((a, b) => {
                    if (a._id < b._id) return 1;   // Ordem decrescente
                    if (a._id > b._id) return -1;
                    return 0;
                });

                resolve(sortedData);  // Retornando os dados ordenados

                // resolve(json.data);


            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

export function getCustomer(id) {
    return new Promise((resolve, reject) => {
        axios.get(urlAPI.customers + "/" + id, {
            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status === 200) {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

export function getReport(reportType) {
    console.log('getReport =====================================>')
    return new Promise((resolve, reject) => {
        axios.get(`${urlAPI.reports}/${reportType}`, {
            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status === 200) {
                console.log('resultado getReport =============================>')
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

// / ADICIONAR CONTATOS
export async function createContact(contact) {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: urlAPI.contactList + "/upload",

            headers: {
                "x-auth-token": token
            },
            data: contact
        }).then((res) => {
            if (res.status === 200) {
                resolve(res);
            }
        }).catch((err) => {
            reject(err.toJSON());
        });
    });
}

// Deletar Contato
export async function deleteContact(id) {
    return new Promise((resolve, reject) => {
        axios({
            method: "DELETE",
            url: urlAPI.contact + "/" + id,
            headers: {
                "x-auth-token": token
            }
        }).then((res) => {
            if (res.status == "200") {
                resolve("Deleted");
            }
        }).catch((err) => {
            reject(err);
        });
    });
}
// Deletar Contato

// Atualizando um contato
export async function updateContact(contact) {
    return new Promise((resolve, reject) => {
        axios({
            method: "PUT",
            url: urlAPI.contact + "/" + contact.id,
            headers: {
                "x-auth-token": token
            },
            data: contact
        }).then((res) => {
            if (res.status == "200") {
                resolve(res.data);
            } else {
                reject(res.data);
            }
        }).catch((err) => {
            reject(err);
        });
    });
}
// Atualizando um contato

// / CONTATOS

// / DELETAR CUPOM
export async function deleteCoupons(id) {
    if (id) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "DELETE",
                url: urlAPI.coupon + "/" + id,
                headers: {
                    "x-auth-token": token
                }
            }).then(function (json) {
                if (json.status == "200") {
                    resolve(json.data);
                }
            }).catch(function (error) {
                console.log("error", error);
            });
        });
    }
}

// GRAVAR CAMPANHAS
export async function proceedSaveCampaign(campaign) {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: urlAPI.campaigns,
            headers: {
                "x-auth-token": token
            },
            data: {
                name: campaign.name, // Share
                sentType: campaign.sentType, // 1 => E-mail 3 => Share
                messageId: campaign.messageId, // Id da Mensagem que está sendo salva no mysql, Body, na tabela MAIL_TEMPLATE //=> Ou Id do Cupom Quando Share
                messageName: campaign.messageName, // Nome da Mensagem que está sendo salva no mysql, Body, na tabela MAIL_TEMPLATE //=> ou Title do Cupom Quando Share
                contactName: campaign.contactName, // Nome da Lista de Contato, obtida em GET ContactList // Share
                contactListId: campaign.contactListId, // Id da Lista de Contato, obtida em GET ContactList // Share
                sendFrom: campaign.sendFrom, // --> No caso do e-mail marketing, é o email de remetente // Share
                replyTo: campaign.replyTo, // --> No caso do e-mail marketing, é o e-mail para respostas ||
                subject: campaign.subject, // --> No caso do e-mail marketing é o assunto do e-mail, gravado junto do Body HTML ||
                status: campaign.status,
                sendDate: campaign.sendDate, // --> No caso do e-mail marketing é o assunto do e-mail, gravado junto do Body HTML ||
            }
        }).then(function (json) {
            if (json.status === 200) { // Sucesso
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}

// Grava ou atualiza o Body HTML da Mensagem
export async function postHtmlBody(messageBody) {
    return new Promise((result, reject) => {
        let meth;
        let url;
        if (messageBody.messageId) {
            meth = "PUT";
            url = urlAPI.message + "/" + messageBody.messageId;
        } else {
            meth = "POST";
            url = urlAPI.message;
        }

        axios({
            method: meth,
            url: url,

            headers: {
                "x-auth-token": token
            },

            data: {
                title: messageBody.messageName,
                messageBody: messageBody.messageBody,
                bodyType: 1
            }
        }).then(function (json) {
            result(json);
        }).catch(function (error) {
            reject(error);
        });
    });
}

// Com o código do cupom obtem as informações do cupom
export async function getCouponCode(id) {
    return new Promise((resolve, reject) => {
        axios.get(urlAPI.registercoupon + "/" + id, {
            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            resolve(json);
        }).catch(function (error) {
            reject(error);
        });
    });
}

// / Faz a validação do cupom
export async function registerCouponCode(id, name, email, initialValue, discount, voucher, finalValue) {
    return new Promise((resolve, reject) => {
        axios({
            method: "PUT",
            url: urlAPI.registercoupon + "/" + id,

            headers: {
                "x-auth-token": token
            },

            data: {
                codeId: "", // validação for feita por QR Code
                email: email,
                name: name,
                locator: "",
                extraField: "",
                contactId: "",
                initialValue: initialValue,
                discount: discount,
                voucher: voucher,
                finalValue: finalValue
            }
        }).then(function (json) {
            resolve(json);
        }).catch(function (error) {
            reject(error);
        });
    });
}

// Obtem todos os estabelecimentos
export function getAllBranchs(id) {
    let newUrl;

    if (id) {
        newUrl = urlAPI.branch + "/" + id;
    } else {
        newUrl = urlAPI.branch;
    }

    return new Promise((resolve, reject) => {
        return axios({
            method: "GET",
            url: newUrl,
            headers: {
                "x-auth-token": token
            }
        }).then((res) => {
            if (res.status == "200") {
                resolve(res);
            } else {
                reject("Error");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// Grava ou Atualiza um Estabelecimento
export function proceedSaveBranch(branch, id) {
    let newUrl;
    let data;

    let meth;
    if (id) {
        meth = "PUT";
        newUrl = urlAPI.branch + "/" + id;
    } else {
        meth = "POST";
        newUrl = urlAPI.branch;
    }

    if (branch.image && branch.image != "") {
        data = {
            name: branch.name,
            email: branch.email,
            companyName: branch.companyName,
            companyName2: branch.companyName2,
            branchType: branch.branchType,
            companyIdentifier: removeMask(branch.companyIdentifier),
            addressLine1: branch.addressLine1,
            addressLine2: branch.addressLine2,
            cityRegion: branch.cityRegion,
            zipCode: removeMask(branch.zipCode),
            state: branch.state,
            city: branch.city,
            facebookPage: branch.facebookPage,
            instagramPage: branch.instagramPage,
            latitude: branch.latitude,
            longitude: branch.longitude,
            mobil: removeMask(branch.mobil),
            phone: removeMask(branch.phone),
            webSite: branch.webSite,
            whatsup: removeMask(branch.whatsup),
            image: branch.image
        };
    } else {
        data = {
            name: branch.name,
            email: branch.email,
            companyName: branch.companyName,
            companyName2: branch.companyName2,
            branchType: branch.branchType,
            companyIdentifier: removeMask(branch.companyIdentifier),
            addressLine1: branch.addressLine1,
            addressLine2: branch.addressLine2,
            cityRegion: branch.cityRegion,
            zipCode: removeMask(branch.zipCode),
            state: branch.state,
            city: branch.city,
            facebookPage: branch.facebookPage,
            instagramPage: branch.instagramPage,
            latitude: branch.latitude,
            longitude: branch.longitude,
            mobil: removeMask(branch.mobil),
            phone: removeMask(branch.phone),
            webSite: branch.webSite,
            whatsup: removeMask(branch.whatsup)
        };
    }

    return new Promise((resolve, reject) => {
        return axios({
            method: meth,
            url: newUrl,

            headers: {
                "x-auth-token": token
            },

            data: data
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json);
            } else {
                reject("Error");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export async function companyIdentifierValidation(identifier, companyIdentifier, branchType, branchId) {
    if (companyIdentifier) {
        if (identifier == "email" || ((companyIdentifier.length === 14 || companyIdentifier.length === 18) && identifier == "companyIdentifier")) {
            return new Promise((resolve, reject) => {
                let url;
                if (identifier == "companyIdentifier") {
                    if (branchId) {
                        url = urlAPI.branch + "/identifier/" + branchId + `?companyIdentifier=${companyIdentifier}&branchType=${branchType}&identifier=companyIdentifier`;
                    } else {
                        url = urlAPI.branch + "/identifier/all" + `?companyIdentifier=${companyIdentifier}&branchType=${branchType}&identifier=companyIdentifier`;
                    }
                } else if (identifier == "email") {
                    if (branchId) {
                        url = urlAPI.branch + "/identifier/" + branchId + `?companyIdentifier=${companyIdentifier}&identifier=email`;
                    } else {
                        url = urlAPI.branch + "/identifier/all" + `?companyIdentifier=${companyIdentifier}&identifier=email`;
                    }
                }

                return axios({
                    method: "GET",
                    url: url,
                    headers: {
                        "x-auth-token": token
                    }
                }).then((res) => {
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                });
            });
        } else {
            return false;
        }
    } else {
        return false;
    }
}

// / IDENTIFICAR CNPJ REPETIDO EM CASO DE CONTA DA EMPRESA
export async function companyRootIDIdentifierValidation(identifier, companyIdentifier, identifierType, accountId) {
    if (companyIdentifier) {
        if ((companyIdentifier.length === 14 || companyIdentifier.length === 18) && identifier == "companyIdentifier") {
            return new Promise((resolve, reject) => {
                let url;
                if (identifier == "companyIdentifier") {
                    url = urlAPI.account + "/identifier/" + accountId + `?companyIdentifier=${companyIdentifier}&identifierType=${identifierType}&identifier=companyIdentifier`;
                } else if (identifier == "email") {
                    // if (branchId) {
                    //     url = urlAPI.branch + '/identifier/' + branchId + `?companyIdentifier=${companyIdentifier}&identifier=email`
                    // } else {
                    //     url = urlAPI.branch + '/identifier/all' + `?companyIdentifier=${companyIdentifier}&identifier=email`
                    // }
                }
                return axios({
                    method: "GET",
                    url: url,
                    headers: {
                        "x-auth-token": token
                    }
                }).then((res) => {
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                });
            });
        } else {
            return false;
        }
    } else {
        return false;
    }
}
// / IDENTIFICAR CNPJ REPETIDO EM CASO DE CONTA DA EMPRESA

// / Obtem todos operadores do estabelecimento
export function getAllOperatorsBranchs(id) {
    let newUrl;

    if (id) {
        newUrl = urlAPI.useroperator + "/" + id;
    } else {
        newUrl = urlAPI.useroperator;
    }

    return new Promise((resolve, reject) => {
        return axios({
            method: "GET",
            url: newUrl,
            headers: {
                "x-auth-token": token
            }
        }).then((res) => {
            if (res.status == "200") {
                resolve(res.data);
            } else {
                reject("Error");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
// / Obtem todos operadores do estabelecimento

// / Cria um novo Operador / Edita
export function proceedSaveBranchOperator(useroperator, id) {
    let newUrl;
    let data;

    let meth;
    if (id) {
        meth = "PUT";
        newUrl = urlAPI.useroperator + "/" + id;
        data = {
            name: useroperator.name,
            email: useroperator.email,
            branchId: useroperator.branchId
        };
    } else {
        meth = "POST";
        newUrl = urlAPI.useroperator;
        data = {
            name: useroperator.name,
            email: useroperator.email,
            branchId: useroperator.branchId,
            isActive: useroperator.isActive
        };
    }

    return new Promise((resolve, reject) => {
        return axios({
            method: meth,
            url: newUrl,

            headers: {
                "x-auth-token": token
            },

            data: data
        }).then(function (json) {
            if (json.status == "200") {
                resolve(json.data);
            } else {
                reject("Error");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
// / Cria um novo Operador

// / Altera o Status do operador
export async function alterOperatorStatus(id) {
    return new Promise((resolve, reject) => {
        return axios({
            method: "PUT",
            url: urlAPI.useroperator + "/isActive/" + id,
            headers: {
                "x-auth-token": token
            }
        }).then((res) => resolve(res.data)).catch((error) => reject(error));
    });
}
// / Altera o Status do operador

// / Valida o email do Operador antes de criar um novo
export async function branchIdentifierValidation(identifier, useroperatorId) {
    if (identifier) {
        return new Promise((resolve, reject) => {
            let url;

            if (!useroperatorId) {
                url = urlAPI.useroperator + "/identifier/" + identifier;
            } else if (useroperatorId) {
                url = urlAPI.useroperator + "/identifier/" + identifier + `?useroperatorId=${useroperatorId}`;
            }

            return axios({
                method: "GET",
                url: url,
                headers: {
                    "x-auth-token": token
                }
            }).then((res) => {
                resolve(res.data);
            }).catch((error) => {
                reject(error);
            });
        });
    } else {
        return false;
    }
}
// / Valida o email do Operador antes de criar um novo

// / AUTENTICAÇÃO
export async function Auth(token) {
    return new Promise((resolve, reject) => {
        return axios({
            method: "GET",
            url: urlAPI.login,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.data.isActive) {
                if (json.data.isVerify) {
                    checkAccount(token, json).then((result) => {
                        let year = result.data.validTo.split("-")[0].toString();
                        let month = result.data.validTo.split("-")[1].toString() - 1;
                        let day = result.data.validTo.split("-")[2].toString();

                        let validTo = new Date(year, month, day);

                        let today = new Date();

                        today.setHours(0, 0, 0, 0);
                        validTo.setHours(0, 0, 0, 0);

                        let dt1 = new Date(validTo);
                        let dt2 = new Date(today);
                        let difference = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));

                        difference = difference * -1;
                        let renewLabel = document.getElementById('renewLabel');

                        // Assinatura Vencida
                        if (today > validTo) {
                            if (difference > 3) {
                                if (renewLabel) {
                                    document.getElementById('renewLabel').style.display = 'block';
                                    document.getElementById('renewLabel').innerHTML = `Assinatura Desativada: Reative agora mesmo:
                                    <a href="account?card=billing" style='color: white'>Renovar</a></label>`;
                                }
                            } else {
                                if (renewLabel) {
                                    document.getElementById('renewLabel').style.display = 'block';
                                    document.getElementById('renewLabel').innerHTML = `Sua Assinatura Venceu! Renove agora mesmo:
                                    <a href="account?card=billing" style='color: white'>Renovar</a></label>`;
                                }
                            }
                            // returnToAccountPage(); // Faz retornar para página da conta.
                        } else if (today <= validTo) { // Assinatura válida.
                            if (difference < 4) {
                                // console.log('True_')

                                if (renewLabel) {
                                    document.getElementById('renewLabel').style.display = 'block';
                                    document.getElementById('renewLabel').innerHTML = `Sua Assinatura vencerá ${difference != 0 ? 'em ' + difference + ' dias' : 'hoje'}! Renove agora mesmo:
                                    <a href="account?card=billing" style='color: white'>Renovar</a></label>`;
                                }

                            } else { // document.getElementById('renewLabel').style.display = 'none';
                            }
                        }

                        json.data.accountId = result.data.accountId; // Adiciona o AccountID ao auth
                        json.data.planId = result.data.planId; // Adiciona o AccountID ao auth
                        json.data.contactsLimit = result.data.contactsLimit;

                        json.data.emailSendingQuota = result.data.emailSendingQuota;
                        json.data.usedAditionalBranchs = result.data.usedAditionalBranchs;
                        json.data.usedCampaigns = result.data.usedCampaigns;
                        json.data.usedContactsLimit = result.data.usedContactsLimit;
                        json.data.usedEmailSendingQuota = result.data.usedEmailSendingQuota;

                        let config = {
                            id: result.data.accountId,
                            isConfigured: result.data.isConfigured
                        };

                        localStorage.removeItem("auth");
                        localStorage.removeItem("tooweze_token");
                        localStorage.removeItem("isConfigured");
                        localStorage.setItem("tooweze_token", token);
                        localStorage.setItem("auth", JSON.stringify(result.data));
                        localStorage.setItem("isConfigured", JSON.stringify(config));

                        resolve(json.data);
                    }).catch((error) => {
                        reject(error);
                    });
                } else {
                    // E-mail Not Verified
                    // window.location.replace("pages-confirm-email.html" + '?id=' + json.data._id + '&send');
                    reject("Email Not Confirmed");
                }
            } else { // Inactive
                reject("LogOut");
                localStorage.removeItem("tooweze_token");
                localStorage.removeItem("auth");
                // window.location.replace("pages-logout.html");
            }
        }).catch(function (error) {
            console.log("error login", error);
            reject(error);
        });
    });
}

// Obtem os dados da conta no Login
async function checkAccount(token, data_) {
    return new Promise((result, reject) => {
        axios({
            method: "GET",
            url: urlAPI.account,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status == "200") {
                data_.data.isConfigured = json.data.isConfigured;
                data_.data.accountId = json.data._id;
                data_.data.validTo = json.data.validTo;
                data_.data.planId = json.data.planId;
                data_.data.contactsLimit = json.data.contactsLimit;
                data_.data.logo = json.data.logo;

                data_.data.aditionalBranchs = json.data.aditionalBranchs;
                data_.data.emailSendingQuota = json.data.emailSendingQuota;
                data_.data.usedAditionalBranchs = json.data.usedAditionalBranchs;
                data_.data.usedCampaigns = json.data.usedCampaigns;
                data_.data.usedContactsLimit = json.data.usedContactsLimit;
                data_.data.usedEmailSendingQuota = json.data.usedEmailSendingQuota;

                result(data_);
            }
        }).catch(function (error) {
            if (error.response.data) {
                reject(error);
            } else {
                reject(error);
            }
        });
    });
}

// / AUTENTICAÇÃO

// / Obtem os dados da conta
export async function getAccount(token) {
    return new Promise((result, reject) => {
        axios({
            method: "GET",
            url: urlAPI.account,

            headers: {
                "x-auth-token": token
            }
        }).then(function (json) {
            if (json.status == "200") {
                result(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / Obtem os dados da conta

// / LOGIN
export async function proceedLogin(username, password) {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: urlAPI.auth,

            headers: {
                "x-auth-token": adminToken.token
            },

            data: {
                email: username,
                password: password
            }
        }).then(function (json) { // Recebe o token

            Auth(json.data).then((res) => {
                resolve(res);
            }).catch((error) => {
                reject(error);
            });
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / LOGIN

// Register access page
export async function registerAccessPage(page) {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: urlAPI.registeraccessedpage,
            headers: {
                "x-auth-token": token
            },
            data: {
                page: page
            }
        }).then(function (json) {
            resolve(json)
        }).catch(error => {
            reject(error);
        })
    })
}
// Register access page

// / Recuperar Senha - Envia o Email
export async function recoverPw(email) {
    return new Promise((result, reject) => {
        return axios({
            method: "POST",
            url: urlAPI.recoverpassword,

            headers: {
                "x-auth-token": adminToken.token
            },
            data: {
                email: email
            }
        }).then(function (json) {
            result(json.data);
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / Recuperar Senha - Envia o Email

// / Envia e grava a nova senha
export async function recoverPassword(hash, newpassword) {
    return new Promise((resolve, reject) => {
        return axios({
            method: "POST",
            url: urlAPI.change + "/" + hash,
            data: {
                password: newpassword
            }
        }).then(function (json) {
            resolve(json);
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / Envia e grava a nova senha

// / Criar Conta
export async function createUser(name, email, password, companyName, website) {
    return new Promise((resolve, reject) => {
        return axios({
            method: "POST",
            url: urlAPI.users,

            headers: {
                "x-auth-token": adminToken.token
            },
            data: {
                name: name,
                email: email,
                password: password,
                companyName: companyName,
                website: website,

            }
        }).then(function (json) {
            resolve(json);
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / Criar Conta

// / REENVIAR LINK PARA VALIDAÇÃO DA CONTA CRIADA
// / Primeiro verifica se já foi validado.
export async function validateEmail(id) {
    return new Promise((resolve, reject) => {
        return axios.get(urlAPI.users + "/" + id, {
            headers: {
                "x-auth-token": adminToken.token
            }
        }).then(function (json) {
            resolve(json.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

// / Reenvia o Link
export async function resendLink(id) {
    return new Promise((resolve, reject) => {
        return axios({
            method: "POST",
            url: urlAPI.mailvalidation,

            headers: {
                "x-auth-token": adminToken.token
            },
            data: {
                _id: id
            }
        }).then(function (json) {
            resolve(json);
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / REENVIAR LINK PARA VALIDAÇÃO DA CONTA CRIADA

// / Validar conta nova com o Token
export async function checkHash(hash) {
    return new Promise((resolve, reject) => {
        return axios({
            method: "GET",
            url: urlAPI.mailvalidation + "/" + hash
        }).then(function (json) {
            resolve(json.data);
        }).catch(function (error) { // token inválido: status: 401
            reject(error);
        });
    });
}
// / Validar conta nova com o Token

// / Atualizar informações da conta
export async function updateAccount(account) {
    return new Promise((resolve, reject) => {
        return axios({
            method: "PUT",
            url: urlAPI.account + "/" + account.id,
            headers: {
                "x-auth-token": token
            },
            data: {
                logo: account.logo,
                companyName: account.companyName,
                companyName2: account.companyName2,
                companyType: account.companyType, // Tipo de Estabelecimento (1 - Pessoa Física/ 2 - Pessoa Jurídica)
                companyIdentifier: account.companyIdentifier, // 1 - CPF ou 2 - CNPJ
                identifierType: account.identifierType,
                addressLine1: account.addressLine1,
                addressLine2: account.addressLine2,
                number: account.number,
                cityRegion: account.cityRegion,
                zipCode: account.zipCode,
                state: account.state,
                city: account.city,
                image: account.image
            }
        }).then((res) => {
            resolve(res);
        }).catch((error) => {
            reject(error);
        });
    });
}
// / Atualizar informações da conta

// / Atualiza a imagem do Perfil
export async function updateUser(user) {
    return new Promise((resolve, reject) => {
        return axios({
            method: "PUT",
            url: urlAPI.users + "/" + user.id,
            headers: {
                "x-auth-token": token
            },
            data: {
                image: user.profilePic
            }
        }).then((res) => {
            resolve(res);
        }).catch((error) => {
            reject(error);
        });
    });
}
// / Atualiza a imagem do Perfil

// / Planos
export async function getAllPlans() { // Pegando todas opções de planos e colocando no menu.

    return new Promise((resolve, reject) => {
        return axios({
            method: "GET",
            url: urlAPI.plan,
            headers: {
                "x-auth-token": adminToken.token
            }
        }).then(function (json) { // console.log('plano', json);

            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / Planos

// / Limites
export async function getAccountLimits() { // Pegando todas opções de planos e colocando no menu.

    return new Promise((resolve, reject) => {
        return axios({
            method: "GET",
            url: urlAPI.accountlimits,
            headers: {
                "x-auth-token": token
            }
        }).then(function (json) { // console.log('plano', json);

            if (json.status == "200") {
                resolve(json.data);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}
// / Limites

// / FUNÇÕES DE SUPORTE
export async function buscaCEP(CEP) {
    return new Promise((result, reject) => {
        return axios({ method: "GET", url: `https://viacep.com.br/ws/${CEP}/json` }).then((res) => {
            result(res);
        }).catch((error) => {
            reject(error);
        });
    });
}

// Remove mascára, mantendo apenas números
function removeMask(textBoxValue) {
    if (textBoxValue) {
        return textBoxValue.replace(/\D/g, ""); // Remove tudo o que não é dígito
    }
}
