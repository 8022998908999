import React, { Component } from "react";
import { Button } from "devextreme-react";
import { loadMessages, locale } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import "../AddBranch/AddBranchMiddle.css";
import { SelectBox } from "devextreme-react";
import { TextBox } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";
import { actualURL } from "../../apis/apiBase";

import {
  getAllBranchs,
  getAllOperatorsBranchs,
  proceedSaveBranchOperator,
  branchIdentifierValidation,
  alterOperatorStatus,
} from "../../apis/toowezeApi";
import {
  Validator,
  RequiredRule,
  EmailRule,
  AsyncRule,
} from "devextreme-react/validator";
import { i18n, lgMessages, languageCode } from "../../translate/i18n";

const position = { of: "#operator" };

export default class AddOperatorsMiddle extends Component {
  constructor(props) {
    super(props);

    loadMessages(lgMessages);
    locale(languageCode);

    this.state = {
      branchId: null,
      useroperatorId: null,
      branchs: "",
      operators: [],
      loadPanelVisible: false,
      goValidateEmail: false,
      emailCheck: false,
      isActive: true,
    };
  }

  componentDidMount() {
    /// Lê os estabelecimentos e os operadores
    this.getAllBranchsAndOperators();
  }

  getAllBranchsAndOperators = () => {
    /// Obtem todos os estabelecimentos
    getAllBranchs()
      .then((res) => {
        this.setState({
          branchs: res.data,
        });
        /// Obtem todos operadores
        getAllOperatorsBranchs()
          .then((res) => {
            this.setState({
              operators: res,
            });
          })
          .catch((error) => {
            console.log("Operators Error", error);
          });
      })
      .catch((error) => {
        console.log("Estabelecimentos Error", error);
      });
  };

  // Reseta todos os campos
  reset = () => {
    this.setState({
      branchId: null,
      name: null,
      email: null,
      groupId: "",
      rootId: "",
      logoURL: "",
      image: "",
      useroperatorId: null,
      isActive: true,
    });
  };

  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "top", offset: "0 200" },
        closeOnClick: true,
      },
      type,
      3000
    );
  }

  // Administra as mudanças de valores dos TextBox
  handleValueChange = (event, fieldId) => {
    if (fieldId !== "isActive") {
      this.setState({
        [fieldId]: event.value ? event.value : null,
      });
    } else if (fieldId === "isActive") {
      // isActive não é devextreme
      this.setState(
        {
          [fieldId]: event.target.checked,
        },
        () => {
          if (this.state.useroperatorId) {
            alterOperatorStatus(this.state.useroperatorId)
              .then((res) => {
                this.setState({
                  goValidateEmail: true,
                  emailCheck: false,
                });

                setTimeout(() => {
                  this.setState({ loadPanelVisible: false });
                }, 500);

                if (res.isActive === true) {
                  this.showNotify(
                    i18n.t("addoperators.notify.useractivatedsuccessfully"),
                    "success"
                  );
                } else if (res.isActive === false) {
                  this.showNotify(
                    i18n.t("addoperators.notify.userdeactivatedsuccessfully"),
                    "success"
                  );
                }
              })
              .catch((error) => {
                console.log("Error Status", error);
              });
          }
        }
      );
    }

    if (fieldId == "email") {
      this.setState({ goValidateEmail: true });
    }
  };

  // Verifica se o email já está cadastrado
  asyncCompanyIdentifierEmailValidation = (params) => {
    if (this.state.goValidateEmail == true) {
      this.setState({ goValidateEmail: false });
      return branchIdentifierValidation(
        params.value,
        this.state.useroperatorId
      ).then((res) => {
        this.setState({ emailCheck: !res });
        return !res; // Não tem repetido = true, é valido? !res
      });
    } else {
      return new Promise((resolve, reject) => resolve(this.state.emailCheck));
    }
  };

  // Administra a mudança de valor no dropdownlist relativo aos Operadores
  operatorSelected = (event) => {
    this.setState(
      { useroperatorId: event.value, loadPanelVisible: true },
      () => {
        /// Obtem todos operadores

        getAllOperatorsBranchs(this.state.useroperatorId)
          .then((res) => {
            this.setState({
              name: res.name,
              email: res.email,
              branchId: res.branchId,
              isActive: res.isActive,
              goValidateEmail: true,
              emailCheck: false,
            });

            this.branchSelected(this.state.branchId);
          })
          .catch((error) => {
            console.log("Operators Error", error);
          });
      }
    );
  };

  // Administra a mudança de valor no dropdownlist relativo aos Estabelecimentos
  branchSelected = (event) => {
    if (event.value) {
      this.setState({ branchId: event.value, loadPanelVisible: true });
    }

    if (this.state.branchId) {
      getAllBranchs(this.state.branchId)
        .then((res) => {
          let selectedBranch;
          selectedBranch = res.data;

          this.setState({
            branchId: selectedBranch._id,
            groupId: selectedBranch.groupId,
            rootId: selectedBranch.rootId,
            logoURL: selectedBranch.logoURL ? selectedBranch.logoURL : "",
            image: "",
          });

          setTimeout(() => {
            this.setState({ loadPanelVisible: false });
          }, 500);
        })
        .catch((error) => {
          this.setState({ loadPanelVisible: false });
        });
    }
  };

  onKeyUp(event, fieldId) {
    event.event.preventDefault();

    if (fieldId == "email") {
      let email = event.event.currentTarget.value;

      if (email) {
        if (email.includes("@")) {
          this.setState({
            email: email,
            goValidateEmail: true,
          });
        }
      }
    }
  }

  /// Cria um novo operador ou atualiza um existente
  proceedSaveBranchOperator = () => {
    if (
      this.state.name !== "" &&
      this.state.name !== null &&
      this.state.name !== undefined
    ) {
      if (
        this.state.email !== "" &&
        this.state.email !== null &&
        this.state.email !== undefined
      ) {
        if (this.state.emailCheck === true) {
          if (
            this.state.branchId !== "" &&
            this.state.branchId !== null &&
            this.state.branchId !== undefined
          ) {
            proceedSaveBranchOperator(this.state, this.state.useroperatorId)
              .then((res) => {
                if (res) {
                  if (this.state.useroperatorId) {
                    this.showNotify(
                      i18n.t("addoperators.notify.operatorupdatedsuccessfully"),
                      "success"
                    );
                  } else {
                    this.showNotify(
                      i18n.t("addoperators.notify.operatorcreatedsuccessfully"),
                      "success"
                    );
                  }
                } else {
                  this.showNotify(
                    i18n.t("addoperators.notify.genericerror"),
                    "error"
                  );
                }

                this.reset();

                /// Lê os estabelecimentos e os operadores
                this.getAllBranchsAndOperators();
              })
              .catch((error) => {
                this.showNotify(error, "error");
              });
          } else {
            this.showNotify(
              i18n.t("addoperators.notify.establishmentrequired"),
              "error"
            );
          }
        } else {
          /// Email já em uso.
          this.showNotify(
            i18n.t("addoperators.notify.emailalreadyinuse"),
            "error"
          );
        }
      } else {
        /// Email obrigatório
        this.showNotify(i18n.t("addoperators.notify.emailrequired"), "error");
      }
    } else {
      /// Nome obrigatório
      this.showNotify(i18n.t("addoperators.notify.namerequired"), "error");
    }
  };

  render() {
    return (
      <div className="container col-11" style={{ marginLeft: "30px" }}>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={true}
        />

        <div id="operator" className="row mt-3 branch">
          <div className="col-12">
            <div className="card" style={{ height: "600px", overflow: "auto" }}>
              <div className="card-body">
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div
                        className="form-avatar"
                        style={{
                          backgroundImage: `url(${
                            this.state.image && this.state.image != ""
                              ? this.state.image
                              : this.state.logoURL
                          })`,
                        }}
                      ></div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-row-reverse">
                        <SelectBox
                          dataSource={
                            this.state.operators ? this.state.operators : null
                          }
                          style={{ margin: "3px" }}
                          displayExpr="name"
                          valueExpr="_id"
                          value={
                            this.state.useroperatorId
                              ? this.state.useroperatorId
                              : null
                          }
                          width={150}
                          onValueChanged={(event) =>
                            this.operatorSelected(event)
                          }
                        />
                        <Button
                          id="backButton"
                          icon="plus"
                          onClick={this.reset}
                          text={i18n.t("addoperators.btnnew")}
                          height={30}
                          width={100}
                          style={{ margin: "3px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body" style={{ paddingBottom: "5px" }}>
                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addoperators.labelname")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          showClearButton={true}
                          placeholder={i18n.t("addoperators.placeholdername")}
                          value={this.state.name}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "name")
                          }
                        >
                          <Validator>
                            <RequiredRule
                              message={i18n.t(
                                "addoperators.message.namerequired"
                              )}
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addoperators.labelemail")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          showClearButton={true}
                          placeholder={i18n.t("addoperators.placeholder")}
                          value={this.state.email}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "email")
                          }
                          onKeyUp={(event) => this.onKeyUp(event, "email")}
                        >
                          <Validator>
                            <RequiredRule
                              message={i18n.t(
                                "addoperators.message.emailrequired"
                              )}
                            />
                            <EmailRule
                              message={i18n.t(
                                "addoperators.message.emailinvalid"
                              )}
                            />
                            <AsyncRule
                              message={i18n.t(
                                "addoperators.message.emailalreadyregistered"
                              )}
                              validationCallback={(event) =>
                                this.asyncCompanyIdentifierEmailValidation(
                                  event
                                )
                              }
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>

                    <div className="dx-field">
                      <div className="dx-field-label">
                        {i18n.t("addoperators.labelestablishment")}
                      </div>
                      <div className="dx-field-value">
                        <SelectBox
                          dataSource={
                            this.state.branchs ? this.state.branchs : null
                          }
                          displayExpr="companyName2"
                          valueExpr="_id"
                          value={
                            this.state.branchId ? this.state.branchId : null
                          }
                          onValueChanged={(event) => this.branchSelected(event)}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div
                        className="row"
                        style={{ float: "right", marginRight: "-11px" }}
                      >
                        <div className="col pr-0">
                          <label
                            style={{
                              fontSize: "12px",
                              paddingTop: "6px",
                              float: "right",
                            }}
                            htmlFor=""
                          >
                            {i18n.t("addoperators.labeldeactivate")}
                          </label>
                        </div>
                        <div className="col">
                          <div
                            style={{
                              display: "block",
                              height: "25px",
                              margin: "0px auto",
                            }}
                          >
                            <div
                              className="custom-control custom-switch"
                              style={{ float: "left", paddingTop: "3px" }}
                            >
                              <input
                                id="ckb_isActive"
                                type="checkbox"
                                className="custom-control-input"
                                checked={
                                  this.state.isActive
                                    ? this.state.isActive
                                    : false
                                }
                                onChange={(event) => {
                                  this.handleValueChange(event, "isActive");
                                }}
                              />
                              <label
                                title={
                                  this.state.isActive == false
                                    ? i18n.t("addoperators.labeldeactivate")
                                    : i18n.t("addoperators.labelactivate")
                                }
                                className="custom-control-label red"
                                style={{ fontSize: "12px", paddingTop: "3px" }}
                                htmlFor="ckb_isActive"
                              >
                                {i18n.t("addoperators.labelactivate")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div id="addressCard" className="card">
                  <div
                    className="card-body second-group"
                    style={{ paddingBottom: "5px" }}
                  >
                    <div className="col-12 pr-0">
                      <div className="row">
                        <div className="col-6"></div>
                        <div className="col-6 form-group mt-1">
                          <Button
                            id="backButton"
                            icon="tags"
                            text={
                              this.state.useroperatorId
                                ? i18n.t("addoperators.labelupdate")
                                : i18n.t("addoperators.labelregister")
                            }
                            width="150px"
                            height="37px"
                            type="success"
                            visible={true}
                            onClick={this.proceedSaveBranchOperator}
                            style={{
                              borderWidth: "0px",
                              borderStyle: "unset",
                              float: "right",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <label>
                  <ul>
                    <li>{i18n.t("addoperators.message.firstgeneric")}</li>
                    <li>{i18n.t("addoperators.message.secondgeneric")}</li>
                  </ul>
                </label>
                <a
                  className="btn btn-info"
                  href={
                    actualURL.pwaURL +
                    `?rootId=${this.state.rootId}&branchId=${this.state.branchId}&install`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: this.state.rootId ? "block" : "none" }}
                >
                  {i18n.t("addoperators.labeloperatoraccess")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
