import React, { Component } from "react";
import { i18n } from "../../translate/i18n";
import { getAccountLimits } from "../../apis/toowezeApi";

export default class AccountSupportMenu extends Component {
  constructor(props) {
    super(props);

    let accountInfo = JSON.parse(localStorage.getItem("auth"));

    this.state = {
      _id: accountInfo._id,
      accountId: accountInfo.accountId,
      createdAt: accountInfo.createdAt,
      email: accountInfo.email,
      isActive: accountInfo.accountInfoisActive,
      isAdmin: accountInfo.isAdmin,
      isConfigured: accountInfo.isConfigured,
      isSuper: accountInfo.isSuper,
      isVerify: accountInfo.isVerify,
      logo: accountInfo.logo,
      name: accountInfo.name,
      planId: accountInfo.planId,
      rootId: accountInfo.rootId,
      validTo: accountInfo.validTo,

      contactsLimit: accountInfo.contactsLimit,
      aditionalBranchs: accountInfo.aditionalBranchs,
      emailSendingQuota: accountInfo.emailSendingQuota,
      usedAditionalBranchs: accountInfo.usedAditionalBranchs,
      usedCampaigns: accountInfo.usedCampaigns,
      usedContactsLimit: accountInfo.usedContactsLimit,
      usedEmailSendingQuota: accountInfo.usedEmailSendingQuota,
    };
  }

  componentDidMount = () => {
    getAccountLimits().then(accountLimits => {
      this.setState({
        contactsLimit: accountLimits.totalAllowedContacts,
        usedContactsLimit: accountLimits.totalUsedContacts,
        emailSendingQuota: accountLimits.totalAllowedSendEmails,
        usedEmailSendingQuota: accountLimits.totalUsedSendEmails,
      })
    }).catch(err => {
      console.log("Err", err);
    })
  }

  render() {
    return (
      <div>
        <div className="">
          <div className="card">
            <div className="card-body">
              {/* <div>
                <span id="totalCampaign">
                  {i18n.t("account.support.labellimit")}
                </span>
                <div
                  className="progress mb-2"
                  style={{ backgroundColor: "#c9e4dc" }}
                >
                  <div
                    id="campaignInfo"
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{
                      width:
                        (this.state.usedContactsLimit /
                          this.state.contactsLimit) *
                        100 +
                        "%",
                    }}
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span id="labelcampaignInfo">
                      <strong>
                        {i18n.t("account.support.labelused")}
                        {this.state.usedContactsLimit}
                      </strong>
                    </span>
                  </div>
                </div>
              </div> */}
              <div>
                <span id="totalContacts">
                  {i18n.t("account.support.labelcontactslimit") + " "}
                  {this.state.contactsLimit}
                </span>
                <div
                  className="progress mb-2"
                  style={{ backgroundColor: "#c9e4dc" }}
                >
                  <div
                    id="contactsInfo"
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{
                      width:
                        (this.state.usedContactsLimit /
                          this.state.contactsLimit) *
                        100 +
                        "%",
                    }}
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <label id="labelcontactsInfo" style={{ color: "black" }} title="Teste">
                      <strong>
                        {i18n.t("account.support.labelused")}
                        {this.state.usedContactsLimit}
                      </strong>
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <span id="totalEmails">
                  {i18n.t("account.support.labelemailsendlimit")}
                  {this.state.emailSendingQuota}
                </span>
                <div
                  className="progress mb-2"
                  style={{ backgroundColor: "#c9e4dc" }}
                >
                  <div
                    id="emailsInfo"
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{ width: "0%" }}
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span id="labelemailsInfo">
                      <strong>
                        {i18n.t("account.support.labelused")}
                        {this.state.usedEmailSendingQuota}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              {/* <div>
                {i18n.t("account.support.labelextraestablishments")}
                {this.state.aditionalBranchs}
                <div
                  className="progress mb-2"
                  style={{ backgroundColor: "#c9e4dc" }}
                >
                  <div
                    className="progress-bar bg-info"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span>
                      <strong>
                        {i18n.t("account.support.labelused")}
                        {this.state.usedAditionalBranchs}
                      </strong>
                    </span>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
          <div style={{ padding: "5px" }}></div>
          <div className="card">
            <div className="card-body">
              <div>
                <label className="progress-bar bg-warning" style={{ color: "black", borderRadius: "5px" }}>
                  Validade da Assinatura: {(new Date(this.state.validTo + "T23:23:59").toLocaleDateString("pt-BR"))}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
