import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Auth } from "../../apis/toowezeApi"

const CARDS = [
  { id: 1, title: "E-mail Marketing", href: "emailmktstats" },
  { id: 2, title: "Vendas", href: "salesstats" },
  // { id: 3, title: "NPS", href: "nps" },
  { id: 4, title: "Transações Vendas", href: "transactions" },
]

export default function DashBoard(props) {
  const [user, setUser] = useState(null)

  useEffect(() => {
    Auth(localStorage.getItem("tooweze_token"))
      .then((auth) => {
        setUser({
          userName: auth.name,
          accountId: auth.accountId,
          contactsLimit: auth.contactsLimit,
          createdAt: auth.createdAt,
          email: auth.email,
          isActive: auth.isActive,
          isAdmin: auth.isAdmin,
          isConfigured: auth.isConfigured,
          isSuper: auth.isSuper,
          isVerify: auth.isVerify,
          name: auth.name,
          planId: auth.planId,
          rootId: auth.rootId,
          validTo: auth.validTo,
          profilePic: auth.profilePic
            ? auth.profilePic
            : "../images/avatar-1_.jpg",
        });
      })
      .catch((error) => {
        console.log(error);
        try {
          localStorage.removeItem("tooweze_token");
        } catch (error) {
          console.log(error);
        }
      });
  }, [])


  function DefaultComponent() {
    return <div className="container-settings">
      <div className="content-title-settings">
        <div className="title-settings">
        </div>
      </div>
      <div className="cards-container-settings">
        {CARDS.map((card) => (
          <Link
            key={card.href}
            to={card.href}>
            <div className="card-settings" key={card.id} >
              <label> {card.title}</label>
            </div>
          </Link>
        ))}
      </div>
    </div>
  }

  return <DefaultComponent user={user} />
}