import React, { useEffect, useState, lazy, Suspense } from "react";

import "./settingsStyle.css"
import { Auth } from "../../apis/toowezeApi"
import { LoadingIndicator } from "devextreme-react/bar-gauge";
import { locale, loadMessages } from "devextreme/localization";
import { i18n, lgMessages, languageCode } from "../../translate/i18n";

const CARDS = [
  { id: 1, title: i18n.t("configurations.pointsrules"), href: "config-points", disabled: false },
  { id: 2, title: i18n.t("configurations.cashbackrules"), href: "config-cashback", disabled: false },
  // { id: 3, title: "Configurar Cupons de Desconto", href: "config-coupon" },
  // { id: 4, title: "Criar Campanhas", href: "campaign" },
  { id: 5, title: i18n.t("configurations.portalandapp"), href: "config-app", disabled: false },
  { id: 6, title: i18n.t("configurations.store"), href: "config-store", disabled: true },
  { id: 7, title: i18n.t("configurations.configcomm"), href: "config-comm", disabled: false },
]


export default function SettingsMiddle(props) {
  const [user, setUser] = useState(null)

  loadMessages(lgMessages);
  locale(languageCode);

  useEffect(() => {
    Auth(localStorage.getItem("tooweze_token"))
      .then((auth) => {
        setUser({
          userName: auth.name,
          accountId: auth.accountId,
          contactsLimit: auth.contactsLimit,
          createdAt: auth.createdAt,
          email: auth.email,
          isActive: auth.isActive,
          isAdmin: auth.isAdmin,
          isConfigured: auth.isConfigured,
          isSuper: auth.isSuper,
          isVerify: auth.isVerify,
          name: auth.name,
          planId: auth.planId,
          rootId: auth.rootId,
          validTo: auth.validTo,
          profilePic: auth.profilePic
            ? auth.profilePic
            : "../images/avatar-1_.jpg",
        });
      })
      .catch((error) => {
        console.log(error);
        try {
          localStorage.removeItem("tooweze_token");
        } catch (error) {
          console.log(error);
          // teste
        }
      });
  }, [])


  function DefaultComponent() {
    return <div className="container-settings">
      {/* <div className="content-title-settings">
        {user?.userName ? (
          <div className="subtitle-settings">
            Olá, {user?.userName}
          </div>
        ) : null}
        <div className="title-settings">
          Seja bem vindo(a)!
        </div>
      </div> */}
      <div className="cards-container-settings">
        {CARDS.map((card) => (
          <div className={`card-settings ${card.disabled ? 'disabled-card' : ''}`} onClick={() => props.history.push(`?tab=${card.href}`)} key={card.id}>
            {card.title}
          </div>
        ))}
      </div>
    </div>
  }

  let tab = props?.tab;

  const mappingComponentByTab = {
    "config-points": () => lazy(() => import("./components/Rules/PointsRule")),
    "config-cashback": () => lazy(() => import("./components/Rules/CashbackRule")),
    // "config-coupon": () => lazy(() => import("./components/")),
    // "campaign": () => lazy(() => import("./components/ConfigApp")),
    "config-app": () => lazy(() => import("./components/ConfigApp")),
    "config-comm": () => lazy(() => import("./components/ConfigComm"))
  }

  if (tab) {
    tab = tab.replace("?=", "")
    const Component = mappingComponentByTab?.[tab]?.() || null

    if (Component) {
      return (
        <Suspense fallback={<LoadingIndicator />}>
          <Component user={user} />
        </Suspense>
      )
    } else return <DefaultComponent user={user} />
  }


  return <DefaultComponent user={user} />
}