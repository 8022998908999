const production = true;
let urlSource;
let adminToken_;
let actualURLSource;
let pwaURL;

if (production) { // Produção
    urlSource = "https://api.tooweze.com/api/";
    // urlSource = "http://localhost:3000/api/"; // / API REST
    adminToken_ = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1Y2UzMjZmYTU5YTM1NDAwMTc0NzQ2NWMiLCJpc0FkbWluIjp0cnVlLCJpc1N1cGVyIjp0cnVlLCJyb290SWQiOiJmMzZiNmZmNS01YmRmLTU0NDgtYjE1Yy1lNjUzNDA3Y2I2MDAiLCJpYXQiOjE1NjM3OTg2Mzd9.Vn1mAsdnUyCEqhfMC--pZ28IffXGfFd4aJQcoPE8z1g";
    actualURLSource = "https://customer.tooweze.com/";
    pwaURL = "https://sales.tooweze.com/";
    // Local
    // urlSource = 'http://localhost:3000/api/'
    // adminToken_ = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZDkzYTBhMjNhMWI1ZDAwMTc4OWMwZjAiLCJpc0FkbWluIjp0cnVlLCJpc1N1cGVyIjpmYWxzZSwicm9vdElkIjoiN2Y5ZTY5M2MtNmU3Yi01ZDU4LWIzYWEtZmJlYzkxMDc4MDEzIiwiaWF0IjoxNTg3NTIxMTA4fQ.rTqhS-wouQUjyjXA7KbqE6JPoOam17HhV_LH2Zr9x0w';

} else {
    urlSource = "http://localhost:3000/api/"; // / API REST
    adminToken_ = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1YzhhNWVhNWM5ZGRmOTA5NmM4OWI4ZGQiLCJpc0FkbWluIjp0cnVlLCJpc1N1cGVyIjp0cnVlLCJyb290SWQiOiI1NTI1IiwiaWF0IjoxNTU4NTM2NzEzfQ.cvBA6whVFzBkj1C6BxFx8v_05Lpl91WR7PGnbSmBr_M";
    urlSource = 'http://localhost:3000/api/'
    adminToken_ = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1Y2UzMjZmYTU5YTM1NDAwMTc0NzQ2NWMiLCJpc0FkbWluIjp0cnVlLCJpc1N1cGVyIjp0cnVlLCJyb290SWQiOiJmMzZiNmZmNS01YmRmLTU0NDgtYjE1Yy1lNjUzNDA3Y2I2MDAiLCJpYXQiOjE1NjM3OTg2Mzd9.Vn1mAsdnUyCEqhfMC--pZ28IffXGfFd4aJQcoPE8z1g";

    // // adminToken_ = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZTY4MmM2YmRmNGRkYjRmZjRjYzhhM2EiLCJpc0FkbWluIjp0cnVlLCJpc1N1cGVyIjpmYWxzZSwicm9vdElkIjoiOWJhMzAzNjMtMmE2Yi01MTM3LWJmN2ItNmQxMzFjM2YyOTE3IiwiaWF0IjoxNTg4MDc5MDg5fQ.RkXWKOc8YwVJQ4P-GvR39ls8UO-0q2cxykr1hzi0z58';
    actualURLSource = "http://localhost:8080/"; // / FRONTEND
    pwaURL = "http://127.0.0.1:8080/";
}

export const adminToken = {
    token: adminToken_
};

// export PORT=3001
export const urlAPI = {
    campaigns: urlSource + "campaigns",
    message: urlSource + "message",
    contact: urlSource + "contact",
    contactList: urlSource + "contactlist",
    publish: urlSource + "publish",
    coupon: urlSource + "coupon",
    couponpage: urlSource + "couponpage",
    users: urlSource + "users",
    mailvalidation: urlSource + "mailvalidation",
    auth: urlSource + "auth",
    login: urlSource + "login",
    registeraccessedpage: urlSource + "registeraccessedpage",
    recoverpassword: urlSource + "recoverpassword",
    change: urlSource + "recoverpassword/change",
    events: urlSource + "events",
    sendervalidation: urlSource + "sendervalidation",
    codegenerate: urlSource + "codegenerate",
    registercoupon: urlSource + "registercoupon",
    validateallsenders: urlSource + "validateallsenders",
    account: urlSource + "account",
    plan: urlSource + "plans",
    registercharge: urlSource + "registercharge",
    notifypayment: urlSource + "notifypayment",
    imagegallery: urlSource + "imagegallery",
    branch: urlSource + "branch",
    useroperator: urlSource + "useroperator",
    stickerprovider: urlSource + "stickerprovider",
    paymentgateway: urlSource + "paymentgateway",
    balance: urlSource + "balance",
    emailstats: urlSource + "emailstats",
    landingpage: urlSource + "landingpage",
    emailtest: urlSource + "emailtest",
    studio: urlSource + "studio",
    accountlimits: urlSource + "accountlimits",
    imagebank: urlSource + "imagebank",
    products: urlSource + "products",
    portal: urlSource + "configurations/portal",
    store: urlSource + "store",
    addstoreitem: urlSource + "addstoreitem",
    rules: urlSource + "createrules",
    customers: urlSource + "customers",
    customertotalbalance: urlSource + "customertotalbalance",
    customerbalance: urlSource + "customerbalance",
    reports: urlSource + "reports",
    customerActivity: urlSource + "customerActivity",
    communicationsconfigs: urlSource + "communicationsconfigs",
    customercommunicationsconfigs: urlSource + "customercommunicationsconfigs",
    logs: urlSource + "logs",
};

// Define a url actual em ambiente de produção ou de desenvolvimento
export const actualURL = {
    url: actualURLSource,
    pwaURL: pwaURL
};

export const redirecURL = {
    url: "https://blash-76778.firebaseapp.com/viewcoupon.html",
    title: "Tooweze! - Sales Improvement Tools"
};
