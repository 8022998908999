import React, { Component } from "react";
import "./ViewLandingPagesMenu.css";
import { connect } from "react-redux";
import { getLandingPage, updateConfigLandinPage, proceedLandingConfigStatus, deleteLandingPage, getLandingPagesTemplates, CreateLandinPage } from "../../../apis/toowezeApi";
import { TextBox, TextArea } from "devextreme-react";
import { loadMessages, locale } from "devextreme/localization";
import { Button } from "devextreme-react";
import { Redirect } from "react-router";
import Modal from "../../Modal";
import updateViewLandingPageMenu from "../../../actions/updateViewLandingPageMenu";
import { bindActionCreators } from "redux";
import updateModal from "../../../actions/updateModal";
import notify from "devextreme/ui/notify";
import { LoadPanel } from "devextreme-react/load-panel";
import { Validator, RequiredRule } from "devextreme-react/validator";
import { i18n, lgMessages, languageCode } from "../../../translate/i18n";
import he from 'he';

let Spinner;

class ViewLandingPagesMenu extends Component {
    constructor(props) {
        super(props);

        loadMessages(lgMessages);
        locale(languageCode);

        this.state = {
            _id: "",
            title: "",
            pageTitle: "",
            bucket: "",
            defaultUrl: "",
            defaultImage: [],
            showImage: "",
            domain: "",
            scripts: "",
            status: 0,
            loadPanelVisible: false,
            html: "",
            css: ""
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true;
        }

        // // Evita renderizar cada vez que o estado é modificado
        if (
            this.props.viewLandingPageMenuReducer !== nextProps.viewLandingPageMenuReducer
        ) {
            if (this.props.viewLandingPageMenuReducer[0].element !== "0") {
                this.getLandingPage_(this.props.viewLandingPageMenuReducer[0].element, this.props.viewLandingPageMenuReducer[0].extra);
                return true;
            } else {
                // Limpar todos os campos e imagem do supporte menu.
            }
        }

        // // Evita renderizar cada vez que o estado é modificado
        if (this.props.modalControl !== nextProps.modalControl) {
            if (this.props.modalControl[0].element === "deleteLanginPage" && this.props.modalControl[0].go === "deleteLanginPage") {

                deleteLandingPage(this.props.modalControl[0].extra).then((res) => {
                    this.props.updateViewLandingPageMenu(
                        "0",
                        0,
                        "updateViewLandingPageMenu"
                    );
                    this.handleReloadOnClick()
                });
            }
            return true;
        }

        return true;
    }

    handleDeleteLandingPage = (event) => {
        this.props.updateModal("deleteLanginPage", 0, event);
    };

    componentDidMount() {
        Spinner = document.getElementById("loading"); // Spinner
    }

    resetMenu() {
        this.setState({
            _id: 0,
            title: "",
            pageTitle: "",
            bucket: "",
            defaultUrl: "",
            defaultImage: "",
            showImage: "",
            domain: "",
            scripts: "", //,
            status: false,
            loadPanelVisible: false,
        });
        this.SpinnerOn(true)
    }

    // Obtem LandinPage usando o id
    getLandingPage_(id, type) {
        this.resetMenu();
        if (id) {
            if (type === 'template') {
                if (id !== -1) {
                    getLandingPagesTemplates(id).then((res) => {
                        let urlChoice;
                        if (res?.defaultImage) {
                            if (res?.defaultImage.length > 1) {
                                const maxSize = res?.defaultImage.reduce((acc, shot) => acc = acc > shot.size ? acc : shot.size, 0);
                                for (let i = 0; i < res.defaultImage.length; i++) {
                                    if (res.defaultImage[i].size === maxSize)
                                        urlChoice = res.defaultImage[i].url
                                }
                            } else {
                                if (res?.defaultImage[0]?.url) {
                                    urlChoice = res?.defaultImage[0]?.url
                                }
                            }
                        }

                        this.setState({
                            _id: -1,
                            title: res.title,
                            pageTitle: res.pageTitle,
                            defaultImage: res.defaultImage,
                            showImage: urlChoice ? urlChoice : "https://coupon-images-tooweze-thumb.s3.amazonaws.com/blank_image_landingpage.png",
                            loadPanelVisible: false,
                            html: res.html,
                            css: res.css,
                            bucket: ""
                        });
                    });
                } else {
                    // Pagina em branco
                    this.setState({
                        _id: id,
                        title: 'Untitled1',
                        pageTitle: 'Untitled',
                        defaultImage: [{ url: "https://coupon-images-tooweze-thumb.s3.amazonaws.com/blank_image_landingpage.png", size: 1000 }],
                        showImage: "https://coupon-images-tooweze-thumb.s3.amazonaws.com/blank_image_landingpage.png",
                        loadPanelVisible: false,
                        html: "<h1>Hello Word</h1>",
                        css: "h1 { text-align: center;}",
                        bucket: "",
                    });
                }


            } else if (type === 'file') {

                getLandingPage(id).then((res) => {

                    let urlChoice;

                    if (res?.defaultImage) {
                        if (res?.defaultImage.length > 1) {
                            const maxSize = res?.defaultImage.reduce((acc, shot) => acc = acc > shot.size ? acc : shot.size, 0);
                            for (let i = 0; i < res.defaultImage.length; i++) {
                                if (res.defaultImage[i].size === maxSize)
                                    urlChoice = res.defaultImage[i].url
                            }
                        } else {
                            if (res?.defaultImage[0]?.url) {
                                urlChoice = res?.defaultImage[0]?.url
                            }
                        }
                    }

                    // Preparando os valores para serem inseridos nos controles de Data e Hora do Devexpress
                    this.setState({
                        _id: res._id,
                        title: res.title,
                        pageTitle: res.pageTitle,
                        bucket: res.bucket,
                        defaultUrl: res.defaultUrl,
                        defaultImage: res.defaultImage,
                        showImage: urlChoice ? urlChoice : "https://coupon-images-tooweze-thumb.s3.amazonaws.com/blank_image_landingpage.png",
                        domain: res.domain,
                        scripts: he.decode(res.scripts.join('')), //,
                        status: res.status,
                        loadPanelVisible: false,
                    });
                });
            }

        }
    }

    // Direciona para a página de editar a landinpage com a id na url
    handleRedirectOnClick = (id) => {
        if (id !== -1) {
            this.setState({ redirect: true, id: id });
        } else {
            this.showNotify(
                i18n.t("mylandingpages.support.notifysaveconfigpage"),
                "error"
            );
        }
    };

    // Reload a página após deletar um cupom
    handleReloadOnClick = () => {
        let urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("id");
        window.location.reload(false);
    };

    handleValueChange = (event, fieldId) => {

        if (fieldId) {
            if (fieldId == "random") {
                // random não é devextreme
                this.setState({
                    [fieldId]: event.target.checked,
                });
            } else if (fieldId == "scripts") {

                // let scripts_ = event.value.split('</script>');

                // console.log('scripts_', scripts_)

                // "\n"

                this.setState({
                    [fieldId]: event.value ? event.value.toString() : [],
                });
            } else {
                this.setState({
                    [fieldId]: event.value ? event.value.toString() : null,
                });
            }
        }

    };

    handleStatus(event) {
        // Atualizar Status Direto no Banco de Dados
        if (this.state._id) {
            if (
                this.state.title === "" ||
                this.state.title === undefined ||
                this.state.title === null
            ) {
                this.showNotify(
                    i18n.t("mylandingpages.support.notifypagetitle"),
                    "error"
                );
                this.SpinnerOn(false);
                this.setState({ loadPanelVisible: false });
            } else if (
                this.state.bucket === "" ||
                this.state.bucket === undefined ||
                this.state.bucket === null
            ) {
                this.showNotify(
                    i18n.t("mylandingpages.support.notifypagebucket"),
                    "error"
                );
                this.SpinnerOn(false);
                this.setState({ loadPanelVisible: false });
            } else {

                this.showNotify(
                    "Status da página, alterado com sucesso!",
                    "success"
                );
                this.SpinnerOn(false);
                this.setState({ loadPanelVisible: false });

                this.setState(
                    {
                        status: event.target.checked ? 1 : 0,
                    },
                    () => this.proceedLandingConfigStatus()
                );
            }
        }
    }

    proceedLandingConfigStatus() {
        if (this.state.status === 0) {
            // Mantenho assim, pois se setar o status para zero, não vai fazer todo o processo abaixo de gravar tudo.
            proceedLandingConfigStatus(this.state.status, this.state._id)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.status === 1) {
                            this.showNotify(
                                i18n.t("mylandingpages.support.notifyconfigactivestatus"),
                                "success"
                            );
                        } else {
                            this.showNotify(
                                i18n.t("mylandingpages.support.notifyconfigdeactivestatus"),
                                "success"
                            );
                        }
                    }
                })
                .catch((error) => {
                    this.showNotify(error, "error");
                });
        } else if (this.state.status === 1) {
            // Isso garante que ao atualizar o Status, não grave com Desconto 0%
            this.updateConfigLandinPage();
        }
    }

    // Gravar ou atualiza a LandingPage
    updateConfigLandinPage = () => {

        if (this.state._id) {
            if (
                this.state.title === "" ||
                this.state.title === undefined ||
                this.state.title === null
            ) {
                this.showNotify(
                    i18n.t("mylandingpages.support.notifypagetitle"),
                    "error"
                );
                this.SpinnerOn(false);
                this.setState({ loadPanelVisible: false });
            } else if (
                this.state.bucket === "" ||
                this.state.bucket === undefined ||
                this.state.bucket === null
            ) {
                this.showNotify(
                    i18n.t("mylandingpages.support.notifypagebucket"),
                    "error"
                );
                this.SpinnerOn(false);
                this.setState({ loadPanelVisible: false });
            } else {
                // Pode proseguir
                // if (this.state._id !== -1 && this.state._id !== "") {

                this.SpinnerOn(true, i18n.t("mylandingpages.support.messagesaving"));
                this.setState({ loadPanelVisible: true });


                // if (this.state.defaultUrl !== "") {
                // Atualiza o Cupom Atual

                let splitArray = this.state.scripts.toString().replace(/(\r\n|\n|\r)/gm, "");
                splitArray = splitArray.split('</script>');

                const splitArray2 = [];

                splitArray.forEach(item => {
                    if (item.includes('script')) {
                        splitArray2.push(item + '</script>');
                    }
                });

                let landingPageConfigs = {
                    bucket: this.clearBucket(this.state.bucket),
                    defaultUrl: this.state.defaultUrl,
                    defaultImage: this.state.defaultImage,
                    domain: this.state.domain,
                    pageTitle: this.state.pageTitle,
                    scripts: splitArray2,
                    status: this.state.status,
                    title: this.state.title,
                };

                if (this.state._id !== -1 && this.state._id !== "") {
                    updateConfigLandinPage(this.state._id, landingPageConfigs)
                        .then((res) => {
                            // Se
                            // res ************** Bucket Already in use.

                            this.setState({
                                _id: res._id
                            })
                            this.props.updateViewLandingPageMenu(
                                "0",
                                0,
                                "updateViewLandingPageMenu"
                            );
                            if (res) {
                                if (res !== "Bucket Already in use." && res?.message !== "The specified bucket does not exist" && res !== 'The specified bucket is not valid.') {
                                    setTimeout(() => {
                                        this.SpinnerOn(false);
                                        this.setState({ loadPanelVisible: false });
                                        this.SpinnerOn(
                                            true,
                                            i18n.t("mylandingpages.support.messageupdating")
                                        );
                                        setTimeout(() => {
                                            this.SpinnerOn(false);
                                        }, 1000);
                                    }, 1000);

                                } else {
                                    this.SpinnerOn(false);
                                    this.setState({ loadPanelVisible: false });

                                    if (res === 'Bucket Already in use.') {
                                        this.showNotify("Domínio já em uso!", "error");
                                    } else if (res === 'The specified bucket is not valid.') {
                                        this.showNotify("Domínio não pode conter caracteres inválidos!.", "error");
                                    } else if (res?.message === 'The specified bucket does not exist') {
                                        this.showNotify("Erro ao criar novo domínio.", "error");
                                    }

                                }
                            } else {
                                this.SpinnerOn(false);
                                this.showNotify("ERRO!", "error");
                            }
                        })
                        .catch((err) => {

                            this.showNotify(err?.response?.data, "error");

                            this.setState({ loadPanelVisible: false });
                            this.SpinnerOn(false);
                        });
                } else {
                    // CreateLandinPage

                    landingPageConfigs.html = this.state.html;
                    landingPageConfigs.css = this.state.css;
                    landingPageConfigs.defaultImage = this.state.defaultImage;

                    CreateLandinPage(landingPageConfigs).then(res => {
                        this.props.updateViewLandingPageMenu(
                            "0",
                            0,
                            "updateViewLandingPageMenu"
                        );
                        if (res) {
                            this.setState({
                                _id: res._id
                            })
                            if (res !== "Bucket Already in use." && res?.message !== "The specified bucket does not exist" && res !== 'The specified bucket is not valid.') {
                                setTimeout(() => {
                                    this.SpinnerOn(false);
                                    this.setState({ loadPanelVisible: false });
                                    this.SpinnerOn(
                                        true,
                                        i18n.t("mylandingpages.support.messageupdating")
                                    );
                                    setTimeout(() => {
                                        this.SpinnerOn(false);
                                    }, 1000);
                                }, 1000);

                            } else {
                                this.SpinnerOn(false);
                                this.setState({ loadPanelVisible: false });

                                if (res === 'Bucket Already in use.') {
                                    this.showNotify("Domínio já em uso!", "error");
                                } else if (res === 'The specified bucket is not valid.') {
                                    this.showNotify("Domínio não pode conter caracteres inválidos!.", "error");
                                } else if (res?.message === 'The specified bucket does not exist') {
                                    this.showNotify("Erro ao criar novo domínio.", "error");
                                }

                            }
                        } else {
                            this.SpinnerOn(false);
                            this.showNotify("ERRO!", "error");
                        }
                    }).catch(err => {

                        if (err.response.data === "Cupom Code Already Exists!") {
                            this.showNotify(
                                i18n.t("mylandingpages.support.notifycodealready"),
                                "error"
                            );
                        } else {
                            this.showNotify(err.response.data, "error");
                        }

                        this.setState({ loadPanelVisible: false });
                        this.SpinnerOn(false);
                    })
                }


                // } else {
                //     /// Erro
                // }

                // } else {
                // Gravar
                // CreateLandinPage
                // Selecione um cupom
                // this.showNotify(
                //     i18n.t("mylandingpages.support.notifyselectpage"),
                //     "error"
                // );
                // this.SpinnerOn(false);
                // this.setState({ loadPanelVisible: false });
            }
        }
    }




    // Faz aparecer uma mensagem
    showNotify(message, type) {
        notify(
            {
                message: message,
                width: 300,
                position: { at: "top", offset: "0 200" },
                closeOnClick: true,
            },
            type,
            3000
        );
    }

    SpinnerOn(on, message) {
        if (on == true) {
            Spinner.style.display = "block";
            Spinner.innerHtml = "block";

            if (message) {
                this.setState({
                    messageClass: "show",
                    messageText: message,
                });
            }
        } else {
            Spinner.style.display = "none";
            setTimeout(() => {
                this.setState({
                    messageClass: "fade",
                    messageText: "",
                });
            }, 4000);
        }
    }

    // log(locale) {
    //     console.log(`${new Intl.DateTimeFormat(locale).format(date)} ${new Intl.NumberFormat(locale).format(count)}`);
    // }

    Item(data) {
        return (
            <div className="coupon custom-item" style={{ height: "40px" }}>
                <div className="row" style={{ height: "40px" }}>
                    <div className="col-4" style={{ height: "40px" }}>
                        {/* <img src={data.logoURL} /> */}
                        <div
                            className="coupon form-avatar"
                            style={{ backgroundImage: `url(${data.logoURL})` }}
                        ></div>
                    </div>
                    <div className="col-8" style={{ height: "40px" }}>
                        <div className="" style={{ paddingTop: "11px" }}>
                            {data.companyName2}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    clearBucket = (bucketName) => {
        let removebuket = bucketName;

        if (removebuket.includes("twz-")) {
            removebuket = removebuket.replace("twz-", "")
        }

        removebuket = removebuket.replace(/\s/g, "").trim();
        removebuket = removebuket.toString().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
        return removebuket;
    }

    render() {
        // console.log('this.props', this.props)
        // this.getLandingPage_(this.props.viewLandingPageMenuReducer[0].element)

        //this.log("en-US");
        // expected output: 5/24/2012 26,254.39

        //this.log("pt-BR");
        // expected output: 24.5.2012 26.254,39

        // console.log("State", this.state);

        if (this.state.redirect == true) {
            if (this.state.id !== -1) {
                return <Redirect push to={`/createpage?id=${this.state.id + '?version=' + new Date().getMilliseconds()}`} />;
            } else {
                // Reload depois de deletar o cupom.
                // return <Redirect push to="/mylandingpages" />;
                // this.showNotify(
                //     i18n.t("mylandingpages.support.notifysaveconfigpage"),
                //     "error"
                // );
            }
        }

        // canvas: "{"version":"3.6.3","objects":[{"type":"image","ver"
        // condiction: "createCoupon.condiction.value"
        // createdAt: 1588204934
        // createdBy: "5e682c6bdf4ddb4ff4cc8a3a"
        // description: "createCoupon.description.value"
        // discount: "createCoupon.discount.value"
        // discountPrice: "0"
        // endDate: "0"
        // image: "https://coupon-images-bash.s3.amazonaws.com/6f83532c-2d7d-482f-96dc-dcc222b92b93.jpg"
        // initDate: "0"
        // minimumPurchase: "0"
        // originalPrice: "0"
        // rootId: "9ba30363-2a6b-5137-bf7b-6d131c3f2917"
        // status: 1
        // title: "createCoupon.title.value"
        // __v: 0
        // _id: "5eb087b5d61c3020243351c0"

        return (
            <div style={{ height: "700px" }}>
                <div
                    id="supportMenu"
                    className="col-12"
                    style={{
                        textAlign: "center",
                        paddingRight: "0px",
                        paddingLeft: "0px",
                    }}
                >
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        // position={position}
                        // onHiding={this.hideLoadPanel}
                        visible={this.state.loadPanelVisible}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                        closeOnOutsideClick={true}
                        height={250}
                    />
                    <Modal
                        modalId="delete-header-modal"
                        title={i18n.t("mylandingpages.support.modaldeletetitle")}
                        h5Content={i18n.t("mylandingpages.support.modaldeleteh5")}
                        content={i18n.t("mylandingpages.support.modaldeletecontent")}
                        cancelText={i18n.t("mylandingpages.support.modaldeletecanceltext")}
                        okText={i18n.t("mylandingpages.support.modaldeleteoktext")}
                        actionType="deleteLanginPage"
                        extra={this.state._id}
                    />

                    <div
                        className=""
                        style={{
                            width: "260px",
                            height: "194px",
                            margin: "0 auto",
                            background: "whitesmoke",
                            minHeight: '250px', backgroundImage: `url(${this.state?.showImage ? this.state?.showImage : 'https://coupon-images-tooweze-thumb.s3.amazonaws.com/blank_image_landingpage.png'})`, backgroundSize: 'cover'
                        }}
                    >
                        {/* <img
                            src={
                                this.state.deafultImage[0]
                            }
                            alt=""
                            style={{ maxWidth: "100%", minWidth: "80%", height: "auto" }}
                        /> */}
                    </div>

                    <div
                        style={{
                            display: "block",
                            height: "25px",
                            margin: "0px auto",
                            width: "260px",
                        }}
                    >
                        <div
                            className="custom-control custom-switch"
                            style={{ float: "left" }}
                        >
                            <input
                                id="ckb_Status"
                                type="checkbox"
                                className="custom-control-input active"
                                checked={this.state.status == 1 ? true : false}
                                onChange={(event) => {
                                    this.handleStatus(event);
                                }}
                                readOnly={this.state._id ? false : true}
                            />
                            <label
                                title={
                                    this.state.status == 1
                                        ? i18n.t("mylandingpages.support.swhitdeactivelabel")
                                        : i18n.t("mylandingpages.support.swhitactivelabel")
                                }
                                className="custom-control-label active"
                                style={{ fontSize: "14px", color: "green" }}
                                htmlFor="ckb_Status"
                            ></label>
                        </div>
                        <div id="loading" style={{ display: "none", textAlign: "right" }}>
                            <span
                                className={`badge badge-info ${this.state.messageClass}`}
                                style={{ marginRight: "6%" }}
                            >
                                {this.state.messageText}
                            </span>
                            <div
                                className="spinner-grow spinner-grow-sm"
                                style={{ color: "#d4453a" }}
                                role="status"
                            >
                                <span className="sr-only"></span>
                            </div>
                            <div
                                className="spinner-grow spinner-grow-sm"
                                style={{ color: "#d4453a" }}
                                role="status"
                            >
                                <span className="sr-only"></span>
                            </div>
                            <div
                                className="spinner-grow spinner-grow-sm"
                                style={{ color: "#d4453a" }}
                                role="status"
                            >
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </div>

                    {/* <div> */}
                    <div className="form">
                        <div className="dx-fieldset" style={{ marginTop: "10px" }}>
                            <div
                            // style={{marginBottom: '0.5rem'}}
                            >
                                <strong>{i18n.t("mylandingpages.support.maintitle")}</strong>
                            </div>
                        </div>

                        <div
                            className=""
                            style={{
                                marginBottom: "0px",
                                minHeight: "30px",
                                zIndex: "999",
                                float: "right",
                                paddingRight: "0px",
                            }}
                        >
                            <Button
                                disabled={this.state._id ? false : true}
                                width={100}
                                text={i18n.t("mylandingpages.support.btnsave")}
                                type="default"
                                stylingMode="contained"
                                style={{ marginLeft: "3px", fontSize: "10px" }}
                                onClick={() => this.updateConfigLandinPage()}
                            />
                            <Button
                                disabled={this.state._id ? false : true}
                                width={100}
                                text={i18n.t("mylandingpages.support.btnedit")}
                                type="default"
                                stylingMode="outlined"
                                style={{ marginLeft: "3px", fontSize: "10px" }}
                                onClick={() =>
                                    this.handleRedirectOnClick(this.state._id)
                                }
                            />
                            <a
                                // id={2}
                                title={"item.title"}
                                className="icons-list-demo"
                                style={{ marginLeft: "4px", cursor: "pointer", zIndex: "999" }}
                                data-toggle="modal"
                                data-target="#delete-header-modal"
                                data-id={this.state._id ? this.state._id : null}
                                onClick={() => this.handleDeleteLandingPage(this.state._id)}
                            >
                                <svg
                                    id={2 + 15}
                                    aria-hidden="true"
                                    focusable="false"
                                    width="22px"
                                    height="22px"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        d={
                                            "M15 16h4v2h-4v-2m0-8h7v2h-7V8m0 4h6v2h-6v-2m-4-2v8H5v-8h6m2-2H3v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V8m1-3h-3l-1-1H6L5 5H2v2h12V5z"
                                        }
                                        fill={"#ff0000"}
                                    />
                                </svg>
                            </a>
                        </div>

                        <br />

                        <div className="dx-field">
                            <div className="dx-field-label">
                                {i18n.t("mylandingpages.support.title")}
                            </div>
                            <div className="dx-field-value">
                                <TextBox
                                    defaultValue=""
                                    readOnly={this.state._id ? false : true}
                                    showClearButton={true}
                                    placeholder={i18n.t("mylandingpages.support.titleplace")}
                                    value={this.state.title}
                                    onValueChanged={(event) =>
                                        this.handleValueChange(event, "title")
                                    }
                                >
                                    <Validator>
                                        <RequiredRule
                                            message={i18n.t("mylandingpages.support.validtitleplace")}
                                        />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>

                        <div className="dx-field">
                            <div className="dx-field-label">
                                {i18n.t("mylandingpages.support.pagetitle")}
                            </div>
                            <div className="dx-field-value">
                                <TextBox
                                    defaultValue=""
                                    readOnly={this.state._id ? false : true}
                                    showClearButton={true}
                                    placeholder={i18n.t("mylandingpages.support.pagetitleplace")}
                                    value={this.state.pageTitle}
                                    onValueChanged={(event) =>
                                        this.handleValueChange(event, "pageTitle")
                                    }
                                >
                                    <Validator>
                                        <RequiredRule
                                            message={i18n.t("mylandingpages.support.validpagetitle")}
                                        />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label">
                                {i18n.t("mylandingpages.support.bucket")}
                            </div>
                            <div className="dx-field-value">
                                <TextBox
                                    defaultValue=""
                                    // readOnly={this.state._id ? false : true}
                                    showClearButton={true}
                                    placeholder={i18n.t("mylandingpages.support.bucketplace")}
                                    value={this.clearBucket(this.state?.bucket?.toString().replace('twz-', ''))}
                                    onValueChanged={(event) =>
                                        this.handleValueChange(event, "bucket")
                                    }
                                >
                                    <Validator>
                                        <RequiredRule
                                            message={i18n.t("mylandingpages.support.validbucketplace")}
                                        />
                                    </Validator>
                                </TextBox>
                            </div>
                            <div className="dx-field-label">
                            </div>
                        </div>

                        <div className="row" style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <div>
                                <label><a className="badge badge-success" href={this.state?.bucket ? ('https://twz-' + this.clearBucket(this.state?.bucket?.toString().replace('twz-', ''))) + '.s3.amazonaws.com/index.html' + '?version=' + new Date().getMilliseconds() : ""} target="_blank">{this.state?.bucket ? 'twz-' + this.clearBucket(this.state?.bucket?.toString().replace('twz-', '')) + '.s3.amazonaws.com' : ""}</a></label>
                            </div>
                        </div>

                        {/* <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
                            <strong>{i18n.t("mylandingpages.support.terms")}</strong>
                        </div>
                        <div className="dx-fieldset" id="notes-container">
                            <div className="dx-field">
                                <TextArea
                                    className="redHeight2 dx-field-value"
                                    height={300}
                                    width={"100%"}
                                    onValueChanged={(event) =>
                                        this.handleValueChange(event, "description")
                                    }
                                    value={this.state.description}
                                    readOnly={this.state._id ? false : true}
                                />
                            </div>
                        </div> */}
                        <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
                            <strong>{i18n.t("mylandingpages.support.script")}</strong>
                        </div>
                        <div className="dx-fieldset" id="notes-container">
                            <div className="dx-field">
                                <TextArea
                                    className="redHeight2 dx-field-value"
                                    placeholder={i18n.t("mylandingpages.support.scriptPlace")}
                                    height={300}
                                    width={"100%"}
                                    onValueChanged={(event) =>
                                        this.handleValueChange(event, "scripts")
                                    }
                                    value={this.state.scripts}
                                    readOnly={this.state._id ? false : true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// setSliderValue({ value }) {
//     this.setState({ sliderValue: value });
// }

// valueChanged(e) {
//     console.log('e.value', e.value);
//     this.setState({
//         status: e.value
//     });
// }
// }

// function format(value) {
//     return `${value}%`;
// }

function mapStateToProps(state) {
    // mapStateToProps returns an object, with:
    // property is the local prop name to this component
    // value will be the property in the root reducer... ie, piece of the store
    return {
        viewLandingPageMenuReducer: state.viewLandingPageMenuReducer,
        modalControl: state.modalControl,
    };
}

function mapDispatchToProps(dispatch) {
    // this function return bindActionCreators
    // and we hand bindActionCreators an object
    // each property will be a local prop
    // each value will be a function that is dispatch when run
    // second arg of bindActionCreators is the dispatch
    return bindActionCreators(
        {
            updateViewLandingPageMenu: updateViewLandingPageMenu,
            updateModal: updateModal,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewLandingPagesMenu);
