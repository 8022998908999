import React, { Component } from "react";
import creditCard from "../../images/creditcard.gif";
import logo_pix from "../../images/logo_pix.png";
import { Button } from "devextreme-react";
import { locale, loadMessages } from "devextreme/localization";
import notify from "devextreme/ui/notify";
//Base
import { LoadPanel } from "devextreme-react/load-panel";
import { getAccount, updateAccount, readSenderEmails, deleteSenderEmails, insertSenderEmail } from "../../apis/toowezeApi";
import { TextBox } from "devextreme-react";
import DateBox from "devextreme-react/date-box";
import { Slider } from "devextreme-react/slider";
import { SelectBox } from "devextreme-react";
import saveImagemGallery, {
  getAllBranchs,
  proceedSaveBranch,
  companyIdentifierValidation,
  buscaCEP,
  getAllPlans,
  updateUser,
  companyRootIDIdentifierValidation,
} from "../../apis/toowezeApi";
import getCities from "../../apis/citiesApi";
import states from "../data/states";
import {
  Validator,
  RequiredRule,
  CompareRule,
  EmailRule,
  PatternRule,
  StringLengthRule,
  RangeRule,
  AsyncRule,
} from "devextreme-react/validator";
import DataGrid, {
  Column,
  Pager,
  Paging,
  HeaderFilter,
  SearchPanel,
  Lookup,
  Editing,
} from "devextreme-react/data-grid";
import { i18n, lgMessages, languageCode } from "../../translate/i18n";
import { generatepaperpayment, getBalance } from "../../apis/payment";
import data_ from "./data";
import Spinner from "../LayOut/Spinner";
const I18N_STORAGE_KEY = "i18nextLng";
const thelanguage = localStorage.getItem(I18N_STORAGE_KEY);
const moment = require("moment-timezone");

const position = { of: "#addressCard" };
const positionAll = { of: "#branchCard" };
export default class AccountMiddle extends Component {
  constructor(props) {
    super(props);
    // Configura o idioma
    loadMessages(lgMessages);
    locale(languageCode);

    this.state = {
      identifierType: 2,
      subGroupId: null,
      branchs: "",
      loadPanelVisible: false,
      allPanelVisible: false,
      cities: [],
      companyIdentifierCheck: true,
      goValidateIdentifier: false,
      goValidateEmail: false,
      emailCheck: false,
      cardType: "profile",
      barCode: "active_",
      card: "",
      renew: true,
      companyIdentifierReadOnly: false,
      planPrice: 0,
      disbaleButton: false,
      selectedPlan: [],
      senderEmails: []
      // readSenderEmails: []
    };

  }

  componentDidMount() {

    let urlParams = new URLSearchParams(window.location.search);
    let setCard = urlParams.get("card");

    if (setCard) {
      this.setState({
        cardType: setCard
      })
    }

    if (setCard === "billing") {
      this.getPlans()
    }

    getAccount(localStorage.getItem("tooweze_token"))
      .then((res) => {
        const userAccount = JSON.parse(localStorage.getItem("auth"));

        this.setState({
          id: res._id,
          email: res.email,
          isConfigured: res.isConfigured,
          planId: res.planId,
          planName: res.planName,
          planPrice: res.price,
          // planPrice
          currecy: res.currecy,
          userId: userAccount._id,
          userName: userAccount.name,
          profilePic: userAccount.profilePic,
          campaigns: res.campaigns,
          emailSendingQuota: res.emailSendingQuota,
          contactsLimit: res.contactsLimit,
          aditionalBranchs: res.aditionalBranchs,
          validFrom: res.validFrom,
          validTo: res.validTo,
          status: res.status,
          rootId: res.rootId,
          companyName: res.companyName,
          companyName2: res.companyName2,
          identifierType: res.identifierType,
          companyIdentifier: res.companyIdentifier,
          companyIdentifierB: res.companyIdentifier,
          datagrid: [],
          // Endereço da Empresa
          addressLine1: res.addressLine1,
          addressLine2: res.addressLine2,
          number: res.number,
          numberB: res.number,
          cityRegion: res.cityRegion,
          zipCode: res.zipCode,
          state: res.state,
          city: res.city,

          // Replica o endereço da empresa, na cobrança para agilizar
          addressLine1B: res.addressLine1,
          addressLine2B: res.addressLine2,
          cityRegionB: res.cityRegion,
          zipCodeB: res.zipCode,
          // stateB: res.state,
          cityB: res.city,

          usedCampaigns: res.usedCampaigns,
          usedEmailSendingQuota: res.usedEmailSendingQuota,
          usedContactsLimit: res.usedContactsLimit,
          usedAditionalBranchs: res.usedAditionalBranchs,
          createdBy: res.createdBy,
          createdAt: res.createdAt,
          companyIdentifierReadOnly: res.companyIdentifier ? true : false,
          pixReady: false,
        });

        if (res.zipCode) {
          // Reler
          buscaCEP(res.zipCode)
            .then((cep) => {
              if (cep.data.uf) {
                getCities(cep.data.uf)
                  .then((res) => {
                    if (res.data) {
                      // if (fieldId === "zipCode") {
                      this.setState({
                        cities: res.data,
                        loadPanelVisible: false,
                        addressLine1: cep.data.logradouro,
                        // addressLine2: cep.data.complemento,
                        cityRegion: cep.data.bairro,
                        state: cep.data.uf,
                        stateB: cep.data.uf,
                        city: cep.data.localidade,
                        backgroundColor: "#ffdede",
                      });
                      // } else if (fieldId === "zipCodeB") {
                      // this.setState({
                      //   citiesB: res.data,
                      //   loadPanelVisible: false,
                      //   addressLine1B: cep.data.logradouro,
                      //   addressLine2B: cep.data.complemento,
                      //   cityRegionB: cep.data.bairro,
                      //   stateB: cep.data.uf,
                      //   cityB: cep.data.localidade,
                      //   backgroundColorB: "#ffdede",
                      // });
                      // }
                    }
                  })
                  .catch((error) => {
                    console.log("Cities Error", error);
                  });
              }
            })
            .catch((error) => {
              this.setState({
                loadPanelVisible: false,
              });
            });
        }

      })
      .catch((error) => {
        console.log("error", error);
      });

    this.proceedReadSenderEmails();

  }



  // EVENTOS RELATIVOS AO EMAIL REMETENTE
  // Evento que ocorre quando uma linha é adicionada na tabela.
  onRowInserted = (event) => {

    insertSenderEmail(event.data.email).then(res => {
      if (res) {
        this.proceedReadSenderEmails();
      }
    }).catch(error => {
      // Already Registered to Another Account.
      if (error.message === "Request failed with status code 409") {
        this.showNotify(i18n.t("account.message.senderemailalreadyregistered"), "error");
      } else {
        this.showNotify(error.message, "error");
      }

      this.proceedReadSenderEmails();

    });
  };


  // Evento que ocorre quando uma linha é deletada da tabela.
  onRowRemoved = (event) => {
    if ((this.state.emailsCount - 1) === 0) {
      this.showNotify(i18n.t("account.message.cantdeleteemail"), "error");
    } else {
      // Função para deletar.
      deleteSenderEmails(event.data._id).then(res => {
        this.proceedReadSenderEmails();
      }).catch(err => {
        console.log("Erro deletando", err)
      });
    }

  };
  // EVENTOS RELATIVOS AO EMAIL REMETENTE

  onKeyUp(event, fieldId) {
    event.event.preventDefault();

    let finalValue = event.event.currentTarget.value;

    if (fieldId === "zipCode" || fieldId === "zipCodeB") {
      this.setState({ goValidateEmail: false });
      this.setState({ goValidateIdentifier: false });

      /// CEP
      var v = finalValue.replace(/^([\d]{5})\.*([\d]{3})/, "$1-$2");
      finalValue = v;

      this.setState({
        [fieldId]: finalValue,
      });

      if (finalValue.length === 9) {
        this.setState({
          loadPanelVisible: true,
        });

        buscaCEP(finalValue)
          .then((cep) => {
            if (cep.data.uf) {
              getCities(cep.data.uf)
                .then((res) => {
                  if (res.data) {
                    if (fieldId === "zipCode") {
                      this.setState({
                        cities: res.data,
                        loadPanelVisible: false,
                        addressLine1: cep.data.logradouro,
                        // addressLine2: cep.data.complemento,
                        cityRegion: cep.data.bairro,
                        state: cep.data.uf,
                        city: cep.data.localidade,
                        backgroundColor: "#ffdede",
                      });
                    } else if (fieldId === "zipCodeB") {
                      this.setState({
                        citiesB: res.data,
                        loadPanelVisible: false,
                        addressLine1B: cep.data.logradouro,
                        // addressLine2B: cep.data.complemento,
                        cityRegionB: cep.data.bairro,
                        stateB: cep.data.uf,
                        cityB: cep.data.localidade,
                        backgroundColorB: "#ffdede",
                      });
                    }
                  }
                })
                .catch((error) => {
                  console.log("Cities Error", error);
                });
            }
          })
          .catch((error) => {
            this.setState({
              loadPanelVisible: false,
            });
          });
      }
    } else if (fieldId === "companyIdentifier") {
      if (finalValue) {
        if (finalValue.length === 14) {
          /// Pessoa Jurídica
          var v = finalValue.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          );
          finalValue = v;
        } else if (finalValue.length === 11) {
          /// Pessoa Física
          var v = finalValue.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/g,
            "$1.$2.$3-$4"
          );
          finalValue = v;
        }

        this.setState({
          companyIdentifier: finalValue,
        });

        if (this.state.companyIdentifier) {
          if (
            this.state.companyIdentifier.length == 13 ||
            this.state.companyIdentifier.length == 17
          ) {
            this.setState({ goValidateIdentifier: true });
          } else {
            this.setState({ goValidateIdentifier: false });
          }
        }
      }
    } else if (
      fieldId === "phone" ||
      fieldId === "whatsup" ||
      fieldId === "mobil"
    ) {
      // Telefone
      var v = finalValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
      v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
      finalValue = v;
      this.setState({
        [fieldId]: finalValue ? finalValue : null,
      });
    } else if (fieldId === "email") {
      let email = event.event.currentTarget.value;

      if (email) {
        if (email.includes("@")) {
          this.setState({
            email: email,
            goValidateEmail: true,
          });
        }
      }
    } else if (fieldId === "creditCard") {
      var v = finalValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/g, "$1 $2 $3 $4");
      finalValue = v;
      this.setState({
        [fieldId]: finalValue ? finalValue : null,
      });
    } else if (fieldId === "validate") {
      var v = finalValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/(\d{2})(\d{2})/g, "$1/$2");
      finalValue = v;
      this.setState({
        [fieldId]: finalValue ? finalValue : null,
      });
    }
  }

  // Verifica se o email já está cadastrado
  asyncCompanyIdentifierEmailValidation = (params) => {
    if (this.state.goValidateEmail == true) {
      this.setState({ goValidateEmail: false });
      return companyIdentifierValidation(
        "email",
        params.value,
        "",
        this.state.branchId
      ).then((res) => {
        this.setState({ emailCheck: !res });
        return !res; // Não tem repetido = true, é valido? !res
      });
    } else {
      return new Promise((resolve, reject) => resolve(this.state.emailCheck));
    }
  };

  // Insere a máscara quando clica no textbox
  insertMask(textBoxValue, fieldId) {
    if (textBoxValue) {
      textBoxValue = textBoxValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
      if (fieldId === "zipCode") {
        /// CEP
        return textBoxValue.replace(/^([\d]{5})\.*([\d]{3})/, "$1-$2");
      } else if (
        fieldId === "companyIdentifier" ||
        fieldId === "companyIdentifierB"
      ) {
        if (textBoxValue.length === 14) {
          /// Pessoa Jurídica
          return textBoxValue.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          );
        } else if (textBoxValue.length === 11) {
          /// Pessoa Física
          return textBoxValue.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/g,
            "$1.$2.$3-$4"
          );
        }
      } else if (
        fieldId === "phone" ||
        fieldId === "whatsup" ||
        fieldId === "mobil"
      ) {
        // Telefone
        textBoxValue = textBoxValue.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        return textBoxValue.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
      }
    }
  }

  // Remove a máscara
  removeMask(textBoxValue) {
    if (textBoxValue) {
      return textBoxValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
    }
  }

  proceedReadSenderEmails = () => {
    readSenderEmails().then(res => {
      this.setState({
        senderEmails: res,
        emailsCount: res.length
      });
    }).catch(err => {
      console.log("readSenderEmails Error", err)
    });
  }

  // Administra as mudanças de valores dos TextBox
  handleValueChange = (event, fieldId) => {
    let selectedPlan;
    if (fieldId === "planId") {
      selectedPlan = this.state.planData.filter((plan) => plan._id == event.value);
      this.setState({
        planPrice: selectedPlan[0].price,
        planName: selectedPlan[0].planName,
        planId: event.value,
        selectedPlan: selectedPlan
        // plans: event.value,
      });
    }



    if (fieldId) {
      this.setState({
        [fieldId]: event.value ? event.value : null,
      });
    }

    if (fieldId === "state") {
      getCities(event.value)
        .then((res) => {
          this.setState({
            cities: res.data,
          });
        })
        .catch((error) => {
          console.log("Cities Error", error);
        });
    } else if (fieldId == "email") {
      this.setState({ goValidateEmail: true });
    } else if (fieldId == "identifierType") {
      let companyIdentifier = this.state.companyIdentifier;
      this.setState({
        companyIdentifier: 0,
        goValidateIdentifier: false,
      });

      this.setState({
        companyIdentifier: companyIdentifier,
        goValidateIdentifier: true,
      });
    } else if (fieldId == "renew") {
      this.setState({
        [fieldId]: event.target.checked,
      });
    }
  };

  // Função de quando clica os cards relativos a escolha do tipo de campanha.
  changePaymentType = (event) => {
    if (event.currentTarget.id === "card") {
      this.setState({
        card: "active_",
        barCode: "",
      });
    } else if (event.currentTarget.id === "barCode") {
      this.setState({
        card: "",
        barCode: "active_",
      });
    }
  };

  getBalance = () => {
    getBalance()
      .then((res) => {
        this.setState({
          datagrid: res,
          cardType: "payments",
        });
      })
      .catch((err) => {
        this.setState({
          cardType: "payments",
        });
        console.log("err balance", err);
      });
  };
  // Criar o boleto PIX
  createPaperPayment = (event) => {
    this.setState({
      disbaleButton: true,
    });

    generatepaperpayment(this.state)
      .then((res) => {
        this.setState({
          cardType: "pix",
          reference: res.reference,
          dueDate: res.dueDate,
          pixAmount: res.amount,
          pixStatus: res.status,
          id: res.code,
          payloadInBase64: Buffer.from(
            res.pix.payloadInBase64,
            "base64"
          ).toString("utf-8"),
          imageInBase64: res.pix.imageInBase64,
        });

        setTimeout(() => {
          document.getElementsByClassName("middleBody")[0].scrollTop = 0;
          this.setState({
            pixReady: true,
            disbaleButton: false,
          });
        }, 1000);
      })
      .catch((error) => {

        // if (error.response) {
        //   // Request made and server responded
        //   console.log(error.response.data[0].message);
        //   console.log(error.response.status);
        //   console.log(error.response.headers);
        // } else if (error.request) {
        //   // The request was made but no response was received
        //   console.log(error.request);
        // } else {
        //   // Something happened in setting up the request that triggered an Error
        //   console.log('Error', error.message);
        // };

        let message = error?.response?.data[0]?.message;

        if (message && message.includes("billing.document")) {
          message = message.replace("billing.document", i18n.t("account.labelcompanytype"))
        }

        this.showNotify(message ? message : error, "error");
        this.setState({
          pixReady: false,
          disbaleButton: false,
        });
      });
  };

  resendEmail = (event) => {
    insertSenderEmail(event.data.email).then(res => {
      // mensagem
      if (res) {
        this.showNotify(i18n.t("account.message.resendemail"), "success");
      }
    }).catch(error => {
      // mensagem
      this.showNotify(error, "error");
    });
  }


  // Atualiza os dados da conta
  updateAccount = () => {
    if (
      this.state.companyIdentifier != "" &&
      this.state.companyIdentifier != undefined &&
      this.state.identifierType != 1 &&
      this.state.identifierType != 2
    ) {
      this.showNotify(i18n.t("account.message.identifieralert"), "error");
    } else {
      this.showNotify("OK", "success");

      if (
        (this.state.companyIdentifier === "" ||
          this.state.companyIdentifier === undefined) &&
        (this.state.identifierType === 1 || this.state.identifierType === 2)
      ) {
        this.setState({
          identifierType: undefined,
        });
      }

      updateAccount(this.state)
        .then((res) => {
          /// Update Image Profile
          if (this.state.image) {
            updateUser({ id: this.state.userId, profilePic: this.state.image })
              .then((res) => { })
              .catch((error) => {
                this.showNotify(error, "error");
              });
          }
        })
        .catch((error) => {
          this.showNotify(error, "error");
        });
    }
  };

  // Transforma a imagem em Blob e grava no State
  handleUploadChange = (event) => {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.setState({
          image: reader.result,
        });
      };
    }
  };

  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "top", offset: "0 200" },
        closeOnClick: true,
      },
      type,
      3000
    );
  }

  getPlans() {
    getAllPlans()
      .then((res) => {
        if (res) {
          this.setState({
            planData: res,
          });
        }
      })
      .catch((error) => {
        this.showNotify(error, "error");
      });
  }

  calculateDate(data) {
    return data.createdAt ? new Date(data.createdAt * 1000) : "";
  }

  setCurrecy(data) {
    return data.data.amount
      ? i18n.t("account.grid.currency") +
      " " +
      parseFloat(data.data.amount).toFixed(2)
      : "";
  }

  statuses = [
    {
      id: "PAID",
      name: i18n.t("account.grid.paid"),
    },
    {
      id: "Credit",
      name: i18n.t("account.grid.credit"),
    },
    {
      id: "Debit",
      name: i18n.t("account.grid.debit"),
    },
  ];

  statusesSender = [
    {
      id: "ACTIVE",
      name: i18n.t("account.grid.paid"),
    },
    {
      id: "Credit",
      name: i18n.t("account.grid.credit"),
    },
    {
      id: "Debit",
      name: i18n.t("account.grid.debit"),
    },
  ];

  statusEditorRender(cell) {
    let onValueChanged = this.onValueChanged.bind(this, cell);
    return (
      <SelectBox
        defaultValue={cell.value}
        {...cell.column.lookup}
        onValueChanged={onValueChanged}
        itemRender={this.itemRender}
      />
    );
  }


  resend = (data) => {
    if (data.data.status === "Pending") {
      return (
        <><Button
          text={i18n.t("account.grid.columnlabelresend")}
          width={60}
          height={20}
          type="success"
          style={{ fontSize: "10px" }}
          onClick={() => this.resendEmail(data)}
        /></>
      )
    }
  }

  emailStatus = (data) => {
    if (data.data.status === "Pending") {
      return (<label>{i18n.t("account.grid.inactiveStatus")}</label>)
    } else if (data.data.status === "Active") {
      return (<label>{i18n.t("account.grid.activeStatus")}</label>)
    }
  }



  // Verifica se existe CPF ou CNPJ já Cadastrados
  companyRootIDIdentifierValidation = (params) => {
    if (this.state.goValidateIdentifier == true) {
      this.setState({ goValidateIdentifier: false });
      if (params.value.length > 10) {
        return companyRootIDIdentifierValidation(
          "companyIdentifier",
          params.value,
          this.state.identifierType,
          this.state.id
        ).then((res) => {
          this.setState({ companyIdentifierCheck: !res });
          return !res; // Não tem repetido = true, é valido? !res
        });
      } else {
        return new Promise((resolve, reject) =>
          resolve(this.state.companyIdentifierCheck)
        );
      }
    } else {
      return new Promise((resolve, reject) =>
        resolve(this.state.companyIdentifierCheck)
      );
    }
  };

  render() {
    let cardType = "";
    let creditCardFields = "";
    let autoCardRenew = "";

    if (this.state.cardType === "profile") {
      cardType = (
        <>
          <div className="col-12">
            <div className="row" style={{ height: "68px" }}>
              <div className="col-6">
                <div className="circular--portrait">
                  <img
                    src={
                      this.state.image && this.state.image != ""
                        ? this.state.image
                        : this.state.profilePic
                    }
                  />
                </div>
              </div>

              <div className="col-6 pr-0 pl-0">
                <div className="d-flex flex-row-reverse">
                  <div className="input-group" style={{ marginTop: "13px" }}>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="inputGroupFile04"
                        onChange={this.handleUploadChange}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="inputGroupFile04"
                      >
                        {i18n.t("account.profilepic")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body" style={{ paddingBottom: "5px" }}>
              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labelname")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    value={this.state.userName}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labelemail")}
                </div>
                <div className="dx-field-value">
                  <TextBox value={this.state.email} readOnly={true} />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labeltype")}
                </div>
                <div className="dx-field-value">
                  <SelectBox
                    readOnly={this.state.companyIdentifierReadOnly}
                    items={[
                      {
                        identifierType: 1,
                        identifierName: i18n.t("account.labelperson"),
                      },
                      {
                        identifierType: 2,
                        identifierName: i18n.t("account.labelcorporate"),
                      },
                    ]}
                    stylingMode="filled"
                    displayExpr="identifierName"
                    valueExpr="identifierType"
                    defaultValue={this.state.identifierType}
                    value={this.state.identifierType}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "identifierType")
                    }
                    width="100%"
                    placeholder={i18n.t("account.placeholderidentifiertype")}
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labelidentifiertype")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    placeholder=""
                    value={this.state.companyIdentifier}
                    readOnly={this.state.companyIdentifierReadOnly}
                    onKeyUp={(event) =>
                      this.onKeyUp(event, "companyIdentifier")
                    }
                    onFocusOut={() =>
                      this.setState({
                        companyIdentifier: this.insertMask(
                          this.state.companyIdentifier,
                          "companyIdentifier"
                        ),
                      })
                    }
                  >
                    <Validator>
                      <AsyncRule
                        message={i18n.t(
                          "account.message.identifieralreadyregistered"
                        )}
                        ignoreEmptyValue={true}
                        validationCallback={(event) =>
                          this.companyRootIDIdentifierValidation(event)
                        }
                      />
                    </Validator>
                  </TextBox>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labelcompanyname")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    showClearButton={true}
                    placeholder=""
                    value={this.state.companyName}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "companyName")
                    }
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labelcompanynameas")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    showClearButton={true}
                    placeholder=""
                    value={this.state.companyName2}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "companyName2")
                    }
                  ></TextBox>
                </div>
              </div>
            </div>
          </div>

          <hr style={{ margin: "8px" }} />

          <div id="addressCard" className="card">
            <div
              className="card-body second-group"
              style={{ paddingBottom: "5px" }}
            >
              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labelzipcode")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    placeholder=""
                    maxLength={9}
                    value={this.state.zipCode}
                    onKeyUp={(event) => this.onKeyUp(event, "zipCode")}
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labelcityregion")}
                </div>
                <div className="dx-field-value">
                  <div className="row">
                    <div className="col-4">
                      <SelectBox
                        dataSource={states}
                        displayExpr="sigla"
                        valueExpr="sigla"
                        stylingMode="filled"
                        defaultValue=""
                        width="100%"
                        placeholder={i18n.t("account.labelregion")}
                        value={this.state.state}
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "state")
                        }
                      />
                    </div>
                    <div className="col-8">
                      <SelectBox
                        dataSource={this.state.cities}
                        displayExpr="nome"
                        valueExpr="nome"
                        stylingMode="filled"
                        defaultValue=""
                        width="100%"
                        placeholder={i18n.t("account.labelcity")}
                        value={this.state.city}
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "city")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labeladdressline1")}
                </div>
                <div className="dx-field-value">
                  <div className="row">
                    <div className="col-8" style={{ paddingRight: "3px" }}>
                      <TextBox
                        showClearButton={true}
                        placeholder={i18n.t("account.placeholderaddressline1")}
                        value={this.state.addressLine1}
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "addressLine1")
                        }
                      />
                    </div>
                    <div className="col-4" style={{ paddingLeft: "4px" }}>
                      <TextBox
                        showClearButton={true}
                        placeholder={i18n.t("account.placeholdernumber")}
                        value={this.state.number}
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "number")
                        }
                        style={{
                          backgroundColor: this.state.backgroundColor
                            ? this.state.backgroundColor
                            : "",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labeladdressline2")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    showClearButton={true}
                    placeholder={i18n.t("account.placeholderaddressline2")}
                    value={this.state.addressLine2}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "addressLine2")
                    }
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labelneighborhood")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    showClearButton={true}
                    placeholder={i18n.t("account.placeholderneighborhood")}
                    value={this.state.cityRegion}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "cityRegion")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12"
            style={{ paddingTop: "4px", paddingRight: "0px" }}
          >
            <Button
              text={i18n.t("account.btnupdate")}
              width={150}
              height={30}
              type="success"
              style={{ float: "right" }}
              onClick={this.updateAccount}
            />
          </div>
        </>
      );
    } else if (this.state.cardType === "billing") {
      if (this.state.card === "active_") {
        autoCardRenew = (
          <div className="">
            <div
              className="dx-field-label"
              style={{
                width: "60%",
                fontWeight: "bold",
                color: this.state.renew == false ? "grey" : "green",
                fontSize: "12px",
              }}
            >
              {i18n.t("account.allowrenew")}
            </div>
            <div className="dx-field-value" style={{ width: "40%" }}>
              <div
                style={{
                  display: "block",
                  height: "25px",
                  margin: "0px auto",
                  width: "260px",
                }}
              >
                <div
                  className="custom-control custom-switch"
                  style={{ float: "left", paddingTop: "3px" }}
                >
                  <input
                    id="ckb_renew"
                    type="checkbox"
                    className="custom-control-input"
                    checked={this.state.renew ? this.state.renew : false}
                    onChange={(event) => {
                      this.handleValueChange(event, "renew");
                    }}
                  />
                  <label
                    title={
                      this.state.renew == false
                        ? i18n.t("account.activate")
                        : i18n.t("account.deactivate")
                    }
                    className="custom-control-label red"
                    style={{ fontSize: "14px", paddingTop: "3px" }}
                    htmlFor="ckb_renew"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        );

        creditCardFields = (
          <div id="infoCard" className="card">
            <div
              className="card-body second-group"
              style={{ paddingBottom: "5px" }}
            >
              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labelcardnumber")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    value={this.state.creditCard}
                    onKeyUp={(event) => this.onKeyUp(event, "creditCard")}
                    maxLength={19}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5"></div>
                <div className="col-7">
                  <div className="row">
                    <div className="col-6">
                      <div className="dx-field" style={{ width: "180px" }}>
                        <div className="dx-field-label">
                          {i18n.t("account.labelvalidate")}
                        </div>
                        <div className="dx-field-value">
                          <TextBox
                            value=""
                            width="60px"
                            value={this.state.validate}
                            onKeyUp={(event) => this.onKeyUp(event, "validate")}
                            maxLength={5}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="dx-field">
                        <div className="dx-field-label">
                          {i18n.t("account.cvc")}
                        </div>
                        <div className="dx-field-value">
                          <TextBox
                            width="50px"
                            maxLength={3}
                            value={this.state.cvc}
                            onValueChanged={(event) =>
                              this.handleValueChange(event, "cvc")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        creditCardFields = (
          <div className="row">
            <div className="col">
              <p className="mt-2">
                <label className="badge badge-warning">
                  {i18n.t("account.message.generic")}
                </label>
              </p>
            </div>
            <div className="col">
              <p className="mt-2" style={{ float: "right" }}>
                <label className="badge badge-danger">
                  {i18n.t("account.monthlypayment")}{" "}
                  {parseFloat(this.state.planPrice).toFixed(2)}
                </label>
              </p>
            </div>
          </div>
        );
        autoCardRenew = "";
      }

      cardType = (
        <div style={{ marginTop: "-10px" }}>
          <div className="col-12 pl-0 pr-0">
            <div
              className="container row"
              style={{ padding: "0px", margin: "0 auto", height: "113px" }}
            >
              {/* {thelanguage === "pt" ? ( */}
              <div
                className="col-4"
                style={{
                  paddingLeft: "1px",
                  paddingRight: "1px",
                  height: "100px",
                }}
              >
                <div
                  id="barCode"
                  className={"card " + this.state.barCode}
                  style={{ textAlign: "center", height: "130px" }}
                  onClick={(event) => this.changePaymentType(event)}
                >
                  <div className="card-body">
                    <svg height={74} viewBox="0 0 512 512" width={74}>
                      <path d="M0 51.919v408.162h512V51.919zm482 378.162H30V81.919h452z" />
                      <path d="M61 111.919h30v288.162H61zM121 111.919h30v228.162h-30zM181 111.919h30v228.162h-30zM241 111.919h30v228.162h-30zM301 111.919h30v288.162h-30zM361 111.919h30v228.162h-30zM421 111.919h30v228.162h-30zM121 370.081h30v30h-30zM181 370.081h30v30h-30zM241 370.081h30v30h-30zM361 370.081h30v30h-30zM421 370.081h30v30h-30z" />
                    </svg>
                    {/* <!-- SVG ICON EMAIL MARKETING --> */}
                  </div>
                  <label>{i18n.t("account.labelpaperpayment")}</label>
                </div>
              </div>
              {/* // ) : this.state.card === "" ? ( */}
              {/* //   this.setState({ */}
              {/* //     card: "active_", */}
              {/* //     barCode: "", */}
              {/* //   }) */}
              {/* // ) : ( */}
              {/* //   "" */}
              {/* // )} */}

              {/* Opçao de Cartáo de crédito */}
              {/* <div
                className="col-4"
                style={{
                  paddingLeft: "1px",
                  paddingRight: "1px",
                  height: "100px",
                }}
              >
                <div
                  id="card"
                  className={"card " + this.state.card}
                  style={{
                    marginLeft: "0px",
                    textAlign: "center",
                    height: "130px",
                  }}
                  onClick={(event) => this.changePaymentType(event)}
                >
                  <div className="card-body">
                    <svg viewBox="0 0 512.001 512.001" height={74} width={74}>
                      <path
                        d="M481.53 347.033l30.015-191.649c3.121-19.448-10.112-37.747-29.561-40.877L76.973 49.501c-19.448-3.121-37.746 10.112-40.867 29.56l-14.392 89.627v177.23l459.816 1.115z"
                        fill="#146aa8"
                      />
                      <path
                        d="M481.985 114.507L366.953 96.049C289.694 214.264 164.799 296.142 69.019 346.034l412.511.999 30.015-191.649c3.121-19.449-10.112-37.747-29.56-40.877z"
                        fill="#126099"
                      />
                      <path
                        d="M35.669 159.771h410.192c19.698 0 35.669 15.971 35.669 35.669v231.848c0 19.698-15.971 35.669-35.669 35.669H35.669C15.971 462.956 0 446.986 0 427.288V195.44c0-19.698 15.971-35.669 35.669-35.669z"
                        fill="#4398d1"
                      />
                      <path
                        d="M445.861 159.771h-7.134c-84.179 149.444-241.469 251.956-337.49 303.185h344.624c19.698 0 35.669-15.971 35.669-35.669V195.44c0-19.698-15.971-35.669-35.669-35.669z"
                        fill="#3e8cc7"
                      />
                      <path
                        fill="#fdb62f"
                        d="M44.586 204.357h89.172v71.338H44.586z"
                      />
                      <g fill="#fd7b2f">
                        <path d="M44.586 231.109h26.752v17.834H44.586zM107.007 231.109h26.752v17.834h-26.752z" />
                      </g>
                      <circle
                        cx={392.358}
                        cy={373.784}
                        r={44.586}
                        fill="#fdb62f"
                      />
                      <circle
                        cx={338.854}
                        cy={373.784}
                        r={44.586}
                        fill="#de4c3c"
                      />
                      <g fill="#87ced9">
                        <path d="M35.669 338.115h53.503v17.834H35.669zM124.841 338.115h53.503v17.834h-53.503zM214.013 338.115h53.503v17.834h-53.503zM35.669 391.619h231.848v17.834H35.669zM312.103 204.357h17.834v35.669h-17.834zM347.772 204.357h17.834v35.669h-17.834zM383.44 204.357h17.834v35.669H383.44zM419.109 204.357h17.834v35.669h-17.834z" />
                      </g>
                    </svg>
                  </div>
                  <label>{i18n.t("account.labelcreditcards")}</label>
                </div>
              </div> */}

              {/* PLANOS  */}
              {/* <div
                className="col-4"
                style={{
                  paddingLeft: "1px",
                  paddingRight: "1px",
                  height: "100px",
                }}
              >
                <div
                  id="card"
                  className={"card "}
                  style={{
                    marginLeft: "0px",
                    textAlign: "center",
                    height: "130px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <div
                    className="card-body"
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <label>{i18n.t("account.labelplans")}</label>
                    <SelectBox
                      dataSource={this.state.planData}
                      displayExpr="planName"
                      valueExpr="_id"
                      defaultValue=""
                      width="100%"
                      placeholder=""
                      value={this.state.plans}
                      onValueChanged={(event) =>
                        this.handleValueChange(event, "plans")
                      }
                    />
                  </div>
                  <label style={{ marginBottom: "9px" }}>
                    {i18n.t("account.monthlypayment")}
                    {parseFloat(this.state.planPrice).toFixed(2)}
                  </label>
                </div>
              </div> */}
            </div>
          </div>

          <br />
          <div className="card" style={{ minHeight: "205px" }}>
            <div className="card-body" style={{ paddingBottom: "5px" }}>
              <div className="dx-field">
                {/* <div className="col"> */}
                <div className="dx-field-label">
                  {i18n.t("account.monthlypayment")}
                </div>
                {/* </div> */}
                {/* <div className="col"> */}
                <div className="dx-field-value">
                  {/* <label>{i18n.t("account.labelplans")}</label> */}
                  <SelectBox
                    dataSource={this.state.planData}
                    displayExpr="planName"
                    valueExpr="_id"
                    defaultValue=""
                    width="100%"
                    placeholder=""
                    value={this.state.planId}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "planId")
                    }
                  />
                </div>
                {/* </div> */}
                {/* <div className="col">
                  <div className="dx-field-label">
                    <label>{parseFloat(this.state.planPrice).toFixed(2)}</label>
                  </div>
                </div> */}
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {this.state.barCode === "active_"
                    ? i18n.t("account.labelcompanyname")
                    : i18n.t("account.labelcardname")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    value={this.state.userName}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "userName")
                    }
                  ></TextBox>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labelcompanytype")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    placeholder=""
                    value={this.state.companyIdentifierB}
                    maxLength={18}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "companyIdentifierB")
                    }
                    onFocusOut={() =>
                      this.setState({
                        companyIdentifier: this.insertMask(
                          this.state.companyIdentifierB,
                          "companyIdentifierB"
                        ),
                      })
                    }
                  ></TextBox>
                </div>
              </div>

              {/* Campos Boleto Pix */}
              {creditCardFields}

              {this.state.selectedPlan[0]?.contactsLimit ? <div>
                <div className="row">
                  <div className="col">
                    <span id="totalContacts" className="badge badge-info">
                      {i18n.t("account.support.labelcontactslimit") + " "}
                      {this.state.selectedPlan[0]?.contactsLimit}
                    </span>
                  </div>
                  <div className="col">
                    <span id="totalEmails" className="badge badge-info">
                      {i18n.t("account.support.labelemailsendlimit")}
                      {this.state.selectedPlan[0]?.emailSendingQuota}
                    </span>
                  </div>
                </div>
              </div> : ""}

            </div>
          </div>

          <div className="pt-1"></div>

          <div id="addressCard" className="card mb-1">
            <div
              className="card-body second-group"
              style={{ paddingBottom: "5px" }}
            >
              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labelzipcode")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    placeholder=""
                    maxLength={9}
                    value={this.state.zipCodeB}
                    onKeyUp={(event) => this.onKeyUp(event, "zipCodeB")}
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labelcityregion")}
                </div>
                <div className="dx-field-value">
                  <div className="row">
                    <div className="col-4">
                      <SelectBox
                        dataSource={states}
                        displayExpr="sigla"
                        valueExpr="sigla"
                        stylingMode="filled"
                        defaultValue=""
                        width="100%"
                        value={this.state.stateB}
                        placeholder={i18n.t("account.labelregion")}
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "stateB")
                        }
                      />
                    </div>
                    <div className="col-8">
                      <SelectBox
                        dataSource={this.state.cities}
                        displayExpr="nome"
                        valueExpr="nome"
                        stylingMode="filled"
                        defaultValue=""
                        width="100%"
                        placeholder={i18n.t("account.labelcity")}
                        value={this.state.cityB}
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "cityB")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labeladdressline1")}
                </div>
                <div className="dx-field-value">
                  <div className="row">
                    <div className="col-8" style={{ paddingRight: "3px" }}>
                      <TextBox
                        showClearButton={true}
                        placeholder={i18n.t("account.placeholderaddressline1")}
                        value={this.state.addressLine1B}
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "addressLine1B")
                        }
                      />
                    </div>
                    <div className="col-4" style={{ paddingLeft: "4px" }}>
                      <TextBox
                        showClearButton={true}
                        placeholder={i18n.t("account.placeholdernumber")}
                        value={this.state.numberB}
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "numberB")
                        }
                        style={{
                          backgroundColor: this.state.backgroundColorB
                            ? this.state.backgroundColorB
                            : "",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labeladdressline2")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    showClearButton={true}
                    placeholder={i18n.t("account.placeholderaddressline2")}
                    value={this.state.addressLine2B}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "addressLine2B")
                    }
                  />
                </div>
              </div>

              <div className="dx-field">
                <div className="dx-field-label">
                  {i18n.t("account.labelneighborhood")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    showClearButton={true}
                    placeholder={i18n.t("account.placeholderneighborhood")}
                    value={this.state.cityRegionB}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "cityRegionB")
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Botão */}
          <div>
            <div className="row">
              <div className="col-8">{autoCardRenew}</div>
              <div className="col-4">
                <Button
                  text={
                    this.state.barCode === "active_"
                      ? i18n.t("account.labelcreatepaperpayment")
                      : i18n.t("account.labelmakepayment")
                  }
                  width={150}
                  height={30}
                  type="success"
                  disabled={this.state.disbaleButton}
                  style={{ float: "right" }}
                  onClick={(event) => this.createPaperPayment(event)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.cardType === "configurations") {

      //   [{
      //     "createdAt": 1596474166,
      //     "status": "Active",
      //     "_id": "61d4809202a779c5a016ea14",
      //     "email": "alan@tooweze.com",
      //     "rootId": "a4343b1a-c208-5110-b115-f519c0a5eb2e",
      //     "__v": 0
      // }]

      creditCardFields = (
        <div className="row">
          <DataGrid
            dataSource={this.state.senderEmails}
            showBorders={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            style={{ fontSize: "smaller", width: "100%" }}
            onRowRemoved={
              this.onRowRemoved
            }
            onRowInserted={this.onRowInserted}
          >
            <Editing mode="row"
              allowUpdating={false}
              allowAdding={true}
              allowDeleting={true} />
            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 15]}
              showInfo={true}
            />
            <HeaderFilter visible={true} />
            <SearchPanel
              visible={true}
              className="custom"
              width={240}
              placeholder={i18n.t("account.grid.searchplaceholder")}
            />
            <Column
              dataField="createdAt"
              width={80}
              caption={i18n.t("account.grid.columnlabelcreatedAt")}
              dataType="date"
              calculateCellValue={this.calculateDate}
              allowEditing={false}
            ></Column>

            <Column
              dataField="email"
              width={240}
              caption={i18n.t("account.grid.columnlabelemail")}
            />
            <Column
              dataField="status"
              caption={i18n.t("account.grid.columnstatus")}
              width={70}
              allowEditing={false}
              cellRender={this.emailStatus}
            >
            </Column>
            <Column
              dataField="_id"
              width={70}
              caption={i18n.t("account.grid.columnlabelresend")}
              cellRender={this.resend}
              allowEditing={false}
            />
          </DataGrid>
        </div>
      );

      cardType = (
        <div style={{ marginTop: "-10px" }}>
          <div className="col-12 pl-0 pr-0">
            <div
              className="container row"
              style={{ padding: "0px", margin: "0 auto", height: "113px" }}
            >
              <div
                className="col-4"
                style={{
                  paddingLeft: "1px",
                  paddingRight: "1px",
                  height: "100px",
                }}
              >
                <div
                  id="emailConfig"
                  className={"card " + this.state.barCode}
                  style={{ textAlign: "center", height: "130px" }}
                  onClick={(event) => this.changePaymentType(event)}
                >
                  <div className="card-body">
                    {/* <!-- SVG ICON EMAIL MARKETING --> */}

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsSvgjs="http://svgjs.com/svgjs"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="74"
                      height="74"
                      version="1.1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="74"
                        height="74"
                        transform="translate(11.995 11.995) scale(.9167)"
                        viewBox="0 0 48 48"
                      >
                        <path
                          fill="#ffbf66"
                          d="M1 17v23l16.3-14.49L1 40a4 4 0 004 4h38a4 4 0 004-4L30.7 25.51 47 40V17L24 29z"
                          className="color78b9eb svgShape"
                        ></path>
                        <path
                          fill="#ffbf66"
                          d="M14 12v4h3.294a6.929 6.929 0 00.548 1.33l-2.327 2.327 2.828 2.828 2.327-2.327a6.929 6.929 0 001.33.548V24h4v-3.294a6.929 6.929 0 001.33-.548l2.327 2.327 2.828-2.828-2.327-2.327a6.929 6.929 0 00.548-1.33H34v-4h-3.294a6.929 6.929 0 00-.548-1.33l2.327-2.327-2.828-2.828-2.327 2.327A6.929 6.929 0 0026 7.294V4h-4v3.294a6.929 6.929 0 00-1.33.548l-2.327-2.327-2.828 2.828 2.327 2.327a6.929 6.929 0 00-.548 1.33zm10-1a3 3 0 11-3 3 3 3 0 013-3z"
                          className="color78b9eb svgShape"
                        ></path>
                        <path
                          fill="#24212f"
                          d="M45 13h-7a1 1 0 000 2h7a1 1 0 011 1v.394L24 27.872 2 16.394V16a1 1 0 011-1h7a1 1 0 000-2H3a3 3 0 00-3 3v24a5.006 5.006 0 005 5h38a5.006 5.006 0 005-5V16a3 3 0 00-3-3zM2 18.65l13.556 7.072L2 37.773zM43 43H5a3 3 0 01-2.958-2.588l15.41-13.7 6.085 3.175a1.005 1.005 0 00.926 0l6.085-3.175 15.41 13.7A3 3 0 0143 43zm3-5.227L32.444 25.722 46 18.65z"
                          className="color1e81ce svgShape"
                        ></path>
                        <path
                          fill="#24212f"
                          d="M14 17h2.584l.051.123-1.827 1.827a1 1 0 000 1.414l2.828 2.828a1 1 0 001.414 0l1.827-1.827.123.051V24a1 1 0 001 1h4a1 1 0 001-1v-2.584l.123-.051 1.827 1.827a1.029 1.029 0 001.414 0l2.828-2.828a1 1 0 000-1.414l-1.827-1.827.051-.123H34a1 1 0 001-1v-4a1 1 0 00-1-1h-2.584l-.051-.123 1.827-1.827a1 1 0 000-1.414l-2.828-2.828a1 1 0 00-1.414 0l-1.827 1.827L27 6.584V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v2.584l-.123.051-1.827-1.827a1.029 1.029 0 00-1.414 0l-2.828 2.828a1 1 0 000 1.414l1.827 1.827-.051.123H14a1 1 0 00-1 1v4a1 1 0 001 1zm1-4h2.294a1 1 0 00.958-.714 5.967 5.967 0 01.469-1.139 1 1 0 00-.172-1.184l-1.62-1.62 1.414-1.414 1.62 1.62a1 1 0 001.183.172 5.987 5.987 0 011.139-.469A1 1 0 0023 7.294V5h2v2.294a1 1 0 00.715.958 5.987 5.987 0 011.139.469 1 1 0 001.183-.172l1.62-1.62 1.414 1.414-1.62 1.62a1 1 0 00-.172 1.184 5.967 5.967 0 01.469 1.139 1 1 0 00.958.714H33v2h-2.294a1 1 0 00-.958.714 5.967 5.967 0 01-.469 1.139 1 1 0 00.172 1.184l1.62 1.62-1.414 1.414-1.62-1.62a1 1 0 00-1.183-.172 5.987 5.987 0 01-1.139.469 1 1 0 00-.715.958V23h-2v-2.294a1 1 0 00-.715-.958 5.987 5.987 0 01-1.139-.469 1 1 0 00-1.183.172l-1.62 1.62-1.414-1.414 1.62-1.62a1 1 0 00.172-1.184 5.967 5.967 0 01-.469-1.139 1 1 0 00-.958-.714H15z"
                          className="color1e81ce svgShape"
                        ></path>
                        <path
                          fill="#24212f"
                          d="M24 18a4 4 0 10-4-4 4 4 0 004 4zm0-6a2 2 0 11-2 2 2 2 0 012-2z"
                          className="color1e81ce svgShape"
                        ></path>
                      </svg>
                    </svg>
                    {/* <!-- SVG ICON EMAIL MARKETING --> */}
                  </div>
                  <label>{i18n.t("account.labelEmail")}</label>
                </div>
              </div>

              <div
                className="col-4"
                style={{
                  paddingLeft: "1px",
                  paddingRight: "1px",
                  height: "100px",
                }}
              >
                <div
                  id="card"
                  className={"card "}
                  style={{
                    marginLeft: "0px",
                    textAlign: "center",
                    height: "130px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <div
                    className="card-body"
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    {/* <!-- SVG ICON COMPARTILHAMENTO --> */}
                    <label></label>
                    {/* <!-- SVG ICON COMPARTILHAMENTO --> */}
                  </div>
                </div>
              </div>

              <div
                className="col-4"
                style={{
                  paddingLeft: "1px",
                  paddingRight: "1px",
                  height: "100px",
                }}
              >
                <div
                  id="card"
                  className={"card "}
                  style={{
                    marginLeft: "0px",
                    textAlign: "center",
                    height: "130px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <div
                    className="card-body"
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    {/* <!-- SVG ICON COMPARTILHAMENTO --> */}
                    <label></label>
                    {/* <!-- SVG ICON COMPARTILHAMENTO --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <div className="card" style={{ minHeight: "205px" }}>
            <div className="card-body" style={{ paddingBottom: "5px" }}>
              {/* <div className="dx-field">
                <div className="dx-field-label">
                  {this.state.barCode === "active_"
                    ? i18n.t("account.labelcompanyname")
                    : i18n.t("account.labelcardname")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    value={this.state.userName}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "userName")
                    }
                  ></TextBox>
                </div>
              </div> */}

              {/* <div className="dx-field">
                <div className="dx-field-label">
                  1 {i18n.t("account.labelcompanytype")}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    placeholder=""
                    value={this.state.companyIdentifierB}
                    maxLength={18}
                    onValueChanged={(event) =>
                      this.handleValueChange(event, "companyIdentifierB")
                    }
                    onFocusOut={() =>
                      this.setState({
                        companyIdentifier: this.insertMask(
                          this.state.companyIdentifierB,
                          "companyIdentifierB"
                        ),
                      })
                    }
                  ></TextBox>
                </div>
              </div> */}

              {/* Campos do Cartão de Crédito */}
              {creditCardFields}

            </div>
          </div>
        </div>
      );
    } else if (this.state.cardType === "pix") {
      cardType = (
        <div style={{ marginTop: "-10px" }}>
          <div className="col-12 pl-0 pr-0">
            <div
              className="container row"
              style={{ padding: "0px", margin: "0 auto", height: "113px" }}
            >
              <div
                className="col-8"
                style={{
                  paddingLeft: "1px",
                  paddingRight: "1px",
                  height: "100px",
                }}
              >
                <div
                  id="card"
                  className={"card "}
                  style={{
                    marginLeft: "0px",
                    textAlign: "center",
                    height: "130px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <div
                    className="card-body"
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <div className="row">
                      <div className="col"></div>
                      <div className="col">
                        <label>PAGAMENTO VIA</label>
                        <img
                          style={{ maxWidth: "83%" }}
                          src={logo_pix}
                          alt="logo_pix"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* PLANOS  */}
              <div
                className="col-4"
                style={{
                  paddingLeft: "1px",
                  paddingRight: "1px",
                  height: "100px",
                }}
              >
                <div
                  id="card"
                  className={"card "}
                  style={{
                    marginLeft: "0px",
                    textAlign: "center",
                    height: "130px",
                    // backgroundColor: "#f5f5f5",
                  }}
                >
                  <div className="">
                    {/* <!-- SVG ICON COMPARTILHAMENTO --> */}
                    <img
                      width="80%"
                      height="90%"
                      src={`data:image/jpeg;base64, ${this.state.imageInBase64}`}
                    />

                    {/* <!-- SVG ICON COMPARTILHAMENTO --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <div className="card" style={{ minHeight: "205px" }}>
            <div className="card-body" style={{ paddingBottom: "5px" }}>
              <div className="dx-field">
                <div className="dx-field-label">
                  <span className="badge badge-success">
                    {i18n.t("account.pixCopy")}
                  </span>
                </div>
                <div className="dx-field-value">
                  <TextBox value={this.state.payloadInBase64}></TextBox>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  {/* {i18n.t("account.labelcompanytype")} */}
                </div>
                <div className="col-6">
                  <span className="badge" style={{ whiteSpace: "unset" }}>
                    {this.state.references}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <span
                    className="badge badge-light"
                    style={{ display: "block", borderRadius: "0px" }}
                  >
                    ID
                  </span>
                  <span
                    className="badge badge-warning"
                    style={{ display: "block", borderRadius: "0px" }}
                  >
                    {this.state.id}
                  </span>
                </div>
                <div className="col-4">
                  <span
                    className="badge badge-light"
                    style={{ display: "block", borderRadius: "0px" }}
                  >
                    VALIDADE QRCODE
                  </span>
                  <span
                    className="badge badge-warning"
                    style={{ display: "block", borderRadius: "0px" }}
                  >
                    {moment(this.state.dueDate)
                      .tz("America/Sao_Paulo")
                      .format("DD/MM/YYYY")}
                  </span>
                </div>
                <div className="col-4">
                  <span
                    className="badge badge-light"
                    style={{ display: "block", borderRadius: "0px" }}
                  >
                    VALOR
                  </span>
                  <span
                    className="badge badge-warning"
                    style={{ display: "block", borderRadius: "0px" }}
                  >
                    R$ {parseFloat(this.state.pixAmount).toFixed(2)}
                  </span>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-12"
                  style={{
                    overflow: "auto",
                    borderRadius: "4px",
                    border: "solid 1px #b7d5f9",
                    marginTop: "15px",
                    paddingTop: "15px",
                  }}
                >
                  <p>
                    <span
                      className="badge badge-default"
                      style={{ display: "block" }}
                    >
                      {this.state.reference}
                    </span>
                  </p>
                </div>
              </div>

              {/* Campos do Cartão de Crédito */}
              {creditCardFields}
            </div>
          </div>
        </div>
      );
    } else if (this.state.cardType === "payments") {
      cardType = (
        <div style={{ marginTop: "-10px" }}>
          <br />
          <div className="card" style={{ minHeight: "450px" }}>
            <div className="card-body" style={{ padding: "0px" }}>
              <div
                className="col-12"
              // style={{ marginLeft: "30px", marginTop: "30px" }}
              >
                <DataGrid
                  dataSource={this.state.datagrid}
                  showBorders={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  style={{ fontSize: "smaller", width: "100%" }}
                >
                  <Paging defaultPageSize={10} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[5, 10, 15]}
                    showInfo={true}
                  />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    className="custom"
                    width={240}
                    placeholder={i18n.t("account.grid.searchplaceholder")}
                  />
                  <Column
                    dataField="createdAt"
                    width={80}
                    caption={i18n.t("account.grid.columnlabelcreatedAt")}
                    dataType="date"
                    calculateCellValue={this.calculateDate}
                  ></Column>

                  <Column
                    dataField="description"
                    width={240}
                    caption={i18n.t("account.grid.columnlabeldescription")}
                  />

                  <Column
                    dataField="amount"
                    width={70}
                    caption={i18n.t("account.grid.columnamount")}
                    cellRender={this.setCurrecy}
                  />
                  <Column
                    dataField="status"
                    caption={i18n.t("account.grid.columnstatus")}
                    width={70}
                    editCellRender={this.statusEditorRender}
                  >
                    <Lookup
                      dataSource={this.statuses}
                      displayExpr="name"
                      valueExpr="id"
                    />
                    <RequiredRule />
                  </Column>
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container col-11" style={{ marginLeft: "30px" }}>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={true}
        />

        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={positionAll}
          visible={this.state.allPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={true}
        />

        <div id="branchCard" className="row mt-3 branch">
          <div className="col-3 pr-0">
            <div className="card" style={{ height: "600px", overflow: "auto" }}>
              <div className="card-body p-1">
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div style={{ height: "90px" }}></div>
                    </div>

                    <div className="col-6">
                      <div className="d-flex flex-row-reverse"></div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row mb-1">
                    <Button
                      id="backButton"
                      text={i18n.t("account.btnprofiledata")}
                      width="100%"
                      height="30px"
                      onClick={() => this.setState({ cardType: "profile" })}
                      type="default"
                      stylingMode={
                        this.state.cardType === "profile"
                          ? "contained"
                          : "outlined"
                      }
                    />
                  </div>
                  <div className="row mb-1">
                    <Button
                      id="backButton"
                      text={i18n.t("account.btnsign")}
                      width="100%"
                      height="30px"
                      onClick={() => {
                        this.setState({ cardType: "billing" });
                        this.getPlans();
                      }}
                      type="default"
                      stylingMode={
                        this.state.cardType === "billing"
                          ? "contained"
                          : "outlined"
                      }
                    />
                  </div>

                  {/* <div className="row mb-1">
                    <Button
                      id="backButton"
                      text={i18n.t("account.labelextracredit")}
                      width="100%"
                      height="30px"
                      onClick={() => {
                        this.setState({ cardType: "extracredit" });
                        this.getPlans();
                      }}
                      type="default"
                      stylingMode={
                        this.state.cardType === "extracredit"
                          ? "contained"
                          : "outlined"
                      }
                    />
                  </div> */}

                  <div className="row mb-1">
                    <Button
                      id="backButton"
                      text={i18n.t("account.btnpayments")}
                      width="100%"
                      height="30px"
                      onClick={() => this.getBalance()}
                      type="default"
                      stylingMode={
                        this.state.cardType === "payments"
                          ? "contained"
                          : "outlined"
                      }
                    />
                  </div>
                  <div className="row mb-1">
                    <Button
                      id="backButton"
                      text={i18n.t("account.btnconfigurations")}
                      width="100%"
                      height="30px"
                      onClick={() =>
                        this.setState({ cardType: "configurations" })
                      }
                      type="default"
                      stylingMode={
                        this.state.cardType === "configurations"
                          ? "contained"
                          : "outlined"
                      }
                    />
                  </div>
                </div>

                <hr />
              </div>
            </div>
          </div>

          <div className="col-9 pr-0">
            <div className="card" style={{ height: "600px", overflow: "auto" }}>
              {this.state.pixReady === false &&
                this.state.cardType === "pix" ? (
                <>
                  <Spinner />
                </>
              ) : (
                <div className="card-body">{cardType}</div>
              )}
            </div>
          </div>

          {/* DIREITA */}
          {/* DIREITA */}
          {/* DIREITA */}
          {/* DIREITA */}
          {/* DIREITA */}
        </div>
      </div>
    );
  }
}
