// This file is an action creator
// action creators return actions
// actions is an object that has at least a property of type
// this action creator is going to be handed to the dispatch
export default (element, index, extra) => {
    return {
        type: 'updateTopBar',
        payload: {
            element,
            index,
            extra
        }
    }
}