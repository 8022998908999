import React, { Component } from "react";
import TopBar from "../../LayOut/TopBar";
import MiddleBody from "../../LayOut/MiddleBody";
import SupportMenu from "../../LayOut/SupportMenu";
import LeftMenu from "../../LayOut/LeftMenu";
import TopRightMenu from "../../LayOut/TopRightMenu";

export default class ViewLandingPages extends Component {
  render() {
    return (
      <>
        <div
          id="5"
          className="col-1"
          style={{
            paddingLeft: "0 !important",
            margin: "0 auto",
            paddingTop: "8px",
          }}
        >
          {/* <!-- Left Menu --> */}
          <LeftMenu />
        </div>

        <div id="6" className="col-8 supMiddle">
          {/* <!--Menu Superior--> */}
          <TopBar module="mylandingpages" history={this.props.history} />
          {/* <!--Parte do meio--> */}
          <MiddleBody module="mylandingpages" history={this.props.history} />
        </div>
        <div id="7" className="col-3">
          {/* <!--Menu de Apoio--> */}
          <TopRightMenu history={this.props.history} />
          <SupportMenu module="mylandingpages" />
        </div>
      </>
    );
  }
}
