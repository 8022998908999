import React, { Component } from "react";
import "./ViewCouponsMiddle.css";
import { getCoupons } from "../../../apis/toowezeApi";
import { connect } from "react-redux";
import updateViewCouponSupportMenu from "../../../actions/updateViewCouponSupportMenu";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import Spinner from "../../LayOut/Spinner";
import letsGoGif from "../../../images/letsGo.gif";
import { i18n } from "../../../translate/i18n";

class ViewCouponsMiddle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allCoupons: [],
      allCouponsBody: null,
      clickedClass: "",
      spinner: true,
      timestamp: Date.now(),
    };
  }

  // Aciona o Action que passa as informações para o component CouponBuilderSupportMenu
  // Neste caso ao clicar no cupom, ele irá ser detalhado no Support Menu
  addElement = (element) => {
    this.props.updateViewCouponSupportMenu(element, 0, "extra");
  };

  // Busca o cupom caso esteja vindo de outra página indicando o id na url
  getCouponFromId() {
    let url = window.location.search;
    let params = queryString.parse(url);
    if (params.id) {
      this.addElement(params.id);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    // Atualiza os cupons depois de ter sido deletado no component ViewCouponSupportMenu
    // updateViewCouponMiddle
    if (
      this.props.viewCouponSupportReducer !== nextProps.viewCouponSupportReducer
    ) {
      // Lê todos os cupons
      if (
        this.props.viewCouponSupportReducer[0].extra == "updateViewCouponMiddle"
      ) {
        this.setState({
          allCoupons: [],
          allCouponsBody: null,
        });
        this.readCouponsStartPage();
        return true;
      }
    }

    return true;
  }

  componentDidMount() {
    // Lê todos os cupons
    this.readCouponsStartPage();
  }

  /// Lê todos os cupons
  readCouponsStartPage() {
    let coupons = [];
    let allCoupons = [];

    coupons.push(getCoupons());

    Promise.all(coupons)
      .then((data) => {
        if (data.length > 0) {
          data.forEach((coupon) => {
            if (coupon.length > 0) {
              // Evitando erro quando uma busca retorna vazia.
              allCoupons.push(coupon);
            }
          });
        }

        allCoupons.reverse();

        if (this.state.allCoupons.length == 0) {
          this.setState({
            allCoupons: allCoupons,
          });
        }
      })
      .catch((err) => console.log("erro **********", err));

    // Busca o cupom caso esteja vindo de outra página indicando o id na url
    this.getCouponFromId();
  }

  handleRedirectOnClick = (id) => {
    this.setState({ redirect: true, id: id });
  };

  render() {
    // Checa se tem cupom
    if (this.state.allCoupons.length > 0) {
      this.state.allCouponsBody = this.state.allCoupons.map((element) => {
        return element.map((coupon) => {
          return (
            <div
              key={coupon._id}
              className="col-md-6 col-xl-4"
              style={{ float: "left", padding: "3px" }}
              onClick={() => {
                this.addElement(coupon._id);
              }}
            >
              <div
                id={coupon._id}
                className={
                  "d-block shadow bg-white borderClass " +
                  this.state.clickedClass
                }
              >
                <div className="">
                  <div
                    className="input-group-append"
                    style={{ height: "28px", float: "right" }}
                  ></div>
                  <img
                    src={
                      coupon.imageurlThumb +
                      "?" +
                      (coupon.updated ? coupon.updated : null)
                    }
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          );
        });
      });
    } else {
      // Apresenta mensagem

      setTimeout(() => {
        this.state.allCouponsBody = (
          <h4>
            <h5>
              {i18n.t("viewcoupons.start")}
              <a href="studio"> {i18n.t("viewcoupons.click")}</a>
            </h5>
            <img src={letsGoGif} />
          </h4>
        );
      }, 100);

      if (this.state.spinner === true) {
        this.setState({
          spinner: false,
        });
      }
    }

    if (this.state.allCouponsBody) {
      if (this.state.allCouponsBody.length > 0 && this.state.spinner == true) {
        this.setState({
          spinner: false,
        });
      }
    }

    if (this.state.spinner == true) {
      return <Spinner />;
    }

    return (
      <>
        <div className="col-md-12 col-xl-12 content">
          {this.state.allCouponsBody}
        </div>
      </>
    );
  }
}

// mapStateToProps takes one args, "state" and that is the rootReducer/Store
function mapStateToProps(state) {
  // mapStateToProps returns an object, with:
  // property is the local prop name to this component
  // value will be the property in the root reducer... ie, piece of the store
  return {
    modalControl: state.modalControl,
    viewCouponSupportReducer: state.viewCouponSupportReducer,
  };
}

// mapDispatchToProps is how we tie our component to the dispatch
// it take one args: dispatch
function mapDispatchToProps(dispatch) {
  // this function return bindActionCreators
  // and we hand bindActionCreators an object
  // each property will be a local prop
  // each value will be a function that is dispatch when run
  // second arg of bindActionCreators is the dispatch
  return bindActionCreators(
    {
      updateViewCouponSupportMenu: updateViewCouponSupportMenu,
    },
    dispatch
  );
}

// export default TopBar;
// connect take 2 args, the first one is a function that is going to map
// a piece of redux state to this components props
export default connect(mapStateToProps, mapDispatchToProps)(ViewCouponsMiddle);
