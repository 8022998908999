import React, { Component } from "react";
import Spinner from "../../LayOut/Spinner";
import { Button } from "devextreme-react";
import { TextBox } from "devextreme-react";
import { SelectBox } from "devextreme-react";

// import usMessages from "devextreme/localization/messages/en.json";
import notify from "devextreme/ui/notify";

import DataGrid, {
    Column,
    Pager,
    Paging,
    HeaderFilter,
    SearchPanel,
    Editing,
    Popup,
    Form,
    EmailRule,
    Export,
    MasterDetail,
    Lookup,
    Scrolling

} from "devextreme-react/data-grid";
import { loadMessages, locale, } from "devextreme/localization";
import { getCustomers, createCustomer, updateCustomer, deleteCustomer, buscaCEP } from "../../../apis/toowezeApi";
import getCities from "../../../apis/citiesApi";
import states from "../../data/states";

import countries from "../../data/countries";

import queryString, { stringify } from "query-string";
import { Link } from "react-router-dom";
import { Item } from "devextreme-react/form";
import { Template } from "devextreme-react/core/template";
import { Popup as PopUp1 } from "devextreme-react/popup";
import { ScrollView } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";
import SwitchComponent from "../../CouponModule/BuildCanvas/SwitchComponent";
import Switch from "devextreme-react/switch";
import { i18n, lgMessages, languageCode, } from "../../../translate/i18n";
import "./CustomersViewMiddle.css";

let columns_;
let columns_Line;
let lines;
let myDrops;
// let delimiter = ";";
let columnList = [];
let message = "";
let isOK = false;
const position = {
    of: "#importing"
};

console.log("CustomerViewMiddle")
const phoneEditorOptions = { mask: '+1 (X00) 000-0000', maskRules: { X: /[02-9]/ } };

const MasterDetailGridElemnt = (element) => {
    if (element) {
        const balancePointsProcesses = element?.data?.data?.balancePointsProcesses;
        const result = [
            ...balancePointsProcesses,
        ];

        // _id: "643e054f58f06f3743ae95bc"
        // amount: 2350.2
        // branchId: "63b9f839d555c06788a0f70d"
        // createAt: 1681249478
        // customerId: "63b8aa1c0e1366854f61f891"
        // expirationDate: "2023-06-20T20:59:59-03:00"
        // observation: ""
        // operator: "63b9f873d555c06788a0f73e"
        // par: "643b05b358f06f3743ae48a3"
        // paymentRefId: "63c9e1b7224a25b2abfea326"
        // purchaseRefId: "63c9e1b7224a25b2abfea325"
        // ref: "643e054f58f06f3743ae95b6"
        // rootId: "d491f550-7656-56e7-832e-a3420ff48a01"
        // ruleId: "63b9f97cd555c06788a0f76d"
        // ruleName: "Importação de Pontos"

        const dataSource = result;
        return (
            <DataGrid
                dataSource={dataSource}
                showBorders={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                className="custom"
            >
                <Column
                    dataField="amount"
                    caption="amount"
                />
                <Column
                    dataField="customerId"
                    caption="customerId"
                />
                <Column
                    dataField="paymentRefId"
                    caption="paymentRefId"
                />
            </DataGrid>
        );
    }
};

const MasterDetailAddressGridElemnt = (element) => {
    if (element) {
        console.log("element", element)
        const address = element?.data?.data?.address;

        const dataSource = [address];
        return (
            <DataGrid
                dataSource={dataSource}
                showBorders={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                className="custom"
            >
                <Column
                    dataField="addressLine1"
                    caption="addressLine1"
                />
                <Column
                    dataField="addressLine2"
                    caption="addressLine2"
                />
                <Column
                    dataField="cityId"
                    caption="cityId"
                />
                <Column
                    dataField="cityName"
                    caption="cityName"
                />
                <Column
                    dataField="neighborhood"
                    caption="neighborhood"
                />
                <Column
                    dataField="neighborhood"
                    caption="neighborhood"
                />
                <Column
                    dataField="regionId"
                    caption="regionId"
                />
                <Column
                    dataField="regionName"
                    caption="cityId"
                />
                <Column
                    dataField="zipcode"
                    caption="zipcode"
                />

            </DataGrid>
        );
    }
};

const simpleLabel = (data) => {
    return (
        <span className="badge badge-success">
            {
                "Clique para ver detalhes"
            } </span>
    );
}

const MasterDetailGrid = (element) => {

    if (element) {
        const finalElement = {
            ...element?.data?.data,
        };

        const dataSource = [finalElement];
        console.log("dataSource", dataSource)
        return (
            <DataGrid
                dataSource={dataSource}
                showBorders={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                className="custom"
            >
                <MasterDetail enabled={true} dataField="address" component={MasterDetailAddressGridElemnt} />
                <Column
                    cellRender={simpleLabel}
                    caption={"Endereço"}
                ></Column>

                <Column
                    dataField="personalId"
                    caption={"CPF"}
                />
                <Column
                    dataField="personalsecondId"
                    caption={"RG"}
                />
                <Column
                    dataField="profilePic"
                    caption={"Foto"}
                />
                <Column
                    dataField="origin"
                    caption={"Origem"}
                />
            </DataGrid>
        );
    }
};

export default class CustomersViewMiddle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datagrid: [],
            spinner: true,
            events: [],
            popupVisible: false,
            delimiter: ";",
            text: "",
            button: false,
            backgroundcolor: "white",
            loadPanelVisible: false,
            statusDisabled: false,
            showDelimiter: "",
            myDrops: []
        };
        loadMessages(lgMessages);
        locale(languageCode);
    }

    componentDidMount() {
        this.readCustomersGrid();
    }

    // tagsHeaderFilter = [
    //     {
    //         text: "Engenheiro Civil",
    //         value: ["tags", "contains", "Engenheiro Civil"],
    //     },
    //     {
    //         text: "Servidora",
    //         value: ["tags", "contains", "Servidora"],
    //     },
    // ]



    readCustomersGrid() {
        getCustomers().then((res) => {
            this.setState({ datagrid: res, spinner: false, });
        }).catch((err) => {
            this.setState({ datagrid: [], spinner: false, });
        });
    }

    showNotify(message, type) {
        notify({
            message: message,
            width: 300,
            position: {
                at: "top",
                offset: "0 200",
            },
            closeOnClick: true,
        }, type, 3000);
    }

    // Cria os botões
    // TODO: Customer Activities
    contactsActions(data) {
        return (
            <Link to={
                `campaignsactivity?id=${data.data._id
                }`
            }
                className="buttonClass badge badge-success"
                style={
                    { color: "#fff" }
                }>
                {
                    i18n.t("contacts.grid.customerlabeldetails")
                } </Link>
        );
    }

    setStatus(data) {
        // // Active | Inactive | Deleted
        if (data.data.status === "Active") {
            return (
                <span className="badge badge-success">
                    {
                        i18n.t("customers.grid.customerstatusactive")
                    } </span>
            );
        } else if (data.data.status === "Inactive") {
            return (
                <span className="badge badge-danger">
                    {
                        i18n.t("customers.grid.customerstatusinactive")
                    } </span>
            );
        }
    }

    statusHeaderFilter = [
        {
            text: i18n.t("customers.grid.customerstatusactive"),
            value: ["status", "=", "Active"],
        },
        {
            text: i18n.t("customers.grid.customerstatusinactive"),
            value: ["status", "=", "Inactive"],
        },
    ];

    setGender(data) {
        if (data.data.gender === "1") {
            return (
                <span className="badge badge-primary" width="30px">
                    {
                        i18n.t("customers.grid.customergendermale")
                    } </span>
            );
        } else if (data.data.gender === "2") {
            return (
                <span className="badge badge-danger" width={3}>
                    {
                        i18n.t("customers.grid.customergenderfemale")
                    } </span>
            );
        } else {
            return (
                <span className="badge badge-info" width={3}>
                    {
                        i18n.t("customers.grid.customergendernot")
                    } </span>
            );
        }
    }

    setTags(data) {
        console.log("data", data?.data?.tags?.join("").toString())
        try {
            if (data?.data?.tags?.length > 0) {
                console.log(
                    "data1", data?.data?.tags?.join("")
                )
                return (
                    <span className="badge badge-info" width={3}>{data?.data?.tags?.join("").toString()}</span>
                );
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    genderHeaderFilter = [
        {
            text: i18n.t("customers.grid.customergendermaleheader"),
            value: ["gender", "=", "1"],
        },
        {
            text: i18n.t("customers.grid.customergenderfemalehearder"),
            value: ["gender", "=", "2"],
        },
    ];

    // Deleta um Customer da Lista
    onRowRemoving = (e) => {
        deleteCustomer(e.data._id).then(() => {
            this.showNotify(i18n.t("customers.grid.notifycustomersdeleted"), "success");
            this.readCustomersGrid();
        }).catch((err) => {

            this.showNotify(err, "error");
        });
    };

    // Atualiza um contato
    onRowUpdated = (e) => {
        updateCustomer({
            _id: e.data._id,
            name: e.data.name,
            email: e.data.email,
            mobil: e.data.mobil,
            gender: e.data.gender,
            externalID: e.data.externalID,
            birthDay: e.data.birthDay,
            personalId: e.data.personalId,
            personalsecondId: e.data.personalsecondId,
            address: {
                addressLine1: e.data.address.addressLine1,
                addressLine2: e.data.address.addressLine2,
                neighborhood: e.data.address.neighborhood,
                cityName: e.data.address.cityName,
                zipcode: e.data.address.zipcode,
                regionName: e.data.address.regionName
            },
            // TODO: Permitir atualizar as tags também, que é um array
            // tags: e.data.tags,
        }).then(() => {
            this.showNotify(i18n.t("customers.grid.notifycustomersupdated"), "success");
            this.readCustomersGrid();
        }).catch((err) => {
            this.showNotify(err, "error");
        });
    };

    onRowInserting = (e) => {
        e.cancel = new Promise((resolve, reject) => {
            if (e.data.mobil !== undefined || e.data.email !== undefined) {
                createCustomer({
                    name: e.data?.name,
                    email: e.data?.email,
                    mobil: e.data?.mobil,
                    gender: e.data?.gender,
                    externalID: e.data?.externalID,
                    birthDay: e.data?.birthDay,
                    personalId: e.data?.personalId,
                    personalsecondId: e.data?.personalsecondId,
                    address: {
                        addressLine1: e.data?.address?.addressLine1,
                        addressLine2: e.data?.address?.addressLine2,
                        neighborhood: e.data?.address?.neighborhood,
                        cityName: e.data?.address?.cityName,
                        zipcode: e.data?.address?.zipcode,
                        regionName: e.data?.address?.regionName,
                        regionId: e.data?.address?.regionId,
                        countryId: e.data?.address?.countryId
                    },
                    status: e.data?.address?.status,
                    origin: i18n.t("customers.grid.columnlabelorigin")
                }).then((res) => {
                    if (res) {
                        if (res.data.validation) {
                            if (res.data.validation.errors > 0) {
                                reject("Erro! " + res.data.validation.errorList[0]);
                            } else if (res.data.validation.success > 0) {
                                this.showNotify(i18n.t("customers.grid.notifycustomerdded"), "success");
                                this.readCustomersGrid();
                                resolve();
                            }

                        } else {
                            reject("Error!");
                        }

                    } else {
                        reject("Error!");
                    }

                }).catch((err) => {
                    reject(err);
                });
            } else {
                reject(i18n.t("contacts.grid.notifyrequiredfield"));
            }
        });
    };

    // Incluindo um botão no toolbar do Grid
    toolbarItemRender = () => {
        return (
            <Button id="import" icon="export"
                onClick={
                    this.importCustomers
                }
                hint={
                    i18n.t("customers.grid.customershintlabel")
                }
                style={
                    { background: "#f9b831" }
                } />
        );
    };

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({ location: "after", template: "totalGroupCount", });
    };
    // Incluindo um botão no toolbar do Grid

    // Função de Abrir modal de importar Contatos
    // importCustomers = () => {
    //     this.setState({ popupVisible: true });
    // };

    // Pop Up tipo modal para importar contatos
    // Desmiss o PopUp
    // hideInfo = () => {
    //     this.setState({ popupVisible: false, text: "", loadPanelVisible: false, });
    // };

    // delimiterValue = (event) => {
    //     this.setState({ delimiter: event.target.value });
    // };


    // readUpload = (event) => {
    //     if (event && event.target.files[0]) {
    //         let myFile = event.target.files[0];
    //         event.target.value = "";
    //         var reader = new FileReader();
    //         reader.readAsDataURL(myFile);
    //         if (myFile) {
    //             reader.onload = (e) => {
    //                 this.setState({ text: e.target.result, button: true, }, function () {
    //                     this.createDrop()
    //                 });

    //             };
    //         }
    //     }
    // };

    // TODO:
    // Evento do OnChange do FileUpload, lê as colunas e cria os dropdowns para o relacionamento das colunas antes da importação.
    buildForm = (text) => {

        if (text) { // var text = this.props.text;

            try {
                let indexOfBase = text.indexOf('base64,');
                let textToRemove = text.slice(0, indexOfBase + 7);

                if (text.includes(textToRemove)) {
                    text = text.replace(textToRemove, "");
                    isOK = true;
                } else {
                    isOK = false;
                    message = i18n.t("contacts.grid.messageimportingerror");
                }

            } catch (error) {
                isOK = false;
                message = i18n.t("contacts.grid.messageimportingerror");
            }

            if (isOK === true) {
                text = atob(text);

                // Remove aspas do texto
                let test = text;
                let patt = new RegExp(/['"]+/g);
                let res = patt.test(test);

                if (res === true) {
                    text = text.replace(/['"]+/g, '')
                }

                lines = text.split("\n");

                // Delimiter automatic
                let temp = lines[0];
                let count1 = (temp.match(/;/g) || []).length;
                let count2 = (temp.match(/,/g) || []).length;
                let check_delimiter;

                if (count1 > count2) {
                    this.setState({ delimiter: ";" })
                    check_delimiter = ";";
                } else if (count1 < count2) {
                    this.setState({ delimiter: "," })
                    check_delimiter = ",";
                }

                if (lines.length > 1) { // Alterei pois antes estava percorrendo todo arquivo apenas para identifiar o nome das colunas, que estão na primeira linha.

                    columns_ = lines[0].split(check_delimiter);
                    columnList = [];
                    columns_Line = columns_;

                    for (var j = 0, len2 = columns_Line.length; j < len2; j++) {
                        if (columns_Line[j].trim() !== "") {
                            columnList.push({ id: j, value: columns_Line[j], });
                        } else {
                            columns_Line.splice(j);
                        }
                    }
                }

                if (this.state.button === false) {
                    this.setState({ button: true });
                }

                return columnList;

            } else { // Mensagem
                this.showNotify(message);
                return [];
            }
        }
    };

    // TODO:
    // Função que realiza a importação dos contatos:
    proceedImportContacts = () => {

        if (isOK === true) {

            this.setState({ loadPanelVisible: true });

            let emailCol;
            let firstNameCol;
            let lastNameCol;
            let mobilCol;
            let extraField1Col;
            let extraField2Col;

            // Encontra as colunas relacionadas
            var colNumbers = new Promise(function (resolve, reject) {

                for (var k = 0, len3 = columns_Line.length; k < len3; k++) {

                    let selectOption_ = document.getElementById(`columnRef${k}`).value;

                    // Posiona os números das colunas e e atrela as variáveis
                    if (selectOption_ !== "0") {

                        if (selectOption_ === "email") {
                            emailCol = k;
                        } else if (selectOption_ === "firstName") {
                            firstNameCol = k;
                        } else if (selectOption_ === "lastName") {
                            lastNameCol = k;
                        } else if (selectOption_ === "mobil") {
                            mobilCol = k;
                        } else if (selectOption_ === "extraField1") {
                            extraField1Col = k
                        } else if (selectOption_ === "extraField2") {
                            extraField2Col = k
                        }
                    }
                }

                resolve({
                    emailCol,
                    firstNameCol,
                    lastNameCol,
                    mobilCol,
                    extraField1Col,
                    extraField2Col,
                });

            });

            colNumbers.then((colNumbers_) => {
                if (colNumbers_.emailCol !== undefined || colNumbers_.mobilCol !== undefined) {
                    return new Promise((resolve, reject) => {

                        let url = window.location.search;
                        let params = queryString.parse(url);

                        let data_ = {
                            listId: params.id,
                            list: [],
                        };

                        // Sabendo qual o relacionamento das colunas faz a importação
                        for (var i = 0, len = lines.length; i < len; i++) {

                            let columns_;
                            columns_ = lines[i].split(this.state.delimiter);

                            let email_ = "";
                            let firstName_ = "";
                            let lastName_ = "";
                            let mobil_ = "";
                            let extraField1_ = "";
                            let extraField2_ = "";

                            email_ = columns_[colNumbers_.emailCol];
                            firstName_ = columns_[colNumbers_.firstNameCol];
                            lastName_ = columns_[colNumbers_.lastNameCol];
                            mobil_ = columns_[colNumbers_.mobilCol];
                            extraField1_ = columns_[colNumbers_.extraField1Col];
                            extraField2_ = columns_[colNumbers_.extraField2Col];

                            if (email_) {
                                email_ = email_.trim().toLowerCase();
                            }

                            if (firstName_) {
                                firstName_ = firstName_.trim();
                            }

                            if (lastName_) {
                                lastName_ = lastName_.trim();
                            }

                            if (mobil_) {
                                mobil_ = mobil_.trim();
                            }

                            if (mobil_) {
                                mobil_ = mobil_.match(/\d+/g);
                            }

                            if (mobil_) {

                                if (mobil_.length > 0) {
                                    mobil_ = mobil_.join("");
                                }

                            }

                            if (email_ !== undefined) {
                                if (!email_.includes("@")) {
                                    email_ = undefined;
                                }

                            }

                            if (extraField1_) {
                                extraField1_ = extraField1_.trim();
                            }

                            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                            if (!re.test(String(email_).trim().toLowerCase())) {
                                email_ = undefined;
                            }

                            if ((email_ !== "" && email_ !== undefined && email_.includes("@")) || (mobil_ !== "" && mobil_ !== undefined)) {

                                if (firstName_) {

                                    let firstNameArray = firstName_.split(" ");

                                    if (firstNameArray.length > 1 && (lastName_ === undefined || lastNameCol === "0")) {

                                        firstName_ = firstNameArray.shift();
                                        lastName_ = firstNameArray.join(" ");
                                    }

                                }

                                let add = {

                                    firstName: firstName_,
                                    lastName: lastName_,
                                    mobil: mobil_,
                                    email: email_,
                                    extraField1: extraField1_,
                                    extraField2: extraField2_,
                                    status: 1,
                                    origin: i18n.t("contacts.grid.contactoriginlabelimported"),

                                };

                                data_.list.push(add);
                            } else {
                                continue;
                            }

                        }

                        resolve(data_);

                    }).then((mydata) => { // Enviando tudo para importação.
                        createCustomer(mydata).then((res) => { // Resultado da importação
                            if (res) {
                                if (res.status === 200) {
                                    if (res.data.validation) {
                                        if (res.data.validation.success > 0 && res.data.validation.errors > 0) { // Houveram sucessos e erros
                                            this.showNotify(`${res.data.validation.success
                                                }, ${i18n.t("contacts.grid.notifyimportresult1")
                                                } ${res.data.validation.errors
                                                } ${i18n.t("contacts.grid.notifyimportresult2")
                                                }
                                                 ${i18n.t("contacts.grid.notifyimportresult3")
                                                }`);
                                            this.readCustomersGrid(); // / Relê os dados no grid.

                                        } else if (res.data.validation.errors > 0 && res.data.validation.success == 0) { // Todos com erro
                                            this.showNotify(i18n.t("contacts.grid.notifyimportresult4"), "error");
                                            this.readCustomersGrid(); // / Relê os dados no grid.

                                        } else if (res.data.validation.success > 0 && res.data.validation.errors == 0) { // Todos com Sucesso
                                            this.showNotify(i18n.t("contacts.grid.notifyimportresult5"), "success");
                                            this.readCustomersGrid(); // / Relê os dados no grid.
                                        }

                                        setTimeout(() => {
                                            this.hideInfo();
                                        }, 3000);
                                    }

                                } else if (res.status === 402) {
                                    this.showNotify(i18n.t("contacts.grid.notifyimportresult6"), "error");
                                    this.hideInfo();
                                }

                                else {
                                    this.showNotify("Error!", "error");
                                    this.hideInfo();
                                }

                            } else {
                                this.showNotify("Error!", "error");
                                this.hideInfo();
                            }

                        }).catch((error) => { // Erro da Importação

                            this.setState({ loadPanelVisible: false });

                            let message = "";
                            let message_ = "";

                            console.log("error", error);

                            if (error) {

                                if (error?.reponse) {
                                    console.log("error.reponse", error.reponse);
                                    if (error?.response?.data) {
                                        console.log("error.response.data", error.response.data)
                                        message = error.response.data;
                                        if (message === "You did reach the account limit") { // Close the model
                                            message_ = "Sua conta atingiu o limite de contatos. Faça um Upgrade!";
                                            this.showNotify("Erro Na Importação: " + message_, "error");
                                        } else if (message === "No Contacts to import!") {
                                            message_ = "Não há nenhum contato nesta lista para importar!";
                                            this.showNotify("Erro Na Importação: " + message_, "error");
                                        } else {
                                            message_ = message;
                                            this.showNotify("Erro Na Importação: " + message_, "error");
                                        }
                                    }

                                } else {
                                    if (error?.message === "Request failed with status code 402") {
                                        message_ = "Sua conta atingiu o limite de contatos. Faça um Upgrade!";
                                        this.showNotify("Erro Na Importação: " + message_, "error");
                                    } else {
                                        console.log("error status", error.status)
                                        this.showNotify("Erro Na Importação: " + error.reponse, "error");
                                    }
                                }
                            } else {
                                this.showNotify("Erro Na Importação: " + error, "error");
                            }
                        });
                    }).catch((err) => {

                        console.log("err", err);
                        this.showNotify("Erro Na Importação: " + err, "error");

                    });

                } else {

                    this.setState({ loadPanelVisible: false });

                    if (colNumbers_.emailCol == undefined && colNumbers_.mobilCol == undefined && colNumbers_.firstNameCol == undefined && colNumbers_.lastNameCol == undefined) {

                        this.showNotify("Você não relacionou nenhuma coluna para importação.", "error");
                    } else {
                        this.showNotify("Você precisa selecionar ao menos uma coluna com dados de contato como: email ou celular.", "error");
                    }
                }
            });
        }

    };

    changedValue = (event, id) => {
        this.setState({
            backgroundcolor: {
                id: id,
                color: "yellow",
            }
        });
    };

    // Status
    editStatus = (data) => { // Tras o component de Switch
        return (
            <div>
                <SwitchComponent id={
                    data.data._id
                }
                    event={"customerStatus"}
                    value={
                        data.data.status === "Active" ? true : false
                    } />
            </div>
        );
    };

    renderSwitch = (cellInfo) => {
        const setEditedValue = (valueChangedEventArg) => {
            cellInfo.setValue(valueChangedEventArg.value);
        };
        return (
            <div className=""
                style={
                    { paddingLeft: "6px" }
                }>
                <Switch width={50}
                    switchedOnText={i18n.t("customers.grid.customerstatusactive")}
                    switchedOffText={i18n.t("customers.grid.customerstatusinactive")}
                    defaultValue={
                        cellInfo.value
                    }
                    disabled={
                        cellInfo.row.values[3] === 0 || cellInfo.row.values[3] === 1 ? false : true
                    }
                    onValueChanged={setEditedValue} />
            </div>
        );
    };

    calculateCellValue2(data) {
        if (data) {
            if (data.createdAt) {
                return new Date(data.createdAt * 1000);
            }
        }
    }

    addCurrency = (data) => {
        if (data.value) {
            const value = data.value;
            const formattedValue = Number(value).toFixed(2);
            const withZero = formattedValue.includes('.') ? formattedValue : formattedValue + '0';
            return (
                <span className="badge badge-primary" width="30px">
                    {
                        withZero
                    } </span>
            );
        } else {
            return (<div>
                <label className="badge">n/a</label>
            </div>
            );
        }
    };


    // Cria link para ver atividades da campanha
    activity(data) {
        return (
            <Link to={`addeditcustomers?id=${data.data._id}`}>
                {i18n.t("customers.grid.customerlabeldetails")}
            </Link>
        );
    }

    // TODO:
    createDrop() {
        if (this.state.text) {

            myDrops = this.buildForm(this.state.text).map((result) => {

                return (
                    <div key={
                        result.id
                    }
                        className="form-group">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6">
                                    <label htmlFor={
                                        "Column_" + result.id
                                    }>
                                        Coluna do seu arquivo
                                    </label>
                                    <input className="form-control" type="text"
                                        id={
                                            "Column_" + result.id
                                        }
                                        defaultValue={
                                            result.value
                                        } />
                                </div>
                                <div className="col-6">
                                    <label htmlFor={
                                        "columnRef" + result.id
                                    }>
                                        {
                                            i18n.t("contacts.grid.columnrelationslabel")
                                        } </label>
                                    <select id={
                                        "columnRef" + result.id
                                    }
                                        className="browser-default custom-select"
                                        defaultValue="0">
                                        <option value="0">
                                            {
                                                i18n.t("contacts.grid.dropdownignorelabel")
                                            } </option>
                                        <option value="firstName">
                                            {
                                                i18n.t("contacts.grid.dropdownnamelabel")
                                            } </option>
                                        <option value="lastName">
                                            {
                                                i18n.t("contacts.grid.dropdownnlastnamelabel")
                                            } </option>
                                        <option value="email">
                                            {
                                                i18n.t("contacts.grid.dropdownnemaillabel")
                                            } </option>
                                        <option value="mobil">
                                            {
                                                i18n.t("contacts.grid.dropdownnmobillabel")
                                            } </option>
                                        <option value="extraField1">
                                            Extra {/* {i18n.t("contacts.grid.dropdownnextralabel")} */} </option>
                                        <option value="extraField2">
                                            Extra2 {/* {i18n.t("contacts.grid.dropdownnextralabel")} */} </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            });
            this.setState({ myDrops: myDrops })

        }
        // }, 500);

    }


    onKeyUp1(event) {
        console.log("event", event)
    }

    onKeyUp(event, fieldId) {

        console.log("event", event)
        event.event.preventDefault();

        let finalValue = event.event.currentTarget.value;

        if (fieldId === "zipCode" || fieldId === "zipCodeB") {
            this.setState({ goValidateEmail: false });
            this.setState({ goValidateIdentifier: false });

            /// CEP
            var v = finalValue.replace(/^([\d]{5})\.*([\d]{3})/, "$1-$2");
            finalValue = v;

            this.setState({
                [fieldId]: finalValue,
            });

            if (finalValue.length === 9) {
                this.setState({
                    loadPanelVisible: true,
                });

                buscaCEP(finalValue)
                    .then((cep) => {
                        if (cep.data.uf) {
                            getCities(cep.data.uf)
                                .then((res) => {
                                    if (res.data) {
                                        if (fieldId === "zipCode") {
                                            this.setState({
                                                cities: res.data,
                                                loadPanelVisible: false,
                                                addressLine1: cep.data.logradouro,
                                                // addressLine2: cep.data.complemento,
                                                cityRegion: cep.data.bairro,
                                                state: cep.data.uf,
                                                city: cep.data.localidade,
                                                backgroundColor: "#ffdede",
                                            });
                                        } else if (fieldId === "zipCodeB") {
                                            this.setState({
                                                citiesB: res.data,
                                                loadPanelVisible: false,
                                                addressLine1B: cep.data.logradouro,
                                                // addressLine2B: cep.data.complemento,
                                                cityRegionB: cep.data.bairro,
                                                stateB: cep.data.uf,
                                                cityB: cep.data.localidade,
                                                backgroundColorB: "#ffdede",
                                            });
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.log("Cities Error", error);
                                });
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            loadPanelVisible: false,
                        });
                    });
            }
        } else if (fieldId === "companyIdentifier") {
            if (finalValue) {
                if (finalValue.length === 14) {
                    /// Pessoa Jurídica
                    var v = finalValue.replace(
                        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                        "$1.$2.$3/$4-$5"
                    );
                    finalValue = v;
                } else if (finalValue.length === 11) {
                    /// Pessoa Física
                    var v = finalValue.replace(
                        /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                        "$1.$2.$3-$4"
                    );
                    finalValue = v;
                }

                this.setState({
                    companyIdentifier: finalValue,
                });

                if (this.state.companyIdentifier) {
                    if (
                        this.state.companyIdentifier.length == 13 ||
                        this.state.companyIdentifier.length == 17
                    ) {
                        this.setState({ goValidateIdentifier: true });
                    } else {
                        this.setState({ goValidateIdentifier: false });
                    }
                }
            }
        } else if (
            fieldId === "phone" ||
            fieldId === "whatsup" ||
            fieldId === "mobil"
        ) {
            // Telefone
            var v = finalValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
            v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
            v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
            finalValue = v;
            this.setState({
                [fieldId]: finalValue ? finalValue : null,
            });
        } else if (fieldId === "email") {
            let email = event.event.currentTarget.value;

            if (email) {
                if (email.includes("@")) {
                    this.setState({
                        email: email,
                        goValidateEmail: true,
                    });
                }
            }
        } else if (fieldId === "creditCard") {
            var v = finalValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
            v = v.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/g, "$1 $2 $3 $4");
            finalValue = v;
            this.setState({
                [fieldId]: finalValue ? finalValue : null,
            });
        } else if (fieldId === "validate") {
            var v = finalValue.replace(/\D/g, ""); //Remove tudo o que não é dígito
            v = v.replace(/(\d{2})(\d{2})/g, "$1/$2");
            finalValue = v;
            this.setState({
                [fieldId]: finalValue ? finalValue : null,
            });
        }
    }


    toDate = (data) => {
        try {
            return new Date(data?.data?.log_date_timeZone).toLocaleString();
        } catch (error) {
            console.log('error =======>', error)
            return null
        }
    }


    render() {

        if (this.state.spinner === true) {
            return <Spinner />;
        } else {
            return (
                <div className="container col-11"
                    style={
                        {
                            marginLeft: "30px",
                            marginTop: "30px",
                        }
                    }>
                    {/* PopUp de Importação de Contatos */}
                    <PopUp1 visible={
                        this.state.popupVisible
                    }
                        onHiding={
                            this.hideInfo
                        }
                        dragEnabled={false}
                        closeOnOutsideClick={true}
                        showTitle={true}
                        title="Importar Lista de Cotatos"
                        width={600}
                        height={450}
                        style={
                            { overflow: "scroll" }
                        }>
                        <ScrollView width="100%" height="100%">
                            <div id="importing" className="col-12"
                                style={
                                    { marginLeft: "3px" }
                                }>
                                <div className="row">
                                    <div className="col p-0">
                                        <div className="pl-3 pr-3">
                                            {/* <div className="container form-group mb-3">
                                                <label htmlFor="delimiter">
                                                    {
                                                        i18n.t("contacts.grid.delimiter")
                                                    } </label>
                                                <input className="form-control" type="text" id="delimiter"
                                                    style={
                                                        {
                                                            width: "72px",
                                                            margin: "0px 0px 16px 0px",
                                                            padding: "6px",

                                                        }

                                                    }
                                                    maxLength="1"
                                                    value={
                                                        this.state.delimiter
                                                    }
                                                    onChange={
                                                        (event) => this.setState({ delimiter: event.target.value })
                                                    } />
                                                <label style={
                                                    {
                                                        fontWeight: "bolder",
                                                        color: 'red',
                                                    }

                                                }>
                                                    {
                                                        this.state.showDelimiter
                                                    }</label>
                                            </div> */}

                                            <div className="container input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"
                                                        style={
                                                            { height: "35px" }
                                                        }>
                                                        {
                                                            i18n.t("contacts.grid.upload")
                                                        } </span>
                                                </div>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="fileUpload_"
                                                        onChange={
                                                            (event) => this.readUpload(event)
                                                        } />
                                                    <label className="custom-file-label" htmlFor="fileUpload_">
                                                        {
                                                            i18n.t("contacts.grid.uploadfile")
                                                        } </label>
                                                </div>
                                            </div>

                                            <div id="allColumns">
                                                {this.state.myDrops}

                                                <div className="container row p-0"
                                                    style={
                                                        { textAlign: "right" }
                                                    }>
                                                    <div className="col p-0">
                                                        <Button className="m-1"
                                                            visible={
                                                                this.state.button
                                                            }
                                                            width={120}
                                                            height={25}
                                                            text="Cancelar"
                                                            type="success"
                                                            stylingMode="outlined"
                                                            onClick={
                                                                this.hideInfo
                                                            } />
                                                        <Button visible={
                                                            this.state.button
                                                        }
                                                            width={120}
                                                            height={25}
                                                            text="Importar"
                                                            type="success"
                                                            stylingMode="contained"
                                                            onClick={
                                                                this.proceedImportContacts
                                                            } />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <LoadPanel shadingColor="rgba(0,0,0,0.4)"
                                position={position}
                                visible={
                                    this.state.loadPanelVisible
                                }
                                // showIndicator={true}
                                shading={true}
                                showPane={true}
                                closeOnOutsideClick={true} />
                        </ScrollView>
                    </PopUp1>
                    {/* PopUp de Importação de Contatos */}



                    <DataGrid dataSource={
                        this.state.datagrid
                    }


                        columnWidth={100}
                        showBorders={true}

                        allowColumnReordering={true}
                        allowColumnResizing={true}
                    // onRowInserting={
                    //     this.onRowInserting
                    // }
                    // onRowUpdated={
                    //     this.onRowUpdated
                    // }
                    // onRowRemoving={
                    //     this.onRowRemoving
                    // }
                    // onToolbarPreparing={
                    //     this.onToolbarPreparing
                    // }
                    /// Adicionando um botão no toolbar do grid
                    >
                        {/* <Scrolling columnRenderingMode="virtual" /> */}
                        <Scrolling mode="standard" />
                        <Paging defaultPageSize={12} />
                        <Pager showInfo={true} />

                        {/* <Editing mode="popup"
                            // allowUpdating={true}
                            allowDeleting={true}
                            allowAdding={true}>
                            <Popup title="Cliente"
                                showTitle={true}
                                width={700}
                                height={500}
                                shading={true}
                                fullScreen={false}>
                            </Popup>
                            <Form>

                                <div id="addressCard" className="card mb-1">
                                    <div
                                        className="card-body second-group"
                                        style={{ paddingBottom: "5px" }}
                                    >
                                        <div className="dx-field">
                                            <div className="dx-field-label">
                                                {i18n.t("account.labelzipcode")}
                                            </div>
                                            <div className="dx-field-value">
                                                <TextBox
                                                    placeholder=""
                                                    maxLength={9}
                                                    value={this.state.zipCodeB}
                                                    onKeyUp={(event) => this.onKeyUp(event, "zipCodeB")}
                                                />
                                            </div>
                                        </div>

                                        <div className="dx-field">
                                            <div className="dx-field-label">
                                                {i18n.t("account.labelcityregion")}
                                            </div>
                                            <div className="dx-field-value">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <SelectBox
                                                            dataSource={states}
                                                            displayExpr="sigla"
                                                            valueExpr="sigla"
                                                            stylingMode="filled"
                                                            defaultValue=""
                                                            width="100%"
                                                            value={this.state.stateB}
                                                            placeholder={i18n.t("account.labelregion")}
                                                            onValueChanged={(event) =>
                                                                this.handleValueChange(event, "stateB")
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-8">
                                                        <SelectBox
                                                            dataSource={this.state.cities}
                                                            displayExpr="nome"
                                                            valueExpr="nome"
                                                            stylingMode="filled"
                                                            defaultValue=""
                                                            width="100%"
                                                            placeholder={i18n.t("account.labelcity")}
                                                            value={this.state.cityB}
                                                            onValueChanged={(event) =>
                                                                this.handleValueChange(event, "cityB")
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="dx-field">
                                            <div className="dx-field-label">
                                                {i18n.t("account.labeladdressline1")}
                                            </div>
                                            <div className="dx-field-value">
                                                <div className="row">
                                                    <div className="col-8" style={{ paddingRight: "3px" }}>
                                                        <TextBox
                                                            showClearButton={true}
                                                            placeholder={i18n.t("account.placeholderaddressline1")}
                                                            value={this.state.addressLine1B}
                                                            onValueChanged={(event) =>
                                                                this.handleValueChange(event, "addressLine1B")
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-4" style={{ paddingLeft: "4px" }}>
                                                        <TextBox
                                                            showClearButton={true}
                                                            placeholder={i18n.t("account.placeholdernumber")}
                                                            value={this.state.numberB}
                                                            onValueChanged={(event) =>
                                                                this.handleValueChange(event, "numberB")
                                                            }
                                                            style={{
                                                                backgroundColor: this.state.backgroundColorB
                                                                    ? this.state.backgroundColorB
                                                                    : "",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="dx-field">
                                            <div className="dx-field-label">
                                                {i18n.t("account.labeladdressline2")}
                                            </div>
                                            <div className="dx-field-value">
                                                <TextBox
                                                    showClearButton={true}
                                                    placeholder={i18n.t("account.placeholderaddressline2")}
                                                    value={this.state.addressLine2B}
                                                    onValueChanged={(event) =>
                                                        this.handleValueChange(event, "addressLine2B")
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="dx-field">
                                            <div className="dx-field-label">
                                                {i18n.t("account.labelneighborhood")}
                                            </div>
                                            <div className="dx-field-value">
                                                <TextBox
                                                    showClearButton={true}
                                                    placeholder={i18n.t("account.placeholderneighborhood")}
                                                    value={this.state.cityRegionB}
                                                    onValueChanged={(event) =>
                                                        this.handleValueChange(event, "cityRegionB")
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Form>
                        </Editing> */}

                        <HeaderFilter visible={true} />
                        <SearchPanel visible={true}
                            className="custom"
                            width={240}
                            placeholder={
                                i18n.t("customers.grid.searchplaceholder")
                            } />

                        <Column
                            dataField="seeActivity"
                            caption={i18n.t("customers.grid.customerlabeldetails")}
                            cellRender={this.activity}
                            allowFiltering={false}
                        />


                        <Column dataField="name" width={200} caption={i18n.t("customers.grid.columnlabelname")} />
                        <Column dataField="email" width={200} caption={i18n.t("customers.grid.columnlabelemail")} />
                        <Column dataField="mobil" caption={i18n.t("customers.grid.columnlabelmobil")} />

                        <Column dataField="gender"
                            caption={
                                i18n.t("customers.grid.columnlabelgender")
                            }
                            cellRender={
                                this.setGender
                            }
                            editCellRender={
                                this.renderSwitch
                            }

                        ><HeaderFilter dataSource={this.genderHeaderFilter} /></Column>
                        {/* <MasterDetail enabled={true} component={MasterDetailGrid} /> */}
                        <Column dataField="externalID" caption={i18n.t("customers.grid.columnlabelexternalID")} />
                        <Column dataField="birthDay" dataType="date" caption={i18n.t("customers.grid.columnlabelbirthDay")} />
                        <Column dataField="address.cityName" caption={i18n.t("customers.grid.columnlabelcityName")} />
                        <Column dataField="regionId" caption={i18n.t("customers.grid.columnlabelregionName")}>
                            <Lookup dataSource={states} valueExpr="id" displayExpr="nome" />
                        </Column>

                        {/* <Col    umn dataField="personalsecondId" caption={i18n.t("customers.grid.columnlabelpersonalsecondId")} /> */}
                        <Column dataField="countryId" caption={i18n.t("customers.grid.columnlabelcountryName")}>
                            <Lookup dataSource={countries} valueExpr="id" displayExpr="name" />
                        </Column>
                        <Column dataField="tags" type={"Array"}
                            // cellRender={this.setTags} 
                            caption={i18n.t("customers.grid.columnlabeltags")}>
                            {/* <HeaderFilter dataSource={this.tagsHeaderFilter} /> */}
                        </Column>

                        <Column dataField="status"
                            caption={
                                i18n.t("customers.grid.columnlabelstatus")
                            }
                            cellRender={
                                this.setStatus
                            }
                            editCellRender={
                                this.renderSwitch
                            } >
                            <HeaderFilter dataSource={this.statusHeaderFilter} />
                        </Column>

                        <Column
                            dataField="log_date_timeZone"
                            caption={i18n.t("transactions.activity.log_date_timeZone")}
                            cellRender={this.toDate}
                        ></Column>

                        <Column dataField="_id" width={100} />

                        <Template name="totalGroupCount"
                            render={
                                this.toolbarItemRender
                            } />
                        <Export enabled={true}
                            allowExportSelectedData={false}
                            fileName="TooWeze_Customers" />
                    </DataGrid>

                </div>
            );
        }
    }
}