// All reducers have 2 params :
// 1. Current State
// 2. Info that came from any action

const seedDate = [
    {
        screenSize: ""
        // screenSize: { width: "540", height: "400" }
    },
];

export default (state = seedDate, action) => {
    if (action.type == "updateScreenSize") {
        const newState = [...state];
        newState[action.payload.index].screenSize = action.payload.screenSize;
        return newState;
    } else {
        const newState = [...state];
        return newState;
    }
};