import React, { Component } from "react";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Label,
  Format,
  Legend,
  Export,
  Font,
  Tooltip,
  ArgumentAxis,
  Size,
  ValueAxis,
  LoadingIndicator,
  Grid,
  ScrollBar,
  ZoomAndPan
} from "devextreme-react/chart";
import { reports } from "../../../apis/toowezeApi";
import { Button } from "devextreme-react";
import DateBox from "devextreme-react/date-box";
import { loadMessages, locale } from "devextreme/localization";
import { lgMessages, languageCode } from "../../../translate/i18n";
import Switch from "devextreme-react/switch";
import './switch.css';
import RadioGroup from 'devextreme-react/radio-group';

const priorities = ['Vendas', 'CashBack Distribuído', 'Pontos Distribuídos'];

const customPalette = ["#f9c14e"];

export default class SalesStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphStats: 0,
      spinner: true,
      customStartDate: 0,
      customEndDate: 0,
      exibitionType: 'Financial',
      exibitionPeriod: 'byDay'
    };
    this.customizeLabel = this.customizeLabel.bind(this);
    loadMessages(lgMessages);
    locale(languageCode);
  }

  componentDidMount() {
    this.getReport()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.exibitionType !== this.state.exibitionType) {
      this.setState({
        graphStats: null
      })
    }
  }

  changeExibitionType = (event) => {
    let exibitionType;

    if (event.value === 'Vendas') {
      exibitionType = 'Financial'
    } else if (event.value === 'CashBack Distribuído') {
      exibitionType = 'CashBack'
    } else if (event.value === 'Pontos Distribuídos') {
      exibitionType = 'Points'
    }

    this.setState({
      exibitionType: exibitionType
    })

    this.getReport()

  }

  getReport = (initDate, endDate, peridType) => {

    if (!initDate || !endDate) {
      this.setState({
        customStartDate: 0,
        customEndDate: 0
      })

      const monthDates = this.getMonthDates(peridType);
      initDate = monthDates.startOfMonth;
      endDate = monthDates.endOfMonth;
    }


    if (this.state.exibitionType === 'Financial' || this.state.exibitionType === 'CashBack' || this.state.exibitionType === 'Points') {
      reports(this.state.exibitionPeriod + this.state.exibitionType, initDate, endDate).then(result => {

        let result_;

        if (result) {

          if (this.state.exibitionPeriod === 'byDay') {
            result_ = result[0].dailyValues.map(item => {
              return item;
            })

            result_ = result[0].dailyValues.map(item => {
              if (item?.averageFeels_like) {
                item.averageFeels_like = parseInt(item?.averageFeels_like);
              }
              return item;
            })

            this.setState({
              salesCorrelation: result[0]?.stats?.sales,
              ticketCorrelation: result[0]?.stats?.averageTicket,
              transactionCorrelation: result[0]?.stats?.count
            })

          } else if (this.state.exibitionPeriod === 'byMonth') {

            if (result?.monthlyValues) {
              result_ = result.monthlyValues.map(item => {
                return item;
              })
              result_ = result.monthlyValues.map(item => {
                item.averageFeels_like = parseInt(item?.averageFeels_like);
                return item;
              })

              this.setState({
                salesCorrelation: result?.stats?.sales,
                ticketCorrelation: result?.stats?.averageTicket,
                transactionCorrelation: result?.stats?.count
              })

            } else {
              result_ = result.map(item => {
                return item;
              })
              result_ = result.map(item => {
                if (item?.averageFeels_like) {
                  item.averageFeels_like = parseInt(item?.averageFeels_like);
                }
                return item;
              })
            }
          }
        }

        if (result_) {
          this.setState({
            graphStats: result_
          })
        }

      }).catch(err => {
        console.log('err', err)
      })
    }
  }

  handleValueChange(event, type) {
    if (type === "customStartDate") {
      this.setState({ customStartDate: this.returnDateValue(new Date(event.value.toString())) + 'T00:00:00-03:00' })
    } else if (type === "customEndDate") {
      this.setState({ customEndDate: this.returnDateValue(new Date(event.value.toString())) + 'T23:59:59-03:00' })
    }
    if (this.state.customStartDate !== 0 && this.state.customEndDate !== 0) {
      this.getReport(this.state.customStartDate, this.state.customEndDate)
    }
  }

  returnDateValue(newDate) {
    return newDate.getFullYear() + "-" + ("0" + (newDate.getMonth() + 1)).slice(-2) + "-" + ("0" + newDate.getDate()).slice(-2);
  }

  getMonthDates(periodType, customInitDate = "", customEndDate = "") {

    const date = new Date();
    let initDate;
    let endDate;
    let year;
    let month;
    let day;

    if (periodType === 'fullMonth' || !periodType) {
      initDate = new Date(date.getFullYear(), date.getMonth(), 1);
      endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else if (periodType === 'last30days') {
      const startDate = new Date();
      startDate.setDate(date.getDate() - 30);
      initDate = startDate;
      endDate = date
    } else if (periodType === 'last90days') {
      const startDate = new Date();
      startDate.setDate(date.getDate() - 90);
      initDate = startDate;
      endDate = date
    } else if (periodType === 'lastmonth') {
      const startDate = new Date();
      startDate.setDate(date.getDate() - 30);
      initDate = startDate;
      endDate = date
    }

    const formatDate = (date, type) => {

      year = date.getFullYear();
      month = (date.getMonth() + 1).toString().padStart(2, '0');
      day = date.getDate().toString().padStart(2, '0');

      let hours = '00';
      let minutes = '00';
      let seconds = '00';

      if (type === 'start') {
        hours = '00';
        minutes = '00';
        seconds = '00';
      }

      if (type === 'end') {
        hours = '23';
        minutes = '59';
        seconds = '59';
      }

      const finalOffSet = "-03:00";

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${finalOffSet}`;
    };

    const formattedDates = {
      startOfMonth: formatDate(initDate, 'start'),
      endOfMonth: formatDate(endDate, 'end'),
    };

    return formattedDates;
  }

  render() {

    return (
      <div
        className="col-12"
      >
        <div className="row m-0" style={{ textAlign: 'center' }}>
          <div className="col-md-12 col-xl-12 content">
            <div className="row" style={{ justifyContent: 'space-around' }}>
            </div>
          </div>
        </div>

        <div className="row m-0">
          <div className="col-12 container">
            <div className="row pb-3">
              <div className="col-6 p-3" style={{ backgroundColor: "whitesmoke" }}>
                <div className="row">
                  <div className="col-6">
                    <div className="dx-field">
                      <div className="dx-field-label">
                        {"Data Inicial"}
                      </div>
                      <DateBox
                        className="dx-field-value"
                        type="date"
                        value={
                          this.state.customStartDate
                            ? this.state.customStartDate === "0"
                              ? null
                              : this.state.customStartDate
                            : null
                        }
                        placeholder="Período Inicial"
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "customStartDate")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="dx-field">
                      <div className="dx-field-label">
                        {"Data Final"}
                      </div>
                      <DateBox
                        className="dx-field-value"
                        type="date"
                        placeholder="Período Final"
                        value={
                          this.state.customEndDate
                            ? this.state.customEndDate === "0"
                              ? null
                              : this.state.customEndDate
                            : null
                        }
                        onValueChanged={(event) =>
                          this.handleValueChange(event, "customEndDate")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12" style={{ paddingLeft: 10 }}>
                    <RadioGroup onValueChanged={(event) => this.changeExibitionType(event)} items={priorities} defaultValue={priorities[0]} layout="horizontal" />
                  </div>
                </div>
              </div>
              <div className="col-6 p-3" style={{ backgroundColor: "whitesmoke" }}>
                <div className="row">
                  <div className="col-3">
                    <Switch width={110} style={{ padding: 2, paddingTop: 3 }}
                      switchedOnText="Mudar P/ Mensal"
                      switchedOffText="Mudar P/ Diário"
                      defaultValue={
                        this.state.exibitionPeriod === 'byDay' ? true : false
                      }
                      onValueChanged={() => {
                        const newExhibitionType = this.state.exibitionPeriod === 'byDay' ? 'byMonth' : 'byDay';
                        this.setState({ exibitionPeriod: newExhibitionType }, () => {
                          this.getReport();
                        });
                      }}
                    />
                    <div style={{ paddingBottom: 15 }}></div>
                    <Switch width={110} style={{ padding: 2, paddingTop: 3 }}
                      switchedOnText="Temperatura ON"
                      switchedOffText="Temperatura OFF"

                      defaultValue={
                        this.state.temperature === 'ON' ? true : false
                      }
                      onValueChanged={() => {
                        const newExhibitionType = this.state.temperature === 'ON' ? 'OFF' : 'ON';
                        this.setState({ temperature: newExhibitionType });
                      }}
                    />
                  </div>
                  <div className="col-3"><Button
                    id="backButton"
                    // text={i18n.t("account.btnprofiledata")}
                    text="Últimos 30 Dias"
                    width="100%"
                    height="30px"
                    onClick={() => this.getReport("", "", "last30days")}
                    type="default"
                    stylingMode="outlined" /></div>
                  <div className="col-3"><Button
                    id="backButton"
                    // text={i18n.t("account.btnprofiledata")}
                    text="Últimos 90 Dias"
                    width="100%"
                    height="30px"
                    onClick={() => this.getReport("", "", "last90days")}
                    type="default"
                    stylingMode="outlined" /></div>
                  <div className="col-3">
                    <Button
                      id="backButton"
                      // text={i18n.t("account.btnprofiledata")}
                      text="Este Mês"
                      width="100%"
                      height="30px"
                      onClick={() => this.getReport("", "", "")}
                      type="default"
                      stylingMode="outlined" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-0">
          <div className="col-12 container">
            <div className="row pb-3">

              <div className="card col-4 p-3" style={{ backgroundColor: "#f8f8f8", fontSize: "14px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}>
                <div className="row">
                  <div className="col text-center">
                    <h4 style={{ fontSize: "18px", margin: "10px 0" }}>Vendas e Temperatura*</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center">
                    <label className="badge badge-success" style={{ padding: "6px 12px", borderRadius: "4px", backgroundColor: "#755ff5" }}>
                      <strong style={{ fontSize: "16px" }}>{this.returnCorrelation(this.state.salesCorrelation)}</strong>
                    </label>
                  </div>
                </div>
              </div>
              <div className="card col-4 p-3" style={{ backgroundColor: "#f8f8f8", fontSize: "14px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}>
                <div className="row">
                  <div className="col text-center">
                    <h4 style={{ fontSize: "18px", margin: "10px 0" }}>Ticket Médio e Temperatura*</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center">
                    <label className="badge badge-success" style={{ padding: "6px 12px", borderRadius: "4px", backgroundColor: "#755ff5" }}>
                      <strong style={{ fontSize: "16px" }}>{this.returnCorrelation(this.state.ticketCorrelation)}</strong>
                    </label>
                  </div>
                </div>
              </div>
              <div className="card col-4 p-3" style={{ backgroundColor: "#f8f8f8", fontSize: "14px", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}>
                <div className="row">
                  <div className="col text-center">
                    <h4 style={{ fontSize: "18px", margin: "10px 0" }}>Transações e Temperatura*</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center">
                    <label className="badge badge-success" style={{ padding: "6px 12px", borderRadius: "4px", backgroundColor: "#755ff5" }}>
                      <strong style={{ fontSize: "16px" }}>{this.returnCorrelation(this.state.transactionCorrelation)}</strong>
                    </label>
                  </div>
                </div>
              </div>

              <footer className="text-muted">
                <p>*Coeficiente de Correlação de Pearson - Versão Beta</p></footer>
            </div>
          </div>
        </div>

        <div className="row m-0 container">
          <div className="col-12">
            <div className="row" style={{ paddingTop: 20 }}>
              <Chart
                id="chart"
                style={{ height: "400px", width: "100%" }}
                palette={customPalette}
                title={this.returnTitle()}
                dataSource={this.state?.graphStats}
                onPointClick={this.onPointClick}

              >
                <LoadingIndicator
                  enabled={true}
                />
                <CommonSeriesSettings
                  argumentField="totalAmount"
                  type="bar"
                  hoverMode="allArgumentPoints"
                  selectionMode="allArgumentPoints"
                >
                  <Label visible={true}>
                    <Format type="fixedPoint" precision={0} />
                  </Label>
                </CommonSeriesSettings>

                <Series
                  name="Vendas"
                  argumentField="periodtype"
                  valueField="totalAmount"
                  type="bar"
                  color="#755ff5"
                >

                </Series>


                {
                  (this.state.temperature === 'ON') ? <Series
                    name="Temperatura"
                    argumentField="periodtype"
                    valueField="averageFeels_like"
                    axis="averageFeels_like"
                    // valueField={(temp) => this.averageFeels_like(temp)}
                    type="spline"
                    color="#ffb400"
                    Tooltip=""
                  >
                    <Label visible={false}>
                    </Label>

                  </Series>

                    : ''
                }

                <ValueAxis
                  name="Vendas"
                  position="right"
                  title="Vendas"
                >
                  <Grid visible={true} />
                </ValueAxis>
                <ValueAxis>
                  <Grid visible={true} />
                </ValueAxis>

                <Legend visible={false} position={'bottom'} >
                </Legend>
                <ZoomAndPan argumentAxis="both" />
                <ScrollBar visible={true} />
                <Export enabled={true} />
              </Chart>

            </div>
            <div className="row" style={{ paddingTop: 50 }}>
              <Chart
                id="chart"
                style={{ height: "400px", width: "100%" }}
                palette={customPalette}
                title={this.state.exibitionPeriod === 'byDay' ? 'Ticket Médio Diário' : 'Ticket Médio Mensal'}
                dataSource={this.state.graphStats}
              >
                <LoadingIndicator
                  enabled={true}
                />
                <CommonSeriesSettings
                  argumentField="campaign"
                  type="bar"
                  hoverMode="allArgumentPoints"
                  selectionMode="allArgumentPoints"
                >
                  <Label visible={true}>
                    <Format type="fixedPoint" precision={0} />
                  </Label>
                </CommonSeriesSettings>
                <Series
                  name="ticket"
                  argumentField="periodtype"
                  valueField="averageTicket"
                  type="line"
                  color="#755ff5"
                >
                  <Label
                    customizeText={this.customizeLabelTicket}
                  >
                    <Font size={12} />
                  </Label>
                </Series>
                {
                  (this.state.temperature === 'ON') ? <Series
                    name="Temperatura"
                    argumentField="periodtype"
                    valueField="averageFeels_like"
                    axis="averageFeels_like"
                    // valueField={(temp) => this.averageFeels_like(temp)}
                    type="spline"
                    color="#ffb400"
                    Tooltip=""
                  >
                    <Label visible={false}>
                    </Label>
                  </Series>

                    : ''
                }
                <ValueAxis />
                <ArgumentAxis alueMarginsEnabled={false}
                  discreteAxisDivisionMode="crossLabels">
                  <Grid visible={true} />
                </ArgumentAxis>
                <Legend visible={false} >
                </Legend>
                <ZoomAndPan argumentAxis="both" />
                <ScrollBar visible={true} />
                <Export enabled={true} />
              </Chart>

            </div>
            <div className="row" style={{ paddingTop: 50 }}>
              <Chart
                id="dailytransactions"
                title={this.state.exibitionPeriod === 'byDay' ? 'Total Transações Dia' : 'Total Transações Mês'}
                style={{ height: "400px", width: "100%" }}
                dataSource={this.state.graphStats}
                precision={1}>
                <CommonSeriesSettings
                  argumentField="campaign"
                  type="bar"
                  hoverMode="allArgumentPoints"
                  selectionMode="allArgumentPoints"
                >
                  <Label visible={true}>
                    <Format type="fixedPoint" precision={0} />
                  </Label>
                </CommonSeriesSettings>
                <LoadingIndicator
                  enabled={true}
                />
                <Series
                  argumentField="periodtype"
                  valueField="count"
                  type="spline"
                  color="#755ff5"
                />
                {
                  (this.state.temperature === 'ON') ? <Series
                    name="Temperatura"
                    argumentField="periodtype"
                    valueField="averageFeels_like"
                    axis="averageFeels_like"
                    // valueField={(temp) => this.averageFeels_like(temp)}
                    type="spline"
                    color="#ffb400"
                    Tooltip=""
                  >
                    <Label visible={false}>
                    </Label>
                    {/* <Label
                      customizeText={(temp) => this.averageFeels_like(temp)}
                    >
                      <Font size={10} />
                    </Label> */}

                  </Series>

                    : ''
                }
                <Legend visible={false} />
                <ZoomAndPan argumentAxis="both" />
                <ScrollBar visible={true} />
                <Export enabled={true} />
                <LoadingIndicator enabled={true} />
              </Chart>
            </div>
          </div>
        </div>
      </div>
    );
  }

  returnTitle() {
    if (this.state.exibitionType === 'Financial') {
      return ('Vendas ' + (this.state.exibitionPeriod === 'byDay' ? 'Dia a Dia' : 'Mês a Mês'))
    } else if (this.state.exibitionType === 'CashBack') {
      return 'CashBack Distribuído ' + (this.state.exibitionPeriod === 'byDay' ? 'Dia a Dia' : 'Mês a Mês')
    } else if (this.state.exibitionType === 'PointBack') {
      return 'Pontos ' + (this.state.exibitionPeriod === 'byDay' ? 'Dia a Dia' : 'Mês a Mês')
    }
  }

  returnCorrelation(coeficiente_corr) {
    if (coeficiente_corr >= 0.8) {
      return "Correlação positiva muito forte";
    } else if (coeficiente_corr >= 0.5) {
      return "Correlação positiva moderada";
    } else if (coeficiente_corr >= 0.3) {
      return "Correlação positiva fraca";
    } else if (coeficiente_corr <= -0.8) {
      return "Correlação negativa muito forte";
    } else if (coeficiente_corr <= -0.5) {
      return "Correlação negativa moderada";
    } else if (coeficiente_corr <= -0.3) {
      return "Correlação negativa fraca";
    } else if (coeficiente_corr === null) {
      return "Dados Insuficientes";
    } else {
      return "Correlação insignificante";
    }
  }

  customizeLabel(stats) {

    if (stats) {
      return `R$ ${stats?.point?.data?.totalAmount?.toFixed(2)}`;
    }
  }

  customizeLabelTicket(stats) {
    if (stats) {
      return `R$ ${stats?.point?.data?.averageTicket?.toFixed(2)}`;
    }
  }

  averageFeels_like(temp) {

    if (temp) {
      if (this.state?.exibitionPeriod === 'byMonth') {
        return `${(parseInt(temp?.point?.data?.averageFeels_like))}°C`;
      } else {
        return `${(temp?.point?.data?.averageFeels_like)}°C`;
      }
    }
  }

  onPointClick(e) {
    e.target.select();
  }
}
