// All reducers have 2 params :
// 1. Current State
// 2. Info that came from any action
const seedDate =
    [
        {
            fontSize: ''
        }

    ];

export default (state = seedDate, action) => {

    if (action.type == 'updateFontSize') {
        const newState = [...state];
        newState[action.payload.index].fontSize = action.payload.fontSize;
        return newState;
    } else {
        const newState = [...state];
        return newState;
    }
}


