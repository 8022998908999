// All reducers have 2 params :
// 1. Current State
// 2. Info that came from any action

const seedDate = [
  {
    objectFocus: "Square",
  },
];

export default (state = seedDate, action) => {
  return state;
};
