import React, { Component } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import updatePhotoCard from "../../../actions/updatePhotoCard";
import Modal from "../../Modal";
import "./PhotoCards.css";
import './modal.css';
import InfiniteScroll from "react-infinite-scroll-component";
import getImagesFromUnsplash, { downloadUnsplashImage } from "../../../apis/unsplash"; /// Função que se conecta com a API do Unsplash
import getSticker from "../../../apis/getStipop";
import { i18n } from "../../../translate/i18n";
import { getImagemGallery, getStudio } from "../../../apis/toowezeApi";
import updateModal from "../../../actions/updateModal";

library.add(faTrash);

let classSize;
let authorTop;

class PhotoCards extends Component {
  constructor() {
    super();

    this.state = {
      images: [],
      count: 30,
      start: 1,
      imagesShow: [],
      spinner: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.photoControl !== nextProps.photoControl) {
      if (
        this.props.photoControl[0].extra &&
        this.props.photoControl[0].extra != ""
      ) {
        //this.removeBackGround();

        this.setState({
          count: 30,
          start: 1,
          imagesShow: [],
        });

        this.readAllImages("", this.props.photoControl[0].extra);
        return true;
      }
    }

    // Busca
    if (this.props.photoControl !== nextProps.photoControl) {
      if (
        this.props.photoControl[0].search &&
        this.props.photoControl[0].search !== ""
      ) {
        //this.removeBackGround();

        this.setState({
          count: 30,
          start: 1,
          imagesShow: [],
        });

        this.readAllImages("", this.props.photoControl[0].extra);
        return true;
      }
    }

    return true;
  }

  async readAllImages(search, type) {

    this.setState({
      spinner: true,
    });

    const imagesPromises = [];
    const allImages = [];

    let search_;

    switch (this.props.search) {
      case "pet":
        search_ = { search: "pet", label: "Pets" };
        break;
      case "apparel":
        search_ = { search: "apparel", label: "Apparel" };
        break;
      case "cup":
        search_ = { search: "cup", label: "Coffee" };
        break;
      case "sales":
        search_ = { search: "sales", label: "Sales" };
        break;
      case "products":
        search_ = { search: "products", label: "Products" };
        break;
      case "business":
        search_ = { search: "business", label: "Business" };
        break;
      case "myGallery":
        search_ = { search: "myGallery", label: "Gallery" };
        break;
      case "sticker":
        search_ = { search: "sticker", label: "Stickers" };
        break;
      case "art":
        search_ = { search: "art", label: "Art" };
        break;
      case "search":
        search_ = {
          search: "search",
          label: "Search",
          searchTerm: this.props.photoControl[0].search,
          translatefrom: localStorage.getItem("i18nextLng")
        };
        break;
      default:
        search_ = "";
    }

    // Categorias do Unsplash
    // Nature
    // Wallpapers
    // Textures & Patterns
    // People
    // Business & Work
    // Travel
    // Technology
    // Animals
    // Interiors
    // Architecture
    // Food & Drink
    // Current Events
    // Athletics
    // Spirituality
    // Health & Wellness
    // Film
    // Fashion
    // Experimental
    // Arts & Culture
    // History

    if (search_.search === "myGallery") {
      imagesPromises.push(getImagemGallery());
    } else if (search_.search === "sticker") {
      /// Lê os stickers
      imagesPromises.push(getSticker("id123", this.state.start));
    } else if (search_.search === "art") {
      // Art
      imagesPromises.push(getStudio())
    } else {
      imagesPromises.push(
        getImagesFromUnsplash(search_, type, this.state.start)
      );
    }

    await Promise.all(imagesPromises)
      .then((data) => {
        if (data.length > 0) {
          data.forEach((imageData) => {
            if (imageData.length > 0) {
              allImages.push(imageData);

              // if (allImages[0].length < 10) {
              //   this.fetchImages(allImages[0].label);
              // }
            }
          });
        }

        allImages.search = search_.search;
        if (this.state.imagesShow.length === 0) {
          this.setState({
            imagesShow: allImages,
            spinner: false,
            count: this.state.count + allImages.length,
          });
        } else {

          if ((search_.search !== "myGallery") && (search_.search !== "art")) {
            this.setState({
              imagesShow: this.state.imagesShow.concat(allImages),
              spinner: false,
              count: this.state.count + allImages.length,
            });
          } else {
          }
        }
      })
      .catch((err) => {
        console.log("erro **********", err);
      });
  }

  componentDidMount() {
    if (
      this.props.photoControl[0].extra &&
      this.props.photoControl[0].extra != ""
    ) {
      this.readAllImages("", this.props.photoControl[0].extra);
    } else {
      this.readAllImages("", "landscape");
    }
  }

  handleClick = async (event) => {

    let urlEvent;
    const target = event.target.dataset;
    urlEvent = target.url;

    if (target?.type === "unsplash") {
      urlEvent = await downloadUnsplashImage(urlEvent);
      if (this.props.photoControl[0].element !== "highResolution") {
        urlEvent = urlEvent + "&width=675"
      } else {
        urlEvent = urlEvent + "&width=2000"
      }
    }

    if (target?.type === "canva") {
      this.props.updatePhotoCard(urlEvent, 0, target.id);
    } else {
      this.props.updatePhotoCard(urlEvent, 0, "");
    }

  };

  // Administra o estado para enviar a informação do id da foto a ser deletada para o modal.
  handleDelete = (event) => {
    this.props.updateModal("deleteImageGallery", 0, event.target.dataset.id);
  };

  handleDeleteArt = (event) => {
    this.props.updateModal("deleteArt", 0, event.target.dataset.id);
  };

  fetchImages = (label) => {
    if (
      this.props.photoControl[0].extra &&
      this.props.photoControl[0].extra != ""
      && this.props.photoControl[0].extra != undefined
    ) {
      this.readAllImages(label, this.props.photoControl[0].extra);
    } else {
      this.readAllImages(label, "landscape");
    }

    this.setState({ start: this.state.start + 1 });
  };

  render() {
    if (this.props.photoControl[0].extra == "portrait") {
      classSize = 150;
      authorTop = 131;
    } else if (this.props.photoControl[0].extra == "landscape") {
      classSize = 80;
      authorTop = 62;
    } else {
      classSize = 80;
      authorTop = 62;
    }

    let allPhotos = "";

    if (this.state.imagesShow.length > 0) {
      allPhotos = (
        <div className="tab-pane" id="profile1">
          <div className="form-elegant scrollbar-light-blue">
            <div
              id="allImages2"
              className="col-12"
              style={{ margin: "0", height: "69px", padding: "0px" }}
            >
              <InfiniteScroll
                className="row content"
                dataLength={this.state.count}
                next={this.fetchImages}
                hasMore={true}
                height={"56vh"}
                style={{ alignContent: "start" }}

              >
                {this.state.imagesShow.map((photos, index) => {
                  return photos.map((photo, index) => {
                    return (
                      <div
                        key={index}
                        id={photo.id}
                        className="imageDiv col-12 col-sm-12 col-md-6 col-xl-4 col-xl-4"
                        onClick={(event) => {
                          this.handleClick(event);
                        }}
                        data-url={photo?.links?.download_location ? photo?.links?.download_location : photo?.urls?.raw}
                        data-high={photo.urls.high}
                        data-type={photo.urls?.type ? photo.urls?.type : (photo?.links?.download_location ? "unsplash" : "")}
                        data-id={photo.id ? photo.id : ""}
                        style={{
                          background: `url(${photo.urls.thumb})`,
                          minHeight: (photo.type !== "gallery" && photo.type !== "art") ? classSize : "",
                          height: (photo.type !== "gallery" && photo.type !== "art") ? "" : "85px",
                        }}
                      >
                        {(photo.type === "gallery" || photo.type === "art") ? (
                          <a
                            href="#"
                            className="delete"
                            id="btn_removebg_"
                            title={i18n.t(
                              "coupon.supportmenu.icontextbackgroundremove"
                            )}
                            data-toggle="modal"
                            data-target={photo.type == "gallery" ? "#remove-header-modal2" : "#remove-header-modal3"}
                            data-id={photo.id}
                            onClick={photo.type === "gallery" ? (event) => this.handleDelete(event) : (event) => this.handleDeleteArt(event)}
                          >
                            <svg
                              pointerEvents="none"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="far"
                              data-icon="trash-alt"
                              className="svg-inline--fa fa-trash-alt fa-w-14"
                              role="img"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentColor"
                                d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
                              ></path>
                            </svg>
                          </a>
                        ) : (
                          ""
                        )}
                        {/* <div className="row" style={{ top: 10 }}>
                          <a
                            className="author"
                            href={"https://unsplash.com"}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {"Unsplash"}
                          </a>
                        </div> */}

                        {(photo.type !== "gallery" && photo.type !== "art") ? <div className="row"
                          style={{ top: authorTop }}
                        >

                          <a
                            className="author"
                            href={photo.user.links.html + "?utm_source=tooweze&utm_medium=referral"}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ top: authorTop }}
                          >
                            {("Photo by " + photo.user.first_name
                              ? photo.user.first_name
                              : "") +
                              " " +
                              (photo.user.last_name ? photo.user.last_name : "")}
                          </a>
                        </div> : ""}
                      </div>
                    );
                  });
                })}
              </InfiniteScroll>

              {/* Modal Específico para deletar foto da galeria, a informação extra é o id da foto que foi clicada para deletar */}
              {/* <Modal
                modalId="remove-header-modal3"
                title={i18n.t("coupon.supportmenu.modalimagegallerytitle")}
                h5Content={i18n.t(
                  "coupon.supportmenu.modalimagegalleryh5Content"
                )}
                content={i18n.t("coupon.supportmenu.modalimagegallerycontent")}
                cancelText={i18n.t(
                  "coupon.supportmenu.modalimagegallerycancelText"
                )}
                okText={i18n.t("coupon.supportmenu.modalimagegalleryokText")}
                actionType="deleteImageGallery"
                extra={this.state.extra}
              /> */}
            </div>
          </div>
        </div>
      );
    }
    // else { Comentei, pois qdo da erro funciona ok, mas qdo funciona o unsplash a busca continua dando mensagem de erro por estar vazia, preciso ver isso depois.

    //     ///https://images.unsplash.com/gifs/fail/fail-8.gif

    //     allPhotos = <div className="tab-pane" id="profile1">
    //     <div className="form-elegant scrollbar-light-blue">
    //     <div id="allImages2" className="col-12" style={{margin:'0',height:'69px'}}>

    //     <div><span>Erro no carregamento das imagens. Se o Erro Persistir entre em contato com o nosso suporte.</span>
    //     <img src="https://images.unsplash.com/gifs/fail/fail-20.gif" />
    //     </div>

    //     </div>
    //     </div>
    //     </div>
    // }

    return allPhotos;

    // }
  }
}

// mapStateToProps takes one args, "state" and that is the rootReducer/Store
function mapStateToProps(state) {
  // mapStateToProps returns an object, with:
  // property is the local prop name to this component
  // value will be the property in the root reducer... ie, piece of the store
  return {
    // couponNavBarData: state.couponNavBar,
    // canvas: state.canvas,
    // fontControl: state.fontControl,
    // selectedPhoto: state.selectedPhoto,
    photoControl: state.photoControl,
  };
}

// mapDispatchToProps is how we tie our component to the dispatch
// it take one args: dispatch
function mapDispatchToProps(dispatch) {
  // this function return bindActionCreators
  // and we hand bindActionCreators an object
  // each property will be a local prop
  // each value will be a function that is dispatch when run
  // second arg of bindActionCreators is the dispatch
  return bindActionCreators(
    {
      updatePhotoCard: updatePhotoCard,
      updateModal: updateModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoCards);
// export default PhotoCards;
