import React, { Component } from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  HeaderFilter,
  SearchPanel,
  Export,
  
} from "devextreme-react/data-grid";
import { getCampaigns, activeCampaign } from "../../../apis/toowezeApi";
import Spinner from "../../LayOut/Spinner";
import notify from "devextreme/ui/notify";
import { loadMessages, locale } from "devextreme/localization";
import SwitchComponent from "../../LayOut/SwitchComponent";
import "./CampaignsViewMiddle.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { i18n, lgMessages, languageCode } from "../../../translate/i18n";

class CampaignsViewMiddle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datagrid: [],
      spinner: true,
    };

    // this.onExporting = this.onExporting.bind(this);

    loadMessages(lgMessages);
    locale(languageCode);

    // Converte no filtro do Grid as informações em legendas.
    this.statusHeaderFilter = [
      {
        text: i18n.t("campaignview.grid.filterlabelactive"),
        value: ["status", "=", 1],
      },
      {
        text: i18n.t("campaignview.grid.filterlabelinactive"),
        value: ["status", "=", 0],
      },
    ];

    this.sentTypeHeaderFilter = [
      {
        text: i18n.t("campaignview.grid.filterlabelemailmkt"),
        value: ["sentType", "=", 1],
      },
      {
        text: i18n.t("campaignview.grid.filterlabelsms"),
        value: ["sentType", "=", 2],
      },
      {
        text: i18n.t("campaignview.grid.filterlabelsocialshare"),
        value: ["sentType", "=", 3],
      },
      {
        text: i18n.t("campaignview.grid.filterlabelgeneric"),
        value: ["sentType", "=", 0],
      },
    ];
  }

  // Ajuda a evitar o Render da página toda vez que há alguma mudança no State
  shouldComponentUpdate(nextProps, nextState) {
    // Evita renderizar cada vez que o estado é modificado
    if (this.state !== nextState) {
      return true;
    }

    // Evita renderizar cada vez que o estado é modificado
    // Quando clicar no switch no Grid altera o status da campanha.
    if (this.props.CampaignsView !== nextProps.CampaignsView) {
      if (this.props.CampaignsView[0].element === "campaignStatus") {
        this.alterCampaignStatus(
          this.props.CampaignsView[0].id,
          this.props.CampaignsView[0].extra
        );
      }
      return true;
    }

    return true;
  }

  componentDidMount() {
    getCampaigns()
      .then((res) => {
        this.setState({
          datagrid: res,
          spinner: false,
        });
      })
      .catch((err) => {
        this.setState({
          datagrid: [],
          spinner: false,
        });
      });
  }

  // Altera o Status da Campanha
  alterCampaignStatus(id, status) {
    activeCampaign(id).then((res) => {
      if (res.sentType) {
        if (res.sentType == 3) {
          // Share Link
          if (res.status == 0) {
            this.showNotify(
              i18n.t("campaignview.notify.notifydeactivecampaign"),
              "error"
            );
          } else if (res.status == 1) {
            this.showNotify(
              i18n.t("campaignview.notify.notifyactivecampaign"),
              "success"
            );
          }
        }
      } else if (res.APPLICABLE) {
        // Email Marketing
        if (res.Status == 0) {
          this.showNotify(
            i18n.t("campaignview.notify.notifydeactivecampaign"),
            "error"
          );
        } else if (res.Status == 1) {
          this.showNotify(
            i18n.t("campaignview.notify.notifyactivecampaign"),
            "success"
          );
        }
      }
    });
  }

  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "top", offset: "0 200" },
        closeOnClick: true,
      },
      type,
      10000
    );
  }

  calculateCellValue(data) {
    return [data.contactName, data.name].join(" ");
  }

  calculateCellValue2(data) {
    return new Date(data.createdAt * 1000);
  }

  handlecouponStatus(event) {
    // console.log('Ativar/Desativar', event);
  }

  // Altera o retorno do tipo de envio.
  sentType(data) {
    if (data.data.sentType == "1") {
      return (
        <span className="badge badge-success">
          {i18n.t("campaignview.grid.customfieldemailmkt")}
        </span>
      );
    } else if (data.data.sentType == "2") {
      return (
        <span className="badge badge-danger">
          {i18n.t("campaignview.grid.customfieldsms")}
        </span>
      );
    } else if (data.data.sentType == "3") {
      return (
        <span className="badge badge-danger">
          {i18n.t("campaignview.grid.customfieldsocialshare")}
        </span>
      );
    } else if (data.data.sentType == "0") {
      return (
        <span className="badge badge-danger">
          {i18n.t("campaignview.grid.customfieldgeneric")}
        </span>
      );
    }
  }

  // Altera o retorno do tipo de envio.
  sentStatus(data) {
    // Tras o component de Switch
    return (
      <div>
        <SwitchComponent
          id={data.data._id}
          event={"campaignStatus"}
          value={data.data.status == 1 ? true : false}
        />
      </div>
    );
  }

  // Cria link para ver atividades da campanha
  activity(data) {
    return (
      <Link to={`campaignsactivity?id=${data.data._id}`}>
        {i18n.t("campaignview.grid.eventlinklabel")}
      </Link>
    );
  }

  // Cria link para visualizar a campanha
  details(data) {
    return (
      <Link to={`campaigncreate?campaignId=${data.data._id}`}>
        {i18n.t("campaignview.grid.detailslinklabel")}
      </Link>
    );
  }

  render() {
    if (this.state.spinner == true) {
      return <Spinner />;
    } else {
      // View	Nome Campanha	Lista de Contatos	Tipo	Publicada	Remetente	Criada em	Enviada em

      return (
        <div
          className="container col-11"
          style={{ marginLeft: "30px", overflow: "scroll", marginTop: "30px" }}
        >
          <DataGrid
            dataSource={this.state.datagrid}
            showBorders={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            className="custom dx-cell-focus-disabled"
            // style={{Width: '500px'}}
            onExporting={this.onExporting}
          >
            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 15]}
              showInfo={true}
            />

            <HeaderFilter visible={true} />
            <SearchPanel
              visible={true}
              className="custom"
              width={240}
              placeholder={i18n.t("campaignview.grid.searchlabel")}
            />
            <Column
              dataField="name"
              caption={i18n.t("campaignview.grid.columnlabelcampaignname")}
            />
            <Column
              dataField="messageName"
              caption={i18n.t("campaignview.grid.columnlabelmessagename")}
            />
            <Column
              dataField="contactName"
              caption={i18n.t("campaignview.grid.columnlabelcontactname")}
            />
            <Column
              dataField="sentType"
              caption={i18n.t("campaignview.grid.columnlabelsenttype")}
              cellRender={this.sentType}
            >
              <HeaderFilter dataSource={this.sentTypeHeaderFilter} />
            </Column>
            <Column
              dataField="sendFrom"
              caption={i18n.t("campaignview.grid.columnlabelsendfrom")}
            />
            <Column
              dataField="createdAt"
              caption={i18n.t("campaignview.grid.columnlabelcreatedat")}
              dataType="date"
              calculateCellValue={this.calculateCellValue2}
            />
            <Column
              dataField="sendDate"
              caption={i18n.t("campaignview.grid.columnlabelsenddate")}
              dataType="date"
            />
            <Column
              dataField="status"
              caption={i18n.t("campaignview.grid.columnstatus")}
              cellRender={this.sentStatus}
              width={100}
              className="dx-cell-focus-disabled"
              focusedRowEnabled={false}
            >
              <HeaderFilter dataSource={this.statusHeaderFilter} />
            </Column>
            <Column
              dataField="details"
              caption={i18n.t("campaignview.grid.columnlabelcheckcampaign")}
              cellRender={this.details}
            />
            <Column
              dataField="seeActivity"
              caption={i18n.t("campaignview.grid.columnlabelactivity")}
              cellRender={this.activity}
            />
            <Export enabled={true} allowExportSelectedData={false} />
          </DataGrid>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  // mapStateToProps returns an object, with:
  // property is the local prop name to this component
  // value will be the property in the root reducer... ie, piece of the store
  return {
    CampaignsView: state.CampaignsView,
  };
}

// export default CanvasComponent;
export default connect(mapStateToProps)(CampaignsViewMiddle);
