import React, { Component } from "react";
import "./Auth.css";
import logo from "../../images/complet_logo_2.png";
import { LoadPanel } from "devextreme-react/load-panel";
import { TextBox } from "devextreme-react";
import { Validator, RequiredRule, EmailRule } from "devextreme-react/validator";
import { Button } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { Link } from "react-router-dom";
import { proceedLogin } from "../../apis/toowezeApi";
import { i18n } from "../../translate/i18n";
const position = { of: "#logIn" };

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadPanelVisible: false,
      allPanelVisible: false,
      goValidateEmail: false,
      emailCheck: false,
      photo: "9",
      checked: false,
      email: "",
      password: "",
      includesAuth: false,
    };
  }

  componentWillMount() {
    document.body.style.backgroundColor = "white";
    // Limpa as informações de Login
    localStorage.removeItem("auth");
    localStorage.removeItem("tooweze_token");
    localStorage.removeItem("isConfigured");
    // Limpa as informações de Login
    this.setState({
      photo: Math.round(Math.random() * 8).toString(),
    });
  }

  componentDidMount() {
    document.body.style.backgroundColor = "white";
    this.setState({
      includesAuth: window.location.href.includes("auth"),
    });
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  // Administra as mudanças de valores dos TextBox
  handleValueChange = (event, fieldId) => {
    if (fieldId) {
      if (fieldId === "checked") {
        this.setState({
          [fieldId]: event.target.checked ? event.target.checked : false,
        });
      } else {
        this.setState({
          [fieldId]: event.value ? event.value : null,
        });
      }
    }
  };

  // Faz login na conta
  LogIn = (event) => {
    event.event.preventDefault();

    if (this.state.email) {
      this.setState({ loadPanelVisible: true });

      proceedLogin(this.state.email, this.state.password)
        .then((res) => {
          setTimeout(() => {
            this.setState({ loadPanelVisible: false });
            if (res) {
              window.location.replace("/dashboard");
            }
          }, 500);
        })
        .catch((error) => {
          this.setState({ loadPanelVisible: false });
          try {
            if (error.response) {
              if (error.response.data === "Invalid email or password.") {
                this.showNotify(i18n.t("login.notify.userpassword"), "error");
              } else if (
                error.response.data ===
                '"password" length must be at least 5 characters long'
              ) {
                this.showNotify(i18n.t("login.notify.passwordlength"), "error");
              } else if (error.response.data === "Email Not Confirmed") {
                this.showNotify(i18n.t("login.notify.confirmemail"), "error");
              } else {
                this.showNotify(error.response.data, "error");
              }
            } else {
              if (error === "Email Not Confirmed") {
                this.showNotify(i18n.t("login.notify.confirmemail"), "error");
              } else {
                this.showNotify(error, "error");
              }
            }
          } catch (error) {
            this.showNotify(error, "error");
          }
        });
    }
  };

  // Faz aparecer uma mensagem
  showNotify(message, type) {
    notify(
      {
        message: message,
        width: 300,
        position: { at: "middle", offset: "-450 -250" },
        closeOnClick: true,
      },
      type,
      3000
    );
  }

  render() {
    return (
      <div className="row col-12 p-0 m-0">
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={true}
        />
        <div id="7" className="d-none d-md-block col-md-4" style={{ paddingRight: "29px", paddingLeft: "0px" }}>
          <div className="logoFirst">
            <img className="logoExterno" src={logo} alt="Logo" />
          </div>

          <div className="auth-fluid-form-box" style={{ paddingTop: "18vh" }}>
            <div className="align-items-center d-flex h-100">
              <div className="card-body" style={{ paddingRight: "4px" }}>
                <div className="auth-brand text-center text-lg-left"></div>

                <h4 className="mt-0">{i18n.t("login.labellogin")}</h4>
                <p className="text-muted mb-4">
                  {i18n.t("login.labelemailpassword")}
                </p>

                <div>
                  <form id="logIn">
                    <div className="dx-field">
                      <div className="dx-label">
                        {i18n.t("login.labelemail")}
                      </div>
                      <div className="dx-value">
                        <TextBox
                          showClearButton={true}
                          placeholder=""
                          value={this.state.email}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "email")
                          }
                        >
                          <Validator>
                            <RequiredRule
                              message={i18n.t("login.message.emailrequired")}
                            />
                            <EmailRule
                              message={i18n.t("login.message.invalidemail")}
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>
                    <div className="dx-field">
                      <div className="dx-label">
                        {i18n.t("login.labelpassword")}
                        <div
                          href="pages-login.html"
                          className="text-muted ml-1"
                          style={{ float: "right" }}
                        >
                          <Link
                            to={
                              this.state.includesAuth === true
                                ? "recoverypassword"
                                : "/auth/recoverypassword"
                            }
                          >
                            <b>{i18n.t("login.labelforgetpassword")} </b>
                          </Link>
                        </div>
                      </div>
                      <div className="dx-value">
                        <TextBox
                          showClearButton={true}
                          mode="password"
                          value={this.state.password}
                          onValueChanged={(event) =>
                            this.handleValueChange(event, "password")
                          }
                        >
                          <Validator>
                            <RequiredRule
                              message={i18n.t("login.message.passwordrequired")}
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>

                    <div className="form-group mb-0 text-center">
                      <Button
                        text=" Log In "
                        height={30}
                        width={"100%"}
                        type="success"
                        onClick={this.LogIn}
                      ></Button>
                    </div>

                    <div className="text-center mt-4">
                      <p id="output" className="text-muted font-16"></p>
                    </div>
                  </form>
                </div>

                <footer>
                  <p className="text-muted">
                    {i18n.t("login.labelaccount")}
                    <Link
                      to={
                        this.state.includesAuth === true
                          ? "register"
                          : "/auth/register"
                      }
                    >
                      <b>{i18n.t("login.labelregister")}</b>
                    </Link>
                  </p>
                </footer>
              </div>
            </div>
          </div>
        </div>
        <div
          id="6"
          className="auth d-none d-md-block col-md-8"
          style={{
            top: "-17px",
            right: "-50px",
            background: `url('/images/capa_${this.state.photo}.jpg') 50% no-repeat`,
            backgroundSize: "cover",
          }}
        >
          {/* <!--Parte do meio--> */}
          {/* <MiddleBody module='Home'/> */}
          <div className="saleText text-center">
            <h2 className="mb-3">{i18n.t("login.salesmessage")}</h2>
            <p className="saleTextFirst">{i18n.t("login.salessubmessage")}</p>
            <p>{i18n.t("login.salesfooter")}</p>
          </div>
        </div>
        <div className="d-sm-none d-md-block col-12" style={{ display: "flex", alignItems: "center", height: "500px" }}><label><strong>Esta aplicação não está preparada para ser visualizada no celular.</strong> <a href="https://www.tooweze.com"><span style={{ color: "red" }}>Voltar</span></a></label></div>
      </div>
    );
  }
}
