import React, { Component } from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  HeaderFilter,
  SearchPanel,
  Export,
} from "devextreme-react/data-grid";
import { getCampaignsActivity } from "../../../apis/toowezeApi";
import Spinner from "../../LayOut/Spinner";
import { loadMessages, locale } from "devextreme/localization";
import queryString from "query-string";
import "./CampaignsActivityMiddle.css";
import { Button } from "devextreme-react";
import { Popup } from "devextreme-react/popup";
import { i18n, lgMessages, languageCode } from "../../../translate/i18n";
import { FilterRow, MasterDetail } from "devextreme-react/data-grid";
import imgCelsius from "../../../images/thermometer.png";
// import imgKelvin from "../images/kelvin.png";
import imgFahrenheit from "../../../images/fahrenheit.png";

const flag = (data) => {
  return <img alt="" src={data.data.flag} />;
};

const temp = (data) => {
  if (data) {
    if (localStorage.getItem("i18nextLng") !== "en-US") {
      return (parseInt(data) - 273.15).toFixed(2);
    } else {
      return ((parseInt(data) - 273.15).toFixed(2) * 9) / 5 + 32;
    }
  } else {
    return <label></label>;
  }
};

const imgTemp = () => {
  if (localStorage.getItem("i18nextLng") !== "en-US") {
    return { src: imgCelsius, size: 12 };
  } else {
    return { src: imgFahrenheit, size: 28 };
  }
};

const degrees = (data) => {
  let result = <label></label>;
  if (data.data?.main?.temp) {
    result = (
      <div>
        <label className="badge badge-warning">
          {temp(data.data?.main?.temp)}
        </label>
        <img
          alt=""
          src={imgTemp().src}
          style={{ marginBottom: "5px" }}
          width={imgTemp().size}
          height={imgTemp().size}
        />
      </div>
    );
  }
  return result;
};

const humidity = (data) => {
  let result = <label></label>;
  if (data.data?.main?.humidity) {
    result = (
      <div>
        <label className="badge badge-warning">
          {data.data?.main?.humidity}%
        </label>
        <img
          alt=""
          src={
            "http://openweathermap.org/img/w/" +
            data.data?.weather[0]?.icon +
            ".png"
          }
          // style={{ marginBottom: "5px" }}
          width="18"
          height="18"
        />
      </div>
    );
  }
  return result;
};

const addCurrency = (data) => {
  let result = <label></label>;
  if (data) {
    const resultData = data.data[data.column.dataField];
    const symbol = data.data?.currency?.symbol;
    let finalResult;

    if (data.data[data.column.dataField]) {
      if (data.column.dataField !== "discount") {
        finalResult = symbol + " " + resultData;
      } else if (data.column.dataField === "discount") {
        finalResult = resultData + " " + "%";
      }
    }

    if (finalResult) {
      result = (
        <div>
          <label className="badge badge-success">{finalResult}</label>
        </div>
      );
    } else {
      result = (
        <div>
          <label className="badge">n/a</label>
        </div>
      );
    }
  }
  return result;
};

const addCity = (data) => {
  let result;
  result = (
    <div>
      <label className="badge">
        {data.data.city +
          " - " +
          data.data.region +
          " - " +
          data.data.country_name}
      </label>
    </div>
  );
  return result;
};

const formatField = (data) => {
  let result;
  result = (
    <div>
      <label className="badge">{data.value}</label>
    </div>
  );
  return result;
};

const MasterDetailGrid = (element) => {
  const finalElement = {
    ...element.data.data.ipAddress,
    ...element.data.data.temperature,
    ...element.data.data,
  };

  const dataSource = [finalElement];
  return (
    <DataGrid
      dataSource={dataSource}
      showBorders={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      className="custom"
    >
      <Column
        dataField="main.temp"
        cellRender={degrees}
        caption={i18n.t("campaignview.activity.columniptempmaintemp")}
      />
      <Column
        dataField="main.humidity"
        cellRender={humidity}
        caption={i18n.t("campaignview.activity.columniptempmainhumidity")}
      />
      <Column
        dataField="initialValue"
        caption={i18n.t("campaignview.activity.columninitialvalue")}
        cellRender={addCurrency}
      />
      <Column
        dataField="discount"
        caption={i18n.t("campaignview.activity.columndiscount")}
        cellRender={addCurrency}
      />
      <Column
        dataField="discountValue"
        caption={i18n.t("campaignview.activity.columndiscountvalue")}
        cellRender={addCurrency}
      />
      <Column
        dataField="voucher"
        caption={i18n.t("campaignview.activity.columnvoucher")}
        cellRender={addCurrency}
      />
      <Column
        dataField="finalValue"
        caption={i18n.t("campaignview.activity.columnfinalvalue")}
        cellRender={addCurrency}
      />
      {/* <Column
        dataField="postal"
        caption={i18n.t("campaignview.activity.columniptempzipcode")}
      /> */}
      <Column
        dataField="city"
        caption={i18n.t("campaignview.activity.columniptemploc")}
        cellRender={addCity}
      />
      {/* <Column
        dataField="region"
        caption={i18n.t("campaignview.activity.columniptempregion")}
      /> */}
      {/* <Column
        dataField="country_name"
        caption={i18n.t("campaignview.activity.columniptempcountryname")}
      /> */}
      <Column
        dataField="asn.name"
        caption={i18n.t("campaignview.activity.columniptempprovidername")}
        cellRender={formatField}
      />
      {/* <Column
        dataField="currency.symbol"
        caption={i18n.t("campaignview.activity.columniptempcurrencysymbol")}
      /> */}
      <Column
        dataField="languages[0].native"
        caption={i18n.t("campaignview.activity.columniptemplanguages")}
        cellRender={formatField}
      />
      <Column
        dataField="latitude"
        caption={i18n.t("campaignview.activity.columniptemplatitude")}
        cellRender={formatField}
      />
      <Column
        dataField="longitude"
        caption={i18n.t("campaignview.activity.columniptemplongitude")}
        cellRender={formatField}
      />
    </DataGrid>
  );
};
export default class CampaignsActivityMiddle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datagrid: [],
      spinner: true,
      popupVisible: false,
      couponImage: "",
      couponTitle: "",
    };

    loadMessages(lgMessages);
    locale(languageCode);

    // Converte no filtro do Grid as informações em legendas.
    this.statusHeaderFilter = [
      {
        text: 'Bounce',
        value: ["eventType", "=", "Bounce"],
      },
      {
        text: 'Spam Report',
        value: ["eventType", "=", "Complaint"],
      },
      {
        text: i18n.t("campaignview.activity.filterlabelopen"),
        value: ["eventType", "=", "Open"],
      },
      {
        text: i18n.t("campaignview.activity.filterlabelviewpage"),
        value: ["eventType", "=", "View Page"],
      },
      {
        text: i18n.t("campaignview.activity.filterlabelgeneratecode"),
        value: ["eventType", "=", "Generate Code"],
      },
      {
        text: i18n.t("campaignview.activity.filterlabelclick"),
        value: ["eventType", "=", "Click"],
      },
      {
        text: i18n.t("campaignview.activity.filterlabelusedcoupon"),
        value: ["eventType", "=", "Using Coupon Code"],
      },
      {
        text: i18n.t("campaignview.activity.filterlandingpageform"),
        value: ["eventType", "=", "LandingPage Lead Form"],
      },

      // {
      //   text: i18n.t("campaignview.activity.filterlabelusedcoupon"),
      //   value: ["eventSource", "=", "3"],
      // },
    ];

    this.statusHeaderFilterSource = [
      {
        text: i18n.t("campaignview.activity.labelsource"),
        value: ["source", "=", "3"],
      },
    ];

    this.unsubHeaderFilter = [
      {
        text: i18n.t("campaignview.activity.unsubscribe"),
        value: ["source", "contains", "unsubscribe"],
      },
    ]
  }

  // Busca a campanha pelo Id e dá detalhes
  componentDidMount() {
    let url = window.location.search;
    let params = queryString.parse(url);
    if (params.id) {
      getCampaignsActivity(params.id, 3000, 0).then((res) => {
        this.setState({
          datagrid: res,
          spinner: false,
        });
      });
    }
  }

  // Converte o timestamp que vem do banco de dados em data normal no Grid.
  timeStampToDate(data) {
    return new Date(data.createdAt * 1000);
  }

  // Criandos as legendas no Grid relativas a eventos do cupom, abertura de email ou geração de código.
  eventType(data) {
    if (data.data.eventType == "Open") {
      return (
        <span className="badge badge-success">
          {i18n.t("campaignview.activity.customfieldopen")}
        </span>
      );


    } else if (data.data.eventType == "Bounce") {
      return (
        <span className="badge badge-warning">
          Bounce
          {/* {i18n.t("campaignview.activity.customfieldviewpage")} */}
        </span>
      );


    } else if (data.data.eventType == "Complaint") {
      return (
        <span className="badge badge-warning">
          Spam Report
          {/* {i18n.t("campaignview.activity.customfieldviewpage")} */}
        </span>
      );

    } else if (data.data.eventType == "Delivery") {
      return (
        <span className="badge badge-success">
          Enviado
          {/* {i18n.t("campaignview.activity.customfieldviewpage")} */}
        </span>
      );


    } else if (data.data.eventType == "View Page") {
      return (
        <span className="badge badge-warning">
          {i18n.t("campaignview.activity.customfieldviewpage")}
        </span>
      );
    } else if (data.data.eventType == "Generate Code") {
      return (
        <span className="badge badge-danger">
          {i18n.t("campaignview.activity.customfieldgeneratedcoupon")}
        </span>
      );
    } else if (data.data.eventType == "Click") {
      return (
        <span className="badge badge-danger">
          {i18n.t("campaignview.activity.customfieldclick")}
        </span>
      );
    } else if (data.data.eventType == "Using Coupon Code") {
      return (
        <span className="badge badge-success">
          {i18n.t("campaignview.activity.customfieldusedcoupon")}
        </span>
      );
    } else if (data.data.eventType == "LandingPage Lead Form") {
      return (
        <span className="badge badge-success">
          {i18n.t("campaignview.activity.customfieldusedlandingpageform")}
        </span>
      );
    }
  }

  eventSource(data) {
    if (data.data.source === "3") {
      return (
        <span className="badge badge-success">
          {i18n.t("campaignview.activity.labelsource")}
        </span>
      );
    } else if (data.data.source === "4") {
      return (
        <span className="badge badge-success">
          {i18n.t("campaignview.activity.labelsourcelandingpage")}
        </span>
      );
    } else {
      return <span>{data.data.source}</span>;
    }
  }

  clickType(data) {
    if (data.data?.link?.toString().includes("https://e0a84.web.app")) {
      data.data.link = 'Unsubscribe';
      return (
        <span className="badge badge-danger">
          {i18n.t("campaignview.activity.unsubscribe")}
        </span>
      );
    } else {
      return <span>{data.data?.link}</span>;
    }
  }

  showModal = (data) => {
    this.setState({
      popupVisible: true,
      couponImage: data.imageurlThumb ? data.imageurlThumb : data.link,
      couponTitle: data.couponTitle,
      couponId: data.couponId,
    });
  };

  eventCouponId = (data) => {
    if (data.data.couponId) {
      return (
        <div onClick={() => this.showModal(data.data)}>
          <span className="likeLink">
            {i18n.t("campaignview.activity.modalcouponlabel")}
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  hideInfo = () => {
    this.setState({
      popupVisible: false,
    });
  };

  render() {
    if (this.state.spinner === true) {
      return <Spinner />;
    } else {
      return (
        <div className="container col-11" style={{ marginLeft: "30px" }}>
          <p style={{ height: "9px" }}></p>

          <Popup
            visible={this.state.popupVisible}
            onHiding={this.hideInfo}
            dragEnabled={false}
            closeOnOutsideClick={true}
            showTitle={true}
            title={this.state.couponTitle}
            width={300}
            height={350}
          >
            <p>
              {this.state.couponImage ? (
                <img
                  src={this.state.couponImage}
                  alt=""
                  style={{ width: "100%", height: "auto", marginBottom: "5px" }}
                />
              ) : (
                i18n.t("campaignview.message.couponimage")
              )}

              <div className="col-12" style={{ marginLeft: "3px" }}>
                <div className="row">
                  <div className="col p-0">
                    <Button
                      width={120}
                      text={i18n.t(
                        "campaignview.activity.btncouponconfiglabel"
                      )}
                      type="success"
                      stylingMode="outlined"
                      onClick={() => {
                        this.props.history.push(
                          `/viewcoupons?id=${this.state.couponId}`
                        );
                      }}
                    />
                  </div>
                  <div className="col p-0">
                    <Button
                      width={120}
                      text={i18n.t("campaignview.activity.btnimageeditlabel")}
                      type="default"
                      stylingMode="outlined"
                      onClick={() => {
                        this.props.history.push(
                          `/studio?id=${this.state.couponId}`
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <div style={{ marginLeft: "3px", paddingTop: "8px" }}>
                <span className="text-muted" style={{ fontSize: "12px" }}>
                  {i18n.t("campaignview.message.imageediwarning")}
                </span>
              </div>
            </p>
          </Popup>
          <DataGrid
            dataSource={this.state.datagrid}
            showBorders={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            className="custom"

          >
            <HeaderFilter visible={true} />
            <SearchPanel
              visible={true}
              className="custom"
              width={240}
              placeholder={i18n.t("campaignview.activity.searchlabel")}
            />

            <MasterDetail enabled={true} component={MasterDetailGrid} />
            <FilterRow visible={false} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={10} defaultPageIndex={0} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 30]}
              showInfo={true}
            />
            <Column
              dataField="source"
              cellRender={this.eventSource}
              caption={i18n.t("campaignview.activity.columnlabelsource")}
            ></Column>
            <Column
              dataField="destination"
              caption={i18n.t("campaignview.activity.columnlabeldestination")}
            />
            <Column
              dataField="subject"
              caption={i18n.t("campaignview.activity.columnnamesubject")}
            />
            <Column
              dataField="eventType"
              caption={i18n.t("campaignview.activity.columnnameeventtype")}
              cellRender={this.eventType}
            >
              <HeaderFilter dataSource={this.statusHeaderFilter} />
            </Column>
            <Column
              dataField="link"
              caption={i18n.t("campaignview.activity.columnnamelink")}
              cellRender={this.clickType}
            >
              <HeaderFilter dataSource={this.unsubHeaderFilter} />
            </Column>
            <Column
              dataField="ipAddress.ip"
              caption={i18n.t("campaignview.activity.columnnameipaddress")}
            />
            <Column
              dataField="couponId"
              caption={i18n.t("campaignview.activity.columnlabelcoupon")}
              cellRender={this.eventCouponId}
            />
            <Column
              dataField="timestamp"
              caption={i18n.t("campaignview.activity.columnlabeltimestamp")}
              dataType="date"
              calculateCellValue={this.timeStampToDate}
            />
            <Export
              enabled={true}
              allowExportSelectedData={false}
              fileName="TooWeze_CampaignActivity"
            />
          </DataGrid>
        </div>
      );
    }
  }
}
